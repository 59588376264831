export const apiEndpoints = {
    AGENCIAS: {
        GET_AGENCIAS: '/api/v2/Agencias/GetAgencias',
        SALVAR_AGENCIA: '/api/v2/Agencias/SalvarAgencia',
        DELETE_AGENCIA: '/api/v2/Agencias/DeleteAgencia',
    },
    ARQUIVOS: {
        GET_ARQUIVOS: '/api/v2/Arquivos/GetArquivos',
        ADD_ARQUIVO: '/api/v2/Arquivos/AddArquivo',
        DELETE_ARQUIVO: '/api/v2/Arquivos/DeleteArquivo',
    },
    AUDITORIAS: {
        GET_AUDITORIAS: '/api/v2/Auditorias/GetAuditorias',
        SALVAR_AUDITORIA: '/api/v2/Auditorias/SalvarAuditoria',
        GET_RESPONSAVEIS: '/api/v2/Auditorias/GetResponsaveis',
        GET_MESTRES: '/api/v2/Auditorias/GetMestres',
        GET_OBRAS: '/api/v2/Auditorias/GetObras',
        GET_AUDITORIA: '/api/v2/Auditorias/GetAuditoria',
        GET_AUDITORIAS_OBRA: '/api/v2/Auditorias/GetAuditoriasObra',
        POST_FILE: '/api/v2/Auditorias/PostFile',
        DELETE_AUDITORIA: '/api/v2/Auditorias/DeleteAuditoria',
        RESPONDER_AUDITORIA: '/api/v2/Auditorias/responderAuditoria',
        GET_AUDITORIAS_PENDENTES: '/api/v2/Auditorias/getAuditoriasPendentes',
    },
    BANCO: {
        GET_FISICO: '/api/v2/Banco/GetFisico',
        GET_AVANCOS: '/api/v2/Banco/GetAvancos',
        GET_REALIZADO_MES: '/api/v2/Banco/getRealizadoMes',
        SALVAR_FISICO: '/api/v2/Banco/salvarFisico',
        GET_FISICOS: '/api/v2/Banco/getFisicos',
        GET_AVANCOS_FISICOS: '/api/v2/Banco/getAvancosFisicos',
        UPDATE_AVANCO_FISICO: '/api/v2/Banco/updateAvancoFisico',
        GET_CATEGORIA_ITENS: '/api/v2/Banco/getCategoriaItens',
        GET_ORCAMENTOS_BANCO: '/api/v2/Banco/getOrcamentosBanco',
        GET_ORCAMENTOS: '/api/v2/Banco/GetOrcamentos',
        NOVO_ORCAMENTO_BANCO: '/api/v2/Banco/NovoOrcamentoBanco',
        GET_ORCAMENTO_BANCO: '/api/v2/Banco/GetOrcamentoBanco',
        NOVO_ITEM_ORCAMENTO_BANCO: '/api/v2/Banco/novoItemOrcamentoBanco',
        DELETE_ORCAMENTO_BANCO_ITEM: '/api/v2/Banco/DeleteOrcamentoBancoItem',
        UPDATE_VALOR_TOTAL: '/api/v2/Banco/UpdateValorTotal',
        DELETE_ORCAMENTO_BANCO: '/api/v2/Banco/deleteOrcamentoBanco',
        GET_MEDICOES: '/api/v2/Banco/getMedicoes',
        GET_RESPONSAVEIS: '/api/v2/Banco/getResponsaveis',
        NOVA_MEDICAO: '/api/v2/Banco/novaMedicao',
        UPDATE_MEDICAO: '/api/v2/Banco/updateMedicao',
        DELETE_MEDICAO: '/api/v2/Banco/deleteMedicao',
        GET_FLUXO_OBRAS: '/api/v2/Banco/getFluxoObras',
    },
    CASAS: {
        GET_CASAS: '/api/v2/Casas/GetCasas',
        SALVAR_CASA: '/api/v2/Casas/SalvarCasa',
        ADD_CASA: '/api/v2/Casas/AddCasa',
        GET_ADITIVOS: '/api/v2/Casas/GetAditivos',
        DELETE_CASA: '/api/v2/Casas/DeleteCasa',
        ADD_TAREFAS: '/api/v2/Tarefas/AddTarefas',
        GET_CASA: '/api/v2/Casas/GetCasa2',
        POST_FILE: '/api/v2/Casas/PostFile',
        DOWNLOAD: '/api/v2/Casas/Download',
        DELETE_ARQUIVO: '/api/v2/Chamados/DeleteArquivo',
        DISPONIBILIZAR_ARQUIVO_CLIENTE: '/api/v2/Casas/DisponibilizarArquivoCliente',
        ADD_CHAMADO: '/api/v2/Chamados/AddChamado',
        ADD_CLIENTE_OBRA: '/api/v2/Casas/AddClienteObra',
        REMOVE_CLIENTE_OBRA: '/api/v2/Casas/RemoveClienteObra',
        CARREGAR_PROCESSOS: '/api/v2/Casas/CarregarProcessos',
    },
    CATEGORIAS: {
        GET_CATEGORIAS: '/api/v2/Categorias/GetCategorias',
        GET_CATEGORIAS_OC: '/api/v2/Categorias/GetCategoriasOC',
        SALVAR_CATEGORIA: '/api/v2/Categorias/SalvarCategoria',
        DELETE_CATEGORIA: '/api/v2/Categorias/DeleteCategoria',
    },
    CCAS: {
        GET_CCAS: '/api/v2/CCAs/GetCCAs',
        SALVAR_CCA: '/api/v2/CCAs/SalvarCCA',
        DELETE_CCA: '/api/v2/CCAs/DeleteCCA',
    },
    CHAMADOS: {
        GET_CHAMADOS: '/api/v2/Chamados/GetChamados',
        SEND_LEMBRETE: '/api/v2/Chamados/SendLembrete',
        GET_CHAMADO: '/api/v2/Chamados/GetChamado',
        GET_RESPONSAVEIS: '/api/v2/Chamados/GetResponsaveis',
        DELETE_HISTORICO: '/api/v2/Chamados/DeleteHistorico',
        DELETE_CHAMADO: '/api/v2/Chamados/DeleteChamado',
        ADD_HISTORICO: '/api/v2/Chamados/AddHistorico',
        SALVAR_AVALIACAO: '/api/v2/Chamados/SalvarAvaliacao',
        SALVAR_GERAL: '/api/v2/Chamados/SalvarGeral',
        ATUALIZAR_RECLAMACAO: '/api/v2/Chamados/AtualizarReclamacao',
        DELETAR_RECLAMACAO: '/api/v2/Chamados/DeletarReclamacao',
        ADD_RECLAMACAO: '/api/v2/Chamados/AddReclamacao',
        POST_FILE: '/api/v2/Chamados/PostFile',
        DOWNLOAD: '/api/v2/Chamados/Download',
        DELETE_ARQUIVO: '/api/v2/Chamados/DeleteArquivo',
        CONCLUIR_CHAMADO: '/api/v2/Chamados/ConcluirChamado',
        VISITA_FEITA_CHAMADO: '/api/v2/Chamados/VisitaFeitaChamado',
        AVISO_ENCERRAMENTO: '/api/v2/Chamados/AvisoEncerramento',
    },
    CLIENTE_OBRA: {
        GET_INVESTIMENTOS: '/api/v2/Investidor/GetInvestimentos',
        GET_RELATORIO: '/api/v2/Investidor/GetRelatorio',
    },
    COLABORADORES: {
        GET_COLABORADORES: '/api/v2/Colaboradores/GetColaboradores',
        DELETE_COLABORADOR: '/api/v2/Colaboradores/deleteColaborador',
        GET_ARQUIVOS: '/api/v2/Colaboradores/getArquivos',
        DELETE_ARQUIVO: '/api/v2/Colaboradores/deleteArquivo',
        POST_FILE: '/api/v2/Colaboradores/PostFile',
        SALVAR_COLABORADOR: '/api/v2/Colaboradores/salvarColaborador',
        ATIVA_COLABORADOR: '/api/v2/Colaboradores/ativaColaborador',
        SALVAR_CONTRATO: '/api/v2/Colaboradores/salvarContrato',
        DELETE_CONTRATO: '/api/v2/Colaboradores/deleteContrato',
        GET_RELATORIO_PAGAMENTOS: '/api/v2/Ordens/getRelatorioPagamentos',
    },
    COMPOSICOES: {
        GET_COMPOSICOES: '/api/v2/Composicoes/GetComposicoes',
        ADD_COMPOSICAO: '/api/v2/Composicoes/AddComposicao',
        SALVAR_COMPOSICAO: '/api/v2/Composicoes/SalvarComposicao',
        DELETE_COMPOSICAO: '/api/v2/Composicoes/DeleteComposicao',
    },
    CONTAS: {
        GET_CONTAS: '/api/v2/Conta/GetContas',
        SET_SUBSCRIPTION: '/api/v2/Conta/SetSubscription',
        GET_PLANOS: '/api/v2/Conta/GetPlanos',
        GET_MODULOS: '/api/v2/Conta/GetModulos',
        GET_CONTA: '/api/v2/Conta/GetConta',
        CRIAR_CONTA: '/api/v2/Conta/CriarConta',
    },
    CORRESPONDENCIA: {
        GET_QUADRO: '/api/v2/Correspondencia/GetQuadro',
        CHANGE_ORDER: '/api/v2/Correspondencia/ChangeOrder',
        GET_CLIENTES_FULL: '/api/v2/Correspondencia/GetClientesFull',
        GET_CLIENTES_MES: '/api/v2/Correspondencia/GetClientesMes',
        CHECK_CPF: '/api/v2/Correspondencia/CheckCPF',
        GET_CLIENTE: '/api/v2/Correspondencia/GetCliente',
        DELETE_CLIENTE: '/api/v2/Correspondencia/DeleteCliente',
        ADD_CLIENTE: '/api/v2/Correspondencia/AddCliente',
        ADD_HISTORICO: '/api/v2/Correspondencia/AddHistorico',
        GET_CASAS: '/api/v2/Correspondencia/GetCasas',
        SALVAR_CLIENTE: '/api/v2/Correspondencia/SalvarCliente',
        AVANCAR: '/api/v2/Correspondencia/Avancar',
        CHECKLIST: '/api/v2/Correspondencia/CheckList',
        PERSPECTIVA_CHANGE: '/api/v2/Correspondencia/PerspectivaChange',
        DESISTIR: '/api/v2/Correspondencia/Desistir',
        GET_CLIENTES_PERSPECTIVA: '/api/v2/Correspondencia/GetClientesPerspectiva',
        GET_ENGENHARIAS: '/api/v2/Correspondencia/GetEngenharias',
        SAIR_PERSPECTIVA: '/api/v2/Correspondencia/SairPerspectiva',
        APROVADOS_MES: '/api/v2/Correspondencia/AprovadosMes',
        NOVOS_CADASTROS_MES: '/api/v2/Correspondencia/NovosCadastrosMes',
        PENDENTES_APROVACAO: '/api/v2/Correspondencia/PendetesAprovacao',
        ENGENHARIAS_MES: '/api/v2/Correspondencia/EngenhairiasMes',
        FUNIL_STATUS: '/api/v2/Correspondencia/FunilStatus',
        CONTRATADO_MES: '/api/v2/Correspondencia/ContratadoMes',
        CONTRATADO_MES_ATUAL: '/api/v2/Correspondencia/ContratadoMesAtual',
        NOVOS_CADASTROS_MES_ATUAL: '/api/v2/Correspondencia/NovosCadastrosMesAtual',
        APROVADOS_MES_ATUAL: '/api/v2/Correspondencia/AprovadosMesAtual',
    },
    CORRETORES: {
        GET_CORRETORES: '/api/v2/Corretores/GetCorretores',
        ADD_CORRETOR: '/api/v2/Corretores/SalvarCorretor',
        DELETE_CORRETOR: '/api/v2/Corretores/DeleteCorretor',
    },
    DASHBOARD: {
        GET_CORRETORES: '/api/v2/Dashboard/GetCorretores',
        GET_MEDIA: '/api/v2/Dashboard/GetMedia',
        GET_PRIMEIRA_PARCELA: '/api/v2/Dashboard/GetPrimeiraParcela',
        GET_AREA: '/api/v2/Dashboard/GetArea',
        GET_CLIENTESOBRA: '/api/v2/Dashboard/GetObras',
    },
    DIARIOS: {
        GET_DIARIOS: '/api/v2/Diarios/GetDiarios',
        SALVAR_DIARIO: '/api/v2/Diarios/SalvarDiario',
        GET_RESPONSAVEIS: '/api/v2/Diarios/GetResponsaveis',
        GET_AUDITORES: '/api/v2/Diarios/getAuditores',
        GET_MESTRES: '/api/v2/Diarios/GetMestres',
        GET_DIARIO: '/api/v2/Diarios/GetDiario',
        GET_DIARIOS_OBRA: '/api/v2/Diarios/GetDiariosObra',
        POST_FILE: '/api/v2/Diarios/PostFile',
        DELETE_DIARIO: '/api/v2/Diarios/DeleteDiario',
    },
    EMPRESAS_LEGALIZACAO: {
        GET_EMPRESAS: '/api/v2/EmpresasLegalizacao/GetEmpresas',
        SALVAR_EMPRESA: '/api/v2/EmpresasLegalizacao/SalvarEmpresa',
        DELETE_EMPRESA: '/api/v2/EmpresasLegalizacao/DeleteEmpresa',
    },
    EMPRESAS_FATURAMENTO: {
        GET_EMPRESAS: '/api/v2/EmpresasFaturamento/GetEmpresasFaturamento',
        UPDATE_EMPRESA: '/api/v2/EmpresasFaturamento/SalvarEmpresaFaturamento',
        DELETE_EMPRESA: '/api/v2/EmpresasFaturamento/DeleteEmpresaFaturamento',
    },
    EMPREENDIMENTOS: {
        GET_EMPREENDIMENTOS: '/api/v2/Empreendimentos/GetEmpreendimentos',
        GET_INVESTIDORES_FULL: '/api/v2/Empreendimentos/GetInvestidoresFull',
        GET_EMPREENDIMENTO: '/api/v2/Empreendimentos/GetEmpreendimento',
        DELETE_PROCESSO: '/api/v2/Empreendimentos/DeleteProcesso',
        REMOVER_INVESTIMENTO: '/api/v2/Empreendimentos/RemoverInvestimento',
        STEP_PROCESSO: '/api/v2/Empreendimentos/StepProcesso',
        CHANGE_PROCESSO: '/api/v2/Empreendimentos/ChangeProgresso',
        ADD_PROCESSO: '/api/v2/Empreendimentos/AddProcesso',
        SALVAR_EMPREENDIMENTO: '/api/v2/Empreendimentos/SalvarEmpreendimento',
        DELETE_EMPREENDIMENTO: '/api/v2/Empreendimentos/DeleteEmpreendimento',
        GET_GALERIA: '/api/v2/Galeria/GetGaleria',
        POST_FILE: '/api/v2/Empreendimentos/PostFile',
        DELETE_GALERIA: '/api/v2/Empreendimentos/DeleteGaleria',
        GET_INVESTIDORES: '/api/v2/Empreendimentos/GetInvestidores',
        SALVAR_INVESTIMENTO: '/api/v2/Empreendimentos/SalvarInvestimento',
        ADD_INVESTIMENTO: '/api/v2/Empreendimentos/AddInvestimento',
        GET_LANCAMENTOS: '/api/v2/Empreendimentos/GetLancamentos',
        ADD_LANCAMENTO: '/api/v2/Empreendimentos/AddLancamento',
        DELETE_LANCAMENTO: '/api/v2/Empreendimentos/DeleteLancamento',
    },
    FISICO: {
        GET_FISICO: '/api/v2/Fisico/GetFisico',
        GET_REALIZADO_MES: '/api/v2/Fisico/getRealizadoMes',
        GET_AVANCOS: '/api/v2/Fisico/GetAvancos',
        SALVAR_FISICO: '/api/v2/Fisico/salvarFisico',
        GET_FISICOS: '/api/v2/Fisico/getFisicos',
        GET_AVANCOS_FISICOS: '/api/v2/Fisico/getAvancosFisicos',
        UPDATE_AVANCO_FISICO: '/api/v2/Fisico/updateAvancoFisico',
        GET_CATEGORIA_ITENS: '/api/v2/Fisico/getCategoriaItens',
    },
    FORNECEDOR: {
        GET_FORNECEDORES: '/api/v2/Fornecedores/GetFornecedores',
        SALVAR_FORNECEDOR: '/api/v2/Fornecedores/SalvarFornecedor',
        DELETE_FORNECEDOR: '/api/v2/Fornecedores/DeleteFornecedor',
        POST_FILE: '/api/v2/Fornecedores/PostFile',
        DOWNLOAD: '/api/v2/Fornecedores/Download',
        DELETE_FILE: '/api/v2/Fornecedores/DeleteArquivo',
        GET_ARQUIVOS: '/api/v2/Fornecedores/GetArquivos',
    },
    GALERIA: {
        GET_GALERIA: '/api/v2/Galeria/GetGaleria',
        ADD_GALERIA: '/api/v2/Galeria/AddGaleria',
        DELETE_GALERIA: '/api/v2/Galeria/DeleteGaleria',
    },
    GRUPOS: {
        GET_GRUPOS_ORCAMENTO: '/api/v2/Grupos/GetGruposOrcamento',
        GET_GRUPOS: '/api/v2/Grupos/GetGrupos',
        GET_GRUPOS_OC: '/api/v2/Grupos/GetGruposOC',
        SALVAR_GRUPO: '/api/v2/Grupos/SalvarGrupo',
        DELETE_GRUPO: '/api/v2/Grupos/DeleteGrupo',
        BLOQUEAR_GRUPO: '/api/v2/Grupos/BloquearGrupo',
        EDITAR_NOME: '/api/v2/Grupos/EditarGrupoNome',
    },
    INVESTIDOR: {
        GET_INVESTIMENTOS: '/api/v2/Investidor/GetInvestimentos',
        GET_RELATORIO: '/api/v2/Investidor/GetRelatorio',
    },
    LEGALIZACAO: {
        GET_LEGALIZACAO: '/api/v2/Legalizacao/GetLegalizacao',
        ADD_LEGALIZACAO: '/api/v2/Legalizacao/AddLegalizacao',
        DELETE_LEGALIZACAO: '/api/v2/Legalizacao/DeleteLegalizacao',
        CHANGE_ORDER: '/api/v2/Legalizacao/ChangeOrder',
        CHANGE_PROGRESSO: '/api/v2/Legalizacao/ChangeProgresso',
    },
    LOTEAMENTOS: {
        GET_LOTEAMENTOS: '/api/v2/Loteamentos/GetLoteamentos',
        SALVAR_LOTEAMENTO: '/api/v2/Loteamentos/SalvarLoteamento',
        DELETE_LOTEAMENTO: '/api/v2/Loteamentos/DeleteLoteamento',
    },
    MATERIAIS: {
        GET_MATERIAIS: '/api/v2/Materiais/GetMateriais',
        GET_MATERIAIS_SERVER: '/api/v2/Materiais/GetMateriaisServer',
        GET_MATERIAIS_LIST: '/api/v2/Materiais/GetMateriaisList',
        SALVAR_MATERIAL: '/api/v2/Materiais/SalvarMaterial',
        DELETE_MATERIAL: '/api/v2/Materiais/DeleteMaterial',
    },
    MESSAGES: {
        GET_GRUPOS: '/api/v2/Notificacoes/getGrupos',
        GET_GRUPOS_USER: '/api/v2/Notificacoes/getGruposUser',
        JOIN_GROUP: '/api/v2/Notificacoes/joinGroup',
        GET_NEW_NOTIFICATION: '/api/v2/Notificacoes/getNewNotification',
        SET_LAST_READ: '/api/v2/Notificacoes/setLastRead',
        GET_LAST_READ: '/api/v2/Notificacoes/getLastRead',
        SET_MESSAGE_READ: '/api/v2/Notificacoes/SetMessageRead',
        SET_MESSAGE_UNREAD: '/api/v2/Notificacoes/setMessageUnread',
        GET_ONLINE_USERS: '/api/v2/Notificacoes/getOnlineUsers',
        CHECK_ALL_READ: '/api/v2/Notificacoes/checkAllRead',
    },
    METAS: {
        GET_METAS: '/api/v2/Metas/GetMetas',
        GET_ORCAMENTOS: '/api/v2/Metas/GetOrcamentos',
        SALVAR_META: '/api/v2/Metas/SalvarMeta',
        DELETE_META: '/api/v2/Metas/deleteMeta',
        BLOQUEAR_GRUPO: '/api/v2/Metas/bloquearGrupo',
        BLOQUEAR_META: '/api/v2/Metas/bloquearMeta',
    },
    MODALIDADES: {
        GET_MODALIDADES: '/api/v2/Modalidades/GetModalidades',
        SALVAR_MODALIDADE: '/api/v2/Modalidades/SalvarModalidade',
        DELETE_MODALIDADE: '/api/v2/Modalidades/DeleteModalidade',
    },
    ORCAMENTOS: {
        GET_CATEGORIAS: '/api/v2/Categorias/GetCategoriasOrcamento',
        GET_GRUPOS: '/api/v2/Grupos/GetOrcamentoGrupos',
        GET_ORCAMENTOS_SERVER: '/api/v2/Orcamentos/GetOrcamentosServer',
        GET_ORCAMENTOS_OC: '/api/v2/Orcamentos/GetOrcamentosOC',
        GET_ORCAMENTOS_CLEAN: '/api/v2/Orcamentos/GetOrcamentosClean',
        GET_ORCAMENTOS: '/api/v2/Orcamentos/GetOrcamentos',
        GET_ORCAMENTOS_LIST: '/api/v2/Orcamentos/GetOrcamentosList',
        GET_ORCAMENTO_ITENS_ORDEM: '/api/v2/Orcamentos/GetOrcamentoItensOrdem',
        GET_ORCAMENTO_ITENS_GERAL_ORDEM: '/api/v2/Orcamentos/GetOrcamentoItensGeralOrdem',
        GET_MENSAL: '/api/v2/Orcamentos/GetMensal',
        GET_ORCAMENTO: '/api/v2/Orcamentos/GetOrcamento',
        ATUALIZAR_MASSA: '/api/v2/Orcamentos/AtualizaMassa',
        GET_ORCAMENTO_REALIZADO: '/api/v2/Orcamentos/GetOrcamentoRealizado2',
        GET_ORCAMENTO_ITEM: '/api/v2/Orcamentos/GetOrcamentoItem',
        GET_ORCAMENTO_ITENS: '/api/v2/Orcamentos/GetOrcamentoItens',
        BLOQUEAR_ORCAMENTO: '/api/v2/Orcamentos/BloquearOrcamento',
        BLOQUEAR_ITEM: '/api/v2/Orcamentos/BloquearItem',
        ATIVAR_ORCAMENTO: '/api/v2/Orcamentos/AtivarOrcamento',
        SALVAR_ORCAMENTO: '/api/v2/Orcamentos/SalvarOrcamento',
        SALVAR_DESCRICAO: 'api/v2/Orcamentos/SalvarDescricao',
        ADD_ITEM: '/api/v2/Orcamentos/AddItem',
        DELETE_ITEM: '/api/v2/Orcamentos/DeleteItem',
        DELETE_ORCAMENTO: '/api/v2/Orcamentos/DeleteOrcamento',
        COPY_ORCAMENTO: '/api/v2/Orcamentos/CopyOrcamento',
        ADD_APROVADOR: '/api/v2/Orcamentos/AddAprovador',
        REMOVE_APROVADOR: '/api/v2/Orcamentos/RemoveAprovador',
        GET_COMPRAS_ITEM: '/api/v2/Orcamentos/GetComprasitem',
        SOLICITAR_TRANSFERENCIA: '/api/v2/Ordens/SolicitarTransferencia',
        ADD_COMPOSICAO_ORCAMENTO: '/api/v2/Orcamentos/AddComposicaoOrcamento',
        GET_DASHBOARD: '/api/v2/Orcamentos/GetDashboard',
    },
    FOLHAS: {
        SALVAR_FOLHA_ITENS: '/api/v2/Folhas/SalvarFolhaItens',
        ADD_ITEM_FOLHA: '/api/v2/Folhas/AddItemFolha',
        REMOVE_ITEM_FOLHA: '/api/v2/Folhas/RemoveItemFolha',
        ADD_COLABORADOR_FOLHA: '/api/v2/Folhas/AddColaboradorFolha',
        REMOVE_COLABORADOR_FOLHA: '/api/v2/Folhas/RemoveColaboradorFolha',
        SALVAR_FOLHA_STEP1: '/api/v2/Folhas/SalvarStep1',
        SUBMETER_FOLHA: '/api/v2/Folhas/SubmeterFolha',
        SALVAR_FOLHA_NOVA: '/api/v2/Folhas/SalvarFolhaNova',
        GET_FOLHAS: '/api/v2/Folhas/GetFolhas',
        GET_FOLHAS_PENDENTES: '/api/v2/Folhas/GetFolhasPendentes',
        GET_FOLHA: '/api/v2/Folhas/GetFolha',
        GET_FOLHA_MEDICAO: '/api/v2/Folhas/GetFolhaNova',
        CANCELAR_FOLHA: '/api/v2/Folhas/CancelarFolha',
        PAGAR_FOLHA: '/api/v2/Folhas/PagarFolha',
        APROVAR_FOLHA: '/api/v2/Folhas/AprovarFolha',
        SALVAR_FOLHA: '/api/v2/Folhas/SalvarFolha',
        DASHBOARD: '/api/v2/Folhas/DashboardFolhas',
    },
    ORDENS: {
        MATERIAL_RECEBIDO: '/api/v2/Ordens/MaterialEntregue',
        ADD_ITEM: '/api/v2/Ordens/AddItemOrdem',
        SAVE_DESCONTO: '/api/v2/Ordens/SaveDesconto',
        SAVE_JUSTIFICATIVA: '/api/v2/Ordens/SaveJustificativa',
        SAVE_DESCRICAO: '/api/v2/Ordens/SaveDescricao',
        SAVE_OBSERVACAO: '/api/v2/Ordens/SaveObservacao',
        DELETE_ITEM: '/api/v2/Ordens/DeleteItemOrdem',
        SALVAR_ORDEM_STEP1: '/api/v2/Ordens/SalvarOrdemStep1',
        SALVAR_ORDEM_STEP2: '/api/v2/Ordens/SalvarOrdemStep2',
        SALVAR_ORDEM_STEP3: '/api/v2/Ordens/SalvarOrdemStep3',
        SALVAR_ORDEM_NOVA: '/api/v2/Ordens/CriarOrdem',
        GET_NOVA_ORDEM: '/api/v2/Ordens/GetNovaOrdem',
        GET_SOLICITANTES: '/api/v2/Ordens/getSolicitantes',
        SUBMETER_ORDEM: '/api/v2/Ordens/SubmeterOrdem',
        COPY_ORDEM: '/api/v2/Ordens/CopyOrdem',
        GET_ORDENS: '/api/v2/Ordens/GetOrdens',
        GET_TRANSFERENCIAS: '/api/v2/Ordens/GetTransferencias',
        GET_ORDENS_APROVAR: '/api/v2/Ordens/GetOrdensAprovar',
        ADD_SALVAR: '/api/v2/Ordens/SalvarOrdem',
        DELETE_ORDEM: '/api/v2/Ordens/DeleteOrdem',
        GET_ORDEM: '/api/v2/Ordens/GetOrdem',
        GET_ORDEM_EDIT: '/api/v2/Ordens/GetOrdemEdit',
        GET_ORDENS_ITENS: '/api/v2/Ordens/GetOrdensItens',
        CANCELAR_ORDEM: '/api/v2/Ordens/CancelarOrdem',
        CANCELAR_TRANSFERENCIA: '/api/v2/Ordens/CancelarTransferencia',
        PAGAR_ORDEM: '/api/v2/Ordens/PagarOrdem',
        PROCESSAR_TRANSFERENCIA: '/api/v2/Ordens/ProcessarTransferencia',
        CONFIRMAR_DOCUMENTACAO: '/api/v2/Ordens/ConfirmarDocumentacao',
        APROVAR_ORDEM: '/api/v2/Ordens/AprovarOrdem',
        APROVAR_TRANSFERENCIA: '/api/v2/Ordens/AprovarTransferencia',
        AJUSTE_NF: '/api/v2/Ordens/AjustaNF',
        ENVIAR_ORDEM: '/api/v2/Ordens/EnviarOrdem',
        EXCLUIR_ITEM_ORDEM: '/api/v2/Ordens/ExcluirItemOrdem',
        FINALIZAR_ORDEM: '/api/v2/Ordens/FinalizarOrdem',
        SOLICITAR_PAGAMENTO: '/api/v2/Ordens/SolicitarPagamento',
        PAGAR_PARCIAL: '/api/v2/Ordens/PagarParcial',
        POST_FILE: '/api/v2/Ordens/PostFile',
        DOWNLOAD_FILE: '/api/v2/Ordens/Download',
        DELETE_NF_ORDEM: '/api/v2/Ordens/DeleteNotaFiscal',
        EDIT_NF_ORDEM: '/api/v2/Ordens/EditNotaFiscal',
        ADD_NF_ORDEM: '/api/v2/Ordens/AddNotaFiscal',
        DELETE_ARQUIVO: '/api/v2/Ordens/DeleteArquivo',
        CHECK_SALDO: '/api/v2/Ordens/CheckSaldo',
        COMPILAR: '/api/v2/Ordens/CompilarArquivosOrdem',
        GET_ORDENS_SERVER: '/api/v2/Ordens/GetOrdensServer',
    },
    OBRA: {
        GET_OBRA: '/api/v2/Obra/GetObra',
        ADD_OBRA: '/api/v2/Obra/AddObra',
        DELETE_OBRA: '/api/v2/Obra/DeleteObra',
        CHANGE_ORDER: '/api/v2/Obra/ChangeOrder',
        CHANGE_PROGRESSO: '/api/v2/Obra/ChangeProgresso',
    },
    PARCEIROS: {
        GET_PARCEIROS: '/api/v2/Parceiros/GetParceiros',
        SALVAR_PARCEIRO: '/api/v2/Parceiros/SalvarParceiro',
        DELETE_PARCEIRO: '/api/v2/Parceiros/DeleteParceiro',
    },
    PATRIMONIOS: {
        GET_PATRIMONIO: '/api/v2/Patrimonios/GetPatrimonio',
        GET_PATRIMONIOS: '/api/v2/Patrimonios/GetPatrimonios',
        SALVAR_PATRIMONIO: '/api/v2/Patrimonios/SalvarPatrimonio',
        DELETE_PATRIMONIO: '/api/v2/Patrimonios/DeletePatrimonio',
    },
    PERFILS: {
        GET_PERFIS: '/api/v2/Role/GetRolesFull',
        GET_PERMISSIONS: '/api/v2/Role/GetPermissions',
        SALVAR_PERFIL: '/api/v2/Role/SalvarPerfil',
        ADD_ROLE: '/api/v2/Role/AddRole',
        DELETE_PERFIL: '/api/v2/Role/DeletePerfil',
        ADD_PERMISSION_TO_ROLE: '/api/v2/Role/AddPermissionToRole2',
    },
    PLANEJAMENTOS: {
        GET_ORCAMENTOS: '/api/v2/Planejamentos/GetOrcamentos',
        GET_PLANEJAMENTOS: '/api/v2/Planejamentos/GetPlanejamentos',
        LOAD_MODELO: '/api/v2/Planejamentos/LoadModelo',
        NOVO_PLANEJAMENTO: '/api/v2/Planejamentos/NovoPlanejamento',
        GET_CASA: '/api/v2/Planejamentos/GetCasa',
        GET_PLANEJAMENTO: '/api/v2/Planejamentos/GetPlanejamento',
        NOVA_MEDICAO: '/api/v2/Planejamentos/NovaMedicao',
        GET_MEDICOES: '/api/v2/Planejamentos/GetMedicoes',
        MODIFICA_MEDICAO: '/api/v2/Planejamentos/ModificaMedicao',
        DELETE_MEDICAO: '/api/v2/Planejamentos/DeleteMedicao',
    },
    PRODUTOS: {
        GET_PRODUTOS: '/api/v2/Produtos/GetProdutos',
        SALVAR_PRODUTO: '/api/v2/Produtos/SalvarProduto',
        DELETE_PRODUTO: '/api/v2/Produtos/DeleteProduto',
    },
    TRANSFERENCIAS: {
        GET_TRANSFERENCIA_VIEW: '/api/v2/Transferencias/GetTransferenciaView',
        GET_TRANSFERENCIA: '/api/v2/Transferencias/GetTransferencia',
        GET_TRANSFERENCIAS: '/api/v2/Transferencias/GetTransferencias',
        FINALIZAR: '/api/v2/Transferencias/FinalizarTransferencia',
        SUBMETER: '/api/v2/Transferencias/SubmeterTransferencia',
        CANCELAR: '/api/v2/Transferencias/CancelarTransferencia',
        APROVAR: '/api/v2/Transferencias/AprovarTransferencia',
        PENDENTES: '/api/v2/Transferencias/GetPendentes',
        SALVAR: '/api/v2/Transferencias/SalvarTransferencia',
    },
    USER: {
        LOGIN: '/api/v2/Usuarios/Login',
        CHECK: '/api/v2/Usuarios/Check',
        GET_APROVADORES: '/api/v2/Usuarios/GetAprovadores',
        FORGOT_PASSWORD: '/api/v2/Usuarios/ForgotPassword',
        RESET_PASSWORD: '/api/v2/Usuarios/ResetPassword',
        DELETE_USER_CASA: '/api/v2/Usuarios/DeleteUser',
        CHANGE_PASSWORD: '/api/v2/Usuarios/ChangePassword',
        SALVAR_CONFIGURACAO: '/api/v2/Configuracao/SalvarConfiguracao',
        GET_CONFIGURACAO: '/api/v2/Configuracao/GetConfiguracao',
        GET_MAILS_SETTINGS: '/api/v2/Configuracao/GetMailSettings',
        SALVAR_MAILS_SETTINGS: '/api/v2/Configuracao/SalvarMailSettings',
        GET_TIPOS_TAREFAS: '/api/v2/Tarefas/GetTiposTarefas',
    },
    USUARIOS: {
        GET_USUARIOS: '/api/v2/Usuarios/GetUsers',
        ADD_USER_TO_ROLE: '/api/v2/Role/AddUserToRole',
        UPDATE_USUARIO: '/api/v2/Usuarios/SalvarUser',
        ADD_USER: '/api/v2/Usuarios/AddUser',
        DELETE_USER: '/api/v2/Usuarios/DeleteUser',
    },
    PEDIDOS: {
        GET_ALL: '/api/v2/Pedidos/Pedidos',
        DELETE: '/api/v2/Pedidos/Delete',
        PEDIDO: '/api/v2/Pedidos/Pedido',
        SAVE: '/api/v2/Pedidos/Save',
        FINALIZAR: '/api/v2/Pedidos/Finalizar',
        APROVAR: '/api/v2/Pedidos/Aprovar',
        CANCELAR: '/api/v2/Pedidos/Cancelar',
        PENDENTES: '/api/v2/Pedidos/Pendentes',
        ITENS: '/api/v2/Pedidos/ItensPedido',
        PEDIDO_ITENS: '/api/v2/Pedidos/GetPedidoItens',
        PEDIDO_ITENS_OBRAS: '/api/v2/Pedidos/GetPedidoItensObras',
        GERAR_PEDIDO_ITENS: '/api/v2/Pedidos/GerarPedidosItens',
        GERAR_PEDIDO_OBRAS: '/api/v2/Pedidos/GerarPedidosObras',
    },
};
