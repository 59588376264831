import { Table, Button, Popconfirm, Tooltip, Switch, Space } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  ChangeOrder,
  CheckList,
  GetCliente,
  GetClientesPerspectiva,
  SairPerspectiva,
} from '@actions/correspondenciaActions';
import { filterobject } from '@utils/filterandsort';
import { moneyMask } from '@utils/masks';
import {
  DeleteOutlined,
  MenuOutlined,
  WarningTwoTone,
  ProfileOutlined,
  ClockCircleTwoTone,
  PrinterOutlined,
} from '@ant-design/icons';
import { SetPageHeader } from '@actions/userActions';
import moment from 'moment';
import PrintPage from '../PrintPage';
import FichaPop from './FichaPop';
import { GetLoteamentos } from '@actions/loteamentosActions';
import { GetCorretores } from '@actions/corretoresActions';
import { GetCCAs } from '@actions/ccasActions';
import { GetAgencias } from '@actions/agenciasActions';
import { GetModalidades } from '@actions/modalidadesActions';
import { GetParceiros } from '@actions/parceirosActions';
import { GetProdutos } from '@actions/produtosActions';
import { CSS } from '@dnd-kit/utilities';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { ClienteCorrespondencia } from '@models/ClienteCorrespondencia';
import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';

function PerspectivaView() {
  const dispatch = useAppDispatch();
  const { loadingclientes, clientesp } = useAppSelector((state) => state.correspondencia);
  const [ficha, setFicha] = useState<{ ficha: ClienteCorrespondencia; visible: boolean }>({
    ficha: null,
    visible: false,
  });
  const printRef = useRef();
  const columns: ColumnsType<ClienteCorrespondencia> = [
    {
      key: 'dragHandle',
      dataIndex: 'index',
      width: 30,
      align: 'center',
      className: 'drag-visible',
      render: () => <MenuOutlined style={{ cursor: 'pointer' }} />,
    },
    {
      align: 'center',
      width: 60,
      render: (_, __, index) => index + 1,
    },
    {
      align: 'center',
      width: 80,
      render: (_, row) => (
        <Space>
          <Tooltip title={`Vencimento do crédito: ${moment(row.vencimentoCredito).format('DD/MM/YYYY')}`}>
            {moment(row.vencimentoCredito).diff(moment(new Date()), 'days') <= 30 &&
            moment(row.vencimentoCredito).diff(moment(new Date()), 'days') >= 0 ? (
              <ClockCircleTwoTone twoToneColor="orange" />
            ) : moment(row.vencimentoCredito).diff(moment(new Date()), 'days') <= 30 ? (
              <WarningTwoTone twoToneColor="red" />
            ) : null}
          </Tooltip>
          <Tooltip title="Ficha do Cliente">
            <Button
              icon={<ProfileOutlined />}
              size="small"
              onClick={() => {
                setFicha({ visible: true, ficha: row });
                dispatch(GetCliente(row.id));
              }}
            />
          </Tooltip>
          <Tooltip title="Remover da lista">
            <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(SairPerspectiva(row.id))}>
              <Button size="small" icon={<DeleteOutlined />} />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 100,
    },
    {
      title: 'Proponente',
      dataIndex: 'clienteNome1',
      width: 150,
      className: 'drag-visible',
    },
    {
      title: 'CPF',
      dataIndex: 'clienteCPF1',
      width: 120,
    },
    {
      title: 'Modalidade',
      dataIndex: 'modalidade',
      width: 110,
    },
    {
      title: 'Município',
      dataIndex: 'municipio',
      width: 110,
    },
    {
      title: 'Parceiro',
      dataIndex: 'parceiro',
      width: 100,
    },
    {
      title: 'Agência',
      dataIndex: 'agencia',
      width: 100,
    },
    {
      title: 'Engenharia',
      dataIndex: 'clSolicitarEngenharia',
      width: 120,
      align: 'center',
      sortOrder: 'descend',
      render: (text, row) => (
        <Switch
          size="small"
          checked={text}
          onChange={(v) => dispatch(CheckList({ ID: row.id, value: v, field: 'clSolicitarEngenharia' }))}
        />
      ),
    },
    {
      title: 'Doc Vendedor',
      dataIndex: 'clDocumentosVendedorSistema',
      width: 100,
      align: 'center',
      sortOrder: 'descend',
      render: (text, row) => (
        <Switch
          size="small"
          checked={text}
          onChange={(v) => dispatch(CheckList({ ID: row.id, value: v, field: 'clDocumentosVendedorSistema' }))}
        />
      ),
    },
    {
      title: 'SIOPI',
      dataIndex: 'clSIOPI',
      align: 'center',
      width: 80,
      sortOrder: 'descend',
      render: (text, row) => (
        <Switch
          size="small"
          checked={text}
          onChange={(v) => dispatch(CheckList({ ID: row.id, value: v, field: 'clSIOPI' }))}
        />
      ),
    },
    {
      title: 'PF3',
      dataIndex: 'clPF3',
      align: 'center',
      width: 80,
      sortOrder: 'descend',
      render: (text, row) => (
        <Switch
          size="small"
          checked={text}
          onChange={(v) => dispatch(CheckList({ ID: row.id, value: v, field: 'clPF3' }))}
        />
      ),
    },
    {
      title: 'Valor Financiado',
      dataIndex: 'valorFinanciamento',
      width: 170,
      align: 'right',
      render: (text) => moneyMask.format(text),
    },
  ];
  useEffect(() => {
    dispatch(GetClientesPerspectiva());
    dispatch(GetLoteamentos());
    dispatch(GetCorretores());
    dispatch(GetCCAs());
    dispatch(GetAgencias());
    dispatch(GetModalidades());
    dispatch(GetParceiros());
    dispatch(GetProdutos());
  }, []);

  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Correspondência',
        backbutton: true,
        extras: [
          <Link to="/PrintPerspectiva" key={0} target="_blank">
            <Button size="small" type="primary" ghost style={{ width: 100 }} icon={<PrinterOutlined />}>
              Imprimir
            </Button>
          </Link>,
        ],
        subtitle: 'Perspectiva de Contratação',
        menu: 'perspectiva',
      })
    );
  }, [clientesp, printRef, PrintPage]);

  const [itens, SetItens] = useState(filterobject(clientesp, '').filter((c) => c.perspectiva === true));
  const [activeId, setActiveId] = useState(null);
  useEffect(() => {
    SetItens(clientesp.sort((a, b) => a.index - b.index));
  }, [clientesp]);
  const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));
  function handleDragStart(event: any) {
    const { active } = event;
    setActiveId(active.id);
  }

  function handleDragEnd(event: any) {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const oldIndex = itens.findIndex((item) => item.index === active.id);
      const newIndex = itens.findIndex((item) => item.index === over.id);
      const newData = arrayMove(itens, oldIndex, newIndex).map((c, i) => ({
        ...c,
        index: i + 1,
      }));
      dispatch(ChangeOrder({ Lista: newData.map((c) => ({ ID: c.id, Index: c.index })) }));
      SetItens(newData);
    }
    // Stop overlay.
    setActiveId(null);
  }
  const sortbleItems = useMemo(() => clientesp?.map(({ id }) => id), [clientesp]);
  function DraggableWrapper(props: any) {
    const { children, ...restProps } = props;
    /**
     * 'children[1]` is `dataSource`
     * Check if `children[1]` is an array
     * because antd gives 'No Data' element when `dataSource` is an empty array
     */
    return children[1] instanceof Array ? (
      <SortableContext items={sortbleItems} strategy={verticalListSortingStrategy}>
        <tbody {...restProps}>
          {
            // This invokes `Table.components.body.row` for each element of `children`.
            children
          }
        </tbody>
      </SortableContext>
    ) : (
      <tbody {...restProps}>{children}</tbody>
    );
  }
  const RowOverlay = () => (
    <DragOverlay className="ant-table-row ant-table-row-level-0">
      <Table
        columns={columns}
        rowKey="index"
        size="small"
        showHeader={false}
        style={{ boxShadow: '1px 1px 3px' }}
        dataSource={itens.filter((_, index) => index === activeId - 1)}
        pagination={false}
      />
    </DragOverlay>
  );
  function DraggableRow(props: any) {
    const { attributes, listeners, setNodeRef, transform, transition, isOver } = useSortable({
      id: props['data-row-key'],
    });
    const { children, ...restProps } = props;
    /**
     * 'children[1]` is a row of `dataSource`
     * Check if `children[1]` is an array
     * because antd gives 'No Data' element when `dataSource` is an empty array
     */
    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };

    if (isOver) {
      const el = document.getElementById(`row-key-${activeId}`);
      return (
        <tr {...restProps} style={{ height: el?.clientHeight }}>
          <td colSpan={children?.length}></td>
        </tr>
      );
    }
    //if (isDragging) return <RowOverlay />;
    return children instanceof Array ? (
      <tr ref={setNodeRef} {...attributes} {...restProps} style={style} id={`row-key-${props['data-row-key']}`}>
        {children.map((child) => {
          const { children, key, ...restProps } = child;
          return key === 'dragHandle' ? (
            <td {...restProps} {...listeners} q>
              {child}
            </td>
          ) : (
            <td {...restProps}>{child}</td>
          );
        })}
      </tr>
    ) : (
      <tr {...restProps}>{children}</tr>
    );
  }
  return (
    <div>
      <FichaPop visible={ficha.visible} setVisible={setFicha} />
      <DndContext
        sensors={sensors}
        //collisionDetection={closestCorners}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis]}
      >
        <Table
          columns={columns}
          rowKey="index"
          size="small"
          loading={loadingclientes}
          dataSource={filterobject(itens, '').filter((c) => c.perspectiva === true)}
          pagination={false}
          scroll={{ y: 'calc(100vh - 280px)', x: 1324 }}
          components={{
            body: {
              wrapper: DraggableWrapper,
              row: DraggableRow,
            },
          }}
          summary={(pagedata) => (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={3}>
                  <strong>TOTAL CONTRATOS: {pagedata.length}</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} colSpan={10} align="right">
                  <strong>TOTAL À FINANCIAR:</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} colSpan={2} align="right">
                  <strong>{moneyMask.format(pagedata.reduce((a, b) => a + b.valorFinanciamento, 0))}</strong>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
        <RowOverlay />
      </DndContext>
    </div>
  );
}

export default PerspectivaView;
