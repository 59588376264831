import { SetPageHeader } from '@actions/userActions';
import { useAppDispatch } from '@config/reduxStore';
import { IFolhaForm } from '@models/Folha';
import { Card, Form, Input, Space, Steps } from 'antd';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FolhaFormColaboradores from './FolhaForm.Colaboradores';
import FolhaFormGeral from './FolhaForm.Geral';
import FolhaFormNavigation from './FolhaForm.Navigation';
import FolhaFormOrcamento from './FolhaForm.Orcamento';
import FolhaFormRateio from './FolhaForm.Rateio';
import FolhaFormResumoFinal from './FolhaForm.ResumoFinal';
import FolhaActions from './Shared/FolhaActions';
import { useFolhaForm } from './useFolhaForm';

const steps = [
  {
    key: 0,
    title: 'Geral',
    content: <FolhaFormGeral />,
  },
  {
    key: 1,
    title: 'Colaboradores',
    content: <FolhaFormColaboradores />,
  },

  {
    key: 2,
    title: 'Orçamento',
    content: <FolhaFormOrcamento />,
  },
  {
    key: 3,
    title: 'Rateio',
    content: <FolhaFormRateio />,
  },
  {
    key: 4,
    title: 'Resumo',
    content: <FolhaFormResumoFinal />,
  },
];

const FolhaForm = () => {
  const { step, goto, form, save, next, numSteps, folha } = useFolhaForm();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const handleSubmit = (values: IFolhaForm) => {
    if (step < numSteps - 1) {
      save(values);
      next();
    } else {
      save(values);
    }
  };
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Folha de Medição',
        subtitle: 'Nova folha de medição de obra',
        extras: [],
        backbutton: true,
        menu: 'folhas',
      })
    );
  }, []);
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={{ rateio: [], itens: [] }}
      scrollToFirstError
    >
      <Form.Item name="id" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="cod" hidden>
        <Input />
      </Form.Item>
      <Steps
        current={step}
        items={steps.map((c) => ({ title: c.title, key: c.key, disabled: true }))}
        onChange={(v) => {
          if ((step + 1 === v || v < step) && !!id) {
            if (v > step) {
              if (step === 0 || step === 3) {
                form.submit();
              } else goto(v);
            } else goto(v);
          }
        }}
      />
      <Card
        style={{ marginTop: 10 }}
        size="small"
        title={`${steps[step].title} ${folha?.orcamento ? ' - ' + folha?.orcamento : ''}`}
        extra={
          <Space direction="horizontal">
            <FolhaFormNavigation />
            {step === 4 && <FolhaActions />}
          </Space>
        }
      >
        <div>{steps[step].content}</div>
      </Card>
    </Form>
  );
};

export default FolhaForm;
