import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Input, DatePicker, Button, Select } from 'antd';
import { FilterConfirmProps, FilterDropdownProps } from 'antd/lib/table/interface';
import React from 'react';

export const naturalSorter = (a: string, b: string) => {
  if (a === null) a = '';
  if (b === null) b = '';
  return a.localeCompare(b, undefined, {
    numeric: true,
    sensitivity: 'base',
  });
};
export const sortAlpha = (a: string, b: string) => {
  if (a == null && b == null) return 0;
  if (a == null && b) return -1;
  if (b == null && a) return 1;
  if (a > b) return 1;
  else if (a < b) return -1;
  else return 0;
};
export const orderDate = (a: Date | moment.Moment, b: Date | moment.Moment) =>
  moment(moment(a).isValid() ? moment(a) : moment(0)).unix() -
  moment(moment(b).isValid() ? moment(b) : moment(0)).unix();
export const orderDateDesc = (a: Date | moment.Moment, b: Date | moment.Moment) =>
  moment(moment(b).isValid() ? moment(a) : moment(0)).unix() -
  moment(moment(a).isValid() ? moment(b) : moment(0)).unix();

const handleSearch = (confirm: (param?: FilterConfirmProps | undefined) => void) => {
  confirm();
};

export const getColumnSearchPropsServer = (
  dataIndex: string,
  tipo: 'string' | 'daterange' | 'mes' | 'customselect',
  title: string,
  filters: any,
  filterdata?: any[],
  searchref?: any
) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => (
    <div style={{ padding: 8 }}>
      {tipo === 'string' && (
        <Input
          ref={searchref}
          placeholder={`Buscar ${title}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
      )}
      {tipo === 'daterange' && (
        <DatePicker.RangePicker
          format="DD/MM/YYYY"
          value={selectedKeys[0] as unknown as [moment.Moment, moment.Moment]}
          onChange={(v) => setSelectedKeys(v ? ([v] as unknown as React.Key[]) : [])}
          style={{ marginBottom: 8 }}
        />
      )}
      {tipo === 'mes' && (
        <DatePicker.MonthPicker
          format="MM/YYYY"
          value={selectedKeys[0] as unknown as moment.Moment}
          onChange={(v) => setSelectedKeys(v ? ([v] as unknown as React.Key[]) : [])}
          style={{ marginBottom: 8 }}
        />
      )}
      <Button
        type="primary"
        onClick={() => handleSearch(confirm)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Buscar
      </Button>
      <Button
        onClick={() => {
          clearFilters();
          setSelectedKeys([]);
          confirm();
        }}
        size="small"
        style={{ width: 90 }}
      >
        Limpar
      </Button>
      {tipo === 'customselect' && (
        <Select
          showSearch
          allowClear
          mode="multiple"
          value={selectedKeys}
          optionFilterProp="children"
          placeholder={`Buscar ${title}`}
          onChange={(v) => setSelectedKeys(v ? v : [])}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        >
          {filterdata.map((c) => (
            <Select.Option value={c.value}>{c.text}</Select.Option>
          ))}
        </Select>
      )}
    </div>
  ),
  onFilterDropdownVisibleChange: (visible: boolean) => {
    if (visible) {
      setTimeout(() => searchref.current.focus(), 100);
    }
  },
  filteredValue: filters ? filters[dataIndex] : null,
  filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  //onFilter: (value:any, record:any) => filterValue(value, record[dataIndex], tipo),
});

export const getColumnSearchProps = (
  dataIndex: string | string[],
  tipo: string,
  title: string,
  filters?: any,
  filterdata?: any[]
) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => (
    <div style={{ padding: 8 }}>
      {tipo === 'string' && (
        <Input
          placeholder={`Buscar ${title}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
      )}
      {tipo === 'daterange' && (
        <DatePicker.RangePicker
          format="DD/MM/YYYY"
          value={selectedKeys[0] as unknown as [moment.Moment, moment.Moment]}
          onChange={(v) => setSelectedKeys(v ? ([v] as unknown as React.Key[]) : [])}
          style={{ marginBottom: 8 }}
        />
      )}
      {tipo === 'mes' && (
        <DatePicker.MonthPicker
          format="MM/YYYY"
          value={selectedKeys[0] as unknown as moment.Moment}
          onChange={(v) => setSelectedKeys(v ? ([v] as unknown as React.Key[]) : [])}
          style={{ marginBottom: 8 }}
        />
      )}
      <Button
        type="primary"
        onClick={() => {
          handleSearch(confirm);
        }}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Buscar
      </Button>
      <Button
        onClick={() => {
          clearFilters();
          setSelectedKeys([]);
          confirm();
        }}
      >
        Limpar
      </Button>
      {tipo === 'customselect' && (
        <Select
          showSearch
          allowClear
          mode="multiple"
          value={selectedKeys}
          optionFilterProp="children"
          placeholder={`Buscar ${title}`}
          onChange={(v) => setSelectedKeys(v ? v : [])}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        >
          {filterdata.map((c: any) => (
            <Select.Option key={c.value} value={c.value}>
              {c.text}
            </Select.Option>
          ))}
        </Select>
      )}
    </div>
  ),
  filteredValue: filters
    ? typeof dataIndex === 'object'
      ? filters?.filters[dataIndex.toString().replace(',', '.')]
      : filters?.filters[dataIndex]
    : null,
  filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value: any, record: any) =>
    filterValue(value, typeof dataIndex === 'object' ? record[dataIndex[0]] : record[dataIndex], tipo),
});

export const filterValue = (filterValue: any, value: any, tipo: any) => {
  if (tipo === 'string' || tipo === 'customselect') {
    return value ? value.toString().normalize().includes(filterValue.normalize()) : false;
  } else if (tipo === 'daterange') {
    if (moment(value).isValid()) {
      if (moment(value).isAfter(filterValue[0]) && moment(value).isBefore(filterValue[1])) return true;
      else return false;
    } else return false;
  } else if (tipo === 'mes') {
    return moment(value).isValid()
      ? moment(value).month() === moment(filterValue).month() && moment(value).year() === moment(filterValue).year()
      : false;
  }
};
interface Data {
  [key: string]: any;
}
export function filterobject<Type extends Data>(data: Type[], value: string): Type[] {
  if (!value) return data;
  else {
    let filtered: Type[] = [];
    let values = value.split(' ');
    data.forEach((c) => {
      const keys = Object.keys(c);
      if (
        values.every((v) => {
          return keys.some((k) => {
            if (k === 'id') return false;
            else {
              if (String(c[k]).normalize().includes(v.normalize())) return true;
              else return false;
            }
          });
        })
      )
        filtered.push(c);
    });

    return filtered;
  }
}

Object.defineProperty(String.prototype, 'normalize', {
  value: function normalize() {
    return this.toLowerCase()
      .replace(/á|à|ã|â/, 'a')
      .replace(/ç/, 'c')
      .replace(/é|è|ê/, 'e')
      .replace(/í|ì|ê/, 'i')
      .replace(/ó|ò|õ|ô/, 'o')
      .replace(/ú|ù|û/, 'u');
  },
  writable: true,
  configurable: true,
});
