import LoadingText from '@components/LoadingText';
import { Col, Row } from 'antd';
import { useOrdemForm } from '../useOrdemForm';
import OrdemResumoFinalArquivos from '../../Shared/OrdemArquivos';
import OrdemResumoFinalFornecedor from './OrdemForm.ResumoFinal.Fornecedor';
import OrdemResumoFinalHeader from './OrdemForm.ResumoFinal.Header';
import OrdemResumoFinalHistorico from '../../Shared/OrdemHistorico';
import OrdemResumoFinalItens from './OrdemForm.ResumoFinal.Itens';
import OrdemResumoFinalPagamento from './OrdemForm.ResumoFinal.Pagamento';
import OrdemResumoFinalPagamentos from '../../Shared/OrdemPagamentos';

function OrdemResumoFinal() {
  const { ordem } = useOrdemForm();
  if (ordem === null) return <LoadingText text="Carregando" />;
  return (
    <div>
      <OrdemResumoFinalHeader />
      <OrdemResumoFinalFornecedor />
      <OrdemResumoFinalPagamento />
      <OrdemResumoFinalItens />
      <Row gutter={[8, 8]}>
        <Col span={8}>
          <OrdemResumoFinalHistorico />
        </Col>
        <Col span={8}>
          <OrdemResumoFinalArquivos />
        </Col>
        <Col span={8}>
          <OrdemResumoFinalPagamentos />
        </Col>
      </Row>
    </div>
  );
}

export default OrdemResumoFinal;
