import { useState, useEffect, useRef } from 'react';
import { Table, Input, Button, DatePicker, Tooltip, Modal, Checkbox, Row, Col } from 'antd';
import { filterobject, sortAlpha, getColumnSearchProps, orderDate } from '@utils/filterandsort';
import { GetParceiros } from '@actions/parceirosActions';
import { GetClientesMes } from '@actions/correspondenciaActions';
import { ProfileOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { SetPageHeader } from '@actions/userActions';
import { moneyMask, moneyNegMask } from '@utils/masks';
import { GetAgencias } from '@actions/agenciasActions';
import { GetCCAs } from '@actions/ccasActions';
import PrintPage from '../PrintPage';
import ReactToPrint from 'react-to-print';
import { setCookie, getCookie } from '@services/localStorage';
import { ClienteCorrespondencia } from '@models/ClienteCorrespondencia';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { FilterDropdownProps } from 'antd/lib/table/interface';

function ContratosMes() {
  const [filter, setfilter] = useState('');
  const [showCols, setShowCols] = useState(false);
  const dispatch = useAppDispatch();

  const { loadingclientes, clientes } = useAppSelector((state) => state.correspondencia);
  const { parceiros } = useAppSelector((state) => state.parceiros);
  const { agencias } = useAppSelector((state) => state.agencias);
  const { corretores } = useAppSelector((state) => state.corretores);
  const { loteamentos } = useAppSelector((state) => state.loteamentos);
  const { modalidades } = useAppSelector((state) => state.modalidades);
  const { ccas } = useAppSelector((state) => state.ccas);
  const printRef = useRef();
  useEffect(() => {
    dispatch(GetClientesMes());
    dispatch(GetParceiros());
    dispatch(GetAgencias());
    dispatch(GetCCAs());
    dispatch(
      SetPageHeader({
        title: 'Correspondência',
        backbutton: true,
        extras: [],
        subtitle: 'Contratos do Mês',
        menu: 'contratosmes',
      })
    );
  }, []);
  const cookievalues = JSON.parse(getCookie('contratosmesfilters') || null) || {
    filters: { competencia: [moment(new Date())] },
  };
  const [filtermes, setfiltermes] = useState(cookievalues);
  const columns: any = [
    { fixed: 'left', width: 40, render: (_: any, __: any, index: number) => index + 1 },
    {
      fixed: 'left',
      width: 50,
      dataIndex: 'id',
      render: (_: any, row: any) => (
        <div>
          <Tooltip title="Ficha">
            <Link to={`/ClientesCorrespondencia/${row.id}`}>
              <Button size="small" icon={<ProfileOutlined />} />
            </Link>
          </Tooltip>
        </div>
      ),
    },
    {
      title: 'Cliente',
      dataIndex: 'clienteNome1',
      visible: true,
      allowed: true,
      sorter: (a: any, b: any) => sortAlpha(a.clienteNome1, b.clienteNome1),
      ...getColumnSearchProps('clienteNome1', 'string', 'Cliente', filtermes),
    },
    {
      title: 'CPF',
      dataIndex: 'clienteCPF1',
      visible: true,
      allowed: true,
      sorter: (a: any, b: any) => sortAlpha(a.clienteCPF1, b.clienteCPF1),
      ...getColumnSearchProps('clienteCPF1', 'string', 'CPF', filtermes),
    },
    {
      title: 'Agência',
      dataIndex: 'agencia',
      visible: true,
      allowed: true,
      filteredValue: filtermes ? filtermes.filters.agencia : null,
      sorter: (a: any, b: any) => sortAlpha(a.agencia, b.agencia),
      filters: agencias.map((co) => ({
        value: co.banco + ' - ' + co.nome + '(' + co.numero + ')',
        text: co.banco + ' - ' + co.nome + '(' + co.numero + ')',
      })),
      onFilter: (value: any, row: ClienteCorrespondencia) => row.agencia === value,
    },
    {
      title: 'Modalidade',
      dataIndex: 'modalidadeCredito',
      visible: true,
      allowed: true,
      filteredValue: filtermes ? filtermes.filters.modalidadeCredito : null,
      sorter: (a: any, b: any) => sortAlpha(a.modalidadeCredito, b.modalidadeCredito),
      filters: modalidades.map((c) => ({ value: c.descricao, text: c.descricao })),
      onFilter: (value: any, row: ClienteCorrespondencia) => row.modalidadeCredito.includes(value),
    },
    {
      title: 'Parceiro',
      dataIndex: 'parceiro',
      visible: true,
      allowed: true,
      filteredValue: filtermes ? filtermes.filters.parceiro : null,
      sorter: (a: any, b: any) => sortAlpha(a.parceiro, b.parceiro),
      filters: parceiros.map((c) => ({ value: c.nome, text: c.nome })),
      onFilter: (value: any, row: ClienteCorrespondencia) => row.parceiro.includes(value),
    },
    {
      title: 'Data Assinatura',
      dataIndex: 'dataAssinatura',
      visible: true,
      allowed: true,
      sorter: (a: any, b: any) => orderDate(a.dataAssinatura, b.dataAssinatura),
      ...getColumnSearchProps('dataAssinatura', 'daterange', 'Data Assinatura', filtermes),
      render: (text: any) => (moment(text).isValid() ? moment(text).format('DD/MM/YYYY') : ''),
    },
    {
      title: 'Loteamento',
      dataIndex: 'loteamento',
      visible: true,
      allowed: true,
      filteredValue: filtermes ? filtermes.filters.loteamento : null,
      sorter: (a: any, b: any) => sortAlpha(a.loteamento, b.loteamento),
      filters: loteamentos.map((c) => ({ value: c.nome, text: c.nome })),
      onFilter: (value: any, row: ClienteCorrespondencia) => row.loteamento.includes(value),
    },
    {
      title: 'CCA',
      dataIndex: 'cca',
      visible: true,
      allowed: true,
      filteredValue: filtermes ? filtermes.filters.cca : null,
      sorter: (a: any, b: any) => sortAlpha(a.cca, b.cca),
      filters: ccas.map((co) => ({
        value: co.nome,
        text: co.nome,
      })),
      onFilter: (value: any, row: ClienteCorrespondencia) => row.cca.includes(value),
    },
    {
      title: 'Parceiro',
      dataIndex: 'corretor',
      visible: true,
      allowed: true,
      filteredValue: filtermes ? filtermes.filters.corretor : null,
      sorter: (a: any, b: any) => sortAlpha(a.corretor, b.corretor),
      filters: corretores.map((c) => ({ value: c.nome, text: c.nome })),
      onFilter: (value: any, row: ClienteCorrespondencia) => row.corretor.includes(value),
    },
    {
      title: 'Valor Financimento',
      dataIndex: 'valorFinanciamento',
      visible: true,
      allowed: true,
      sorter: (a: any, b: any) => a.valorFinanciamento - b.valorFinanciamento,
      render: (text: any) => moneyMask.format(text),
      ...getColumnSearchProps('valorFinanciamento', 'string', 'Valor Financiamento', filtermes),
    },
    {
      title: 'Competência',
      dataIndex: 'competencia',
      visible: true,
      allowed: true,
      sorter: (a: any, b: any) => sortAlpha(a.competencia, b.competencia),
      render: (text: any) => (moment(text).isValid() ? moment(text).format('MMM YYYY') : ''),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }: FilterDropdownProps) => {
        return (
          <div style={{ padding: 8 }}>
            <DatePicker.MonthPicker
              format="MM/YYYY"
              onChange={(v) => {
                setfiltermes({
                  ...filtermes,
                  filters: { ...filtermes.filters, competencia: [v] },
                });
                setCookie(
                  'contratosmesfilters',
                  JSON.stringify({
                    ...filtermes,
                    filters: { ...filtermes.filters, competencia: [v] },
                  })
                );
                setSelectedKeys(v ? ([v] as any) : []);
                confirm();
              }}
              allowClear={false}
              style={{ marginBottom: 8 }}
              value={moment(selectedKeys[0])}
            />
          </div>
        );
      },
      filteredValue: filtermes ? filtermes.filters.competencia : null,
      filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value: string, record: ClienteCorrespondencia) =>
        moment(record.competencia).isValid()
          ? moment(record.competencia).year() === moment(value).year() &&
            moment(record.competencia).month() === moment(value).month()
          : false,
    },
  ];
  const cookievalues2 = JSON.parse(getCookie('colunasclientesMes') || '[]') || [];

  const [visibleCols, setVisibleCols] = useState(
    cookievalues2.length > 0 ? cookievalues2 : columns.filter((c: any) => c.visible).map((c: any) => c.dataIndex)
  );
  return (
    <div>
      <div style={{ textAlign: 'right' }}>
        <ReactToPrint
          trigger={() => (
            <Button size="small" type="primary" ghost style={{ width: 100 }}>
              Imprimir
            </Button>
          )}
          content={() => printRef.current}
        />
        <div style={{ display: 'none' }}>
          <PrintPage
            ref={printRef}
            title={`Contratos do Mês - ${
              filtermes ? moment(filtermes.filters.competencia[0]).format('MMM/YYYY') : null
            }`}
            dataSource={filterobject(clientes, filter)}
            columns={columns}
          />
        </div>
        <DatePicker.MonthPicker
          size="small"
          style={{ width: 100 }}
          placeholder="Competência"
          format="MMM YYYY"
          value={filtermes ? moment(filtermes.filters.competencia[0]) : null}
          defaultValue={moment(new Date())}
          allowClear={false}
          onChange={(v) => {
            setCookie(
              'contratosmesfilters',
              JSON.stringify({
                ...filtermes,
                filters: { ...filtermes.filters, competencia: [v] },
              })
            );
            setfiltermes({ ...filtermes, filters: { competencia: [v] } });
          }}
        />
        <Input
          size="small"
          style={{ width: 400 }}
          onChange={(e) => setfilter(e.target.value)}
          placeholder="Buscar"
          suffix={
            <Button size="small" onClick={() => setShowCols(true)}>
              Selecionar Colunas
            </Button>
          }
        />
      </div>
      <Modal
        width={800}
        open={showCols}
        title="Mostrar Colunas"
        onCancel={() => setShowCols(false)}
        footer={[
          <Button key="1" onClick={() => setShowCols(false)}>
            Fechar
          </Button>,
        ]}
      >
        <Checkbox.Group
          value={visibleCols}
          onChange={(value) => {
            setVisibleCols([...value, 'id']);
            setCookie('colunasclientesMes', JSON.stringify([...value, 'id']));
          }}
        >
          <Row>
            {columns
              .filter((c: any) => c.allowed === true)
              .map((c: any, index: number) => (
                <Col key={index} span={8}>
                  <Checkbox value={c.dataIndex}>{c.title}</Checkbox>
                </Col>
              ))}
          </Row>
        </Checkbox.Group>
      </Modal>
      <Table
        size="small"
        rowKey="id"
        loading={loadingclientes}
        onChange={(pagination, filters, sorter) => {
          setfiltermes({ pagination, filters, sorter });
          setCookie('contratosmesfilters', JSON.stringify({ pagination, filters, sorter }));
        }}
        dataSource={filterobject(clientes, filter)}
        columns={columns.filter((c: any) => visibleCols.find((d: any) => c.dataIndex === d))}
        pagination={{
          position: ['bottomRight'],
          size: 'small',
          pageSize: 35,
          showSizeChanger: true,
          pageSizeOptions: [25, 50, 100],
          showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
        }}
        summary={(data) => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={9} align="right">
                <strong>TOTAL</strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                <strong>{moneyNegMask.format(data.reduce((a: any, b: any) => a + b.valorFinanciamento, 0))}</strong>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </div>
  );
}

export default ContratosMes;
