import { useEffect } from 'react';
import { Table, Progress, Divider } from 'antd';
import { GetLegalizacao } from '@actions/legalizacaoActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { ProcessoEmpreendimento } from '@models/ProcessoEmpreendimento';

function TableLegalizacaoReport() {
  const dispatch = useAppDispatch();
  const legalizacao = useAppSelector((state) => state.legalizacao);
  const { user } = useAppSelector((state) => state.user);
  useEffect(() => {
    dispatch(GetLegalizacao(user.casaid));
  }, [user.casaid]);
  return (
    <div>
      <Divider orientation="left">Progresso de Legalização</Divider>

      <Table pagination={false} loading={legalizacao.loading} size="small" rowKey="id" dataSource={legalizacao.items}>
        <Table.Column title="Item" render={(_, __, index) => index + 1} />
        <Table.Column title="Descrição" dataIndex="descricao" />
        <Table.Column
          width={150}
          title="Progresso"
          dataIndex="progresso"
          render={(_, row: ProcessoEmpreendimento) => <Progress percent={row.progresso} />}
        />
      </Table>
    </div>
  );
}

export default TableLegalizacaoReport;
