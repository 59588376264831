import { apiEndpoints } from '@constants/apiEndpoints';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Axios } from '@config/axios';

export const getAuditorias = createAsyncThunk(
  'auditorias/getAuditorias',
  async () => await Axios.get(apiEndpoints.AUDITORIAS.GET_AUDITORIAS)
);
export const salvarAuditoria = createAsyncThunk(
  'auditorias/salvarAuditoria',
  async (data: any) => await Axios.post(apiEndpoints.AUDITORIAS.SALVAR_AUDITORIA, data)
);
export const getResponsaveis = createAsyncThunk(
  'auditorias/getResponsaveis',
  async () => await Axios.get(apiEndpoints.AUDITORIAS.GET_RESPONSAVEIS)
);

export const getMestres = createAsyncThunk(
  'auditorias/getMestres',
  async () => await Axios.get(apiEndpoints.AUDITORIAS.GET_MESTRES)
);
export const getObras = createAsyncThunk(
  'auditorias/getObras',
  async () => await Axios.get(apiEndpoints.AUDITORIAS.GET_OBRAS)
);
export const getAuditoria = createAsyncThunk(
  'auditorias/getAuditoria',
  async (id: number | string) => await Axios.get(apiEndpoints.AUDITORIAS.GET_AUDITORIA, { params: { id } })
);
export const getAuditoriasObra = createAsyncThunk(
  'auditorias/getAuditoriasObra',
  async (id: number) => await Axios.get(apiEndpoints.AUDITORIAS.GET_AUDITORIAS_OBRA, { params: { id } })
);
export const saveFiles = createAsyncThunk(
  'auditorias/saveFiles',
  async (form: FormData) => await Axios.post(apiEndpoints.AUDITORIAS.POST_FILE, form)
);

export const deleteAuditoria = createAsyncThunk(
  'auditorias/deleteAuditoria',
  async (id: number) => await Axios.delete(apiEndpoints.AUDITORIAS.DELETE_AUDITORIA, { params: { id } })
);

export const responderAuditoria = createAsyncThunk(
  'auditorias/responderAuditoria',
  async (data: { id: number | string; resposta: string }) =>
    await Axios.post(apiEndpoints.AUDITORIAS.RESPONDER_AUDITORIA, data)
);
export const getAuditoriasPendentes = createAsyncThunk(
  'auditorias/getAuditoriasPendentes',
  async () => await Axios.get(apiEndpoints.AUDITORIAS.GET_AUDITORIAS_PENDENTES)
);
