import { DatePicker, Form, Input, Modal, Select } from 'antd';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { salvarContrato } from '@actions/colaboradoresActions';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { ContratoFornecedor } from '@models/ContratoFornecedor';

function ContratosModal({
  visible,
  setVisible,
  fornecedorID,
  id,
}: {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<{ visible: boolean; fornecedorID: number; id: number | null }>>;
  fornecedorID: number;
  id: number;
}) {
  const { colaboradores } = useAppSelector((state) => state.colaboradores);
  const [form] = Form.useForm<ContratoFornecedor>();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!id) form.resetFields();
    form.setFieldsValue({ id: id, fornecedorID: fornecedorID });
    if (id) {
      const contrato = colaboradores.find((c) => c.id === fornecedorID).contratos.find((c) => c.id === id);
      form.setFieldsValue({
        ...contrato,
        dataAdmissao: moment(contrato.dataAdmissao).isValid() ? moment(contrato.dataAdmissao) : null,
        dataInicio: moment(contrato.dataInicio).isValid() ? moment(contrato.dataInicio) : null,
        dataFinal: moment(contrato.dataFinal).isValid() ? moment(contrato.dataFinal) : null,
      });
    }
  }, [fornecedorID, id, form, colaboradores]);
  return (
    <Modal
      open={visible}
      onCancel={() => setVisible({ visible: false, fornecedorID: null, id: null })}
      title="Adicionar Contrato"
      onOk={() => form.submit()}
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        onFinish={(values) => {
          dispatch(salvarContrato({ ...values, id: id || 0 }));
          setVisible({ visible: false, id: null, fornecedorID: null });
          form.resetFields();
        }}
      >
        <Form.Item name="id" label="ID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="fornecedorID" label="FornecedorID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="tipoContrato" label="Tipo Contrato" rules={[{ required: true, message: 'Campo obrigatório' }]}>
          <Select>
            <Select.Option value="Obra Certa">Obra Certa</Select.Option>
            <Select.Option value="Normal">Normal</Select.Option>
            <Select.Option value="Outro">Outro</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="dataAdmissao" label="Data Admissão" rules={[{ required: true, message: 'Campo obrigatório' }]}>
          <DatePicker format="DD/MM/YYYY" />
        </Form.Item>
        <Form.Item name="dataInicio" label="Data Início">
          <DatePicker format="DD/MM/YYYY" />
        </Form.Item>
        <Form.Item name="dataFinal" label="Data Final">
          <DatePicker format="DD/MM/YYYY" />
        </Form.Item>
        <Form.Item name="obra" label="Obra">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ContratosModal;
