import { useEffect, useState } from 'react';
import { Table, Input, Button, Tooltip, Modal, Form, Typography, Tag } from 'antd';
import { InfoOutlined, EyeOutlined, StopOutlined, FormOutlined, CheckOutlined } from '@ant-design/icons';
import { getColumnSearchProps, sortAlpha, filterobject, orderDate, naturalSorter } from '@utils/filterandsort';
import { GetAprovadores, SetPageHeader } from '@actions/userActions';
import { Permission } from '@hooks//usePermissions';
import { Link } from 'react-router-dom';
import { moneyMask, doubleMask, moneyNegMask } from '@utils/masks';
import moment from 'moment';
import { GetMateriais } from '@actions/materiaisActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { ColumnsType } from 'antd/lib/table';
import { OrdemStatus, ordemType } from '@constants/ordemStatus';
import { AprovarTransferencia, GetPendentes } from '@actions/transferenciasActions';
import { TransferenciaList } from '@models/Transferencia';
import { ViewCancelForm } from '@actions/novaOrdemActions';
import CancelTransferenciaModalRedux from './Shared/CancelTransferenciaModalRedux';

function TransferenciasAprovar() {
  const dispatch = useAppDispatch();
  const { transferenciasPendentes, loadingPendentes } = useAppSelector((state) => state.transferencias);
  const [filter, setFilter] = useState<string>('');
  const [filters, setFilters] = useState<any>(null);
  const [motivomodal, setmotivomodal] = useState({ visible: false, motivo: '' });

  useEffect(() => {
    dispatch(GetPendentes());
    dispatch(GetMateriais());
    dispatch(GetAprovadores(ordemType.transferencia));
    dispatch(
      SetPageHeader({
        title: 'Engenharia',
        backbutton: true,
        extras: [],
        subtitle: 'Ordens de Transferência a Aprovar',
        menu: 'aprovartransferencias',
      })
    );
  }, []);
  const columns: ColumnsType<TransferenciaList> = [
    {
      width: 50,
      fixed: 'left',
      render: (_, row) => (
        <div>
          {!row.cancelada && (
            <>
              <Permission permission="ordem.aprove">
                <Tooltip title="Aprovar Ordem">
                  <Button
                    onClick={() => dispatch(AprovarTransferencia(row.id))}
                    icon={<CheckOutlined />}
                    size="small"
                  />
                </Tooltip>
              </Permission>
              <Tooltip title="Imprimir">
                <Link to={`/PrintOrdemTransferencia/${row.id}`}>
                  <Button icon={<EyeOutlined />} size="small" />
                </Link>
              </Tooltip>
              <Tooltip title="Cancelar Ordem">
                <Button
                  icon={<StopOutlined />}
                  size="small"
                  onClick={() => dispatch(ViewCancelForm({ view: true, id: row.id, replace: false, cod: row.cod }))}
                />
              </Tooltip>
            </>
          )}
          {row.cancelada && (
            <>
              <Link to={`/PrintOrdemTransferencia/${row.id}`}>
                <Button icon={<EyeOutlined />} size="small" />
              </Link>
              <Button
                icon={<InfoOutlined />}
                size="small"
                onClick={() => setmotivomodal({ visible: true, motivo: row.motivo })}
              />
            </>
          )}
        </div>
      ),
    },
    {
      title: 'Nº Ordem',
      dataIndex: 'id',
      sorter: (a, b) => naturalSorter(a.cod, b.cod),
      render: (_, row) => row.cod,
      ...getColumnSearchProps('cod', 'string', 'Nº Ordem', filters),
    },
    {
      title: 'Situação',
      width: 200,
      dataIndex: 'situacao',
      sorter: (a, b) => sortAlpha(a.situacao, b.situacao),
      filters: [
        { text: 'Em processamento financeiro', value: 'Em processamento financeiro' },
        { text: 'Pendente de aprovação', value: 'Pendente de aprovação' },
        { text: 'Cancelada', value: 'Cancelada' },
        { text: 'Finalizada', value: 'Finalizada' },
      ],
      onFilter: (value, row) => row.situacao.includes(value as string),
      filteredValue: filters ? filters.filters.situacao : null,
      render: (text) => (
        <Tag
          color={
            text === OrdemStatus.EmProcessamentoFinanceiro
              ? 'orange'
              : text === OrdemStatus.PendenteAprovacao
              ? 'default'
              : text === OrdemStatus.Finalizada
              ? 'green'
              : text === OrdemStatus.Cancelada
              ? 'red'
              : 'default'
          }
          icon={text === OrdemStatus.Rascunho ? <FormOutlined /> : null}
        >
          {text}
        </Tag>
      ),
    },
    {
      title: 'Descrição',
      dataIndex: 'descricao',
      ...getColumnSearchProps('descricao', 'string', 'Descrição', filters),
    },
    {
      title: 'Solicitante',
      dataIndex: 'comprador',
      ...getColumnSearchProps('comprador', 'string', 'Solicitante', filters),
    },
    {
      title: 'Data Criação',
      dataIndex: 'dataCriacao',
      sorter: (a, b) => orderDate(a.dataCriacao, b.dataCriacao),
      ...getColumnSearchProps('dataCriacao', 'daterange', 'Data Criação', filters),
      render: (text) => moment(text).format('DD/MM/YYYY'),
    },
    {
      title: 'Origem',
      dataIndex: 'origem',
      sorter: (a, b) => sortAlpha(a.origem, b.origem),
      ...getColumnSearchProps('orcamento', 'string', 'Orcamento', filters),
    },
    {
      title: 'Valor',
      dataIndex: 'total',
      sorter: (a: any, b: any) => a.total - b.total,
      ...getColumnSearchProps('total', 'string', 'Valor', filters),
      render: (text) => moneyNegMask.format(text),
    },
  ];
  return (
    <div>
      <CancelTransferenciaModalRedux />
      <Modal
        title="Motivo Cancelamento"
        open={motivomodal.visible}
        footer={[<Button onClick={() => setmotivomodal({ visible: false, motivo: '' })}>Fechar</Button>]}
        onCancel={() => setmotivomodal({ visible: false, motivo: '' })}
      >
        <Typography.Text>{motivomodal.motivo}</Typography.Text>
      </Modal>
      <Form wrapperCol={{ span: 8 }} labelCol={{ span: 3 }}>
        <Form.Item label="Busca Geral">
          <Input onChange={(e) => setFilter(e.target.value)} placeholder="Buscar" allowClear />
        </Form.Item>
      </Form>
      <Table
        size="small"
        rowKey="id"
        onChange={(paginantion, filters, sorter) => setFilters({ paginantion, filters, sorter })}
        loading={loadingPendentes}
        columns={columns}
        dataSource={filterobject(transferenciasPendentes, filter)}
        scroll={{ y: 'calc(100vh - 300px)', x: 1200 }}
        pagination={{
          position: ['bottomRight'],
          size: 'small',
          showSizeChanger: true,
          pageSizeOptions: [10, 25, 50, 100],
          showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
        }}
        expandedRowRender={(row) => (
          <div className="ordem" style={{ overflowX: 'auto' }}>
            <table style={{ width: '100%', fontSize: 10 }}>
              <tbody>
                <tr>
                  <th colSpan={9}>Itens</th>
                </tr>
                <tr>
                  <th style={{ width: 30 }}></th>
                  <th>Orçamento</th>
                  <th>Categoria</th>
                  <th>Grupo</th>
                  <th>Item</th>
                  <th>V. Unitário</th>
                  <th>Quantidade</th>
                  <th>V. Total</th>
                </tr>
                {row.ordemItens.map((c, index) => (
                  <tr key={index} className={c.estourado ? 'naoorcado' : null}>
                    <td>{index + 1}</td>
                    <td>{c.orcamento as string}</td>
                    <td>{c.categoria as string}</td>
                    <td>{c.grupo as string}</td>
                    <td>{c.item as string}</td>
                    <td>{moneyMask.format(c.valorUnitario)}</td>
                    <td>{doubleMask.format(c.quantidade)}</td>
                    <td>{moneyNegMask.format(c.valorTotal)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      />
    </div>
  );
}
export default TransferenciasAprovar;
