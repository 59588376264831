import { ReactNode, useEffect, useState } from 'react';
import { Layout, Affix, Spin, Menu, PageHeader, Badge } from 'antd';
import NavBar from './NavBar';
import HomeView from './Home/HomeView';
import { Check } from '@actions/userActions';
import { Routes, Link, Route, useLocation } from 'react-router-dom';
import ProfileView from './Account/ProfileView';
import logo from '../assets/img/main.png';
import logofacilitar from '../assets/img/logofacilitar.png';
import {
  FormOutlined,
  SettingOutlined,
  HomeOutlined,
  ToolOutlined,
  TeamOutlined,
  BankOutlined,
  UnorderedListOutlined,
  ShoppingCartOutlined,
  ReconciliationOutlined,
  AuditOutlined,
  FileOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import DashboardView from './Sistema/Home/DashboardView';
import CasasView from './Sistema/Casas/CasasView';
import EmpreendimentosView from './Sistema/Cadastros/Empreendimentos/EmpreendimentosView';
import LoteamentosView from './Sistema/Cadastros/Loteamentos/LoteamentosView';
import AgenciasView from './Sistema/Cadastros/Agencias/AgenciasView';
import CorretoresView from './Sistema/Cadastros/Corretores/CorretoresView';
import ParceirosView from './Sistema/Cadastros/Parceiros/ParceirosView';
import FichaNova from './Sistema/Casas/FichaNova';
import ChamadoView from './Sistema/Chamados/ChamadoView';
import UsuariosView from './Sistema/Usuarios/UsuariosView';
import { HasPermission, HasRole } from '@hooks//usePermissions';
import ChamadoForm from './Sistema/Chamados/ChamadoForm';
import PerfisView from './Sistema/Perfis/PerfisView';
import EmpreendimentoControl from './Sistema/Cadastros/Empreendimentos/EmpreendimentoControl';
import DashboardClienteObra from './Sistema/ClienteObra/DashboardClienteObra';
import DashboardInvestidor from './Sistema/Investidor/DashboardInvestidor';
import InvestidoresView from './Sistema/Investidores/InvestidoresView';
import RelatorioMain from './Sistema/Investidor/RelatorioMain';
import ModalidadesView from './Sistema/Cadastros/ModalidadesCredito/ModalidadesView';
import CCAsView from './Sistema/Cadastros/CCAs/CCAsView';
import ProdutosView from './Sistema/Cadastros/Produtos/ProdutosView';
import ClientesView from './Sistema/Correspondencia/ClientesView';
import FichaCliente from './Sistema/Correspondencia/FichaCliente';
import QuadroMain from './Sistema/Correspondencia/QuadroMain';
import ContratosMes from './Sistema/Correspondencia/ContratosMes';
import FornecedoresView from './Sistema/Compras/Fornecedores/FornecedoresView';
import MateriaisView from './Sistema/Compras/Materiais/MateriaisView';
import OrdensView from './Sistema/Compras/Ordens/OrdensView/OrdensView';
import EmpresasFaturamentoView from './Sistema/Compras/EmpresasFaturamento/EmpresasFaturamentoView';
import CategoriasView from './Sistema/Compras/Categorias/CategoriasView';
import OrcamentosView from './Sistema/Compras/Orcamentos/OrcamentosView';
import OrcamentoForm from './Sistema/Compras/Orcamentos/OrcamentoForm';
import OrcamentoView from './Sistema/Compras/Orcamentos/OrcamentoView';
import OrdemPrint from './Sistema/Compras/Ordens/OrdemView/OrdemPrint';
import OrdemPrintTransferencia from './Sistema/Compras/Transferencias/TransferenciaView/OrdemPrintTransferencia';
import OrdemPrintPDF from './Sistema/Compras/Ordens/OrdemView/OrdemPrintPDF';
import OrdensPendencias from './Sistema/Compras/Ordens/Aprovacao/OrdensPendentes';
import TransferenciasView from './Sistema/Compras/Transferencias/TransferenciasView/TransferenciasView';
import TransferenciasAprovar from './Sistema/Compras/Transferencias/TransferenciasAprovar';
import ConfiguracaoView from './Sistema/Configuracao/ConfiguracaoView';
import Icon from '@ant-design/icons';
import FolhasView from './Sistema/Compras/Folhas/FolhasView/FolhasView';
import FolhaView from './Sistema/Compras/Folhas/FolhaView/FolhaView';
import FolhasPendentes from './Sistema/Compras/Folhas/FolhasPendentes';
import NotificacaoView from './Sistema/Notificacao/NotificacaoView';
import PerspectivaView from './Sistema/Correspondencia/PerspectivaView';
import EngenhariasView from './Sistema/Correspondencia/EngenhariasView';
import ContratarView from './Home/ContratarView';
import ModelosView from './Sistema/Configuracao/ModelosView';
import TermoVistoriaEntrega from './Sistema/Casas/TermoVistoriaEntrega';
import TermoRevistoria from './Sistema/Casas/TermoRevistoria';
import TermoChavesManual from './Sistema/Casas/TermoChavesManual';
import OSvisita from './Sistema/Chamados/OSvisita';
import OSencerramento from './Sistema/Chamados/OSencerramento';
import Comunicado from './Sistema/Chamados/Comunicado';
import CartaDeEnc from './Sistema/Chamados/CartaDeEnc';
import EmpresasLegalizacaoView from './Sistema/Cadastros/EmpresasLegalizacao/EmpresasLegalizacaoView';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import SistemaIndisponivelView from './Home/SistemaIndisponivelView';
import ComposicoesView from './Sistema/Compras/Composicoes/ComposicoesView';
import OrcamentoDetails from './Sistema/Compras/Orcamentos/OrcamentoDetails';
import AuditoriaForm from './Sistema/Auditorias/AuditoriaForm';
import AuditoriasView from './Sistema/Auditorias/AuditoriasView';
import AuditoriaView from './Sistema/Auditorias/AuditoriaView';
import PrintAuditoria from './Sistema/Auditorias/PrintAuditoria';
import DashboardFolhas from './Sistema/Compras/Folhas/Dashboard/DashboardFolhas';
import DiarioForm from './Sistema/Diarios/DiarioForm';
import DiariosView from './Sistema/Diarios/DiariosView';
import DiarioView from './Sistema/Diarios/DiarioView';
import PrintDiario from './Sistema/Diarios/PrintDiario';
import { getAuditoriasPendentes } from '@actions/auditoriasAction';
import ColaboradoresView from './Sistema/Compras/Colaboradores/ColaboradoresView';
import RelatorioPagamentos from './Sistema/Compras/Colaboradores/RelatorioPagamentos';
import DashboardOrcamentos from './Sistema/Compras/Orcamentos/DashboardOrcamentos';
import LogoView from './Sistema/Configuracao/LogoView';
import { useNotification } from '@hooks//useNotifications';
import { getLastRead, getNotifications } from '@actions/messagesActions';
import FisicoView from './Sistema/Fisico/FisicoView';
import PlanejamentoObra from './Sistema/Fisico/PlanejamentoObra';
import Lancar from './Sistema/Fisico/Lancar';
import FisicoReport from './Sistema/Fisico/FisicoReport';
import FisicoBanco from './Sistema/Financiamentos/Fisico';
import PlanejamentoObraBanco from './Sistema/Financiamentos/PlanejamentoObra';
import LancarBanco from './Sistema/Financiamentos/Lancar';
import FisicoReportBanco from './Sistema/Financiamentos/FisicoReport';
import OrcamentosBanco from './Sistema/Financiamentos/OrcamentosBanco';
import OrcamentoBancoForm from './Sistema/Financiamentos/OrcamentoBancoForm';
import Medicoes from './Sistema/Financiamentos/Medicoes';
import FluxoObras from './Sistema/Financiamentos/FluxoObras';
import MetasOrcamento from './Sistema/MetasOrcamento/MetasOrcamento';
import JornadaCliente from './Sistema/Correspondencia/JornadaCliente';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { SubscriptionStatus } from '@constants/subscriptions';
import { GetFolhasPendentes } from '@actions/folhasActions';
import { GetOrdensPendentes } from '@actions/novaOrdemActions';
import OrdemForm from './Sistema/Compras/Ordens/OrdemForm/OrdemForm';
import { OrdemFormProvider } from './Sistema/Compras/Ordens/OrdemForm/useOrdemForm';
import FolhaPrintPDF from './Sistema/Compras/Folhas/FolhaPrintPDF';
import FolhaForm from './Sistema/Compras/Folhas/FolhaForm/FolhaForm';
import { FolhaFormProvider } from './Sistema/Compras/Folhas/FolhaForm/useFolhaForm';
import TransferenciaForm from './Sistema/Compras/Transferencias/TranferenciaForm/TransferenciaForm.Main';
import { TransferenciaFormProvider } from './Sistema/Compras/Transferencias/TranferenciaForm/useTransferenciaForm';
import OrdemPrintBody from './Sistema/Compras/Transferencias/TransferenciaView/OrdemPrintBody';
import { GetPendentes } from '@actions/transferenciasActions';
import { GetPendentes as GetPedidosPendentes } from '@actions/pedidosActions';
import PedidosView from './Sistema/Compras/Pedidos/PedidosView/PedidosView';
import PedidoView from './Sistema/Compras/Pedidos/PedidoView/PedidoView';
import PedidoViewBody from './Sistema/Compras/Pedidos/PedidoView/PedidoViewBody';
import PedidosPendentes from './Sistema/Compras/Pedidos/PedidosPendentes/PedidosPendentes';
import PedidoForm from './Sistema/Compras/Pedidos/PedidoForm/PedidoForm';
import DashboardPedidos from './Sistema/Compras/Pedidos/Dashboard/DashboardPedidos';
import useFacilitar from '@hooks/useFacilitar';
import PerspectivaPrint from './Sistema/Correspondencia/Print/PerspectivaPrint';
import DashboardCorrepondencia from './Sistema/Correspondencia/Dashboard/DashboardCorrespondencia';

const { Content, Header, Sider } = Layout;
interface MenuItem {
  title: string | ReactNode;
  key: string;
  type: 'menu' | 'submenu';
  link?: string;
  icon?: ReactNode;
  roles: string[];
  modulo?: string;
  allow?: boolean;
  children?: MenuItem[];
  badge?: boolean;
}

function Main() {
  const { user, paypal } = useAppSelector((state) => state.user);
  const openmenuitems = [];
  const pageheader = useAppSelector((state) => state.user.pageheader);
  const { transferenciasPendentes } = useAppSelector((state) => state.transferencias);
  const { ordensPendentes } = useAppSelector((state) => state.novaOrdem);
  const { folhasPendentes } = useAppSelector((state) => state.folhas);
  const { auditoriasPendentes } = useAppSelector((state) => state.auditorias);
  const { pedidosPendentes } = useAppSelector((state) => state.pedidos);
  const [isActive, SetIsActive] = useState(false);
  const location = useLocation();
  const { connect } = useNotification();
  const dispatch = useAppDispatch();
  const facilitar = useFacilitar();
  const menuitems: MenuItem[] = [
    {
      title: 'Unidades',
      key: 'casas',
      type: 'menu',
      link: '/Casas',
      icon: <HomeOutlined />,
      roles: [
        'financeiro',
        'contabilidade',
        'admin',
        'engenharia',
        'master',
        'sacadmin',
        'compras',
        'contabilidade',
        'contratosac',
        'arquitetura',
      ],
      children: [],
      modulo: 'Básico',
    },
    {
      title: 'Empreendimentos',
      key: 'empreendimentos',
      link: '/Empreendimentos',
      type: 'menu',
      modulo: 'Básico',
      children: [],
      icon: <HomeOutlined />,
      roles: ['admin', 'financeiro', 'master', 'engenharia', 'contabilidade'],
    },
    {
      title: 'Investidores',
      key: 'investidores',
      type: 'menu',
      link: '/Investidores',
      icon: <TeamOutlined />,
      roles: ['financeiro', 'contabilidade', 'admin', 'master'],
      modulo: 'Investidor',
      children: [],
    },
    {
      title: 'SAC',
      key: 'chamados',
      link: '/Chamados',
      type: 'menu',
      icon: <ToolOutlined />,
      modulo: 'SAC',
      roles: ['master', 'admin', 'engenharia', 'comercial', 'compras', 'contratosac'],
      children: [],
    },
    {
      title: 'Planejamento',
      key: 'orcamentosmain',
      type: 'submenu',
      icon: <ReconciliationOutlined />,
      roles: ['master', 'admin', 'financeiro', 'engenharia', 'compras'],
      modulo: 'Compras',
      children: [
        {
          title: 'Orçamentos',
          key: 'orcamentos',
          link: '/Orcamentos',
          type: 'menu',
          roles: ['master', 'admin', 'financeiro', 'engenharia', 'compras'],
          badge: false,
        },
        {
          title: 'Orçamentos Medições',
          key: 'orcamentosbanco',
          link: '/OrcamentosBanco',
          type: 'menu',
          icon: <ReconciliationOutlined />,
          roles: ['master', 'admin', 'financeiro', 'engenharia', 'compras'],
          badge: false,
        },
        {
          title: 'Planejamento Obra',
          key: 'fisico',
          link: '/Fisico',
          type: 'menu',
          roles: ['master', 'admin', 'financeiro', 'engenharia', 'compras'],
          badge: false,
        },
        {
          title: 'Cronogramas Medições',
          key: 'planejamentos',
          link: '/Planejamentos',
          type: 'menu',
          icon: <BankOutlined />,
          children: [],
          modulo: 'Planejamentos',
          roles: ['all'],
        },
        {
          title: 'Fluxo',
          key: 'medicoes',
          link: '/FluxoObra',
          type: 'menu',
          icon: <BankOutlined />,
          children: [],
          modulo: 'Planejamentos',
          roles: ['master', 'financeiro', 'engadmin'],
        },
        {
          title: 'Metas Custo',
          key: 'metas',
          link: '/MetasOrcamento',
          type: 'menu',
          icon: <BankOutlined />,
          children: [],
          modulo: 'Planejamentos',
          roles: ['master', 'engadmin'],
        },
      ],
    },

    {
      title: 'Auditorias',
      key: 'auditorias',
      type: 'menu',
      link: '/Auditorias',
      icon: <AuditOutlined />,
      roles: ['financeiro', 'engenharia', 'admin', 'master'],
      modulo: 'Básico',
      badge: true,
      children: [],
    },
    {
      title: 'Diarios de Obra',
      key: 'diarios',
      type: 'menu',
      link: '/Diarios',
      icon: <FileOutlined />,
      roles: ['financeiro', 'engenharia', 'admin', 'master'],
      modulo: 'Básico',
      children: [],
    },
    {
      title: 'Composições',
      key: 'composicoes',
      link: '/Composicoes',
      type: 'menu',
      icon: <UnorderedListOutlined />,
      roles: ['master', 'admin', 'financeiro', 'engenharia', 'compras'],
      modulo: 'Compras',
      children: [],
    },
    {
      title: 'Aprovações',
      key: 'aprovacoes',
      badge: true,
      type: 'submenu',
      icon: <CheckOutlined />,
      roles: ['master', 'aprovador'],
      modulo: 'Compras',
      children: [
        {
          title: 'Ordens',
          key: 'aprovarordens',
          link: '/Pendencias',
          type: 'menu',
          icon: null,
          roles: ['aprovador'],
          badge: true,
          allow: user.aprovaOrdem,
        },
        {
          title: 'Pedidos',
          key: 'aprovarpedidos',
          link: '/PedidosPendentes',
          type: 'menu',
          icon: null,
          roles: ['aprovador'],
          badge: true,
          allow: user.aprovaPedido,
        },
        {
          title: 'Folhas',
          key: 'aprovarfolhas',
          link: '/FolhasPendentes',
          type: 'menu',
          icon: null,
          roles: ['aprovador'],
          badge: true,
          allow: user.aprovaFolha,
        },
        {
          title: 'Transferências',
          key: 'aprovartransferencias',
          link: '/TransferenciasAprovar',
          type: 'menu',
          icon: null,
          roles: ['aprovador'],
          badge: true,
          allow: user.aprovaTransferencia,
        },
      ],
    },
    {
      title: 'Compras',
      key: 'compras',
      type: 'submenu',
      icon: <ShoppingCartOutlined />,
      roles: ['master', 'admin', 'financeiro', 'engenharia', 'compras'],
      modulo: 'Compras',
      children: [
        {
          title: 'Ordens',
          key: 'ordens',
          link: '/Ordens',
          type: 'menu',
          allow: HasPermission('ordem.list', user.permissions),
          icon: null,
          roles: ['all'],
        },

        {
          title: 'Pedidos',
          key: 'pedidos',
          link: '/Pedidos',
          type: 'menu',
          icon: null,
          allow: HasPermission('pedidos.list', user.permissions),
          roles: ['all'],
        },

        {
          title: 'Folhas',
          key: 'folhas',
          link: '/Folhas',
          type: 'menu',
          allow: HasPermission('folha.list', user.permissions),
          icon: null,
          roles: ['all'],
        },
        {
          title: 'Transferencias',
          key: 'transferencias',
          link: '/Transferencias',
          type: 'menu',
          allow: HasPermission('ordem.list', user.permissions),
          icon: null,
          roles: ['all'],
        },
        {
          title: 'Categorias',
          key: 'categorias',
          link: '/Categorias',
          type: 'menu',
          icon: null,
          roles: ['all'],
        },
        {
          title: 'Materiais/Serviços',
          key: 'materiaisservicos',
          link: '/MateriaisServicos',
          type: 'menu',
          icon: null,
          roles: ['all'],
        },
      ],
    },
    {
      title: 'Cadastros Compras',
      key: 'engenharia',
      type: 'submenu',
      icon: <Icon component={() => <img alt="worker" src="/worker.svg" />} />,
      roles: ['master', 'admin', 'financeiro', 'engenharia', 'compras'],
      modulo: 'Compras',
      children: [
        {
          title: 'Dashboard',
          key: 'dashboard',
          link: '/Dashboard',
          type: 'menu',
          icon: null,
          roles: [],
        },
        {
          title: 'Fornecedores',
          key: 'fornecedores',
          link: '/Fornecedores',
          type: 'menu',
          icon: null,
          roles: ['all'],
        },
        {
          title: 'Colaboradores',
          key: 'colaboradores',
          link: '/Colaboradores',
          type: 'menu',
          icon: null,
          roles: ['all'],
        },
        {
          title: 'Empresas Faturamento',
          key: 'empresasfaturamento',
          link: '/EmpresasFaturamento',
          type: 'menu',
          icon: null,
          roles: ['all'],
        },
      ],
    },

    {
      title: 'Correspondência',
      key: 'correspondencia',
      type: 'submenu',
      icon: <BankOutlined />,
      modulo: 'Correspondência',
      roles: ['master', 'correspondencia', 'financeiro', 'admin', 'master'],
      children: [
        {
          title: 'Quadro de Vendas',
          key: 'quadrovendas',
          link: '/QuadroVendas',
          type: 'menu',
          icon: null,
          roles: ['all'],
        },
        {
          title: 'Jornada',
          key: 'jornada',
          link: '/Jornada',
          type: 'menu',
          icon: null,
          roles: [''],
        },
        {
          title: 'Contratos Mês',
          key: 'contratosmes',
          link: '/ContratosMes',
          type: 'menu',
          icon: null,
          roles: ['all'],
        },
        {
          title: 'Clientes',
          key: 'clientes',
          link: '/ClientesCorrespondencia',
          type: 'menu',
          icon: null,
          roles: ['admin', 'master', 'correspondencia', 'financeiro'],
        },
        {
          title: 'Perspectiva',
          key: 'perspectiva',
          link: '/Perspectiva',
          type: 'menu',
          icon: null,
          roles: ['admin', 'master', 'correspondencia', 'financeiro'],
        },
        {
          title: 'Engenharias',
          key: 'engenharias',
          link: '/Engenharias',
          type: 'menu',
          icon: null,
          roles: ['admin', 'master', 'correspondencia', 'financeiro'],
        },
      ],
    },
    {
      title: 'Cadastros',
      key: 'cadastros',
      type: 'submenu',
      icon: <FormOutlined />,
      modulo: 'Básico',
      roles: ['correspondencia', 'financeiro', 'admin', 'master', 'engenharia', 'compras', 'contabilidade'],
      children: [
        {
          title: 'Loteamentos',
          key: 'loteamentos',
          link: '/Loteamentos',
          type: 'menu',
          icon: null,
          roles: ['all'],
        },
        {
          title: 'Empresas',
          key: 'empresas',
          link: '/Empresas',
          type: 'menu',
          icon: null,
          roles: ['admin', 'financeiro', 'master'],
        },
        {
          title: 'Corretores',
          key: 'corretores',
          link: '/Corretores',
          type: 'menu',
          icon: null,
          roles: ['admin', 'financeiro', 'master', 'correspondencia'],
        },
        {
          title: 'Agências',
          key: 'agencias',
          link: '/Agencias',
          type: 'menu',
          icon: null,
          roles: ['admin', 'financeiro', 'master', 'correspondencia'],
        },
        {
          title: 'Parceiros',
          key: 'parceiros',
          link: '/Parceiros',
          type: 'menu',
          icon: null,
          roles: ['admin', 'financeiro', 'master', 'correspondencia'],
        },
        {
          title: 'Modalidades de Crédito',
          key: 'modalidades',
          link: '/ModalidadesCredito',
          type: 'menu',
          icon: null,
          roles: ['admin', 'master', 'correspondencia'],
        },
        {
          title: 'CCAs',
          key: 'ccas',
          link: '/CCAs',
          type: 'menu',
          icon: null,
          roles: ['admin', 'master', 'correspondencia'],
        },
        {
          title: 'Produtos Bancários',
          key: 'produtos',
          link: '/ProdutosBancarios',
          type: 'menu',
          icon: null,
          roles: ['admin', 'master', 'correspondencia'],
        },
      ],
    },
    {
      title: 'Configurações',
      key: 'configuracoes',
      type: 'submenu',
      icon: <SettingOutlined />,
      roles: ['admin', 'master'],
      modulo: 'Básico',
      children: [
        {
          title: 'Usuários',
          key: 'usuarios',
          link: '/Usuarios',
          type: 'menu',
          icon: null,
          roles: ['master', 'admin'],
        },
        {
          title: 'Perfis',
          key: 'perfils',
          link: '/Perfis',
          type: 'menu',
          icon: null,
          roles: ['master'],
        },
        {
          title: 'Configuracão',
          key: 'configuracao',
          link: '/Configuracao',
          type: 'menu',
          icon: null,
          roles: ['master'],
        },
        {
          title: 'Logo',
          key: 'logo',
          link: '/Logo',
          type: 'menu',
          icon: null,
          roles: ['master'],
        },
        {
          title: 'Notificação',
          key: 'notificacao',
          link: '/Notificacao',
          type: 'menu',
          icon: null,
          roles: ['admin', 'master'],
        },
        {
          title: 'Modelos',
          key: 'modelos',
          link: '/Modelos',
          type: 'menu',
          icon: null,
          roles: ['admin', 'master'],
        },
      ],
    },
  ];
  useEffect(() => {
    if (user.authenticated && isActive) {
      const configValue: string = process.env.NODE_ENV;
      if (configValue !== 'development') connect();
      else connect();
    }
  }, [user.authenticated, isActive]);
  useEffect(() => {
    if (!location.pathname.includes('PrintOrdemPDF')) dispatch(Check(paypal));
  }, [paypal]);
  useEffect(() => {
    if (user.authenticated === true) {
      if (user.conta.subscriptionID !== null) {
        if (user.subscription.status === SubscriptionStatus.ACTIVE) SetIsActive(true);
      } else {
        SetIsActive(false);
      }
    }
  }, [user]);
  useEffect(() => {
    if (HasRole(['aprovador'], user.roles) && isActive) {
      if (user.aprovaOrdem) dispatch(GetOrdensPendentes());
      if (user.aprovaFolha) dispatch(GetFolhasPendentes());
      if (user.aprovaTransferencia) dispatch(GetPendentes());
      if (user.aprovaPedido) dispatch(GetPedidosPendentes());
    }
    if (HasRole(['engenharia'], user.roles) && isActive) {
      dispatch(getAuditoriasPendentes());
    }
    if (!HasRole(['clienteobra', 'investidor'], user.roles) && isActive) {
      dispatch(getNotifications({ take: 10, skip: 0 }));
      dispatch(getLastRead());
    }
  }, [user, isActive]);
  menuitems.forEach((c) => {
    openmenuitems.push(c.key);
    c.children.forEach((d) => openmenuitems.push(d.key));
  });
  const background = location.state && location.state.backgroundLocation;
  if (location.pathname.includes('PrintOrdemPDF')) {
    return (
      <Routes>
        <Route path="/PrintOrdemPDF/:id/:contaID" element={<OrdemPrintPDF />} />
      </Routes>
    );
  } else if (location.pathname.includes('PrintTransferencia')) {
    return (
      <Routes>
        <Route path="/PrintTransferencia/:id" element={<OrdemPrintBody />} />
      </Routes>
    );
  } else if (location.pathname.includes('PrintPerspectiva')) {
    return (
      <Routes>
        <Route path="/PrintPerspectiva" element={<PerspectivaPrint />} />
      </Routes>
    );
  } else if (location.pathname.includes('PrintFolha')) {
    return (
      <Routes>
        <Route path="/PrintFolha/:id" element={<FolhaPrintPDF />} />
      </Routes>
    );
  } else if (location.pathname.includes('PrintPedido')) {
    return (
      <Routes>
        <Route path="/PrintPedido/:id" element={<PedidoViewBody />} />
      </Routes>
    );
  } else if (!user.checked)
    return (
      <Spin spinning>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}></div>
      </Spin>
    );
  else if (!user.authenticated) return <HomeView />;
  else
    return (
      <PayPalScriptProvider
        options={{
          vault: true,
          intent: 'subscription',
          'client-id': paypal.clientid,
        }}
      >
        <Spin spinning={user.loading}>
          <Layout>
            {user.authenticated && (
              <Affix>
                <Header>
                  <Link to="/" style={{ float: 'left' }}>
                    <img alt="logo" src={facilitar ? logofacilitar : logo} height={50}></img>
                  </Link>
                  <NavBar />
                </Header>
              </Affix>
            )}
            <Layout style={{ marginLeft: user.authenticated ? 0 : 0 }}>
              {user.authenticated &&
                isActive &&
                !HasRole(['investidor', 'clienteobra', 'mestre de obras'], user.roles) && (
                  <Sider theme="light" collapsedWidth={0} collapsible={true} breakpoint="sm">
                    <Menu mode="inline" selectedKeys={[pageheader.menu]}>
                      {menuitems
                        .filter((c) => c.allow === true || c.allow === undefined)
                        .map((i) => {
                          if (HasRole(i.roles, user.roles) && user.modulos.map((m) => m.nome).includes(i.modulo)) {
                            if (i.type === 'submenu')
                              return (
                                <Menu.SubMenu
                                  key={i.key}
                                  title={
                                    i.badge ? (
                                      <Badge
                                        showZero={false}
                                        count={
                                          folhasPendentes.length +
                                          transferenciasPendentes.length +
                                          pedidosPendentes.length +
                                          ordensPendentes.length
                                        }
                                        size="small"
                                        offset={[20, 6]}
                                      >
                                        <span>
                                          {i.icon}
                                          <span>{i.title}</span>
                                        </span>
                                      </Badge>
                                    ) : (
                                      <span>
                                        {i.icon}
                                        <span>{i.title}</span>
                                      </span>
                                    )
                                  }
                                >
                                  {i.children
                                    .filter((c) => c.allow === true || c.allow === undefined)
                                    .map((m) => {
                                      if (HasRole(m.roles, user.roles)) {
                                        return (
                                          <Menu.Item key={m.key}>
                                            {m.badge ? (
                                              <Badge
                                                showZero={false}
                                                count={
                                                  m.key === 'aprovarordens'
                                                    ? ordensPendentes.length
                                                    : m.key === 'aprovarfolhas'
                                                    ? folhasPendentes.length
                                                    : m.key === 'aprovarpedidos'
                                                    ? pedidosPendentes.length
                                                    : m.key === 'aprovartransferencias'
                                                    ? transferenciasPendentes.length
                                                    : 0
                                                }
                                                size="small"
                                                offset={[20, 6]}
                                              >
                                                <Link to={m.link}>{m.title}</Link>
                                              </Badge>
                                            ) : (
                                              <Link to={m.link}>{m.title}</Link>
                                            )}
                                          </Menu.Item>
                                        );
                                      } else return '';
                                    })}
                                </Menu.SubMenu>
                              );
                            else
                              return (
                                <Menu.Item key={i.key}>
                                  <Link to={i.link}>
                                    {i.badge ? (
                                      <Badge
                                        showZero={false}
                                        count={
                                          i.key === 'auditorias'
                                            ? auditoriasPendentes
                                            : i.key === 'aprovacoes'
                                            ? folhasPendentes.length +
                                              transferenciasPendentes.length +
                                              pedidosPendentes.length +
                                              ordensPendentes.length
                                            : 0
                                        }
                                        size="small"
                                        offset={[18, 6]}
                                      >
                                        <span>
                                          {i.icon}
                                          <span>{i.title}</span>
                                        </span>
                                      </Badge>
                                    ) : (
                                      <span>
                                        {i.icon}
                                        <span>{i.title}</span>
                                      </span>
                                    )}
                                  </Link>
                                </Menu.Item>
                              );
                          } else return null;
                        })}
                    </Menu>
                  </Sider>
                )}
              <Content
                style={{
                  overflow: 'scroll',
                  height: user.authenticated ? 'calc(100vh - 85px)' : '100vh',
                  marginRight: user.authenticated ? 10 : 0,
                  marginLeft: user.authenticated ? (HasRole(['investidor', 'clienteobra'], user.roles) ? 10 : 40) : 0,
                  marginTop: user.authenticated ? 10 : 0,
                }}
              >
                {user.checked && (
                  <div className="print">
                    {user.authenticated && isActive && (
                      <Affix offsetTop={64}>
                        <PageHeader
                          style={{ backgroundColor: 'rgb(240,242,245)' }}
                          title={pageheader.title}
                          onBack={() => pageheader.backbutton && window.history.back()}
                          extra={pageheader.extras}
                          subTitle={pageheader.subtitle}
                        />
                      </Affix>
                    )}
                    <Content>
                      {user.authenticated && isActive && (
                        <Routes location={background || location}>
                          <Route path="/DashboardPedidos" element={<DashboardPedidos />} />
                          <Route path="/Pedidos" element={<PedidosView />} />
                          <Route path="/Pedidos/:id" element={<PedidoView />} />
                          <Route path="/NovoPedido" element={<PedidoForm />} />
                          <Route path="/PedidosPendentes" element={<PedidosPendentes />} />
                          <Route path="/Casas" element={<CasasView />} />
                          <Route path="/Empreendimentos/:id" element={<EmpreendimentoControl />} />
                          <Route path="/Empreendimentos" element={<EmpreendimentosView />} />
                          <Route path="/Loteamentos" element={<LoteamentosView />} />
                          <Route path="/Agencias" element={<AgenciasView />} />
                          <Route path="/Corretores" element={<CorretoresView />} />
                          <Route path="/Empresas" element={<EmpresasLegalizacaoView />} />
                          <Route path="/Parceiros" element={<ParceirosView />} />
                          <Route path="/Chamados/:id" element={<ChamadoForm />} />
                          <Route path="/Chamados" element={<ChamadoView />} />
                          <Route path="/QuadroVendas" element={<QuadroMain />} />
                          {false && <Route path="/Jornada" element={<JornadaCliente />} />}
                          <Route path="/ContratosMes" element={<ContratosMes />} />
                          <Route path="/Perspectiva" element={<PerspectivaView />} />
                          <Route path="/Engenharias" element={<EngenhariasView />} />
                          <Route path="/ClientesCorrespondencia/:id" element={<FichaCliente />} />
                          <Route path="/ClientesCorrespondencia" element={<ClientesView />} />
                          <Route path="/ClienteObra" element={<DashboardClienteObra />} />
                          <Route path="/Investidores" element={<InvestidoresView />} />
                          <Route path="/Usuarios" element={<UsuariosView />} />
                          <Route path="/Perfis" element={<PerfisView />} />
                          <Route path="/ModalidadesCredito" element={<ModalidadesView />} />
                          <Route path="/CCAs" element={<CCAsView />} />
                          <Route path="/ProdutosBancarios" element={<ProdutosView />} />
                          <Route path="/RelatorioInvestimento/:id" element={<RelatorioMain />} />
                          <Route path="/Fornecedores" element={<FornecedoresView />} />
                          <Route path="/Colaboradores" element={<ColaboradoresView />} />
                          <Route path="/Colaboradores/:id" element={<RelatorioPagamentos />} />
                          <Route path="/MateriaisServicos" element={<MateriaisView />} />
                          <Route
                            path="/NovaOrdem/:gostep"
                            element={
                              <OrdemFormProvider numSteps={4}>
                                <OrdemForm />
                              </OrdemFormProvider>
                            }
                          >
                            <Route
                              path=":id"
                              element={
                                <OrdemFormProvider numSteps={4}>
                                  <OrdemForm />
                                </OrdemFormProvider>
                              }
                            />
                          </Route>
                          <Route
                            path="/NovaOrdem"
                            element={
                              <OrdemFormProvider numSteps={4}>
                                <OrdemForm />
                              </OrdemFormProvider>
                            }
                          />
                          <Route path="/Pendencias" element={<OrdensPendencias />} />
                          <Route path="/EmpresasFaturamento" element={<EmpresasFaturamentoView />} />
                          <Route path="/Ordens" element={<OrdensView />} />
                          <Route path="/Transferencias" element={<TransferenciasView />} />
                          <Route path="/Transferencia/:id" element={<OrdemPrintTransferencia />} />
                          <Route
                            path="/NovaTransferencia"
                            element={
                              <TransferenciaFormProvider>
                                <TransferenciaForm />
                              </TransferenciaFormProvider>
                            }
                          />
                          <Route path="/PrintTransferencia/:id" element={<OrdemPrintBody />} />
                          <Route path="/TransferenciasAprovar" element={<TransferenciasAprovar />} />
                          <Route path="/PrintOrdem/:id" element={<OrdemPrint />} />
                          <Route path="/PrintOrdemTransferencia/:id" element={<OrdemPrintTransferencia />} />
                          <Route path="/Categorias" element={<CategoriasView />} />
                          <Route path="/Orcamentos" element={<OrcamentosView />} />
                          <Route path="/Fisico" element={<FisicoView />} />
                          <Route path="/Fisico/:id" element={<PlanejamentoObra />} />
                          <Route path="/FisicoReport/:id" element={<FisicoReport />} />
                          <Route path="/Lancar/:id" element={<Lancar />} />
                          <Route path="/DashboardOrcamentos" element={<DashboardOrcamentos />} />
                          <Route path="/OrcamentoReport/:id" element={<OrcamentoDetails />} />
                          <Route path="/Composicoes" element={<ComposicoesView />} />
                          <Route path="/OrcamentoForm/:id" element={<OrcamentoForm />} />
                          <Route path="/OrcamentoBancoForm/:id" element={<OrcamentoBancoForm />} />
                          <Route path="/OrcamentoForm" element={<OrcamentoForm />} />
                          <Route path="/OrcamentoView/:id" element={<OrcamentoView />} />
                          <Route path="/OrcamentosBanco" element={<OrcamentosBanco />} />
                          <Route path="/Configuracao" element={<ConfiguracaoView />} />
                          <Route path="/Logo" element={<LogoView />} />
                          <Route path="/Planejamentos/:id" element={<PlanejamentoObraBanco />} />
                          <Route path="/PlanejamentoReport/:id" element={<FisicoReportBanco />} />
                          <Route path="/Planejamentos" element={<FisicoBanco />} />
                          <Route path="/MetasOrcamento" element={<MetasOrcamento />} />
                          <Route path="/LancarBanco/:id" element={<LancarBanco />} />
                          <Route path="/FluxoObra" element={<FluxoObras />} />
                          <Route path="/Medicoes/:id" element={<Medicoes />} />
                          <Route path="/PrintFolha/:id" element={<FolhaPrintPDF />} />
                          <Route path="/Folhas/:id" element={<FolhaView />} />
                          <Route path="/FolhasPendentes" element={<FolhasPendentes />} />
                          <Route path="/Folhas" element={<FolhasView />} />
                          <Route path="/DashboardFolhas" element={<DashboardFolhas />} />
                          <Route path="/NovaFolha/:id" element={<FolhaForm />} />
                          <Route
                            path="/NovaFolhaMedicao"
                            element={
                              <FolhaFormProvider>
                                <FolhaForm />
                              </FolhaFormProvider>
                            }
                          />

                          <Route
                            path="/NovaFolhaMedicao/:stepNum"
                            element={
                              <FolhaFormProvider>
                                <FolhaForm />
                              </FolhaFormProvider>
                            }
                          >
                            <Route
                              path=":id"
                              element={
                                <FolhaFormProvider>
                                  <FolhaForm />
                                </FolhaFormProvider>
                              }
                            />
                          </Route>
                          <Route path="/Notificacao" element={<NotificacaoView />} />
                          <Route path="/Profile" element={<ProfileView />} />
                          <Route path="/Modelos" element={<ModelosView />} />
                          <Route path="/AuditoriaForm" element={<AuditoriaForm />} />
                          <Route path="/Auditorias" element={<AuditoriasView />} />
                          <Route path="/Auditorias/:id" element={<AuditoriaView />} />
                          <Route path="/AuditoriaForm/:id" element={<AuditoriaForm />} />
                          <Route path="/PrintAuditoria/:id" element={<PrintAuditoria />} />
                          <Route path="/DiarioForm" element={<DiarioForm />} />
                          <Route path="/Diarios" element={<DiariosView />} />
                          <Route path="/Diarios/:id" element={<DiarioView />} />
                          <Route path="/DiarioForm/:id" element={<DiarioForm />} />
                          <Route path="/PrintDiario/:id" element={<PrintDiario />} />
                          <Route path="/NaoAutorizado" element={<div> Rota não autorizada</div>} />
                          <Route path="/TermoVistoriaEntrega/:casaid" element={<TermoVistoriaEntrega />} />
                          <Route path="/TermoRevistoria/:casaid" element={<TermoRevistoria />} />
                          <Route path="/TermoChavesManual/:casaid" element={<TermoChavesManual />} />
                          <Route path="/FormVisita/:chamadoid" element={<OSvisita />} />
                          <Route path="/FormEncerramento/:chamadoid" element={<OSencerramento />} />
                          <Route path="/ComunicadoEncerramento/:chamadoid" element={<Comunicado />} />
                          <Route path="/CartaEncerramento/:chamadoid" element={<CartaDeEnc />} />
                        </Routes>
                      )}
                      {user.authenticated && (
                        <Routes>
                          <Route
                            path="/"
                            element={
                              isActive &&
                              !HasRole(
                                ['investidor', 'clienteobra', 'mestre de obras', 'correspondencia'],
                                user.roles
                              ) ? (
                                <DashboardView />
                              ) : isActive &&
                                facilitar &&
                                !HasRole(['investidor', 'clienteobra', 'mestre de obras'], user.roles) ? (
                                <DashboardCorrepondencia />
                              ) : isActive && HasRole(['investidor'], user.roles) ? (
                                <DashboardInvestidor />
                              ) : isActive && HasRole(['clienteobra'], user.roles) ? (
                                <DashboardClienteObra />
                              ) : isActive && HasRole(['mestre de obras'], user.roles) ? (
                                <DiariosView />
                              ) : !isActive && HasRole(['master'], user.roles) ? (
                                <ContratarView ContaID={user.conta.id} />
                              ) : !isActive && HasRole(['investidor', 'clienteobra', 'mestre de obras'], user.roles) ? (
                                <SistemaIndisponivelView />
                              ) : !isActive &&
                                !HasRole(['master', 'investidor', 'clienteobra', 'mestre de obras'], user.roles) ? (
                                <SistemaIndisponivelView />
                              ) : null
                            }
                          />
                        </Routes>
                      )}
                      {background && user.authenticated && isActive && (
                        <Routes>
                          <Route path="/Casas/:id" element={<FichaNova />} />
                        </Routes>
                      )}
                    </Content>
                  </div>
                )}
              </Content>
            </Layout>
          </Layout>
        </Spin>
      </PayPalScriptProvider>
    );
}

export default Main;
