import { OrdemStatus } from '@constants/ordemStatus';
import { IOrdemForm } from '@models/Ordem';
import { RazaoEstouro } from '@models/OrdemItem';
import { GetOrdemEdit, SalvarOrdemStep1, SalvarOrdemStep2, SubmeterOrdem } from '@services/api/api.ordem';
import { moneyMask } from '@utils/masks';
import { FormInstance } from 'antd';
import { Form } from 'antd';
import moment from 'moment';
import { FieldData } from 'rc-field-form/es/interface';
import React, { ReactNode, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

export interface StepOrdemFormProps {
  key: number;
  title: string;
  content: ReactNode;
}
interface UseOrdemFormContext {
  step: number;
  next: () => void;
  previous: () => void;
  goTo: (num: number) => void;
  form: FormInstance<IOrdemForm>;
  numSteps: number;
  save: (values: Partial<IOrdemForm>) => void;
  isLoading: boolean;
  ordem: IOrdemForm;
  isFetching: boolean;
  estouros: Estouro[];
}
interface Estouro {
  item: number;
  id: number;
  descricao: string;
}

const OrdemFormContext = React.createContext<UseOrdemFormContext>(null);
const Fields: FieldData[] = [
  {
    name: 'descricao',
  },
];
export const OrdemFormProvider = ({ children, numSteps }: { children: ReactNode; numSteps: number }) => {
  const queryClient = useQueryClient();
  const [step, setStep] = useState<number>(0);
  const { id, gostep } = useParams();
  const [estouros, setEstouros] = useState<Estouro[]>([]);
  const { isLoading, data, isFetching } = GetOrdemEdit(id);
  const saveStep1 = SalvarOrdemStep1();
  const saveStep2 = SalvarOrdemStep2();
  const submeterOrdem = SubmeterOrdem();
  const navigate = useNavigate();
  const next = () => {
    if (step < numSteps) {
      navigate(`/NovaOrdem/${step + 1}/${id}`);
      setStep((pv) => pv + 1);
    }
  };
  const previous = () => {
    if (step > 0) {
      navigate(`/NovaOrdem/${step - 1}/${id}`);
      setStep((pv) => pv - 1);
    }
  };
  const goTo = (num: number) => {
    if (num >= 0 && num <= numSteps) {
      navigate(`/NovaOrdem/${num}/${id}`);
      setStep(num);
    }
  };
  const [form] = Form.useForm<IOrdemForm>();
  const save = (values: Partial<IOrdemForm>) => {
    const situacao = form.getFieldValue('situacao');
    if (
      step === 0 &&
      situacao !== OrdemStatus.Cancelada &&
      situacao !== OrdemStatus.Finalizada &&
      situacao !== OrdemStatus.Substituida &&
      situacao !== OrdemStatus.PendentePagamento &&
      situacao !== OrdemStatus.PendenteDocumentos
    )
      saveStep1.mutate(values);
    if (
      step === 1 &&
      situacao !== OrdemStatus.Cancelada &&
      situacao !== OrdemStatus.Finalizada &&
      situacao !== OrdemStatus.Substituida &&
      situacao !== OrdemStatus.PendentePagamento &&
      situacao !== OrdemStatus.PendenteDocumentos
    )
      saveStep2.mutate({
        ...values,
        prazoPagamento: moment(moment(values.prazoPagamento).format('YYYY-MM-DD')),
        prazoEntrega: moment(moment(values.prazoEntrega).format('YYYY-MM-DD')),
      });
    if (step === numSteps - 1) submeterOrdem.mutate({ id: values.id });
  };
  form.setFields(Fields);
  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        desconto: moneyMask.format(data.desconto),
        prazoPagamento: moment(data.prazoPagamento).isValid() ? moment(data.prazoPagamento) : null,
        prazoEntrega: moment(data.prazoEntrega).isValid() ? moment(data.prazoEntrega) : null,
      });
      let temp: Estouro[] = [];
      data.ordensItens.forEach((c, index) => {
        if (c.estourado) {
          temp.push({
            id: c.id,
            item: index + 1,
            descricao:
              c.razaoEstouro === RazaoEstouro.Bloqueado
                ? 'Item bloqueado'
                : c.razaoEstouro === RazaoEstouro.Quantidade
                ? 'Item estourado em quantidade'
                : c.razaoEstouro === RazaoEstouro.Valor
                ? 'Item estourado em valor'
                : c.razaoEstouro === RazaoEstouro.ValoreQuantidade
                ? 'Item estoura em valor e quantidade'
                : '',
          });
        }
      });
      setEstouros(temp);
    }
  }, [data]);
  useEffect(() => {
    if (gostep) {
      const num = Number(gostep);
      if (num >= 0 && num < numSteps && !!id) setStep(Number(gostep));
      else navigate('/NovaOrdem');
    }
  }, [id, step]);
  useEffect(() => {
    if (gostep) {
      const num = Number(gostep);
      if (num >= 0 && num < numSteps && !!id) setStep(Number(gostep));
      else navigate('/NovaOrdem');
    }
    queryClient.invalidateQueries();
  }, []);
  return (
    <OrdemFormContext.Provider
      value={{ step, next, previous, form, numSteps, goTo, save, isLoading, ordem: data, isFetching, estouros }}
    >
      {children}
    </OrdemFormContext.Provider>
  );
};

export const useOrdemForm = () => {
  const context = React.useContext(OrdemFormContext);
  if (context === undefined) throw new Error('Hook must be wrapped by Provider');

  const { step, next, previous, form, numSteps, goTo, save, isLoading, ordem, isFetching, estouros } = context;
  return { step, next, previous, form, numSteps, goTo, save, isLoading, ordem, isFetching, estouros };
};
