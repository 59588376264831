import { Axios } from '@config/axios';
import { setCookie } from '@services/localStorage';
import { AppDispatch } from '@config/reduxStore';
import { apiEndpoints } from '@constants/apiEndpoints';
import { ReactNode } from 'react';
import { ordemType } from '@constants/ordemStatus';

export const Login = function (values: any, returnURL: string) {
    return function (dispatch: AppDispatch) {
        dispatch({
            type: 'LOGIN_PENDING',
        });
        Axios.post(apiEndpoints.USER.LOGIN, { ...values })
            .then((res) => {
                setCookie('token', res.data);
                if (returnURL) window.location.href = returnURL;
                else window.location.href = '/';
            })
            .catch((res) => {
                dispatch({
                    type: 'LOGIN_REJECTED',
                    payload: res,
                });
            });
    };
}; //0800 4004 654
export const SetPageHeader = function ({
    extras = [],
    ...restProps
}: {
    title: string;
    subtitle: string;
    backbutton: boolean;
    menu: string;
    extras?: ReactNode[];
}) {
    return function (dispatch: AppDispatch) {
        dispatch({
            type: 'SET_PAGE_HEADER',
            payload: {
                ...restProps,
                extras: extras,
                backbutton: restProps.backbutton ? () => window.history.back() : null,
            },
        });
    };
};
export const Check = function (paypal: any) {
    return function (dispatch: AppDispatch) {
        dispatch({
            type: 'CHECK_PENDING',
            payload: '',
        });
        Axios.get(apiEndpoints.USER.CHECK)
            .then((check) => {
                if (check.data.authenticated === true && check.data.conta.subscriptionID !== null) {
                    const params = new URLSearchParams();
                    params.append('grant_type', 'client_credentials');
                    Axios.post(`${paypal.api}/v1/oauth2/token`, params, {
                        headers: { Accept: 'application/json', 'Accept-Language': 'en_US' },
                        auth: {
                            username: paypal.clientid,
                            password: paypal.secret,
                        },
                    }).then((res) => {
                        Axios.get(`${paypal.api}/v1/billing/subscriptions/${check.data.conta.subscriptionID}`, {
                            headers: {
                                Authorization: `Bearer ${res.data.access_token}`,
                                'Content-Type': 'application/json',
                            },
                        })
                            .then((c) => {
                                dispatch({
                                    type: 'CHECK_FULFILLED',
                                    payload: { ...check.data, subscription: c.data },
                                });
                            })
                            .catch((c) => {
                                dispatch({
                                    type: 'CHECK_FULFILLED',
                                    payload: { ...check.data, subscription: { ...c.response.data, status: 'ACTIVE' } },
                                    //payload: { ...check.data, subscription: { ...c.response.data, status: c.response.data.name } },
                                });
                            });
                    });
                } else {
                    dispatch({
                        type: 'CHECK_FULFILLED',
                        payload: { ...check.data, subscription: { status: 'NULL' } },
                    });
                }
            })
            .catch(() => {
                dispatch({
                    type: 'CHECK_REJECTED',
                });
            });
    };
};
export const GetAprovadores = function (type: ordemType) {
    return function (dispatch: AppDispatch) {
        dispatch({
            type: 'GET_APROVADORES',
            payload: Axios.get(apiEndpoints.USER.GET_APROVADORES, { params: { type: type } }),
        });
    };
};

export const Logout = function () {
    return function () {
        setCookie('token', null);
        window.location.href = '/';
    };
};
export const ForgotPassword = function (Email: string) {
    return function (dispatch: AppDispatch) {
        dispatch({
            type: 'FORGOT_PASSWORD',
            payload: Axios.post(apiEndpoints.USER.FORGOT_PASSWORD, { Email }),
        });
    };
};
export const ResetPassword = function (values: any) {
    return function (dispatch: AppDispatch) {
        dispatch({
            type: 'RESET_PASSWORD',
            payload: Axios.post(apiEndpoints.USER.RESET_PASSWORD, { ...values }),
        });
    };
};

export const DeleteUser = function (id: string) {
    return function (dispatch: AppDispatch) {
        dispatch({
            type: 'DELETE_USER_CASA',
            payload: Axios.get(apiEndpoints.USER.DELETE_USER_CASA, { params: { id } }),
        });
    };
};
export const ChangePassword = function (values: { oldpassword: string; newpassword: string; ConfirmPassword: string }) {
    return function (dispatch: AppDispatch) {
        dispatch({
            type: 'CHANGE_PASSWORD',
            payload: Axios.post(apiEndpoints.USER.CHANGE_PASSWORD, values),
        });
    };
};
export const SalvarConfiguracao = function (values: any) {
    return function (dispatch: AppDispatch) {
        dispatch({
            type: 'SALVAR_CONFIGURACAO',
            payload: Axios.post(apiEndpoints.USER.SALVAR_CONFIGURACAO, values),
        });
    };
};
export const GetConfiguracao = function () {
    return function (dispatch: AppDispatch) {
        dispatch({
            type: 'GET_CONFIGURACAO',
            payload: Axios.get(apiEndpoints.USER.GET_CONFIGURACAO),
        });
    };
};
export const GetMailSettings = function () {
    return function (dispatch: AppDispatch) {
        dispatch({
            type: 'GET_MAILSETTINGS',
            payload: Axios.get(apiEndpoints.USER.GET_MAILS_SETTINGS),
        });
    };
};
export const SalvarMailSettings = function (values: any) {
    return function (dispatch: AppDispatch) {
        dispatch({
            type: 'SALVAR_MAILSETTINGS',
            payload: Axios.post(apiEndpoints.USER.SALVAR_MAILS_SETTINGS, values),
        });
    };
};
export const GetTiposTarefas = function () {
    return function (dispatch: AppDispatch) {
        dispatch({
            type: 'GET_TIPOS_TAREFAS',
            payload: Axios.get(apiEndpoints.USER.GET_TIPOS_TAREFAS),
        });
    };
};
