import { Alert, Button, Descriptions, Input, Popconfirm, Skeleton, Space, Table, Typography } from 'antd';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { deleteOrcamentoBancoItem, getOrcamento, updateValorTotal } from '@actions/bancoActions';
import { SetPageHeader } from '@actions/userActions';
import { PlusOutlined, EditOutlined, DeleteOutlined, StopOutlined, EnterOutlined } from '@ant-design/icons';
import { doubleMask1, moneyMask } from '@utils/masks';
import NovoItemOrcamentoBancoModal from './NovoItemOrcamentoBancoModal';
import { useState } from 'react';
import { naturalSorter } from '@utils/filterandsort';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

function OrcamentoBancoForm() {
  const dispatch = useAppDispatch();
  const { loadingOrcamentoBanco, orcamentoBanco, deletingItem } = useAppSelector((state) => state.banco);
  const [novoItem, setNovoItem] = useState(false);
  const [itemEdit, setItemEdit] = useState(null);
  const [valorTotal, setValorTotal] = useState<{ edit: boolean; valor: number }>({ edit: false, valor: 0 });
  const { id } = useParams();
  useEffect(() => {
    dispatch(getOrcamento(id));
  }, [id]);
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Planejamentos',
        backbutton: true,
        extras: [
          <Button
            type="primary"
            key={0}
            icon={<PlusOutlined />}
            onClick={() => {
              setItemEdit(null);
              setNovoItem(true);
            }}
          >
            Novo Item
          </Button>,
        ],
        subtitle: 'Orçamento Banco',
        menu: 'orcamentosbanco',
      })
    );
  }, []);
  useEffect(() => {
    setValorTotal({ edit: false, valor: orcamentoBanco?.valorTotal });
  }, [orcamentoBanco]);
  return (
    <Skeleton loading={loadingOrcamentoBanco}>
      <NovoItemOrcamentoBancoModal visible={novoItem} setVisible={setNovoItem} item={itemEdit} />
      <Descriptions bordered column={1}>
        <Descriptions.Item label="Obra">{orcamentoBanco?.descricao}</Descriptions.Item>
        <Descriptions.Item label="Valor Total">
          {valorTotal.edit ? (
            <Input
              value={moneyMask.format(valorTotal.valor)}
              suffix={<EnterOutlined />}
              onKeyDown={(key) => {
                if (key.key === 'Enter') {
                  dispatch(updateValorTotal({ id: id, valor: valorTotal.valor }));
                  setValorTotal({ ...valorTotal, edit: false });
                }
              }}
              onChange={(e) => setValorTotal({ edit: true, valor: Number(moneyMask.normalize(e.target.value)) })}
            />
          ) : (
            <div>
              <Typography.Text>{moneyMask.format(valorTotal.valor)}</Typography.Text>
              <EditOutlined
                style={{ cursor: 'pointer', color: 'blue', marginLeft: 5 }}
                onClick={() => setValorTotal({ ...valorTotal, edit: true })}
              />
            </div>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Soma dos Itens">
          {moneyMask.format(orcamentoBanco?.orcamentoBancoItens.reduce((a, b) => a + b.valorTotal, 0))}
        </Descriptions.Item>
      </Descriptions>
      <br />
      {valorTotal.valor !== orcamentoBanco?.orcamentoBancoItens.reduce((a, b) => a + b.valorTotal, 0) && (
        <Alert message="Somatório dos itens não bate com o total" type="error" icon={<StopOutlined />} />
      )}
      <Table
        size="small"
        loading={deletingItem}
        dataSource={
          orcamentoBanco?.orcamentoBancoItens
            ? [...orcamentoBanco?.orcamentoBancoItens]?.sort((a, b) => naturalSorter(a.codigo, b.codigo))
            : []
        }
        pagination={false}
        rowKey="id"
        summary={(data) => (
          <Table.Summary fixed>
            <Table.Summary.Row style={{ fontWeight: 'bold' }}>
              <Table.Summary.Cell index={0} align="right" colSpan={4}>
                TOTAL
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} align="right">
                {moneyMask.format(data.reduce((a, b) => a + b.valorTotal, 0))}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} align="right">
                {doubleMask1.format(100)}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      >
        <Table.Column
          title=""
          dataIndex="id"
          width={70}
          render={(v, row) => (
            <Space>
              <Button
                icon={<EditOutlined />}
                size="small"
                onClick={() => {
                  setItemEdit(row);
                  setNovoItem(true);
                }}
              />
              <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(deleteOrcamentoBancoItem(v))}>
                <Button icon={<DeleteOutlined />} size="small" />
              </Popconfirm>
            </Space>
          )}
        />
        <Table.Column title="Item" align="center" render={(_, __, index) => index + 1} />
        <Table.Column title="Código" align="center" dataIndex="codigo" />
        <Table.Column title="Item" dataIndex="descricao" />
        <Table.Column title="Valor" align="right" dataIndex="valorTotal" render={(v) => moneyMask.format(v)} />
        <Table.Column
          title="Peso"
          dataIndex="valorTotal"
          align="right"
          render={(v) =>
            doubleMask1.format((100 * v) / orcamentoBanco?.orcamentoBancoItens.reduce((a, b) => a + b.valorTotal, 0))
          }
        />
      </Table>
    </Skeleton>
  );
}

export default OrcamentoBancoForm;
