import { Axios } from '@config/axios';
import { apiEndpoints } from '@constants/apiEndpoints';
import { doubleMask, moneyMask, moneyNegMask } from '@utils/masks';
import { Descriptions } from 'antd';
import { useQuery } from 'react-query';

interface GetOrcamentoItemResult {
  id: number;
  item: string;
  orcado: number;
  valorUnitario: number;
  realizadoValor: number;
  realizadoQuantidade: number;
  orcadoValor: number;
  orcadoQuantidade: number;
  saldoValor: number;
  saldoQuantidade: number;
  orcadoValorUnitario: number;
  categoria: string;
  grupo: string;
}
const GetOrcamentoItem = async (orcamentoItemID: number) => {
  const res = await Axios.get<GetOrcamentoItemResult>(apiEndpoints.ORCAMENTOS.GET_ORCAMENTO_ITEM, {
    params: { orcamentoItemID: orcamentoItemID },
  });
  return res.data;
};
function OrdemItemInfo({ id }: { id: number }) {
  const { isLoading, data } = useQuery(['orcamentoItem', id], () => GetOrcamentoItem(id), {
    onError: (err: any) => console.log(err.response?.data?.Message),
  });
  if (isLoading || data == null) return <div>Carregando...</div>;
  return (
    <Descriptions
      bordered
      title={data.item}
      size="small"
      column={1}
      colon
      labelStyle={{ textAlign: 'right' }}
      contentStyle={{ textAlign: 'right' }}
    >
      <Descriptions.Item label="Categoria">{data.categoria}</Descriptions.Item>
      <Descriptions.Item label="Grupo">{data.grupo}</Descriptions.Item>
      <Descriptions.Item label="Valor Unitário Orçado">{moneyMask.format(data.valorUnitario)}</Descriptions.Item>
      <Descriptions.Item label="Quantidade Orçada">{doubleMask.format(data.orcadoQuantidade)}</Descriptions.Item>
      <Descriptions.Item label="Valor Realizado">{moneyMask.format(data.realizadoValor)}</Descriptions.Item>
      <Descriptions.Item label="Quantidade Realizada">{doubleMask.format(data.realizadoQuantidade)}</Descriptions.Item>
      <Descriptions.Item label="Saldo Valor">{moneyNegMask.format(data.saldoValor)}</Descriptions.Item>
      <Descriptions.Item label="Saldo Quantidade">{doubleMask.format(data.saldoQuantidade)}</Descriptions.Item>
    </Descriptions>
  );
}

export default OrdemItemInfo;
