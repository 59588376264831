import { useEffect } from 'react';
import { Modal, Table, Button, Form, Input, DatePicker, Radio, Popconfirm } from 'antd';
import { moneyMask } from '@utils/masks';
import moment from 'moment';
import {
  LoadLancamentos,
  GetLancamentos,
  AddLancamento,
  GetEmpreendimento,
  DeleteLancamento,
} from '@actions/empsActions';
import { DeleteOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { LancamentoInvestimento } from '@models/LancamentoInvestimento';

function LancamentosModal() {
  const dispatch = useAppDispatch();
  const { lancamentosmodal, investimento, addinglancamento, deletinglancamento } = useAppSelector(
    (state) => state.emps
  );
  const { investimentos } = useAppSelector((state) => state.emps.empreendimento);
  const [form] = Form.useForm();
  const { id } = useParams();
  useEffect(() => {
    if (investimento) dispatch(GetLancamentos(investimento));
  }, [investimento]);
  return (
    <Modal
      open={lancamentosmodal}
      title="Lançamentos"
      onCancel={() => {
        dispatch(LoadLancamentos());
        dispatch(GetEmpreendimento(id));
      }}
      footer={[
        <Button
          key="1"
          onClick={() => {
            dispatch(LoadLancamentos());
            dispatch(GetEmpreendimento(id));
          }}
        >
          Fechar
        </Button>,
      ]}
    >
      <Form
        form={form}
        name="lancamentoForm"
        onFinish={(values) => {
          dispatch(
            AddLancamento({
              ...values,
              valor: moneyMask.normalize(values.valor),
              investimentoid: investimento,
            })
          );
          form.resetFields();
          // props.dispatch(GetEmpreendimento(id));
        }}
        wrapperCol={{ span: 18 }}
        labelCol={{ span: 6 }}
      >
        <Form.Item name="data" label="Data" rules={[{ required: true, message: 'Campo obrigatório' }]}>
          <DatePicker format="DD/MM/YYYY" />
        </Form.Item>
        <Form.Item name="descricao" label="Descrição" rules={[{ required: true, message: 'Campo obrigatório' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="tipo" label="Tipo" rules={[{ required: true, message: 'Campo obrigatório' }]}>
          <Radio.Group>
            <Radio value="0">Aporte</Radio>
            <Radio value="1">Resgate</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="valor" label="Valor" rules={[{ required: true, message: 'Campo obrigatório' }]}>
          <Input
            onChange={(e) =>
              form.setFieldsValue({
                valor: moneyMask.format(moneyMask.normalize(e.target.value)),
              })
            }
          />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 18, offset: 6 }}>
          <Button htmlType="submit" type="primary" size="small" loading={addinglancamento}>
            Adicionar
          </Button>
        </Form.Item>
      </Form>
      <Table
        size="small"
        pagination={false}
        scroll={{ y: 240 }}
        rowKey="id"
        loading={deletinglancamento}
        dataSource={investimento ? investimentos.find((c) => c.id === investimento).lancamentosInvestimento : []}
      >
        <Table.Column
          render={(_, row: LancamentoInvestimento) => (
            <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(DeleteLancamento(row.id))}>
              <Button size="small" icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
        />
        <Table.Column title="Data" dataIndex="data" render={(text) => moment(text).format('DD/MM/YYYY')} />
        <Table.Column title="Tipo" dataIndex="tipo" render={(text) => (text === 0 ? 'Aporte' : 'Resgate')} />
        <Table.Column title="Descrição" dataIndex="descricao" />
        <Table.Column title="Valor" dataIndex="valor" render={(text) => moneyMask.format(text)} />
      </Table>
    </Modal>
  );
}

export default LancamentosModal;
