import { useEffect, useState } from 'react';
import { CriarConta, GetConta, GetPlanos } from '@actions/contasActions';
import { Col, Row, Button, Steps, Card, Space, Form, Input, Spin } from 'antd';
import { cepMask, cnpjMask, cpfMask } from '@utils/masks';
import Planos from './Planos';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

function ContratarView(props: { ContaID: number }) {
  const { planos, creatingaccount, conta } = useAppSelector((state) => state.conta);
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const [current, setCurrent] = useState(1);
  const [formConta] = Form.useForm();
  const { ContaID } = props;
  useEffect(() => {
    dispatch(GetPlanos());
    if (ContaID) {
      dispatch(GetConta(ContaID));
    }
  }, [props, dispatch]);
  useEffect(() => {
    if (conta && planos && user.subscription) {
      if (user.subscription.status === 'APPROVAL_PENDING') {
        setCurrent(5);
      } else setCurrent(2);
    }
  }, [conta, planos, user.subscription]);

  return (
    <Spin spinning={creatingaccount}>
      <Steps current={current} style={{ marginBottom: 10 }}>
        <Steps.Step title="Conta" description="Preencha os dados para criar sua conta" />
        <Steps.Step title="Assinatura" description="Escolha o tipo de assinatura que se encaixa melhor no seu perfil" />
        <Steps.Step title="Pagamento" description="Realize o pagamento" />
      </Steps>
      {current === 5 && (
        <div>Seu Pagamento está pendente de aprovação. Aguarde até que o pagamento seja aprovado pela instituição.</div>
      )}
      {current === 2 && <Planos ContaID={ContaID} />}
      {current === 1 && (
        <Row gutter={[8, 8]} justify="center">
          <Form
            form={formConta}
            layout="vertical"
            onFinish={(values) =>
              dispatch(
                CriarConta({
                  ...values,
                })
              )
            }
          >
            <Col flex="1 1 600px">
              <Card title="Dados da conta">
                <Form.Item
                  name="nome"
                  label="Nome da Empresa"
                  help="Nome fantasia que aparecerá nas telas do sistema"
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="cPF_CNPJ"
                  label="CPF/CNPJ"
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                  normalize={(v) =>
                    v.length >= 15 ? cnpjMask.format(cnpjMask.normalize(v)) : cpfMask.format(cpfMask.normalize(v))
                  }
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="nome_razao"
                  label="Nome/Razão Social"
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item name="endereco" label="Endereço" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                  <Input />
                </Form.Item>
                <Form.Item name="numero" label="Nº" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                  <Input />
                </Form.Item>
                <Form.Item name="complemento" label="Complemento">
                  <Input />
                </Form.Item>
                <Form.Item
                  name="cep"
                  label="CEP"
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                  normalize={(v) => cepMask.format(cepMask.normalize(v))}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="userName"
                  label="Nome Completo"
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="email"
                  label="E-mail"
                  rules={[
                    { required: true, message: 'Campo obrigatório' },
                    { type: 'email', message: 'E-mail inválido' },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="emailConfirm"
                  label="Confirmar E-mail"
                  rules={[
                    { required: true, message: 'Campo obrigatório' },
                    { type: 'email', message: 'E-mail inválido' },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('email') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('E-mails diferem entre si'));
                      },
                    }),
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Senha"
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório!',
                    },
                    {
                      pattern: /^(?=.{6,})/,
                      message: 'A senha deve conter no mínimo 6 dígitos',
                    },
                    {
                      pattern: /^(?=.*[a-z])/,
                      message: 'A senha deve conter ao menos uma letra minúscula',
                    },
                    {
                      pattern: /^(?=.*[A-Z])/,
                      message: 'A senha deve conter ao menos uma letra maiúscula',
                    },
                    {
                      pattern: /^(?=.*[0-9])/,
                      message: 'A senha deve conter ao menos um número',
                    },
                    {
                      pattern: /^(?=.*[!@#$%^&*])/,
                      message: 'A senha deve conter ao menos um caractére especial',
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  name="passwordConfirm"
                  label="Confirmar Senha"
                  rules={[
                    { required: true, message: 'Campo obrigatório' },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('As senhas diferem entre si'));
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              </Card>
            </Col>
          </Form>
        </Row>
      )}

      <div style={{ margin: 20 }}>
        <Space direction="horizontal">
          <Button
            hidden={current === 2}
            type="primary"
            onClick={() => (current === 5 ? setCurrent(2) : formConta.submit())}
          >
            {current === 2 ? 'Processar Pagamento' : current === 5 ? 'Pagar novamente' : 'Criar Conta'}
          </Button>
        </Space>
      </div>
    </Spin>
  );
}

export default ContratarView;
