import { Button, Descriptions, Divider, Table } from 'antd';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getDiario } from '@actions/diariosActions';
import { SetPageHeader } from '@actions/userActions';
import moment from 'moment';
import IonPhotoViewer from '@codesyntax/ionic-react-photo-viewer';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

function DiarioView() {
  const dispatch = useAppDispatch();
  const { loadingDiario, diario } = useAppSelector((state) => state.diarios);
  const { id } = useParams();
  useEffect(() => {
    dispatch(getDiario(id));
    dispatch(
      SetPageHeader({
        title: 'Diario',
        backbutton: true,
        extras: [
          <Link to={'/PrintDiario/' + id} key={0} target="_blank">
            <Button ghost type="primary">
              Imprimir
            </Button>
          </Link>,
        ],
        subtitle: 'Diario Realizada',
        menu: 'diarios',
      })
    );
  }, []);
  if (loadingDiario && !diario) return <div>Carregando...</div>;
  else
    return (
      <div>
        {diario && (
          <div>
            <Divider>Informações Gerais</Divider>
            <Descriptions bordered size="small" layout="vertical">
              <Descriptions.Item label="OBRA">{diario.obra?.descricao}</Descriptions.Item>
              <Descriptions.Item label="DATA">{moment(diario.data).format('DD/MM/YYYY')}</Descriptions.Item>
              <Descriptions.Item label="AUDITOR">{diario.auditor?.nome}</Descriptions.Item>
              <Descriptions.Item label="MESTRE">{diario.mestre?.nome}</Descriptions.Item>
              <Descriptions.Item label="RESPONSÁVEL">{diario.responsavel?.nome}</Descriptions.Item>
            </Descriptions>
            <Divider>Situação da Obra</Divider>
            <Descriptions bordered size="small" layout="vertical">
              <Descriptions.Item label="CHUVA">{diario.chuva ? 'SIM' : 'NÃO'}</Descriptions.Item>
              <Descriptions.Item label="PESSOAL OCIOSO">{diario?.pessoalOcioso ? 'SIM' : 'NÃO'}</Descriptions.Item>
              <Descriptions.Item label="PESSOAS EM OBRA">{diario?.equipe?.length || 0}</Descriptions.Item>
            </Descriptions>
            <Divider>Equipe Presente</Divider>
            <Table size="small" dataSource={diario?.equipe} pagination={false}>
              <Table.Column title="Nome" dataIndex="nome" />
              <Table.Column title="Função" dataIndex="funcao" />
            </Table>
            <Divider>Materiais Recebidos</Divider>
            <Table size="small" dataSource={diario?.materiais} pagination={false}>
              <Table.Column title="Material" dataIndex="material" />
              <Table.Column title="Notas" dataIndex="notas" />
            </Table>
            <Divider>Serviços em Execução</Divider>
            <Table size="small" dataSource={diario?.servicos} pagination={false}>
              <Table.Column title="Serviço" dataIndex="servico" />
              <Table.Column title="Notas" dataIndex="notas" />
            </Table>
            <Divider>Serviços Concluídos</Divider>
            <Table size="small" dataSource={diario?.concluidos} pagination={false}>
              <Table.Column title="Serviço" dataIndex="servico" />
              <Table.Column title="Notas" dataIndex="notas" />
            </Table>
            <Divider>Pendências</Divider>
            <Table size="small" dataSource={diario?.pendencias} pagination={false}>
              <Table.Column title="Pendência" dataIndex="pendencia" />
              <Table.Column title="Notas" dataIndex="notas" />
            </Table>
            <Divider>Ocorrências</Divider>
            <Table size="small" dataSource={diario?.ocorrencias} pagination={false}>
              <Table.Column title="Ocorrência" dataIndex="ocorrencia" />
            </Table>
            <Divider>Observações Gerais</Divider>
            <Descriptions>
              <Descriptions.Item>{diario.notas}</Descriptions.Item>
            </Descriptions>
            {diario?.fotos && (
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px 8px' }}>
                <Divider>Fotos</Divider>
                {diario.fotos.map((f) => (
                  <div key={f.id} style={{ width: 80, cursor: 'pointer' }}>
                    <IonPhotoViewer title={f.fileName} src={f.url}>
                      <img src={f.url} alt={f.fileName} width={80} height={80} />
                    </IonPhotoViewer>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    );
}

export default DiarioView;
