import { IFolhaItem, IFolhaRateio } from '@models/Folha';
import { doubleMask2, moneyMask } from '@utils/masks';
import { Skeleton, Table, Typography } from 'antd';
import { useFolhaForm } from './useFolhaForm';

function FolhaFormResumoFinalRateio() {
  const { folha, isLoading } = useFolhaForm();
  if (isLoading) return <Skeleton active />;
  return (
    <Table
      size="small"
      dataSource={folha?.rateio}
      pagination={false}
      title={() => 'Rateio dos Itens'}
      scroll={{ y: 300 }}
      rowKey="id"
      summary={(data) => (
        <Table.Summary fixed>
          <Table.Summary.Row style={{ fontWeight: 'bold' }}>
            <Table.Summary.Cell index={0} colSpan={2} align="left">
              {`${data.length}  Colaboradores`}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={0} colSpan={2} align="right">
              TOTAL
            </Table.Summary.Cell>
            <Table.Summary.Cell index={1} align="right">
              <Typography.Text
                style={{ color: folha?.rateio.reduce((a, b) => a + b.porcentagem, 0) > 100 ? 'red' : undefined }}
              >
                {doubleMask2.format(folha?.rateio.reduce((a, b) => a + b.porcentagem, 0))} / 100%
              </Typography.Text>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={2} align="right">
              <Typography.Text
                style={{
                  color:
                    Math.floor(
                      folha.rateio.reduce(
                        (a: number, b: IFolhaRateio) => a + moneyMask.normalize(String(b.medicao)),
                        0
                      ) * 100
                    ) /
                      100 >
                    Math.floor(folha.itens?.reduce((a: number, b: IFolhaItem) => a + b.valorMedir, 0) * 100) / 100
                      ? 'red'
                      : undefined,
                }}
              >
                {`${moneyMask.format(
                  Math.floor(folha?.rateio.reduce((a: number, b: IFolhaRateio) => a + Number(b.medicao), 0) * 100) / 100
                )} / ${moneyMask.format(
                  Math.floor(folha.itens?.reduce((a: number, b: IFolhaItem) => a + b.valorMedir, 0) * 100) / 100
                )}`}
              </Typography.Text>
            </Table.Summary.Cell>
          </Table.Summary.Row>
        </Table.Summary>
      )}
    >
      <Table.Column render={(_, __, index) => index + 1} width={40} align="center" />
      <Table.Column title="Nome" dataIndex="id" render={(v) => folha?.colaboradores.find((c) => c.id === v).nome} />
      <Table.Column title="Função" dataIndex="id" render={(v) => folha?.colaboradores.find((c) => c.id === v).funcao} />
      <Table.Column
        title="CPF/CNPJ"
        dataIndex="id"
        render={(v) => folha?.colaboradores.find((c) => c.id === v).cpF_CNPJ}
      />
      <Table.Column
        title="Percentual"
        dataIndex="porcentagem"
        align="right"
        width={150}
        render={(v) => doubleMask2.format(v)}
      />
      <Table.Column
        title="Valor Medido"
        dataIndex="medicao"
        align="right"
        width={200}
        render={(v) => moneyMask.format(v)}
      />
    </Table>
  );
}

export default FolhaFormResumoFinalRateio;
