import { ExtendedAction } from '@config/reduxStore';
import { AvancoFisico, RealizadoMes, ValorFisico } from '@models/AvancoFisico';
import { FisicoBanco, FluxoObra, ModeloBanco, OrcamentoFluxoBanco, PlanejadoBanco } from '@models/Fisico';
import { Medicao } from '@models/Medicao';
import { OrcamentoBanco } from '@models/OrcamentoBanco';
import { User } from '@models/User';
import { createReducer } from '@reduxjs/toolkit';
import { notification } from 'antd';

import moment from 'moment';
import {
  deleteMedicao,
  deleteOrcamentoBanco,
  deleteOrcamentoBancoItem,
  GetAvancos,
  getAvancosFisicos,
  getCategoriaItens,
  getFisico,
  getFisicos,
  getFluxoObras,
  getMedicoes,
  getModelos,
  getOrcamento,
  getOrcamentos,
  getOrcamentosBanco,
  getRealizadoMes,
  getResponsaveis,
  novaMedicao,
  novoItemOrcamentoBanco,
  novoOrcamentobanco,
  salvarFisico,
  updateAvancoFisico,
  updateMedicao,
  updateValorTotal,
} from '@actions/bancoActions';
import { Orcamento } from '@models/Orcamento';

interface BancoReducer {
  fisicos: PlanejadoBanco[];
  loadingFisicos: boolean;
  avancosFisicos: AvancoFisico[];
  loadingAvancosFisicos: boolean;
  fisico: FisicoBanco[];
  loadingFisico: boolean;
  savingFisico: boolean;
  avancos: AvancoFisico;
  loadingAvancos: boolean;
  realizados: RealizadoMes[];
  loadingRealizados: boolean;
  orcamento: Orcamento;
  min: moment.Moment;
  max: moment.Moment;
  valores: ValorFisico[];
  grupos: [];
  loadingGrupos: boolean;
  orcamentosBanco: OrcamentoBanco[];
  loadingOrcamentosBanco: boolean;
  modelos: ModeloBanco[];
  loadingModelos: boolean;
  orcamentos: OrcamentoBanco[];
  loadingOrcamentos: boolean;
  savingOrcamentoBanco: boolean;
  orcamentoBanco: OrcamentoBanco;
  loadingOrcamentoBanco: boolean;
  savingItem: boolean;
  deletingItem: boolean;
  medicoes: Medicao[];
  loadingMedicoes: boolean;
  responsaveis: User[];
  loadingResponsaveis: boolean;
  savingMedicao: boolean;
  fluxoObras: FluxoObra[];
  loadingFluxoObras: boolean;
}
var initialState: BancoReducer = {
  fisicos: [],
  loadingFisicos: false,
  avancosFisicos: [],
  loadingAvancosFisicos: false,
  fisico: [],
  loadingFisico: false,
  savingFisico: false,
  avancos: null,
  loadingAvancos: false,
  realizados: [],
  loadingRealizados: false,
  orcamento: null,
  min: moment(new Date()),
  max: moment(new Date()),
  valores: [],
  grupos: [],
  loadingGrupos: false,
  orcamentosBanco: [],
  loadingOrcamentosBanco: false,
  modelos: [],
  loadingModelos: false,
  orcamentos: [],
  loadingOrcamentos: false,
  savingOrcamentoBanco: false,
  orcamentoBanco: null,
  loadingOrcamentoBanco: false,
  savingItem: false,
  deletingItem: false,
  medicoes: [],
  loadingMedicoes: false,
  responsaveis: [],
  loadingResponsaveis: false,
  savingMedicao: false,
  fluxoObras: [],
  loadingFluxoObras: false,
};

export default createReducer(initialState, (builder) => {
  builder.addCase(getFluxoObras.pending, (state) => ({
    ...state,
    loadingFluxoObras: true,
  }));
  builder.addCase(getFluxoObras.rejected, (state) => {
    return {
      ...state,
      loadingFluxoObras: false,
    };
  });
  builder.addCase(getFluxoObras.fulfilled, (state, action: ExtendedAction) => {
    return {
      ...state,
      loadingFluxoObras: false,
      fluxoObras: action.payload.data.orcamento.map((c: OrcamentoFluxoBanco) => ({
        ...c,
        despesas: c.medicoes,
        receitas: action.payload.data.banco.find((d: OrcamentoFluxoBanco) => d.id === c.id)?.medicoes || [],
      })),
    };
  });
  builder.addCase(deleteMedicao.pending, (state) => ({
    ...state,
    savingMedicao: true,
  }));
  builder.addCase(deleteMedicao.rejected, (state) => {
    notification.error({ message: 'Erro ao excluir medição' });
    return {
      ...state,
      savingMedicao: false,
    };
  });
  builder.addCase(deleteMedicao.fulfilled, (state, action: ExtendedAction) => {
    notification.success({ message: 'Medição excluída com sucesso!' });
    return {
      ...state,
      savingMedicao: false,
      medicoes: state.medicoes.filter((c) => c.id !== action.payload.data),
    };
  });
  builder.addCase(updateMedicao.pending, (state) => ({
    ...state,
    savingMedicao: true,
  }));
  builder.addCase(updateMedicao.rejected, (state) => {
    notification.error({ message: 'Erro ao atualizar medição' });
    return {
      ...state,
      savingMedicao: false,
    };
  });
  builder.addCase(updateMedicao.fulfilled, (state, action: ExtendedAction) => {
    notification.success({ message: 'Medição atualizada com sucesso!' });
    return {
      ...state,
      savingMedicao: false,
      medicoes: state.medicoes.map((c) => {
        if (c.id === action.payload.data.id) return action.payload.data;
        else return c;
      }),
    };
  });
  builder.addCase(novaMedicao.pending, (state) => ({
    ...state,
    savingMedicao: true,
  }));
  builder.addCase(novaMedicao.rejected, (state) => {
    notification.error({ message: 'Erro ao lançar medição' });
    return {
      ...state,
      savingMedicao: false,
    };
  });
  builder.addCase(novaMedicao.fulfilled, (state, action: ExtendedAction) => {
    notification.success({ message: 'Medição lançada com sucesso!' });
    return {
      ...state,
      savingMedicao: false,
      medicoes: [...state.medicoes, action.payload.data],
    };
  });
  builder.addCase(getResponsaveis.pending, (state) => ({
    ...state,
    loadingResponsaveis: true,
  }));
  builder.addCase(getResponsaveis.rejected, (state) => {
    notification.error({ message: 'Erro ao buscar responsáveis' });
    return {
      ...state,
      loadingResponsaveis: false,
    };
  });
  builder.addCase(getResponsaveis.fulfilled, (state, action: ExtendedAction) => {
    return {
      ...state,
      loadingResponsaveis: false,
      responsaveis: action.payload.data,
    };
  });
  builder.addCase(getMedicoes.pending, (state) => ({
    ...state,
    loadingMedicoes: true,
  }));
  builder.addCase(getMedicoes.rejected, (state) => {
    notification.error({ message: 'Erro ao buscar medições' });
    return {
      ...state,
      loadingMedicoes: false,
    };
  });
  builder.addCase(getMedicoes.fulfilled, (state, action: ExtendedAction) => {
    return {
      ...state,
      loadingMedicoes: false,
      medicoes: action.payload.data,
    };
  });
  builder.addCase(deleteOrcamentoBanco.pending, (state) => ({
    ...state,
    loadingOrcamentosBanco: true,
  }));
  builder.addCase(deleteOrcamentoBanco.rejected, (state) => {
    notification.error({ message: 'Erro ao remover orçamento' });
    return {
      ...state,
      loadingOrcamentosBanco: false,
    };
  });
  builder.addCase(deleteOrcamentoBanco.fulfilled, (state, action: ExtendedAction) => {
    notification.success({ message: 'Orçamento removido com sucesso!' });
    return {
      ...state,
      loadingOrcamentosBanco: false,
      orcamentosBanco: state.orcamentosBanco.filter((c: OrcamentoBanco) => c.id !== action.payload.data),
    };
  });
  builder.addCase(deleteOrcamentoBancoItem.pending, (state) => ({
    ...state,
    deletingItem: true,
  }));
  builder.addCase(deleteOrcamentoBancoItem.rejected, (state) => {
    notification.error({ message: 'Erro ao remover item' });
    return {
      ...state,
      deletingItem: false,
    };
  });
  builder.addCase(deleteOrcamentoBancoItem.fulfilled, (state, action: ExtendedAction) => {
    notification.success({ message: 'Item removido com sucesso!' });
    return {
      ...state,
      deletingItem: false,
      orcamentoBanco: {
        ...state.orcamentoBanco,
        orcamentoBancoItens: state.orcamentoBanco.orcamentoBancoItens.filter((c) => c.id !== action.payload.data),
      },
    };
  });
  builder.addCase(updateValorTotal.fulfilled, () => {
    notification.success({ message: 'Valor total alterado com sucesso!' });
  });
  builder.addCase(novoItemOrcamentoBanco.pending, (state) => ({
    ...state,
    savingItem: true,
  }));
  builder.addCase(novoItemOrcamentoBanco.rejected, (state) => {
    notification.error({ message: 'Erro ao adicionar item' });
    return {
      ...state,
      savingItem: false,
    };
  });
  builder.addCase(novoItemOrcamentoBanco.fulfilled, (state, action: ExtendedAction) => {
    notification.success({ message: 'Item adicionado com sucesso!' });
    return {
      ...state,
      savingItem: false,
      orcamentoBanco: {
        ...state.orcamentoBanco,
        orcamentoBancoItens: action.payload.data.novo
          ? [...state.orcamentoBanco.orcamentoBancoItens, action.payload.data.entity]
          : state.orcamentoBanco.orcamentoBancoItens.map((c) => {
              if (c.id === action.payload.data.entity.id) return action.payload.data.entity;
              else return c;
            }),
      },
    };
  });
  builder.addCase(getOrcamento.pending, (state) => ({
    ...state,
    loadingOrcamentoBanco: true,
  }));
  builder.addCase(getOrcamento.rejected, (state) => ({
    ...state,
    loadingOrcamentoBanco: false,
  }));
  builder.addCase(getOrcamento.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    loadingOrcamentoBanco: false,
    orcamentoBanco: action.payload.data,
  }));
  builder.addCase(novoOrcamentobanco.pending, (state) => ({
    ...state,
    savingOrcamentoBanco: true,
  }));
  builder.addCase(novoOrcamentobanco.rejected, (state) => ({
    ...state,
    savingOrcamentoBanco: false,
  }));
  builder.addCase(novoOrcamentobanco.fulfilled, (_, action: ExtendedAction) => {
    window.location.href = '/OrcamentoBancoForm/' + action.payload.data;
  });
  builder.addCase(getOrcamentos.pending, (state) => ({
    ...state,
    loadingOrcamentos: true,
    orcamentos: [],
  }));
  builder.addCase(getOrcamentos.rejected, (state) => ({
    ...state,
    loadingOrcamentos: false,
  }));
  builder.addCase(getOrcamentos.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    loadingOrcamentos: false,
    orcamentos: action.payload.data,
  }));
  builder.addCase(getModelos.pending, (state) => ({
    ...state,
    loadingModelos: true,
    modelos: [],
  }));
  builder.addCase(getModelos.rejected, (state) => ({
    ...state,
    loadingModelos: false,
  }));
  builder.addCase(getModelos.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    loadingModelos: false,
    modelos: action.payload.data,
  }));
  builder.addCase(getOrcamentosBanco.pending, (state) => ({
    ...state,
    loadingOrcamentosBanco: true,
    orcamentosBanco: [],
  }));
  builder.addCase(getOrcamentosBanco.rejected, (state) => ({
    ...state,
    loadingOrcamentosBanco: false,
  }));
  builder.addCase(getOrcamentosBanco.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    loadingOrcamentosBanco: false,
    orcamentosBanco: action.payload.data,
  }));
  builder.addCase(getCategoriaItens.pending, (state) => ({
    ...state,
    loadingGrupos: true,
    grupos: [],
  }));
  builder.addCase(getCategoriaItens.rejected, (state) => ({
    ...state,
    loadingGrupos: false,
  }));
  builder.addCase(getCategoriaItens.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    loadingGrupos: false,
    grupos: action.payload.data,
  }));
  builder.addCase(getFisico.pending, (state) => ({
    ...state,
    loadingFisico: true,
  }));
  builder.addCase(getFisico.rejected, (state) => ({
    ...state,
    loadingFisico: false,
  }));
  builder.addCase(getFisico.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    loadingFisico: false,
    fisico: action.payload.data.categorias,
    orcamento: action.payload.data.orcamento,
  }));
  builder.addCase(GetAvancos.pending, (state) => ({
    ...state,
    loadingAvancos: true,
  }));
  builder.addCase(GetAvancos.rejected, (state) => ({
    ...state,
    loadingAvancos: false,
  }));
  builder.addCase(GetAvancos.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    loadingAvancos: false,
    avancos: action.payload.data,
  }));
  builder.addCase(getRealizadoMes.pending, (state) => ({
    ...state,
    loadingRealizados: true,
  }));
  builder.addCase(getRealizadoMes.rejected, (state) => ({
    ...state,
    loadingRealizados: false,
  }));
  builder.addCase(getRealizadoMes.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    loadingRealizados: false,
    realizados: action.payload.data.map((c: any) => ({
      ...c,
      mes: moment(`${c.mes}/${c.ano}`, 'MM/YYYY').set('date', 1),
    })),
  }));
  builder.addCase(salvarFisico.pending, (state) => ({
    ...state,
    savingFisico: true,
  }));
  builder.addCase(salvarFisico.rejected, (state) => {
    notification.error({ message: 'Erro ao salvar planejamento!' });
    return {
      ...state,
      savingFisico: false,
    };
  });
  builder.addCase(salvarFisico.fulfilled, (state) => {
    notification.success({ message: 'Planejamento salvo com sucesso!' });
    return {
      ...state,
      savingFisico: false,
    };
  });
  builder.addCase(getFisicos.pending, (state) => ({
    ...state,
    loadingFisicos: true,
  }));
  builder.addCase(getFisicos.rejected, (state) => ({
    ...state,
    loadingFisicos: false,
  }));
  builder.addCase(getFisicos.fulfilled, (state, action: ExtendedAction) => {
    return {
      ...state,
      loadingFisicos: false,
      fisicos: action.payload.data.fisicos,
      valores: action.payload.data.valores,
    };
  });
  builder.addCase(getAvancosFisicos.pending, (state) => ({
    ...state,
    loadingAvancosFisicos: true,
  }));
  builder.addCase(getAvancosFisicos.rejected, (state) => ({
    ...state,
    loadingAvancosFisicos: false,
  }));
  builder.addCase(getAvancosFisicos.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    loadingAvancosFisicos: false,
    avancosFisicos: action.payload.data.fisicos,
    min: moment(action.payload.data.min),
    max: moment(action.payload.data.max),
  }));
  builder.addCase(updateAvancoFisico.pending, (state) => ({
    ...state,
    loadingAvancosFisicos: true,
  }));
  builder.addCase(updateAvancoFisico.rejected, (state, action: ExtendedAction) => {
    notification.error({ message: action.payload.response.data.Message });
    return {
      ...state,
      loadingAvancosFisicos: false,
    };
  });
  builder.addCase(updateAvancoFisico.fulfilled, (state, action: ExtendedAction) => {
    notification.success({ message: 'Progresso atualizado com sucesso' });
    return {
      ...state,
      loadingAvancosFisicos: false,
      avancosFisicos: action.payload.data.novo
        ? [...state.avancosFisicos, action.payload.data.avanco]
        : state.avancosFisicos.map((c) => {
            if (c.id === action.payload.data.avanco.id) {
              return action.payload.data.avanco;
            } else return c;
          }),
    };
  });
});
