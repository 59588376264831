import { AppDispatch } from '@config/reduxStore';
import { apiEndpoints } from '@constants/apiEndpoints';
import { Composicao } from '@models/Composicao';
import { Axios } from '@config/axios';
export const GetComposicoes = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_COMPOSICOES',
      payload: Axios.get(apiEndpoints.COMPOSICOES.GET_COMPOSICOES),
    });
  };
};
export const SetComposicaoForm = function (visible: boolean, composicao: Composicao) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'COMPOSICAO_FORM',
      payload: { visible, composicao },
    });
  };
};
export const AddComposicao = function (values: Composicao) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'ADD_COMPOSICAO',
      payload: Axios.post(apiEndpoints.COMPOSICOES.ADD_COMPOSICAO, values),
    });
  };
};
export const SalvarComposicao = function (values: Composicao) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SALVAR_COMPOSICAO',
      payload: Axios.post(apiEndpoints.COMPOSICOES.SALVAR_COMPOSICAO, values),
    });
  };
};
export const DeleteComposicao = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DELETE_COMPOSICAO',
      payload: Axios.get(apiEndpoints.COMPOSICOES.DELETE_COMPOSICAO, { params: { id: id } }),
    });
  };
};
