import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Ordem } from '@models/Ordem';
import { Descriptions, Input, Modal, notification, Typography } from 'antd';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { moneyMask, moneyNegMask } from '@utils/masks';
import { AjusteNF } from '@actions/novaOrdemActions';

function ModalAjusteNF({
  visible,
  setVisible,
  ordem,
}: {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  ordem: Ordem;
}) {
  const [value, setValue] = useState(moneyNegMask.format(ordem?.valorAjusteNF));
  const dispatch = useAppDispatch();
  const { ajustandoNF } = useAppSelector((state) => state.novaOrdem);
  useEffect(() => {
    if (!ajustandoNF) {
      setVisible(false);
    }
  }, [ajustandoNF, setVisible]);
  useEffect(() => {
    setValue(moneyNegMask.format(ordem?.valorAjusteNF));
  }, [ordem?.valorAjusteNF]);
  return (
    <Modal
      title="Ajuste Nota Fiscal"
      open={visible}
      okButtonProps={{ loading: ajustandoNF, disabled: ajustandoNF }}
      onCancel={() => setVisible(false)}
      onOk={() => {
        if (moneyNegMask.normalize(value) > 10 || moneyNegMask.normalize(value) < -10) {
          notification.error({
            message: 'Valor fora da margem permitida',
          });
        } else {
          dispatch(AjusteNF({ id: ordem?.id, valor: moneyNegMask.normalize(value) }));
          notification.success({ message: 'Ajuste realizado com sucesso!' });
        }
      }}
    >
      <Descriptions size="small" bordered column={1}>
        <Descriptions.Item label="Subtotal">{moneyMask.format(ordem?.subtotal)}</Descriptions.Item>
        <Descriptions.Item label="Desconto">{moneyMask.format(ordem?.desconto)}</Descriptions.Item>
        <Descriptions.Item label="Ajuste NF">
          <Input
            status={moneyNegMask.normalize(value) > 10 || moneyNegMask.normalize(value) < -10 ? 'error' : undefined}
            value={value}
            onChange={(e) => setValue(moneyNegMask.format(moneyNegMask.normalize(e.target.value)))}
          />
          {(moneyNegMask.normalize(value) > 10 || moneyNegMask.normalize(value) < -10) && (
            <Typography.Text type="danger">Máx 10,00. Min -10,00</Typography.Text>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Total">
          {moneyMask.format(ordem?.subtotal - ordem?.desconto + moneyNegMask.normalize(value))}
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
}

export default ModalAjusteNF;
