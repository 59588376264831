import { useEffect, useState } from 'react';
import { Table, Input, Button, Popconfirm } from 'antd';
import { GetCategorias, FormCategoria, DeleteCategoria } from '@actions/categoriasActions';
import CategoriaForm from './CategoriaForm';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { getColumnSearchProps, sortAlpha, filterobject } from '@utils/filterandsort';
import { SetPageHeader } from '@actions/userActions';
import { Permission } from '@hooks//usePermissions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { ColumnsType } from 'antd/lib/table';
import { Categoria } from '@models/Categoria';

function CategoriasView() {
  const dispatch = useAppDispatch();
  const categorias = useAppSelector((state) => state.categorias);
  const [filter, setFilter] = useState('');
  const [filters, setFilters] = useState(null);
  useEffect(() => {
    dispatch(GetCategorias());
    dispatch(
      SetPageHeader({
        title: 'Engenharia',
        backbutton: true,
        extras: [
          <Permission key="1" permission="categoria.add">
            <Button onClick={() => dispatch(FormCategoria(null, true))} type="primary">
              Novo Categoria
            </Button>
          </Permission>,
        ],
        subtitle: 'Categorias',
        menu: 'categorias',
      })
    );
  }, []);
  const columns: ColumnsType<Categoria> = [
    {
      width: 80,
      fixed: 'left',
      render: (_, row) => (
        <div>
          <Button icon={<EditOutlined />} onClick={() => dispatch(FormCategoria(row.id, true))} size="small" />
          <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(DeleteCategoria(row.id))}>
            <Button icon={<DeleteOutlined />} size="small" />
          </Popconfirm>
        </div>
      ),
    },
    {
      width: '50%',
      title: 'Descrição',
      dataIndex: 'descricao',
      sorter: (a, b) => sortAlpha(a.descricao, b.descricao),
      ...getColumnSearchProps('descricao', 'string', 'Descrição', filters),
    },
  ];

  return (
    <div>
      <CategoriaForm />
      <div style={{ textAlign: 'right' }}>
        <Input style={{ width: 200 }} onChange={(e) => setFilter(e.target.value)} placeholder="Buscar" allowClear />
      </div>

      <Table
        size="small"
        rowKey="id"
        onChange={(paginantion, filters, sorter) => setFilters({ paginantion, filters, sorter })}
        loading={categorias.loadingcategorias}
        columns={columns}
        dataSource={filterobject(categorias.categorias, filter)}
        scroll={{ y: 'calc(100vh - 300px)', x: 400 }}
        pagination={{
          position: ['bottomRight'],
          size: 'small',
          showSizeChanger: true,
          pageSizeOptions: [10, 25, 50, 100],
          showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
        }}
      />
    </div>
  );
}
export default CategoriasView;
