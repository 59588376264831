import { AppDispatch } from '@config/reduxStore';
import { apiEndpoints } from '@constants/apiEndpoints';
import { CCA } from '@models/CCA';
import { Axios } from '@config/axios';

export const GetCCAs = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_CCAS',
      payload: Axios.get(apiEndpoints.CCAS.GET_CCAS),
    });
  };
};
export const SalvarCCA = function (values: CCA) {
  return function (dispatch: AppDispatch) {
    if (values.id) {
      dispatch({
        type: 'UPDATE_CCA',
        payload: Axios.post(apiEndpoints.CCAS.SALVAR_CCA, { ...values }),
      });
    } else {
      dispatch({
        type: 'ADD_CCA',
        payload: Axios.post(apiEndpoints.CCAS.SALVAR_CCA, { ...values }),
      });
    }
  };
};
export const DeleteCCA = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DELETE_CCA',
      payload: Axios.get(apiEndpoints.CCAS.DELETE_CCA, { params: { id } }),
    });
  };
};
export const FormCCA = function (id: number | null, open: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'FORM_CCA',
      payload: { id, open },
    });
  };
};
