import { AppDispatch } from '@config/reduxStore';
import { apiEndpoints } from '@constants/apiEndpoints';
import { Ordem } from '@models/Ordem';
import { Axios } from '@config/axios';

export const GetOrdens = function (situacao: string, itemID: number, canceladas: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_ORDENS',
      payload: Axios.get(apiEndpoints.ORDENS.GET_ORDENS, {
        params: {
          situacao,
          itemID,
          canceladas,
        },
      }),
    });
  };
};

export const GetTransferencias = function (situacao: string, itemID: number, aprovar: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_TRANSFERENCIAS',
      payload: Axios.get(apiEndpoints.ORDENS.GET_TRANSFERENCIAS, { params: { situacao, itemID, aprovar } }),
    });
  };
};
export const GetTransferenciasAprovar = function (situacao: string, itemID: number, aprovar: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_TRANSFERENCIASP',
      payload: Axios.get(apiEndpoints.ORDENS.GET_TRANSFERENCIAS, { params: { situacao, itemID, aprovar } }),
    });
  };
};
export const GetOrdensAprovar = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_ORDENSP',
      payload: Axios.get(apiEndpoints.ORDENS.GET_ORDENS_APROVAR),
    });
  };
};

export const SalvarOrdem = function (values: Ordem) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'ADD_ORDEM',
      payload: Axios.post(apiEndpoints.ORDENS.ADD_SALVAR, { ...values }),
    });
  };
};

export const DeleteOrdem = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DELETE_ORDEM',
      payload: Axios.get(apiEndpoints.ORDENS.DELETE_ORDEM, { params: { id } }),
    });
  };
};

export const GetOrdemEdit = function (id: number | string) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_ORDEM_EDIT',
      payload: Axios.get(apiEndpoints.ORDENS.GET_ORDEM_EDIT, { params: { id } }),
    });
  };
};
export const FormOrdem = function (id: number, open: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'FORM_ORDEM',
      payload: { id, open },
    });
  };
};
export const FormRecibo = function (id: number, open: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'FORM_RECIBO',
      payload: { id, open },
    });
  };
};
export const FormCompilar = function (id: number, open: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'FORM_COMPILAR',
      payload: { id, open },
    });
  };
};

export const CancelarOrdem = function (id: number, motivo: string) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'CANCELAR_ORDEM',
      payload: Axios.get(apiEndpoints.ORDENS.CANCELAR_ORDEM, { params: { id, motivo } }),
    });
  };
};

export const CancelarTransferencia = function (id: number, motivo: string) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'CANCELAR_TRANSFERENCIA',
      payload: Axios.get(apiEndpoints.ORDENS.CANCELAR_TRANSFERENCIA, { params: { id, motivo } }),
    });
  };
};
export const PagarOrdem = function (data: { id?: number | string; cod?: string }) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'PAGAR_ORDEM',
      payload: Axios.get(apiEndpoints.ORDENS.PAGAR_ORDEM, { params: data }),
    });
  };
};
export const ProcessarTransferencia = function (id: number | string) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'PROCESSAR_TRANSFERENCIA',
      payload: Axios.get(apiEndpoints.ORDENS.PROCESSAR_TRANSFERENCIA, { params: { id } }),
    });
  };
};

export const ConfirmarDocumentacao = function (data: { id?: number | string; cod?: string }) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'CONFIRMAR_DOCUMENTACAO_ORDEM',
      payload: Axios.get(apiEndpoints.ORDENS.CONFIRMAR_DOCUMENTACAO, { params: data }),
    });
  };
};
export const SubmeterOrdem = function (data: { id?: number | string; cod?: string }) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SUBMETER_ORDEM',
      payload: Axios.get(apiEndpoints.ORDENS.SUBMETER_ORDEM, { params: data }),
    });
  };
};
export const ClearOrdem = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'CLEAR_ORDEM',
    });
  };
};
export const AprovarOrdem = function (id: number | number, justificativa?: string) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'APROVAR_ORDEM',
      payload: Axios.get(apiEndpoints.ORDENS.APROVAR_ORDEM, { params: { id, justificativa } }),
    });
  };
};

export const AprovarTransferencia = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'APROVAR_TRANSFERENCIA',
      payload: Axios.get(apiEndpoints.ORDENS.APROVAR_TRANSFERENCIA, { params: { id } }),
    });
  };
};

export const ExcluirItemOrdem = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'EXCLUIR_ITEM_ORDEM',
      payload: Axios.get(apiEndpoints.ORDENS.EXCLUIR_ITEM_ORDEM, { params: { id } }),
    });
  };
};
export const FinalizarOrdem = function (data: { id?: number | string; cod?: string }) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'FINALIZAR_ORDEM',
      payload: Axios.get(apiEndpoints.ORDENS.FINALIZAR_ORDEM, { params: data }),
    });
  };
};
export const SolicitarPagamento = function (data: { id?: number | string; cod?: string }) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SOLICITAR_PAGAMENTO_ORDEM',
      payload: Axios.get(apiEndpoints.ORDENS.SOLICITAR_PAGAMENTO, { params: data }),
    });
  };
};
export const PagarParcial = function (data: {
  valor: number;
  data: Date;
  ordemID: number;
  id: number;
  cod: String;
  formaPagamento: number;
}) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'PAGAR_PARCIAL_ORDEM',
      payload: Axios.post(apiEndpoints.ORDENS.PAGAR_PARCIAL, data),
    });
  };
};
export const UploadFiles = function (form: FormData) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'UPLOAD_FILES_ORDEM',
      payload: Axios.post(apiEndpoints.ORDENS.POST_FILE, form),
    });
  };
};
export const DownloadArquivo = function (fileid: number, filename: string) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DOWNLOAD_FILE_ORDEM',
      payload: Axios.get(apiEndpoints.ORDENS.DOWNLOAD_FILE, { params: { fileid }, responseType: 'blob' }).then(
        (res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        }
      ),
    });
  };
};
export const DeleteNotaFiscal = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DELETE_NF_ORDEM',
      payload: Axios.get(apiEndpoints.ORDENS.DELETE_NF_ORDEM, { params: { id } }),
    });
  };
};
export const AddNotaFiscal = function (id: number, numero: string) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'ADD_NF_ORDEM',
      payload: Axios.post(apiEndpoints.ORDENS.ADD_NF_ORDEM, { OrdemID: id, NumeroNF: numero }),
    });
  };
};
export const DeleteArquivo = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DELETE_FILE_ORDEM',
      payload: Axios.post(apiEndpoints.ORDENS.DELETE_ARQUIVO, { arquivoid: id }),
    });
  };
};

export const DeleteArquivoPedido = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DELETE_FILE_PEDIDO',
      payload: Axios.post(apiEndpoints.ORDENS.DELETE_ARQUIVO, { arquivoid: id }),
    });
  };
};
export const CheckSaldo = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'CHECK_SALDO',
      payload: Axios.get(apiEndpoints.ORDENS.CHECK_SALDO, { params: { orcamentoItemID: id } }),
    });
  };
};
export const CompilarArquivosOrdem = function (id: number, arquivos: number[]) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'COMPILAR_ARQUIVOSORDEM',
      payload: Axios.post(apiEndpoints.ORDENS.COMPILAR, {
        ordemid: id,
        arquivosIds: JSON.stringify(arquivos),
      }),
    });
  };
};
export const CompilarArquivosPedido = function (id: number, arquivos: number[]) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'COMPILAR_ARQUIVOS_PEDIDO',
      payload: Axios.post(apiEndpoints.ORDENS.COMPILAR, {
        ordemid: id,
        arquivosIds: JSON.stringify(arquivos),
      }),
    });
  };
};

export const SetSelectedArquivos = function (values: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SELECTED_ARQUIVOS',
      payload: values,
    });
  };
};
