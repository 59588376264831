import { Alert, Badge, Tabs } from 'antd';
import FolhaFormOrcamentoAddItens from './FolhaForm.Orcamento.AddItens';
import FolhaFormOrcamentoRemoveItens from './FolhaForm.Orcamento.RemoveItens';
import { useFolhaForm } from './useFolhaForm';

function FolhaFormOrcamento() {
  const { folha } = useFolhaForm();
  return (
    <div>
      <Alert
        message="Adicione os colaboradores que farão parte da folha"
        type="info"
        showIcon
        closable
        style={{ width: 'fit-content', margin: 5 }}
      />
      <Tabs
        items={[
          {
            key: 'additens',
            tabKey: 'additens',
            label: 'Itens de Orçamento',
            children: <FolhaFormOrcamentoAddItens />,
          },
          {
            key: 'removeitens',
            tabKey: 'removeitens',
            label: (
              <Badge count={folha?.itens.length} offset={[10, 0]}>
                Itens Adicionados
              </Badge>
            ),
            children: <FolhaFormOrcamentoRemoveItens />,
          },
        ]}
      />
    </div>
  );
}

export default FolhaFormOrcamento;
