import { ExtendedAction } from '@config/reduxStore';
import { ProdutoBancario } from '@models/ProdutoBancario';
import { notification } from 'antd';

interface ProdutosReducer {
  produtos: ProdutoBancario[];
  formview: boolean;
  produto: number;
  loadingprodutos: boolean;
  savingproduto: boolean;
  feedback: string;
}
var initialState: ProdutosReducer = {
  produtos: [],
  formview: false,
  produto: null,
  loadingprodutos: false,
  savingproduto: false,
  feedback: '',
};

const produtosReducer = function (state = initialState, action: ExtendedAction) {
  switch (action.type) {
    case 'RESET_FEEDBACK':
      state = {
        ...state,
        feedback: '',
      };
      break;
    case 'FORM_PRODUTO':
      state = {
        ...state,
        formview: action.payload.open,
        feedback: '',
        produto: action.payload.id ? action.payload.id : null,
      };
      break;
    case 'GET_PRODUTOS_PENDING':
      state = {
        ...state,
        loadingprodutos: true,
        produtos: initialState.produtos,
      };
      break;
    case 'GET_PRODUTOS_REJECTED':
      state = {
        ...state,
        loadingprodutos: false,
      };
      break;
    case 'GET_PRODUTOS_FULFILLED':
      state = {
        ...state,
        loadingprodutos: false,
        produtos: action.payload.data,
      };
      break;
    case 'ADD_PRODUTO_PENDING':
      state = {
        ...state,
        savingproduto: true,
        feedback: '',
      };
      break;
    case 'ADD_PRODUTO_REJECTED':
      state = {
        ...state,
        savingproduto: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: 'Erro ao cadastrar produto!' });
      break;
    case 'ADD_PRODUTO_FULFILLED':
      state = {
        ...state,
        savingproduto: false,
        feedback: 'Produto criado com sucesso!',
        produtos: [...state.produtos, action.payload.data],
      };
      notification.success({ message: 'Produto cadastrado com sucesso!' });
      break;
    case 'UPDATE_PRODUTO_PENDING':
      state = {
        ...state,
        savingproduto: true,
        feedback: '',
      };
      break;
    case 'UPDATE_PRODUTO_REJECTED':
      state = {
        ...state,
        savingproduto: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: 'Erro ao atualizar produto' });
      break;
    case 'UPDATE_PRODUTO_FULFILLED':
      state = {
        ...state,
        savingproduto: false,
        feedback: 'Produto atualizado com sucesso!',
        produtos: state.produtos.map((c) => {
          if (c.id === action.payload.data.id) return action.payload.data;
          else return c;
        }),
      };
      notification.success({ message: 'Produto atualizado com sucesso!' });
      break;
    case 'DELETE_PRODUTO_PENDING':
      state = {
        ...state,
        loadingprodutos: true,
        feedback: '',
      };
      break;
    case 'DELETE_PRODUTO_REJECTED':
      state = {
        ...state,
        loadingprodutos: false,
        feedback: action.payload.response.data.Message,
      };
      notification.warning({ message: state.feedback });
      break;
    case 'DELETE_PRODUTO_FULFILLED':
      state = {
        ...state,
        loadingprodutos: false,
        feedback: 'Produto excluído com sucesso!',
        produtos: state.produtos.filter((c) => c.id !== action.payload.data),
      };
      notification.success({ message: state.feedback });
      break;
    default:
      return state;
  }
  return state;
};

export default produtosReducer;
