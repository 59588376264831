import { useEffect, useState } from 'react';
import { Table, Button, Popconfirm, Input, Checkbox, Modal, Row, Col, Tooltip } from 'antd';
import { GetCasas, FormCasa, DeleteCasa } from '@actions/casasActions';
import { DeleteOutlined, ProfileOutlined } from '@ant-design/icons';
import CasaForm from './CasaForm';
import { moneyMask } from '@utils/masks';
import { GetEmps } from '@actions/empsActions';
import moment from 'moment';
import { orderDate, filterobject, getColumnSearchProps, sortAlpha } from '@utils/filterandsort';
import { Permission, HasRole } from '@hooks//usePermissions';
import { SetPageHeader } from '@actions/userActions';
import { GetCorretores } from '@actions/corretoresActions';
import { GetResponsaveis } from '@actions/chamadosActions';
import { GetAgencias } from '@actions/agenciasActions';
import { GetEmpresas } from '@actions/empresasActions';
import { Link, useLocation } from 'react-router-dom';
import { setCookie, getCookie } from '@services/localStorage';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

function CasasView() {
  const dispatch = useAppDispatch();
  const casas = useAppSelector((state) => state.casas);
  const roles = useAppSelector((state) => state.user.user.roles);

  const { emps } = useAppSelector((state) => state.emps);
  const { corretores } = useAppSelector((state) => state.corretores);
  const [filter, setFilter] = useState('');
  const [showCols, setShowCols] = useState(false);
  const cookievalues2 = JSON.parse(getCookie('casasfilters') || null) || null;
  const [filters, setfilters] = useState(cookievalues2);
  const location = useLocation();
  useEffect(() => {
    dispatch(GetCasas());
    dispatch(GetCorretores());
    dispatch(GetEmps());
    dispatch(GetResponsaveis());
    dispatch(GetAgencias());
    dispatch(GetEmpresas());
    dispatch(
      SetPageHeader({
        title: 'Unidades',
        backbutton: true,
        extras: [
          <Permission key="1" permission="casa.add">
            <Button onClick={() => dispatch(FormCasa(null, true))} type="primary">
              Nova Casa
            </Button>
          </Permission>,
        ],
        subtitle: 'Casas',
        menu: 'casas',
      })
    );
  }, []);

  const colunas: any = [
    {
      dataIndex: 'id',
      width: 120,
      allowed: false,
      fixed: 'left',
      render: (text: any, row: any) => (
        <div>
          <Tooltip title="Ficha do Imóvel">
            <Link to={`/Casas/${text}`} state={{ backgroundLocation: location }}>
              <Button icon={<ProfileOutlined />} size="small" />
            </Link>
          </Tooltip>
          <Permission permission="casa.delete">
            <Tooltip title="Excluir Imóvel">
              <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(DeleteCasa(row.id))}>
                <Button icon={<DeleteOutlined />} size="small" />
              </Popconfirm>
            </Tooltip>
          </Permission>
        </div>
      ),
      visible: true,
    },
    {
      title: 'Vendida',
      dataIndex: 'vendida',
      visible: false,
      filteredValue: filters ? filters.filters.vendida : null,
      allowed: HasRole(['financeiro', 'master', 'admin'], roles),
      sorter: (a: any, b: any) => a.vendida - b.vendida,
      render: (text: any) => (text === true ? 'Sim' : 'Não'),
      onFilter: (value: any, row: any) => row.vendida === value,
      filters: [
        { value: true, text: 'Sim' },
        { value: false, text: 'Não' },
      ],
    },

    {
      title: 'Empresa Legalização',
      dataIndex: 'empresaLegalizacao',
      visible: true,
      allowed: true,
      sorter: (a: any, b: any) => sortAlpha(a.empresaLegalizacao as string, b.empresaLegalizacao as string),
      ...getColumnSearchProps('empresaLegalizacao', 'string', 'Empresa Legalização.', filters),
    },
    {
      title: 'Área Construída',
      dataIndex: 'area',
      visible: true,
      allowed: true,
      sorter: (a: any, b: any) => sortAlpha(a.area, b.area),
      render: (text: any) => (text ? text + 'm²' : '0m²'),
      ...getColumnSearchProps('area', 'string', 'Área Construída', filters),
    },
    {
      title: 'Valor/m²',
      dataIndex: 'valorM2',
      visible: true,
      allowed: HasRole(['master', 'admin', 'financeiro', 'comercial'], roles),
      sorter: (a: any, b: any) => sortAlpha(a.valorM2, b.valorM2),
      render: (value: any) => moneyMask.format(value),
      ...getColumnSearchProps('valorM2', 'string', 'Valor/m²', filters),
    },
    {
      title: 'Aditivos',
      dataIndex: 'aditivos',
      visible: true,
      allowed: HasRole(['master', 'admin', 'financeiro'], roles),
      sorter: (a: any, b: any) => sortAlpha(a.aditivos, b.aditivos),
      render: (text: any) => moneyMask.format(text),
      ...getColumnSearchProps('aditivos', 'string', 'Aditivos', filters),
    },
    {
      title: 'Receitas Total',
      dataIndex: 'receitasTotal',
      visible: true,
      allowed: HasRole(['master', 'admin', 'financeiro'], roles),
      sorter: (a: any, b: any) => sortAlpha(a.receitasTotal, b.receitasTotal),
      render: (text: number) => moneyMask.format(text),
      ...getColumnSearchProps('receitasTotal', 'string', 'Receitas', filters),
    },
    {
      title: 'Receitas Recebidas',
      dataIndex: 'receitasRecebidas',
      visible: true,
      allowed: HasRole(['master', 'admin', 'financeiro'], roles),
      sorter: (a: any, b: any) => sortAlpha(a.receitasRecebidas, b.receitasRecebidas),
      render: (text: number) => moneyMask.format(text),
      ...getColumnSearchProps('receitasRecebidas', 'string', 'Receitas', filters),
    },
    {
      title: 'Receitas Pendentes',
      dataIndex: 'receitasPendentes',
      visible: false,
      allowed: HasRole(['master', 'admin', 'financeiro'], roles),
      sorter: (a: any, b: any) => sortAlpha(a.receitasPendentes, b.receitasPendentes),
      render: (text: number) => moneyMask.format(text),
      ...getColumnSearchProps('receitasPendentes', 'string', 'Receitas', filters),
    },
    {
      title: 'Receitas Vencidas',
      dataIndex: 'receitaVencidas',
      visible: true,
      allowed: HasRole(['master', 'admin', 'financeiro'], roles),
      sorter: (a: any, b: any) => sortAlpha(a.receitaVencidas, b.receitaVencidas),
      render: (text: number) => moneyMask.format(text),
      ...getColumnSearchProps('receitaVencidas', 'string', 'Receitas', filters),
    },
    {
      title: 'Cliente Construção',
      dataIndex: 'clienteObra',
      allowed: true,
      visible: false,
      sorter: (a: any, b: any) => sortAlpha(a.clienteObra, b.clienteObra),
      render: (text: any) => (text ? 'Sim' : 'Não'),
      filteredValue: filters ? filters.filters.clienteObra : null,
      onFilter: (value: any, row: any) => row.clienteObra === value,
      filters: [
        { value: false, text: 'Não' },
        { value: true, text: 'Sim' },
      ],
    },
    {
      title: 'Pavimentos',
      dataIndex: 'pavimentos',
      visible: true,
      allowed: true,
      sorter: (a: any, b: any) => sortAlpha(a.pavimentos, b.pavimentos),
      ...getColumnSearchProps('pavimentos', 'string', 'Pavimentos', filters),
    },
    {
      title: 'Banheiros',
      dataIndex: 'banheiros',
      visible: false,
      allowed: true,
      sorter: (a: any, b: any) => sortAlpha(a.banheiros, b.banheiros),
      ...getColumnSearchProps('banheiros', 'string', 'Banheiros', filters),
    },
    {
      title: 'Endereço',
      dataIndex: 'endereco',
      visible: true,
      allowed: true,
      sorter: (a: any, b: any) => sortAlpha(a.endereco, b.endereco),
      ...getColumnSearchProps('endereco', 'string', 'Endereço', filters),
    },
    {
      title: 'Número',
      dataIndex: 'numero',
      visible: true,
      allowed: true,
      sorter: (a: any, b: any) => sortAlpha(a.numero, b.numero),
      ...getColumnSearchProps('numero', 'string', 'Número', filters),
    },
    {
      title: 'Complemento',
      dataIndex: 'complemento',
      visible: true,
      allowed: true,
      sorter: (a: any, b: any) => sortAlpha(a.complemento, b.complemento),
      ...getColumnSearchProps('complemento', 'string', 'Complemento', filters),
    },
    {
      title: 'Quadra',
      dataIndex: 'quadra',
      visible: false,
      allowed: true,
      sorter: (a: any, b: any) => sortAlpha(a.quadra, b.quadra),
      ...getColumnSearchProps('quadra', 'string', 'Quadra', filters),
    },
    {
      title: 'Lote',
      dataIndex: 'lote',
      visible: false,
      allowed: true,
      sorter: (a: any, b: any) => sortAlpha(a.lote, b.lote),
      ...getColumnSearchProps('lote', 'string', 'Lote', filters),
    },
    {
      title: 'CEP',
      dataIndex: 'cep',
      allowed: true,
      visible: false,
      sorter: (a: any, b: any) => sortAlpha(a.cep, b.cep),
      ...getColumnSearchProps('cep', 'string', 'CEP', filters),
    },
    {
      title: 'Empreendimento',
      dataIndex: 'empreendimento',
      visible: true,
      allowed: true,
      filteredValue: filters ? filters.filters.empreendimento : null,
      sorter: (a: any, b: any) => sortAlpha(a.empresa, b.empresa),
      onFilter: (value: any, row: any) => row.empreendimento === value,
      filters: emps.map((c) => ({ value: c.nome, text: c.nome })),
    },
    {
      title: 'Alvará',
      dataIndex: 'alvara',
      visible: false,
      allowed: true,
      sorter: (a: any, b: any) => sortAlpha(a.alvara, b.alvara),
      ...getColumnSearchProps('alvara', 'string', 'Alvará', filters),
    },
    {
      title: 'Data Alvara',
      dataIndex: 'dataAlvara',
      visible: false,
      allowed: true,
      sorter: (a: any, b: any) => orderDate(a.dataAlvara, b.dataAlvara),
      render: (value: any) => (moment(value).isValid() ? moment(value).format('DD/MM/YYYY') : ''),
      ...getColumnSearchProps('dataAlvara', 'daterange', 'Data Alvara', filters),
    },
    {
      title: 'ART',
      dataIndex: 'art',
      visible: false,
      allowed: true,
      sorter: (a: any, b: any) => sortAlpha(a.art, b.art),
      ...getColumnSearchProps('art', 'string', 'ART', filters),
    },
    {
      title: 'Habite-se',
      dataIndex: 'habitese',
      visible: false,
      allowed: true,
      sorter: (a: any, b: any) => sortAlpha(a.habitese, b.habitese),
      ...getColumnSearchProps('habitese', 'string', 'Habite-se', filters),
    },
    {
      title: 'Data Habite-se',
      dataIndex: 'dataHabitese',
      visible: false,
      allowed: true,
      sorter: (a: any, b: any) => orderDate(a.dataHabitese, b.dataHabitese),
      render: (value: any) => (moment(value).isValid() ? moment(value).format('DD/MM/YYYY') : ''),
      ...getColumnSearchProps('dataHabitese', 'daterange', 'Data Habite-se', filters),
    },
    {
      title: 'Sequencial',
      dataIndex: 'sequencial',
      visible: false,
      allowed: true,
      sorter: (a: any, b: any) => sortAlpha(a.sequencial, b.sequencial),
      ...getColumnSearchProps('sequencial', 'string', 'Sequencial', filters),
    },
    {
      title: 'Matrícula CEI',
      dataIndex: 'matriculaCEI',
      visible: false,
      allowed: true,
      sorter: (a: any, b: any) => sortAlpha(a.matriculaCEI, b.matriculaCEI),
      ...getColumnSearchProps('matriculaCEI', 'string', 'Matrícula CEI', filters),
    },
    {
      title: 'Matrícula Filha',
      dataIndex: 'matriculaFilha',
      visible: false,
      allowed: true,
      sorter: (a: any, b: any) => sortAlpha(a.matriculaFilha, b.matriculaFilha),
      ...getColumnSearchProps('matriculaFilha', 'string', 'Matrícula Filha', filters),
    },
    {
      title: 'Matrícula Mãe',
      dataIndex: 'matriculaMae',
      visible: false,
      allowed: true,
      sorter: (a: any, b: any) => sortAlpha(a.matriculaMae, b.matriculaMae),
      ...getColumnSearchProps('matriculaMae', 'string', 'Matrícula Mãe', filters),
    },
    {
      title: 'Laudo',
      dataIndex: 'laudo',
      visible: false,
      allowed: true,
      sorter: (a: any, b: any) => a.laudo - b.laudo,
      render: (value: any) => moneyMask.format(value),
      ...getColumnSearchProps('laudo', 'string', 'Laudo', filters),
    },
    {
      title: 'Empresa Laudo',
      dataIndex: 'empresaLaudo',
      visible: false,
      allowed: true,
      sorter: (a: any, b: any) => sortAlpha(a.empresaLaudo, b.empresaLaudo),
      ...getColumnSearchProps('empresaLaudo', 'string', 'Empresa Laudo', filters),
    },
    {
      title: 'Proprietário 1',
      dataIndex: 'proprietarioNome1',
      visible: false,
      allowed: true,
      sorter: (a: any, b: any) => sortAlpha(a.proprietarioNome1, b.proprietarioNome1),
      ...getColumnSearchProps('proprietarioNome1', 'string', 'Proprietário 1', filters),
    },
    {
      title: 'CPF Proprietário 1',
      dataIndex: 'proprietarioCPF1',
      visible: false,
      allowed: true,
      sorter: (a: any, b: any) => sortAlpha(a.proprietarioCPF1, b.proprietarioCPF1),
      ...getColumnSearchProps('proprietarioCPF1', 'string', 'CPF Proprietário 1', filters),
    },

    {
      title: 'Nascimento Prop. 1',
      dataIndex: 'proprietarioNascimento1',
      visible: false,
      allowed: true,
      sorter: (a: any, b: any) => moment(a.proprietarioNascimento1).isAfter(moment(b.proprietarioNascimento1)),
      render: (value: any) => (moment(value).isValid() ? moment(value).format('DD/MM/YYYY') : ''),
      ...getColumnSearchProps('proprietarioNascimento1', 'daterange', 'Nascimento Prop. 1', filters),
    },
    {
      title: 'Telefone Proprietário 1',
      dataIndex: 'proprietarioTelefone1',
      visible: false,
      allowed: true,
      sorter: (a: any, b: any) => sortAlpha(a.proprietarioTelefone1, b.proprietarioTelefone1),
      ...getColumnSearchProps('proprietarioTelefone1', 'string', 'Telefone Proprietário 1', filters),
    },
    {
      title: 'Proprietário 2',
      dataIndex: 'proprietarioNome2',
      visible: false,
      allowed: true,
      sorter: (a: any, b: any) => sortAlpha(a.proprietarioNome2, b.proprietarioNome2),
      ...getColumnSearchProps('proprietarioNome2', 'string', 'Proprietário 2', filters),
    },
    {
      title: 'CPF Proprietário 2',
      dataIndex: 'proprietarioCPF2',
      visible: false,
      allowed: true,
      sorter: (a: any, b: any) => sortAlpha(a.proprietarioCPF2, b.proprietarioCPF2),
      ...getColumnSearchProps('proprietarioCPF2', 'string', 'CPF Proprietário 2', filters),
    },

    {
      title: 'Nascimento Prop. 2',
      dataIndex: 'proprietarioNascimento2',
      visible: false,
      allowed: true,
      sorter: (a: any, b: any) => moment(a.proprietarioNascimento2).isAfter(moment(b.proprietarioNascimento2)),
      render: (value: any) => (moment(value).isValid() ? moment(value).format('DD/MM/YYYY') : ''),
      ...getColumnSearchProps('proprietarioNascimento2', 'string', 'Nascimento Prop. 2', filters),
    },
    {
      title: 'Telefone Proprietário 2',
      dataIndex: 'proprietarioTelefone2',
      visible: false,
      allowed: true,
      sorter: (a: any, b: any) => sortAlpha(a.proprietarioTelefone2, b.proprietarioTelefone2),
      ...getColumnSearchProps('proprietarioTelefone2', 'string', 'Telefone Proprietário 2', filters),
    },
    {
      title: 'Parceiro',
      dataIndex: 'corretor',
      visible: false,
      allowed: true,
      filteredValue: filters ? filters.filters.corretor : null,
      sorter: (a: any, b: any) => sortAlpha(a.corretor, b.corretor),
      onFilter: (value: any, row: any) => row.corretor.includes(value),
      filters: corretores.map((c) => ({ value: c.nome, text: c.nome })),
    },
    {
      title: 'Data Entrega Casa',
      dataIndex: 'dataEntrega',
      visible: false,
      allowed: true,
      sorter: (a: any, b: any) => orderDate(a.dataEntrega, b.dataEntrega),
      render: (value: any) => (moment(value).isValid() ? moment(value).format('DD/MM/YYYY') : ''),
      ...getColumnSearchProps('dataEntrega', 'daterange', 'Data Entrega Casa', filters),
    },
    {
      title: 'Data Contrato',
      dataIndex: 'dataContrato',
      visible: false,
      allowed: HasRole(['financeiro', 'master', 'admin', 'contabilidade'], roles),
      sorter: (a: any, b: any) => orderDate(a.dataContrato, b.dataContrato),
      render: (value: any) => (moment(value).isValid() ? moment(value).format('DD/MM/YYYY') : ''),
      ...getColumnSearchProps('dataContrato', 'daterange', 'Data Contrato', filters),
    },
    {
      title: 'Data Contrato Obra',
      dataIndex: 'dataContratoObra',
      visible: false,
      allowed: HasRole(['financeiro', 'master', 'admin', 'contabilidade'], roles),
      sorter: (a: any, b: any) => orderDate(a.dataContratoObra, b.dataContratoObra),
      render: (value: any) => (moment(value).isValid() ? moment(value).format('DD/MM/YYYY') : ''),
      ...getColumnSearchProps('dataContratoObra', 'daterange', 'Data Contrato Obra', filters),
    },
    {
      title: 'Nº Contrato',
      dataIndex: 'contrato',
      visible: false,
      allowed: HasRole(['financeiro', 'master', 'admin', 'contabilidade'], roles),
      sorter: (a: any, b: any) => sortAlpha(a.contrato, b.contrato),
      ...getColumnSearchProps('contrato', 'string', 'Nº Contrato', filters),
    },
    {
      title: 'Agência',
      dataIndex: 'agencia',
      visible: false,
      allowed: HasRole(['financeiro', 'master', 'admin', 'contabilidade'], roles),
      sorter: (a: any, b: any) => sortAlpha(a.agencia, b.agencia),
      ...getColumnSearchProps('agencia', 'string', 'Agência', filters),
    },
    {
      title: 'Valor Compra/Venda',
      dataIndex: 'valorCompraVenda',
      visible: false,
      allowed: HasRole(['financeiro', 'master', 'admin', 'contabilidade'], roles),
      sorter: (a: any, b: any) => a.valorCompraVenda - b.valorCompraVenda,
      render: (value: any) => moneyMask.format(value),
      ...getColumnSearchProps('valorCompraVenda', 'string', 'Valor Compra/Venda', filters),
    },
    {
      title: 'Valor Financiado',
      dataIndex: 'valorFinanciado',
      visible: false,
      allowed: HasRole(['financeiro', 'master', 'admin', 'contabilidade'], roles),
      sorter: (a: any, b: any) => a.valorFinanciado - b.valorFinanciado,
      render: (value: any) => moneyMask.format(value),
      ...getColumnSearchProps('valorFinanciado', 'string', 'Valor Financiado', filters),
    },
    {
      title: 'FGTS',
      dataIndex: 'valorFGTS',
      visible: false,
      allowed: HasRole(['financeiro', 'master', 'admin', 'contabilidade'], roles),
      sorter: (a: any, b: any) => a.valorFGTS - b.valorFGTS,
      render: (value: any) => moneyMask.format(value),
      ...getColumnSearchProps('valorFGTS', 'string', 'FGTS', filters),
    },
    {
      title: 'Subsídio',
      dataIndex: 'valorSubsidio',
      visible: false,
      allowed: HasRole(['financeiro', 'master', 'admin', 'contabilidade'], roles),
      sorter: (a: any, b: any) => a.valorSubsidio - b.valorSubsidio,
      render: (value: any) => moneyMask.format(value),
      ...getColumnSearchProps('valorSubsidio', 'string', 'Subsídio', filters),
    },
    {
      title: 'Desconto',
      dataIndex: 'valorDesconto',
      visible: false,
      allowed: HasRole(['financeiro', 'master', 'admin', 'contabilidade'], roles),
      sorter: (a: any, b: any) => a.valorDesconto - b.valorDesconto,
      render: (value: any) => moneyMask.format(value),
      ...getColumnSearchProps('valorDesconto', 'string', 'Desconto', filters),
    },
    {
      title: 'Entrada',
      dataIndex: 'valorEntrada',
      visible: false,
      allowed: HasRole(['financeiro', 'master', 'admin', 'contabilidade'], roles),
      sorter: (a: any, b: any) => a.valorEntrada - b.valorEntrada,
      render: (value: any) => moneyMask.format(value),
      ...getColumnSearchProps('valorEntrada', 'string', 'Entrada', filters),
    },
    {
      title: 'Valor Líquido',
      dataIndex: 'valorTotalLiquido',
      visible: false,
      allowed: HasRole(['financeiro', 'master', 'admin', 'contabilidade'], roles),
      sorter: (a: any, b: any) => a.valorTotalLiquido - b.valorTotalLiquido,
      render: (value: any) => moneyMask.format(value),
      ...getColumnSearchProps('valorTotalLiquido', 'string', 'Valor Líquido', filters),
    },
    {
      title: 'Documentação Inclusa',
      dataIndex: 'documentacaoInclusa',
      visible: false,
      filteredValue: filters ? filters.filters.documentacaoInclusa : null,
      allowed: HasRole(['financeiro', 'master', 'admin', 'engenharia', 'correspondencia'], roles),
      sorter: (a: any, b: any) => sortAlpha(a.documentacaoInclusa, b.documentacaoInclusa),
      render: (text: any) => (text === true ? 'Sim' : 'Não'),
      onFilter: (value: any, row: any) => row.documentacaoInclusa === value,
      filters: [
        { value: true, text: 'Sim' },
        { value: false, text: 'Não' },
      ],
    },
    {
      title: 'Valor Contrato Obra',
      dataIndex: 'valorContratoObra',
      visible: false,
      allowed: HasRole(['financeiro', 'master', 'admin', 'contabilidade'], roles),
      sorter: (a: any, b: any) => a.valorContratoObra - b.valorContratoObra,
      render: (value: any) => moneyMask.format(value),
      ...getColumnSearchProps('valorContratoObra', 'string', 'Valor Contrato Obra', filters),
    },
    {
      title: 'Contrato Entrou',
      dataIndex: 'contratoEntrou',
      visible: false,
      filteredValue: filters ? filters.filters.contratoEntrou : null,
      allowed: HasRole(['financeiro', 'master', 'admin', 'contabilidade'], roles),
      sorter: (a: any, b: any) => sortAlpha(a.contratoEntrou, b.contratoEntrou),
      render: (text: any) => (text === true ? 'Sim' : 'Não'),
      onFilter: (value: any, row: any) => row.contratoEntrou === value,
      filters: [
        { value: true, text: 'Sim' },
        { value: false, text: 'Não' },
      ],
    },
    {
      title: 'Produto Agência',
      dataIndex: 'produtoAgencia',
      visible: false,
      allowed: HasRole(['financeiro', 'master', 'admin'], roles),
      sorter: (a: any, b: any) => a.produtoAgencia - b.produtoAgencia,
      render: (value: any) => moneyMask.format(value),
      ...getColumnSearchProps('produtoAgencia', 'string', 'Produto Agência', filters),
    },
    {
      title: 'Taxa a Vista',
      dataIndex: 'taxaAvista',
      visible: false,
      allowed: HasRole(['financeiro', 'master', 'admin'], roles),
      sorter: (a: any, b: any) => a.produtoAgencia - b.produtoAgencia,
      render: (value: any) => moneyMask.format(value),
      ...getColumnSearchProps('taxaAvista', 'string', 'Taxa a Vista', filters),
    },
    {
      title: 'Valor Distribuído',
      dataIndex: 'distribuido',
      visible: false,
      allowed: HasRole(['financeiro', 'master', 'admin'], roles),
      sorter: (a: any, b: any) => a.distribuido - b.distribuido,
      render: (value: any) => moneyMask.format(value),
      ...getColumnSearchProps('distribuido', 'string', 'Valor Distribuído', filters),
    },
    {
      title: 'Data da Distribuição',
      dataIndex: 'dataDistribuido',
      visible: false,
      allowed: HasRole(['financeiro', 'master', 'admin'], roles),
      sorter: (a: any, b: any) => orderDate(a.dataDistribuido, b.dataDistribuido),
      render: (value: any) => (moment(value).isValid() ? moment(value).format('DD/MM/YYYY') : ''),
      ...getColumnSearchProps('dataDistribuido', 'daterange', 'Data da Distribuição', filters),
    },
  ];
  const cookievalues = JSON.parse(getCookie('colunascasas') || '[]') || [];
  const [visibleCols, setVisibleCols] = useState(
    cookievalues.length > 0 ? cookievalues : colunas.filter((c: any) => c.visible).map((c: any) => c.dataIndex)
  );
  return (
    <div>
      <CasaForm />
      <Button
        size="small"
        style={{ float: 'left' }}
        onClick={() => {
          setfilters(null);
          localStorage.removeItem('casasfilters');
        }}
      >
        Limpar Filtros
      </Button>
      <div style={{ textAlign: 'right' }}>
        <Input
          size="small"
          style={{ width: 400 }}
          placeholder="Buscar"
          onChange={(e) => setFilter(e.target.value)}
          allowClear
          suffix={
            <Button size="small" onClick={() => setShowCols(true)}>
              Selecionar Colunas
            </Button>
          }
        />
      </div>

      <div style={{ float: 'right', marginRight: 10 }}>
        <Modal
          width={800}
          open={showCols}
          title="Mostrar Colunas"
          onCancel={() => setShowCols(false)}
          footer={[
            <Button key="1" onClick={() => setShowCols(false)}>
              Fechar
            </Button>,
          ]}
        >
          <Checkbox.Group
            value={visibleCols}
            onChange={(value) => {
              setVisibleCols([...value, 'id']);
              setCookie('colunascasas', JSON.stringify([...value, 'id']));
            }}
          >
            <Row>
              {colunas
                .filter((c: any) => c.allowed === true)
                .map((c: any, index: number) => (
                  <Col key={index} span={8}>
                    <Checkbox value={c.dataIndex}>{c.title}</Checkbox>
                  </Col>
                ))}
            </Row>
          </Checkbox.Group>
        </Modal>
      </div>
      <Table
        size="small"
        rowKey="id"
        loading={casas.loadingcasas}
        columns={colunas.filter((c: any) => visibleCols.find((d: any) => c.dataIndex === d))}
        dataSource={filterobject(casas.casas, filter)}
        scroll={{ y: 'calc(100vh - 300px)', x: 1024 }}
        onChange={(pagination, filters, sorter) => {
          setfilters({ pagination, filters, sorter });
          setCookie('casasfilters', JSON.stringify({ pagination, filters, sorter }));
        }}
        pagination={{
          position: ['bottomRight'],
          size: 'small',
          current: filters ? filters.pagination.current : 1,
          pageSize: filters ? filters.pagination.pageSize : 10,
          showSizeChanger: true,
          pageSizeOptions: [10, 25, 50, 100],
          showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
        }}
      />
    </div>
  );
}
export default CasasView;
