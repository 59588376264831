import { Table } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getRelatorioPagamentos } from '@actions/colaboradoresActions';
import moment from 'moment';
import { moneyMask } from '@utils/masks';

import { getColumnSearchProps, orderDate } from '@utils/filterandsort';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { RelatorioFornecedor } from '@models/Fornecedor';

function RelatorioPagamentos() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [filters, setFilters] = useState(null);
  const { relatorio, loadingRelatorio } = useAppSelector((state) => state.colaboradores);
  useEffect(() => {
    dispatch(getRelatorioPagamentos(id));
  }, []);
  return (
    <div>
      <Table
        dataSource={relatorio}
        size="small"
        loading={loadingRelatorio}
        scroll={{ x: 1000, y: 'calc(100vh - 200px)' }}
        pagination={false}
        onChange={(pagination, filters, sorter) => setFilters({ pagination, filters, sorter })}
        summary={(data) => (
          <Table.Summary fixed>
            <Table.Summary.Row style={{ fontWeight: 'bold' }}>
              <Table.Summary.Cell index={0} colSpan={4} align="right">
                TOTAL
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} align="right">
                {moneyMask.format(data.reduce((a, b) => a + b.adiantamento, 0))}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} align="right">
                {moneyMask.format(data.reduce((a, b) => a + b.subtotal, 0))}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3} align="right">
                {moneyMask.format(data.reduce((a, b) => a + b.total, 0))}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      >
        <Table.Column render={(_, __, i) => i + 1} align="center" width={50} />
        <Table.Column
          title="Ordem ID"
          dataIndex="cod"
          align="center"
          width={90}
          render={(v, row: RelatorioFornecedor) => (
            <Link
              to={
                row.isFolha
                  ? '/Folhas/' + row.id
                  : row.transferencia
                  ? '/PrintOrdemTransferencia/' + row.id
                  : '/PrintOrdem/' + row.id
              }
            >
              {v}
            </Link>
          )}
        />
        <Table.Column title="Orçamento" dataIndex={['orcamento', 'descricao']} />
        <Table.Column
          title="Tipo"
          render={(_, r: RelatorioFornecedor) => (r.isFolha ? 'Folha' : r.transferencia ? 'Transferência' : 'Ordem')}
        />
        <Table.Column
          title="Mês"
          dataIndex="mesCorrente"
          render={(v) => (moment(v).isValid() ? moment(v).format('MM/YYYY') : '')}
          sorter={(a, b) => orderDate(a.mesCorrente, b.mesCorrente)}
          {...getColumnSearchProps('mesCorrente', 'daterange', 'Mês', filters)}
        />
        <Table.Column
          title="Adiantamento"
          dataIndex="adiantamento"
          render={(v) => moneyMask.format(v)}
          align="right"
          sorter={(a, b: RelatorioFornecedor) => a.adiantamento - b.adiantamento}
        />
        <Table.Column
          title="Medição"
          dataIndex="subtotal"
          render={(v) => moneyMask.format(v)}
          align="right"
          sorter={(a, b: RelatorioFornecedor) => a.subtotal - b.subtotal}
        />
        <Table.Column
          title="Recebido Medição"
          dataIndex="total"
          render={(v) => moneyMask.format(v)}
          align="right"
          sorter={(a, b: RelatorioFornecedor) => a.total - b.total}
        />
      </Table>
    </div>
  );
}

export default RelatorioPagamentos;
