import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Button, Carousel, Modal } from 'antd';
import { CloseGaleria } from '@actions/galeriaActions';

function GalleryModal() {
  const dispatch = useAppDispatch();
  const galeria = useAppSelector((state) => state.galeria);
  const images = galeria.galeriaid ? galeria.items.find((c) => c.id === galeria.galeriaid).fotos.map((c) => c.url) : [];
  return (
    <div>
      {galeria.viewgaleria && (
        <Modal
          width="95%"
          open={galeria.viewgaleria}
          onCancel={() => dispatch(CloseGaleria())}
          footer={[
            <Button key={0} onClick={() => dispatch(CloseGaleria())}>
              Fechar
            </Button>,
          ]}
        >
          <Carousel>
            {images.map((i) => (
              <div key={i} style={{}}>
                <img
                  alt={i}
                  src={i}
                  style={{
                    height: 'calc(100vh - 200px)',
                    textAlign: 'center',
                    margin: 'auto',
                  }}
                />
              </div>
            ))}
          </Carousel>
        </Modal>
      )}
    </div>
  );
}

export default GalleryModal;
