import { createReducer } from '@reduxjs/toolkit';
import {
    AjusteNF,
    AprovarOrdem,
    CancelarOrdem,
    clearFormData,
    CopyOrdem,
    EnviarOrdem,
    getEmpresasFaturamento,
    getFornecedores,
    getNovaOrdem,
    getOrcamentoItens,
    getOrcamentos,
    GetOrdem,
    GetOrdemItens,
    GetOrdensPagination,
    GetOrdensPendentes,
    getSolicitantes,
    MaterialEntregue,
    salvarOrdemNova,
    setAlertas,
    SetEnvioFornecedorForm,
    submeterOrdem,
    updateFields,
    updateFormData,
    ViewCancelForm,
    ViewItensModal,
} from '@actions/novaOrdemActions';
import moment from 'moment';
import { moneyMask } from '@utils/masks';
import { notification } from 'antd';
import { ExtendedAction } from '@config/reduxStore';
import { OrcamentoItem } from '@models/OrcamentoItem';
import { User } from '@models/User';
import { Fornecedor } from '@models/Fornecedor';
import { Orcamento } from '@models/Orcamento';
import { Ordem, OrdemList } from '@models/Ordem';
import { EmpresaFaturamento } from '@models/EmpresaFaturamento';
import { OrdemItemView } from '@models/OrdemItem';

interface NovaOrdemReducer {
    ordens: OrdemList[];
    total: number;
    loadingOrdens: boolean;
    formdata: Ordem;
    ordemLoaded: boolean;
    newOrdem: boolean;
    loadingOrdem: boolean;
    fields: [];
    loadingFornecedores: boolean;
    fornecedores: Fornecedor[];
    loadingOrcamentos: boolean;
    orcamentos: Orcamento[];
    loadingEmpresasFaturamento: boolean;
    empresasFaturamento: EmpresaFaturamento[];
    loadingOrcamentoItens: boolean;
    orcamentoItens: OrcamentoItem[];
    savingOrdem: boolean;
    solicitantes: User[];
    loadingSolicitantes: boolean;
    alertas: any[];
    cancelingOrdem: boolean;
    ajustandoNF: boolean;
    cancelForm: {
        id: number | null;
        cod: string;
        visible: boolean;
        replace: boolean;
    };
    ordensList: OrdemList[];
    ordensPendentes: OrdemList[];
    loadingPendentes: boolean;
    ordemItens: OrdemItemView;
    loadingOrdemItens: boolean;
    ordemItensModal: {
        ordemID: number | null;
        visible: boolean;
        cod: string;
    };
    ordem: Ordem;
    envioFornecedorForm: {
        ordemID: number;
        visible: boolean;
        email: string;
    };
}
let initialState: NovaOrdemReducer = {
    ordens: [],
    total: 0,
    loadingOrdens: false,
    formdata: null,
    ordemLoaded: false,
    newOrdem: false,
    loadingOrdem: false,
    fields: [],
    loadingFornecedores: false,
    fornecedores: [],
    ajustandoNF: false,
    loadingOrcamentos: false,
    orcamentos: [],
    loadingEmpresasFaturamento: false,
    empresasFaturamento: [],
    loadingOrcamentoItens: false,
    orcamentoItens: [],
    savingOrdem: false,
    solicitantes: [],
    loadingSolicitantes: false,
    alertas: [],
    cancelingOrdem: false,
    cancelForm: {
        id: null,
        cod: null,
        visible: false,
        replace: false,
    },
    ordensList: [],
    ordensPendentes: [],
    loadingPendentes: false,
    ordemItens: null,
    loadingOrdemItens: false,
    ordemItensModal: {
        ordemID: null,
        visible: false,
        cod: '',
    },
    ordem: null,
    envioFornecedorForm: {
        visible: false,
        ordemID: null,
        email: null,
    },
};

export default createReducer(initialState, (builder) => {
    builder.addCase(MaterialEntregue.pending, (state) => {
        return {
            ...state,
            loadingOrdens: true,
        };
    });
    builder.addCase(MaterialEntregue.rejected, (state, action: ExtendedAction) => {
        notification.error({ message: action.payload.response.data.Message });
        return {
            ...state,
            loadingOrdens: false,
        };
    });
    builder.addCase(MaterialEntregue.fulfilled, (state, action: ExtendedAction) => {
        console.log(action);
        notification.success({ message: 'Status de material entregue alterado com sucesso!' });
        return {
            ...state,
            loadingOrdens: false,
            ordens: state.ordens.map((c) => {
                if (c.id === action.payload.id) {
                    return { ...c, materialEntregue: action.payload.materialEntregue };
                } else return c;
            }),
        };
    });
    builder.addCase(SetEnvioFornecedorForm, (state, action: ExtendedAction) => {
        return {
            ...state,
            envioFornecedorForm: action.payload,
        };
    });
    builder.addCase(updateFormData, (state, action: ExtendedAction) => ({
        ...state,
        formdata: { ...state.formdata, ...action.payload, situacao: 'Rascunho' },
    }));
    builder.addCase(clearFormData, (state) => ({
        ...state,
        formdata: null,
    }));
    builder.addCase(setAlertas, (state, action: ExtendedAction) => ({
        ...state,
        alertas: action.payload,
    }));
    builder.addCase(updateFields, (state, action: ExtendedAction) => ({
        ...state,
        fields: action.payload,
    }));
    builder.addCase(getNovaOrdem.pending, (state) => ({
        ...state,
        loadingOrdem: true,
        formdata: null,
    }));
    builder.addCase(getNovaOrdem.rejected, (state) => ({
        ...state,
        loadingOrdem: false,
    }));
    builder.addCase(getNovaOrdem.fulfilled, (state, action: ExtendedAction) => ({
        ...state,
        loadingOrdem: false,
        ordemLoaded: true,
        formdata: {
            ...state.formdata,
            ...action.payload.data,
            prazoEntrega: moment(action.payload.data.prazoEntrega).isValid()
                ? moment(action.payload.data.prazoEntrega)
                : null,
            prazoPagamento: moment(action.payload.data.prazoPagamento).isValid()
                ? moment(action.payload.data.prazoPagamento)
                : null,
            total: moneyMask.format(action.payload.data.total),
            desconto: moneyMask.format(action.payload.data.desconto),
            subtotal: moneyMask.format(action.payload.data.subtotal),
            ordensItens: action.payload.data.ordensItens.map((c: any) => ({
                ...c,
                valorUnitario: moneyMask.format(c.valorUnitario),
                desconto: moneyMask.format(c.desconto),
                valorTotal: moneyMask.format(c.valorTotal),
            })),
        },
    }));
    builder.addCase(GetOrdensPagination.pending, (state) => ({
        ...state,
        loadingOrdens: true,
    }));
    builder.addCase(GetOrdensPagination.rejected, (state, action: ExtendedAction) => {
        notification.error({ message: action.payload.response.data.Message });
        return {
            ...state,
            loadingOrdens: false,
        };
    });
    builder.addCase(GetOrdensPagination.fulfilled, (state, action: ExtendedAction) => ({
        ...state,
        loadingOrdens: false,
        ordens: action.payload.data.results,
        total: action.payload.data.total,
    }));
    builder.addCase(getSolicitantes.pending, (state) => ({
        ...state,
        loadingSolicitantes: true,
    }));
    builder.addCase(getSolicitantes.rejected, (state) => ({
        ...state,
        loadingSolicitantes: false,
    }));
    builder.addCase(getSolicitantes.fulfilled, (state, action: ExtendedAction) => ({
        ...state,
        loadingSolicitantes: false,
        solicitantes: action.payload.data,
    }));
    builder.addCase(getFornecedores.pending, (state) => ({
        ...state,
        loadingFornecedores: true,
    }));
    builder.addCase(getFornecedores.rejected, (state) => ({
        ...state,
        loadingFornecedores: false,
    }));
    builder.addCase(getFornecedores.fulfilled, (state, action: ExtendedAction) => ({
        ...state,
        loadingFornecedores: false,
        fornecedores: action.payload.data,
    }));
    builder.addCase(getOrcamentos.pending, (state) => ({
        ...state,
        loadingOrcamentos: true,
    }));
    builder.addCase(getOrcamentos.rejected, (state) => ({
        ...state,
        loadingOrcamentos: false,
    }));
    builder.addCase(getOrcamentos.fulfilled, (state, action: ExtendedAction) => ({
        ...state,
        loadingOrcamentos: false,
        orcamentos: action.payload.data,
    }));
    builder.addCase(getEmpresasFaturamento.pending, (state) => ({
        ...state,
        loadingEmpresasFaturamento: true,
    }));
    builder.addCase(getEmpresasFaturamento.rejected, (state) => ({
        ...state,
        loadingEmpresasFaturamento: false,
    }));
    builder.addCase(getEmpresasFaturamento.fulfilled, (state, action: ExtendedAction) => ({
        ...state,
        loadingEmpresasFaturamento: false,
        empresasFaturamento: action.payload.data,
    }));
    builder.addCase(getOrcamentoItens.pending, (state) => ({
        ...state,
        loadingOrcamentoItens: true,
    }));
    builder.addCase(getOrcamentoItens.rejected, (state) => ({
        ...state,
        loadingOrcamentoItens: false,
    }));
    builder.addCase(getOrcamentoItens.fulfilled, (state, action: ExtendedAction) => ({
        ...state,
        loadingOrcamentoItens: false,
        orcamentoItens: [
            ...state.orcamentoItens.filter((c) => c.orcamentoID !== action.payload.data.orcamentoid),
            ...action.payload.data.orcamentositens,
        ],
    }));
    builder.addCase(salvarOrdemNova.pending, (state) => ({
        ...state,
        savingOrdem: true,
    }));
    builder.addCase(salvarOrdemNova.rejected, (state, action: ExtendedAction) => {
        notification.error({ message: action.payload.response.data.Message });
        return {
            ...state,
            savingOrdem: false,
        };
    });
    builder.addCase(salvarOrdemNova.fulfilled, (state, action: ExtendedAction) => ({
        ...state,
        savingOrdem: false,
        newOrdem: action.payload.data.newOrdem,
        formdata: {
            ...state.formdata,
            ...action.payload.data.data,
            total: moneyMask.format(action.payload.data.data.total),
            desconto: moneyMask.format(action.payload.data.data.desconto),
            subtotal: moneyMask.format(action.payload.data.data.subtotal),
            ordensItens: action.payload.data.data.ordensItens.map((c: any) => ({
                ...c,
                valorUnitario: moneyMask.format(c.valorUnitario),
                desconto: moneyMask.format(c.desconto),
                valorTotal: moneyMask.format(c.valorTotal),
            })),
        },
    }));
    builder.addCase(submeterOrdem.pending, (state) => ({
        ...state,
        savingOrdem: true,
    }));
    builder.addCase(submeterOrdem.rejected, (state, action: ExtendedAction) => {
        notification.error({ message: action.payload });
        return {
            ...state,
            savingOrdem: false,
        };
    });
    builder.addCase(submeterOrdem.fulfilled, (state, action: ExtendedAction) => {
        notification.success({ message: 'Ordem submetida com sucesso!' });
        return {
            ...state,
            savingOrdem: false,
            formdata: {
                ...state.formdata,
                situacao: action.payload.data.situacao,
                aprovacao3: action.payload.data.aprovacao3,
            },
        };
    });
    builder.addCase(CancelarOrdem.pending, (state) => {
        return { ...state, cancelingOrdem: true };
    });
    builder.addCase(CancelarOrdem.rejected, (state) => {
        notification.error({ message: 'Erro ao cancelar folha' });
        return { ...state, cancelingOrdem: false };
    });
    builder.addCase(CancelarOrdem.fulfilled, (state, action: ExtendedAction) => {
        return {
            ...state,
            cancelingOrdem: false,
            ordensPendentes: state.ordensPendentes.filter((c) => c.id !== action.meta.arg.id),
            ordensList: state.ordensList.map((c) =>
                c.id === action.meta.arg.id ? { ...c, ...action.meta.arg, cancelada: true, situacao: 'Cancelada' } : c
            ),
        };
    });
    builder.addCase(ViewCancelForm, (state, action: ExtendedAction) => {
        return {
            ...state,
            cancelForm: {
                id: action.payload.id,
                visible: action.payload.view,
                replace: action.payload.replace,
                cod: action.payload.cod,
            },
        };
    });
    builder.addCase(GetOrdensPendentes.pending, (state) => {
        return { ...state, loadingPendentes: true };
    });
    builder.addCase(GetOrdensPendentes.rejected, (state, action: ExtendedAction) => {
        notification.error({ message: action.payload.response.data.Message });
        return { ...state, loadingPendentes: false };
    });
    builder.addCase(GetOrdensPendentes.fulfilled, (state, action: ExtendedAction) => {
        return { ...state, loadingPendentes: false, ordensPendentes: action.payload.data };
    });
    builder.addCase(ViewItensModal, (state, action: ExtendedAction) => {
        return { ...state, ordemItensModal: action.payload };
    });
    builder.addCase(GetOrdemItens.pending, (state) => {
        return { ...state, loadingOrdemItens: true, ordemItens: null };
    });
    builder.addCase(GetOrdemItens.rejected, (state, action: ExtendedAction) => {
        notification.error({ message: action.payload.response.data.Message });
        return { ...state, loadingOrdemItens: false };
    });
    builder.addCase(GetOrdemItens.fulfilled, (state, action: ExtendedAction) => {
        return { ...state, loadingOrdemItens: false, ordemItens: action.payload.data };
    });
    builder.addCase(CopyOrdem.pending, (state) => {
        return { ...state, cancelingOrdem: false };
    });
    builder.addCase(CopyOrdem.rejected, (state, action: ExtendedAction) => {
        notification.error({ message: action.payload.response.data.Message });
        return { ...state, cancelingOrdem: false };
    });
    builder.addCase(CopyOrdem.fulfilled, (_, action: ExtendedAction) => {
        window.location.href = '/NovaOrdem/3/' + action.payload.data.id;
    });
    builder.addCase(GetOrdem.pending, (state) => {
        return { ...state, loadingOrdem: false };
    });
    builder.addCase(GetOrdem.rejected, (state, action: ExtendedAction) => {
        notification.error({ message: action.payload.response.data.Message });
        return { ...state, loadingOrdem: false };
    });
    builder.addCase(GetOrdem.fulfilled, (state, action: ExtendedAction) => {
        return { ...state, loadingOrdem: false, ordem: action.payload.data };
    });
    builder.addCase(AprovarOrdem.pending, (state) => {
        return { ...state, loadingPendentes: true };
    });
    builder.addCase(AprovarOrdem.rejected, (state, action: ExtendedAction) => {
        notification.error({ message: action.payload.response.data.Message });
        return { ...state, loadingPendentes: false };
    });
    builder.addCase(AprovarOrdem.fulfilled, (state, action: ExtendedAction) => {
        notification.success({ message: 'Ordem aprovada com sucesso!' });
        return {
            ...state,
            loadingPendentes: false,
            ordensPendentes: state.ordensPendentes.filter((c) => c.id !== action.payload.data.id),
        };
    });
    builder.addCase(AjusteNF.pending, (state) => {
        return { ...state, ajustandoNF: true };
    });
    builder.addCase(AjusteNF.rejected, (state, action: ExtendedAction) => {
        notification.error({ message: action.payload?.response?.data?.Message });
        return { ...state, ajustandoNF: false };
    });
    builder.addCase(AjusteNF.fulfilled, (state, action: ExtendedAction) => {
        notification.success({ message: 'Ordem aprovada com sucesso!' });
        return {
            ...state,
            ajustandoNF: false,
            ordens: state.ordens.map((c) => {
                if (c.id === action.payload.data.id) {
                    return {
                        ...c,
                        ...action.payload.data,
                    };
                } else {
                    return c;
                }
            }),
        };
    });
    builder.addCase(EnviarOrdem.pending, (state) => {
        return { ...state, loadingOrdens: true };
    });
    builder.addCase(EnviarOrdem.rejected, (state, action: ExtendedAction) => {
        notification.error({ message: action.payload?.response?.data?.Message });
        return { ...state, loadingOrdens: false };
    });
    builder.addCase(EnviarOrdem.fulfilled, (state, action: ExtendedAction) => {
        notification.success({ message: 'Ordem enviada com sucesso!' });
        return {
            ...state,
            loadingOrdens: false,
            ordens: state.ordens.map((c) => {
                if (c.id === action.payload.data.id) {
                    return { ...c, email: action.payload.data.email, emailEnviado: true };
                } else return c;
            }),
        };
    });
});
