import { useState } from 'react';
import { Modal, Button, Table, Tag, Input } from 'antd';
import { ShowGarantias } from '@actions/chamadosActions';
import moment from 'moment';
import { filterobject } from '@utils/filterandsort';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Garantia } from '@models/Chamado';

function Garantias(props: { dataContrato: moment.Moment; data: moment.Moment; garantias: Garantia[] }) {
  const dispatch = useAppDispatch();
  const [filter, setFilter] = useState('');
  const { garantiasmodal } = useAppSelector((state) => state.chamados);
  return (
    <Modal
      title="Garantias"
      open={garantiasmodal}
      onCancel={() => dispatch(ShowGarantias())}
      footer={[
        <Button key="1" onClick={() => dispatch(ShowGarantias())}>
          Fechar
        </Button>,
      ]}
    >
      {moment(props.data).isValid() && (
        <div>
          <Input style={{ width: 200 }} onChange={(e) => setFilter(e.target.value)} placeholder="Buscar" allowClear />
          <Table
            rowKey="id"
            size="small"
            dataSource={filterobject(props.garantias, filter)}
            pagination={false}
            scroll={{ y: 400 }}
          >
            <Table.Column title="Descrição" dataIndex="descricao" />
            <Table.Column title="Sistema" dataIndex="sistema" />
            <Table.Column title="Prazo (meses)" dataIndex="prazo" />
            <Table.Column
              title="Status"
              dataIndex="prazo"
              render={(text) =>
                moment(props.dataContrato).add(text, 'M').isAfter(moment(props.data)) ? (
                  <Tag color="#87d068">Em dia</Tag>
                ) : (
                  <Tag color="#f50">Expirado</Tag>
                )
              }
            />
          </Table>
        </div>
      )}
    </Modal>
  );
}

export default Garantias;
