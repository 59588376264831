import { Axios } from '@config/axios';
import { apiEndpoints } from '@constants/apiEndpoints';
import { PedidoList } from '@models/Pedidos';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { AxiosResponse } from 'axios';
import { ViewCancelForm } from './novaOrdemActions';

export const GetAllPagination = createAsyncThunk(
  'pedidos/GetAllPagination',
  async (
    data: {
      filtros: {
        filters: Record<string, FilterValue>;
        pagination: TablePaginationConfig;
        sorter: SorterResult<PedidoList> | SorterResult<PedidoList>[];
      };
      itemID?: number;
      id?: number;
      my?: boolean;
    },
    apiThunk
  ) =>
    Axios.post(apiEndpoints.PEDIDOS.GET_ALL, data.filtros, { params: { itemID: data.itemID } }).catch((res) =>
      apiThunk.rejectWithValue(res)
    )
);
export const GetPendentes = createAsyncThunk('pedidos/GetPendentes', async (_, apiThunk) =>
  Axios.get<PedidoList[]>(apiEndpoints.PEDIDOS.PENDENTES).catch((res) => apiThunk.rejectWithValue(res))
);
export const AprovarPedido = createAsyncThunk('pedidos/AprovarPedido', async (id: number, apiThunk) =>
  Axios.put<number>(apiEndpoints.PEDIDOS.APROVAR, null, { params: { id: id } }).catch((res) =>
    apiThunk.rejectWithValue(res)
  )
);
export const CancelarPedido = createAsyncThunk(
  'pedidos/CancelarPedido',
  async ({ id, motivo, cod }: { id: number; motivo: string; cod: string }, apiThunk) =>
    Axios.put<number, AxiosResponse<number, number>, number>(apiEndpoints.PEDIDOS.CANCELAR, null, {
      params: { id: id, motivo: motivo },
    })
      .then((res) => {
        apiThunk.dispatch(ViewCancelForm({ view: false, id: id, replace: false, cod: cod }));
        console.log(res);
        apiThunk.fulfillWithValue(res);
      })
      .catch((res) => apiThunk.rejectWithValue(res))
);
export const FinalizarPedido = createAsyncThunk(
  'pedidos/FinalizarPedido',
  async (
    data: {
      filtros: {
        filters: Record<string, FilterValue>;
        pagination: TablePaginationConfig;
        sorter: SorterResult<PedidoList> | SorterResult<PedidoList>[];
      };
      itemID?: number;
      id?: number;
      my?: boolean;
    },
    apiThunk
  ) => {
    Axios.post(apiEndpoints.PEDIDOS.FINALIZAR, data.filtros, {
      params: { itemID: data.itemID, id: data.id },
    })
      .then((res) => {
        apiThunk.dispatch(GetAllPagination(data));
        apiThunk.fulfillWithValue(res);
      })
      .catch((res) => {
        apiThunk.rejectWithValue(res);
      });
  }
);
