import { useEffect, useState } from 'react';
import logo from '../../../assets/img/main.png';
import moment from 'moment';
import { GetChamado } from '@actions/chamadosActions';
import { useParams } from 'react-router-dom';
import { Axios } from '@config/axios';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

function OSvisita() {
  const dispatch = useAppDispatch();
  const { chamado } = useAppSelector((state) => state.chamados);
  const { chamadoid } = useParams();
  const [templates, setTemplates] = useState(null);
  const { user } = useAppSelector((state) => state.user);
  useEffect(() => {
    dispatch(GetChamado(chamadoid));
    Axios.get('/api/v2/Templates/GetTemplates').then((res) => setTemplates(res.data));
  }, []);
  useEffect(() => {
    if (chamado && templates) {
      document.write(
        templates.visitaTecnica.body.replace(/{{\w+}}/g, (a: any) => {
          if (a === '{{logo}}') return `<img src="${user.configuracao.logo || logo}" alt="logo" height="70"/>`;
          if (a === '{{quebrapagina}}') return '<div style="break-after:page"></div>';
          if (a === '{{dataChamado}}')
            return moment(chamado.dataChamado).isValid() ? moment(chamado.dataChamado).format('DD/MM/YYYY') : '';
          if (a === '{{dataEntrega}}')
            return moment(chamado.dataEntrega).isValid() ? moment(chamado.dataEntrega).format('DD/MM/YYYY') : '';
          if (a === '{{dataContrato}}')
            return moment(chamado.dataContrato).isValid() ? moment(chamado.dataContrato).format('DD/MM/YYYY') : '';
          if (a === '{{reclamacoes}}')
            return `<table style="border-collapse: collapse; width: 100%;" border="1">
                <thead>
                  <tr>
                    <th style="width: 23.56%; text-align: center;">Parecer</th>
                    <th style="width: 23.56%; text-align: center;">Item</th>
                    <th style="width: 23.56%; text-align: center;">Ação/Justificativa</th>
                    <th style="width: 23.56%; text-align: center;">Previsão do Reparo</th>
                  </tr>
                </thead>
                <tbody>
                  ${
                    chamado.reclamacoes &&
                    chamado.reclamacoes.map(
                      (r) =>
                        `<tr>
                        <td style="text-align: center;">( ) Prodecente</td>
                        <td rowspan="2">${r.descricao}</td>
                        <td rowspan="2"></td>
                        <td rowspan="2"></td>
                      </tr>
                      <tr><td style="text-align: center;">( ) Improcedente</td></tr>`
                    )
                  }
                </tbody>
              </table>`;
          return chamado && chamado[String(a.replace(/{{/g, '').replace(/}}/g, ''))];
        })
      );
      document.close();
      window.print();
    }
  }, [chamado, templates]);
  return <div />;
}

export default OSvisita;
