import { RemoveItemFolha } from '@services/api/api.folha';
import { GetOrcamentosRealizado } from '@services/api/api.orcamento';
import { Button, Spin, Table } from 'antd';
import { useFolhaForm } from './useFolhaForm';
import { DeleteOutlined } from '@ant-design/icons';

function FolhaFormOrcamentoRemoveItens() {
  const { isLoading, isFetching, folha } = useFolhaForm();
  const { data, isLoading: loadingOrcamento } = GetOrcamentosRealizado({
    ativo: true,
    orcamentoID: folha?.orcamentoID,
    tipo: 1,
  });
  const removeItemFolha = RemoveItemFolha();
  return (
    <Spin spinning={isLoading || isFetching || loadingOrcamento || removeItemFolha.isLoading}>
      <Table size="small" dataSource={folha?.itens} pagination={false} rowKey="id" scroll={{ y: 300 }}>
        <Table.Column render={(_, __, index) => index + 1} width={40} align="center" />
        <Table.Column
          dataIndex="id"
          width={60}
          align="center"
          render={(v) => (
            <>
              <Button
                size="small"
                icon={<DeleteOutlined />}
                onClick={() => removeItemFolha.mutate({ folhaID: folha.id, ordemItemID: v, cod: folha.cod })}
              />
            </>
          )}
        />
        <Table.Column
          title="Categoria"
          dataIndex="id"
          render={(v) => data?.orcamentosItens.find((c) => c.id === v).categoria as string}
        />
        <Table.Column
          title="Grupo"
          dataIndex="id"
          render={(v) => data?.orcamentosItens.find((c) => c.id === v).grupo as string}
        />
        <Table.Column
          title="Item"
          dataIndex="id"
          render={(v) => data?.orcamentosItens.find((c) => c.id === v).item as string}
        />
        <Table.Column
          title="Unidade"
          dataIndex="id"
          align="center"
          width={80}
          render={(v) => data?.orcamentosItens.find((c) => c.id === v).unidade as string}
        />
      </Table>
    </Spin>
  );
}

export default FolhaFormOrcamentoRemoveItens;
