import { AppDispatch } from '@config/reduxStore';
import { apiEndpoints } from '@constants/apiEndpoints';
import { Axios } from '@config/axios';

export const GetGaleria = function (casaid: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_GALERIA',
      payload: Axios.get(apiEndpoints.GALERIA.GET_GALERIA, { params: { casaid } }),
    });
  };
};
export const AddGaleria = function (formdata: FormData) {
  return function (dispatch: AppDispatch) {
    //const form = new FormData();
    //form.append()
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    dispatch({
      type: 'ADD_GALERIA_OBRA',
      payload: Axios.post(apiEndpoints.GALERIA.ADD_GALERIA, formdata, config),
    });
  };
};
export const DeleteGaleria = function (galeriaid: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DELETE_GALERIA_OBRA',
      payload: Axios.post(apiEndpoints.GALERIA.DELETE_GALERIA, { galeriaid }),
    });
  };
};

export const OpenGaleria = function (galeriaid: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'OPEN_GALERIA',
      payload: galeriaid,
    });
  };
};
export const CloseGaleria = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'CLOSE_GALERIA',
    });
  };
};
