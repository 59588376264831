import { ExtendedAction } from '@config/reduxStore';
import { Corretor } from '@models/Corretor';
import { notification } from 'antd';

interface CorretoresReducer {
  corretores: Corretor[];
  formview: boolean;
  corretor: number | null;
  loadingcorretores: boolean;
  savingcorretor: boolean;
  feedback: string;
}
var initialState: CorretoresReducer = {
  corretores: [],
  formview: false,
  corretor: null,
  loadingcorretores: false,
  savingcorretor: false,
  feedback: '',
};

const corretoresReducer = function (state = initialState, action: ExtendedAction) {
  switch (action.type) {
    case 'RESET_FEEDBACK':
      state = {
        ...state,
        feedback: '',
      };
      break;
    case 'FORM_CORRETOR':
      state = {
        ...state,
        feedback: '',
        formview: action.payload.open,
        corretor: action.payload.id ? action.payload.id : null,
      };
      break;
    case 'GET_CORRETORES_PENDING':
      state = {
        ...state,
        loadingcorretores: true,
        corretores: initialState.corretores,
      };
      break;
    case 'GET_CORRETORES_REJECTED':
      state = {
        ...state,
        loadingcorretores: false,
      };
      break;
    case 'GET_CORRETORES_FULFILLED':
      state = {
        ...state,
        loadingcorretores: false,
        corretores: action.payload.data,
      };
      break;
    case 'ADD_CORRETOR_PENDING':
      state = {
        ...state,
        savingcorretor: true,
        feedback: '',
      };
      break;
    case 'ADD_CORRETOR_REJECTED':
      state = {
        ...state,
        savingcorretor: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: 'Erro ao cadastrar corretor!' });
      break;
    case 'ADD_CORRETOR_FULFILLED':
      state = {
        ...state,
        savingcorretor: false,
        feedback: 'Corretor criado com sucesso!',
        corretores: [...state.corretores, action.payload.data],
      };
      notification.success({ message: 'Corretor cadastrado com sucesso!' });
      break;
    case 'UPDATE_CORRETOR_PENDING':
      state = {
        ...state,
        savingcorretor: true,
        feedback: '',
      };
      break;
    case 'UPDATE_CORRETOR_REJECTED':
      state = {
        ...state,
        savingcorretor: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: 'Erro ao atualizar corretor!' });
      break;
    case 'UPDATE_CORRETOR_FULFILLED':
      state = {
        ...state,
        savingcorretor: false,
        feedback: 'Corretor atualizado com sucesso!',
        corretores: state.corretores.map((c) => {
          if (c.id === action.payload.data.id) return action.payload.data;
          else return c;
        }),
      };
      notification.success({ message: 'Corretor atualizado com sucesso!' });
      break;
    case 'DELETE_CORRETOR_PENDING':
      state = {
        ...state,
        loadingcorretores: true,
        feedback: '',
      };
      break;
    case 'DELETE_CORRETOR_REJECTED':
      state = {
        ...state,
        loadingcorretores: false,
        feedback: action.payload.response.data.Message,
      };
      notification.warning({ message: state.feedback });
      break;
    case 'DELETE_CORRETOR_FULFILLED':
      state = {
        ...state,
        loadingcorretores: false,
        feedback: 'Corretor excluído com sucesso!',
        corretores: state.corretores.filter((c) => c.id !== action.payload.data),
      };
      notification.success({ message: state.feedback });
      break;
    default:
      return state;
  }
  return state;
};

export default corretoresReducer;
