import { useEffect, useState } from 'react';
import { Table, Input, Button, Tooltip, Space } from 'antd';
import { EyeOutlined, CheckOutlined, StopOutlined, WarningOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { getColumnSearchProps, sortAlpha, filterobject, orderDate } from '@utils/filterandsort';
import { SetPageHeader } from '@actions/userActions';
import { Permission } from '@hooks//usePermissions';
import { Link } from 'react-router-dom';
import { GetEmps } from '@actions/empsActions';
import { moneyMask } from '@utils/masks';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { ColumnsType } from 'antd/lib/table';
import { OrdemList } from '@models/Ordem';
import CancelOrdemModal from '../Shared/CancelOrdemModalRedux';
import { AprovarOrdem, GetOrdensPendentes, ViewCancelForm, ViewItensModal } from '@actions/novaOrdemActions';
import OrdemItensModal from '../Shared/OrdemItensModal';

function OrdensPendentes() {
  const dispatch = useAppDispatch();
  const { ordensPendentes, loadingPendentes } = useAppSelector((state) => state.novaOrdem);
  const [filter, setFilter] = useState('');
  const [filters, setFilters] = useState(null);
  useEffect(() => {
    dispatch(GetEmps());
    dispatch(GetOrdensPendentes());
    dispatch(
      SetPageHeader({
        title: 'Engenharia',
        backbutton: true,
        extras: [],
        subtitle: 'Ordens Pendentes',
        menu: 'aprovarordens',
      })
    );
  }, []);
  const columns: ColumnsType<OrdemList> = [
    {
      width: 130,
      fixed: 'left',
      render: (_, row) => (
        <Space>
          {!row.cancelada && (
            <>
              <Link to={`/PrintOrdem/${row.id}`}>
                <Button icon={<EyeOutlined />} size="small" />
              </Link>
              <Permission permission="ordem.aprove">
                <Tooltip title="Aprovar Ordem">
                  <Button
                    icon={<CheckOutlined />}
                    size="small"
                    onClick={() => {
                      dispatch(AprovarOrdem(row.id));
                    }}
                  />
                </Tooltip>
              </Permission>
              <Tooltip title="Cancelar Ordem">
                <Button
                  icon={<StopOutlined />}
                  size="small"
                  onClick={() => {
                    dispatch(ViewCancelForm({ view: true, id: row.id, replace: false, cod: row.cod }));
                  }}
                />
              </Tooltip>
            </>
          )}
          <Tooltip title="Itens">
            <Button
              icon={<ShoppingCartOutlined />}
              size="small"
              onClick={() => {
                dispatch(ViewItensModal({ ordemID: row.id, cod: row.cod, visible: true }));
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
    {
      title: 'Nº Ordem',
      dataIndex: 'cod',
      sorter: (a, b) => sortAlpha(a.cod, b.cod),
      ...getColumnSearchProps('cod', 'string', 'Nº Ordem', filters),
      render: (_, row) => (
        <div>
          <span>{row.cod} </span>
          {row.aprovacao && (
            <Tooltip title={row.justificativa}>
              <span style={{ color: 'orange' }}>
                <WarningOutlined />
              </span>
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      title: 'Data Criação',
      dataIndex: 'dataCriacao',
      sorter: (a, b) => orderDate(a.dataCriacao, b.dataCriacao),
      ...getColumnSearchProps('dataCriacao', 'daterange', 'Data Criação', filters),
      render: (text) => moment(text).format('DD/MM/YYYY'),
    },
    {
      title: 'Descrição',
      dataIndex: 'descricao',
      sorter: (a, b) => sortAlpha(a.descricao, b.descricao),
      ...getColumnSearchProps('descricao', 'string', 'Descricao', filters),
    },
    {
      title: 'Solicitante',
      dataIndex: 'comprador',
      ...getColumnSearchProps('comprador', 'string', 'Solicitante', filters),
    },
    {
      title: 'Fornecedor',
      dataIndex: 'fornecedor',
      ...getColumnSearchProps('fornecedor', 'string', 'Fornecedor', filters),
    },
    {
      title: 'Orçamento',
      dataIndex: 'orcamento',
      ...getColumnSearchProps('orcamento', 'string', 'Orcamento', filters),
    },
    {
      title: 'Valor',
      dataIndex: 'total',
      sorter: (a, b) => (a.total as number) - (b.total as number),
      ...getColumnSearchProps('total', 'string', 'Valor', filters),
      render: (text) => moneyMask.format(text),
    },
  ];
  return (
    <div>
      <CancelOrdemModal />
      <OrdemItensModal />
      <div style={{ textAlign: 'right' }}>
        <Input style={{ width: 200 }} onChange={(e) => setFilter(e.target.value)} placeholder="Buscar" allowClear />
      </div>
      <Table
        size="small"
        rowKey="id"
        onChange={(paginantion, filters, sorter) => setFilters({ paginantion, filters, sorter })}
        loading={loadingPendentes}
        columns={columns}
        dataSource={filterobject(ordensPendentes, filter)}
        scroll={{ y: 'calc(100vh - 250px)', x: 1200 }}
        pagination={{
          position: ['bottomRight'],
          size: 'small',
          showSizeChanger: true,
          pageSizeOptions: [10, 25, 50, 100],
          showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
        }}
      />
    </div>
  );
}
export default OrdensPendentes;
