import { Button, Checkbox, DatePicker, Input, Progress, Space, Table, Tooltip } from 'antd';
import { useEffect } from 'react';
import { getFisicos } from '@actions/fisicoActions';
import { SetPageHeader } from '@actions/userActions';
import { AreaChartOutlined, EditOutlined, CheckOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { filterobject, naturalSorter } from '@utils/filterandsort';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Permission } from '@hooks//usePermissions';
import { moneyMask } from '@utils/masks';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Fisico } from '@models/Fisico';
import { FisicoFinanceiro } from '@models/FisicoFinanceiro';

function FisicoView() {
  const dispatch = useAppDispatch();
  const { fisicos, loadingFisicos, valores } = useAppSelector((state) => state.fisico);
  const [filter, setFilter] = useState('');
  const [mes, setMes] = useState(moment(new Date()));
  const [ativo, setAtivo] = useState(true);
  useEffect(() => {
    dispatch(getFisicos({ ativo: true, mes: mes.format('YYYY-MM-DD') }));
    dispatch(
      SetPageHeader({
        title: 'Andamento Físico Financeiro',
        backbutton: true,
        extras: [],
        subtitle: 'Planejamento/Evolução Físico-Financeiro',
        menu: 'fisico',
      })
    );
  }, []);
  const strokeColor = {
    from: '#108ee9',
    to: '#87d068',
  };
  return (
    <div>
      <Space>
        <Input
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          style={{ width: 200 }}
          placeholder="Buscar..."
        />
        <DatePicker.MonthPicker
          format="MM/YYYY"
          value={mes}
          onChange={(v) => {
            setMes(v);
            dispatch(getFisicos({ ativo: ativo, mes: v.format('YYYY-MM-DD') }));
          }}
        />
        <Checkbox
          defaultChecked
          checked={ativo}
          onChange={(e) => {
            setAtivo(e.target.checked);
            dispatch(getFisicos({ ativo: e.target.checked, mes: mes.format('YYYY-MM-DD') }));
          }}
        >
          Ativos
        </Checkbox>
      </Space>
      <Table
        size="small"
        dataSource={filterobject(fisicos, filter)}
        loading={loadingFisicos}
        rowKey="id"
        pagination={false}
        scroll={{ y: 'calc(100vh - 200px)', x: 1000 }}
      >
        <Table.Column title="Item" render={(_, __, index) => index + 1} width={40} align="center" />
        <Table.Column
          title=""
          width={100}
          dataIndex="id"
          render={(v, row: FisicoFinanceiro) => (
            <Space>
              <Permission permission="planejamento.add">
                <Tooltip title="Planejar">
                  <Link to={'/Fisico/' + v}>
                    <Button size="small" icon={<EditOutlined />} />
                  </Link>
                </Tooltip>
              </Permission>
              {row.fisico && (
                <Permission permission="planejamento.lancar">
                  <Tooltip title="Lançar Avanço">
                    <Link to={'/Lancar/' + v}>
                      <Button size="small" icon={<CheckOutlined />} />
                    </Link>
                  </Tooltip>
                </Permission>
              )}
              {row.fisico && (
                <Permission permission="planejamento.get">
                  <Tooltip title="Relatório">
                    <Link to={'/FisicoReport/' + v}>
                      <Button size="small" icon={<AreaChartOutlined />} />
                    </Link>
                  </Tooltip>
                </Permission>
              )}
            </Space>
          )}
        />
        <Table.Column
          title="Obra"
          dataIndex="descricao"
          width={300}
          ellipsis
          sorter={(a, b: Fisico) => naturalSorter(a.descricao, b.descricao)}
        />
        <Table.Column
          title="Início"
          dataIndex={['fisico', 'dataInicio']}
          render={(v) => (moment(v).isValid() ? moment(v).format('MM/YYYY') : '')}
        />
        <Table.Column title="Meses" align="center" dataIndex={['fisico', 'meses']} />
        <Table.Column
          align="right"
          render={() => (
            <div>
              <div>PLANEJADO</div>
              <div>EXECUTADO</div>
            </div>
          )}
        />
        <Table.Column
          title="Físico"
          dataIndex="id"
          align="center"
          width={150}
          render={(v, row: FisicoFinanceiro) => {
            const planejado = row.planejados.reduce(
              (a, b) =>
                a +
                ((b.planejado as number) *
                  valores.find((c) => c.id === v)?.categorias.find((c) => c.id === b.categoriaID)?.orcado) /
                  valores.find((c) => c.id === v)?.categorias.reduce((a, b) => a + b.orcado, 0),
              0
            );
            const realizado = row.avancos.reduce(
              (a, b) =>
                a +
                (b.realizado *
                  valores.find((c) => c.id === v)?.categorias.find((c) => c.id === b.categoriaID)?.orcado) /
                  valores.find((c) => c.id === v)?.categorias.reduce((a, b) => a + b.orcado, 0),
              0
            );
            return (
              <div>
                <div>
                  <Progress percent={Math.ceil(planejado * 1) / 1} strokeColor={strokeColor} />
                </div>
                <div>
                  <Progress percent={Math.ceil(realizado * 1) / 1} strokeColor={strokeColor} />
                </div>
              </div>
            );
          }}
        />
        <Table.Column
          title="Financeiro"
          dataIndex="id"
          align="right"
          width={150}
          render={(v, row: FisicoFinanceiro) => {
            const planejado = row.planejados?.reduce(
              (a, b) =>
                a +
                ((b.planejado as number) *
                  valores.find((c) => c.id === v)?.categorias.find((c) => c.id === b.categoriaID).orcado) /
                  100,
              0
            );
            const realizado = valores.find((c) => c.id === v)?.categorias.reduce((a, b) => a + b.realizado, 0);
            return (
              <div>
                <div>{moneyMask.format(planejado)}</div>
                <div style={{ color: planejado < realizado ? 'red' : 'blue' }}>{moneyMask.format(realizado)}</div>
              </div>
            );
          }}
        />
      </Table>
    </div>
  );
}

export default FisicoView;
