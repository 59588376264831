import { useEffect } from 'react';
import { Modal, Form, Input, Select, Button, InputNumber, Col, Row } from 'antd';
import { SalvarUsuario, FormUsuario } from '@actions/usuariosActions';
import { HasPermission, HasRole } from '@hooks//usePermissions';
import { cpfMask, moneyMask } from '@utils/masks';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { User } from '@models/User';

function UsuarioForm() {
  const dispatch = useAppDispatch();
  const usuarios = useAppSelector((state) => state.usuarios);
  const user = useAppSelector((state) => state.user.user);
  const permissions = useAppSelector((state) => state.user.user.permissions);
  const [form] = Form.useForm<User>();
  const submitForm = (values: any) => {
    dispatch(SalvarUsuario(values.alcada ? { ...values, alcada: Number(moneyMask.normalize(values.alcada)) } : values));
  };
  useEffect(() => {
    form.resetFields();
    dispatch({ type: 'RESET_FEEDBACK' });
    if (usuarios.usuario) {
      form.setFieldsValue({
        ...usuarios.usuarios.find((c) => c.id === usuarios.usuario),
      });
    }
  }, [usuarios.usuario, usuarios.usuarios, form]);
  return (
    <div>
      <Modal
        open={usuarios.formview}
        onCancel={() => dispatch(FormUsuario(null, false))}
        title="Cadastro Usuario"
        footer={[
          <Button onClick={() => dispatch(FormUsuario(null, false))} key="2">
            Fechar
          </Button>,
          <Button key="3" type="primary" onClick={() => form.submit()} loading={usuarios.savingusuario}>
            Salvar
          </Button>,
        ]}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={submitForm}
          name="usuarioForm"
          initialValues={{ alcada: moneyMask.format(0) }}
        >
          <Form.Item style={{ display: 'none' }} name="id">
            <Input />
          </Form.Item>
          <Form.Item label="Nome" name="nome" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: 'Campo obrigatório' },
              { type: 'email', message: 'Formato de email errado' },
            ]}
          >
            <Input disabled={usuarios.usuario !== null} />
          </Form.Item>
          <Form.Item
            style={{
              display: HasPermission('perfil.update', permissions) || usuarios.usuario === null ? 'block' : 'none',
            }}
            label="Perfis"
            name="roles"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
          >
            <Select mode="multiple" value={form.getFieldValue('roles')}>
              {usuarios.roles.map((c) => {
                if (c.toUpperCase() === 'APROVADOR') {
                  if (HasRole(['master'], user.roles))
                    return (
                      <Select.Option key={c} value={c}>
                        {c.toUpperCase()}
                      </Select.Option>
                    );
                } else
                  return (
                    <Select.Option key={c} value={c}>
                      {c.toUpperCase()}
                    </Select.Option>
                  );
                return null;
              })}
            </Select>
          </Form.Item>
          <Form.Item label="CPF" name="cpf" normalize={(v) => cpfMask.format(cpfMask.normalize(v))}>
            <Input />
          </Form.Item>
          <Form.Item label="Banco" name="banco">
            <Input />
          </Form.Item>
          <Form.Item label="Agência" name="agencia">
            <Input />
          </Form.Item>
          <Form.Item label="Conta" name="contaBancaria">
            <Input />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              if (HasRole(['master'], user.roles) && getFieldValue('roles')?.some((c: string) => c === 'aprovador'))
                return (
                  <div>
                    <Row gutter={[8, 8]}>
                      <Col span={12}>
                        <Form.Item label="Aprova Pedidos" name="aprovaPedido" valuePropName="checked">
                          <Checkbox />
                        </Form.Item>
                      </Col>
                      {getFieldValue('aprovaPedido') && (
                        <Col span={12}>
                          <Form.Item
                            label="Nível de Aprovação"
                            name="nivelPedidos"
                            rules={[
                              { required: true, message: 'Campo Obrigatório' },
                              {
                                validator: (_, val) =>
                                  val < 1 || val > 3 ? Promise.reject('Valor entre 1 e 3') : Promise.resolve(),
                                message: 'Valor nulo',
                              },
                            ]}
                            help="Nível de aprovação. 1, 2 ou 3"
                          >
                            <InputNumber min={1} max={3} />
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col span={12}>
                        <Form.Item label="Aprova Compras" name="aprovaOrdem" valuePropName="checked">
                          <Checkbox />
                        </Form.Item>
                      </Col>
                      {getFieldValue('aprovaOrdem') && (
                        <Col span={12}>
                          <Form.Item
                            label="Nível de Aprovação Compras"
                            name="nivelOrdens"
                            rules={[{ required: true, message: 'Campo Obrigatório' }]}
                            help="Nível de aprovação. 1, 2 ou 3"
                          >
                            <InputNumber min={1} max={3} />
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col span={12}>
                        <Form.Item label="Aprova Folhas" name="aprovaFolha" valuePropName="checked">
                          <Checkbox />
                        </Form.Item>
                      </Col>
                      {getFieldValue('aprovaFolha') && (
                        <Col span={12}>
                          <Form.Item
                            label="Nível de Aprovação Folhas"
                            name="nivelFolhas"
                            rules={[{ required: true, message: 'Campo Obrigatório' }]}
                            help="Nível de aprovação. 1, 2 ou 3"
                          >
                            <InputNumber min={1} max={3} />
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col span={12}>
                        <Form.Item label="Aprova Transferências" name="aprovaTransferencia" valuePropName="checked">
                          <Checkbox />
                        </Form.Item>
                      </Col>
                      {getFieldValue('aprovaTransferencia') && (
                        <Col span={12}>
                          <Form.Item
                            label="Nível de Aprovação Transferências"
                            name="nivelTransferencias"
                            rules={[{ required: true, message: 'Campo Obrigatório' }]}
                            help="Nível de aprovação. 1, 2 ou 3"
                          >
                            <InputNumber min={1} max={3} />
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                  </div>
                );
              else return null;
            }}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
export default UsuarioForm;
