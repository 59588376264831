import { useEffect } from 'react';
import { Button, Col, Divider, Form, Row, Input, Card, Select, Switch } from 'antd';
import { GetUsuarios, GetRoles } from '@actions/usuariosActions';
import { SetPageHeader, GetMailSettings, SalvarMailSettings } from '@actions/userActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Configuracao } from '@models/Configuracao';

const { Option } = Select;
function NotificacaoView() {
  const dispatch = useAppDispatch();
  const { usuarios, loadingusuarios, roles, loadingroles } = useAppSelector((state) => state.usuarios);
  const { mailsettings, loadingmailsettings } = useAppSelector((state) => state.user.user);
  const [form] = Form.useForm<Configuracao>();

  useEffect(() => {
    dispatch(GetUsuarios());
    dispatch(GetMailSettings());
    dispatch(GetRoles());
  }, []);
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Configurações',
        backbutton: true,
        extras: [
          <Button key="1" type="primary" onClick={() => form.submit()} loading={loadingmailsettings}>
            Salvar
          </Button>,
        ],
        subtitle: 'Notificação',
        menu: 'notificacao',
      })
    );
  }, [loadingmailsettings]);
  useEffect(() => {
    if (mailsettings) {
      form.setFieldsValue({ ...mailsettings });
    }
  }, [mailsettings]);
  return (
    <>
      <Form
        form={form}
        name="form"
        scrollToFirstError
        layout="vertical"
        onFinish={(values) => {
          dispatch(
            SalvarMailSettings({
              ...values,
              novoSac: {
                ...values.novoSac,
                perfis: values.novoSac.perfis.join(),
                usuarios: values.novoSac.usuarios.join(),
                emails: values.novoSac.emails.join(),
              },
              atualizacaoSac: {
                ...values.atualizacaoSac,
                perfis: values.atualizacaoSac.perfis.join(),
                usuarios: values.atualizacaoSac.usuarios.join(),
                emails: values.atualizacaoSac.emails.join(),
              },
              encerramentoSac: {
                ...values.encerramentoSac,
                perfis: values.encerramentoSac.perfis.join(),
                usuarios: values.encerramentoSac.usuarios.join(),
                emails: values.encerramentoSac.emails.join(),
              },
              enviarFornecedor: {
                ...values.enviarFornecedor,
                perfis: values.enviarFornecedor.perfis.join(),
                usuarios: values.enviarFornecedor.usuarios.join(),
                emails: values.enviarFornecedor.emails.join(),
              },
              folhaAprovada: {
                ...values.folhaAprovada,
                perfis: values.folhaAprovada.perfis.join(),
                usuarios: values.folhaAprovada.usuarios.join(),
                emails: values.folhaAprovada.emails.join(),
              },
              folhaPaga: {
                ...values.folhaPaga,
                perfis: values.folhaPaga.perfis.join(),
                usuarios: values.folhaPaga.usuarios.join(),
                emails: values.folhaPaga.emails.join(),
              },
              folhaSubmetida: {
                ...values.folhaSubmetida,
                perfis: values.folhaSubmetida.perfis.join(),
                usuarios: values.folhaSubmetida.usuarios.join(),
                emails: values.folhaSubmetida.emails.join(),
              },
              novaOrdem: {
                ...values.novaOrdem,
                perfis: values.novaOrdem.perfis.join(),
                usuarios: values.novaOrdem.usuarios.join(),
                emails: values.novaOrdem.emails.join(),
              },
              novaTransferencia: {
                ...values.novaTransferencia,
                perfis: values.novaTransferencia.perfis.join(),
                usuarios: values.novaTransferencia.usuarios.join(),
                emails: values.novaTransferencia.emails.join(),
              },
              novoFornecedor: {
                ...values.novoFornecedor,
                perfis: values.novoFornecedor.perfis.join(),
                usuarios: values.novoFornecedor.usuarios.join(),
                emails: values.novoFornecedor.emails.join(),
              },
              ordemAprovada: {
                ...values.ordemAprovada,
                perfis: values.ordemAprovada.perfis.join(),
                usuarios: values.ordemAprovada.usuarios.join(),
                emails: values.ordemAprovada.emails.join(),
              },
              ordemCancelada: {
                ...values.ordemCancelada,
                perfis: values.ordemCancelada.perfis.join(),
                usuarios: values.ordemCancelada.usuarios.join(),
                emails: values.ordemCancelada.emails.join(),
              },
              ordemFinalizada: {
                ...values.ordemFinalizada,
                perfis: values.ordemFinalizada.perfis.join(),
                usuarios: values.ordemFinalizada.usuarios.join(),
                emails: values.ordemFinalizada.emails.join(),
              },
              ordemSubstituida: {
                ...values.ordemSubstituida,
                perfis: values.ordemSubstituida.perfis.join(),
                usuarios: values.ordemSubstituida.usuarios.join(),
                emails: values.ordemSubstituida.emails.join(),
              },
              pagamentoRealizado: {
                ...values.pagamentoRealizado,
                perfis: values.pagamentoRealizado.perfis.join(),
                usuarios: values.pagamentoRealizado.usuarios.join(),
                emails: values.pagamentoRealizado.emails.join(),
              },
              pedidoAprovado: {
                ...values.pedidoAprovado,
                perfis: values.pedidoAprovado.perfis.join(),
                usuarios: values.pedidoAprovado.usuarios.join(),
                emails: values.pedidoAprovado.emails.join(),
              },
              pedidoFinalizado: {
                ...values.pedidoFinalizado,
                perfis: values.pedidoFinalizado.perfis.join(),
                usuarios: values.pedidoFinalizado.usuarios.join(),
                emails: values.pedidoFinalizado.emails.join(),
              },
              pedidoSubmetido: {
                ...values.pedidoSubmetido,
                perfis: values.pedidoSubmetido.perfis.join(),
                usuarios: values.pedidoSubmetido.usuarios.join(),
                emails: values.pedidoSubmetido.emails.join(),
              },
              processamentoRealizado: {
                ...values.processamentoRealizado,
                perfis: values.processamentoRealizado.perfis.join(),
                usuarios: values.processamentoRealizado.usuarios.join(),
                emails: values.processamentoRealizado.emails.join(),
              },
              solicitacaoFinalizacao: {
                ...values.solicitacaoFinalizacao,
                perfis: values.solicitacaoFinalizacao.perfis.join(),
                usuarios: values.solicitacaoFinalizacao.usuarios.join(),
                emails: values.solicitacaoFinalizacao.emails.join(),
              },
              solicitacaoPagamento: {
                ...values.solicitacaoPagamento,
                perfis: values.solicitacaoPagamento.perfis.join(),
                usuarios: values.solicitacaoPagamento.usuarios.join(),
                emails: values.solicitacaoPagamento.emails.join(),
              },
              transferenciaAprovada: {
                ...values.transferenciaAprovada,
                perfis: values.transferenciaAprovada.perfis.join(),
                usuarios: values.transferenciaAprovada.usuarios.join(),
                emails: values.transferenciaAprovada.emails.join(),
              },
              transferenciaCancelada: {
                ...values.transferenciaCancelada,
                perfis: values.transferenciaCancelada.perfis.join(),
                usuarios: values.transferenciaCancelada.usuarios.join(),
                emails: values.transferenciaCancelada.emails.join(),
              },
            })
          );
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Card title="SAC">
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <Card hoverable type="inner" title="Novo SAC">
                    <Form.Item name={['novoSac', 'id']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item name={['novoSac', 'perfis']} label="Perfis">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Perfis"
                        loading={loadingroles}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {roles
                          ? roles.map((c, index) => (
                              <Select.Option key={index} value={c}>
                                {c.toUpperCase()}
                              </Select.Option>
                            ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['novoSac', 'usuarios']} label="Usuários">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Usuários"
                        loading={loadingusuarios}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {usuarios
                          ? usuarios
                              .filter(
                                (f) =>
                                  f.ativo === true && !f.roles.includes('investidor') && f.roles.includes('clienteobra')
                              )
                              .map((c, index) => (
                                <Select.Option key={index} value={c.id}>
                                  {c.nome.toUpperCase()}
                                </Select.Option>
                              ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name={['novoSac', 'emails']}
                      label="E-mails"
                      rules={[
                        {
                          type: 'array',
                          message: 'Email inválido',
                          defaultField: { type: 'email', message: 'Email inválido' },
                        },
                      ]}
                    >
                      <Select mode="tags" style={{ width: '100%' }} placeholder="Adicione e-mails">
                        {mailsettings && mailsettings.novoSac.emails.map((item) => <Option key={item}>{item}</Option>)}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['novoSac', 'responsavel']} label="Responsável" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card hoverable type="inner" title="Atualização do SAC">
                    <Form.Item name={['atualizacaoSac', 'id']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item name={['atualizacaoSac', 'perfis']} label="Perfis">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Perfis"
                        loading={loadingroles}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {roles
                          ? roles.map((c, index) => (
                              <Select.Option key={index} value={c}>
                                {c.toUpperCase()}
                              </Select.Option>
                            ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['atualizacaoSac', 'usuarios']} label="Usuários">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Usuários"
                        loading={loadingusuarios}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {usuarios
                          ? usuarios
                              .filter(
                                (f) =>
                                  f.ativo === true && !f.roles.includes('investidor') && f.roles.includes('clienteobra')
                              )
                              .map((c, index) => (
                                <Select.Option key={index} value={c.id}>
                                  {c.nome.toUpperCase()}
                                </Select.Option>
                              ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['atualizacaoSac', 'emails']} label="E-mails">
                      <Select mode="tags" style={{ width: '100%' }} placeholder="Adicione e-mails">
                        {mailsettings &&
                          mailsettings.atualizacaoSac.emails.map((item) => <Option key={item}>{item}</Option>)}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['atualizacaoSac', 'responsavel']} label="Responsável" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card hoverable type="inner" title="Encerramento do SAC">
                    <Form.Item name={['encerramentoSac', 'id']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item name={['encerramentoSac', 'perfis']} label="Perfis">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Perfis"
                        loading={loadingroles}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {roles
                          ? roles.map((c, index) => (
                              <Select.Option key={index} value={c}>
                                {c.toUpperCase()}
                              </Select.Option>
                            ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['encerramentoSac', 'usuarios']} label="Usuários">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Usuários"
                        loading={loadingusuarios}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {usuarios
                          ? usuarios
                              .filter(
                                (f) =>
                                  f.ativo === true && !f.roles.includes('investidor') && f.roles.includes('clienteobra')
                              )
                              .map((c, index) => (
                                <Select.Option key={index} value={c.id}>
                                  {c.nome.toUpperCase()}
                                </Select.Option>
                              ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['encerramentoSac', 'emails']} label="E-mails">
                      <Select mode="tags" style={{ width: '100%' }} placeholder="Adicione e-mails">
                        {mailsettings &&
                          mailsettings.encerramentoSac.emails.map((item) => <Option key={item}>{item}</Option>)}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['encerramentoSac', 'responsavel']} label="Responsável" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                  </Card>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={24}>
            <Card title="Engenharia">
              <Row gutter={[16, 16]}>
                <Divider>Transferências</Divider>
                <Col span={8}>
                  <Card hoverable type="inner" title="Nova Transferência">
                    <Form.Item name={['novaTransferencia', 'id']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item name={['novaTransferencia', 'perfis']} label="Perfis">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Perfis"
                        loading={loadingroles}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {roles
                          ? roles.map((c, index) => (
                              <Select.Option key={index} value={c}>
                                {c.toUpperCase()}
                              </Select.Option>
                            ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['novaTransferencia', 'usuarios']} label="Usuários">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Usuários"
                        loading={loadingusuarios}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {usuarios
                          ? usuarios
                              .filter(
                                (f) =>
                                  f.ativo === true && !f.roles.includes('investidor') && f.roles.includes('clienteobra')
                              )
                              .map((c, index) => (
                                <Select.Option key={index} value={c.id}>
                                  {c.nome.toUpperCase()}
                                </Select.Option>
                              ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['novaTransferencia', 'emails']} label="E-mails">
                      <Select mode="tags" style={{ width: '100%' }} placeholder="Adicione e-mails">
                        {mailsettings &&
                          mailsettings.novaTransferencia.emails.map((item) => <Option key={item}>{item}</Option>)}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['novaTransferencia', 'comprador']} label="Comprador" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                    <Form.Item name={['novaTransferencia', 'criador']} label="Criador" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card hoverable type="inner" title="Transferência Aprovada">
                    <Form.Item name={['transferenciaAprovada', 'id']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item name={['transferenciaAprovada', 'perfis']} label="Perfis">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Perfis"
                        loading={loadingroles}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {roles
                          ? roles.map((c, index) => (
                              <Select.Option key={index} value={c}>
                                {c.toUpperCase()}
                              </Select.Option>
                            ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['transferenciaAprovada', 'usuarios']} label="Usuários">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Usuários"
                        loading={loadingusuarios}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {usuarios
                          ? usuarios
                              .filter(
                                (f) =>
                                  f.ativo === true && !f.roles.includes('investidor') && f.roles.includes('clienteobra')
                              )
                              .map((c, index) => (
                                <Select.Option key={index} value={c.id}>
                                  {c.nome.toUpperCase()}
                                </Select.Option>
                              ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['transferenciaAprovada', 'emails']} label="E-mails">
                      <Select mode="tags" style={{ width: '100%' }} placeholder="Adicione e-mails">
                        {mailsettings &&
                          mailsettings.transferenciaAprovada.emails.map((item) => <Option key={item}>{item}</Option>)}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['transferenciaAprovada', 'comprador']} label="Comprador" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                    <Form.Item name={['transferenciaAprovada', 'criador']} label="Criador" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card hoverable type="inner" title="Transferência Cancelada">
                    <Form.Item name={['transferenciaCancelada', 'id']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item name={['transferenciaCancelada', 'perfis']} label="Perfis">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Perfis"
                        loading={loadingroles}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {roles
                          ? roles.map((c, index) => (
                              <Select.Option key={index} value={c}>
                                {c.toUpperCase()}
                              </Select.Option>
                            ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['transferenciaCancelada', 'usuarios']} label="Usuários">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Usuários"
                        loading={loadingusuarios}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {usuarios
                          ? usuarios
                              .filter(
                                (f) =>
                                  f.ativo === true && !f.roles.includes('investidor') && f.roles.includes('clienteobra')
                              )
                              .map((c, index) => (
                                <Select.Option key={index} value={c.id}>
                                  {c.nome.toUpperCase()}
                                </Select.Option>
                              ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['transferenciaCancelada', 'emails']} label="E-mails">
                      <Select mode="tags" style={{ width: '100%' }} placeholder="Adicione e-mails">
                        {mailsettings &&
                          mailsettings.transferenciaCancelada.emails.map((item) => <Option key={item}>{item}</Option>)}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['transferenciaCancelada', 'comprador']} label="Comprador" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                    <Form.Item name={['transferenciaCancelada', 'criador']} label="Criador" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card hoverable type="inner" title="Processamento Realizado">
                    <Form.Item name={['processamentoRealizado', 'id']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item name={['processamentoRealizado', 'perfis']} label="Perfis">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Perfis"
                        loading={loadingroles}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {roles
                          ? roles.map((c, index) => (
                              <Select.Option key={index} value={c}>
                                {c.toUpperCase()}
                              </Select.Option>
                            ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['processamentoRealizado', 'usuarios']} label="Usuários">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Usuários"
                        loading={loadingusuarios}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {usuarios
                          ? usuarios
                              .filter(
                                (f) =>
                                  f.ativo === true && !f.roles.includes('investidor') && f.roles.includes('clienteobra')
                              )
                              .map((c, index) => (
                                <Select.Option key={index} value={c.id}>
                                  {c.nome.toUpperCase()}
                                </Select.Option>
                              ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['processamentoRealizado', 'emails']} label="E-mails">
                      <Select mode="tags" style={{ width: '100%' }} placeholder="Adicione e-mails">
                        {mailsettings &&
                          mailsettings.processamentoRealizado.emails.map((item) => <Option key={item}>{item}</Option>)}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['processamentoRealizado', 'comprador']} label="Comprador" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                    <Form.Item name={['processamentoRealizado', 'criador']} label="Criador" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                  </Card>
                </Col>
                <Divider>Ordens</Divider>
                <Col span={8}>
                  <Card hoverable type="inner" title="Nova Ordem">
                    <Form.Item name={['novaOrdem', 'id']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item name={['novaOrdem', 'perfis']} label="Perfis">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Perfis"
                        loading={loadingroles}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {roles
                          ? roles.map((c, index) => (
                              <Select.Option key={index} value={c}>
                                {c.toUpperCase()}
                              </Select.Option>
                            ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['novaOrdem', 'usuarios']} label="Usuários">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Usuários"
                        loading={loadingusuarios}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {usuarios
                          ? usuarios
                              .filter(
                                (f) =>
                                  f.ativo === true && !f.roles.includes('investidor') && f.roles.includes('clienteobra')
                              )
                              .map((c, index) => (
                                <Select.Option key={index} value={c.id}>
                                  {c.nome.toUpperCase()}
                                </Select.Option>
                              ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['novaOrdem', 'emails']} label="E-mails">
                      <Select mode="tags" style={{ width: '100%' }} placeholder="Adicione e-mails">
                        {mailsettings &&
                          mailsettings.novaOrdem.emails.map((item) => <Option key={item}>{item}</Option>)}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['novaOrdem', 'comprador']} label="Comprador" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                    <Form.Item name={['novaOrdem', 'criador']} label="Criador" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card hoverable type="inner" title="Ordem Aprovada">
                    <Form.Item name={['ordemAprovada', 'id']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item name={['ordemAprovada', 'perfis']} label="Perfis">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Perfis"
                        loading={loadingroles}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {roles
                          ? roles.map((c, index) => (
                              <Select.Option key={index} value={c}>
                                {c.toUpperCase()}
                              </Select.Option>
                            ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['ordemAprovada', 'usuarios']} label="Usuários">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Usuários"
                        loading={loadingusuarios}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {usuarios
                          ? usuarios
                              .filter(
                                (f) =>
                                  f.ativo === true && !f.roles.includes('investidor') && f.roles.includes('clienteobra')
                              )
                              .map((c, index) => (
                                <Select.Option key={index} value={c.id}>
                                  {c.nome.toUpperCase()}
                                </Select.Option>
                              ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['ordemAprovada', 'emails']} label="E-mails">
                      <Select mode="tags" style={{ width: '100%' }} placeholder="Adicione e-mails">
                        {mailsettings &&
                          mailsettings.ordemAprovada.emails.map((item) => <Option key={item}>{item}</Option>)}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['ordemAprovada', 'comprador']} label="Comprador" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                    <Form.Item name={['ordemAprovada', 'criador']} label="Criador" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                  </Card>
                </Col>

                <Col span={8}>
                  <Card hoverable type="inner" title="Enviar Para Fornecedor">
                    <Form.Item name={['enviarFornecedor', 'id']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item name={['enviarFornecedor', 'perfis']} label="Perfis">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Perfis"
                        loading={loadingroles}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {roles
                          ? roles.map((c, index) => (
                              <Select.Option key={index} value={c}>
                                {c.toUpperCase()}
                              </Select.Option>
                            ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['enviarFornecedor', 'usuarios']} label="Usuários">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Usuários"
                        loading={loadingusuarios}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {usuarios
                          ? usuarios
                              .filter(
                                (f) =>
                                  f.ativo === true && !f.roles.includes('investidor') && f.roles.includes('clienteobra')
                              )
                              .map((c, index) => (
                                <Select.Option key={index} value={c.id}>
                                  {c.nome.toUpperCase()}
                                </Select.Option>
                              ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['enviarFornecedor', 'emails']} label="E-mails">
                      <Select mode="tags" style={{ width: '100%' }} placeholder="Adicione e-mails">
                        {mailsettings &&
                          mailsettings.enviarFornecedor.emails.map((item) => <Option key={item}>{item}</Option>)}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['enviarFornecedor', 'comprador']} label="Comprador" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                    <Form.Item name={['enviarFornecedor', 'criador']} label="Criador" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card hoverable type="inner" title="Ordem Substituída">
                    <Form.Item name={['ordemSubstituida', 'id']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item name={['ordemSubstituida', 'perfis']} label="Perfis">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Perfis"
                        loading={loadingroles}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {roles
                          ? roles.map((c, index) => (
                              <Select.Option key={index} value={c}>
                                {c.toUpperCase()}
                              </Select.Option>
                            ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['ordemSubstituida', 'usuarios']} label="Usuários">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Usuários"
                        loading={loadingusuarios}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {usuarios
                          ? usuarios
                              .filter(
                                (f) =>
                                  f.ativo === true && !f.roles.includes('investidor') && f.roles.includes('clienteobra')
                              )
                              .map((c, index) => (
                                <Select.Option key={index} value={c.id}>
                                  {c.nome.toUpperCase()}
                                </Select.Option>
                              ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['ordemSubstituida', 'emails']} label="E-mails">
                      <Select mode="tags" style={{ width: '100%' }} placeholder="Adicione e-mails">
                        {mailsettings &&
                          mailsettings.ordemSubstituida.emails.map((item) => <Option key={item}>{item}</Option>)}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['ordemSubstituida', 'comprador']} label="Comprador" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                    <Form.Item name={['ordemSubstituida', 'criador']} label="Criador" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card hoverable type="inner" title="Ordem Cancelada">
                    <Form.Item name={['ordemCancelada', 'id']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item name={['ordemCancelada', 'perfis']} label="Perfis">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Perfis"
                        loading={loadingroles}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {roles
                          ? roles.map((c, index) => (
                              <Select.Option key={index} value={c}>
                                {c.toUpperCase()}
                              </Select.Option>
                            ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['ordemCancelada', 'usuarios']} label="Usuários">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Usuários"
                        loading={loadingusuarios}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {usuarios
                          ? usuarios
                              .filter(
                                (f) =>
                                  f.ativo === true && !f.roles.includes('investidor') && f.roles.includes('clienteobra')
                              )
                              .map((c, index) => (
                                <Select.Option key={index} value={c.id}>
                                  {c.nome.toUpperCase()}
                                </Select.Option>
                              ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['ordemCancelada', 'emails']} label="E-mails">
                      <Select mode="tags" style={{ width: '100%' }} placeholder="Adicione e-mails">
                        {mailsettings &&
                          mailsettings.ordemCancelada.emails.map((item) => <Option key={item}>{item}</Option>)}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['ordemCancelada', 'comprador']} label="Comprador" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                    <Form.Item name={['ordemCancelada', 'criador']} label="Criador" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card hoverable type="inner" title="Solicitação de Pagamento">
                    <Form.Item name={['solicitacaoPagamento', 'id']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item name={['solicitacaoPagamento', 'perfis']} label="Perfis">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Perfis"
                        loading={loadingroles}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {roles
                          ? roles.map((c, index) => (
                              <Select.Option key={index} value={c}>
                                {c.toUpperCase()}
                              </Select.Option>
                            ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['solicitacaoPagamento', 'usuarios']} label="Usuários">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Usuários"
                        loading={loadingusuarios}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {usuarios
                          ? usuarios
                              .filter(
                                (f) =>
                                  f.ativo === true && !f.roles.includes('investidor') && f.roles.includes('clienteobra')
                              )
                              .map((c, index) => (
                                <Select.Option key={index} value={c.id}>
                                  {c.nome.toUpperCase()}
                                </Select.Option>
                              ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['solicitacaoPagamento', 'emails']} label="E-mails">
                      <Select mode="tags" style={{ width: '100%' }} placeholder="Adicione e-mails">
                        {mailsettings &&
                          mailsettings.solicitacaoPagamento.emails.map((item) => <Option key={item}>{item}</Option>)}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['solicitacaoPagamento', 'comprador']} label="Comprador" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                    <Form.Item name={['solicitacaoPagamento', 'criador']} label="Criador" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card hoverable type="inner" title="Pagamento Realizado">
                    <Form.Item name={['pagamentoRealizado', 'id']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item name={['pagamentoRealizado', 'perfis']} label="Perfis">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Perfis"
                        loading={loadingroles}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {roles
                          ? roles.map((c, index) => (
                              <Select.Option key={index} value={c}>
                                {c.toUpperCase()}
                              </Select.Option>
                            ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['pagamentoRealizado', 'usuarios']} label="Usuários">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Usuários"
                        loading={loadingusuarios}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {usuarios
                          ? usuarios
                              .filter(
                                (f) =>
                                  f.ativo === true && !f.roles.includes('investidor') && f.roles.includes('clienteobra')
                              )
                              .map((c, index) => (
                                <Select.Option key={index} value={c.id}>
                                  {c.nome.toUpperCase()}
                                </Select.Option>
                              ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['pagamentoRealizado', 'emails']} label="E-mails">
                      <Select mode="tags" style={{ width: '100%' }} placeholder="Adicione e-mails">
                        {mailsettings &&
                          mailsettings.pagamentoRealizado.emails.map((item) => <Option key={item}>{item}</Option>)}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['pagamentoRealizado', 'comprador']} label="Comprador" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                    <Form.Item name={['pagamentoRealizado', 'criador']} label="Criador" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                  </Card>
                </Col>

                <Col span={8}>
                  <Card hoverable type="inner" title="Solicitação de Finalização">
                    <Form.Item name={['solicitacaoFinalizacao', 'id']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item name={['solicitacaoFinalizacao', 'perfis']} label="Perfis">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Perfis"
                        loading={loadingroles}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {roles
                          ? roles.map((c, index) => (
                              <Select.Option key={index} value={c}>
                                {c.toUpperCase()}
                              </Select.Option>
                            ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['solicitacaoFinalizacao', 'usuarios']} label="Usuários">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Usuários"
                        loading={loadingusuarios}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {usuarios
                          ? usuarios
                              .filter(
                                (f) =>
                                  f.ativo === true && !f.roles.includes('investidor') && f.roles.includes('clienteobra')
                              )
                              .map((c, index) => (
                                <Select.Option key={index} value={c.id}>
                                  {c.nome.toUpperCase()}
                                </Select.Option>
                              ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['solicitacaoFinalizacao', 'emails']} label="E-mails">
                      <Select mode="tags" style={{ width: '100%' }} placeholder="Adicione e-mails">
                        {mailsettings &&
                          mailsettings.solicitacaoFinalizacao.emails.map((item) => <Option key={item}>{item}</Option>)}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['solicitacaoFinalizacao', 'comprador']} label="Comprador" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                    <Form.Item name={['solicitacaoFinalizacao', 'criador']} label="Criador" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card hoverable type="inner" title="Ordem Finalizada">
                    <Form.Item name={['ordemFinalizada', 'id']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item name={['ordemFinalizada', 'perfis']} label="Perfis">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Perfis"
                        loading={loadingroles}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {roles
                          ? roles.map((c, index) => (
                              <Select.Option key={index} value={c}>
                                {c.toUpperCase()}
                              </Select.Option>
                            ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['ordemFinalizada', 'usuarios']} label="Usuários">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Usuários"
                        loading={loadingusuarios}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {usuarios
                          ? usuarios
                              .filter(
                                (f) =>
                                  f.ativo === true && !f.roles.includes('investidor') && f.roles.includes('clienteobra')
                              )
                              .map((c, index) => (
                                <Select.Option key={index} value={c.id}>
                                  {c.nome.toUpperCase()}
                                </Select.Option>
                              ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['ordemFinalizada', 'emails']} label="E-mails">
                      <Select mode="tags" style={{ width: '100%' }} placeholder="Adicione e-mails">
                        {mailsettings &&
                          mailsettings.ordemFinalizada.emails.map((item) => <Option key={item}>{item}</Option>)}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['ordemFinalizada', 'comprador']} label="Comprador" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                    <Form.Item name={['ordemFinalizada', 'criador']} label="Criador" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                  </Card>
                </Col>
                <Divider>Folhas</Divider>
                <Col span={8}>
                  <Card hoverable type="inner" title="Folha submetida para aprovação">
                    <Form.Item name={['folhaSubmetida', 'id']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item name={['folhaSubmetida', 'perfis']} label="Perfis">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Perfis"
                        loading={loadingroles}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {roles
                          ? roles.map((c, index) => (
                              <Select.Option key={index} value={c}>
                                {c.toUpperCase()}
                              </Select.Option>
                            ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['folhaSubmetida', 'usuarios']} label="Usuários">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Usuários"
                        loading={loadingusuarios}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {usuarios
                          ? usuarios
                              .filter(
                                (f) =>
                                  f.ativo === true && !f.roles.includes('investidor') && f.roles.includes('clienteobra')
                              )
                              .map((c, index) => (
                                <Select.Option key={index} value={c.id}>
                                  {c.nome.toUpperCase()}
                                </Select.Option>
                              ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['folhaSubmetida', 'emails']} label="E-mails">
                      <Select mode="tags" style={{ width: '100%' }} placeholder="Adicione e-mails">
                        {mailsettings &&
                          mailsettings.folhaSubmetida.emails.map((item) => <Option key={item}>{item}</Option>)}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['folhaSubmetida', 'comprador']} label="Comprador" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                    <Form.Item name={['folhaSubmetida', 'criador']} label="Criador" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card hoverable type="inner" title="Folha Aprovada">
                    <Form.Item name={['folhaAprovada', 'id']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item name={['folhaAprovada', 'perfis']} label="Perfis">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Perfis"
                        loading={loadingroles}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {roles
                          ? roles.map((c, index) => (
                              <Select.Option key={index} value={c}>
                                {c.toUpperCase()}
                              </Select.Option>
                            ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['folhaAprovada', 'usuarios']} label="Usuários">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Usuários"
                        loading={loadingusuarios}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {usuarios
                          ? usuarios
                              .filter(
                                (f) =>
                                  f.ativo === true && !f.roles.includes('investidor') && f.roles.includes('clienteobra')
                              )
                              .map((c, index) => (
                                <Select.Option key={index} value={c.id}>
                                  {c.nome.toUpperCase()}
                                </Select.Option>
                              ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['folhaAprovada', 'emails']} label="E-mails">
                      <Select mode="tags" style={{ width: '100%' }} placeholder="Adicione e-mails">
                        {mailsettings &&
                          mailsettings.folhaAprovada.emails.map((item) => <Option key={item}>{item}</Option>)}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['folhaAprovada', 'comprador']} label="Comprador" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                    <Form.Item name={['folhaAprovada', 'criador']} label="Criador" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card hoverable type="inner" title="Folha Paga">
                    <Form.Item name={['folhaPaga', 'id']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item name={['folhaPaga', 'perfis']} label="Perfis">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Perfis"
                        loading={loadingroles}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {roles
                          ? roles.map((c, index) => (
                              <Select.Option key={index} value={c}>
                                {c.toUpperCase()}
                              </Select.Option>
                            ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['folhaPaga', 'usuarios']} label="Usuários">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Usuários"
                        loading={loadingusuarios}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {usuarios
                          ? usuarios
                              .filter(
                                (f) =>
                                  f.ativo === true && !f.roles.includes('investidor') && f.roles.includes('clienteobra')
                              )
                              .map((c, index) => (
                                <Select.Option key={index} value={c.id}>
                                  {c.nome.toUpperCase()}
                                </Select.Option>
                              ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['folhaPaga', 'emails']} label="E-mails">
                      <Select mode="tags" style={{ width: '100%' }} placeholder="Adicione e-mails">
                        {mailsettings &&
                          mailsettings.folhaPaga.emails.map((item) => <Option key={item}>{item}</Option>)}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['folhaPaga', 'comprador']} label="Comprador" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                    <Form.Item name={['folhaPaga', 'criador']} label="Criador" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                  </Card>
                </Col>
                <Divider>Pedidos</Divider>
                <Col span={8}>
                  <Card hoverable type="inner" title="Pedido submetido para aprovação">
                    <Form.Item name={['pedidoSubmetido', 'id']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item name={['pedidoSubmetido', 'perfis']} label="Perfis">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Perfis"
                        loading={loadingroles}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {roles
                          ? roles.map((c, index) => (
                              <Select.Option key={index} value={c}>
                                {c.toUpperCase()}
                              </Select.Option>
                            ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['pedidoSubmetido', 'usuarios']} label="Usuários">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Usuários"
                        loading={loadingusuarios}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {usuarios
                          ? usuarios
                              .filter(
                                (f) =>
                                  f.ativo === true && !f.roles.includes('investidor') && f.roles.includes('clienteobra')
                              )
                              .map((c, index) => (
                                <Select.Option key={index} value={c.id}>
                                  {c.nome.toUpperCase()}
                                </Select.Option>
                              ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['pedidoSubmetido', 'emails']} label="E-mails">
                      <Select mode="tags" style={{ width: '100%' }} placeholder="Adicione e-mails">
                        {mailsettings &&
                          mailsettings.pedidoSubmetido.emails.map((item) => <Option key={item}>{item}</Option>)}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['pedidoSubmetido', 'comprador']} label="Comprador" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                    <Form.Item name={['pedidoSubmetido', 'criador']} label="Criador" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card hoverable type="inner" title="Pedido Aprovado">
                    <Form.Item name={['pedidoAprovado', 'id']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item name={['pedidoAprovado', 'perfis']} label="Perfis">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Perfis"
                        loading={loadingroles}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {roles
                          ? roles.map((c, index) => (
                              <Select.Option key={index} value={c}>
                                {c.toUpperCase()}
                              </Select.Option>
                            ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['pedidoAprovado', 'usuarios']} label="Usuários">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Usuários"
                        loading={loadingusuarios}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {usuarios
                          ? usuarios
                              .filter(
                                (f) =>
                                  f.ativo === true && !f.roles.includes('investidor') && f.roles.includes('clienteobra')
                              )
                              .map((c, index) => (
                                <Select.Option key={index} value={c.id}>
                                  {c.nome.toUpperCase()}
                                </Select.Option>
                              ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['pedidoAprovado', 'emails']} label="E-mails">
                      <Select mode="tags" style={{ width: '100%' }} placeholder="Adicione e-mails">
                        {mailsettings &&
                          mailsettings.pedidoAprovado.emails.map((item) => <Option key={item}>{item}</Option>)}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['pedidoAprovado', 'comprador']} label="Comprador" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                    <Form.Item name={['pedidoAprovado', 'criador']} label="Criador" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card hoverable type="inner" title="Pedido Finalizado">
                    <Form.Item name={['pedidoFinalizado', 'id']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item name={['pedidoFinalizado', 'perfis']} label="Perfis">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Perfis"
                        loading={loadingroles}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {roles
                          ? roles.map((c, index) => (
                              <Select.Option key={index} value={c}>
                                {c.toUpperCase()}
                              </Select.Option>
                            ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['pedidoFinalizado', 'usuarios']} label="Usuários">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Usuários"
                        loading={loadingusuarios}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {usuarios
                          ? usuarios
                              .filter(
                                (f) =>
                                  f.ativo === true && !f.roles.includes('investidor') && f.roles.includes('clienteobra')
                              )
                              .map((c, index) => (
                                <Select.Option key={index} value={c.id}>
                                  {c.nome.toUpperCase()}
                                </Select.Option>
                              ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['pedidoFinalizado', 'emails']} label="E-mails">
                      <Select mode="tags" style={{ width: '100%' }} placeholder="Adicione e-mails">
                        {mailsettings &&
                          mailsettings.pedidoFinalizado.emails.map((item) => <Option key={item}>{item}</Option>)}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['pedidoFinalizado', 'comprador']} label="Comprador" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                    <Form.Item name={['pedidoFinalizado', 'criador']} label="Criador" valuePropName="checked">
                      <Switch />
                    </Form.Item>
                  </Card>
                </Col>

                <Divider>Fornecedores</Divider>
                <Col span={8}>
                  <Card hoverable type="inner" title="Novo Fornecedor">
                    <Form.Item name={['novoFornecedor', 'id']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item name={['novoFornecedor', 'perfis']} label="Perfis">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Perfis"
                        loading={loadingroles}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {roles
                          ? roles.map((c, index) => (
                              <Select.Option key={index} value={c}>
                                {c.toUpperCase()}
                              </Select.Option>
                            ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['novoFornecedor', 'usuarios']} label="Usuários">
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Selecione os Usuários"
                        loading={loadingusuarios}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                      >
                        {usuarios
                          ? usuarios
                              .filter(
                                (f) =>
                                  f.ativo === true && !f.roles.includes('investidor') && f.roles.includes('clienteobra')
                              )
                              .map((c, index) => (
                                <Select.Option key={index} value={c.id}>
                                  {c.nome.toUpperCase()}
                                </Select.Option>
                              ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['novoFornecedor', 'emails']} label="E-mails">
                      <Select mode="tags" style={{ width: '100%' }} placeholder="Adicione e-mails">
                        {mailsettings &&
                          mailsettings.novoFornecedor.emails.map((item) => <Option key={item}>{item}</Option>)}
                      </Select>
                    </Form.Item>
                  </Card>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  );
}
export default NotificacaoView;
