import { Descriptions } from 'antd';
import FolhaStatusTag from './Shared/FolhaStatusTag';
import { useFolhaForm } from './useFolhaForm';

function FolhaFormResumoFinalGeral() {
  const { folha } = useFolhaForm();
  return (
    <div>
      <Descriptions bordered size="small" column={2}>
        <Descriptions.Item label="Folha Nº">{folha?.cod}</Descriptions.Item>
        <Descriptions.Item label="Situação">
          <FolhaStatusTag status={folha?.situacao} />
        </Descriptions.Item>
        <Descriptions.Item label="Empresa Faturamento">{folha?.empresaFaturamento}</Descriptions.Item>
        <Descriptions.Item label="Orçamento">{folha?.orcamento}</Descriptions.Item>
        <Descriptions.Item label="Solicitante">{folha?.comprador}</Descriptions.Item>
        <Descriptions.Item label="Criadro">{folha?.criador}</Descriptions.Item>
      </Descriptions>
    </div>
  );
}

export default FolhaFormResumoFinalGeral;
