import { OrdemItem } from '@models/OrdemItem';
import { Modal } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import OrdemItemInfo from './OrdemForm.ItemInfo';

function OrdemItemInfoModal({
  item,
  visible,
  setVisible,
}: {
  item: OrdemItem;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}) {
  if (item == null) return null;
  return (
    <Modal open={visible} onCancel={() => setVisible(false)} title={'Informações Orçamentárias'}>
      <OrdemItemInfo id={item.orcamentoItemID} />
    </Modal>
  );
}

export default OrdemItemInfoModal;
