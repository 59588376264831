import { useEffect } from 'react';
import { Modal, Form, Input, Button, Divider, Row, Col, Select, Checkbox } from 'antd';
import { SalvarFornecedor, FormFornecedor } from '@actions/fornecedoresActions';
import { cnpjMask, mobMask, cpfMask, fixoMask, cepMask } from '@utils/masks';
import moment from 'moment';
import { Permission } from '@hooks//usePermissions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Fornecedor } from '@models/Fornecedor';

function FornecedorForm() {
  const dispatch = useAppDispatch();
  const fornecedores = useAppSelector((state) => state.fornecedores);
  const [form] = Form.useForm();
  const submitForm = (values: Fornecedor) => {
    dispatch(SalvarFornecedor(values));
  };
  useEffect(() => {
    form.resetFields();
    if (fornecedores.fornecedor) {
      form.setFieldsValue({
        ...fornecedores.fornecedores.find((c) => c.id === fornecedores.fornecedor),
        dataNascimento: moment(fornecedores.fornecedores.find((c) => c.id === fornecedores.fornecedor).dataNascimento),
      });
    }
  }, [fornecedores.fornecedor, fornecedores.fornecedores, form]);
  return (
    <div>
      <Modal
        open={fornecedores.formview}
        onCancel={() => dispatch(FormFornecedor(null, false))}
        title="Cadastro Fornecedor"
        footer={[
          <Button onClick={() => dispatch(FormFornecedor(null, false))} key="2">
            Fechar
          </Button>,
          <Permission key="3" permission="fornecedor.update">
            <Button type="primary" onClick={() => form.submit()} loading={fornecedores.savingfornecedor}>
              Salvar
            </Button>
          </Permission>,
        ]}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={submitForm}
          name="fornecedorForm"
          initialValues={{ ativo: true, colaborador: false }}
        >
          <Form.Item style={{ display: 'none' }} name="id">
            <Input />
          </Form.Item>
          <Form.Item name="ativo" valuePropName="checked">
            <Checkbox>Ativo</Checkbox>
          </Form.Item>
          <Form.Item
            label="CPF/CNPJ"
            name="cpF_CNPJ"
            normalize={(v) => {
              if (v.length > 14) return cnpjMask.format(cnpjMask.normalize(v));
              else return cpfMask.format(cpfMask.normalize(v));
            }}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Nome" name="nome" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Endereço" name="endereco">
            <Input />
          </Form.Item>
          <Row gutter={8}>
            <Col span={8}>
              <Form.Item label="Cidade" name="cidade">
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Estado" name="estado">
                <Select showSearch>
                  <Select.Option value="Acre">Acre</Select.Option>
                  <Select.Option value="Alagoas">Alagoas</Select.Option>
                  <Select.Option value="Amazonas">Amazonas</Select.Option>
                  <Select.Option value="Amapá">Amapá</Select.Option>
                  <Select.Option value="Bahia">Bahia</Select.Option>
                  <Select.Option value="Ceará">Ceará</Select.Option>
                  <Select.Option value="Distrito Federal">Distrito Federal</Select.Option>
                  <Select.Option value="Espírito Santo">Espírito Santo</Select.Option>
                  <Select.Option value="Goiás">Goiás</Select.Option>
                  <Select.Option value="Maranhão">Maranhão</Select.Option>
                  <Select.Option value="Mato Grosso">Mato Grosso</Select.Option>
                  <Select.Option value="Mato Grosso do Sul">Mato Grosso do Sul</Select.Option>
                  <Select.Option value="Minas Gerais">Minas Gerais</Select.Option>
                  <Select.Option value="Pará">Pará</Select.Option>
                  <Select.Option value="Paraíba">Paraíba</Select.Option>
                  <Select.Option value="Paraná">Paraná</Select.Option>
                  <Select.Option value="Pernambuco">Pernambuco</Select.Option>
                  <Select.Option value="Piauí">Piauí</Select.Option>
                  <Select.Option value="Rio de Janeiro">Rio de Janeiro</Select.Option>
                  <Select.Option value="Rio Grande do Norte">Rio Grande do Norte</Select.Option>
                  <Select.Option value="Rondônia">Rondônia</Select.Option>
                  <Select.Option value="Rio Grande do Sul">Rio Grande do Sul</Select.Option>
                  <Select.Option value="Roraima">Roraima</Select.Option>
                  <Select.Option value="Santa Catarina">Santa Catarina</Select.Option>
                  <Select.Option value="Sergipe">Sergipe</Select.Option>
                  <Select.Option value="São Paulo">São Paulo</Select.Option>
                  <Select.Option value="Tocantins">Tocantins</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="CEP" name="cep" normalize={(v) => cepMask.format(cepMask.normalize(v))}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={10}>
              <Form.Item
                label="Telefone"
                name="telefone"
                normalize={(v) => {
                  if (v.length > 13) return mobMask.format(mobMask.normalize(v));
                  else return fixoMask.format(fixoMask.normalize(v));
                }}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={14}>
              <Form.Item label="Email" name="email" rules={[{ type: 'email', message: 'Email inválido' }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Representante" name="representante">
            <Input />
          </Form.Item>
          <Form.Item name="colaborador" hidden>
            <Input />
          </Form.Item>
          <Divider>Informações para pagamento</Divider>
          <Row gutter={8}>
            <Col span={8}>
              <Form.Item label="Banco" name="banco">
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Agência" name="agencia">
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Conta" name="contaBancaria">
                <Input />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Operação" name="operacao">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="CPF/CNPJ"
            name="cpF_CNPJ_Conta"
            normalize={(v) => {
              if (v.length > 14) return cnpjMask.format(cnpjMask.normalize(v));
              else return cpfMask.format(cpfMask.normalize(v));
            }}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Nome Titular" name="nomeConta">
            <Input />
          </Form.Item>
          <Form.Item label="Pix" name="pix">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
export default FornecedorForm;
