import { useEffect, useState } from 'react';
import { Table, Input, Button, Popconfirm } from 'antd';
import { GetLoteamentos, FormLoteamento, DeleteLoteamento } from '@actions/loteamentosActions';
import LoteamentoForm from './LoteamentoForm';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { filterobject, getColumnSearchProps, sortAlpha } from '@utils/filterandsort';
import { SetPageHeader } from '@actions/userActions';
import { Permission } from '@hooks//usePermissions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Loteamento } from '@models/Loteamento';

function LoteamentosView() {
  const dispatch = useAppDispatch();
  const loteamentos = useAppSelector((state) => state.loteamentos);
  const [filter, setFilter] = useState('');
  useEffect(() => {
    dispatch(GetLoteamentos());
    dispatch(
      SetPageHeader({
        title: 'Cadastros',
        backbutton: true,
        extras: [
          <Permission key="1" permission="loteamento.add">
            <Button onClick={() => dispatch(FormLoteamento(null, true))} type="primary">
              Novo Loteamento
            </Button>
          </Permission>,
        ],
        subtitle: 'Loteamentos',
        menu: 'loteamentos',
      })
    );
  }, []);

  return (
    <div>
      <LoteamentoForm />
      <div style={{ textAlign: 'right' }}>
        <Input style={{ width: 200 }} onChange={(e) => setFilter(e.target.value)} placeholder="Buscar" allowClear />
      </div>
      <Table
        size="small"
        rowKey="id"
        loading={loteamentos.loadingloteamentos}
        dataSource={filterobject(loteamentos.loteamentos, filter)}
        scroll={{ y: 'calc(100vh - 300px)', x: 1024 }}
        pagination={{
          position: ['bottomRight'],
          size: 'small',
          showSizeChanger: true,
          pageSizeOptions: [10, 25, 50, 100],
          showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
        }}
      >
        <Table.Column
          width={80}
          fixed="left"
          render={(_, row: Loteamento) => (
            <div>
              <Permission permission="loteamento.update">
                <Button icon={<EditOutlined />} onClick={() => dispatch(FormLoteamento(row.id, true))} size="small" />
              </Permission>
              <Permission permission="loteamento.delete">
                <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(DeleteLoteamento(row.id))}>
                  <Button icon={<DeleteOutlined />} size="small" />
                </Popconfirm>
              </Permission>
            </div>
          )}
        />
        <Table.Column
          fixed="left"
          title="Nome"
          dataIndex="nome"
          sorter={(a, b) => sortAlpha(a.nome, b.nome)}
          {...getColumnSearchProps('nome', 'string', 'Nome')}
        />
        <Table.Column
          title="Estado"
          dataIndex="estado"
          sorter={(a, b) => sortAlpha(a.estado, b.estado)}
          {...getColumnSearchProps('estado', 'string', 'Estado')}
        />
        <Table.Column
          title="Cidade"
          dataIndex="cidade"
          sorter={(a, b) => sortAlpha(a.cidade, b.cidade)}
          {...getColumnSearchProps('cidade', 'string', 'Cidade')}
        />
      </Table>
    </div>
  );
}
export default LoteamentosView;
