import React, { ReactNode } from 'react';
import * as signalR from '@microsoft/signalr';
import { useState } from 'react';
import { getCookie } from '@services/localStorage';
import { notification } from 'antd';
import { getGruposUser, getNewNotification, resetUnread, setUsers } from '@actions/messagesActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
interface NotificationCongtextProps {
  connect: () => void;
  SendMessage: (message: string, title: string, groups: string, data: string) => void;
  CloseConnection: () => void;
  JoinGroup: (grupo: string, join: boolean) => void;
  ResetLastRead: () => void;
}
const NotificationsContext = React.createContext<NotificationCongtextProps>(null);

const NotificationsProvider = ({ children }: { children: ReactNode | ReactNode[] }) => {
  const { user } = useAppSelector((state) => state.user);
  const [conn, setConn] = useState(null);
  const dispatch = useAppDispatch();
  const connect = async () => {
    try {
      const connection = new signalR.HubConnectionBuilder()
        .withUrl(
          process.env.NODE_ENV === 'development'
            ? 'https://localhost:7099/messages'
            : 'https://api.elevargestao.com.br/messages',
          {
            accessTokenFactory: () => `${getCookie('token')}`,
          }
        )
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Information)
        .build();
      connection.on('ReceiveMessage', (title, message, link, who, username, group) => {
        if (user.id === who) {
          connection.invoke('SendNotification', message, title, group, link, who);
        }
        if (user.id !== who) {
          notification.info({
            message: title,
            description: (
              <div>
                <div>{message}</div>
                <div>{username}</div>
              </div>
            ),
          });
          dispatch(getNewNotification());
        }
      });
      connection.on('NewNotification', () => {
        dispatch(getNewNotification());
      });
      connection.on('ResetLastRead', () => {
        dispatch(resetUnread());
      });
      connection.on('RefreshGroups', (grupo, join) => {
        dispatch(getGruposUser());
        connection.invoke('JoinGroup', grupo, join);
      });
      connection.on('UserList', (users) => {
        dispatch(setUsers(users));
      });
      await connection.start();
      setConn(connection);
    } catch (e) {
      console.log(e);
    }
  };
  const SendMessage = (message: string, title: string, groups: string, data: string) => {
    try {
      conn.invoke('SendNotification', message, title, groups, data);
    } catch (e) {
      console.log(e);
    }
  };
  const ResetLastRead = () => {
    try {
      conn.invoke('ResetLastRead');
    } catch (e) {
      console.log(e);
    }
  };
  const CloseConnection = () => {
    try {
      conn.stop().then((res: any) => console.log(res));
      setConn(null);
    } catch (e) {
      console.log(e);
    }
  };
  const JoinGroup = (grupo: string, join: boolean) => {
    try {
      conn
        .invoke('JoinGroup', grupo, join)
        .then((c: any) => console.log(c))
        .catch((res: any) => console.log(res));
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <NotificationsContext.Provider value={{ connect, SendMessage, CloseConnection, JoinGroup, ResetLastRead }}>
      {children}
    </NotificationsContext.Provider>
  );
};
const useNotification = () => {
  const context = React.useContext(NotificationsContext);
  if (!context) throw new Error('Notifications component must be wrpped by Notifications Provider');
  const { connect, SendMessage, CloseConnection, JoinGroup, ResetLastRead } = context;
  return { connect, SendMessage, CloseConnection, JoinGroup, ResetLastRead };
};

export { NotificationsProvider, NotificationsContext, useNotification };
