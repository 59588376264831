import { useEffect } from 'react';
import { Modal, Form, Input, Button } from 'antd';
import { SalvarCorretor, FormCorretor } from '@actions/corretoresActions';
import { fixoMask, mobMask } from '@utils/masks';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Corretor } from '@models/Corretor';

function CorretorForm() {
  const dispatch = useAppDispatch();
  const corretores = useAppSelector((state) => state.corretores);
  const [form] = Form.useForm<Corretor>();
  const submitForm = (values: Corretor) => {
    dispatch(SalvarCorretor(values));
  };
  useEffect(() => {
    form.resetFields();
    if (corretores.corretor) {
      form.setFieldsValue(corretores.corretores.find((c) => c.id === corretores.corretor));
    }
  }, [corretores.corretor, corretores.corretores, form]);
  return (
    <div>
      <Modal
        open={corretores.formview}
        onCancel={() => dispatch(FormCorretor(null, false))}
        title="Cadastro Corretor"
        footer={[
          <Button onClick={() => dispatch(FormCorretor(null, false))} key="2">
            Fechar
          </Button>,
          <Button key="3" type="primary" onClick={() => form.submit()} loading={corretores.savingcorretor}>
            Salvar
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form} onFinish={submitForm} name="corretorForm">
          <Form.Item style={{ display: 'none' }} name="id">
            <Input />
          </Form.Item>
          <Form.Item label="Nome" name="nome" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Responsável" name="contatoPrincipal">
            <Input />
          </Form.Item>
          <Form.Item label="Telefone" name="telefone">
            <Input
              onChange={(e) =>
                form.setFieldsValue({
                  telefone:
                    e.target.value.length < 14
                      ? fixoMask.format(fixoMask.normalize(e.target.value))
                      : mobMask.format(mobMask.normalize(e.target.value)),
                })
              }
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
export default CorretorForm;
