import { IFolhaItem, IFolhaRateio } from '@models/Folha';
import { doubleMask2, moneyMask } from '@utils/masks';
import { Alert, Form, Input, InputNumber, Skeleton, Table, Typography } from 'antd';
import { useFolhaForm } from './useFolhaForm';

function FolhaFormRateioColaboradores() {
  const { folha, isLoading, form } = useFolhaForm();

  if (isLoading) return <Skeleton active />;
  return (
    <div>
      <Alert
        message="Distribua o valor medido entre os colaboradores"
        type="info"
        showIcon
        closable
        style={{ width: 'fit-content', float: 'right', margin: 5 }}
      />
      <Form.List name="rateio">
        {(fields) =>
          fields.map((field) => (
            <div>
              <Form.Item name={[field.name, 'id']} hidden>
                <Input />
              </Form.Item>
              <Form.Item name={[field.name, 'porcentagem']} hidden>
                <Input />
              </Form.Item>
              <Form.Item name={[field.name, 'medicao']} hidden>
                <Input />
              </Form.Item>
            </div>
          ))
        }
      </Form.List>
      <Form.List name="rateio">
        {(fields) => (
          <Table
            size="small"
            dataSource={form.getFieldValue('rateio') || []}
            pagination={false}
            scroll={{ y: 300 }}
            rowKey="id"
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row style={{ fontWeight: 'bold' }}>
                  <Table.Summary.Cell index={0} colSpan={2} align="left">
                    {`${form.getFieldValue('colaboradores').length}  Colaboradores`}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={0} colSpan={2} align="right">
                    TOTAL
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} align="right">
                    <Form.Item noStyle shouldUpdate>
                      {({ getFieldValue }) => {
                        const value = getFieldValue('rateio').reduce(
                          (a: number, b: IFolhaRateio) => a + b.porcentagem,
                          0
                        );
                        return (
                          <Typography.Text style={{ color: value > 100 ? 'red' : undefined }}>
                            {doubleMask2.format(value)} / 100%
                          </Typography.Text>
                        );
                      }}
                    </Form.Item>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2} align="right">
                    <Form.Item noStyle shouldUpdate>
                      {({ getFieldValue }) => {
                        const total =
                          Math.floor(
                            getFieldValue('itens')?.reduce((a: number, b: IFolhaItem) => a + b.valorMedir, 0) * 100
                          ) / 100;
                        const value =
                          Math.floor(
                            getFieldValue('rateio').reduce(
                              (a: number, b: IFolhaRateio) => a + moneyMask.normalize(String(b.medicao)),
                              0
                            ) * 100
                          ) / 100;
                        return (
                          <Typography.Text style={{ color: value > total ? 'blue' : undefined }}>
                            {`${moneyMask.format(value)} / ${moneyMask.format(total)}`}
                          </Typography.Text>
                        );
                      }}
                    </Form.Item>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
          >
            <Table.Column render={(_, __, index) => index + 1} width={40} />
            <Table.Column
              title="Nome"
              dataIndex="id"
              render={(v) => folha?.colaboradores.find((c) => c.id === v).nome}
            />
            <Table.Column
              title="Função"
              dataIndex="id"
              render={(v) => folha?.colaboradores.find((c) => c.id === v).funcao}
            />
            <Table.Column
              title="CPF/CNPJ"
              dataIndex="id"
              render={(v) => folha?.colaboradores.find((c) => c.id === v).cpF_CNPJ}
            />
            <Table.Column
              title="Percentual"
              dataIndex="id"
              align="right"
              width={150}
              render={(_, __, index) => (
                <Form.Item noStyle shouldUpdate>
                  {({ setFieldValue, getFieldValue }) => {
                    const total =
                      Math.floor(
                        getFieldValue('itens')?.reduce((a: number, b: IFolhaItem) => a + b.valorMedir, 0) * 100
                      ) / 100;
                    return (
                      <Form.Item
                        name={[fields[index].name, 'porcentagem']}
                        initialValue={0}
                        rules={[
                          () => ({
                            validator(_, value) {
                              if (value === 0) {
                                return Promise.reject('Valor não pode ser zero');
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <InputNumber
                          max={100}
                          min={0}
                          precision={2}
                          decimalSeparator=","
                          onChange={(v) =>
                            setFieldValue(
                              ['rateio', fields[index].name, 'medicao'],
                              moneyMask.format((v * total) / 100)
                            )
                          }
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              )}
            />
            <Table.Column
              title="Valor Medido"
              dataIndex="id"
              align="right"
              width={200}
              render={(_, __, index) => (
                <Form.Item noStyle shouldUpdate>
                  {({ setFieldValue, getFieldValue }) => {
                    const total = getFieldValue('itens')?.reduce((a: number, b: IFolhaItem) => a + b.valorMedir, 0);
                    return (
                      <Form.Item
                        name={[fields[index].name, 'medicao']}
                        initialValue={moneyMask.format(0)}
                        normalize={(v) => moneyMask.format(moneyMask.normalize(v))}
                        rules={[
                          () => ({
                            validator(_, value) {
                              if (value === 0) {
                                return Promise.reject('Valor não pode ser zero');
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input
                          onChange={(e) =>
                            setFieldValue(
                              ['rateio', fields[index].name, 'porcentagem'],
                              Math.floor((moneyMask.normalize(e.target.value) / total) * 100 * 100) / 100
                            )
                          }
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              )}
            />
          </Table>
        )}
      </Form.List>
    </div>
  );
}

export default FolhaFormRateioColaboradores;
