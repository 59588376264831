import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Grupo } from '@models/Grupo';
import { Button, Form, Input, Modal, Select } from 'antd';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SalvarGrupo } from '@actions/gruposActions';
import { doubleMask, moneyMask } from '@utils/masks';

function GrupoForm({
  visible,
  setVisible,
  item,
}: {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  item: Grupo;
}) {
  const [grupoform] = Form.useForm<Grupo>();
  const dispatch = useAppDispatch();
  const { savinggrupo } = useAppSelector((c) => c.grupos);
  const { id } = useParams();
  useEffect(() => {
    grupoform.setFieldsValue({ ...item, orcamentoID: Number(id), id: null });
  }, [item, grupoform]);
  return (
    <Modal
      title={`Cadastro de Grupo`}
      open={visible}
      onCancel={() => setVisible(false)}
      zIndex={1010}
      footer={[
        <Button key="1" onClick={() => setVisible(false)}>
          Fechar
        </Button>,
        <Button key="2" loading={savinggrupo} onClick={() => grupoform.submit()} type="primary">
          Salvar
        </Button>,
      ]}
    >
      <Form
        layout="vertical"
        form={grupoform}
        onFinish={(values: Grupo) =>
          dispatch(SalvarGrupo({ ...values, valor: doubleMask.normalize(values.valor.toString()) }))
        }
        name="grupoForm"
        initialValues={{ valor: moneyMask.format(0) }}
      >
        <Form.Item style={{ display: 'none' }} name="id">
          <Input />
        </Form.Item>
        <Form.Item style={{ display: 'none' }} name="grupo">
          <Input />
        </Form.Item>
        <Form.Item style={{ display: 'none' }} name="orcamentoID">
          <Input />
        </Form.Item>
        <Form.Item style={{ display: 'none' }} name="categoriaID">
          <Input />
        </Form.Item>
        <Form.Item label="Descrição" name="descricao" rules={[{ required: true, message: 'Campo obrigatório' }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Valor"
          name="valor"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
          normalize={(v) => doubleMask.format(doubleMask.normalize(v))}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Unidade" name="unidade" rules={[{ required: true, message: 'Campo obrigatório' }]}>
          <Select>
            <Select.Option value="MT">MT</Select.Option>
            <Select.Option value="M²">M²</Select.Option>
            <Select.Option value="M³">M³</Select.Option>
            <Select.Option value="SC">SC</Select.Option>
            <Select.Option value="PC">PC</Select.Option>
            <Select.Option value="KG">KG</Select.Option>
            <Select.Option value="UND">UND</Select.Option>
            <Select.Option value="Casa">Casa</Select.Option>
            <Select.Option value="VB">VB</Select.Option>
            <Select.Option value="CAR">CAR</Select.Option>
            <Select.Option value="L">L</Select.Option>
            <Select.Option value="TON">TON</Select.Option>
            <Select.Option value="H">H</Select.Option>
            <Select.Option value="Dia">Dia</Select.Option>
            <Select.Option value="Mês">Mês</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default GrupoForm;
