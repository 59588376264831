import { useEffect } from 'react';
import { Card, Table, Statistic, Row, Col, Tabs } from 'antd';
import { GetArea } from '@actions/dashboardActions';
import { filterobject } from '@utils/filterandsort';
import { doubleMask } from '@utils/masks';
import moment from 'moment';
import 'moment/locale/pt-br'; // without this line it didn't work
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { AreaDashboard } from '@models/Dashboard';
moment.locale('pt-br');

function AreaConstruida() {
  const dispatch = useAppDispatch();
  const { areas, loadingareas } = useAppSelector((state) => state.dashboard.areas);
  const { configuracao } = useAppSelector((state) => state.user.user);

  useEffect(() => {
    dispatch(GetArea());
  }, []);
  return (
    <Card size="small" title="Área Contruída Contratada" style={{ height: 370 }}>
      <Row>
        <Col span={12}>
          <Statistic
            title={'Contratado ' + moment().format('YYYY')}
            value={doubleMask.format(areas.filter((c) => c.ano === moment().year()).reduce((a, b) => a + b.area, 0))}
            precision={2}
            groupSeparator="."
            decimalSeparator=","
            valueStyle={{ color: '#3f8600' }}
            suffix="m²"
          />
        </Col>
        <Col span={12}>
          <Statistic
            title={'Meta ' + moment().format('YYYY')}
            value={configuracao.metaAno}
            groupSeparator="."
            decimalSeparator=","
            precision={2}
            valueStyle={{ color: 'blue' }}
            suffix="m²"
          />
        </Col>
      </Row>
      <Tabs defaultActiveKey="4">
        <Tabs.TabPane key="1" tab="2019">
          <Table
            size="small"
            showHeader={false}
            rowKey="id"
            dataSource={filterobject(
              areas.filter((c) => c.ano === 2019),
              ''
            )}
            loading={loadingareas}
            pagination={false}
            scroll={{ y: 150 }}
            footer={() =>
              'Totalizando ' +
              doubleMask.format(areas.filter((c) => c.ano === 2019).reduce((a, b) => a + b.area, 0)) +
              'm² contratados.'
            }
          >
            <Table.Column
              dataIndex="mes"
              render={(text, row: AreaDashboard) => moment(`${text}/${row.ano}`, 'MM/YYYY').format('MMM')}
            />
            <Table.Column dataIndex="area" render={(text) => doubleMask.format(text)} />
          </Table>
        </Tabs.TabPane>
        <Tabs.TabPane key="2" tab="2020">
          <Table
            size="small"
            showHeader={false}
            rowKey="id"
            dataSource={filterobject(
              areas.filter((c) => c.ano === 2020),
              ''
            )}
            loading={loadingareas}
            pagination={false}
            scroll={{ y: 150 }}
            footer={() =>
              'Totalizando ' +
              doubleMask.format(areas.filter((c) => c.ano === 2020).reduce((a, b) => a + b.area, 0)) +
              'm² contratados.'
            }
          >
            <Table.Column
              dataIndex="mes"
              render={(text, row: AreaDashboard) => moment(`${text}/${row.ano}`, 'MM/YYYY').format('MMM')}
            />
            <Table.Column align="right" dataIndex="area" render={(text) => doubleMask.format(text)} />
          </Table>
        </Tabs.TabPane>
        <Tabs.TabPane key="3" tab="2021">
          <Table
            size="small"
            showHeader={false}
            rowKey="id"
            dataSource={filterobject(
              areas.filter((c) => c.ano === 2021),
              ''
            )}
            loading={loadingareas}
            pagination={false}
            scroll={{ y: 150 }}
            footer={() =>
              'Totalizando ' +
              doubleMask.format(areas.filter((c) => c.ano === 2021).reduce((a, b) => a + b.area, 0)) +
              'm² contratados.'
            }
          >
            <Table.Column
              dataIndex="mes"
              render={(text, row: AreaDashboard) => moment(`${text}/${row.ano}`, 'MM/YYYY').format('MMM')}
            />
            <Table.Column align="right" dataIndex="area" render={(text) => doubleMask.format(text)} />
          </Table>
        </Tabs.TabPane>
        <Tabs.TabPane key="4" tab="2022">
          <Table
            size="small"
            showHeader={false}
            rowKey="id"
            dataSource={filterobject(
              areas.filter((c) => c.ano === 2022),
              ''
            )}
            loading={loadingareas}
            pagination={false}
            scroll={{ y: 150 }}
            footer={() =>
              'Totalizando ' +
              doubleMask.format(areas.filter((c) => c.ano === 2022).reduce((a, b) => a + b.area, 0)) +
              'm² contratados.'
            }
          >
            <Table.Column
              dataIndex="mes"
              render={(text, row: AreaDashboard) => moment(`${text}/${row.ano}`, 'MM/YYYY').format('MMM')}
            />
            <Table.Column align="right" dataIndex="area" render={(text) => doubleMask.format(text)} />
          </Table>
        </Tabs.TabPane>
        <Tabs.TabPane key="5" tab="Total">
          <Table
            size="small"
            showHeader={false}
            rowKey="id"
            dataSource={filterobject(
              areas.filter((c) => c.ano >= 2019),
              ''
            )}
            loading={loadingareas}
            pagination={false}
            scroll={{ y: 150 }}
            footer={() =>
              'Totalizando ' +
              doubleMask.format(areas.filter((c) => c.ano >= 2019).reduce((a, b) => a + b.area, 0)) +
              'm² contratados.'
            }
          >
            <Table.Column
              dataIndex="ano"
              render={(_, row: AreaDashboard) => moment(`${row.mes}/${row.ano}`, 'MM/YYYY').format('MM/YYYY')}
            />
            <Table.Column align="right" dataIndex="area" render={(text) => doubleMask.format(text)} />
          </Table>
        </Tabs.TabPane>
      </Tabs>
    </Card>
  );
}

export default AreaConstruida;
