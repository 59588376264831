import { ExtendedAction } from '@config/reduxStore';
import { ModalidadeCredito } from '@models/ModalidadeCredito';
import { notification } from 'antd';

interface ModalidadesReducer {
  modalidades: ModalidadeCredito[];
  formview: boolean;
  modalidade: number;
  loadingmodalidades: boolean;
  savingmodalidade: boolean;
  feedback: string;
}
var initialState: ModalidadesReducer = {
  modalidades: [],
  formview: false,
  modalidade: null,
  loadingmodalidades: false,
  savingmodalidade: false,
  feedback: '',
};

const modalidadesReducer = function (state = initialState, action: ExtendedAction) {
  switch (action.type) {
    case 'RESET_FEEDBACK':
      state = {
        ...state,
        feedback: '',
      };
      break;
    case 'FORM_MODALIDADE':
      state = {
        ...state,
        feedback: '',
        formview: action.payload.open,
        modalidade: action.payload.id ? action.payload.id : null,
      };
      break;
    case 'GET_MODALIDADES_PENDING':
      state = {
        ...state,
        loadingmodalidades: true,
        modalidades: initialState.modalidades,
      };
      break;
    case 'GET_MODALIDADES_REJECTED':
      state = {
        ...state,
        loadingmodalidades: false,
      };
      break;
    case 'GET_MODALIDADES_FULFILLED':
      state = {
        ...state,
        loadingmodalidades: false,
        modalidades: action.payload.data,
      };
      break;
    case 'ADD_MODALIDADE_PENDING':
      state = {
        ...state,
        savingmodalidade: true,
        feedback: '',
      };
      break;
    case 'ADD_MODALIDADE_REJECTED':
      state = {
        ...state,
        savingmodalidade: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: 'Erro ao cadastrar modalidade!' });
      break;
    case 'ADD_MODALIDADE_FULFILLED':
      state = {
        ...state,
        savingmodalidade: false,
        feedback: 'Modalidade criada com sucesso!',
        modalidades: [...state.modalidades, action.payload.data],
      };
      notification.success({ message: 'Modalidade cadastrada com sucesso!' });
      break;
    case 'UPDATE_MODALIDADE_PENDING':
      state = {
        ...state,
        savingmodalidade: true,
        feedback: '',
      };
      break;
    case 'UPDATE_MODALIDADE_REJECTED':
      state = {
        ...state,
        savingmodalidade: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: 'Erro ao atualizar modalidade' });
      break;
    case 'UPDATE_MODALIDADE_FULFILLED':
      state = {
        ...state,
        savingmodalidade: false,
        feedback: 'Modaidade atualizada com sucesso!',
        modalidades: state.modalidades.map((c) => {
          if (c.id === action.payload.data.id) return action.payload.data;
          else return c;
        }),
      };
      notification.success({ message: 'Modalidade atualizada com sucesso!' });
      break;
    case 'DELETE_MODALIDADE_PENDING':
      state = {
        ...state,
        loadingmodalidades: true,
        feedback: '',
      };
      break;
    case 'DELETE_MODALIDADE_REJECTED':
      state = {
        ...state,
        loadingmodalidades: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: state.feedback });
      break;
    case 'DELETE_MODALIDADE_FULFILLED':
      state = {
        ...state,
        loadingmodalidades: false,
        feedback: 'Modalidade excluída com sucesso!',
        modalidades: state.modalidades.filter((c) => c.id !== action.payload.data),
      };
      notification.success({ message: state.feedback });
      break;
    default:
      return state;
  }
  return state;
};

export default modalidadesReducer;
