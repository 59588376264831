import { GetColaboradoresList } from '@services/api/api.fornecedor';
import { List, SelectProps } from 'antd';
import { Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';
import LoadingText from './LoadingText';

export interface SelectSolicitanteProps extends SelectProps {
  onAdd: (value: number) => void;
  disabled?: boolean;
}

function ColaboradorSelect({ onAdd, disabled = false, onChange, ...restProps }: SelectSolicitanteProps) {
  const { isLoading, data, isFetching } = GetColaboradoresList();
  const [tempValue, setTempValue] = useState<number>(null);
  if (isLoading) return <LoadingText text="Carregando" />;
  return (
    <Select
      {...restProps}
      loading={isLoading || isFetching}
      optionFilterProp="label"
      showSearch
      disabled={isLoading || disabled}
      onChange={(value, option) => {
        if (onChange) onChange(value, option);
        setTempValue(value);
      }}
      suffixIcon={<PlusOutlined onClick={() => onAdd(tempValue)} />}
    >
      {data.map((c) => (
        <Select.Option key={c.id} value={c.id} label={c.nome + c.cpF_CNPJ + c.funcao}>
          <List.Item>
            <List.Item.Meta description={c.funcao} title={c.nome}></List.Item.Meta>
            <div>{c.cpF_CNPJ}</div>
          </List.Item>
        </Select.Option>
      ))}
    </Select>
  );
}

export default ColaboradorSelect;
