import { useEffect, useState } from 'react';
import { Table, Button, Input, Popconfirm } from 'antd';
import { GetChamados, DeleteChamado } from '@actions/chamadosActions';
import DashboardChamados from './DashboardChamados';
import moment from 'moment';
import { EyeOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { filterobject, getColumnSearchProps, orderDate, sortAlpha } from '@utils/filterandsort';
import { SetPageHeader } from '@actions/userActions';
import { Permission } from '@hooks//usePermissions';
import { setCookie, getCookie } from '@services/localStorage';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { ColumnsType } from 'antd/lib/table';
import { Chamado } from '@models/Chamado';

function ChamadoView() {
  const dispatch = useAppDispatch();
  const chamados = useAppSelector((state) => state.chamados);
  const [filter, setFilter] = useState('');
  const empresas = [...new Set(chamados.chamados.map((c) => c.empresa))];
  const loteamentos = [...new Set(chamados.chamados.map((c) => c.loteamento))];
  const cookievalues = JSON.parse(getCookie('chamadosfilters') || null) || null;
  const [filters, setfilters] = useState(cookievalues);
  useEffect(() => {
    dispatch(GetChamados());
    dispatch(
      SetPageHeader({
        title: 'SAC',
        backbutton: true,
        extras: [],
        subtitle: 'Chamados',
        menu: 'chamados',
      })
    );
  }, []);
  const columns: ColumnsType<Chamado> = [
    {
      width: 80,
      fixed: 'left',
      render: (_, row) => (
        <div>
          <Link to={`/Chamados/${row.id}`}>
            <Button size="small" icon={<EyeOutlined />} />
          </Link>
          <Permission permission="chamado.delete">
            <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(DeleteChamado(row.id))}>
              <Button size="small" icon={<DeleteOutlined />} />
            </Popconfirm>
          </Permission>
        </div>
      ),
    },
    {
      fixed: 'left',
      title: 'Nº Chamado',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      ...getColumnSearchProps('id', 'string', 'Nº Chamado', filters),
    },
    {
      title: 'Ecerramento Solicitado',
      dataIndex: 'EcerramentoSolicitado',
      render: (text) => (text ? 'Sim' : 'Não'),
      filteredValue: filters ? filters.filters.EcerramentoSolicitado : null,
      filters: [
        { value: true, text: 'Sim' },
        { value: false, text: 'Não' },
      ],
      onFilter: (value, row) => row.concluido === value,
    },
    {
      title: 'Concluído',
      dataIndex: 'concluido',
      render: (text) => (text ? 'Sim' : 'Não'),
      filteredValue: filters ? filters.filters.concluido : null,
      filters: [
        { value: true, text: 'Concluído' },
        { value: false, text: 'Em aberto' },
      ],
      onFilter: (value, row) => row.concluido === value,
    },
    {
      title: 'Data Abertura',
      dataIndex: 'dataChamado',
      sorter: (a, b) => orderDate(a.dataChamado, b.dataChamado),
      render: (text) => (moment(text).isValid() ? moment(text).format('DD/MM/YYYY') : ''),
      ...getColumnSearchProps('dataChamado', 'daterange', 'Data Abertura', filters),
    },
    {
      title: 'Data Visita',
      dataIndex: 'dataVisita',
      sorter: (a, b) => orderDate(a.dataVisita, b.dataVisita),
      render: (text) => (moment(text).isValid() ? moment(text).format('DD/MM/YYYY') : ''),
      ...getColumnSearchProps('dataVisita', 'daterange', 'Data Visita', filters),
    },
    {
      title: 'Data Conclusão',
      dataIndex: 'dataConc',
      sorter: (a, b) => orderDate(a.dataConc, b.dataConc),
      render: (text) => (moment(text).isValid() ? moment(text).format('DD/MM/YYYY') : ''),
      ...getColumnSearchProps('dataConc', 'daterange', 'Data Conclusão', filters),
    },
    {
      title: 'Empresa',
      dataIndex: 'empresa',
      filteredValue: filters ? filters.filters.empresa : null,
      sorter: (a, b) => sortAlpha(a.empresa, b.empresa),
      filters: empresas.map((c) => {
        return { value: c, text: c };
      }),
      onFilter: (value, row) => row.empresa === value,
    },
    {
      title: 'Loteamento',
      dataIndex: 'loteamento',
      filteredValue: filters ? filters.filters.loteamento : null,
      sorter: (a, b) => sortAlpha(a.loteamento, b.loteamento),
      filters: loteamentos.map((c) => {
        return { value: c, text: c };
      }),
      onFilter: (value, row) => row.loteamento === value,
    },
    {
      title: 'Endereço',
      dataIndex: 'endereco',
      sorter: (a, b) => sortAlpha(a.endereco, b.endereco),
      ...getColumnSearchProps('endereco', 'string', 'Endereço', filters),
    },
    {
      title: 'Proprietário',
      dataIndex: 'proprietario',
      sorter: (a, b) => sortAlpha(a.proprietario, b.proprietario),
      ...getColumnSearchProps('proprietario', 'string', 'Proprietário', filters),
    },
  ];
  return (
    <div>
      <DashboardChamados />
      <div style={{ textAlign: 'right' }}>
        <Button
          size="small"
          style={{ float: 'left' }}
          onClick={() => {
            setfilters(null);
            localStorage.removeItem('chamadosfilters');
          }}
        >
          Limpar Filtros
        </Button>
        <Input
          style={{ width: 200 }}
          onChange={(e) => setFilter(e.target.value)}
          placeholder="Buscar"
          allowClear
          suffix={<SearchOutlined />}
        />
      </div>
      <Table
        size="small"
        rowKey="id"
        dataSource={filterobject(chamados.chamados, filter)}
        loading={chamados.loadingchamados}
        scroll={{ y: 'calc(100vh - 300px)', x: 1000 }}
        onChange={(pagination, filters, sorter) => {
          setfilters({ pagination, filters, sorter });
          setCookie('chamadosfilters', JSON.stringify({ pagination, filters, sorter }));
        }}
        columns={columns}
        pagination={{
          position: ['bottomRight'],
          size: 'small',
          current: filters ? filters.pagination.current : 1,
          pageSize: filters ? filters.pagination.pageSize : 10,
          showSizeChanger: true,
          pageSizeOptions: [10, 25, 50, 100],
          showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
        }}
        expandedRowRender={(row) => (
          <Table size="small" dataSource={row.reclamacoes} scroll={{ x: '1024px' }}>
            <Table.Column
              title="Descrição"
              dataIndex="descricao"
              {...getColumnSearchProps('descricao', 'string', 'Descrição')}
            />
            <Table.Column title="Situação" dataIndex="status" />
            <Table.Column
              title="Data Início"
              dataIndex="dataInicio"
              render={(text) => (moment(text).isValid() ? moment(text).format('DD/MM/YYYY') : '')}
              {...getColumnSearchProps('descricao', 'daterange', 'Descrição')}
            />
            <Table.Column
              title="Data Término"
              dataIndex="dataTermino"
              render={(text) => (moment(text).isValid() ? moment(text).format('DD/MM/YYYY') : '')}
            />
            <Table.Column title="Solução" dataIndex="solucao" />
          </Table>
        )}
      />
    </div>
  );
}
export default ChamadoView;
