import { SetPageHeader } from '@actions/userActions';
import { useAppDispatch } from '@config/reduxStore';
import { TransferenciaForm } from '@models/Transferencia';
import { GetTransferenciaEdit, SalvarTransferencia } from '@services/api/api.transferencia';
import { moneyMask } from '@utils/masks';
import { Button, Col, Form, notification, Row, Spin } from 'antd';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import TransferenciaFormGeral from './TransferenciaForm.Geral';
import TransferenciasFormItens from './TransferenciaForm.Itens';
import TransferenciaFormResumo from './TransferenciaForm.Resumo';
import { useTransferenciaForm } from './useTransferenciaForm';

function TransferenciaFormMain() {
  const dispatch = useAppDispatch();
  const { form } = useTransferenciaForm();
  const { id } = useParams();
  const { data: transferencia } = GetTransferenciaEdit(id);
  const salvarTransferencia = SalvarTransferencia();
  useEffect(() => {
    if (transferencia) form.setFieldsValue(transferencia);
  }, []);
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Nova Transferência',
        subtitle: '',
        extras: [
          <Button
            key={0}
            size="small"
            type="primary"
            onClick={() => form.submit()}
            loading={salvarTransferencia.isLoading}
          >
            Salvar
          </Button>,
        ],
        menu: 'transferencias',
        backbutton: true,
      })
    );
  }, [salvarTransferencia.isLoading]);
  const handleSubmit = (values: TransferenciaForm) => {
    if (values.ordemItens === null || values.ordemItens.length === 0) {
      notification.error({ message: 'Adicione um item à transferência' });
      return;
    }
    salvarTransferencia.mutate({
      ...values,
      ordemItens: values.ordemItens.map((c) => ({ ...c, valorTotal: moneyMask.normalize(c.valorTotal as string) })),
    });
  };
  return (
    <Spin spinning={salvarTransferencia.isLoading}>
      <Form
        form={form}
        initialValues={{ ordensItens: [] }}
        layout="vertical"
        scrollToFirstError
        onFinish={handleSubmit}
      >
        <Row gutter={[8, 8]} align="top">
          <Col span={12}>
            <TransferenciaFormGeral />
          </Col>
          <Col span={12}>
            <TransferenciaFormResumo />
          </Col>
          <Col span={24}>
            <TransferenciasFormItens />
          </Col>
        </Row>
      </Form>
    </Spin>
  );
}
export default TransferenciaFormMain;
