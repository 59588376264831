import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Image,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Spin,
  Upload,
} from 'antd';
import { useEffect, useState } from 'react';
import { SetPageHeader } from '@actions/userActions';
import moment from 'moment';
import AndamentoItem from './AndamentoItem';
import AvaliacaoItem from './AvaliacaoItem';
import { PlusOutlined, MinusOutlined, UploadOutlined } from '@ant-design/icons';
import {
  getAuditoria,
  getMestres,
  getObras,
  getResponsaveis,
  salvarAuditoria,
  saveFiles,
} from '@actions/auditoriasAction';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

function AuditoriaForm() {
  const dispatch = useAppDispatch();
  const [files, upFile] = useState([]);
  const {
    loadingResponsaveis,
    responsaveis,
    loadingMestres,
    mestres,
    obras,
    loadingObras,
    savingAuditoria,
    auditoria,
    loadingAuditoria,
    savingFiles,
  } = useAppSelector((state) => state.auditorias);
  const [form] = Form.useForm();
  const { id } = useParams();
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Auditoria',
        backbutton: true,
        extras: [
          <Button type="primary" onClick={() => form.submit()}>
            Salvar
          </Button>,
        ],
        subtitle: 'Formulário de auditoria',
        menu: 'auditorias',
      })
    );
  }, [dispatch, form]);
  useEffect(() => {
    dispatch(getResponsaveis());
    dispatch(getMestres());
    dispatch(getObras());
    if (id) dispatch(getAuditoria(id));
  }, []);
  useEffect(() => {
    if (auditoria) {
      form.setFieldsValue({ ...auditoria, data: moment(auditoria.data) });
    }
  }, [auditoria]);
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  return (
    <div style={{ height: 'calc(100vh - 140px)', overflowY: 'scroll' }}>
      <Spin spinning={loadingAuditoria || savingAuditoria}>
        <Form
          layout="vertical"
          form={form}
          onFinish={(values) => {
            dispatch(
              salvarAuditoria({
                ...values,
                values: JSON.stringify(values),
              })
            );
          }}
          initialValues={{ data: moment(new Date()) }}
        >
          <Form.Item hidden name="id">
            <Input />
          </Form.Item>
          <Divider>Informações Gerais</Divider>
          <Form.Item name="data" label="Data" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <DatePicker format="DD/MM/YYYY" />
          </Form.Item>
          <Form.Item name="auditorID" label="Auditor" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Select loading={loadingResponsaveis} optionFilterProp="children" showSearch>
              {responsaveis.map((c) => (
                <Select.Option key={c.id}>{c.nome}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="acompanhanteID" label="Acompanhante">
            <Select />
          </Form.Item>
          <Form.Item name="obraID" label="Obra" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Select loading={loadingObras} optionFilterProp="children" showSearch>
              {obras.map((c) => (
                <Select.Option key={c.id} value={c.id}>
                  {c.descricao}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="mestreID" label="Mestre de Obras" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Select loading={loadingMestres} optionFilterProp="children" showSearch>
              {mestres.map((c) => (
                <Select.Option key={c.id} value={c.id}>
                  {c.nome}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="responsavelID"
            label="Responsável"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
          >
            <Select loading={loadingResponsaveis} optionFilterProp="children" showSearch>
              {responsaveis.map((c) => (
                <Select.Option key={c.id}>{c.nome}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Divider>Situação da Obra</Divider>
          <Form.Item name="loteApoio" label="Possui lote de apoio?" initialValue={true}>
            <Radio.Group>
              <Radio value={true}>Sim</Radio>
              <Radio value={false}>Não</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="pessoas" label="Pessoas trabalhando">
            <InputNumber min={0} precision={0} />
          </Form.Item>
          <Form.Item name="pessoalOcioso" label="Pessoas Ociosas">
            <Radio.Group>
              <Radio value={true}>Sim</Radio>
              <Radio value={false}>Não</Radio>
            </Radio.Group>
          </Form.Item>
          <Divider>Andamento</Divider>
          <AndamentoItem name="SERVIÇOS PRELIMINARES" title="SERVIÇOS PRELIMINARES" />
          <AndamentoItem name="FUNDAÇÃO" title="FUNDAÇÃO" />
          <AndamentoItem name="ALVENARIA" title="ALVENARIA" />
          <AndamentoItem name="LAJE" title="LAJE" />
          <AndamentoItem name="GESSO" title="GESSO" />
          <AndamentoItem name="INSTALAÇÕES ELÉTRICAS" title="INSTALAÇÕES ELÉTRICAS" />
          <AndamentoItem name="INSTALAÇÕES HIDRÁULICAS" title="INSTALAÇÕES HIDRÁULICAS" />
          <AndamentoItem name="PEITORIS E SOLEIRAS" title="PEITORIS E SOLEIRAS" />
          <AndamentoItem name="REVESTIMENTOS" title="REVESTIMENTOS" />
          <AndamentoItem name="ESQUADRIAS DE MADEIRA" title="ESQUADRIAS DE MADEIRA" />
          <AndamentoItem name="ESQUADRIAS DE ALUMÍNIO E VIDRO" title="ESQUADRIAS DE ALUMÍNIO E VIDRO" />
          <AndamentoItem name="BANCADAS" title="BANCADAS" />
          <AndamentoItem name="PINTURA INTERNA" title="PINTURA INTERNA" />
          <AndamentoItem name="PINTURA EXTERNA" title="PINTURA EXTERNA" />
          <AndamentoItem name="LOUÇAS E METAIS" title="LOUÇAS E METAIS" />
          <AndamentoItem name="LIMPEZA" title="LIMPEZA" />
          <Divider>Avaliação</Divider>
          <AvaliacaoItem name="LIMPEZA" title="LIMPEZA" />
          <AvaliacaoItem name="ORGANIZAÇÃO" title="ORGANIZAÇÃO" />
          <AvaliacaoItem name="USO DE EPI" title="USO DE EPI" />
          <AvaliacaoItem name="FARDAMENTO" title="FARDAMENTO" />
          <AvaliacaoItem name="PLACA DA OBRA" title="PLACA DA OBRA" />
          <AvaliacaoItem name="PROJETOS" title="PROJETOS" />
          <AvaliacaoItem name="ESTRUTURA DO CANTEIRO" title="ESTRUTURA DO CANTEIRO" />
          <AvaliacaoItem name="ESTOQUE DE MATERIAIS" title="ESTOQUE DE MATERIAIS" />
          <AvaliacaoItem name="TAPUME" title="TAPUME" />
          <Divider>Serviço em Execução</Divider>
          <Form.List name="servicos">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field) => (
                    <Row gutter={[8, 8]} align="bottom">
                      <Col flex="1 1 40%">
                        <Form.Item name={[field.name, 'servico']}>
                          <Input placeholder="Serviço" />
                        </Form.Item>
                      </Col>
                      <Col flex="1 1 50%">
                        <Form.Item name={[field.name, 'notas']}>
                          <Input placeholder="Notas" />
                        </Form.Item>
                      </Col>
                      <Col flex="1 1 10%">
                        <Form.Item>
                          <Button icon={<MinusOutlined />} onClick={() => remove(field.name)} ghost />
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                  <Button icon={<PlusOutlined />} onClick={() => add()} type="primary" ghost>
                    Serviço
                  </Button>
                </div>
              );
            }}
          </Form.List>
          <Divider>Observações Gerais</Divider>
          <Form.Item name="notas">
            <Input.TextArea rows={5} />
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) =>
              (getFieldValue('id') !== undefined || id) && (
                <div>
                  {auditoria?.fotos && (
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px 8px' }}>
                      <Divider>Fotos</Divider>
                      <Image.PreviewGroup>
                        {auditoria.fotos.map((f) => (
                          <Image src={f.url} alt={f.fileName} width={100} key={f.fileName} />
                        ))}
                      </Image.PreviewGroup>
                    </div>
                  )}
                  <Form.Item label="Imagens" name="arquivos" valuePropName="fileList" getValueFromEvent={normFile}>
                    <Upload
                      name="arquivo"
                      disabled={savingFiles}
                      style={{ display: 'inline' }}
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      multiple={true}
                      beforeUpload={(_, fileList) => {
                        upFile([...files, ...fileList]);
                        Promise.resolve(false);
                      }}
                      accept=".jpg,.jpeg,.png"
                      onRemove={(file) => {
                        Promise.resolve(false);
                        upFile(files.filter((f) => f !== file));
                      }}
                      fileList={files}
                    >
                      <Button icon={<UploadOutlined />} size="small">
                        Arquivos
                      </Button>
                    </Upload>
                    <Button
                      style={{ display: 'inline' }}
                      type="primary"
                      size="small"
                      loading={savingFiles}
                      onClick={() => {
                        const formdata = new FormData();
                        files.map((f) => formdata.append('files', f, f.name));
                        formdata.append('auditoriaid', form.getFieldValue('id'));
                        dispatch(saveFiles(formdata));
                        upFile([]);
                      }}
                    >
                      Enviar
                    </Button>
                  </Form.Item>
                </div>
              )
            }
          </Form.Item>
          {}
        </Form>
      </Spin>
    </div>
  );
}

export default AuditoriaForm;
