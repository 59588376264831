import { apiEndpoints } from '@constants/apiEndpoints';
import { Fornecedor } from '@models/Fornecedor';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { fetcher } from './api';

export const GetFornecedoresList = () => {
  return useQuery<Fornecedor[], AxiosError<any>>(
    ['fornecedoresList'],
    () =>
      fetcher<Fornecedor[]>(apiEndpoints.FORNECEDOR.GET_FORNECEDORES, {
        params: { ativo: true },
      }),
    {
      onError: (err) => console.log(err.response?.data?.Message),
    }
  );
};
export const GetColaboradoresList = () => {
  return useQuery<Fornecedor[], AxiosError<any>>(
    ['colaboradoresList'],
    () => fetcher<Fornecedor[]>(apiEndpoints.COLABORADORES.GET_COLABORADORES, { params: { ativo: true } }),
    {
      onError: (err) => console.log(err.response?.data?.Message),
    }
  );
};
