import EmpresaFaturamentoSelect from '@components/EmpresaFaturamentoSelect';
import FornecedorSelect from '@components/FornecedorSelect';
import SolicitanteSelect from '@components/SolicitanteSelect';
import { Form, Input } from 'antd';

function General() {
  return (
    <>
      <Form.Item rules={[{ required: true, message: 'Campo obrigatório' }]} label="Descrição" name="descricao">
        <Input placeholder="Descrição" />
      </Form.Item>
      <Form.Item name="compradorID" label="Solicitante" rules={[{ required: true, message: 'Campo obrigatório' }]}>
        <SolicitanteSelect placeholder="Selecione um solicitante" />
      </Form.Item>
      <Form.Item name="fornecedorID" label="Fornecedor" rules={[{ required: true, message: 'Campo obrigatório' }]}>
        <FornecedorSelect placeholder="Selecione um fornecedor" />
      </Form.Item>
      <Form.Item
        name="empresaFaturamentoID"
        label="Faturar Para"
        rules={[{ required: true, message: 'Campo obrigatório' }]}
      >
        <EmpresaFaturamentoSelect placeholder="Selecione uma empresa" />
      </Form.Item>
    </>
  );
}

export default General;
