import { useEffect, useState } from 'react';
import {
    Table,
    Input,
    Button,
    Tooltip,
    Modal,
    Form,
    Popconfirm,
    Tag,
    Steps,
    Select,
    Col,
    Row,
    DatePicker,
    message,
    Radio,
} from 'antd';
import {
    EyeOutlined,
    StopOutlined,
    MailOutlined,
    EditOutlined,
    CopyOutlined,
    ShoppingCartOutlined,
    SwapOutlined,
    FileExcelOutlined,
    BankOutlined,
    PrinterOutlined,
    CheckCircleTwoTone,
    CloseCircleTwoTone,
} from '@ant-design/icons';
import { getColumnSearchPropsServer, naturalSorter } from '@utils/filterandsort';
import { GetAprovadores, SetPageHeader } from '@actions/userActions';
import { HasPermission, HasRole, Permission } from '@hooks//usePermissions';
import { Link } from 'react-router-dom';
import { GetEmps } from '@actions/empsActions';
import { moneyNegMask } from '@utils/masks';
import moment from 'moment';
import { GetMateriaisList } from '@actions/materiaisActions';
import { Axios } from '@config/axios';
import ModalAjusteNF from '../Shared/ModalAjusteNF';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { OrdemList } from '@models/Ordem';
import { ColumnsType } from 'antd/lib/table';
import { OrdemStatus, ordemType } from '@constants/ordemStatus';
import {
    CopyOrdem,
    GetOrdensPagination,
    MaterialEntregue,
    SetEnvioFornecedorForm,
    ViewCancelForm,
    ViewItensModal,
} from '@actions/novaOrdemActions';
import CancelOrdemModal from '../Shared/CancelOrdemModalRedux';
import OrdemItensModal from '../Shared/OrdemItensModal';
import EnviarParaFornecedorModal from '../Shared/EnviarParaFornecedorModal';
import OrdemStatusTag from '../Shared/OrdemStatusTag';

function OrdensView() {
    const dispatch = useAppDispatch();
    const { user } = useAppSelector((state) => state.user);
    const { ordens, loadingOrdens, total } = useAppSelector((state) => state.novaOrdem);
    const { aprovadores } = useAppSelector((state) => state.user);
    const { loadingmateriais, materiaislist } = useAppSelector((state) => state.materiais);
    const [downloadModal, setDownloadModal] = useState(false);
    const [ordensPagamentoModal, setOrdensPagamentoModal] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [downloadForm] = Form.useForm();
    const [ordensPagamentoForm] = Form.useForm();
    const [filters, setFilters] = useState<any>({
        pagination: { pageSize: 10, current: 1 },
        filters: {
            comprador: [],
            situacao: [],
            cod: [],
            criador: [],
            dataCriacao: [],
            enviadoPagamento: [],
            descricao: [],
            emailEnviado: [],
            fornecedor: [],
            orcamento: [],
            prazoPagamento: [],
            total: [],
        },
        sorter: { field: 'cod', order: 'descend' },
    });

    const [formbusca] = Form.useForm();
    useEffect(() => {
        dispatch(GetOrdensPagination(filters));
        dispatch(GetEmps());
        dispatch(GetAprovadores(ordemType.ordem));
        dispatch(GetMateriaisList());
        dispatch(
            SetPageHeader({
                title: 'Engenharia',
                backbutton: true,
                extras: [
                    <Button key="3" onClick={() => setOrdensPagamentoModal(true)} icon={<FileExcelOutlined />}>
                        Ordens Pagamento
                    </Button>,
                    <Button key="2" onClick={() => setDownloadModal(true)} icon={<FileExcelOutlined />}>
                        Exportar
                    </Button>,
                    <Permission key="1" permission="ordem.add">
                        <Link to={`/NovaOrdem`}>
                            <Button type="primary">Nova Ordem</Button>
                        </Link>
                    </Permission>,
                ],
                subtitle: 'Ordens de Compra/Serviço',
                menu: 'ordens',
            })
        );
    }, []);

    const [ajusteNF, setAjusteNF] = useState(false);
    const [ajusteNFOrdem, setAjusteNFOrdem] = useState(null);
    const columns: ColumnsType<OrdemList> = [
        {
            width: 50,
            fixed: 'left',
            align: 'center',
            render: (_: any, row: OrdemList) => (
                <div>
                    <Tooltip title="Visualizar">
                        <Link to={`/PrintOrdem/${row.id}`}>
                            <Button icon={<EyeOutlined />} size="small" />
                        </Link>
                    </Tooltip>
                    <Link to={`/PrintOrdemPDF/${row.id}/${user.conta.id}`} target="_blank">
                        <Button icon={<PrinterOutlined />} size="small" />
                    </Link>
                    <Tooltip title="Copiar">
                        <Popconfirm
                            title="Está certo disso?"
                            onConfirm={() => dispatch(CopyOrdem({ id: row.id, motivo: '', replaced: false }))}
                        >
                            <Button icon={<CopyOutlined />} size="small" />
                        </Popconfirm>
                    </Tooltip>
                    <Tooltip title="Material Recebido">
                        <Button
                            icon={
                                row.materialEntregue ? (
                                    <CheckCircleTwoTone twoToneColor="green" />
                                ) : (
                                    <CloseCircleTwoTone twoToneColor="red" />
                                )
                            }
                            size="small"
                            onClick={() => {
                                dispatch(MaterialEntregue(row.id));
                            }}
                        />
                    </Tooltip>
                    <Tooltip title="Itens">
                        <Button
                            icon={<ShoppingCartOutlined />}
                            size="small"
                            onClick={() => {
                                dispatch(ViewItensModal({ ordemID: row.id, cod: row.cod, visible: true }));
                            }}
                        />
                    </Tooltip>
                    {!row.cancelada && (
                        <>
                            {(row.situacao === OrdemStatus.Rascunho ||
                                row.situacao === OrdemStatus.PendenteAprovacao) && (
                                <Link to={`/NovaOrdem/3/${row.id}`}>
                                    <Button icon={<EditOutlined />} size="small" />
                                </Link>
                            )}
                            {(row.situacao === OrdemStatus.Aprovada ||
                                row.situacao === OrdemStatus.PendentePagamento) &&
                                HasRole(['financeiro', 'master'], user.roles) && (
                                    <Tooltip title="Ajustar valor NF">
                                        <Button
                                            size="small"
                                            icon={<BankOutlined />}
                                            onClick={() => {
                                                setAjusteNF(true);
                                                setAjusteNFOrdem(row);
                                            }}
                                        />
                                    </Tooltip>
                                )}
                            {row.emailEnviado === false && row.situacao !== OrdemStatus.Rascunho && (
                                <Tooltip title="Enviar ordem para fornecedor">
                                    <Button
                                        icon={<MailOutlined />}
                                        size="small"
                                        onClick={() =>
                                            dispatch(
                                                SetEnvioFornecedorForm({
                                                    ordemID: row.id,
                                                    visible: true,
                                                    email: row.email,
                                                })
                                            )
                                        }
                                    />
                                </Tooltip>
                            )}
                            {row.emailEnviado === true && row.situacao !== OrdemStatus.Rascunho && (
                                <Tooltip title="Enviar ordem para fornecedor">
                                    <Popconfirm
                                        title="Ordem já enviada. Enviar novamente?"
                                        onConfirm={() =>
                                            dispatch(
                                                SetEnvioFornecedorForm({
                                                    ordemID: row.id,
                                                    visible: true,
                                                    email: row.email,
                                                })
                                            )
                                        }
                                    >
                                        <Button icon={<MailOutlined />} size="small" />
                                    </Popconfirm>
                                </Tooltip>
                            )}
                            {(!(
                                row.situacao === OrdemStatus.Finalizada ||
                                row.situacao === OrdemStatus.PendentePagamento ||
                                row.situacao === OrdemStatus.Aprovada
                            ) ||
                                (HasPermission('ordem.cancel', user.permissions) &&
                                    row.situacao !== OrdemStatus.Finalizada)) && (
                                <>
                                    <Tooltip title="Cancelar Ordem">
                                        <Button
                                            icon={<StopOutlined />}
                                            size="small"
                                            onClick={() => {
                                                dispatch(
                                                    ViewCancelForm({
                                                        view: true,
                                                        id: row.id,
                                                        replace: false,
                                                        cod: row.cod,
                                                    })
                                                );
                                            }}
                                        />
                                    </Tooltip>
                                    <Popconfirm
                                        title="Ao clicar nesse botão essa ordem será cancelada"
                                        onConfirm={() => {
                                            dispatch(
                                                ViewCancelForm({ view: true, id: row.id, replace: true, cod: row.cod })
                                            );
                                        }}
                                    >
                                        <Tooltip title="Substituir">
                                            <Button icon={<SwapOutlined />} size="small" />
                                        </Tooltip>
                                    </Popconfirm>
                                </>
                            )}
                        </>
                    )}
                </div>
            ),
        },
        {
            title: 'Nº Ordem',
            width: 80,
            dataIndex: 'cod',
            sorter: (a: OrdemList, b: OrdemList) => naturalSorter(a.cod, b.cod),
            ...getColumnSearchPropsServer('cod', 'string', 'Nº Ordem', filters),
        },
        {
            title: 'Situação',
            width: 180,
            dataIndex: 'situacao',
            sorter: true,
            ...getColumnSearchPropsServer('situacao', 'customselect', 'Situação', filters, [
                { text: <OrdemStatusTag status={OrdemStatus.Rascunho} />, value: OrdemStatus.Rascunho },
                {
                    text: <OrdemStatusTag status={OrdemStatus.PendenteAprovacao} />,
                    value: OrdemStatus.PendenteAprovacao,
                },
                {
                    text: <OrdemStatusTag status={OrdemStatus.PendenteDocumentos} />,
                    value: OrdemStatus.PendenteDocumentos,
                },
                {
                    text: <OrdemStatusTag status={OrdemStatus.PendentePagamento} />,
                    value: OrdemStatus.PendentePagamento,
                },
                { text: <OrdemStatusTag status={OrdemStatus.PagamentoParcial} />, value: OrdemStatus.PagamentoParcial },
                { text: <OrdemStatusTag status={OrdemStatus.Aprovada} />, value: OrdemStatus.Aprovada },
                { text: <OrdemStatusTag status={OrdemStatus.Finalizada} />, value: OrdemStatus.Finalizada },
                { text: <OrdemStatusTag status={OrdemStatus.Substituida} />, value: OrdemStatus.Substituida },
                { text: <OrdemStatusTag status={OrdemStatus.Cancelada} />, value: OrdemStatus.Cancelada },
            ]),
            render: (text) => <OrdemStatusTag status={text} />,
        },
        {
            title: 'Descrição',
            dataIndex: 'descricao',
            width: 100,
            ...getColumnSearchPropsServer('descricao', 'string', 'Descrição', filters),
        },
        {
            title: 'Orçamento',
            dataIndex: 'orcamento',
            sorter: (a, b) => naturalSorter(a.orcamento as string, b.orcamento as string),
            ...getColumnSearchPropsServer('orcamento', 'string', 'Orcamento', filters),
        },
        {
            title: 'Fornecedor',
            dataIndex: 'fornecedor',
            ...getColumnSearchPropsServer('fornecedor', 'string', 'Fornecedor', filters),
        },

        {
            title: 'Data Criação',
            dataIndex: 'dataCriacao',
            sorter: true,
            width: 110,
            ...getColumnSearchPropsServer('dataCriacao', 'daterange', 'Data Criação', filters),
            render: (text) => moment(text).format('DD/MM/YYYY'),
        },
        {
            title: 'Valor',
            dataIndex: 'total',
            sorter: true,
            width: 150,
            ...getColumnSearchPropsServer('total', 'string', 'Valor', filters),
            render: (text) => moneyNegMask.format(text),
        },
        {
            title: 'Aprovação',
            dataIndex: 'id',
            width: 160,
            render: (_, row) => (
                <Steps direction="vertical" size="small">
                    <Steps.Step
                        title={
                            row.aprovacao1 ? (
                                row.aprovador1
                            ) : (
                                <div>
                                    {aprovadores.filter((c) => !c.aprovaPedido).length > 0
                                        ? aprovadores.filter((c) => c.nivelOrdens === 1).map((c) => <div>{c.nome}</div>)
                                        : null}
                                </div>
                            )
                        }
                        status={row.aprovacao1 ? 'finish' : 'wait'}
                    />
                    <Steps.Step
                        title={
                            row.aprovacao2 ? (
                                row.aprovador2
                            ) : (
                                <div>
                                    {aprovadores.filter((c) => !c.aprovaPedido).length > 0
                                        ? aprovadores.filter((c) => c.nivelOrdens === 2).map((c) => <div>{c.nome}</div>)
                                        : null}
                                </div>
                            )
                        }
                        status={row.aprovacao2 ? 'finish' : 'wait'}
                    />
                    <Steps.Step
                        title={
                            row.aprovacao3 ? (
                                row.aprovador3
                            ) : (
                                <div>
                                    {aprovadores.filter((c) => !c.aprovaPedido).length > 0
                                        ? aprovadores.filter((c) => c.nivelOrdens === 3).map((c) => <div>{c.nome}</div>)
                                        : null}
                                </div>
                            )
                        }
                        status={row.aprovacao3 ? 'finish' : 'wait'}
                    />
                </Steps>
            ),
        },
        {
            title: 'Solicitante',
            dataIndex: 'comprador',
            sorter: true,
            width: 150,
            ...getColumnSearchPropsServer('comprador', 'string', 'Solicitante', filters),
        },
        {
            title: 'Nota Fiscal',
            width: 100,
            dataIndex: 'notafiscal',
            sorter: true,
            ...getColumnSearchPropsServer('notafiscal', 'string', 'Nota Fiscal', filters),
            render: (text) => text.map((c: any) => c.numero).join(', '),
        },
        {
            title: 'Criador',
            dataIndex: 'criador',
            sorter: true,
            width: 150,
            ...getColumnSearchPropsServer('criador', 'string', 'Criador', filters),
        },
        {
            title: 'Material Entregue',
            dataIndex: 'materialEntregue',
            sorter: true,
            width: 110,
            ...getColumnSearchPropsServer('materialEntregue', 'customselect', 'Material Entregue', filters, [
                { text: 'Sim', value: true },
                { text: 'Não', value: false },
            ]),
            render: (text) => (text ? <Tag color="green">Sim</Tag> : <Tag color="red">Não</Tag>),
        },
        {
            title: 'Enviado Fornecedor',
            width: 120,
            dataIndex: 'emailEnviado',
            sorter: true,
            filters: [
                { text: 'Sim', value: true },
                { text: 'Não', value: false },
            ],
            render: (text) => <Tag color={text ? 'green' : 'red'}>{text ? 'Sim' : 'Não'}</Tag>,
        },
        {
            title: 'Prazo Pagamento',
            dataIndex: 'prazoPagamento',
            sorter: true,
            ...getColumnSearchPropsServer('prazoPagamento', 'daterange', 'Prazo Pagamento', filters),
            render: (text) => moment(text).format('DD/MM/YYYY'),
        },
    ];

    return (
        <div>
            <ModalAjusteNF visible={ajusteNF} setVisible={setAjusteNF} ordem={ajusteNFOrdem} />
            <EnviarParaFornecedorModal />
            <Modal
                open={downloadModal}
                title="Download de ordens"
                onCancel={() => setDownloadModal(false)}
                onOk={() => downloadForm.submit()}
                okButtonProps={{ loading: downloading }}
                cancelText="Fechar"
                okText="Baixar"
            >
                <Form
                    form={downloadForm}
                    wrapperCol={{ span: 18 }}
                    labelCol={{ span: 6 }}
                    initialValues={{ Criador: '', Comprador: '', Solicitante: '', fornecedor: '', orcamento: '' }}
                    onFinish={(values) => {
                        setDownloading(true);
                        Axios.post('/api/v2/Ordens/ExportarOrdens', values, { responseType: 'blob' })
                            .then((res) => {
                                setDownloading(false);
                                const url = window.URL.createObjectURL(new Blob([res.data]));
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute(
                                    'download',
                                    `Ordens_${moment(new Date()).format('DD_MM_YYYY HH_mm')}.xlsx`
                                );
                                document.body.appendChild(link);
                                link.click();
                            })
                            .catch(() => {
                                message.error('Erro ao gerar arquivo!');
                                setDownloading(false);
                            });
                    }}
                >
                    <Form.Item name="situacao" label="Situação" help="Deixe em branco para tudo">
                        <Select mode="multiple">
                            <Select.Option value="Rascunho" key="1">
                                Rascunho
                            </Select.Option>
                            <Select.Option value="Pendente de aprovação" key="2">
                                Pendente de aprovação
                            </Select.Option>
                            <Select.Option value="Pendente de documentos" key="3">
                                Pendente de documentos
                            </Select.Option>
                            <Select.Option value="Pendente de pagamento" key="4">
                                Pendente de pagamento
                            </Select.Option>
                            <Select.Option value="Aguardando finalização" key="5">
                                Aguardando finalização
                            </Select.Option>
                            <Select.Option value="Aprovada" key="6">
                                Aprovada
                            </Select.Option>
                            <Select.Option value="Finalizada" key="7">
                                Finalizada
                            </Select.Option>
                            <Select.Option value="Substituída" key="8">
                                Substituída
                            </Select.Option>
                            <Select.Option value="Cancelada" key="9">
                                Cancelada
                            </Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="emailEnviado" label="Enviado Fornecedor" initialValue={null}>
                        <Radio.Group>
                            <Radio value={null}>Ambos</Radio>
                            <Radio value={true}>Sim</Radio>
                            <Radio value={false}>Não</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item name="Solicitante" label="Comprador">
                        <Input />
                    </Form.Item>
                    <Form.Item name="Criador" label="Criador">
                        <Input />
                    </Form.Item>
                    <Form.Item name="enviadoPagamento" label="Enviado p/ Pagto." initialValue={null}>
                        <Radio.Group>
                            <Radio value={null}>Ambos</Radio>
                            <Radio value={true}>Sim</Radio>
                            <Radio value={false}>Não</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item name="fornecedor" label="Fornecedor">
                        <Input />
                    </Form.Item>
                    <Form.Item name="orcamento" label="Orçamento">
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                open={ordensPagamentoModal}
                title="Download de Ordens"
                onCancel={() => setOrdensPagamentoModal(false)}
                onOk={() => ordensPagamentoForm.submit()}
                okButtonProps={{ loading: downloading }}
                cancelText="Fechar"
                okText="Baixar"
            >
                <Form
                    form={ordensPagamentoForm}
                    wrapperCol={{ span: 18 }}
                    labelCol={{ span: 6 }}
                    onFinish={(values) => {
                        setDownloading(true);
                        Axios.post(
                            '/api/v2/Ordens/ExportOrdensConsciliar',
                            { dataInicial: values.datas[0], dataFinal: values.datas[1] },
                            { responseType: 'blob' }
                        )
                            .then((res) => {
                                setDownloading(false);
                                const url = window.URL.createObjectURL(new Blob([res.data]));
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute(
                                    'download',
                                    `Ordens_Pagamento_${moment(new Date()).format('DD_MM_YYYY HH_mm')}.xlsx`
                                );
                                document.body.appendChild(link);
                                link.click();
                            })
                            .catch(() => {
                                message.error('Erro ao gerar arquivo!');
                                setDownloading(false);
                            });
                    }}
                >
                    <Form.Item name="datas" label="Período" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                        <DatePicker.RangePicker format="DD/MM/YYYY" />
                    </Form.Item>
                </Form>
            </Modal>

            <Form form={formbusca} labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}>
                <Row gutter={[8, 8]}>
                    <Col flex="1 0 50%">
                        <Form.Item label="Filtro por item na ordem" name="item">
                            <Select
                                loading={loadingmateriais}
                                showSearch
                                optionFilterProp="children"
                                dropdownMatchSelectWidth
                                allowClear
                                style={{ minWidth: 100 }}
                                onChange={(v) => dispatch(GetOrdensPagination({ ...filters, itemID: v }))}
                            >
                                {materiaislist.map((c) => (
                                    <Select.Option
                                        key={c.id}
                                        value={c.id}
                                    >{`${c.unidade} - ${c.descricao}`}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col flex="1 0 50%">
                        <Form.Item label="Filtro por Nota Fiscal" name="notaFiscal">
                            <Input.Search
                                onSearch={(v) => dispatch(GetOrdensPagination({ ...filters, notaFiscal: v }))}
                                allowClear
                                size="small"
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <CancelOrdemModal />
            <OrdemItensModal />
            <Table
                size="small"
                rowKey="id"
                onChange={(pagination, filters: any, sorter: any) => {
                    let f = {
                        ...filters,
                        cod: filters.cod || [],
                        situacao: filters.situacao || [],
                        descricao: filters.descricao || [],
                        emailEnviado: filters.emailEnviado || [],
                        comprador: filters.comprador || [],
                        criador: filters.criador || [],
                        dataCriacao: filters.dataCriacao
                            ? filters.dataCriacao.length > 0
                                ? [
                                      [
                                          moment(filters.dataCriacao[0][0]).set({
                                              hour: 0,
                                              minute: 0,
                                              second: 0,
                                              millisecond: 0,
                                          }),
                                          moment(filters.dataCriacao[0][1]).set({
                                              hour: 20,
                                              minute: 59,
                                              second: 59,
                                              millisecond: 0,
                                          }),
                                      ],
                                  ]
                                : []
                            : [],
                        enviadoPagamento: filters.enviadoPagamento
                            ? filters.enviadoPagamento.length > 0
                                ? [
                                      [
                                          moment(filters.enviadoPagamento[0][0]).set({
                                              hour: 0,
                                              minute: 0,
                                              second: 0,
                                              millisecond: 0,
                                          }),
                                          moment(filters.enviadoPagamento[0][1]).set({
                                              hour: 20,
                                              minute: 59,
                                              second: 59,
                                              millisecond: 0,
                                          }),
                                      ],
                                  ]
                                : []
                            : [],
                        prazoPagamento: filters.prazoPagamento
                            ? filters.prazoPagamento.length > 0
                                ? [
                                      [
                                          moment(filters.prazoPagamento[0][0]).set({
                                              hour: 0,
                                              minute: 0,
                                              second: 0,
                                              millisecond: 0,
                                          }),
                                          moment(filters.prazoPagamento[0][1]).set({
                                              hour: 20,
                                              minute: 59,
                                              second: 59,
                                              millisecond: 0,
                                          }),
                                      ],
                                  ]
                                : []
                            : [],
                        fornecedor: filters.fornecedor || [],
                        orcamento: filters.orcamento || [],
                        total: filters.total || [],
                    };
                    setFilters({ pagination, filters: f, sorter });
                    dispatch(
                        GetOrdensPagination({
                            pagination,
                            filters: f,
                            sorter: {
                                ...sorter,
                                field: sorter.field || 'cod',
                                order: sorter.order || 'descend',
                            },
                            itemID: formbusca.getFieldValue('item'),
                        })
                    );
                }}
                loading={loadingOrdens}
                columns={columns}
                dataSource={ordens}
                scroll={{ y: 'calc(100vh - 300px)', x: 1800 }}
                pagination={{
                    position: ['bottomRight'],
                    size: 'small',
                    showSizeChanger: true,
                    pageSizeOptions: [10, 25, 50, 100],
                    total: total,
                    showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
                }}
            />
        </div>
    );
}
export default OrdensView;
