import { Spin } from 'antd';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GetFolha, ClearFolha } from '@actions/folhasActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import FolhaPrint from './FolhaPrint';

function FolhaPrintPDF() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { folha, loadingFolha } = useAppSelector((state) => state.folhas);
  const { user } = useAppSelector((state) => state.user);

  useEffect(() => {
    dispatch(GetFolha(Number(id)));
    dispatch(ClearFolha());
  }, [dispatch, id]);
  return (
    <Spin spinning={loadingFolha}>
      <FolhaPrint folha={folha} customLogo={user?.configuracao?.logo} />
    </Spin>
  );
}
export default FolhaPrintPDF;
