import { useEffect } from 'react';
import { Button, Checkbox, Col, Divider, Form, InputNumber, Row, Input } from 'antd';
import { GetConfiguracao, SalvarConfiguracao, SetPageHeader } from '@actions/userActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

function ConfiguracaoView() {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { configuracao, loadingconfiguracao } = useAppSelector((state) => state.user.user);

  useEffect(() => {
    dispatch(GetConfiguracao());
    dispatch(
      SetPageHeader({
        title: 'Configuração',
        backbutton: true,
        extras: [
          <Button type="primary" onClick={() => form.submit()} loading={loadingconfiguracao}>
            Salvar
          </Button>,
        ],
        subtitle: 'Configurações gerais do sistema',
        menu: 'configuracao',
      })
    );
  }, [loadingconfiguracao]);
  useEffect(() => {
    form.setFieldsValue({
      ...configuracao,
      modulos: {
        ...configuracao.modulos,
        basico: true,
      },
    });
  }, [configuracao]);

  return (
    <>
      <Form form={form} name="form" layout="vertical" onFinish={(values) => dispatch(SalvarConfiguracao(values))}>
        <Row>
          <Col span={8}>
            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="metaAno" label="Meta do Ano (m2)">
              <InputNumber />
            </Form.Item>
            <Form.Item name="metaInicioObra" label="Meta Projeto-Obra (dias)">
              <InputNumber />
            </Form.Item>
            <Form.Item name="metaProjetoArquitetonico" label="Meta Contrato-Projeto (dias)">
              <InputNumber />
            </Form.Item>
            <Form.Item name="metaJornada" label="Meta Início Obra (dias)">
              <InputNumber />
            </Form.Item>
            <Form.Item name="pedidoAtrasado" label="Ordem rascunho entra em atraso (dias)">
              <InputNumber />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Divider>Bloqueios - Ordens</Divider>
            <Form.Item name="grupoBloqueado" label="Grupo bloqueado para compras" valuePropName="checked">
              <Checkbox />
            </Form.Item>
            <Form.Item name="itemEstourado" label="Item estoura orçamento em quantidade" valuePropName="checked">
              <Checkbox />
            </Form.Item>
            <Form.Item
              name="valorUnitEstourado"
              label="Item estoura orçamento em valor unitário"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Divider>Dashboard</Divider>
            <Form.Item name="dBareaconstruida" label="Área Construída" valuePropName="checked">
              <Checkbox />
            </Form.Item>
            <Form.Item name="dBclientesobra" label="Clientes Obra" valuePropName="checked">
              <Checkbox />
            </Form.Item>
            <Form.Item name="dBvalormedio" label="Valor Médio Empreendimentos" valuePropName="checked">
              <Checkbox />
            </Form.Item>
            <Form.Item name="dBcorretores" label="Placar Parceiros" valuePropName="checked">
              <Checkbox />
            </Form.Item>
            <Form.Item name="dBparcelas" label="Primeira Parcela Clientes" valuePropName="checked">
              <Checkbox />
            </Form.Item>
            <Form.Item name="dBorcamentos" label="Orçamentos em andamento" valuePropName="checked">
              <Checkbox />
            </Form.Item>
            <Form.Item name="dBresultadosOrcamentos" label="Orçamentos finalizados" valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default ConfiguracaoView;
