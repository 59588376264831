import { Tabs } from 'antd';
import FolhaFormRateioColaboradores from './FolhaForm.Rateio.Colaboradores';
import FolhaFormRateioItens from './FolhaForm.Rateio.Itens';

function FolhaFormRateio() {
  return (
    <div>
      <Tabs
        items={[
          { key: 'itens', tabKey: 'itens', label: 'Itens', children: <FolhaFormRateioItens />, forceRender: true },
          {
            key: 'rateio',
            tabKey: 'reateio',
            label: 'Rateio',
            children: <FolhaFormRateioColaboradores />,
            forceRender: true,
          },
        ]}
      />
    </div>
  );
}

export default FolhaFormRateio;
