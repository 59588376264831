import { useEffect, useState } from 'react';
import { EditOutlined, DeleteOutlined, BarChartOutlined } from '@ant-design/icons';
import { Table, Modal, Form, Select, Button, Input, InputNumber, Descriptions, Popconfirm, Tooltip } from 'antd';
import {
  CadastroInvestimentoModal,
  GetInvestidores,
  AddInvestimento,
  LoadInvestimento,
  LoadLancamentos,
  RemoverInvestimento,
} from '@actions/empsActions';
import { useParams } from 'react-router-dom';
import { moneyMask, moneyNegMask } from '@utils/masks';
import LancamentosModal from './LancamentosModal';
import { filterobject } from '@utils/filterandsort';
import UsuarioForm from '../../Usuarios/UsuarioForm';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Investimento } from '@models/Investimento';

function InvestimentosTable() {
  const dispatch = useAppDispatch();
  const { investimentos } = useAppSelector((state) => state.emps.empreendimento);
  const { cadastroinvestimentomodal, investidores, savinginvestimento, investimento } = useAppSelector(
    (state) => state.emps
  );
  const { id } = useParams();
  const [form] = Form.useForm();
  const [filter, setFilter] = useState('');

  useEffect(() => {
    dispatch(GetInvestidores());
  }, []);
  useEffect(() => {
    if (investimento)
      form.setFieldsValue({
        ...investimentos.find((c) => c.id === investimento),
        valorQuota: moneyMask.format(investimentos.find((c) => c.id === investimento).valorQuota),
      });
    else form.resetFields();
  }, [investimento, investimentos, form]);
  return (
    <div>
      <LancamentosModal />
      <div style={{ textAlign: 'right' }}>
        <Input style={{ width: 200 }} onChange={(e) => setFilter(e.target.value)} placeholder="Buscar" allowClear />
      </div>
      <Table
        rowKey="id"
        dataSource={filterobject(investimentos, filter)}
        size="small"
        pagination={false}
        scroll={{ y: 500, x: 1024 }}
        footer={(data) => (
          <Descriptions size="small" title="Totais" bordered column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}>
            <Descriptions.Item label="Quotas">{data.map((c) => c.quotas).reduce((a, b) => a + b, 0)}</Descriptions.Item>
            <Descriptions.Item label="Valor da Quota">
              {moneyNegMask.format(data.map((c) => c.valorQuota).reduce((a, b) => a + b, 0))}
            </Descriptions.Item>
            <Descriptions.Item label="Necessário">
              {moneyNegMask.format(data.map((c) => c.aporteNecessario).reduce((a, b) => a + b, 0))}
            </Descriptions.Item>
            <Descriptions.Item label="Aportado">
              {moneyNegMask.format(data.map((c) => c.aportado).reduce((a, b) => a + b, 0))}
            </Descriptions.Item>
            <Descriptions.Item label="Pendente">
              {moneyNegMask.format(data.map((c) => c.aportePendente).reduce((a, b) => a + b, 0))}
            </Descriptions.Item>
            <Descriptions.Item label="Retornado">
              {moneyNegMask.format(data.map((c) => c.retornado).reduce((a, b) => a + b, 0))}
            </Descriptions.Item>
            <Descriptions.Item label="Resgatado">
              {moneyNegMask.format(data.map((c) => c.resgatado).reduce((a, b) => a + b, 0))}
            </Descriptions.Item>
            <Descriptions.Item label="Disponível">
              {moneyNegMask.format(
                data.map((c) => c.retornado).reduce((a, b) => a + b, 0) -
                  data.map((c) => c.resgatado).reduce((a, b) => a + b, 0) -
                  data.map((c) => c.aportePendente).reduce((a, b) => a + b, 0)
              )}
            </Descriptions.Item>
          </Descriptions>
        )}
      >
        <Table.Column
          fixed="left"
          render={(_, row: Investimento) => (
            <div>
              <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(RemoverInvestimento(row.id))}>
                <Button size="small" icon={<DeleteOutlined />} />
              </Popconfirm>
              <Tooltip title="Editar">
                <Button size="small" icon={<EditOutlined />} onClick={() => dispatch(LoadInvestimento(row.id))} />
              </Tooltip>
              <Tooltip title="Lançamentos">
                <Button size="small" icon={<BarChartOutlined />} onClick={() => dispatch(LoadLancamentos(row.id))} />
              </Tooltip>
            </div>
          )}
        />
        <Table.Column fixed="left" title="Nome" dataIndex="investidor" />
        <Table.Column title="SCP" dataIndex="scp" />
        <Table.Column title="Fração" dataIndex="fracao" />
        <Table.Column title="Quotas" dataIndex="quotas" />
        <Table.Column title="Valor da Quota" dataIndex="valorQuota" render={(text) => moneyNegMask.format(text)} />
        <Table.Column
          title="Aporte Necessário"
          dataIndex="aporteNecessario"
          render={(text) => moneyNegMask.format(text)}
        />
        <Table.Column title="Valor Aportado" dataIndex="aportado" render={(text) => moneyNegMask.format(text)} />
        <Table.Column title="Aporte Pendente" dataIndex="aportePendente" render={(text) => moneyNegMask.format(text)} />
        <Table.Column title="Valor Retornado" dataIndex="retornado" render={(text) => moneyNegMask.format(text)} />
        <Table.Column title="Valor Resgatado" dataIndex="resgatado" render={(text) => moneyNegMask.format(text)} />
        <Table.Column
          title="Saldo Disponível"
          render={(_, row: Investimento) => moneyNegMask.format(row.retornado - row.resgatado - row.aportePendente)}
        />
      </Table>
      <Modal
        onCancel={() => dispatch(CadastroInvestimentoModal())}
        open={cadastroinvestimentomodal}
        title="Cadastro de Investimento"
        footer={[
          <Button key="1" onClick={() => dispatch(CadastroInvestimentoModal())}>
            Fechar
          </Button>,
          <Button type="primary" key="2" loading={savinginvestimento} onClick={() => form.submit()}>
            Salvar
          </Button>,
        ]}
      >
        <UsuarioForm />
        <Form
          form={form}
          wrapperCol={{ span: 18 }}
          labelCol={{ span: 6 }}
          name="investimentoForm"
          onFinish={(values) =>
            dispatch(
              AddInvestimento({
                ...values,
                empreendimentoid: id,
                valorQuota: moneyMask.normalize(values.valorQuota),
              })
            )
          }
        >
          <Form.Item name="id" style={{ display: 'none' }}>
            <Input />
          </Form.Item>
          <Form.Item
            name="userId"
            label="Investidor"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
            help="Não encontrou o investidor? Peça ao administrador para criar o usuário com perfil INVESTIDOR."
          >
            <Select size="small" showSearch optionFilterProp="children">
              {investidores.map((c, index) => (
                <Select.Option key={index} value={c.id}>
                  {c.nome}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="quotas"
            label="Quotas"
            rules={[
              { type: 'number', message: 'Formato de número incorreto. Ex.: 999,99' },
              { required: true, message: 'Campo obrigatório' },
            ]}
          >
            <InputNumber decimalSeparator="," />
          </Form.Item>

          <Form.Item
            name="valorQuota"
            label="Valor da Quota"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
          >
            <Input
              onChange={(e) =>
                form.setFieldsValue({
                  valorQuota: moneyMask.format(moneyMask.normalize(e.target.value)),
                })
              }
            />
          </Form.Item>
          <Form.Item name="scp" label="Contrato">
            <Input />
          </Form.Item>
          <Form.Item name="fracao" label="Fração">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default InvestimentosTable;
