import { useEffect, useState } from 'react';
import { Table, Input, Button, Popconfirm } from 'antd';
import { GetAgencias, FormAgencia, DeleteAgencia } from '@actions/agenciasActions';
import AgenciaForm from './AgenciaForm';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { filterobject, getColumnSearchProps, sortAlpha } from '@utils/filterandsort';
import { Permission } from '@hooks//usePermissions';
import { SetPageHeader } from '@actions/userActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Agencia } from '@models/Agencia';

function AgenciasView() {
  const dispatch = useAppDispatch();
  const agencias = useAppSelector((state) => state.agencias);
  const [filter, setFilter] = useState('');
  useEffect(() => {
    dispatch(GetAgencias());
    dispatch(
      SetPageHeader({
        title: 'Cadastros',
        backbutton: true,
        extras: [
          <Permission key="1" permission="agencia.add">
            <Button onClick={() => dispatch(FormAgencia({ id: null, open: true }))} type="primary">
              Nova Agencia
            </Button>
          </Permission>,
        ],
        subtitle: 'Agências Bancárias',
        menu: 'agencias',
      })
    );
  }, []);

  return (
    <div>
      <AgenciaForm />
      <div style={{ textAlign: 'right' }}>
        <Input style={{ width: 200 }} onChange={(e) => setFilter(e.target.value)} placeholder="Buscar" allowClear />
      </div>
      <Table
        size="small"
        rowKey="id"
        loading={agencias.loadingagencias}
        dataSource={filterobject(agencias.agencias, filter)}
        scroll={{ y: 'calc(100vh - 300px)', x: 1024 }}
        pagination={{
          position: ['bottomRight'],
          size: 'small',
          showSizeChanger: true,
          pageSizeOptions: [10, 25, 50, 100],
          showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
        }}
      >
        <Table.Column
          width={80}
          fixed="left"
          render={(_, row: Agencia) => (
            <div>
              <Permission permission="agencia.update">
                <Button
                  icon={<EditOutlined />}
                  onClick={() => dispatch(FormAgencia({ id: row.id, open: true }))}
                  size="small"
                />
              </Permission>
              <Permission permission="agencia.delete">
                <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(DeleteAgencia(row.id))}>
                  <Button icon={<DeleteOutlined />} size="small" />
                </Popconfirm>
              </Permission>
            </div>
          )}
        />
        <Table.Column
          title="Banco"
          dataIndex="banco"
          sorter={(a, b) => sortAlpha(a.banco, b.banco)}
          {...getColumnSearchProps('banco', 'string', 'Banco')}
        />
        <Table.Column
          title="Nome"
          dataIndex="nome"
          sorter={(a, b) => sortAlpha(a.nome, b.nome)}
          {...getColumnSearchProps('nome', 'string', 'Nome')}
        />
        <Table.Column
          title="Número"
          dataIndex="numero"
          sorter={(a, b) => sortAlpha(a.numero, b.numero)}
          {...getColumnSearchProps('numero', 'string', 'Número')}
        />
      </Table>
    </div>
  );
}
export default AgenciasView;
