export const OrdemStatus = {
  Rascunho: 'Rascunho',
  PendenteAprovacao: 'Pendente de aprovação',
  Aprovada: 'Aprovada',
  Substituida: 'Substituída',
  Cancelada: 'Cancelada',
  PagamentoParcial: 'Pagamento parcial',
  PendentePagamento: 'Pendente de pagamento',
  Finalizada: 'Finalizada',
  PendenteMedicao: 'Pendente de Medição',
  EmProcessamentoFinanceiro: 'Em processamento financeiro',
  PendenteDocumentos: 'Pendente de documentos',
  EmProcessamentoCompras: 'Em processamento compras',
};
export enum ordemType {
  ordem = 0,
  folha = 1,
  pedido = 2,
  transferencia = 3,
  nenhum = -1,
}
