import { useEffect, useState } from 'react';
import { Table, Button, Popconfirm, Input, Tooltip } from 'antd';
import { GetEmps, FormEmpreendimento, DeleteEmprreendimento, LoadInvestidores } from '@actions/empsActions';
import EmpreendimentoForm from './EmpreendimentoForm';
import { moneyMask } from '@utils/masks';
import { EditOutlined, DeleteOutlined, ControlOutlined, TeamOutlined } from '@ant-design/icons';
import { GetLoteamentos } from '@actions/loteamentosActions';
import { filterobject, getColumnSearchProps, sortAlpha } from '@utils/filterandsort';
import { SetPageHeader } from '@actions/userActions';
import { Permission } from '@hooks//usePermissions';
import { Link } from 'react-router-dom';
import InvestidoresModal from './InvestidoresModal';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Empreendimento } from '@models/Empreendimento';

function EmpreendimentosView() {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const emps = useAppSelector((state) => state.emps);
  const [filter, setFilter] = useState('');
  useEffect(() => {
    dispatch(GetEmps());
    dispatch(GetLoteamentos());
    dispatch(
      SetPageHeader({
        title: 'Cadastros',
        backbutton: true,
        extras: [
          <Permission key="1" permission="empreendimento.add">
            <Button onClick={() => dispatch(FormEmpreendimento(null, true))} type="primary">
              Novo Empreendimento
            </Button>
          </Permission>,
        ],
        subtitle: 'Empreendimentos',
        menu: 'empreendimentos',
      })
    );
  }, []);

  return (
    <div>
      <InvestidoresModal />
      <EmpreendimentoForm />
      <div style={{ textAlign: 'right' }}>
        <Input style={{ width: 200 }} onChange={(e) => setFilter(e.target.value)} placeholder="Buscar" allowClear />
      </div>
      <Table
        size="small"
        rowKey="id"
        loading={emps.loadingemps}
        dataSource={filterobject(emps.emps, filter)}
        scroll={{ y: 'calc(100vh - 300px)', x: 1024 }}
        pagination={{
          position: ['bottomRight'],
          size: 'small',
          showSizeChanger: true,
          pageSizeOptions: [10, 25, 50, 100],
          showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
        }}
      >
        <Table.Column
          width={120}
          fixed="left"
          render={(_, row: Empreendimento) => (
            <div>
              <Permission permission="empreendimento.update">
                <Button
                  icon={<EditOutlined />}
                  onClick={() => dispatch(FormEmpreendimento(row.id, true))}
                  size="small"
                />
              </Permission>
              <Permission permission="empreendimento.delete">
                <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(DeleteEmprreendimento(row.id))}>
                  <Button icon={<DeleteOutlined />} size="small" />
                </Popconfirm>
              </Permission>
              <Permission permission="empreendimento.manage">
                <Tooltip title="Gerenciar">
                  <Link to={`/Empreendimentos/${row.id}`}>
                    <Button icon={<ControlOutlined />} size="small" />
                  </Link>
                </Tooltip>
              </Permission>
              {user.modulos.map((c) => c.nome).includes('Investidor') && (
                <Permission permission="empreendimento.manage.investimentos">
                  <Tooltip title="Investidores">
                    <Button icon={<TeamOutlined />} size="small" onClick={() => dispatch(LoadInvestidores(row.id))} />
                  </Tooltip>
                </Permission>
              )}
            </div>
          )}
        />
        <Table.Column
          fixed="left"
          title="Nome"
          dataIndex="nome"
          sorter={(a, b) => sortAlpha(a.nome, b.nome)}
          {...getColumnSearchProps('nome', 'string', 'Nome')}
        />
        <Table.Column
          title="Casas"
          dataIndex="numCasas"
          sorter={(a, b) => a.numCasas - b.numCasas}
          {...getColumnSearchProps('numCasas', 'string', 'Casas')}
        />
        <Table.Column
          title="Aporte"
          dataIndex="aporte"
          render={(text) => moneyMask.format(text)}
          sorter={(a, b) => a.aporte - b.aporte}
          {...getColumnSearchProps('aporte', 'string', 'Aporte')}
        />
        <Table.Column
          title="Loteamento"
          dataIndex="loteamento"
          sorter={(a, b) => sortAlpha(a.loteamento, b.loteamento)}
          {...getColumnSearchProps('loteamento', 'string', 'Loteamento')}
        />
      </Table>
    </div>
  );
}
export default EmpreendimentosView;
