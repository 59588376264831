import { Button, notification, Spin, Tabs } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { SetPageHeader } from '@actions/userActions';
import { Axios } from '@config/axios';
import { useAppDispatch } from '@config/reduxStore';

function ModelosView() {
  const dispatch = useAppDispatch();
  const [value, setValue] = useState<any>(null);
  const [template, setTemplate] = useState<any>(null);
  const editorRef = useRef(null);
  const [templates, setTemplates] = useState<any>();
  const [loading, setloading] = useState<boolean>(true);
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Configuração',
        backbutton: true,
        extras: [
          <Button
            key={1}
            loading={loading}
            type="primary"
            onClick={() => {
              setloading(true);
              Axios.post('/api/v2/Templates/SalvarTemplate', { templates: value })
                .then(() => {
                  notification.success({ message: 'Modelos atualizados com sucesso!' });
                  setloading(false);
                })
                .catch(() => {
                  notification.error({ message: 'Erro ao atualizar modelos' });
                  setloading(false);
                });
            }}
          >
            Salvar
          </Button>,
        ],
        subtitle: 'Modelos',
        menu: 'modelos',
      })
    );
  }, [value, loading]);
  useEffect(() => {
    Axios.get('/api/v2/Templates/GetTemplates')
      .then((res) => {
        setTemplates(res.data);
        setTemplate(Object.keys(res.data)[0]);
        setValue(Object.keys(res.data).map((c) => ({ body: res.data[c].body, nome: res.data[c].nome, template: c })));
        setloading(false);
      })
      .catch(() => {
        notification.error({ message: 'Erro ao carregar modelos' });
        setloading(false);
      });
  }, []);

  return (
    <Spin spinning={loading}>
      {templates && value && (
        <Tabs defaultActiveKey={template} onChange={(ak) => setTemplate(ak)} activeKey={template} tabPosition="left">
          {Object.keys(templates).map((c, i) => (
            <Tabs.TabPane key={c} tab={templates[c].nome}>
              <Editor
                onInit={(_, editor) => (editorRef.current = editor)}
                value={value[i].body}
                onEditorChange={(v) =>
                  setValue(
                    value.map((d: any) => {
                      if (d.template === c) {
                        d.body = v;
                        return d;
                      } else return d;
                    })
                  )
                }
                apiKey="a2t1vfplr3rceqoh0g9sk3ipc5joh4q3nzjsnlq2tmd6zsv6"
                init={{
                  doctype: 'UTF-8',
                  height: 700,
                  language_load: true,
                  language: 'pt_BR',
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount',
                  ],
                  toolbar:
                    'undo redo | formatselect | ' +
                    'bold italic backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                }}
              />
            </Tabs.TabPane>
          ))}
        </Tabs>
      )}
    </Spin>
  );
}
export default ModelosView;
