import { useAppSelector } from '@config/reduxStore';
import { Modal, Table } from 'antd';
import { Dispatch, SetStateAction } from 'react';

function GruposModal({
  visible,
  setVisible,
  categoria,
}: {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<{ visible: boolean; categoria: string }>>;
  categoria: string;
}) {
  const { grupos, loadingGrupos } = useAppSelector((state) => state.fisico);

  return (
    <Modal
      open={visible}
      onCancel={() => setVisible({ visible: false, categoria: '' })}
      title={categoria}
      cancelText="Fechar"
      okButtonProps={{ hidden: true }}
    >
      <Table size="small" loading={loadingGrupos} dataSource={grupos} rowKey="id">
        <Table.Column title="Unidade" dataIndex="unidade" width={80} />
        <Table.Column title="Descrição" dataIndex="descricao" />
      </Table>
    </Modal>
  );
}
export default GruposModal;
