import { SetPageHeader } from '@actions/userActions';
import { useAppDispatch } from '@config/reduxStore';
import { DatePicker } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import DashboardNovosContratosMes from './Dashboard.NovosCadastrosMes';
import DashboardAprovadosMes from './Dashboard.AprovadosMes';
import DashboardEngenhariasMes from './Dashboard.EngenhariasMes';
import DashboardContratadosMes from './Dashboard.ContratadosMes';
import DashboardPendentesAprovacao from './Dashboard.PendentesAprovacao';
import DashboardFunil from './Dashboard.Funil';
import DashboardContratadoMesAtual from './Dashboard.ContratadoMesAtual';
import DashboardNovosCadastrosMesAtual from './Dashboard.NovosCadastrosMesAtual';
import DashboardAprovadosMesAtual from './Dashboard.AprovadosMesAtual';

function DashboardCorrepondencia() {
  const dispatch = useAppDispatch();
  const [mes, setMes] = useState<moment.Moment>(moment());
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Dashboard',
        menu: '/',
        subtitle: '',
        backbutton: true,
        extras: [<DatePicker.MonthPicker value={mes} onChange={(v) => setMes(v)} format="MMM/YYYY" key="0" />],
      })
    );
  }, [mes]);
  const flex = '0 1 300px';
  return (
    <div>
      <div
        style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '8px 8px', alignItems: 'stretch' }}
      >
        <div style={{ flex: flex }}>
          <DashboardPendentesAprovacao />
          <DashboardContratadoMesAtual mes={mes} />
          <DashboardNovosCadastrosMesAtual mes={mes} />
          <DashboardAprovadosMesAtual mes={mes} />
        </div>
        <div style={{ flex: flex }}>
          <DashboardNovosContratosMes mes={mes} />
        </div>
        <div style={{ flex: flex }}>
          <DashboardAprovadosMes mes={mes} />
        </div>
        <div style={{ flex: flex }}>
          <DashboardEngenhariasMes mes={mes} />
        </div>
        <div style={{ flex: flex }}>
          <DashboardContratadosMes mes={mes} />
        </div>
        <div style={{ flex: flex }}>
          <DashboardFunil />
        </div>
      </div>
    </div>
  );
}

export default DashboardCorrepondencia;
