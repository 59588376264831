import { Alert } from 'antd';
import { useOrdemForm } from './useOrdemForm';

function OrdemAlertas() {
  const { estouros } = useOrdemForm();
  return (
    <div>
      {estouros.map((c) => (
        <Alert type="error" message={`Item ${c.item} - ${c.descricao}`} />
      ))}
    </div>
  );
}

export default OrdemAlertas;
