import { Badge, Menu } from 'antd';
import { Logout } from '@actions/userActions';
import { Link } from 'react-router-dom';
import { BellOutlined } from '@ant-design/icons';
import { useState } from 'react';
import Notifications from './Notifications';
import { setLastRead } from '@actions/messagesActions';
import { useNotification } from '@hooks//useNotifications';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

function NavBar() {
  const user = useAppSelector((state) => state.user.user);
  const [notview, setNotView] = useState(false);
  const dispatch = useAppDispatch();
  const { unread } = useAppSelector((state) => state.messages);
  const { CloseConnection } = useNotification();
  return (
    <div>
      <Notifications visible={notview} setVisible={setNotView} />
      <Menu mode="horizontal" selectable={false} style={{ lineHeight: '64px', justifyContent: 'flex-end' }}>
        {false && (
          <Menu.Item key="1">
            <Link to="/Help">Ajuda</Link>
          </Menu.Item>
        )}
        <Menu.Item
          key="3"
          onClick={() => {
            setNotView(true);
            dispatch(setLastRead());
          }}
        >
          <Badge count={unread} size="small" offset={[5, 0]}>
            <BellOutlined style={{ fontSize: 16 }} />
          </Badge>
        </Menu.Item>
        <Menu.Item key="2">
          <Link to="/Profile">Olá, {user.nome}!</Link>
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            CloseConnection();
            dispatch(Logout());
          }}
          key="5"
        >
          Sair
        </Menu.Item>
      </Menu>
    </div>
  );
}

export default NavBar;
