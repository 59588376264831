import { Modal, Form, DatePicker, Select, Divider, Row, Col, InputNumber, Input, Descriptions } from 'antd';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { getFisico, getResponsaveis, novaMedicao } from '@actions/bancoActions';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { doubleMask1, moneyMask, moneyNegMask } from '@utils/masks';
import { naturalSorter } from '@utils/filterandsort';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Medicao } from '@models/Medicao';

function NovaMedicaoModal({
  visible,
  setVisible,
}: {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}) {
  const { responsaveis, loadingResponsaveis, fisico, medicoes, savingMedicao } = useAppSelector((state) => state.banco);
  const [form] = Form.useForm<Medicao>();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  useEffect(() => {
    dispatch(getResponsaveis());
    dispatch(getFisico(id));
  }, [id]);
  useEffect(() => {
    form.resetFields();
  }, [visible, medicoes, fisico]);
  useEffect(() => {
    if (!savingMedicao) {
      form.resetFields();
      setVisible(false);
      Modal.destroyAll();
      dispatch(getFisico(id));
    }
  }, [savingMedicao]);
  return (
    <Modal
      title="Nova Medição"
      open={visible}
      onCancel={() => {
        setVisible(false);
        Modal.destroyAll();
      }}
      onOk={() => form.submit()}
      okButtonProps={{ loading: savingMedicao }}
      destroyOnClose
    >
      <Form
        form={form}
        onFinish={(values) => {
          dispatch(novaMedicao(values));
        }}
        labelCol={{ span: 6 }}
        onValuesChange={(_, values) =>
          form.setFieldsValue({
            ...values,
            medicaoItens: values.medicaoItens.map((c) => ({
              ...c,
              valorTotal: (c.porcentagem * c.orcado) / 100,
            })),
          })
        }
      >
        <Form.Item name="orcamentoBancoID" hidden initialValue={id}>
          <Input />
        </Form.Item>
        <Form.Item name="mes" label="Mês" rules={[{ required: true, message: 'Campo obrigatório' }]}>
          <DatePicker.MonthPicker
            format="MM/YYYY"
            disabledDate={(current) =>
              current &&
              medicoes.length > 0 &&
              (current <= moment.max(medicoes.map((c) => moment(c.mes))) ||
                current.format('MM/YYYY') === moment.max(medicoes.map((c) => moment(c.mes))).format('MM/YYYY'))
            }
          />
        </Form.Item>
        <Form.Item
          name="dataSolicitado"
          label="Data Solicitação"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <DatePicker format="DD/MM/YYYY" />
        </Form.Item>
        <Form.Item name="dataRealizado" label="Data Finalização">
          <DatePicker format="DD/MM/YYYY" allowClear />
        </Form.Item>
        <Form.Item name="status" label="Situação" initialValue={'Solicitado'}>
          <Select>
            <Select.Option value="Solicitado">Solicitado</Select.Option>
            <Select.Option value="Realizado">Realizado</Select.Option>
            <Select.Option value="Pendência">Pendência</Select.Option>
            <Select.Option value="Finalizado">Finalizado</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="responsavelID" label="Responsável" rules={[{ required: true, message: 'Campo obrigatório' }]}>
          <Select showSearch optionFilterProp="children" loading={loadingResponsaveis}>
            {responsaveis.map((c) => (
              <Select.Option key={c.id} value={c.id}>
                {c.nome}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="notas" label="Notas">
          <Input.TextArea />
        </Form.Item>
        <Divider />
        <Form.List name="medicaoItens">
          {() => {
            return (
              <div style={{ maxHeight: 500, overflowY: 'scroll' }}>
                <Row style={{ fontWeight: 'bold', width: '96%' }}>
                  <Col span={10}>Item</Col>
                  <Col span={6} style={{ textAlign: 'center' }}>
                    Orçado
                  </Col>
                  <Col span={4} style={{ textAlign: 'center' }}>
                    Acumulado
                  </Col>
                  <Col span={4} style={{ textAlign: 'center' }}>
                    Medição
                  </Col>
                </Row>
                {[...fisico]
                  .sort((a, b) => naturalSorter(a.descricao, b.descricao))
                  .map((c, index) => (
                    <Row key={c.id} style={{ width: '96%' }}>
                      <Col span={10}>{c.descricao}</Col>
                      <Col span={6} style={{ textAlign: 'center' }}>
                        {moneyMask.format(c.orcado)}
                      </Col>
                      <Col span={4} style={{ textAlign: 'center' }}>
                        {doubleMask1.format((100 * c.realizado) / c.orcado)}
                      </Col>
                      <Col span={4} style={{ textAlign: 'center' }}>
                        <Form.Item name={[index, 'porcentagem']} initialValue={(100 * c.realizado) / c.orcado}>
                          <InputNumber
                            min={(100 * c.realizado) / c.orcado}
                            max={100}
                            decimalSeparator=","
                            precision={2}
                          />
                        </Form.Item>
                        <Form.Item name={[index, 'orcamentoBancoItemID']} initialValue={c.id} hidden>
                          <Input />
                        </Form.Item>
                        <Form.Item name={[index, 'orcamentoBancoID']} initialValue={id} hidden>
                          <Input />
                        </Form.Item>
                        <Form.Item name={[index, 'valorTotal']} initialValue={c.realizado} hidden>
                          <InputNumber />
                        </Form.Item>
                        <Form.Item name={[index, 'orcado']} initialValue={c.orcado} hidden>
                          <InputNumber />
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
              </div>
            );
          }}
        </Form.List>
        <Divider />
        <Descriptions bordered layout="vertical" size="small">
          <Descriptions.Item label="Acumulado">
            <div>
              <div>
                <Form.Item shouldUpdate noStyle>
                  {() => moneyNegMask.format(fisico.reduce((a, b) => a + b.realizado, 0))}
                </Form.Item>
              </div>
              <div>
                <Form.Item shouldUpdate noStyle>
                  {() =>
                    doubleMask1.format(
                      (100 * fisico.reduce((a, b) => a + b.realizado, 0)) / fisico.reduce((a, b) => a + b.orcado, 0)
                    )
                  }
                </Form.Item>
              </div>
            </div>
          </Descriptions.Item>
          <Descriptions.Item label="Medição">
            <div>
              <div>
                <Form.Item shouldUpdate noStyle>
                  {({ getFieldValue }) =>
                    moneyNegMask.format(
                      getFieldValue('medicaoItens').reduce((a: any, b: any) => a + b.valorTotal, 0) -
                        fisico.reduce((a, b) => a + b.realizado, 0)
                    )
                  }
                </Form.Item>
              </div>
              <div>
                <Form.Item shouldUpdate noStyle>
                  {({ getFieldValue }) =>
                    doubleMask1.format(
                      (100 * getFieldValue('medicaoItens').reduce((a: any, b: any) => a + b.valorTotal, 0)) /
                        fisico.reduce((a, b) => a + b.orcado, 0) -
                        (100 * fisico.reduce((a, b) => a + b.realizado, 0)) / fisico.reduce((a, b) => a + b.orcado, 0)
                    )
                  }
                </Form.Item>
              </div>
            </div>
          </Descriptions.Item>
        </Descriptions>
      </Form>
    </Modal>
  );
}

export default NovaMedicaoModal;
