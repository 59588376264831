import { Col, Form, Radio, Row } from 'antd';

function AvaliacaoItem({ name, title }: { name: string; title: string }) {
  return (
    <Row>
      <Col>
        <Form.Item name={['avaliacao', name]} label={title}>
          <Radio.Group>
            <Radio value="Péssimo">Péssimo</Radio>
            <Radio value="Ruim">Ruim</Radio>
            <Radio value="Regular">Regular</Radio>
            <Radio value="Bom">Bom</Radio>
            <Radio value="Excelente">Excelente</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>
    </Row>
  );
}

export default AvaliacaoItem;
