import { OrdemStatus } from '@constants/ordemStatus';
import { Tag } from 'antd';
import { EditOutlined, CheckOutlined } from '@ant-design/icons';
function FolhaStatusTag({ status }: { status: string }) {
  const color =
    status === OrdemStatus.Cancelada || status === OrdemStatus.Substituida
      ? 'red'
      : status === OrdemStatus.Finalizada
      ? 'green'
      : status === OrdemStatus.PendenteDocumentos
      ? 'orange'
      : status === OrdemStatus.PendentePagamento
      ? 'orange'
      : status === OrdemStatus.PendenteMedicao
      ? 'orange'
      : status === OrdemStatus.Aprovada
      ? 'blue'
      : status === OrdemStatus.PendenteAprovacao
      ? 'darkblue'
      : undefined;

  return (
    <Tag
      color={color}
      icon={
        status === OrdemStatus.Rascunho ? (
          <EditOutlined />
        ) : status === OrdemStatus.Aprovada ? (
          <CheckOutlined />
        ) : undefined
      }
    >
      {status}
    </Tag>
  );
}
export default FolhaStatusTag;
