import { Agencia } from '@models/Agencia';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Axios } from '@config/axios';
import { apiEndpoints } from '@constants/apiEndpoints';

export const GetAgencias = createAsyncThunk('agencias/GetAgencias', async () =>
  Axios.get(apiEndpoints.AGENCIAS.GET_AGENCIAS)
);
export const SalvarAgencia = createAsyncThunk('agencias/SalvarAgencia', async (values: Agencia) =>
  Axios.post(apiEndpoints.AGENCIAS.SALVAR_AGENCIA, { ...values })
);
export const DeleteAgencia = createAsyncThunk('agencias/DeleteAgencia', async (id: number, apiThunk) =>
  Axios.get(apiEndpoints.AGENCIAS.DELETE_AGENCIA, { params: { id } }).catch((res) => apiThunk.rejectWithValue(res))
);
export const FormAgencia = createAction('agencias/FormAgencia', ({ id, open }: { id: number; open: boolean }) => ({
  payload: { id: id, open: open },
}));
