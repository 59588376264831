import { IFolhaForm } from '@models/Folha';
import { GetFolha, SalvarFolhaItens, SalvarFolhaStep1 } from '@services/api/api.folha';
import { moneyMask } from '@utils/masks';
import { Form, FormInstance } from 'antd';
import moment from 'moment';
import React, { ReactNode, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

interface FolhaFormContextModel {
  step: number;
  next: () => void;
  previous: () => void;
  goto: (stepNumber: number) => void;
  form: FormInstance;
  numSteps: number;
  save: (values: Partial<IFolhaForm>) => void;
  isLoading: boolean;
  isFetching: boolean;
  folha: IFolhaForm;
}
interface FolhaFormProviderProps {
  children: ReactNode;
}
const FolhaFormContext = React.createContext<FolhaFormContextModel>(null);

export const FolhaFormProvider = ({ children }: FolhaFormProviderProps) => {
  const [step, setStep] = useState<number>(0);
  const { stepNum, id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm<IFolhaForm>();
  const numSteps = 5;
  const salvarStep1 = SalvarFolhaStep1();
  const salvarFolhaItens = SalvarFolhaItens();
  const { data, isLoading, isFetching } = GetFolha(id);
  const save = (values: Partial<IFolhaForm>) => {
    if (step === 0) {
      salvarStep1.mutate(values);
    }
    if (step === 3) {
      salvarFolhaItens.mutate({
        ...values,
        rateio: values.rateio.map((c) => ({ ...c, medicao: moneyMask.normalize(String(c.medicao)) })),
      });
    }
  };
  const next = () => {
    if (step < numSteps) {
      navigate(`/NovaFolhaMedicao/${step + 1}/${id}`);
      setStep((pv) => pv + 1);
    }
  };
  const previous = () => {
    if (step > 0) {
      navigate(`/NovaFolhaMedicao/${step - 1}/${id}`);
      setStep((pv) => pv - 1);
    }
  };
  useEffect(() => {
    if (stepNum) {
      if (Number(stepNum)) {
        const num = Number(stepNum);
        if (num > 0 && num < numSteps) setStep(Number(stepNum));
        else {
          navigate(`/NovaFolhaMedicao/${0}/${id}`);
          setStep(0);
        }
      }
    }
  }, [step, stepNum]);
  const goto = (stepNumber: number) => {
    navigate(`/NovaFolhaMedicao/${stepNumber}/${id}`);
    setStep(stepNumber);
  };
  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        mesCorrente: moment(data.mesCorrente).isValid() ? moment(data.mesCorrente) : null,
        rateio: data.rateio.map((c) => ({ ...c, medicao: moneyMask.format(c.medicao) })),
      });
    }
  }, [data]);
  return (
    <FolhaFormContext.Provider
      value={{ step, next, previous, goto, form, numSteps, save, isLoading, folha: data, isFetching }}
    >
      {children}
    </FolhaFormContext.Provider>
  );
};

export const useFolhaForm = () => {
  const context = React.useContext(FolhaFormContext);
  if (context === null) throw new Error('Hook is not wrapped by provider');
  return context;
};
