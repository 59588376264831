import { GetFormaPagamento } from '@models/PagamentoOrdem';
import { GetOrdemEdit } from '@services/api/api.ordem';
import { orderDateDesc } from '@utils/filterandsort';
import { moneyMask } from '@utils/masks';
import { Card, List } from 'antd';
import moment from 'moment';
import { useParams } from 'react-router-dom';

function OrdemPagamentos() {
  const { id } = useParams();
  const { data: ordem, isLoading } = GetOrdemEdit(id);
  return (
    <Card title="Pagamentos Parciais" size="small" style={{ marginTop: 10 }} loading={isLoading}>
      <div style={{ height: 300, overflowY: 'scroll' }}>
        <List
          dataSource={ordem ? ordem.pagamentosOrdem.sort((a, b) => orderDateDesc(b.data, a.data)) : []}
          renderItem={(item) => (
            <List.Item.Meta
              title={moneyMask.format(item.valor)}
              description={`${GetFormaPagamento(item.formaPagamento)} - ${moment(item.data).format(
                'DD/MM/YYYY HH:mm:SS'
              )}`}
            />
          )}
        />
      </div>
    </Card>
  );
}

export default OrdemPagamentos;
