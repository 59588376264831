import { ExtendedAction } from '@config/reduxStore';
import { Loteamento } from '@models/Loteamento';
import { notification } from 'antd';

interface LoteamentosReducer {
  loteamentos: Loteamento[];
  formview: boolean;
  loteamento: number;
  loadingloteamentos: boolean;
  savingloteamento: boolean;
  feedback: string;
}
var initialState: LoteamentosReducer = {
  loteamentos: [],
  formview: false,
  loteamento: null,
  loadingloteamentos: false,
  savingloteamento: false,
  feedback: '',
};

const loteamentosReducer = function (state = initialState, action: ExtendedAction) {
  switch (action.type) {
    case 'RESET_FEEDBACK':
      state = {
        ...state,
        feedback: '',
      };
      break;
    case 'FORM_LOTEAMENTO':
      state = {
        ...state,
        formview: action.payload.open,
        feedback: '',
        loteamento: action.payload.id ? action.payload.id : null,
      };
      break;
    case 'GET_LOTEAMENTOS_PENDING':
      state = {
        ...state,
        loadingloteamentos: true,
        loteamentos: initialState.loteamentos,
      };
      break;
    case 'GET_LOTEAMENTOS_REJECTED':
      state = {
        ...state,
        loadingloteamentos: false,
      };
      break;
    case 'GET_LOTEAMENTOS_FULFILLED':
      state = {
        ...state,
        loadingloteamentos: false,
        loteamentos: action.payload.data,
      };
      break;
    case 'ADD_LOTEAMENTO_PENDING':
      state = {
        ...state,
        savingloteamento: true,
        feedback: '',
      };
      break;
    case 'ADD_LOTEAMENTO_REJECTED':
      state = {
        ...state,
        savingloteamento: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: 'Erro ao criar loteamento!' });
      break;
    case 'ADD_LOTEAMENTO_FULFILLED':
      state = {
        ...state,
        savingloteamento: false,
        feedback: 'Loteamento criado com sucesso!',
        loteamentos: [...state.loteamentos, action.payload.data],
      };
      notification.success({ message: 'Loteamento criado com sucesso!' });
      break;
    case 'UPDATE_LOTEAMENTO_PENDING':
      state = {
        ...state,
        savingloteamento: true,
        feedback: '',
      };
      break;
    case 'UPDATE_LOTEAMENTO_REJECTED':
      state = {
        ...state,
        savingloteamento: false,
        feedback: action.payload.response.data.Message,
      };
      break;
    case 'UPDATE_LOTEAMENTO_FULFILLED':
      state = {
        ...state,
        savingloteamento: false,
        feedback: 'Loteamento atualizado com sucesso!',
        loteamentos: state.loteamentos.map((c) => {
          if (c.id === action.payload.data.id) return action.payload.data;
          else return c;
        }),
      };
      break;
    case 'DELETE_LOTEAMENTO_PENDING':
      state = {
        ...state,
        loadingloteamentos: true,
        feedback: '',
      };
      break;
    case 'DELETE_LOTEAMENTO_REJECTED':
      state = {
        ...state,
        loadingloteamentos: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: state.feedback });
      break;
    case 'DELETE_LOTEAMENTO_FULFILLED':
      state = {
        ...state,
        loadingloteamentos: false,
        feedback: 'Loteamento excluído com sucesso!',
        loteamentos: state.loteamentos.filter((c) => c.id !== action.payload.data),
      };
      notification.success({ message: state.feedback });
      break;
    default:
      return state;
  }
  return state;
};

export default loteamentosReducer;
