import { GetOrcamentoItens } from '@services/api/api.orcamentoItem';
import { naturalSorter } from '@utils/filterandsort';
import { TreeSelect, TreeSelectProps } from 'antd';

export interface SelectSolicitanteProps extends TreeSelectProps {
  orcamentoID: number;
  comSaldo?: boolean;
}

function OrcamentoItemSelect({ orcamentoID, comSaldo = true, ...restProps }: SelectSolicitanteProps) {
  const { isLoading, data, isFetching } = GetOrcamentoItens(orcamentoID, comSaldo);
  return (
    <TreeSelect
      {...restProps}
      showSearch
      dropdownMatchSelectWidth={false}
      placeholder="Selecione um item..."
      treeNodeFilterProp="label"
      loading={isLoading || isFetching}
      disabled={!!!orcamentoID || isLoading}
      allowClear
      treeData={[
        ...new Set(
          data?.itens
            .filter((c) => c.orcamentoID === orcamentoID)
            .sort((a, b) => naturalSorter(a.categoria as string, b.categoria as string))
            .map((c) => c.categoriaID)
        ),
      ].map((c) => ({
        value: 'c' + c,
        label: data.itens.find((ca) => ca.categoriaID === c)?.categoria as string,
        key: 'c' + c,
        selectable: false,
        children: [
          ...new Set(
            data.itens
              .filter((g) => g.orcamentoID === orcamentoID && g.categoriaID === c)
              .sort((a, b) => naturalSorter(a.grupo as string, b.grupo as string))
              .map((g) => g.grupoID)
          ),
        ].map((g) => ({
          value: 'g' + g,
          label: data.itens.find((gr) => gr.grupoID === g)?.grupo as string,
          key: 'g' + g,
          selectable: false,
          children: data.itens
            .filter((i) => i.grupoID === g)
            .sort((a, b) => naturalSorter(a.item as string, b.item as string))
            .map((i) => ({
              value: i.id,
              label: (i.unidade + ' - ' + i.item) as string,
              key: i.id,
              saldoQuantidade: i.saldoQuantidade,
              selectable: true,
            })),
        })),
      }))}
    />
  );
}

export default OrcamentoItemSelect;
