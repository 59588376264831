import { useQuery } from 'react-query';
import { fetcher } from './api';
import {
  IAprovadosMes,
  IContratadoMes,
  IEngenhariasMes,
  IFunilStatus,
  INovosCadastrosMes,
} from '@models/ClienteCorrespondencia';
import { AxiosError } from 'axios';
import { apiEndpoints } from '@constants/apiEndpoints';

export const GetAprovadosMes = (mes: moment.Moment) => {
  return useQuery<IAprovadosMes[], AxiosError<any>>(
    ['AprovadosMes', mes],
    () =>
      fetcher<IAprovadosMes[]>(apiEndpoints.CORRESPONDENCIA.APROVADOS_MES, {
        params: { mes: mes.format('YYYY-MM-DD') },
      }),
    {
      initialData: [],
      staleTime: 0,
      onError: (err) => console.log(err.response?.data?.Message),
    }
  );
};
export const GetNovosCadastrosMes = (mes: moment.Moment) => {
  return useQuery<INovosCadastrosMes[], AxiosError<any>>(
    ['NovosCadastrosMes', mes],
    () =>
      fetcher<INovosCadastrosMes[]>(apiEndpoints.CORRESPONDENCIA.NOVOS_CADASTROS_MES, {
        params: { mes: mes.format('YYYY-MM-DD') },
      }),
    {
      initialData: [],
      staleTime: 0,
      onError: (err) => console.log(err.response?.data?.Message),
    }
  );
};
export const GetPendetesAprovacao = () => {
  return useQuery<number, AxiosError<any>>(
    ['PendetesAprovacao'],
    () => fetcher<number>(apiEndpoints.CORRESPONDENCIA.PENDENTES_APROVACAO),
    {
      initialData: 0,
      staleTime: 0,
      onError: (err) => console.log(err.response?.data?.Message),
    }
  );
};
export const GetEngenhairiasMes = (mes: moment.Moment) => {
  return useQuery<IEngenhariasMes[], AxiosError<any>>(
    ['EngenhairiasMes', mes],
    () =>
      fetcher<IEngenhariasMes[]>(apiEndpoints.CORRESPONDENCIA.ENGENHARIAS_MES, {
        params: { mes: mes.format('YYYY-MM-DD') },
      }),
    {
      initialData: [],
      staleTime: 0,
      onError: (err) => console.log(err.response?.data?.Message),
    }
  );
};
export const GetFunilStatus = () => {
  return useQuery<IFunilStatus[], AxiosError<any>>(
    ['FunilStatus'],
    () => fetcher<IFunilStatus[]>(apiEndpoints.CORRESPONDENCIA.FUNIL_STATUS),
    {
      initialData: [],
      staleTime: 0,
      onError: (err) => console.log(err.response?.data?.Message),
    }
  );
};
export const GetContratadoMes = (mes: moment.Moment) => {
  return useQuery<IContratadoMes[], AxiosError<any>>(
    ['ContratadoMes', mes],
    () =>
      fetcher<IContratadoMes[]>(apiEndpoints.CORRESPONDENCIA.CONTRATADO_MES, {
        params: { mes: mes.format('YYYY-MM-DD') },
      }),
    {
      initialData: [],
      staleTime: 0,
      onError: (err) => console.log(err.response?.data?.Message),
    }
  );
};
export const GetContratadoMesAtual = (mes: moment.Moment) => {
  return useQuery<number, AxiosError<any>>(
    ['ContratadoMesAtual', mes],
    () =>
      fetcher<number>(apiEndpoints.CORRESPONDENCIA.CONTRATADO_MES_ATUAL, {
        params: { mes: mes.format('YYYY-MM-DD') },
      }),
    {
      initialData: 0,
      staleTime: 0,
      onError: (err) => console.log(err.response?.data?.Message),
    }
  );
};
export const GetNovosCadastrosMesAtual = (mes: moment.Moment) => {
  return useQuery<number, AxiosError<any>>(
    ['NovosCadastrosMesAtual', mes],
    () =>
      fetcher<number>(apiEndpoints.CORRESPONDENCIA.NOVOS_CADASTROS_MES_ATUAL, {
        params: { mes: mes.format('YYYY-MM-DD') },
      }),
    {
      initialData: 0,
      staleTime: 0,
      onError: (err) => console.log(err.response?.data?.Message),
    }
  );
};
export const GetAprovadosMesAtual = (mes: moment.Moment) => {
  return useQuery<number, AxiosError<any>>(
    ['AprovadosMesAtual', mes],
    () =>
      fetcher<number>(apiEndpoints.CORRESPONDENCIA.APROVADOS_MES_ATUAL, {
        params: { mes: mes.format('YYYY-MM-DD') },
      }),
    {
      initialData: 0,
      staleTime: 0,
      onError: (err) => console.log(err.response?.data?.Message),
    }
  );
};
