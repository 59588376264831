import { Button, DatePicker, Input, InputNumber, Progress, Space, Table } from 'antd';
import { useMemo, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GetAvancos, getCategoriaItens, getFisico, getRealizadoMes, salvarFisico } from '@actions/fisicoActions';
import { SetPageHeader } from '@actions/userActions';
import { doubleMask, doubleMask1, moneyMask } from '@utils/masks';
import moment from 'moment';
import { naturalSorter } from '@utils/filterandsort';
import { PercentageOutlined } from '@ant-design/icons';
import '../../../assets/scss/Fisico.scss';
import { Permission } from '@hooks//usePermissions';
import GruposModal from './GruposModal';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { FisicoFinanceiro } from '@models/FisicoFinanceiro';
import { Fisico } from '@models/Fisico';

function PlanejamentoObra() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [meses, setMeses] = useState<number[]>(new Array(1).fill(0));
  const [inicial, setInicial] = useState<moment.Moment>(moment(new Date()));
  const [lancamentos, setLancamentos] = useState<FisicoFinanceiro[]>([]);
  const [grupos, setGrupos] = useState({ visible: false, categoria: '' });
  const [value, setValue] = useState({ row: null, current: null, value: null });
  const { fisico, loadingFisico, avancos, loadingAvancos, realizados, loadingRealizados, savingFisico, orcamento } =
    useAppSelector((state) => state.fisico);
  useEffect(() => {
    dispatch(getFisico(id));
    dispatch(GetAvancos(id));
    dispatch(getRealizadoMes(id));
  }, [id]);
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Planejamento Físico Financeiro',
        backbutton: true,
        extras: [
          <Permission permission="planejamento.update">
            <Button
              key={0}
              type="primary"
              loading={savingFisico}
              onClick={() =>
                dispatch(
                  salvarFisico({
                    lancamentos: lancamentos.filter((c) => c.planejado > 0),
                    inicial,
                    meses: meses.length,
                    id,
                  })
                )
              }
            >
              Salvar
            </Button>
          </Permission>,
        ],
        subtitle: orcamento,
        menu: 'fisico',
      })
    );
  }, [lancamentos, inicial, meses, id, savingFisico]);
  useMemo(() => {
    if (avancos && realizados) {
      const inicialTemp = inicial;
      const mindate = moment.min([
        ...realizados.map((c) => moment(c.mes)),
        moment(avancos.dataInicio).set('date', 1),
        ...avancos.fisicoFinanceiro.map((c) => moment(c.mes)),
      ]);
      const maxdate = moment.max([
        ...realizados.map((c) => moment(c.mes)),
        ...avancos.fisicoFinanceiro.map((c) => moment(c.mes)),
        moment(inicialTemp.add(avancos.meses, 'month')),
      ]);
      const months = moment(maxdate).diff(mindate, 'month');
      setInicial(moment(mindate));
      setMeses(new Array(months).fill(0));
      setLancamentos(
        avancos.fisicoFinanceiro
          .filter((c) => moment(c.mes) >= mindate && moment(c.mes) <= maxdate)
          .map((c) => ({ ...c, mes: moment(c.mes) }))
      );
    }
  }, [avancos, realizados]);

  useMemo(() => {
    let ls: FisicoFinanceiro[] = [];
    lancamentos.forEach((c) => {
      if (c.categoriaID === value.row?.id && moment(c.mes).format('MM/YYYY') === value?.current) {
        c.planejado = Number(value?.value);
        ls.push(c);
      } else ls.push(c);
    });
    if (
      !lancamentos.some((c) => c.categoriaID === value.row?.id && moment(c.mes).format('MM/YYYY') === value?.current)
    ) {
      ls.push({
        categoriaID: value.row?.id,
        id: 0,
        mes: moment(value?.current, 'MM/YYYY'),
        planejado: Number(value?.value),
        realizado: 0,
      });
    }

    setLancamentos(ls);
  }, [value]);
  const strokeColor = {
    from: '#108ee9',
    to: '#87d068',
  };
  return (
    <div>
      <GruposModal visible={grupos.visible} setVisible={setGrupos} categoria={grupos.categoria} />
      <Space>
        <DatePicker.MonthPicker
          format="MMM/YYYY"
          placeholder="Mês Inicial"
          value={inicial}
          onChange={(v) => setInicial(v)}
        />
        <InputNumber
          min={1}
          precision={0}
          placeholder="Meses de Obra"
          value={meses.length}
          onChange={(v) => setMeses(new Array(v).fill(0))}
        />
      </Space>
      <Table
        size="small"
        className="fisicoform"
        dataSource={[...fisico].sort((a: Fisico, b: Fisico) => naturalSorter(a.descricao, b.descricao))}
        bordered
        pagination={false}
        scroll={{ x: 500 + meses.length * 150, y: 'calc(100vh - 280px)' }}
        rowKey="id"
        loading={loadingFisico || loadingAvancos || loadingRealizados}
        summary={(data) => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} align="right">
                <strong>TOTAL</strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} align="right">
                <div style={{ fontWeight: 'bold' }}>
                  <div>{moneyMask.format(data.reduce((a, b) => a + b.orcado, 0))}</div>
                  <div>100%</div>
                </div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} align="right">
                <strong>
                  <div>
                    <div>AVANÇO FÍSICO</div>
                    <div>CUSTO PLANEJADO</div>
                    <div>REALIZADO</div>
                  </div>
                </strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3} align="right">
                <strong>
                  <div>
                    <div>
                      {doubleMask1.format(
                        lancamentos.reduce(
                          (a, b) => a + (b.planejado as number) * fisico.find((c) => c.id === b.categoriaID)?.orcado,
                          0
                        ) / fisico.reduce((a, b) => a + b.orcado, 0)
                      )}
                    </div>
                    <div>
                      {moneyMask.format(
                        lancamentos.reduce(
                          (a, b) =>
                            a + ((b.planejado as number) * fisico.find((c) => c.id === b.categoriaID)?.orcado) / 100,
                          0
                        )
                      )}
                    </div>
                    <div>{moneyMask.format(realizados.reduce((a, b) => a + b.valor, 0))}</div>
                  </div>
                </strong>
              </Table.Summary.Cell>
              {meses.map((_, index) => {
                const current = moment(inicial).add(index, 'month').format('MM/YYYY');
                return (
                  <Table.Summary.Cell index={4} align="right">
                    <strong>
                      <div>
                        <div>
                          {doubleMask1.format(
                            lancamentos
                              .filter((c) => moment(c.mes).format('MM/YYYY') === current)
                              .reduce(
                                (a, b) =>
                                  a + (b.planejado as number) * fisico.find((c) => c.id === b.categoriaID)?.orcado,
                                0
                              ) / fisico.reduce((a, b) => a + b.orcado, 0)
                          )}
                        </div>
                        <div>
                          {moneyMask.format(
                            lancamentos
                              .filter((c) => moment(c.mes).format('MM/YYYY') === current)
                              .reduce(
                                (a, b) =>
                                  a +
                                  ((b.planejado as number) * fisico.find((c) => c.id === b.categoriaID)?.orcado) / 100,
                                0
                              )
                          )}
                        </div>
                        <div>
                          {moneyMask.format(
                            realizados
                              .filter((c) => moment(c.mes).format('MM/YYYY') === current)
                              .reduce((a, b) => a + b.valor, 0)
                          )}
                        </div>
                      </div>
                    </strong>
                  </Table.Summary.Cell>
                );
              })}
            </Table.Summary.Row>
          </Table.Summary>
        )}
      >
        <Table.Column
          title="Etapa"
          dataIndex="descricao"
          width={200}
          fixed="left"
          render={(v, row: Fisico) => (
            <Button
              type="link"
              onClick={() => {
                dispatch(getCategoriaItens({ categoriaID: row.id, orcamentoID: id }));
                setGrupos({ visible: true, categoria: row.descricao });
              }}
            >
              {v.toUpperCase()}
            </Button>
          )}
        />
        <Table.Column
          title="Orçado"
          dataIndex="orcado"
          fixed="left"
          width={130}
          render={(v) => (
            <div>
              <div>{moneyMask.format(v)}</div>
              <div>{doubleMask1.format((100 * v) / fisico.reduce((a, b) => a + b.orcado, 0))}</div>
            </div>
          )}
          align="right"
        />
        <Table.Column
          align="right"
          fixed="left"
          width={140}
          render={() => (
            <div>
              <div>AVANÇO FÍSICO</div>
              <div>CUSTO PLANEJADO</div>
              <div>REALIZADO</div>
            </div>
          )}
        />
        <Table.Column
          title="Avanço"
          dataIndex="realizado"
          align="right"
          fixed="left"
          width={150}
          render={(v, row: Fisico) => (
            <div>
              <div>
                <Progress
                  strokeColor={strokeColor}
                  percent={lancamentos
                    .filter((c) => c.categoriaID === row.id)
                    .reduce((a, b) => a + (b.planejado as number), 0)}
                  status={
                    lancamentos
                      .filter((c) => c.categoriaID === row.id)
                      .reduce((a, b) => a + (b.planejado as number), 0) > 100
                      ? 'exception'
                      : undefined
                  }
                />
              </div>
              <div>
                {moneyMask.format(
                  (row.orcado *
                    lancamentos
                      .filter((c) => c.categoriaID === row.id)
                      .reduce((a, b) => a + (b.planejado as number), 0)) /
                    100
                )}
              </div>
              <div>{moneyMask.format(v)}</div>
            </div>
          )}
        />

        {meses.map((_, index) => {
          const current = moment(inicial).add(index, 'month').format('MM/YYYY');
          return (
            <Table.Column
              key={index}
              className={current === moment(new Date()).format('MM/YYYY') && 'today'}
              title={current}
              align="right"
              width={150}
              render={(_, row: Fisico) => (
                <div>
                  <div>
                    <Input
                      size="small"
                      onChange={(e) =>
                        setValue({ row, current, value: doubleMask.normalize(doubleMask.format(e.target.value)) })
                      }
                      value={Number(
                        lancamentos?.find(
                          (c) => c.categoriaID === row.id && moment(c.mes).format('MM/YYYY') === current
                        )?.planejado || 0
                      )}
                      suffix={<PercentageOutlined />}
                    />
                  </div>
                  <div>
                    {moneyMask.format(
                      ((lancamentos?.find(
                        (c) => c.categoriaID === row.id && moment(c.mes).format('MM/YYYY') === current
                      )?.planejado as number) *
                        row.orcado) /
                        100 || 0
                    )}
                  </div>
                  <div>
                    {moneyMask.format(
                      realizados
                        ?.filter((c) => c.categoriaID === row.id && moment(c.mes).format('MM/YYYY') === current)
                        ?.reduce((a, b) => a + b.valor, 0) || 0
                    )}
                  </div>
                </div>
              )}
            />
          );
        })}
      </Table>
    </div>
  );
}

export default PlanejamentoObra;
