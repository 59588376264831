import React from 'react';
import { Row, Col } from 'antd';
import { moneyMask } from '@utils/masks';
import moment from 'moment';
import '../../../../../assets/css/Recibo.css';
import { Ordem } from '@models/Ordem';
interface ReciboProps {
  vias: boolean;
  valor: number;
  ordem: Ordem;
  contratante: string;
  cpF_CNPJ: string;
  referente: string;
  endereco: string;
  datarecibo: string;
  nomePrestador: string;
  cpF_CNPJPrestador: string;
  dataPagamento: string;
  pagamento: string;
}
class ReciboToPrint extends React.Component<ReciboProps> {
  render() {
    const { total, empresaFaturamento } = this.props.ordem;
    const vias = this.props.vias;
    const valor = this.props.valor;
    const contratante = this.props.contratante ? this.props.contratante : empresaFaturamento.nome;
    const cpF_CNPJ = this.props.cpF_CNPJ ? this.props.cpF_CNPJ : empresaFaturamento.cpF_CNPJ;
    const referente = this.props.referente
      ? this.props.referente
      : this.props.ordem.ordensItens.map((c) => {
          return c.item;
        });
    const endereco = this.props.endereco ? this.props.endereco : empresaFaturamento.endereco;
    const datarecibo = this.props.datarecibo
      ? this.props.datarecibo
      : this.props.ordem.dataFinanceiro
      ? moment(this.props.ordem.dataFinanceiro).format('DD/MM/YYYY')
      : moment(this.props.ordem.dataAprovacao1).format('DD/MM/YYYY');
    const nomePrestador = this.props.nomePrestador ? this.props.nomePrestador : this.props.ordem.fornecedor.nome;
    const cpF_CNPJPrestador = this.props.cpF_CNPJPrestador
      ? this.props.cpF_CNPJPrestador
      : this.props.ordem.fornecedor.cpF_CNPJ;
    const dataPagamento = this.props.dataPagamento
      ? this.props.dataPagamento
      : this.props.ordem.dataFinanceiro
      ? moment(this.props.ordem.dataFinanceiro).format('DD/MM/YYYY')
      : moment(this.props.ordem.dataAprovacao1).format('DD/MM/YYYY');
    const tipoTransferencia = this.props.pagamento;

    return (
      <Row>
        <Col>
          <div className={'receipt-main'}>
            <p className={'receipt-title'}>Recibo</p>
            <Col span={4} offset={20}>
              <div className="pull-right receipt-section valor">
                <span className="text-large">{valor ? valor : moneyMask.format(total)}</span>
              </div>
            </Col>
            <div className="clearfix"></div>
            <div className="receipt-section">
              <p className="text-main">
                Recebi(emos) da <strong>{contratante}</strong>, pessoa jurídica de direito privado, situada à
                <strong> {' ' + endereco}</strong>, <strong>{empresaFaturamento.cidade}</strong>,{' '}
                <strong>{empresaFaturamento.estado}</strong> inscrita no CNPJ sob nº
                <strong>{' ' + cpF_CNPJ}</strong>, referente à <strong>{referente as string}</strong>.
              </p>
              <p className="text-main">
                Para maior clareza firmo(amos) o presente recibo para que produza os seus efeitos, dando plena, rasa e
                irrevogável quitação, pelo valor recebido.
              </p>
              <p className="text-main">
                O Pagamento foi efetuado através de <strong>{tipoTransferencia}</strong> realizado em{' '}
                <strong>{dataPagamento}</strong>.
              </p>
            </div>
            <div className="receipt-section">
              <text className="pull-right text-large data">Natal, {datarecibo}.</text>
            </div>
            <div className="clearfix"></div>
            <div className="receipt-signature col-xs-6 text-signature">
              <Col span={12} offset={6}>
                <p className="receipt-line "></p>
              </Col>
              <p>
                <strong>{nomePrestador}</strong>
              </p>
              <p>
                <strong>CPF/CNPJ: {cpF_CNPJPrestador}</strong>
              </p>
            </div>
          </div>
        </Col>
        <Col style={!vias ? { display: 'none' } : { display: 'inline', paddingTop: '10px' }}>
          <div className={'receipt-main'}>
            <p className={'receipt-title'}>Recibo</p>
            <Col span={4} offset={20}>
              <div className="pull-right receipt-section valor">
                <span className="text-large">{valor ? valor : moneyMask.format(total)}</span>
              </div>
            </Col>
            <div className="clearfix"></div>
            <div className="receipt-section">
              <p className="text-main">
                Recebi(emos) da <strong>{contratante}</strong>, pessoa jurídica de direito privado, situada à
                <strong> {' ' + endereco}</strong>, <strong>{empresaFaturamento.cidade}</strong>,{' '}
                <strong>{empresaFaturamento.estado}</strong> inscrita no CNPJ sob nº
                <strong>{' ' + cpF_CNPJ}</strong>, referente à <strong>{referente as string}</strong>.
              </p>
              <p className="text-main">
                Para maior clareza firmo(amos) o presente recibo para que produza os seus efeitos, dando plena, rasa e
                irrevogável quitação, pelo valor recebido.
              </p>
              <p className="text-main">
                O Pagamento foi efetuado através de <strong>{tipoTransferencia}</strong> realizado em{' '}
                <strong>{dataPagamento}</strong>.
              </p>
            </div>
            <div className="receipt-section">
              <p className="pull-right text-large data">Natal, {datarecibo}.</p>
            </div>
            <div className="clearfix"></div>
            <div className="receipt-signature col-xs-6 text-signature">
              <Col span={12} offset={6}>
                <p className="receipt-line "></p>
              </Col>
              <p>
                <strong>{nomePrestador}</strong>
              </p>
              <p>
                <strong>CPF/CNPJ: {cpF_CNPJPrestador}</strong>
              </p>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}
export default ReciboToPrint;
