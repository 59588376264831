import { ExtendedAction } from '@config/reduxStore';
import { Permission, Role } from '@models/Role';
import { notification } from 'antd';

interface PerfisReducer {
  perfis: Role[];
  formview: boolean;
  perfil: string;
  loadingperfis: boolean;
  savingperfil: boolean;
  feedback: string;
  permissions: Permission[];
  loadingpermissions: boolean;
}
var initialState: PerfisReducer = {
  perfis: [],
  formview: false,
  perfil: null,
  loadingperfis: false,
  savingperfil: false,
  feedback: '',
  permissions: [],
  loadingpermissions: false,
};

const perfisReducer = function (state = initialState, action: ExtendedAction) {
  switch (action.type) {
    case 'RESET_FEEDBACK':
      state = {
        ...state,
        feedback: '',
      };
      break;
    case 'FORM_PERFIL':
      state = {
        ...state,
        formview: action.payload.open,
        perfil: action.payload.id ? action.payload.id : null,
      };
      break;
    case 'ADD_PERMISSION_TO_ROLE_PENDING':
      state = {
        ...state,
        loadingpermissions: true,
      };
      break;
    case 'ADD_PERMISSION_TO_ROLE_FULFILLED':
      state = {
        ...state,
        loadingpermissions: false,
        perfis: state.perfis.map((c) => {
          if (c.name === action.payload.data.role) {
            if (action.payload.data.removido) {
              c.permissions = c.permissions.filter((r) => r.permission !== action.payload.data.permission);
            } else {
              c.permissions.push({
                permission: action.payload.data.permission,
                id: action.payload.data.permissionid,
              });
            }
            return c;
          } else return c;
        }),
      };
      break;
    case 'GET_PERMISSIONS_PENDING':
      state = {
        ...state,
        loadingpermissions: true,
      };
      break;
    case 'GET_PERMISSIONS_REJECTED':
      state = {
        ...state,
        loadingpermissions: false,
      };
      break;
    case 'GET_PERMISSIONS_FULFILLED':
      state = {
        ...state,
        loadingpermissions: false,
        permissions: action.payload.data,
      };
      break;
    case 'GET_PERFIS_REJECTED':
      state = {
        ...state,
        loadingperfis: false,
      };
      break;
    case 'GET_PERFIS_FULFILLED':
      state = {
        ...state,
        loadingperfis: false,
        perfis: action.payload.data,
      };
      break;
    case 'ADD_PERFIL_PENDING':
      state = {
        ...state,
        savingperfil: true,
        feedback: '',
      };
      break;
    case 'ADD_PERFIL_REJECTED':
      state = {
        ...state,
        savingperfil: false,
        feedback: action.payload.response.data.Message,
      };
      break;
    case 'ADD_PERFIL_FULFILLED':
      state = {
        ...state,
        savingperfil: false,
        feedback: 'Agência criada com sucesso!',
        perfis: action.payload.data,
      };
      break;
    case 'UPDATE_PERFIL_PENDING':
      state = {
        ...state,
        savingperfil: true,
        feedback: '',
      };
      break;
    case 'UPDATE_PERFIL_REJECTED':
      state = {
        ...state,
        savingperfil: false,
        feedback: action.payload.response.data.Message,
      };
      break;
    case 'UPDATE_PERFIL_FULFILLED':
      state = {
        ...state,
        savingperfil: false,
        feedback: 'Agência atualizada com sucesso!',
        perfis: state.perfis.map((c) => {
          if (c.id === action.payload.data.id) return action.payload.data;
          else return c;
        }),
      };
      break;
    case 'DELETE_PERFIL_PENDING':
      state = {
        ...state,
        loadingperfis: true,
        feedback: '',
      };
      break;
    case 'DELETE_PERFIL_REJECTED':
      state = {
        ...state,
        loadingperfis: false,
        feedback: action.payload.response.data.Message,
      };
      notification.warning({ message: state.feedback });
      break;
    case 'DELETE_PERFIL_FULFILLED':
      state = {
        ...state,
        loadingperfis: false,
        feedback: 'Agência atualizada com sucesso!',
        perfis: state.perfis.filter((c) => c.id !== action.payload.data),
      };
      notification.success({ message: state.feedback });
      break;
    default:
      return state;
  }
  return state;
};

export default perfisReducer;
