import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import OrdemPrint2 from './OrdemPrint2';

import { moneyNegMask } from '@utils/masks';
import _ from 'lodash';
import { useAppSelector } from '@config/reduxStore';
import { isValidType } from '@utils/testType';
import { Categoria } from '@models/Categoria';
import { Orcamento } from '@models/Orcamento';
import { GetOrdemEdit } from '@services/api/api.ordem';
import LoadingText from '@components/LoadingText';

function onlyUnique(value: any, index: number, self: any[]) {
  return self.indexOf(value) === index;
}
function OrdemPrint() {
  const { id, contaID } = useParams();
  const { data: ordem, isLoading } = GetOrdemEdit(id, contaID);
  const [categorias, setCategorias] = useState([]);
  const { user } = useAppSelector((state) => state.user);
  useEffect(() => {
    if (ordem !== null) {
      var orcamentos = _.groupBy(ordem?.ordensItens, 'orcamento.descricao');
      setCategorias(
        Object.entries(orcamentos).map((o) => ({
          orcamento: o[0],
          categorias: [...new Set(o[1].map((c) => isValidType<Categoria>(c.categoria) && c.categoria.descricao))].map(
            (c) => ({
              categoria: c,
              total: moneyNegMask.format(
                ordem?.ordensItens
                  .filter((d) => isValidType<Categoria>(d.categoria) && d.categoria.descricao === c)
                  .filter((d) => isValidType<Orcamento>(d.orcamento) && d.orcamento.descricao === o[0])
                  .reduce((a, b) => a + (b.valorTotal as number), 0) +
                  (ordem?.ajusteNF
                    ? Number(ordem.valorAjusteNF) /
                      ordem.ordensItens
                        .map((c) => isValidType<Categoria>(c.categoria) && c.categoria.descricao)
                        .filter(onlyUnique).length
                    : 0) -
                  (ordem?.ordensItens
                    .filter((d) => isValidType<Categoria>(d.categoria) && d.categoria.descricao === c)
                    .filter((d) => isValidType<Orcamento>(d.orcamento) && d.orcamento.descricao === o[0])
                    .reduce((a, b) => a + (b.valorTotal as number), 0) *
                    Number(ordem.desconto)) /
                    Number(ordem.subtotal)
              ),
            })
          ),
        }))
      );
    }
  }, [ordem]);
  if (isLoading) return <LoadingText text="Carregando" />;
  if (ordem && categorias)
    return <OrdemPrint2 ordem={ordem} categorias={categorias} customLogo={user?.configuracao?.logo} />;
  else return null;
}

export default OrdemPrint;
