import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Input, Form, DatePicker, Modal, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { AddGaleria, NovaGaleriaModal } from '@actions/empsActions';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { GaleriaInvestimento } from '@models/RelatorioInvestimento';

function NovaGaleria() {
  const dispatch = useAppDispatch();
  const { novagaleriamodal } = useAppSelector((state) => state.emps);
  const [files, upFile] = useState([]);
  const [formgaleria] = Form.useForm<GaleriaInvestimento>();
  let { id } = useParams();
  const onSubmit = (value: GaleriaInvestimento) => {
    const formdata: FormData = new FormData();
    formdata.append('empreendimentoid', id);
    formdata.append('descricao', value.descricao);
    formdata.append('data', moment(value.data).format('YYYY-MM-DD'));
    value.arquivos.forEach((f: any) => formdata.append('arquivo', f.originFileObj, f.name));
    dispatch(AddGaleria(formdata));
    formgaleria.resetFields();
    dispatch(NovaGaleriaModal());
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  return (
    <Modal
      open={novagaleriamodal}
      title="Adicionar Fotos"
      onCancel={() => dispatch(NovaGaleriaModal())}
      footer={[
        <Button key="1" onClick={() => dispatch(NovaGaleriaModal())}>
          Fechar
        </Button>,
        <Button key="2" type="primary" onClick={() => formgaleria.submit()}>
          Salvar
        </Button>,
      ]}
    >
      <Form form={formgaleria} onFinish={onSubmit} name="galeriaForm" wrapperCol={{ span: 20 }} labelCol={{ span: 4 }}>
        <Form.Item label="Data" name="data" rules={[{ required: true, message: 'Data em branco' }]}>
          <DatePicker format="DD/MM/YYYY" />
        </Form.Item>
        <Form.Item label="Descrição" name="descricao" rules={[{ required: true, message: 'Descrição em branco' }]}>
          <Input placeholder="Descrição" />
        </Form.Item>
        <Form.Item
          label="Imagens"
          name="arquivos"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[{ required: true, message: 'Adicione alguma foto' }]}
        >
          <Upload
            name="logo"
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            listType="picture"
            multiple={true}
            beforeUpload={(_, fileList) => {
              upFile([...files, ...fileList]);
              Promise.resolve(false);
            }}
            accept=".jpg,.jpeg,.png"
            onRemove={(file) => {
              Promise.resolve(false);
              upFile(files.filter((f) => f !== file));
            }}
            fileList={files}
          >
            <Button>
              <UploadOutlined /> Adicionar fotos
            </Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default NovaGaleria;
