import { useEffect, useState } from 'react';
import { Table, Input, Button, Popconfirm } from 'antd';
import { GetProdutos, FormProduto, DeleteProduto } from '@actions/produtosActions';
import ProdutoForm from './ProdutoForm';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { filterobject, getColumnSearchProps, sortAlpha } from '@utils/filterandsort';
import { Permission } from '@hooks//usePermissions';
import { SetPageHeader } from '@actions/userActions';
import { moneyMask } from '@utils/masks';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { ProdutoBancario } from '@models/ProdutoBancario';

function ProdutosView() {
  const dispatch = useAppDispatch();
  const produtos = useAppSelector((state) => state.produtos);
  const [filter, setFilter] = useState('');
  useEffect(() => {
    dispatch(GetProdutos());
    dispatch(
      SetPageHeader({
        title: 'Cadastros',
        backbutton: true,
        extras: [
          <Permission key="1" permission="produto.add">
            <Button onClick={() => dispatch(FormProduto(null, true))} type="primary">
              Novo Produto
            </Button>
          </Permission>,
        ],
        subtitle: 'Produtos Bancários',
        menu: 'produtos',
      })
    );
  }, []);

  return (
    <div>
      <ProdutoForm />
      <div style={{ textAlign: 'right' }}>
        <Input style={{ width: 200 }} onChange={(e) => setFilter(e.target.value)} placeholder="Buscar" allowClear />
      </div>
      <Table
        size="small"
        rowKey="id"
        loading={produtos.loadingprodutos}
        dataSource={filterobject(produtos.produtos, filter)}
        scroll={{ y: 'calc(100vh - 300px)', x: 1024 }}
        pagination={{
          position: ['bottomRight'],
          size: 'small',
          showSizeChanger: true,
          pageSizeOptions: [10, 25, 50, 100],
          showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
        }}
      >
        <Table.Column
          width={80}
          fixed="left"
          render={(_, row: ProdutoBancario) => (
            <div>
              <Permission permission="produto.update">
                <Button icon={<EditOutlined />} onClick={() => dispatch(FormProduto(row.id, true))} size="small" />
              </Permission>
              <Permission permission="produto.delete">
                <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(DeleteProduto(row.id))}>
                  <Button icon={<DeleteOutlined />} size="small" />
                </Popconfirm>
              </Permission>
            </div>
          )}
        />
        <Table.Column
          title="Descrição"
          dataIndex="descricao"
          sorter={(a, b) => sortAlpha(a.descricao, b.descricao)}
          {...getColumnSearchProps('descricao', 'string', 'Descrição')}
        />
        <Table.Column
          title="Pontuação"
          dataIndex="pontuacao"
          sorter={(a, b) => a.pontuacao - b.pontuacao}
          {...getColumnSearchProps('pontuacao', 'string', 'Pontuação')}
        />
        <Table.Column
          title="Remuneração"
          dataIndex="remuneracao"
          sorter={(a, b) => a.remuneracao - b.remuneracao}
          {...getColumnSearchProps('remuneracao', 'string', 'Remuneração')}
          render={(text) => moneyMask.format(text)}
        />
      </Table>
    </div>
  );
}
export default ProdutosView;
