import OrcamentoSelect from '@components/OrcamentoSelect';
import SolicitanteSelect from '@components/SolicitanteSelect';
import { Card, Col, DatePicker, Form, Input, Row } from 'antd';

function PedidoFormGeral() {
  return (
    <Card title="Informações Gerais" size="small">
      <Row gutter={[10, 10]}>
        <Col span={12}>
          <Form.Item label="Descrição" name="descricao" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Input placeholder="Descrição..." />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="compradorID" label="Solicitante" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <SolicitanteSelect placeholder="Solicitante..." />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={18}>
          <Form.Item noStyle shouldUpdate>
            {({ setFieldValue }) => (
              <Form.Item
                label="Orçamento"
                name="orcamentoID"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <OrcamentoSelect placeholder="Orçamento..." onChange={() => setFieldValue('itensPedido', [])} />
              </Form.Item>
            )}
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Prazo" name="prazoEntrega" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <DatePicker format="DD/MM/YYYY" />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
}

export default PedidoFormGeral;
