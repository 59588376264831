import { AppDispatch } from '@config/reduxStore';
import { apiEndpoints } from '@constants/apiEndpoints';
import { Conta } from '@models/Conta';
import { notification } from 'antd';
import { Axios } from '@config/axios';
import { Check } from './userActions';
export const GetContas = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_CONTAS',
      payload: Axios.get(apiEndpoints.CONTAS.GET_CONTAS),
    });
  };
};
export const SetSubscription = function (values: any, paypal: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SET_SUBSCRIPTION_PENDING',
    });
    Axios.get(apiEndpoints.CONTAS.SET_SUBSCRIPTION, { params: values }).then(() => {
      dispatch(Check(paypal));
    });
  };
};
export const GetPlanos = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_PLANOS',
      payload: Axios.get(apiEndpoints.CONTAS.GET_PLANOS),
    });
  };
};
export const GetModulos = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_MODULOS',
      payload: Axios.get(apiEndpoints.CONTAS.GET_MODULOS),
    });
  };
};
export const GetConta = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_CONTA',
      payload: Axios.get(apiEndpoints.CONTAS.GET_CONTA, { params: { id } }),
    });
  };
};
export const CriarConta = function (values: Conta) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'CRIAR_CONTA_PENDING',
    });
    Axios.post(apiEndpoints.CONTAS.CRIAR_CONTA, values)
      .then(() => {
        window.location.href = '/';
      })
      .catch((res) => {
        notification.error({ message: res.response.data });
        dispatch({
          type: 'CRIAR_CONTA_REJECTED',
        });
      });
  };
};
