import { Button, Input, notification, Popconfirm, Space, Table } from 'antd';
import { useEffect, useState } from 'react';
import { DeleteComposicao, GetComposicoes, SetComposicaoForm } from '@actions/composicoesActions';
import { SetPageHeader } from '@actions/userActions';
import { filterobject } from '@utils/filterandsort';
import { Permission } from '@hooks//usePermissions';
import { doubleMaskPrecision, moneyMask } from '@utils/masks';
import ComposicaoForm from './ComposicaoForm';
import { EditOutlined, DeleteOutlined, FileExcelOutlined } from '@ant-design/icons';
import { Axios } from '@config/axios';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Composicao } from '@models/Composicao';

function ComposicoesView() {
  const dispatch = useAppDispatch();
  const { composicoes, loadingcomposicoes } = useAppSelector((state) => state.composicoes);
  const [filter, SetFilter] = useState(null);
  const [expandedRow, SetExpandedRow] = useState<any>([]);
  const [downloading, setDownloading] = useState<boolean>(false);
  useEffect(() => {
    dispatch(GetComposicoes());
    dispatch(
      SetPageHeader({
        title: 'Engenharia',
        backbutton: true,
        extras: [
          <Button
            key="2"
            icon={<FileExcelOutlined />}
            loading={downloading}
            onClick={() => {
              setDownloading(true);
              Axios.post('/api/v2/Composicoes/ExportarComposicoes', composicoes, {
                responseType: 'blob',
              })
                .then((res) => {
                  setDownloading(false);
                  const url = window.URL.createObjectURL(new Blob([res.data]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', `Composicoes_${moment(new Date()).format('DD_MM_YYYY HH:mm')}.xlsx`);
                  document.body.appendChild(link);
                  link.click();
                })
                .catch(() => {
                  notification.error({ message: 'Erro ao gerar arquivo!' });
                  setDownloading(false);
                });
            }}
          >
            Exportar Composições
          </Button>,
          <Permission key="1" permission="orcamento.add">
            <Button onClick={() => dispatch(SetComposicaoForm(true, null))} type="primary">
              Nova Composição
            </Button>
          </Permission>,
        ],
        subtitle: 'Composições',
        menu: 'composicoes',
      })
    );
  }, []);
  return (
    <div>
      <ComposicaoForm />
      <div style={{ textAlign: 'right' }}>
        <Input
          style={{ width: 300 }}
          value={filter}
          onChange={(e) => SetFilter(e.target.value)}
          placeholder="Buscar"
          allowClear
        />
      </div>
      <Table
        size="small"
        loading={loadingcomposicoes}
        dataSource={filterobject(composicoes, filter)}
        rowKey="id"
        pagination={{
          position: ['bottomRight'],
          size: 'small',
          showSizeChanger: true,
          pageSizeOptions: [10, 25, 50, 100],
          showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
        }}
        expandable={{
          onExpandedRowsChange: (expandedKeys) => SetExpandedRow(expandedKeys),
          expandedRowKeys: expandedRow,
          expandedRowRender: (row) => (
            <Table dataSource={row.itensComposicao} size="small" pagination={false}>
              <Table.Column
                title="Item"
                dataIndex="descricao"
                render={(_, row: Composicao) => `${row.unidade} - ${row.descricao}`}
              />
              <Table.Column title="Tipo" dataIndex="tipo" render={(v) => (v == 0 ? 'Insumo' : 'Serviço')} />
              <Table.Column title="Valor Unitário" dataIndex="valorUnitario" render={(v) => moneyMask.format(v)} />
              <Table.Column title="Quantidade" dataIndex="quantidade" render={(v) => doubleMaskPrecision.format(v)} />
              <Table.Column title="Valor Total" dataIndex="valorTotal" render={(v) => moneyMask.format(v)} />
            </Table>
          ),
        }}
      >
        <Table.Column
          dataIndex="id"
          render={(id, row: Composicao) => (
            <Space>
              <Button size="small" icon={<EditOutlined />} onClick={() => dispatch(SetComposicaoForm(true, row))} />
              <Popconfirm onConfirm={() => dispatch(DeleteComposicao(id))} title="Está certo disso?">
                <Button size="small" icon={<DeleteOutlined />} />
              </Popconfirm>
            </Space>
          )}
        />
        <Table.Column title="Código" dataIndex="id" />
        <Table.Column title="Descrição" dataIndex="descricao" />
        <Table.Column title="Unidade" dataIndex="unidade" />
        <Table.Column title="Valor Serviços" dataIndex="valorServicos" render={(v) => moneyMask.format(v)} />
        <Table.Column title="Valor Insumos" dataIndex="valorInsumos" render={(v) => moneyMask.format(v)} />
        <Table.Column title="Valor Total" dataIndex="valorTotal" render={(v) => moneyMask.format(v)} />
      </Table>
    </div>
  );
}

export default ComposicoesView;
