import { Axios } from '@config/axios';
import { Permission } from '@hooks/usePermissions';
import PDFView from '@views/Sistema/PDFView/PDFView';
import { Button, Card, Popconfirm, Table, Upload } from 'antd';
import { UploadOutlined, DeleteOutlined, EyeOutlined, DownloadOutlined } from '@ant-design/icons';
import { CompilarArquivosOrdem, DeleteFile, DownloadFile, UploadFile } from '@services/api/api.ordem';
import { useState } from 'react';
import { ArquivoOrdem } from '@models/ArquivoOrdem';
import { useParams } from 'react-router-dom';
import { GetTransferencia } from '@services/api/api.transferencia';

function TransferenciaArquivos() {
  const { id } = useParams();
  const { data: ordem, isLoading } = GetTransferencia(id);
  const uploadFile = UploadFile();
  const deleteFile = DeleteFile();
  const downloadFile = DownloadFile();
  const [files, upFile] = useState([]);
  const [viewPDF, setViewPDF] = useState(false);
  const [fileView, setFileView] = useState({ fileType: '', url: '' });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const compilar = CompilarArquivosOrdem();
  const rowSelection = {
    onChange: (selectedRowKeys: any) => {
      setSelectedFiles(selectedRowKeys);
    },
    selectedRowKeys: selectedFiles,
  };
  return (
    <Card title="Arquivos" size="small" style={{ marginTop: 10 }}>
      <Permission permission="ordem.file.upload">
        <Upload
          name="arquivo"
          disabled={uploadFile.isLoading}
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          multiple={true}
          beforeUpload={(_, fileList) => {
            upFile([...files, ...fileList]);
            Promise.resolve(false);
          }}
          accept=".pdf,.jpg,.jpeg,.png,.xls,.xlsx"
          onRemove={(file) => {
            Promise.resolve(false);
            upFile(files.filter((f) => f !== file));
          }}
          fileList={files}
        >
          <Button icon={<UploadOutlined />} size="small">
            Escolher Arquivos
          </Button>
        </Upload>
        {files.length > 0 && (
          <Button
            type="primary"
            size="small"
            loading={uploadFile.isLoading}
            onClick={() => {
              const formdata = new FormData();
              files.map((f) => formdata.append('files', f, f.name));
              formdata.append('ordemid', ordem.id.toString());
              uploadFile.mutate({ data: formdata, ordemID: ordem.id, cod: ordem.cod });
              upFile([]);
            }}
          >
            Enviar
          </Button>
        )}
        {selectedFiles.length > 0 && (
          <Button
            size="small"
            type="primary"
            loading={compilar.isLoading}
            onClick={() => compilar.mutate({ ordemid: ordem.id, arquivosIds: selectedFiles })}
          >
            Compilar
          </Button>
        )}
      </Permission>
      <PDFView visible={viewPDF} setVisible={setViewPDF} url={fileView.url} fileType={fileView.fileType} />
      <Table
        size="small"
        rowKey="id"
        scroll={{ y: 400 }}
        loading={isLoading || uploadFile.isLoading || deleteFile.isLoading || downloadFile.isLoading}
        dataSource={ordem?.arquivosOrdem}
        pagination={false}
        rowSelection={rowSelection}
      >
        <Table.Column
          render={(_, row: ArquivoOrdem) => (
            <div>
              <Permission permission="ordem.file.download">
                <Button
                  onClick={() => downloadFile.mutate({ fileid: row.id, filename: row.fileName })}
                  icon={<DownloadOutlined />}
                  size="small"
                />
              </Permission>
              <Permission permission="ordem.file.delete">
                <Popconfirm
                  title="Está certo disso?"
                  onConfirm={() => deleteFile.mutate({ ordemID: ordem.id, fileID: row.id, cod: ordem.cod })}
                >
                  <Button icon={<DeleteOutlined />} size="small" />
                </Popconfirm>
              </Permission>
              <Button
                icon={<EyeOutlined />}
                size="small"
                onClick={() => {
                  Axios.get('/api/v2/Ordens/DownloadLink', { params: { fileid: row.id } }).then((res) => {
                    setFileView({
                      url: res.data,
                      fileType: row.contentType,
                    });
                    setViewPDF(true);
                  });
                }}
              />
            </div>
          )}
        />
        <Table.Column title="Nome do Arquivo" dataIndex="fileName" />
      </Table>
    </Card>
  );
}

export default TransferenciaArquivos;
