import { useEffect } from 'react';
import { Modal, Form, Input, Button } from 'antd';
import { SalvarCategoria, FormCategoria } from '@actions/categoriasActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Categoria } from '@models/Categoria';

function CategoriaForm() {
  const dispatch = useAppDispatch();
  const categorias = useAppSelector((state) => state.categorias);
  const [form] = Form.useForm<Categoria>();
  const submitForm = (values: Categoria) => {
    dispatch(SalvarCategoria(values));
  };
  useEffect(() => {
    if (categorias.categoria) {
      form.resetFields();
      form.setFieldsValue(categorias.categorias.find((c) => c.id === categorias.categoria));
    } else form.resetFields();
  }, [categorias.categoria, categorias.categorias, form]);
  return (
    <div>
      <Modal
        open={categorias.formview}
        onCancel={() => dispatch(FormCategoria(null, false))}
        title="Cadastro Categoria"
        footer={[
          <Button onClick={() => dispatch(FormCategoria(null, false))} key="2">
            Fechar
          </Button>,
          <Button key="3" type="primary" onClick={() => form.submit()} loading={categorias.savingcategoria}>
            Salvar
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form} onFinish={submitForm} name="categoriaForm" initialValues={{ tipo: 0 }}>
          <Form.Item style={{ display: 'none' }} name="id">
            <Input />
          </Form.Item>
          <Form.Item label="Descrição" name="descricao" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
export default CategoriaForm;
