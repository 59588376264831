import { Button, Popconfirm, Space, Table, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { naturalSorter } from '@utils/filterandsort';
import { doubleMask, doubleMask1, moneyMask } from '@utils/masks';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import '../../../../assets/scss/OrcamentoForm.scss';
import { DeleteItem } from '@actions/orcamentosActions';
import OrcamentoItemForm from './OrcamentoItemForm';
import { EditarGrupoNome } from '@actions/gruposActions';
import { OrcamentoItem } from '@models/OrcamentoItem';
import { useAppDispatch } from '@config/reduxStore';
import { Orcamento } from '@models/Orcamento';

function OrcamentoTableForm({ orcamentosItens }: { orcamentosItens: OrcamentoItem[] }) {
  const [data, setData] = useState<any[]>([]);
  const [edititemmodal, setedititemmodal] = useState<boolean>(false);
  const [itemEdit, setItemEdit] = useState<OrcamentoItem>(null);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const total = orcamentosItens.reduce((a, b) => a + b.valorTotal, 0);
    setData(
      [...new Set(orcamentosItens.map((c) => c.categoriaID))]
        .map((c) => ({
          id: 'c' + c,
          nivel: 1,
          descricao: orcamentosItens.find((d) => d.categoriaID === c)?.categoria,
          unidade: '',
          valorUnitario: null,
          quantidade: null,
          peso: orcamentosItens.filter((d) => d.categoriaID === c).reduce((a, b) => a + b.valorTotal, 0) / total,
          valorTotalInsumo: orcamentosItens
            .filter((d) => d.categoriaID === c && d.itemTipo === 0)
            .reduce((a, b) => a + b.valorTotal, 0),
          valorTotalServico: orcamentosItens
            .filter((d) => d.categoriaID === c && d.itemTipo === 1)
            .reduce((a, b) => a + b.valorTotal, 0),
          children: [...new Set(orcamentosItens.filter((g) => g.categoriaID === c).map((g) => g.grupoID))]
            .map((g) => ({
              id: 'g' + g,
              nivel: 2,
              categoriaID: c,
              descricao: orcamentosItens.find((d) => d.grupoID === g)?.grupo,
              unidade: '',
              valorUnitario: null,
              quantidade: null,
              peso: orcamentosItens.filter((d) => d.grupoID === g).reduce((a, b) => a + b.valorTotal, 0) / total,
              valorTotalInsumo: orcamentosItens
                .filter((d) => d.grupoID === g && d.itemTipo === 0)
                .reduce((a, b) => a + b.valorTotal, 0),
              valorTotalServico: orcamentosItens
                .filter((d) => d.grupoID === g && d.itemTipo === 1)
                .reduce((a, b) => a + b.valorTotal, 0),
              children: orcamentosItens
                .filter((oi) => oi.grupoID === g && oi.categoriaID === c)
                .map((oi) => ({
                  id: oi.id,
                  nivel: 3,
                  descricao: oi.item,
                  unidade: oi.unidade,
                  peso: oi.valorTotal / total,
                  valorUnitario: oi.valorUnitario,
                  quantidade: oi.quantidade,
                  valorTotalInsumo: oi.itemTipo === 0 ? oi.valorTotal : 0,
                  valorTotalServico: oi.itemTipo === 1 ? oi.valorTotal : 0,
                }))
                .sort((a, b) => naturalSorter(a.descricao as string, b.descricao as string)),
            }))
            .sort((a, b) => naturalSorter(a.descricao as string, b.descricao as string)),
        }))
        .sort((a: any, b: any) => naturalSorter(a.descricao, b.descricao))
    );
  }, [orcamentosItens]);
  return (
    <div>
      <OrcamentoItemForm visible={edititemmodal} setVisible={setedititemmodal} item={itemEdit} />
      <Table
        className="orcamentoform"
        size="small"
        pagination={false}
        dataSource={data}
        rowKey="id"
        scroll={{ x: 1000, y: 'calc(100vh - 210px)' }}
        rowClassName={(row) => `nivel${row.nivel}`}
        summary={(data) => (
          <Table.Summary fixed>
            <Table.Summary.Row style={{ fontWeight: 'bold' }}>
              <Table.Summary.Cell index={0} colSpan={5} align="right">
                TOTAL
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} align="right">
                {moneyMask.format(data.reduce((a, b) => a + b.valorTotalInsumo, 0))}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} align="right">
                {moneyMask.format(data.reduce((a, b) => a + b.valorTotalServico, 0))}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3} align="right">
                {doubleMask1.format(data.reduce((a, b) => a + b.peso, 0) * 100)}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      >
        <Table.Column
          dataIndex="id"
          width={120}
          render={(v, row: Orcamento) =>
            row.nivel === 3 && (
              <Space>
                <Popconfirm
                  title="Está certo disso?"
                  onConfirm={() => {
                    dispatch(DeleteItem(v));
                  }}
                >
                  <Button size="small" icon={<DeleteOutlined />} />
                </Popconfirm>
                <Button
                  size="small"
                  icon={<EditOutlined />}
                  onClick={() => {
                    setItemEdit(orcamentosItens.find((c) => c.id === v));
                    setedititemmodal(true);
                  }}
                />
              </Space>
            )
          }
        />
        <Table.Column
          title="Descrição"
          dataIndex="descricao"
          width={400}
          render={(v, row: Orcamento) =>
            row.nivel === 2 ? (
              <Typography.Text
                editable={{
                  onChange: (v) => dispatch(EditarGrupoNome(Number(row.id.toString().slice(1)), v)),
                }}
              >
                {v}
              </Typography.Text>
            ) : (
              v
            )
          }
        />
        <Table.Column title="Unidade" dataIndex="unidade" />
        <Table.Column
          title="V. Unitário"
          dataIndex="valorUnitario"
          align="right"
          render={(v, row: Orcamento) => row.nivel === 3 && moneyMask.format(v)}
        />
        <Table.Column
          title="Quantidade"
          dataIndex="quantidade"
          align="right"
          render={(v, row: Orcamento) => row.nivel === 3 && doubleMask.format(v)}
        />
        <Table.Column
          width={100}
          title="Total (Insumo)"
          dataIndex="valorTotalInsumo"
          align="right"
          render={(v) => moneyMask.format(v)}
        />
        <Table.Column
          width={100}
          title="Total (Serviço)"
          dataIndex="valorTotalServico"
          align="right"
          render={(v) => moneyMask.format(v)}
        />
        <Table.Column title="Peso" dataIndex="peso" align="right" render={(v) => doubleMask1.format(v * 100)} />
      </Table>
    </div>
  );
}

export default OrcamentoTableForm;
