import { useEffect } from 'react';
import { Table, Button, Input, Form, DatePicker, Modal, Upload, Popconfirm } from 'antd';
import { DeleteOutlined, UploadOutlined, EyeOutlined } from '@ant-design/icons';
import { AddGaleria, GetGaleria, DeleteGaleria, OpenGaleria } from '@actions/galeriaActions';
import { FormGaleria } from '@actions/casasActions';
import moment from 'moment';
import GalleryModal from './GalleryModal';
import { Axios } from '@config/axios';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Galeria } from '@models/Galeria';
import { ArquivoGaleria } from '@models/RelatorioInvestimento';

function AddGaleriaModal(props: { id: number }) {
  const dispatch = useAppDispatch();
  const galeria = useAppSelector((state) => state.galeria);
  const { galeriaformview, fichaImovel } = useAppSelector((state) => state.casas);

  const [formgaleria] = Form.useForm();
  let { id } = props;
  const onSubmit = (value: Galeria) => {
    const formdata = new FormData();
    formdata.append('casaid', id.toString());
    formdata.append('empreedimentoid', fichaImovel.empreendimentoID.toString());
    formdata.append('titulo', value.titulo);
    formdata.append('data', moment(value.data).format('YYYY-MM-DD'));
    const files = value.arquivos as ArquivoGaleria[];
    files.map((f, index) => formdata.append('arquivo' + index, f.originFileObj));
    dispatch(AddGaleria(formdata));
    formgaleria.resetFields();
  };
  useEffect(() => {
    dispatch(GetGaleria(id));
  }, [id]);

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  return (
    <div>
      <Modal
        open={galeriaformview}
        title="Adicionar Fotos"
        onCancel={() => dispatch(FormGaleria(null, false))}
        footer={[
          <Button key="1" onClick={() => dispatch(FormGaleria(null, false))}>
            Fechar
          </Button>,
          <Button loading={galeria.galeriasaving} key="2" type="primary" onClick={() => formgaleria.submit()}>
            Adicionar
          </Button>,
        ]}
      >
        <Form
          form={formgaleria}
          onFinish={onSubmit}
          name="galeriaForm"
          wrapperCol={{ span: 20 }}
          labelCol={{ span: 4 }}
        >
          <Form.Item label="Titulo" name="titulo" rules={[{ required: true, message: 'Titulo em branco' }]}>
            <Input placeholder="Titulo" />
          </Form.Item>
          <Form.Item name="data" label="Data" initialValue={moment(new Date())}>
            <DatePicker format="DD/MM/YYYY" />
          </Form.Item>
          <Form.Item
            label="Imagens"
            name="arquivos"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[{ required: true, message: 'Adicione alguma foto' }]}
          >
            <Upload
              name="logo"
              accept=".jpg,.jpeg,.png"
              customRequest={(options) => {
                const formdata = new FormData();
                formdata.append('arquivo', options.file);
                Axios.post('/api/v2/Galeria/PostFile', formdata, {
                  headers: {
                    'content-type': 'multipart/form-data',
                  },
                }).then((res) => options.onSuccess(res.data));
              }}
              listType="picture"
              multiple={true}
            >
              <Button>
                <UploadOutlined /> Adicionar fotos
              </Button>
            </Upload>
          </Form.Item>
        </Form>
        <Table
          pagination={false}
          loading={galeria.loading}
          size="small"
          rowKey="id"
          dataSource={galeria.items}
          scroll={{ y: 400 }}
        >
          <Table.Column
            render={(_, row: Galeria) => (
              <div>
                <Popconfirm
                  title="Tem certeza que deseja excluir essa galeria?"
                  onConfirm={() => dispatch(DeleteGaleria(row.id))}
                  okText="Sim"
                  cancelText="Não"
                >
                  <Button size="small" icon={<DeleteOutlined />} />
                </Popconfirm>
                <Button size="small" icon={<EyeOutlined />} onClick={() => dispatch(OpenGaleria(row.id))} />
              </div>
            )}
          />
          <Table.Column title="Item" render={(_, __, index) => index + 1} />
          <Table.Column title="Titulo" dataIndex="titulo" />
          <Table.Column title="Data" dataIndex="data" render={(text) => moment(text).format('DD/MM/YYYY')} />
          <Table.Column title="Fotos" render={(_, row: Galeria) => row.fotos.length} />
        </Table>
        <GalleryModal />
      </Modal>
    </div>
  );
}

export default AddGaleriaModal;
