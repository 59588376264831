import { CloseGaleria } from '@actions/empsActions';
import { Button, Carousel, Modal } from 'antd';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

function GalleryModal() {
  const dispatch = useAppDispatch();
  const { galeriamodal, galeriaid, empreendimento } = useAppSelector((state) => state.emps);
  const images = galeriaid
    ? (empreendimento.galerias.find((c) => c.id === galeriaid).arquivos.map((c) => c) as string[])
    : [];

  return (
    <div>
      {galeriamodal && (
        <Modal
          open={true}
          onCancel={() => dispatch(CloseGaleria())}
          width="95%"
          footer={[
            <Button key={0} onClick={() => dispatch(CloseGaleria())}>
              Fechar
            </Button>,
          ]}
        >
          <Carousel>
            {images.map((i) => (
              <div key={i}>
                <img
                  alt={i}
                  src={i}
                  style={{
                    height: 'calc(100vh - 200px)',
                    textAlign: 'center',
                    margin: 'auto',
                  }}
                />
              </div>
            ))}
          </Carousel>
        </Modal>
      )}
    </div>
  );
}

export default GalleryModal;
