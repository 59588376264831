import { apiEndpoints } from '@constants/apiEndpoints';
import { EmpresaFaturamento } from '@models/EmpresaFaturamento';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { fetcher } from './api';

export const GetEmpresasFaturamentoList = () => {
  return useQuery<EmpresaFaturamento[], AxiosError<any>>(
    ['empresasFaturamentoList'],
    () => fetcher<EmpresaFaturamento[]>(apiEndpoints.EMPRESAS_FATURAMENTO.GET_EMPRESAS),
    {
      initialData: [],
      staleTime: 0,
      onError: (err) => console.log(err.response?.data?.Message),
    }
  );
};
