import { Ordem } from './Ordem';

export enum FormaPagamento {
  Cartao = 0,
  Transferencia = 1,
  Boleto = 2,
  Outro = 3,
}
export const GetFormaPagamento = (forma: number) => {
  if (forma === FormaPagamento.Cartao) return 'Cartão';
  else if (forma === FormaPagamento.Transferencia) return 'Transferência';
  else if (forma === FormaPagamento.Boleto) return 'Boleto';
  else return 'Outro';
};

export interface PagamentoOrdem {
  id: number;
  data: Date;
  valor: number;
  formaPagamento: FormaPagamento;
  ordem: Ordem;
  ordemID: number;
}
