import { ExtendedAction } from '@config/reduxStore';
import { Empreendimento } from '@models/Empreendimento';
import { Investidor } from '@models/Investidor';
import { notification } from 'antd';

interface EmpreendimentoReducer {
  emps: Empreendimento[];
  loadingemps: boolean;
  formview: boolean;
  emp: number;
  savingemp: boolean;
  empreendimento: Empreendimento;
  loadingempreendimento: boolean;
  loadingprogressoobra: boolean;
  loadingprogressolegalizacao: boolean;
  addingprocessoobra: boolean;
  addingprocessolegalizacao: boolean;
  novagaleriamodal: boolean;
  savinggaleria: boolean;
  deletinggaleria: boolean;
  galeriaid: number;
  galeriamodal: boolean;
  cadastroinvestimentomodal: boolean;
  investidores: Investidor[];
  savinginvestimento: boolean;
  feedback: string;
  investimento: number;
  lancamentosmodal: boolean;
  addinglancamento: boolean;
  deletinglancamento: boolean;
  investidoresmodal: boolean;
  empinvestidores: number;
  loadinginvestidores: boolean;
  investidoresfull: Investidor[];
}
var initialState: EmpreendimentoReducer = {
  emps: [],
  loadingemps: false,
  formview: false,
  emp: null,
  savingemp: false,
  empreendimento: null,
  loadingempreendimento: false,
  loadingprogressoobra: false,
  loadingprogressolegalizacao: false,
  addingprocessoobra: false,
  addingprocessolegalizacao: false,
  novagaleriamodal: false,
  savinggaleria: false,
  deletinggaleria: false,
  galeriaid: null,
  galeriamodal: false,
  cadastroinvestimentomodal: false,
  investidores: [],
  savinginvestimento: false,
  feedback: '',
  investimento: null,
  lancamentosmodal: false,
  addinglancamento: false,
  deletinglancamento: false,
  investidoresmodal: false,
  empinvestidores: null,
  loadinginvestidores: false,
  investidoresfull: [],
};

const empreendimentosReducer = function (state = initialState, action: ExtendedAction) {
  switch (action.type) {
    case 'GET_INVESTIDORES_FULL_PENDING':
      state = {
        ...state,
        loadinginvestidores: true,
        investidores: initialState.investidores,
      };
      break;
    case 'GET_INVESTIDORES_FULL_REJECTED':
      state = {
        ...state,
        loadinginvestidores: false,
      };
      break;
    case 'GET_INVESTIDORES_FULL_FULFILLED':
      state = {
        ...state,
        loadinginvestidores: false,
        investidores: action.payload.data,
      };
      break;
    case 'LOAD_INVESTIMENTO':
      state = {
        ...state,
        investimento: action.payload,
        cadastroinvestimentomodal: true,
      };
      break;
    case 'LOAD_INVESTIDORES_MODAL':
      state = {
        ...state,
        investidoresmodal: !state.investidoresmodal,
        empinvestidores: action.payload,
      };
      break;
    case 'CLOSE_INVESTIDORES_MODAL':
      state = {
        ...state,
        investidoresmodal: !state.investidoresmodal,
        empinvestidores: null,
      };
      break;
    case 'LOAD_LANCAMENTOS_MODAL':
      state = {
        ...state,
        lancamentosmodal: !state.lancamentosmodal,
        investimento: action.payload,
      };
      break;
    case 'CLOSE_LANCAMENTOS_MODAL':
      state = {
        ...state,
        lancamentosmodal: !state.lancamentosmodal,
        investimento: null,
      };
      break;
    case 'DELETE_LANCAMENTO_PENDING':
      state = {
        ...state,
        deletinglancamento: true,
      };
      break;
    case 'DELETE_LANCAMENTO_REJECTED':
      state = {
        ...state,
        deletinglancamento: false,
      };
      break;
    case 'DELETE_LANCAMENTO_FULFILLED':
      state = {
        ...state,
        deletinglancamento: false,
        empreendimento: {
          ...state.empreendimento,
          investimentos: state.empreendimento.investimentos.map((i) => {
            if (i.id === action.payload.data.investimentoID) {
              i.lancamentosInvestimento = i.lancamentosInvestimento.filter((c) => c.id !== action.payload.data.id);
              return i;
            } else return i;
          }),
        },
      };
      notification.success({ message: 'Lançamento excluído com sucesso' });
      break;
    case 'ADD_LANCAMENTO_PENDING':
      state = {
        ...state,
        addinglancamento: true,
      };
      break;
    case 'ADD_LANCAMENTO_REJECTED':
      state = {
        ...state,
        addinglancamento: false,
      };
      break;
    case 'ADD_LANCAMENTO_FULFILLED':
      state = {
        ...state,
        addinglancamento: false,
        empreendimento: {
          ...state.empreendimento,
          investimentos: state.empreendimento.investimentos.map((i) => {
            if (i.id === action.payload.data.investimentoID) {
              i.lancamentosInvestimento = [...i.lancamentosInvestimento, action.payload.data];
              return i;
            } else return i;
          }),
        },
      };
      break;
    case 'SALVAR_INVESTIMENTO_PENDING':
      state = {
        ...state,
        feedback: '',
        savinginvestimento: true,
      };
      break;
    case 'SALVAR_INVESTIMENTO_REJECTED':
      state = {
        ...state,
        savinginvestimento: false,
      };
      notification.error({ message: 'Erro ao salvar investimento' });
      break;
    case 'SALVAR_INVESTIMENTO_FULFILLED':
      state = {
        ...state,
        savinginvestimento: false,
        feedback: 'Investimento atualizado com sucesso! ',
        empreendimento: {
          ...state.empreendimento,
          investimentos: state.empreendimento.investimentos.map((c) => {
            if (c.id === action.payload.data.id) return action.payload.data;
            else return c;
          }),
        },
      };
      notification.success({ message: 'Investimento salvo com sucesso' });
      break;
    case 'ADD_INVESTIMENTO_PENDING':
      state = {
        ...state,
        savinginvestimento: true,
        feedback: '',
      };
      break;
    case 'ADD_INVESTIMENTO_REJECTED':
      state = {
        ...state,
        savinginvestimento: false,
      };
      notification.error({ message: 'Erro ao cadastrar investimento!' });
      break;
    case 'ADD_INVESTIMENTO_FULFILLED':
      state = {
        ...state,
        savinginvestimento: false,
        feedback: 'Investimento criado com sucesso! ',
        empreendimento: {
          ...state.empreendimento,
          investimentos: [...state.empreendimento.investimentos, action.payload.data],
        },
      };
      notification.success({ message: 'Investimento cadastrado com sucesso!' });
      break;
    case 'GET_INVESTIDORES_FULFILLED':
      state = {
        ...state,
        investidores: action.payload.data,
      };
      break;
    case 'CADASTRO_INVESTIMENTO_MODAL':
      state = {
        ...state,
        cadastroinvestimentomodal: !state.cadastroinvestimentomodal,
        investimento: null,
      };
      break;
    case 'RESET_FEEDBACK':
      state = {
        ...state,
        feedback: '',
      };
      break;
    case 'OPEN_GALERIA':
      state = {
        ...state,
        galeriaid: action.payload,
        galeriamodal: true,
      };
      break;
    case 'CLOSE_GALERIA':
      state = {
        ...state,
        galeriaid: null,
        galeriamodal: false,
      };
      break;
    case 'NOVA_GALERIA_MODAL':
      state = {
        ...state,
        novagaleriamodal: !state.novagaleriamodal,
      };
      break;
    case 'FORM_EMP':
      state = {
        ...state,
        formview: action.payload.open,
        emp: action.payload.id ? action.payload.id : null,
      };
      break;
    case 'DELETE_GALERIA_PENDING':
      state = {
        ...state,
        deletinggaleria: true,
      };
      break;
    case 'DELETE_GALERIA_REJECTED':
      state = {
        ...state,
        deletinggaleria: false,
      };
      break;
    case 'DELETE_GALERIA_FULFILLED':
      state = {
        ...state,
        deletinggaleria: false,
        empreendimento: {
          ...state.empreendimento,
          galerias: state.empreendimento.galerias.filter((c) => c.id !== action.payload.data),
        },
      };
      notification.success({ message: 'Galeria excluída com sucesso!' });
      break;
    case 'ADD_GALERIA_PENDING':
      state = {
        ...state,
        savinggaleria: true,
      };
      break;
    case 'ADD_GALERIA_REJECTED':
      state = {
        ...state,
        savinggaleria: false,
      };
      break;
    case 'ADD_GALERIA_FULFILLED':
      state = {
        ...state,
        savinggaleria: false,
        empreendimento: {
          ...state.empreendimento,
          galerias: [...state.empreendimento.galerias, action.payload.data],
        },
      };
      notification.success({ message: 'Galeria adicionada com sucesso!' });
      break;
    case 'ADD_PROCESSO_LEGALIZACAO_PENDING':
      state = {
        ...state,
        addingprocessolegalizacao: true,
      };
      break;
    case 'ADD_PROCESSO_LEGALIZACAO_REJECTED':
      state = {
        ...state,
        addingprocessolegalizacao: false,
      };
      break;
    case 'ADD_PROCESSO_LEGALIZACAO_FULFILLED':
      state = {
        ...state,
        addingprocessolegalizacao: false,
        empreendimento: {
          ...state.empreendimento,
          processosLegalizacao: [...state.empreendimento.processosLegalizacao, action.payload.data],
        },
      };
      break;
    case 'ADD_PROCESSO_OBRA_PENDING':
      state = {
        ...state,
        addingprocessoobra: true,
      };
      break;
    case 'ADD_PROCESSO_OBRA_REJECTED':
      state = {
        ...state,
        addingprocessoobra: false,
      };
      break;
    case 'ADD_PROCESSO_OBRA_FULFILLED':
      state = {
        ...state,
        addingprocessoobra: false,
        empreendimento: {
          ...state.empreendimento,
          processosObra: [...state.empreendimento.processosObra, action.payload.data],
        },
      };
      break;
    case 'STEP_PROCESSO_LEGALIZACAO_PENDING':
      state = {
        ...state,
        loadingprogressolegalizacao: true,
      };
      break;
    case 'STEP_PROCESSO_LEGALIZACAO_REJECTED':
      state = {
        ...state,
        loadingprogressolegalizacao: false,
      };
      break;
    case 'STEP_PROCESSO_LEGALIZACAO_FULFILLED':
      state = {
        ...state,
        loadingprogressolegalizacao: false,
        empreendimento: action.payload.data,
      };
      break;
    case 'DELETE_PROCESSO_LEGALIZACAO_PENDING':
      state = {
        ...state,
        loadingprogressolegalizacao: true,
      };
      break;
    case 'DELETE_PROCESSO_LEGALIZACAO_REJECTED':
      state = {
        ...state,
        loadingprogressolegalizacao: false,
      };
      break;
    case 'DELETE_PROCESSO_LEGALIZACAO_FULFILLED':
      state = {
        ...state,
        loadingprogressolegalizacao: false,
        empreendimento: {
          ...state.empreendimento,
          processosLegalizacao: state.empreendimento.processosLegalizacao.filter((c) => c.id !== action.payload.data),
        },
      };
      break;
    case 'CHANGE_PROGRESSO_LEGALIZACAO_PENDING':
      state = {
        ...state,
        loadingprogressolegalizacao: true,
      };
      break;
    case 'CHANGE_PROGRESSO_LEGALIZACAO_REJECTED':
      state = {
        ...state,
        loadingprogressolegalizacao: false,
      };
      break;
    case 'CHANGE_PROGRESSO_LEGALIZACAO_FULFILLED':
      state = {
        ...state,
        loadingprogressolegalizacao: false,
        empreendimento: {
          ...state.empreendimento,
          processosLegalizacao: state.empreendimento.processosLegalizacao.map((c) => {
            if (c.id === action.payload.data.id) return action.payload.data;
            else return c;
          }),
        },
      };
      break;

    case 'STEP_PROCESSO_OBRA_PENDING':
      state = {
        ...state,
        loadingprogressoobra: true,
      };
      break;
    case 'STEP_PROCESSO_OBRA_REJECTED':
      state = {
        ...state,
        loadingprogressoobra: false,
      };
      break;
    case 'STEP_PROCESSO_OBRA_FULFILLED':
      state = {
        ...state,
        loadingprogressoobra: false,
        empreendimento: action.payload.data,
      };
      break;
    case 'DELETE_PROCESSO_OBRA_PENDING':
      state = {
        ...state,
        loadingprogressoobra: true,
      };
      break;
    case 'DELETE_PROCESSO_OBRA_REJECTED':
      state = {
        ...state,
        loadingprogressoobra: false,
      };
      break;
    case 'DELETE_PROCESSO_OBRA_FULFILLED':
      state = {
        ...state,
        loadingprogressoobra: false,
        empreendimento: {
          ...state.empreendimento,
          processosObra: state.empreendimento.processosObra.filter((c) => c.id !== action.payload.data),
        },
      };
      break;
    case 'REMOVER_INVESTIMENTO_PENDING':
      state = {
        ...state,
        loadingempreendimento: true,
        feedback: '',
      };
      break;
    case 'REMOVER_INVESTIMENTO_REJECTED':
      state = {
        ...state,
        loadingempreendimento: false,
        feedback: action.payload.response.data.Message,
      };
      break;
    case 'REMOVER_INVESTIMENTO_FULFILLED':
      state = {
        ...state,
        loadingempreendimento: false,
        feedback: 'Investimento excluído com sucesso!',
        empreendimento: {
          ...state.empreendimento,
          investimentos: state.empreendimento.investimentos.filter((c) => c.id !== action.payload.data),
        },
      };
      break;
    case 'CHANGE_PROGRESSO_OBRA_PENDING':
      state = {
        ...state,
        loadingprogressoobra: true,
      };
      break;
    case 'CHANGE_PROGRESSO_OBRA_REJECTED':
      state = {
        ...state,
        loadingprogressoobra: false,
      };
      break;
    case 'CHANGE_PROGRESSO_OBRA_FULFILLED':
      state = {
        ...state,
        loadingprogressoobra: false,
        empreendimento: {
          ...state.empreendimento,
          processosObra: state.empreendimento.processosObra.map((c) => {
            if (c.id === action.payload.data.id) return action.payload.data;
            else return c;
          }),
        },
      };
      break;
    case 'GET_EMPREENDIMENTO_PENDING':
      state = {
        ...state,
        loadingempreendimento: true,
        empreendimento: initialState.empreendimento,
      };
      break;
    case 'GET_EMPREENDIMENTO_REJECTED':
      state = {
        ...state,
        loadingempreendimento: false,
      };
      notification.error({ message: action.payload.response.data.Message });
      break;
    case 'GET_EMPREENDIMENTO_FULFILLED':
      state = {
        ...state,
        loadingempreendimento: false,
        empreendimento: action.payload.data,
      };
      break;
    case 'GET_EMPS_PENDING':
      state = {
        ...state,
        loadingemps: true,
        emps: initialState.emps,
      };
      break;
    case 'GET_EMPS_REJECTED':
      state = {
        ...state,
        loadingemps: false,
      };
      break;
    case 'GET_EMPS_FULFILLED':
      state = {
        ...state,
        loadingemps: false,
        emps: action.payload.data,
      };
      break;
    case 'ADD_EMP_PENDING':
      state = {
        ...state,
        savingemp: true,
        feedback: '',
      };
      break;
    case 'ADD_EMP_REJECTED':
      state = {
        ...state,
        savingemp: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: 'Erro ao criar empreendimento!' });
      break;
    case 'ADD_EMP_FULFILLED':
      state = {
        ...state,
        savingemp: false,
        feedback: 'Empreendimento criado com sucesso!',
        emps: [...state.emps, action.payload.data],
      };
      notification.success({ message: 'Empreendimento criado com sucesso!' });
      break;
    case 'UPDATE_EMP_PENDING':
      state = {
        ...state,
        savingemp: true,
        feedback: '',
      };
      break;
    case 'UPDATE_EMP_REJECTED':
      state = {
        ...state,
        savingemp: false,
        feedback: action.payload.response.data.Message,
      };
      break;
    case 'UPDATE_EMP_FULFILLED':
      state = {
        ...state,
        savingemp: false,
        feedback: 'Empreendimento atualizado com sucesso!',
        emps: state.emps.map((c) => {
          if (c.id === action.payload.data.id) return action.payload.data;
          else return c;
        }),
      };
      break;
    case 'DELETE_EMP_PENDING':
      state = {
        ...state,
        loadingemps: true,
        feedback: '',
      };
      break;
    case 'DELETE_EMP_REJECTED':
      state = {
        ...state,
        loadingemps: false,
        feedback: action.payload.response.data.Message,
      };
      notification.warning({ message: state.feedback });
      break;
    case 'DELETE_EMP_FULFILLED':
      state = {
        ...state,
        loadingemps: false,
        feedback: 'Empreendimento atualizado com sucesso!',
        emps: state.emps.filter((c) => c.id !== action.payload.data),
      };
      notification.success({ message: state.feedback });
      break;
    default:
      return state;
  }
  return state;
};

export default empreendimentosReducer;
