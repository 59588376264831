import { ExtendedAction } from '@config/reduxStore';
import { Ordem } from '@models/Ordem';
import { notification } from 'antd';

interface OrdensReducer {
  ordens: Ordem[];
  ordensp: Ordem[];
  transferencias: Ordem[];
  transferenciasp: Ordem[];
  ordem: Ordem;
  formview: boolean;
  reciboview: boolean;
  compilarview: boolean;
  loadingordens: boolean;
  savingordem: boolean;
  feedback: string;
  savingfiles: boolean;
  savingcompilar: boolean;
  loadingfiles: boolean;
  loadingnf: boolean;
  newordemid: null;
  newordemcod: null;
  ordensitens: Ordem;
  loadingitens: boolean;
  selectedArquivos: [];
  loadingfolhas: boolean;
  loadingfolhasp: boolean;
  folhas: Ordem[];
  folhasp: Ordem[];
  folha: Ordem;
  total: 0;
  ajustandoNF: boolean;
  pagandoParcial: boolean;
}
var initialState: OrdensReducer = {
  ordens: [],
  ordensp: [],
  transferencias: [],
  transferenciasp: [],
  ordem: null,
  formview: false,
  reciboview: false,
  compilarview: false,
  loadingordens: false,
  savingordem: false,
  feedback: '',
  savingfiles: false,
  savingcompilar: false,
  loadingfiles: false,
  loadingnf: false,
  newordemid: null,
  newordemcod: null,
  ordensitens: null,
  loadingitens: false,
  selectedArquivos: [],
  loadingfolhas: false,
  loadingfolhasp: false,
  folhas: [],
  folhasp: [],
  folha: null,
  total: 0,
  ajustandoNF: false,
  pagandoParcial: false,
};

const ordensReducer = function (state = initialState, action: ExtendedAction) {
  switch (action.type) {
    case 'CLEAR_FOLHA':
      state = {
        ...state,
        folha: initialState.folha,
      };
      break;

    case 'PAGAR_PARCIAL_ORDEM_PENDING':
      state = {
        ...state,
        pagandoParcial: true,
      };
      break;
    case 'PAGAR_PARCIAL_ORDEM_REJECTED':
      notification.error({ message: action.payload.response.data.Message });
      state = {
        ...state,
        pagandoParcial: false,
      };
      break;
    case 'PAGAR_PARCIAL_ORDEM_FULFILLED':
      notification.success({ message: 'Ordem paga parcialmente com sucesso!' });
      state = {
        ...state,
        pagandoParcial: false,
        ordem: {
          ...action.payload.data.ordem,
          replacedBy: action.payload.data.ordem.replacedBy ? action.payload.data.replacedBy : null,
          replacedFrom: action.payload.data.ordem.replacedFrom ? action.payload.data.replacedFrom : null,
        },
      };
      break;

    case 'SUBMETER_FOLHA_PENDING':
      state = {
        ...state,
        loadingfolhas: true,
      };
      break;
    case 'SUBMETER_FOLHA_REJECTED':
      state = {
        ...state,
        loadingfolhas: false,
      };
      notification.error({ message: action.payload.response.data.Message });
      break;
    case 'SUBMETER_FOLHA_FULFILLED':
      state = {
        ...state,
        loadingfolhas: false,
        folha: { ...state.folha, situacao: 'Pendente de Aprovação' },
      };
      notification.success({
        message: 'Folha submetida!',
        description: 'Folha submetida para aprovação com sucesso! A folha não pode mais ser alterada',
      });
      break;

    case 'SALVAR_FOLHA_PENDING':
      state = {
        ...state,
        loadingfolhas: true,
      };
      break;
    case 'SALVAR_FOLHA_REJECTED':
      state = {
        ...state,
        loadingfolhas: false,
      };
      notification.error({ message: action.payload.response.data.Message });
      break;
    case 'SALVAR_FOLHA_FULFILLED':
      state = {
        ...state,
        loadingfolhas: false,
        //folha: action.payload.data,
      };
      break;

    case 'GET_FOLHA_PENDING':
      state = {
        ...state,
        loadingfolhas: true,
        folha: initialState.folha,
      };
      break;
    case 'GET_FOLHA_REJECTED':
      state = {
        ...state,
        loadingfolhas: false,
      };
      notification.error({ message: action.payload.response.data.Message });
      break;
    case 'GET_FOLHA_FULFILLED':
      state = {
        ...state,
        loadingfolhas: false,
        folha: action.payload.data,
      };
      break;

    case 'GET_FOLHAS_PENDENTES_PENDING':
      state = {
        ...state,
        loadingfolhasp: true,
        folha: initialState.folha,
      };
      break;
    case 'GET_FOLHAS_PENDENTES_REJECTED':
      state = {
        ...state,
        loadingfolhasp: false,
      };
      notification.error({ message: action.payload.response.data.Message });
      break;
    case 'GET_FOLHAS_PENDENTES_FULFILLED':
      state = {
        ...state,
        loadingfolhasp: false,
        folhasp: action.payload.data,
      };
      break;
    case 'GET_FOLHAS_PENDING':
      state = {
        ...state,
        loadingfolhas: true,
        folha: initialState.folha,
      };
      break;
    case 'GET_FOLHAS_REJECTED':
      state = {
        ...state,
        loadingfolhas: false,
      };
      notification.error({ message: action.payload.response.data.Message });
      break;
    case 'GET_FOLHAS_FULFILLED':
      state = {
        ...state,
        loadingfolhas: false,
        folhas: action.payload.data,
      };
      break;

    case 'GET_ORDENS_ITENS_PENDING':
      state = {
        ...state,
        loadingitens: true,
      };
      break;
    case 'GET_ORDENS_ITENS_REJECTED':
      state = {
        ...state,
        loadingitens: false,
      };
      notification.error({ message: action.payload.response.data.Message });
      break;
    case 'GET_ORDENS_ITENS_FULFILLED':
      state = {
        ...state,
        loadingitens: false,
        ordensitens: action.payload.data,
      };
      break;
    case 'EXCLUIR_ITEM_ORDEM_PENDING':
      state = {
        ...state,
        loadingordens: true,
      };
      break;
    case 'EXCLUIR_ITEM_ORDEM_REJECTED':
      state = {
        ...state,
        loadingordens: false,
      };
      notification.error({ message: action.payload.response.data.Message });
      break;
    case 'EXCLUIR_ITEM_ORDEM_FULFILLED':
      state = {
        ...state,
        loadingordens: false,
        ordens: action.payload.data,
      };
      notification.success({ message: 'Item excluído com sucesso!' });
      break;

    case 'UPLOAD_FILES_FOLHA_PENDING':
      state = {
        ...state,
        savingfiles: true,
      };
      break;
    case 'UPLOAD_FILES_FOLHA_REJECTED':
      state = {
        ...state,
        savingfiles: false,
      };
      break;
    case 'UPLOAD_FILES_FOLHA_FULFILLED':
      state = {
        ...state,
        savingfiles: false,
        folha: {
          ...state.folha,
          arquivoOrdem: action.payload.data,
        },
      };
      break;
    case 'UPLOAD_FILES_ORDEM_PENDING':
      state = {
        ...state,
        savingfiles: true,
      };
      break;
    case 'UPLOAD_FILES_ORDEM_REJECTED':
      state = {
        ...state,
        savingfiles: false,
      };
      break;
    case 'UPLOAD_FILES_ORDEM_FULFILLED':
      state = {
        ...state,
        savingfiles: false,
        ordem: {
          ...state.ordem,
          arquivosOrdem: action.payload.data,
        },
      };
      break;
    case 'COMPILAR_ARQUIVOS_FOLHA_PENDING':
      state = {
        ...state,
        savingcompilar: true,
      };
      break;
    case 'COMPILAR_ARQUIVOS_FOLHA_REJECTED':
      state = {
        ...state,
        savingcompilar: false,
      };
      break;
    case 'COMPILAR_ARQUIVOS_FOLHA_FULFILLED':
      state = {
        ...state,
        savingcompilar: false,
        selectedArquivos: [],
        folha: {
          ...state.folha,
          arquivosOrdem: action.payload.data,
        },
      };
      break;

    case 'COMPILAR_ARQUIVOSORDEM_PENDING':
      state = {
        ...state,
        savingcompilar: true,
      };
      break;
    case 'COMPILAR_ARQUIVOSORDEM_REJECTED':
      state = {
        ...state,
        savingcompilar: false,
      };
      break;
    case 'COMPILAR_ARQUIVOSORDEM_FULFILLED':
      state = {
        ...state,
        savingcompilar: false,
        selectedArquivos: [],
        ordem: {
          ...state.ordem,
          arquivosOrdem: action.payload.data,
        },
      };
      break;

    case 'DELETE_FILE_FOLHA_PENDING':
      state = {
        ...state,
        loadingfiles: true,
      };
      break;
    case 'DELETE_FILE_FOLHA_REJECTED':
      state = {
        ...state,
        loadingfiles: false,
      };
      break;
    case 'DELETE_FILE_FOLHA_FULFILLED':
      state = {
        ...state,
        loadingfiles: false,
        folha: {
          ...state.folha,
          arquivosOrdem: state.folha.arquivos.filter((c) => c.id !== action.payload.data),
        },
      };
      break;
    case 'ADD_NF_ORDEM_PENDING':
      state = {
        ...state,
        loadingnf: true,
      };
      break;
    case 'ADD_NF_ORDEM_REJECTED':
      state = {
        ...state,
        loadingnf: false,
        feedback: action.payload.response.data.Message,
      };
      break;
    case 'ADD_NF_ORDEM_FULFILLED':
      state = {
        ...state,
        loadingnf: false,
        ordem: {
          ...state.ordem,
          notaFiscalOrdem: [...state.ordem.notaFiscalOrdem, action.payload.data],
        },
      };
      break;
    case 'DELETE_FILE_ORDEM_PENDING':
      state = {
        ...state,
        loadingfiles: true,
      };
      break;
    case 'DELETE_FILE_ORDEM_REJECTED':
      state = {
        ...state,
        loadingfiles: false,
      };
      break;
    case 'DELETE_FILE_ORDEM_FULFILLED':
      state = {
        ...state,
        loadingfiles: false,
        ordem: {
          ...state.ordem,
          arquivosOrdem: state.ordem.arquivosOrdem.filter((c) => c.id !== action.payload.data),
        },
      };
      break;
    case 'DELETE_NF_ORDEM_PENDING':
      state = {
        ...state,
        loadingnf: true,
      };
      break;
    case 'DELETE_NF_ORDEM_REJECTED':
      state = {
        ...state,
        loadingnf: false,
      };
      break;
    case 'DELETE_NF_ORDEM_FULFILLED':
      state = {
        ...state,
        loadingnf: false,
        ordem: {
          ...state.ordem,
          notaFiscalOrdem: state.ordem.notaFiscalOrdem.filter((c) => c.id !== action.payload.data),
        },
      };
      break;
    case 'RESET_FEEDBACK':
      state = {
        ...state,
        feedback: '',
      };
      break;
    case 'FORM_ORDEM':
      state = {
        ...state,
        formview: action.payload.open,
        feedback: '',
        ordem: action.payload.id ? action.payload.id : null,
      };
      break;
    case 'SELECTED_ARQUIVOS':
      state = {
        ...state,
        selectedArquivos: action.payload,
      };
      break;
    case 'FORM_RECIBO':
      state = {
        ...state,
        reciboview: action.payload.open,
        feedback: '',
        //ordem: action.payload.id ? action.payload.id : null,
      };
      break;
    case 'SOLICITAR_PAGAMENTO_ORDEM_PENDING':
      state = {
        ...state,
        loadingordens: true,
      };
      break;
    case 'SOLICITAR_PAGAMENTO_ORDEM_REJECTED':
      state = {
        ...state,
        loadingordens: false,
      };
      break;
    case 'SOLICITAR_PAGAMENTO_ORDEM_FULFILLED':
      state = {
        ...state,
        loadingordens: false,
        ordem: action.payload.data.ordem,
      };
      notification.success({ message: 'Ordem enviada para pagamento com sucesso!' });
      break;
    case 'FINALIZAR_ORDEM_PENDING':
      state = {
        ...state,
        loadingordens: true,
      };
      break;
    case 'FINALIZAR_ORDEM_REJECTED':
      state = {
        ...state,
        loadingordens: false,
      };
      break;
    case 'FINALIZAR_ORDEM_FULFILLED':
      state = {
        ...state,
        loadingordens: false,
        ordem: action.payload.data.ordem,
      };
      notification.success({ message: 'Ordem finalizada com sucesso!' });
      break;
    case 'APROVAR_ORDEM_PENDING':
      state = {
        ...state,
        loadingordens: true,
      };
      break;
    case 'APROVAR_ORDEM_REJECTED':
      state = {
        ...state,
        loadingordens: false,
      };
      notification.error({ message: action.payload.response.data.Message });
      break;
    case 'APROVAR_ORDEM_FULFILLED':
      state = {
        ...state,
        loadingordens: false,
        ordensp: state.ordensp.filter((c) => c.id !== action.payload.data),
      };
      notification.success({ message: 'Ordem aprovada com sucesso!' });
      break;

    case 'APROVAR_FOLHA_PENDING':
      state = {
        ...state,
        loadingfolhasp: true,
      };
      break;
    case 'APROVAR_FOLHA_REJECTED':
      state = {
        ...state,
        loadingfolhasp: false,
      };
      notification.error({ message: action.payload.response.data.Message });
      break;
    case 'APROVAR_FOLHA_FULFILLED':
      state = {
        ...state,
        loadingfolhasp: false,
        folhasp: state.folhasp.filter((c) => c.id !== action.payload.data),
      };
      notification.success({ message: 'Folha aprovada com sucesso!' });
      break;
    case 'APROVAR_TRANSFERENCIA_PENDING':
      state = {
        ...state,
        loadingordens: true,
      };
      break;
    case 'APROVAR_TRANSFERENCIA_REJECTED':
      state = {
        ...state,
        loadingordens: false,
      };
      notification.error({ message: action.payload.response.data.Message });
      break;
    case 'APROVAR_TRANSFERENCIA_FULFILLED':
      state = {
        ...state,
        loadingordens: false,
        transferenciasp: state.transferenciasp.filter((c) => c.id !== action.payload.data),
      };
      notification.success({ message: 'Ordem aprovada com sucesso!' });
      break;
    case 'ENVIAR_ORDEM_PENDING':
      state = {
        ...state,
        loadingordens: true,
      };
      break;
    case 'ENVIAR_ORDEM_REJECTED':
      state = {
        ...state,
        loadingordens: false,
      };
      notification.error({ message: action.payload.response.data.Message });
      break;
    case 'ENVIAR_ORDEM_FULFILLED':
      state = {
        ...state,
        loadingordens: false,
        ordens: state.ordens.map((c) => {
          if (c.id === action.payload.data.id) {
            c.email = action.payload.data.email;
            c.emailEnviado = true;
            return c;
          } else return c;
        }),
      };
      notification.success({ message: 'Ordem enviada com sucesso!' });
      break;
    case 'CONFIRMAR_DOCUMENTACAO_ORDEM_PENDING':
      state = {
        ...state,
        loadingordens: true,
      };
      break;
    case 'CONFIRMAR_DOCUMENTACAO_ORDEM_REJECTED':
      state = {
        ...state,
        loadingordens: false,
      };
      break;
    case 'CONFIRMAR_DOCUMENTACAO_ORDEM_FULFILLED':
      state = {
        ...state,
        loadingordens: false,
        ordem: action.payload.data.ordem,
      };
      notification.success({ message: 'Documentação confirmada com sucesso!' });
      break;
    case 'PAGAR_FOLHA_PENDING':
      state = {
        ...state,
        loadingfolhas: true,
      };
      break;
    case 'PAGAR_FOLHA_REJECTED':
      state = {
        ...state,
        loadingfolhas: false,
      };
      break;
    case 'PAGAR_FOLHA_FULFILLED':
      state = {
        ...state,
        loadingfolhas: false,
        folha: { ...state.folha, situacao: action.payload.data.situacao, tipo: action.payload.data.tipo },
      };
      notification.success({ message: 'Pagamento confirmado com sucesso!' });
      break;
    case 'PAGAR_ORDEM_PENDING':
      state = {
        ...state,
        loadingordens: true,
      };
      break;
    case 'PAGAR_ORDEM_REJECTED':
      state = {
        ...state,
        loadingordens: false,
      };
      notification.error({ message: 'Erro ao confirmar pagamento' });
      break;
    case 'PAGAR_ORDEM_FULFILLED':
      state = {
        ...state,
        loadingordens: false,
        ordem: action.payload.data.ordem,
      };
      notification.success({ message: 'Pagamento confirmado com sucesso!' });
      break;
    case 'PROCESSAR_TRANSFERENCIA_PENDING':
      state = {
        ...state,
        loadingordens: true,
      };
      break;
    case 'PROCESSAR_TRANSFERENCIA_REJECTED':
      state = {
        ...state,
        loadingordens: false,
      };
      break;
    case 'PROCESSAR_TRANSFERENCIA_FULFILLED':
      state = {
        ...state,
        loadingordens: false,
        ordem: action.payload.data,
        transferencias: state.transferencias.map((c) => {
          if (c.id === action.payload.data.id) {
            c.situacao = 'Finalizada';
            return c;
          }
          return c;
        }),
      };
      notification.success({ message: 'Pagamento confirmado com sucesso!' });
      break;

    case 'CANCELAR_FOLHA_PENDING':
      state = {
        ...state,
        loadingfolhas: true,
      };
      break;
    case 'CANCELAR_FOLHA_REJECTED':
      state = {
        ...state,
        loadingfolhas: false,
      };
      break;
    case 'CANCELAR_FOLHA_FULFILLED':
      state = {
        ...state,
        loadingfolhas: false,
        folhas: state.folhas.map((c) => {
          if (c.id === action.payload.data.id) {
            c.situacao = 'Cancelada';
            c.cancelada = true;
            c.motivo = action.payload.data.motivo;
            return c;
          }
          return c;
        }),
        folhasp: state.folhasp.filter((c) => c.id !== action.payload.data.id),
      };
      break;
    case 'CANCELAR_ORDEM_PENDING':
      state = {
        ...state,
        loadingordens: true,
      };
      break;
    case 'CANCELAR_ORDEM_REJECTED':
      state = {
        ...state,
        loadingordens: false,
      };
      break;
    case 'CANCELAR_ORDEM_FULFILLED':
      state = {
        ...state,
        loadingordens: false,
        ordens: state.ordens.map((c) => {
          if (c.id === action.payload.data.id) {
            c.situacao = 'Cancelada';
            c.cancelada = true;
            c.motivo = action.payload.data.motivo;
            return c;
          }
          return c;
        }),
        ordensp: state.ordensp.filter((c) => c.id !== action.payload.data.id),
      };
      break;
    case 'CANCELAR_TRANSFERENCIA_PENDING':
      state = {
        ...state,
        loadingordens: true,
      };
      break;
    case 'CANCELAR_TRANSFERENCIA_REJECTED':
      state = {
        ...state,
        loadingordens: false,
      };
      notification.error({ message: 'Erro ao cancelar transferência' });
      break;
    case 'CANCELAR_TRANSFERENCIA_FULFILLED':
      state = {
        ...state,
        loadingordens: false,
        transferencias: state.transferencias.map((c) => {
          if (c.id === action.payload.data.id) {
            c.situacao = 'Cancelada';
            c.cancelada = true;
            c.motivo = action.payload.data.motivo;
            return c;
          }
          return c;
        }),
        transferenciasp: state.transferenciasp.map((c) => {
          if (c.id === action.payload.data.id) {
            c.situacao = 'Cancelada';
            c.cancelada = true;
            c.motivo = action.payload.data.motivo;
            return c;
          }
          return c;
        }),
      };
      notification.success({ message: 'Transferência cancelada com sucesso!' });
      break;
    case 'GET_ORDEM_EDIT_PENDING':
      state = {
        ...state,
        loadingordens: true,
        ordem: initialState.ordem,
      };
      break;
    case 'GET_ORDEM_EDIT_REJECTED':
      state = {
        ...state,
        loadingordens: false,
      };
      notification.error({ message: action.payload.response.data.Message });
      break;
    case 'GET_ORDEM_EDIT_FULFILLED':
      state = {
        ...state,
        loadingordens: false,
        ordem: action.payload.data,
        newordemid: initialState.newordemid,
      };
      break;
    case 'GET_TRANSFERENCIAS_PENDING':
      state = {
        ...state,
        loadingordens: true,
        transferenciasp: initialState.transferenciasp,
      };
      break;
    case 'GET_TRANSFERENCIAS_REJECTED':
      state = {
        ...state,
        loadingordens: false,
      };
      break;
    case 'GET_TRANSFERENCIAS_FULFILLED':
      state = {
        ...state,
        loadingordens: false,
        transferencias: action.payload.data,
      };
      break;
    case 'GET_TRANSFERENCIASP_PENDING':
      state = {
        ...state,
        loadingordens: true,
        transferenciasp: initialState.transferenciasp,
      };
      break;
    case 'GET_TRANSFERENCIASP_REJECTED':
      state = {
        ...state,
        loadingordens: false,
      };
      break;
    case 'GET_TRANSFERENCIASP_FULFILLED':
      state = {
        ...state,
        loadingordens: false,
        transferenciasp: action.payload.data,
      };
      break;
    case 'GET_ORDEM_PENDING':
      state = {
        ...state,
        loadingordens: true,
        ordem: initialState.ordem,
      };
      break;
    case 'GET_ORDEM_REJECTED':
      state = {
        ...state,
        loadingordens: false,
      };
      notification.error({ message: action.payload.response.data.Message });
      break;
    case 'GET_ORDEM_FULFILLED':
      state = {
        ...state,
        loadingordens: false,
        ordem: {
          ...action.payload.data.ordem,
          replacedBy: action.payload.data.ordem.replacedBy ? action.payload.data.replacedBy : null,
          replacedFrom: action.payload.data.ordem.replacedFrom ? action.payload.data.replacedFrom : null,
        },
      };
      break;
    case 'AJUSTENF_PENDING':
      state = {
        ...state,
        ajustandoNF: true,
      };
      break;
    case 'AJUSTENF_REJECTED':
      state = {
        ...state,
        ajustandoNF: false,
      };
      notification.error({ message: action.payload.response.data.Message });
      break;
    case 'AJUSTENF_FULFILLED':
      state = {
        ...state,
        ajustandoNF: false,
        ordens: state.ordens.map((c) => {
          if (c.id === action.payload.data.id) {
            return {
              ...c,
              ...action.payload.data,
            };
          } else {
            return c;
          }
        }),
      };
      break;
    case 'GET_ORDENS_PENDING':
      state = {
        ...state,
        loadingordens: true,
        ordens: initialState.ordens,
        ordem: initialState.ordem,
      };
      break;
    case 'GET_ORDENS_REJECTED':
      state = {
        ...state,
        loadingordens: false,
      };
      break;
    case 'GET_ORDENS_FULFILLED':
      state = {
        ...state,
        loadingordens: false,
        ordens: action.payload.data,
      };
      break;
    case 'GET_ORDENS_SERVER_PENDING':
      state = {
        ...state,
        loadingordens: true,
        //ordens: initialState.ordens,
        ordem: initialState.ordem,
      };
      break;
    case 'GET_ORDENS_SERVER_REJECTED':
      state = {
        ...state,
        loadingordens: false,
      };
      break;
    case 'GET_ORDENS_SERVER_FULFILLED':
      state = {
        ...state,
        loadingordens: false,
        ordens: action.payload.data.results,
        total: action.payload.data.total,
      };
      break;
    case 'GET_ORDENSP_PENDING':
      state = {
        ...state,
        loadingordens: true,
        ordens: initialState.ordens,
        ordem: initialState.ordem,
        ordensp: initialState.ordensp,
      };
      break;
    case 'GET_ORDENSP_REJECTED':
      state = {
        ...state,
        loadingordens: false,
      };
      break;
    case 'GET_ORDENSP_FULFILLED':
      state = {
        ...state,
        loadingordens: false,
        ordensp: action.payload.data,
      };
      break;
    case 'CLEAR_ORDEM':
      state = {
        ...state,
        newordemid: null,
      };
      break;
    case 'SUBMETER_ORDEM_PENDING':
      state = {
        ...state,
        savingordem: true,
        feedback: '',
      };
      break;
    case 'SUBMETER_ORDEM_REJECTED':
      state = {
        ...state,
        savingordem: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: action.payload.response.data.Message });
      break;
    case 'SUBMETER_ORDEM_FULFILLED':
      state = {
        ...state,
        savingordem: false,
        feedback: 'Ordem submetida com sucesso!',
        ordem: action.payload.data.ordem,
      };
      notification.success({ message: 'Ordem salva com sucesso!' });
      break;
    case 'ADD_ORDEM_PENDING':
      state = {
        ...state,
        savingordem: true,
        feedback: '',
      };
      break;
    case 'ADD_ORDEM_REJECTED':
      state = {
        ...state,
        savingordem: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: 'Erro ao salvar ordem!' });
      break;
    case 'ADD_ORDEM_FULFILLED':
      state = {
        ...state,
        savingordem: false,
        feedback: 'Ordem criada com sucesso!',
        newordemid: action.payload.data.id,
        newordemcod: action.payload.data.cod,
        //ordem: action.payload.data,
      };
      notification.success({ message: 'Ordem salva com sucesso!' });
      break;
    case 'UPDATE_ORDEM_PENDING':
      state = {
        ...state,
        savingordem: true,
        feedback: '',
      };
      break;
    case 'UPDATE_ORDEM_REJECTED':
      state = {
        ...state,
        savingordem: false,
        feedback: action.payload.response.data.Message,
      };
      break;
    case 'UPDATE_ORDEM_FULFILLED':
      state = {
        ...state,
        savingordem: false,
        feedback: 'Ordem atualizada com sucesso!',
        ordens: state.ordens.map((c) => {
          if (c.id === action.payload.data.id) return action.payload.data;
          else return c;
        }),
      };
      break;
    case 'DELETE_ORDEM_PENDING':
      state = {
        ...state,
        loadingordens: true,
        feedback: '',
      };
      break;
    case 'DELETE_ORDEM_REJECTED':
      state = {
        ...state,
        loadingordens: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: state.feedback });
      break;
    case 'DELETE_ORDEM_FULFILLED':
      state = {
        ...state,
        loadingordens: false,
        feedback: 'Ordem excluída com sucesso!',
        ordens: state.ordens.filter((c) => c.id !== action.payload.data),
      };
      notification.success({ message: state.feedback });
      break;
    default:
      return state;
  }
  return state;
};

export default ordensReducer;
