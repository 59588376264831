import { Button, Card, Form, Input, Table, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { CheckCircleTwoTone, MinusOutlined } from '@ant-design/icons';
import { GetConta, GetPlanos, SetSubscription } from '@actions/contasActions';
import axios from 'axios';
import { PayPalButtons } from '@paypal/react-paypal-js';
import { moneyMask } from '@utils/masks';
import { Link } from 'react-router-dom';
import { SubscriptionStatus } from '@constants/subscriptions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { ColumnsType } from 'antd/lib/table';
const funcionalidades = [
  {
    funcionalidade: 'Período de avaliação',
    basico: '30 dias',
    controle: '30 dias',
    total: '30 dias',
    subtitulo: false,
  },
  {
    funcionalidade: 'Empreendimentos',
    basico: '',
    controle: '',
    total: '',
    subtitulo: true,
  },
  {
    funcionalidade: 'Limite de cadastro de empreendimentos',
    basico: 'Ilimitado',
    controle: 'Ilimitado',
    total: 'Ilimitado',
    subtitulo: false,
  },
  {
    funcionalidade: 'Upload de imagens do empreendimento',
    basico: <CheckCircleTwoTone />,
    controle: <CheckCircleTwoTone />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Upload de progresso da obra',
    basico: <CheckCircleTwoTone />,
    controle: <CheckCircleTwoTone />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Upload de progresso da legalização',
    basico: <CheckCircleTwoTone />,
    controle: <CheckCircleTwoTone />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Gerenciamento de investidores',
    basico: <MinusOutlined />,
    controle: <CheckCircleTwoTone />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Portal do investidor',
    basico: <MinusOutlined />,
    controle: <CheckCircleTwoTone />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Distribuição de resultado do investidor por cota',
    basico: <MinusOutlined />,
    controle: <CheckCircleTwoTone />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Imóveis',
    basico: '',
    controle: '',
    total: '',
    subtitulo: true,
  },
  {
    funcionalidade: 'Limite de imóveis cadastrados',
    basico: 'Ilimitado',
    controle: 'Ilimitado',
    total: 'Ilimitado',
    subtitulo: false,
  },
  {
    funcionalidade: 'Upload de fotos do imóvel',
    basico: <CheckCircleTwoTone />,
    controle: <CheckCircleTwoTone />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Upload de arquivos do imóvel',
    basico: <CheckCircleTwoTone />,
    controle: <CheckCircleTwoTone />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Upload de progresso da obra',
    basico: <CheckCircleTwoTone />,
    controle: <CheckCircleTwoTone />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Upload de progresso da legalização',
    basico: <CheckCircleTwoTone />,
    controle: <CheckCircleTwoTone />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Impressão de documentos personalizáveis para entrega do imóvel',
    basico: <CheckCircleTwoTone />,
    controle: <CheckCircleTwoTone />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'SAC',
    basico: '',
    controle: '',
    total: '',
    subtitulo: true,
  },
  {
    funcionalidade: 'Abertura de chamado de assistência técnica',
    basico: <MinusOutlined />,
    controle: <CheckCircleTwoTone />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Impressão de formulários personalizáveis',
    basico: <MinusOutlined />,
    controle: <CheckCircleTwoTone />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Notificações por email',
    basico: <MinusOutlined />,
    controle: <CheckCircleTwoTone />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Pesquisa de satisfação',
    basico: <MinusOutlined />,
    controle: <CheckCircleTwoTone />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Alertas para responsável direto no calendário',
    basico: <MinusOutlined />,
    controle: <CheckCircleTwoTone />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Planejamento',
    basico: '',
    controle: '',
    total: '',
    subtitulo: true,
  },
  {
    funcionalidade: 'Planejamento de obras',
    basico: <MinusOutlined />,
    controle: <MinusOutlined />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Meta de avanço mensal',
    basico: <MinusOutlined />,
    controle: <MinusOutlined />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Avanço físico vs medição financeira',
    basico: <MinusOutlined />,
    controle: <MinusOutlined />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Definição de responsável pela meta do mês',
    basico: <MinusOutlined />,
    controle: <MinusOutlined />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Correspondência',
    basico: '',
    controle: '',
    total: '',
    subtitulo: true,
  },
  {
    funcionalidade: 'Limite de cadastro de clientes',
    basico: 'Ilimitado',
    controle: 'Ilimitado',
    total: 'Ilimitado',
    subtitulo: false,
  },
  {
    funcionalidade: 'Diversas visualizações da jornada do cliente',
    basico: <CheckCircleTwoTone />,
    controle: <CheckCircleTwoTone />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Checklist completo para controle de documentação e progresso',
    basico: <CheckCircleTwoTone />,
    controle: <CheckCircleTwoTone />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Portal do cliente',
    basico: '',
    controle: '',
    total: '',
    subtitulo: true,
  },
  {
    funcionalidade: 'Adição de usuários clientes para acesso ao portal',
    basico: <MinusOutlined />,
    controle: <CheckCircleTwoTone />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Visualização de arquivos disponibilizados pelo administrador',
    basico: <MinusOutlined />,
    controle: <CheckCircleTwoTone />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Visualização do progresso de obra',
    basico: <MinusOutlined />,
    controle: <CheckCircleTwoTone />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Visualização do progresso de legalização',
    basico: <MinusOutlined />,
    controle: <CheckCircleTwoTone />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Visualização da galeria de imagens da obra',
    basico: <MinusOutlined />,
    controle: <CheckCircleTwoTone />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Orçamentos',
    basico: '',
    controle: '',
    total: '',
    subtitulo: true,
  },
  {
    funcionalidade: 'Criação de orçamento para imóvel cadastrado',
    basico: <MinusOutlined />,
    controle: <MinusOutlined />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Atualização de preços unitários em massa',
    basico: <MinusOutlined />,
    controle: <MinusOutlined />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Bloqueio do orçamento para edição',
    basico: <MinusOutlined />,
    controle: <MinusOutlined />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Bloqueio de itens realizados para controle de saldos',
    basico: <MinusOutlined />,
    controle: <MinusOutlined />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Cálculo do custo projetado em tempo real',
    basico: <MinusOutlined />,
    controle: <MinusOutlined />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Exportação de relatórios em Excel',
    basico: <MinusOutlined />,
    controle: <MinusOutlined />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Compras',
    basico: '',
    controle: '',
    total: '',
    subtitulo: true,
  },
  {
    funcionalidade: 'Aprovação de ordens de compra em até 3 níveis',
    basico: <MinusOutlined />,
    controle: <MinusOutlined />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Criação de ordem de compra vinculada ao orçamento',
    basico: <MinusOutlined />,
    controle: <MinusOutlined />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Bloqueio de compras por estouro em valor',
    basico: <MinusOutlined />,
    controle: <MinusOutlined />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Bloqueio de compras por estouro em quantidade',
    basico: <MinusOutlined />,
    controle: <MinusOutlined />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Criação de folhas de pagamento de obra via medição de serviços',
    basico: <MinusOutlined />,
    controle: <MinusOutlined />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Criação de folha de adiantamento de obra',
    basico: <MinusOutlined />,
    controle: <MinusOutlined />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Criação de pedidos de compra',
    basico: <MinusOutlined />,
    controle: <MinusOutlined />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Notificações via email personalizáveis para todas as etapas de compra',
    basico: <MinusOutlined />,
    controle: <MinusOutlined />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Transferência de materiais entre obras',
    basico: <MinusOutlined />,
    controle: <MinusOutlined />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Orçamento de depósito/Almoxarifado',
    basico: <MinusOutlined />,
    controle: <MinusOutlined />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Orçamento estruturado em 3 níveis',
    basico: <MinusOutlined />,
    controle: <MinusOutlined />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Cadastro de fornecedores',
    basico: <MinusOutlined />,
    controle: <MinusOutlined />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Cadastro de empresas para faturamento',
    basico: <MinusOutlined />,
    controle: <MinusOutlined />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Envio de ordens de compra para o fornecedor via email',
    basico: <MinusOutlined />,
    controle: <MinusOutlined />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Cadastro de materiais e serviços',
    basico: <MinusOutlined />,
    controle: <MinusOutlined />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Cadastro dos colaboradores de obra',
    basico: <MinusOutlined />,
    controle: <MinusOutlined />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Dashboard',
    basico: '',
    controle: '',
    total: '',
    subtitulo: true,
  },
  {
    funcionalidade: 'Resultado de vendas por corretor',
    basico: <CheckCircleTwoTone />,
    controle: <CheckCircleTwoTone />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Controle de jornada do cliente de construção',
    basico: <CheckCircleTwoTone />,
    controle: <CheckCircleTwoTone />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Projeções orçamentárias',
    basico: <MinusOutlined />,
    controle: <MinusOutlined />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Resultados finalizados',
    basico: <MinusOutlined />,
    controle: <MinusOutlined />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Metas de fechamento de contrato',
    basico: <CheckCircleTwoTone />,
    controle: <CheckCircleTwoTone />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Usuários',
    basico: '',
    controle: '',
    total: '',
    subtitulo: true,
  },
  {
    funcionalidade: 'Limite de cadastro de usuários',
    basico: 'Ilimitado',
    controle: 'Ilimitado',
    total: 'Ilimitado',
    subtitulo: false,
  },
  {
    funcionalidade: 'Controle de perfis dos usuários',
    basico: <CheckCircleTwoTone />,
    controle: <CheckCircleTwoTone />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Controle de permissões do usuário por perfil',
    basico: <CheckCircleTwoTone />,
    controle: <CheckCircleTwoTone />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
  {
    funcionalidade: 'Definição de perfis aprovadores de compras',
    basico: <MinusOutlined />,
    controle: <MinusOutlined />,
    total: <CheckCircleTwoTone />,
    subtitulo: false,
  },
];
function Planos(props: { ContaID: number }) {
  const dispatch = useAppDispatch();
  const { planos } = useAppSelector((state) => state.conta);
  const { paypal } = useAppSelector((state) => state.user);
  const { ContaID } = props;
  const [statusSubscription, SetStatusSubscription] = useState({ error: false, status: SubscriptionStatus.NULL });
  const [processando, SetProcessando] = useState(false);

  useEffect(() => {
    dispatch(GetPlanos());
    if (ContaID) {
      dispatch(GetConta(ContaID));
    }
  }, [props]);

  const columns: ColumnsType<any> = planos && [
    {
      dataIndex: 'funcionalidade',
      title: ContaID && (
        <Card style={{ margin: 20 }}>
          <Typography.Text>
            Se você já realizou o pagamento e ainda continua vendo essa página, entre com o
            <strong> ID do perfil</strong> recebido por email para validar o pagamento
          </Typography.Text>
          <Form
            onFinish={(values) => {
              SetProcessando(true);
              const params = new URLSearchParams();
              params.append('grant_type', 'client_credentials');
              axios
                .post(`${paypal.api}/v1/oauth2/token`, params, {
                  headers: { Accept: 'application/json', 'Accept-Language': 'en_US' },
                  auth: {
                    username: paypal.clientid,
                    password: paypal.secret,
                  },
                })
                .then((res) => {
                  axios
                    .get(`${paypal.api}/v1/billing/subscriptions/${values.subscriptionID}`, {
                      headers: {
                        Authorization: `Bearer ${res.data.access_token}`,
                        'Content-Type': 'application/json',
                      },
                    })
                    .then((c) => {
                      SetProcessando(false);
                      SetStatusSubscription({ ...c.data, error: false });
                      if (c.data.status === SubscriptionStatus.ACTIVE) {
                        if (c.data.custom_id === ContaID)
                          dispatch(SetSubscription({ subscriptionID: c.data.id, planoID: c.data.custom_id }, paypal));
                      }
                    })
                    .catch((c) => {
                      SetProcessando(false);
                      SetStatusSubscription({ ...c.response.data, error: true, status: SubscriptionStatus.NULL });
                    });
                });
            }}
          >
            <Form.Item
              label="ID do perfil"
              name="subscriptionID"
              rules={[
                { required: true, message: 'Campo obrigatório' },
                { len: 14, message: 'Formato errado' },
              ]}
            >
              <Input placeholder="I-T6TVFE8G1VCF" />
            </Form.Item>
            <Button type="primary" htmlType="submit" loading={processando}>
              Processar
            </Button>
          </Form>
          {statusSubscription.error && <Typography.Text type="danger">Compra não encontrada</Typography.Text>}
          {!statusSubscription.error && statusSubscription.status !== SubscriptionStatus.NULL && (
            <Typography.Text type="warning">Compra se encontra com status: {statusSubscription.status}</Typography.Text>
          )}
        </Card>
      ),
    },
    ...planos.map((c, i) => ({
      dataIndex: i === 0 ? 'basico' : i === 1 ? 'controle' : 'total',
      width: '20%',
      title: (
        <div>
          <Typography.Title level={1} style={{ marginBottom: 10, fontWeight: 'bold' }}>
            {c.nome}
          </Typography.Title>
          {ContaID && (
            <PayPalButtons
              style={{ shape: 'rect', color: 'blue', layout: 'vertical', label: 'subscribe' }}
              createSubscription={(_, actions) =>
                actions.subscription.create({
                  plan_id: c.planoID.toString(),
                  custom_id: c.id.toString(),
                })
              }
              onApprove={async (data) => dispatch(SetSubscription({ ...data, planoID: c.id }, paypal))}
            />
          )}
          {!ContaID && (
            <Link to="/Contratar">
              <Button type="primary" style={{ marginBottom: 10 }}>
                Testar Grátis
              </Button>
            </Link>
          )}
          <Typography.Title level={3} style={{ color: '#3b7ccc' }}>
            {moneyMask.format(c.valor)}
            <span style={{ fontSize: 12 }}>/mês</span>
          </Typography.Title>
        </div>
      ),
    })),
  ];
  return (
    <div>
      <Table
        size="small"
        dataSource={funcionalidades}
        rowKey="funcionalidade"
        rowClassName={(row) => (row.subtitulo ? 'subtitulo' : '')}
        pagination={false}
        columns={columns}
        scroll={{ y: 'calc(100vh - 650px)', x: 1024 }}
      />
    </div>
  );
}

export default Planos;
