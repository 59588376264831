import { useState, useEffect } from 'react';
import { Button, Popconfirm, Table, Modal, Upload } from 'antd';
import { modalArquivoColaborador, uploadFiles, deleteArquivo, getArquivos } from '@actions/colaboradoresActions';
import { UploadOutlined, DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { Axios } from '@config/axios';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { RcFile } from 'antd/lib/upload';
import { Arquivo } from '@models/Arquivo';

function ColaboradorArquivos() {
  const { savingArquivo, arquivoview, colaborador, loadingArquivos, arquivos } = useAppSelector(
    (state) => state.colaboradores
  );
  const [files, upFile] = useState<RcFile[]>([]);
  const dispatch = useAppDispatch();
  const downloadArquivo = (fileid: number, filename: string) => {
    Axios.get('/api/v2/Fornecedores/Download', { params: { fileid }, responseType: 'blob' }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    });
  };
  useEffect(() => {
    dispatch(getArquivos(colaborador));
  }, [colaborador]);
  return (
    <Modal
      open={arquivoview}
      title="Adicionar Arquivos do Fornecedor"
      onCancel={() => dispatch(modalArquivoColaborador(null, false))}
      footer={[
        <Button key="2" onClick={() => dispatch(modalArquivoColaborador(null, false))}>
          Fechar
        </Button>,
      ]}
    >
      <Upload
        name="arquivo"
        disabled={savingArquivo}
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        multiple={true}
        beforeUpload={(_, fileList) => {
          upFile([...files, ...fileList]);
          Promise.resolve(false);
        }}
        accept=".pdf,.jpg,.jpeg,.png,.xls,.xlsx"
        onRemove={(file) => {
          Promise.resolve(false);
          upFile(files.filter((f) => f !== file));
        }}
        fileList={files}
      >
        <Button icon={<UploadOutlined />} size="small">
          Escolher Arquivos
        </Button>
      </Upload>
      {files.length > 0 && (
        <Button
          type="primary"
          size="small"
          loading={savingArquivo}
          onClick={() => {
            const formdata = new FormData();
            files.map((f) => formdata.append('files', f, f.name));
            formdata.append('colaboradorid', colaborador.toString());
            dispatch(uploadFiles(formdata));
            upFile([]);
          }}
        >
          Enviar
        </Button>
      )}

      <Table size="small" rowKey="id" loading={loadingArquivos} dataSource={arquivos} pagination={{ pageSize: 5 }}>
        <Table.Column
          render={(_, row: Arquivo) => (
            <div>
              <Button onClick={() => downloadArquivo(row.id, row.fileName)} icon={<DownloadOutlined />} size="small" />
              <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(deleteArquivo(row.id))}>
                <Button icon={<DeleteOutlined />} size="small" />
              </Popconfirm>
            </div>
          )}
        />
        <Table.Column title="Nome do Arquivo" dataIndex="fileName" />
      </Table>
    </Modal>
  );
}

export default ColaboradorArquivos;
