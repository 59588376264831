import { useEffect } from 'react';
import { Input, Button, Form } from 'antd';
import { ResetPassword, SetPageHeader } from '@actions/userActions';
import Text from 'antd/lib/typography/Text';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
interface FormData {
  Password: string;
  ConfirmPassword: string;
}
function HomeView() {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<FormData>();
  const user = useAppSelector((state) => state.user);
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Perfil do Usuário',
        backbutton: false,
        extras: [],
        subtitle: 'Redefinir Senha',
        menu: 'profile',
      })
    );
  }, []);

  const onFinishReset = (values: FormData) => {
    const urlParams = new URLSearchParams(window.location.search);
    dispatch(ResetPassword({ ...values, userid: urlParams.get('userId'), code: urlParams.get('code') }));
  };

  return (
    <div>
      <div className="back" />
      <div
        style={{
          minWidth: 300,
          maxWidth: 400,
          height: 300,
          padding: 20,
        }}
      >
        <Form onFinish={onFinishReset} form={form} name="loginForm" layout="vertical">
          <Form.Item
            name="Password"
            label="Nova Senha"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório!',
              },
              {
                pattern: /^(?=.{6,})/,
                message: 'A senha deve conter no mínimo 6 dígitos',
              },
              {
                pattern: /^(?=.*[a-z])/,
                message: 'A senha deve conter ao menos uma letra minúscula',
              },
              {
                pattern: /^(?=.*[A-Z])/,
                message: 'A senha deve conter ao menos uma letra maiúscula',
              },
              {
                pattern: /^(?=.*[0-9])/,
                message: 'A senha deve conter ao menos um número',
              },
              {
                pattern: /^(?=.*[!@#$%^&*])/,
                message: 'A senha deve conter ao menos um caractére especial',
              },
            ]}
          >
            <Input.Password placeholder="Senha" />
          </Form.Item>
          <Form.Item
            name="ConfirmPassword"
            label="Confirmar Senha"
            dependencies={['Password']}
            rules={[
              {
                required: true,
                message: 'Campo obrigatório!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('Password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('As senhas não conferem!');
                },
              }),
            ]}
          >
            <Input.Password placeholder="Senha" />
          </Form.Item>
          <Form.Item>
            <Button loading={user.reset.loading} block type="primary" htmlType="submit">
              Solicitar Renovação
            </Button>
          </Form.Item>
          <Form.Item name="feedback">
            <Text type="danger">{user.reset.feedback}</Text>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default HomeView;
