import { useEffect } from 'react';
import { Modal, Form, Input, Button } from 'antd';
import { SalvarModalidade, FormModalidade } from '@actions/modalidadesActions';
import { moneyMask } from '@utils/masks';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { ModalidadeCredito } from '@models/ModalidadeCredito';

function ModalidadeForm() {
  const dispatch = useAppDispatch();
  const modalidades = useAppSelector((state) => state.modalidades);
  const [form] = Form.useForm<ModalidadeCredito>();
  const submitForm = (values: ModalidadeCredito) => {
    dispatch(
      SalvarModalidade({
        ...values,
        remuneracao: moneyMask.normalize(values.remuneracao as string),
      })
    );
  };
  useEffect(() => {
    form.resetFields();
    if (modalidades.modalidade) {
      const modalidade = modalidades.modalidades.find((c) => c.id === modalidades.modalidade);
      form.setFieldsValue({
        ...modalidade,
        remuneracao: moneyMask.format(modalidade.remuneracao),
      });
    }
  }, [modalidades.modalidade, modalidades.modalidades, form]);
  return (
    <div>
      <Modal
        open={modalidades.formview}
        onCancel={() => dispatch(FormModalidade(null, false))}
        title="Cadastro Modalidade"
        footer={[
          <Button onClick={() => dispatch(FormModalidade(null, false))} key="2">
            Fechar
          </Button>,
          <Button key="3" type="primary" onClick={() => form.submit()} loading={modalidades.savingmodalidade}>
            Salvar
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form} onFinish={submitForm} name="modalidadeForm">
          <Form.Item style={{ display: 'none' }} name="id">
            <Input />
          </Form.Item>
          <Form.Item label="Descrição" name="descricao" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Input />
          </Form.Item>

          <Form.Item label="Pontuação" name="pontuacao">
            <Input />
          </Form.Item>
          <Form.Item label="Remuneração" name="remuneracao" normalize={(v) => moneyMask.format(moneyMask.normalize(v))}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
export default ModalidadeForm;
