import { useEffect, useState } from 'react';
import { Card, Table, Input } from 'antd';
import { moneyNegMask } from '@utils/masks';
import { GetMedia } from '@actions/dashboardActions';
import { filterobject } from '@utils/filterandsort';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

function MediaVendas() {
  const dispatch = useAppDispatch();
  const { medias, loadingmedias } = useAppSelector((state) => state.dashboard.medias);
  const [filter, setFilter] = useState('');
  useEffect(() => {
    dispatch(GetMedia());
  }, []);
  return (
    <Card size="small" title="Valor médio de venda" style={{ height: 370 }}>
      <div style={{ textAlign: 'right' }}>
        <Input style={{ width: 200 }} onChange={(e) => setFilter(e.target.value)} placeholder="Buscar" allowClear />
      </div>
      <Table
        size="small"
        showHeader={false}
        rowKey="id"
        dataSource={filterobject(medias, filter)}
        loading={loadingmedias}
        pagination={false}
        scroll={{ y: 280 }}
      >
        <Table.Column dataIndex="nome" />
        <Table.Column dataIndex="media" render={(text) => moneyNegMask.format(text)} />
      </Table>
    </Card>
  );
}

export default MediaVendas;
