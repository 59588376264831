import { AddArquivo, DeleteArquivo, DownloadArquivo, GetArquivos } from '@actions/arquivosActions';
import { ExtendedAction } from '@config/reduxStore';
import { Arquivo } from '@models/Arquivo';
import { createReducer } from '@reduxjs/toolkit';
import { notification } from 'antd';

interface ArquivosReducer {
  items: Arquivo[];
  loading: boolean;
  arquivossaving: boolean;
  feedback: string;
}
var initialState: ArquivosReducer = {
  items: [],
  loading: false,
  arquivossaving: false,
  feedback: '',
};
export default createReducer(initialState, (builder) => {
  builder.addCase(DeleteArquivo.pending, (state) => {
    return {
      ...state,
      loading: true,
    };
  });
  builder.addCase(DeleteArquivo.rejected, (state) => {
    notification.error({ message: 'Erro ao excluir arquivo' });
    return {
      ...state,
      loading: false,
    };
  });
  builder.addCase(DeleteArquivo.fulfilled, (state, action: ExtendedAction) => {
    notification.success({ message: 'Arquivo excluído com sucesso!' });
    return {
      ...state,
      loading: false,
      items: [...state.items.filter((c) => c.id !== action.payload.data)],
    };
  });
  builder.addCase(AddArquivo.pending, (state) => {
    return {
      ...state,
      arquivossaving: true,
    };
  });
  builder.addCase(AddArquivo.rejected, (state) => {
    notification.error({ message: 'Erro ao adicionar arquivo!' });
    return {
      ...state,
      arquivossaving: false,
    };
  });
  builder.addCase(AddArquivo.fulfilled, (state, action: ExtendedAction) => {
    notification.success({ message: 'Arquivo adicionado com sucesso!' });
    return {
      ...state,
      arquivossaving: false,
      items: [...state.items, action.payload.data],
    };
  });
  builder.addCase(GetArquivos.pending, (state) => {
    return { ...state, loading: true };
  });
  builder.addCase(GetArquivos.rejected, (state) => {
    return { ...state, loading: false };
  });
  builder.addCase(GetArquivos.fulfilled, (state, action: ExtendedAction) => {
    return { ...state, loading: false, items: action.payload.data };
  });
  builder.addCase(DownloadArquivo, (state) => {
    return { ...state, loading: !state.loading };
  });
});
