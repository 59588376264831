import { apiEndpoints } from '@constants/apiEndpoints';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Axios } from '@config/axios';

export const getDiarios = createAsyncThunk(
  'diarios/getDiarios',
  async () => await Axios.get(apiEndpoints.DIARIOS.GET_DIARIOS)
);
export const salvarDiario = createAsyncThunk(
  'diarios/salvarDiario',
  async (data: any, apiThunk) =>
    await Axios.post(apiEndpoints.DIARIOS.SALVAR_DIARIO, data).catch((res) => apiThunk.rejectWithValue(res))
);
export const getResponsaveis = createAsyncThunk(
  'diarios/getResponsaveis',
  async () => await Axios.get(apiEndpoints.DIARIOS.GET_RESPONSAVEIS)
);
export const getAuditores = createAsyncThunk(
  'diarios/getAuditores',
  async () => await Axios.get(apiEndpoints.DIARIOS.GET_AUDITORES)
);

export const getMestres = createAsyncThunk(
  'diarios/getMestres',
  async () => await Axios.get(apiEndpoints.DIARIOS.GET_MESTRES)
);
export const getObras = createAsyncThunk('diarios/getObras', async () => await Axios.get('/api/v2/Diarios/GetObras'));
export const getDiario = createAsyncThunk(
  'diarios/getDiario',
  async (id: number | string) => await Axios.get(apiEndpoints.DIARIOS.GET_DIARIO, { params: { id } })
);
export const getDiariosObra = createAsyncThunk(
  'diarios/getDiariosObra',
  async (id: number) => await Axios.get(apiEndpoints.DIARIOS.GET_DIARIOS_OBRA, { params: { id } })
);
export const saveFiles = createAsyncThunk(
  'diarios/saveFiles',
  async (form: FormData) => await Axios.post(apiEndpoints.DIARIOS.POST_FILE, form)
);

export const deleteDiario = createAsyncThunk(
  'diarios/deleteDiario',
  async (id: number) => await Axios.delete(apiEndpoints.DIARIOS.DELETE_DIARIO, { params: { id } })
);
