import { createNumberMask, createTextMask } from 'redux-form-input-masks';

interface numberMaskOptions {
  prefix?: string;
  suffix?: string;
  decimalPlaces?: number;
  multiplier?: number;
  stringValue?: boolean;
  allowEmpty?: boolean;
  allowNegative?: boolean;
  showPlusSign?: boolean;
  spaceAfterSign?: boolean;
  locale?: string;
  onChange?: (value: number | string) => void;
}
interface maskDefinition {
  regExp: RegExp;
  transform?: Function;
}
interface maskDefinitions {
  [key: string]: maskDefinition;
  [key: number]: maskDefinition;
}
interface textMaskOptions {
  pattern: string;
  placeholder?: string;
  maskDefinitions?: maskDefinitions;
  guide?: boolean;
  stripMask?: boolean;
  allowEmpty?: boolean;
  onChange?: (value: string) => void;
  onCompletePattern?: (value: string) => void;
}
const createCustomNumberMask = (data: numberMaskOptions) => {
  const numberMask = createNumberMask(data);
  return {
    ...numberMask,
    normalize: (value: string) => numberMask.normalize(value, '') as number,
  };
};
const createCustomTextMask = (data: textMaskOptions) => {
  const numberMask = createTextMask(data);
  return {
    ...numberMask,
    normalize: (value: string) => numberMask.normalize(value, ''),
  };
};
export const doubleMask = createCustomNumberMask({
  decimalPlaces: 2,
  allowNegative: true,
  locale: 'pt-BR',
});
export const numberingMask = createCustomNumberMask({
  decimalPlaces: 1,
  allowNegative: true,
  locale: 'en-US',
});
export const doubleMask1 = createCustomNumberMask({
  decimalPlaces: 1,
  allowNegative: true,
  suffix: '%',
  locale: 'pt-BR',
});
export const doubleMask2 = createCustomNumberMask({
  decimalPlaces: 2,
  allowNegative: true,
  suffix: '%',
  locale: 'pt-BR',
});
export const doubleMaskPrecision = createCustomNumberMask({
  decimalPlaces: 4,
  allowNegative: true,
  locale: 'pt-BR',
});

export const moneyMask = createCustomNumberMask({
  prefix: 'R$ ',
  decimalPlaces: 2,
  locale: 'pt-BR',
});
export const moneyMaskPrecision = createCustomNumberMask({
  prefix: 'R$ ',
  decimalPlaces: 4,
  locale: 'pt-BR',
});
export const moneyNegMask = createCustomNumberMask({
  prefix: 'R$ ',
  decimalPlaces: 2,
  locale: 'pt-BR',
  allowNegative: true,
});
export const cepMask = createCustomTextMask({
  allowEmpty: true,
  pattern: '99999-999',
  guide: false,
});
export const patrimonioMask = createCustomTextMask({
  allowEmpty: true,
  pattern: '9999',
  guide: false,
});
export const mobMask = createCustomTextMask({
  allowEmpty: true,
  pattern: '(99)99999-9999',
  guide: false,
});
export const cpfMask = createCustomTextMask({
  allowEmpty: true,
  pattern: '999.999.999-99',
  guide: false,
});
export const cnpjMask = createCustomTextMask({
  allowEmpty: true,
  pattern: '99.999.999/9999-99',
  guide: false,
});
export const fixoMask = createCustomTextMask({
  allowEmpty: true,
  pattern: '(99)9999-9999',
  guide: false,
});
export const intMask = createCustomNumberMask({ locale: 'pt-BR' });
