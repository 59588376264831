import { SetPageHeader } from '@actions/userActions';
import { useAppDispatch } from '@config/reduxStore';
import { PedidoList } from '@models/Pedidos';
import { EnviarPedido } from '@services/api/api.pedidos';
import { Button, Form, notification, Space } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import PedidoFormGeral from './PedidoForm.Geral';
import PedidoFormItens from './PedidoForm.Itens';

function PedidoForm() {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<PedidoList>();
  const enviarPedido = EnviarPedido();
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Novo Pedido',
        subtitle: '',
        extras: [
          <Button size="small" type="primary" onClick={() => form.submit()} loading={enviarPedido.isLoading}>
            Enviar
          </Button>,
        ],
        menu: 'pedidos',
        backbutton: true,
      })
    );
  }, [form, enviarPedido.isLoading]);

  const handleSubmit = (values: PedidoList) => {
    if (values.itensPedido.length === 0) {
      notification.error({ message: 'Ordem não possui itens' });
      return;
    }
    enviarPedido.mutate({ ...values, prazoEntrega: moment(values.prazoEntrega).format('YYYY-MM-DD') });
  };
  return (
    <Form form={form} onFinish={handleSubmit} layout="vertical" scrollToFirstError>
      <Space direction="vertical" style={{ width: '100%' }}>
        <PedidoFormGeral />
        <PedidoFormItens />
      </Space>
    </Form>
  );
}

export default PedidoForm;
