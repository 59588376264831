import { useEffect, useState } from 'react';
import { SetPageHeader } from '@actions/userActions';
import { Link } from 'react-router-dom';
import {
  Button,
  Form,
  Select,
  Input,
  InputNumber,
  Table,
  message,
  Descriptions,
  Spin,
  Modal,
  Typography,
  Affix,
  Alert,
  Space,
} from 'antd';
import { GetCasas } from '@actions/casasActions';
import { GetMateriais, FormMaterial } from '@actions/materiaisActions';
import { GetCategorias, FormCategoria } from '@actions/categoriasActions';
import { moneyMask, doubleMask, doubleMaskPrecision, moneyMaskPrecision } from '@utils/masks';
import { PlusOutlined } from '@ant-design/icons';
import { GetOrcamento, SalvarItem, AtualizaMassa, AddComposicaoOrcamento } from '@actions/orcamentosActions';
import { useParams } from 'react-router-dom';
import MaterialForm from '../Materiais/MaterialForm';
import CategoriaForm from '../Categorias/CategoriaForm';
import { Permission } from '@hooks//usePermissions';
import { GetGrupos } from '@actions/gruposActions';
import { GetComposicoes } from '@actions/composicoesActions';
import OrcamentoTableForm from './OrcamentoTableForm';
import GrupoForm from './GrupoForm';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { OrcamentoItem } from '@models/OrcamentoItem';
import { Item } from '@models/Item';
import { Composicao } from '@models/Composicao';
import { isValidType } from '@utils/testType';

function OrcamentoForm() {
  const [addform] = Form.useForm();
  const [grupoform] = Form.useForm();
  const dispatch = useAppDispatch();
  const { loadingcasas } = useAppSelector((state) => state.casas);
  const { composicoes } = useAppSelector((state) => state.composicoes);
  const { materiais, loadingmateriais } = useAppSelector((state) => state.materiais);
  const { categorias, loadingcategorias } = useAppSelector((state) => state.categorias);
  const { loadinggrupos, grupos } = useAppSelector((state) => state.grupos);
  const [addmodal, setaddmodal] = useState(false);
  const [addgrupomodal, setaddgrupomodal] = useState(false);
  const [atualizarMassaModal, SetAtualizarMassaModal] = useState(false);
  const [addcomposicaoform] = Form.useForm<Composicao>();
  const { savingorcamento, orcamento, loadingorcamentos, addingitem, atualizandomassa } = useAppSelector(
    (state) => state.orcamentos
  );
  const [massaForm] = Form.useForm<OrcamentoItem>();
  const { id } = useParams();
  const [addComposicaoModal, SetAddComposicaoModal] = useState(false);
  const [selectedCategoria, setSelectedCategoria] = useState(null);
  useEffect(() => {
    dispatch(GetCasas());
    dispatch(GetMateriais());
    dispatch(GetCategorias());
    dispatch(GetGrupos(id));
    dispatch(GetComposicoes());
    if (id) dispatch(GetOrcamento(id, true));
  }, [id]);
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Engenharia',
        backbutton: true,
        extras: [
          <Space>
            <Permission key="1" permission="orcamento.get">
              <Link target="_blank" to={`/OrcamentoView/${id}`}>
                <Button type="dashed">Visualizar Orçamento</Button>
              </Link>
            </Permission>
            <Permission permission="orcamento.update">
              {orcamento
                ? !orcamento.bloqueado && (
                    <Button type="primary" onClick={() => SetAtualizarMassaModal(true)}>
                      Atualizar Preço em Massa
                    </Button>
                  )
                : null}
            </Permission>
            <Permission key="2" permission="orcamento.update">
              {orcamento
                ? !orcamento.bloqueado && (
                    <Button type="primary" onClick={() => setaddmodal(true)}>
                      Adicionar Item
                    </Button>
                  )
                : null}
            </Permission>
            <Permission key="2" permission="orcamento.update">
              {orcamento
                ? !orcamento.bloqueado && (
                    <Button type="primary" onClick={() => SetAddComposicaoModal(true)}>
                      Adicionar Composição
                    </Button>
                  )
                : null}
            </Permission>
          </Space>,
        ],
        subtitle: 'Orçamento',
        menu: 'orcamentos',
      })
    );
  }, [savingorcamento, loadingcasas, loadingorcamentos, orcamento, Affix]);
  return (
    <Spin spinning={savingorcamento || loadingcasas || loadingorcamentos || loadingmateriais || loadingorcamentos}>
      {orcamento && !orcamento.area && (
        <Alert
          type="info"
          closable
          message="Preencha a área construída na ficha do imóvel para visualizar os valores por m2"
        />
      )}
      <Modal
        title="Atualizar preço em massa"
        open={atualizarMassaModal}
        onCancel={() => SetAtualizarMassaModal(false)}
        okButtonProps={{ loading: atualizandomassa }}
        onOk={() => massaForm.submit()}
      >
        <Form
          form={massaForm}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          onFinish={(values: OrcamentoItem) =>
            dispatch(AtualizaMassa({ ...values, valor: moneyMask.normalize(values.valor), orcamentoID: id }))
          }
        >
          <Form.Item name="itemID" label="Item" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Select
              style={{ width: '100%' }}
              placeholder="Escolha o item do orçamento"
              showSearch
              optionFilterProp="children"
            >
              {orcamento &&
                orcamento.orcamentosItens
                  .map((c) => c.itemID)
                  .filter((v, i, a) => a.indexOf(v) === i)
                  .map((c) => orcamento.orcamentosItens.find((d) => d.itemID === c))
                  .map((c) => (
                    <Select.Option key={c.itemID} value={c.itemID}>
                      {`${c.unidade} - ${c.item}`}
                    </Select.Option>
                  ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="valor"
            label="Novo Valor"
            initialValue={moneyMask.format(0)}
            normalize={(v) => moneyMask.format(moneyMask.normalize(v))}
            rules={[{ required: true, message: 'Campo obrigatório' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <CategoriaForm />
      <MaterialForm />
      <GrupoForm visible={addgrupomodal} setVisible={setaddgrupomodal} item={selectedCategoria} />
      <Modal
        open={addmodal}
        width={800}
        title="Adicionar Item"
        onCancel={() => setaddmodal(false)}
        footer={[
          <Button key="1" onClick={() => setaddmodal(false)}>
            Fechar
          </Button>,
          <Button type="primary" key="2" loading={addingitem} onClick={() => addform.submit()}>
            Adicionar
          </Button>,
        ]}
      >
        <Form
          name="newitemForm"
          form={addform}
          wrapperCol={{ span: 20 }}
          labelCol={{ span: 4 }}
          initialValues={{ tipo: 0 }}
          onFinish={(values) => {
            if (
              orcamento.orcamentosItens.find(
                (c) =>
                  c.categoriaID === values.categoriaID && c.itemID === values.itemID && c.grupoID === values.grupoID
              )
            )
              message.warning('Item já adicionado');
            else {
              dispatch(
                SalvarItem({
                  ...values,
                  quantidade: doubleMask.normalize(values.quantidade),
                  valorUnitario: moneyMask.normalize(values.valorUnitario),
                  valorTotal: moneyMask.normalize(values.valorUnitario) * doubleMask.normalize(values.quantidade),
                  orcamentoID: id,
                })
              );
            }
          }}
        >
          <Form.Item shouldUpdate noStyle>
            {({ setFieldsValue }) => (
              <Form.Item
                label="Categoria"
                name="categoriaID"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input.Group>
                  <Select
                    showSearch
                    onChange={(v) => {
                      setSelectedCategoria({ categoriaID: v });
                      setFieldsValue({ categoriaID: v, grupoID: null });
                    }}
                    style={{ width: '100%' }}
                    optionFilterProp="children"
                    loading={loadingcategorias}
                    suffixIcon={
                      <Button
                        size="small"
                        icon={<PlusOutlined />}
                        style={{ cursor: 'pointer' }}
                        onClick={() => dispatch(FormCategoria(null, true))}
                      />
                    }
                  >
                    {categorias.map((c) => (
                      <Select.Option key={c.id} value={c.id}>
                        {c.descricao}
                      </Select.Option>
                    ))}
                  </Select>
                </Input.Group>
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item label="Grupo" name="grupoID" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                <Input.Group>
                  <Select
                    showSearch
                    onChange={(v) => setFieldsValue({ grupoID: v })}
                    style={{ width: '100%' }}
                    optionFilterProp="children"
                    loading={loadinggrupos}
                    value={getFieldValue('grupoID')}
                    suffixIcon={
                      <Button
                        disabled={!getFieldValue('categoriaID')}
                        size="small"
                        icon={<PlusOutlined />}
                        onClick={() => {
                          grupoform.setFieldsValue({
                            orcamentoID: id,
                            id: null,
                            categoriaID: getFieldValue('categoriaID'),
                          });
                          setaddgrupomodal(true);
                        }}
                      />
                    }
                  >
                    {grupos
                      .filter((c) => c.categoriaID === getFieldValue('categoriaID') && c.orcamentoID === Number(id))
                      .map((c) => (
                        <Select.Option key={c.id} value={c.id}>
                          {c.descricao}
                        </Select.Option>
                      ))}
                  </Select>
                </Input.Group>
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ setFieldsValue }) => (
              <Form.Item label="Item" name="itemID" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                <Input.Group>
                  <Select
                    style={{ width: '100%' }}
                    showSearch
                    onChange={(v) =>
                      setFieldsValue({
                        itemID: v,
                        valorUnitario: moneyMask.format(materiais.find((f) => f.id === v).valorUnitario),
                      })
                    }
                    optionFilterProp="children"
                    loading={loadingmateriais}
                    suffixIcon={
                      <Button size="small" icon={<PlusOutlined />} onClick={() => dispatch(FormMaterial(null, true))} />
                    }
                  >
                    {materiais.map((c) => (
                      <Select.Option key={c.id} value={c.id}>
                        {`${c.unidade} - ${c.descricao}`}
                      </Select.Option>
                    ))}
                  </Select>
                </Input.Group>
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item
            label="Quant."
            name="quantidade"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
            normalize={(v) => doubleMask.format(moneyMask.normalize(v))}
          >
            <Input size="small" style={{ width: 100 }} />
          </Form.Item>
          <Form.Item
            label="V. Unit."
            name="valorUnitario"
            valuePropName="children"
            normalize={(v) => moneyMask.format(v)}
          >
            <Typography.Text />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        open={addComposicaoModal}
        width={800}
        title="Adicionar Composição"
        onCancel={() => SetAddComposicaoModal(false)}
        footer={[
          <Button key="1" onClick={() => SetAddComposicaoModal(false)}>
            Fechar
          </Button>,
          <Button type="primary" key="2" loading={addingitem} onClick={() => addcomposicaoform.submit()}>
            Adicionar
          </Button>,
        ]}
      >
        <Form
          form={addcomposicaoform}
          wrapperCol={{ span: 20 }}
          labelCol={{ span: 4 }}
          initialValues={{ tipo: 0 }}
          onFinish={(values) => {
            dispatch(AddComposicaoOrcamento({ ...values, orcamentoID: id }));
          }}
        >
          <Form.Item name="composicaoID" label="Composição" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Select showSearch optionFilterProp="children">
              {composicoes.map((c) => (
                <Select.Option key={c.id} value={c.id}>
                  {`${c.unidade} - ${c.descricao}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="categoriaID" label="Categoria" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Select
              showSearch
              optionFilterProp="children"
              suffixIcon={<PlusOutlined onClick={() => dispatch(FormCategoria(null, true))} />}
            >
              {categorias.map((c) => (
                <Select.Option key={c.id} value={c.id}>
                  {c.descricao}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Quant."
            name="quantidade"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
            initialValue={0}
          >
            <InputNumber size="small" decimalSeparator="," precision={2} min={0} />
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => (
              <Table
                rowKey="id"
                size="small"
                rowClassName={(row) => (row.tipo === 1 ? 'servico' : null)}
                dataSource={
                  getFieldValue('composicaoID') &&
                  composicoes.find((c) => c.id === getFieldValue('composicaoID')).itensComposicao
                }
              >
                <Table.Column
                  title="Item"
                  dataIndex="descricao"
                  render={(_, row: Item) => `${row.unidade} - ${row.descricao}`}
                />
                <Table.Column title="Tipo" dataIndex="tipo" />
                <Table.Column title="Valor Unitário" dataIndex="valorUnitario" render={(v) => moneyMask.format(v)} />
                <Table.Column
                  title="Quantidade"
                  dataIndex="quantidade"
                  render={(v) => doubleMaskPrecision.format(v * getFieldValue('quantidade'))}
                />
                <Table.Column
                  title="Valor Total"
                  dataIndex="valorTotal"
                  render={(v) => moneyMaskPrecision.format(v * getFieldValue('quantidade'))}
                />
              </Table>
            )}
          </Form.Item>
        </Form>
      </Modal>
      <div style={{ maxHeight: 'calc(100vh - 100px)', overflowY: 'scroll' }}>
        <Descriptions
          style={{ backgroundColor: 'white' }}
          bordered
          size="small"
          layout="vertical"
          title="Informações Gerais"
          extra={
            <Typography.Text key="1" type="danger" strong>
              {orcamento ? (orcamento.bloqueado ? 'Orçamento Bloqueado' : null) : null}
            </Typography.Text>
          }
        >
          <Descriptions.Item label="Empreendimento">{orcamento?.empreendimento}</Descriptions.Item>
          <Descriptions.Item label="Casa">{isValidType<string>(orcamento?.casa) && orcamento?.casa}</Descriptions.Item>
          <Descriptions.Item label="Descricao">{orcamento?.descricao}</Descriptions.Item>
          <Descriptions.Item label="Área">{doubleMask.format(orcamento?.area)} m2</Descriptions.Item>
          <Descriptions.Item label="Total Orçado">{`${moneyMask.format(
            orcamento?.orcamentosItens.reduce((a, b) => a + b.valorTotal, 0)
          )} (${doubleMask.format(
            orcamento?.orcamentosItens.reduce((a, b) => a + b.valorTotal, 0) / orcamento?.area
          )} R$/m2)`}</Descriptions.Item>
        </Descriptions>
        <br />
        {orcamento && <OrcamentoTableForm orcamentosItens={orcamento.orcamentosItens} />}
      </div>
    </Spin>
  );
}

export default OrcamentoForm;
