import { useEffect } from 'react';
import { Modal, Form, Input, Button, Row, Col, Select } from 'antd';
import { SalvarEmpresaFaturamento, FormEmpresaFaturamento } from '@actions/empresasfaturamentoActions';
import { cnpjMask, mobMask, cpfMask, fixoMask, cepMask } from '@utils/masks';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { EmpresaFaturamento } from '@models/EmpresaFaturamento';

function EmpresaFaturamentoForm() {
  const dispatch = useAppDispatch();
  const empresasfaturamento = useAppSelector((state) => state.empresasfaturamento);
  const [form] = Form.useForm();
  const submitForm = (values: EmpresaFaturamento) => {
    dispatch(SalvarEmpresaFaturamento(values));
  };
  useEffect(() => {
    form.resetFields();
    if (empresasfaturamento.empresafaturamento) {
      form.setFieldsValue(
        empresasfaturamento.empresasfaturamento.find((c) => c.id === empresasfaturamento.empresafaturamento)
      );
    }
  }, [empresasfaturamento, empresasfaturamento.empresafaturamento, empresasfaturamento.empresasfaturamento, form]);
  return (
    <div>
      <Modal
        open={empresasfaturamento.formview}
        onCancel={() => dispatch(FormEmpresaFaturamento(null, false))}
        title="Cadastro EmpresaFaturamento"
        footer={[
          <Button onClick={() => dispatch(FormEmpresaFaturamento(null, false))} key="2">
            Fechar
          </Button>,
          <Button
            key="3"
            type="primary"
            onClick={() => form.submit()}
            loading={empresasfaturamento.savingempresafaturamento}
          >
            Salvar
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form} onFinish={submitForm} name="empresafaturamentoForm">
          <Form.Item style={{ display: 'none' }} name="id">
            <Input />
          </Form.Item>
          <Form.Item
            label="CPF/CNPJ"
            name="cpF_CNPJ"
            normalize={(v) => {
              if (v.length > 14) return cnpjMask.format(cnpjMask.normalize(v));
              else return cpfMask.format(cpfMask.normalize(v));
            }}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Nome" name="nome" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Endereço" name="endereco">
            <Input />
          </Form.Item>
          <Row gutter={8}>
            <Col span={8}>
              <Form.Item label="Cidade" name="cidade">
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Estado" name="estado">
                <Select showSearch>
                  <Select.Option value="Acre">Acre</Select.Option>
                  <Select.Option value="Alagoas">Alagoas</Select.Option>
                  <Select.Option value="Amazonas">Amazonas</Select.Option>
                  <Select.Option value="Amapá">Amapá</Select.Option>
                  <Select.Option value="Bahia">Bahia</Select.Option>
                  <Select.Option value="Ceará">Ceará</Select.Option>
                  <Select.Option value="Distrito Federal">Distrito Federal</Select.Option>
                  <Select.Option value="Espírito Santo">Espírito Santo</Select.Option>
                  <Select.Option value="Goiás">Goiás</Select.Option>
                  <Select.Option value="Maranhão">Maranhão</Select.Option>
                  <Select.Option value="Mato Grosso">Mato Grosso</Select.Option>
                  <Select.Option value="Mato Grosso do Sul">Mato Grosso do Sul</Select.Option>
                  <Select.Option value="Minas Gerais">Minas Gerais</Select.Option>
                  <Select.Option value="Pará">Pará</Select.Option>
                  <Select.Option value="Paraíba">Paraíba</Select.Option>
                  <Select.Option value="Paraná">Paraná</Select.Option>
                  <Select.Option value="Pernambuco">Pernambuco</Select.Option>
                  <Select.Option value="Piauí">Piauí</Select.Option>
                  <Select.Option value="Rio de Janeiro">Rio de Janeiro</Select.Option>
                  <Select.Option value="Rio Grande do Norte">Rio Grande do Norte</Select.Option>
                  <Select.Option value="Rondônia">Rondônia</Select.Option>
                  <Select.Option value="Rio Grande do Sul">Rio Grande do Sul</Select.Option>
                  <Select.Option value="Roraima">Roraima</Select.Option>
                  <Select.Option value="Santa Catarina">Santa Catarina</Select.Option>
                  <Select.Option value="Sergipe">Sergipe</Select.Option>
                  <Select.Option value="São Paulo">São Paulo</Select.Option>
                  <Select.Option value="Tocantins">Tocantins</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="CEP" name="cep" normalize={(v) => cepMask.format(cepMask.normalize(v))}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={10}>
              <Form.Item
                label="Telefone"
                name="telefone"
                normalize={(v) => {
                  if (v.length > 13) return mobMask.format(mobMask.normalize(v));
                  else return fixoMask.format(fixoMask.normalize(v));
                }}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}
export default EmpresaFaturamentoForm;
