import { GetClientesPerspectiva } from '@actions/correspondenciaActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Spin } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import { CloseCircleTwoTone, CheckCircleTwoTone } from '@ant-design/icons';
import { moneyMask } from '@utils/masks';
import logofacilitar from '../../../../assets/img/logofacilitar.png';
import './print.scss';

function PerspectivaPrint() {
  const dispatch = useAppDispatch();
  const { loadingclientes, clientesp } = useAppSelector((state) => state.correspondencia);
  useEffect(() => {
    dispatch(GetClientesPerspectiva());
  }, []);
  return (
    <div>
      <Spin spinning={loadingclientes}>
        <table className="print-table-header">
          <thead>
            <tr>
              <th>
                <img src={logofacilitar} alt="logo" width={300} />
              </th>
              <th className="title">Clientes Perspectiva</th>
              <th>{moment().format('DD/MM/YYYY')}</th>
            </tr>
          </thead>
        </table>
        <table className="print-table-body">
          <thead>
            <tr>
              <th></th>
              <th>Vencimento Crédito</th>
              <th>Status</th>
              <th>Proponente</th>
              <th>CPF</th>
              <th>Modalidade</th>
              <th>Município</th>
              <th>Parceiro</th>
              <th>Agência</th>
              <th>Engenharia</th>
              <th>Doc Vendedor</th>
              <th>SIOPI</th>
              <th>PF3</th>
              <th>Valor Financiado</th>
            </tr>
          </thead>
          <tbody>
            {clientesp.map((c, index) => (
              <tr key={c.id}>
                <td className="center">{index + 1}</td>
                <td className="center">{moment(c.vencimentoCredito).format('DD/MM/YYYY')}</td>
                <td className="center">{c.status}</td>
                <td>{c.clienteNome1}</td>
                <td>{c.clienteCPF1}</td>
                <td className="center">{c.modalidade}</td>
                <td>{c.municipio}</td>
                <td>{c.parceiro}</td>
                <td className="center">{String(c.agencia)}</td>
                <td className="center">
                  {c.clSolicitarEngenharia ? (
                    <CheckCircleTwoTone twoToneColor="green" />
                  ) : (
                    <CloseCircleTwoTone twoToneColor="red" />
                  )}
                </td>
                <td className="center">
                  {c.clDocumentosVendedorSistema ? (
                    <CheckCircleTwoTone twoToneColor="green" />
                  ) : (
                    <CloseCircleTwoTone twoToneColor="red" />
                  )}
                </td>
                <td className="center">
                  {c.clSIOPI ? <CheckCircleTwoTone twoToneColor="green" /> : <CloseCircleTwoTone twoToneColor="red" />}
                </td>
                <td>
                  {c.clPF3 ? <CheckCircleTwoTone twoToneColor="green" /> : <CloseCircleTwoTone twoToneColor="red" />}
                </td>
                <td className="right">{moneyMask.format(c.valorFinanciamento)}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={13} className="right">
                TOTAL
              </td>
              <td className="right">{moneyMask.format(clientesp.reduce((a, b) => a + b.valorFinanciamento, 0))}</td>
            </tr>
          </tfoot>
        </table>
      </Spin>
    </div>
  );
}

export default PerspectivaPrint;
