import { Input, Button, Form } from 'antd';
import { ForgotPassword } from '@actions/userActions';
import Text from 'antd/lib/typography/Text';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

interface FormData {
  Email: string;
}
function ForgotView() {
  const dispatch = useAppDispatch();
  const { forgot } = useAppSelector((state) => state.user);
  const [form] = Form.useForm<FormData>();

  const onFinishForgot = (values: FormData) => {
    dispatch(ForgotPassword(values.Email));
  };

  return (
    <div>
      <div className="back" />
      <div
        style={{
          minWidth: 300,
          maxWidth: 400,
          height: 300,
          padding: 20,
        }}
      >
        <Form
          onFinish={onFinishForgot}
          form={form}
          name="loginForm"
          layout="vertical"
          wrapperCol={{ span: 20 }}
          labelCol={{ span: 4 }}
        >
          <Form.Item
            name="Email"
            label="Email"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório!',
              },
              {
                type: 'email',
                message: 'Email inválido',
              },
            ]}
          >
            <Input placeholder="Usuário" />
          </Form.Item>
          <Form.Item wrapperCol={{ span: 20 }}>
            <Button loading={forgot.loading} block type="primary" htmlType="submit">
              Solicitar Renovação
            </Button>
          </Form.Item>
          <Form.Item name="feedback">
            <Text type="danger">{forgot.feedback}</Text>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default ForgotView;
