import { AppDispatch } from '@config/reduxStore';
import { apiEndpoints } from '@constants/apiEndpoints';
import { OrcamentoItem } from '@models/OrcamentoItem';
import { Axios } from '@config/axios';
export const GetOrcamentos = function (ativo: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_ORCAMENTOS',
      payload: Axios.get(apiEndpoints.ORCAMENTOS.GET_ORCAMENTOS, { params: { ativo } }),
    });
  };
};
export const GetOrcamentosClean = function (ativo: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_ORCAMENTOS',
      payload: Axios.get(apiEndpoints.ORCAMENTOS.GET_ORCAMENTOS_CLEAN, { params: { ativo } }),
    });
  };
};
export const GetOrcamentosOC = function (ativo: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_ORCAMENTOSOC',
      payload: Axios.get(apiEndpoints.ORCAMENTOS.GET_ORCAMENTOS_OC, { params: { ativo } }),
    });
  };
};
export const GetOrcamentosList = function (ativo: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_ORCAMENTOS_LIST',
      payload: Axios.get(apiEndpoints.ORCAMENTOS.GET_ORCAMENTOS_LIST, { params: { ativo } }),
    });
  };
};

export const GetOrcamentosServer = function (values: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_ORCAMENTOS_SERVER',
      payload: Axios.post(apiEndpoints.ORCAMENTOS.GET_ORCAMENTOS_SERVER, values),
    });
  };
};
export const GetFluxoMensal = function (values: { mes: moment.Moment; ativo: boolean }) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_FLUXO_MENSAL',
      payload: Axios.post(apiEndpoints.ORCAMENTOS.GET_MENSAL, values),
    });
  };
};
export const GetOrcamento = function (id: number | string, edit: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_ORCAMENTO',
      payload: Axios.get(apiEndpoints.ORCAMENTOS.GET_ORCAMENTO, { params: { id, edit } }),
    });
  };
};
export const AtualizaMassa = function (values: { itemID: number; valor: number; orcamentoID: number | string }) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'ATUALIZA_MASSA',
      payload: Axios.post(apiEndpoints.ORCAMENTOS.ATUALIZAR_MASSA, values),
    });
  };
};
export const GetOrcamentoRealizado = function (id: number | string, tipo?: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_ORCAMENTO_REALIZADO',
      payload: Axios.get(apiEndpoints.ORCAMENTOS.GET_ORCAMENTO_REALIZADO, { params: { id, tipo } }),
    });
  };
};
export const GetOrcamentoItem = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_ORCAMENTO_ITEM',
      payload: Axios.get(apiEndpoints.ORCAMENTOS.GET_ORCAMENTO_ITEM, { params: { id } }),
    });
  };
};
export const GetOrcamentoItens = function (orcamentoID: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_ORCAMENTO_ITENS',
      payload: Axios.get(apiEndpoints.ORCAMENTOS.GET_ORCAMENTO_ITENS, { params: { orcamentoID } }),
    });
  };
};
export const BloquearOrcamento = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'BLOQUEAR_ORCAMENTO_ITENS',
      payload: Axios.get(apiEndpoints.ORCAMENTOS.BLOQUEAR_ORCAMENTO, { params: { id } }),
    });
  };
};
export const BloquearItem = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'BLOQUEAR_ITEM',
      payload: Axios.get(apiEndpoints.ORCAMENTOS.BLOQUEAR_ITEM, { params: { id } }),
    });
  };
};
export const AtivarOrcamento = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'ATIVAR_ORCAMENTO_ITENS',
      payload: Axios.get(apiEndpoints.ORCAMENTOS.ATIVAR_ORCAMENTO, { params: { id } }),
    });
  };
};
export const SalvarOrcamento = function (values: {
  id: number;
  deposito: boolean;
  descricao: string;
  empreendimentoID: number;
  casaID: number;
  planejavel: boolean;
  orcamentosItens: OrcamentoItem[];
}) {
  return function (dispatch: AppDispatch) {
    if (values.id) {
      dispatch({
        type: 'UPDATE_ORCAMENTO',
        payload: Axios.post(apiEndpoints.ORCAMENTOS.SALVAR_ORCAMENTO, { ...values }),
      });
    } else {
      dispatch({
        type: 'ADD_ORCAMENTO',
        payload: Axios.post(apiEndpoints.ORCAMENTOS.SALVAR_ORCAMENTO, { ...values }),
      });
    }
  };
};
export const SalvarDescricao = function (id: number, descricao: string) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SALVAR_DESCRICAO',
      payload: Axios.post(apiEndpoints.ORCAMENTOS.SALVAR_DESCRICAO, { ID: id, Descricao: descricao }),
    });
  };
};
export const ChangeItem = function (data: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'CHANGE_ITEM_ORCAMENTO',
      payload: data,
    });
  };
};
export const SalvarItem = function (values: OrcamentoItem) {
  return function (dispatch: AppDispatch) {
    if (values.id) {
      dispatch({
        type: 'UPDATE_ITEM_ORCAMENTO',
        payload: Axios.post(apiEndpoints.ORCAMENTOS.ADD_ITEM, { ...values }),
      });
    } else {
      dispatch({
        type: 'ADD_ITEM_ORCAMENTO',
        payload: Axios.post(apiEndpoints.ORCAMENTOS.ADD_ITEM, { ...values }),
      });
    }
  };
};
export const SetSelectedItens = function (values: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SELECTED_ITENS',
      payload: values,
    });
  };
};
export const DeleteItem = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DELETE_ITEM_ORCAMENTO',
      payload: Axios.get(apiEndpoints.ORCAMENTOS.DELETE_ITEM, { params: { id } }),
    });
  };
};
export const DeleteOrcamento = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DELETE_ORCAMENTO',
      payload: Axios.get(apiEndpoints.ORCAMENTOS.DELETE_ORCAMENTO, { params: { id } }),
    });
  };
};
export const FormOrcamento = function (id: number, open: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'FORM_ORCAMENTO',
      payload: { id, open },
    });
  };
};
export const CopyOrcamento = function (values: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'COPY_ORCAMENTO',
      payload: Axios.post(apiEndpoints.ORCAMENTOS.COPY_ORCAMENTO, values),
    });
  };
};
export const AddAprovador = function (values: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'ADD_APROVADOR',
      payload: Axios.post(apiEndpoints.ORCAMENTOS.ADD_APROVADOR, values),
    });
  };
};
export const RemoveAprovador = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'REMOVE_APROVADOR',
      payload: Axios.get(apiEndpoints.ORCAMENTOS.REMOVE_APROVADOR, { params: { id } }),
    });
  };
};
export const OpenComprasView = function (dados: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'OPEN_COMPRAS_VIEW',
      payload: dados,
    });
  };
};
export const OpenTransferenciaView = function (dados: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'OPEN_TRANSFERENCIA_VIEW',
      payload: dados,
    });
  };
};
export const CloseComprasView = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'CLOSE_COMPRAS_VIEW',
    });
  };
};
export const CloseTransferenciaView = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'CLOSE_TRANSFERENCIA_VIEW',
    });
  };
};
export const GetComprasItem = function (dados: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_COMPRAS_ITEM',
      payload: Axios.get(apiEndpoints.ORCAMENTOS.GET_COMPRAS_ITEM, { params: dados }),
    });
  };
};
export const SolicitarTransferencia = function (values: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SOLICITAR_TRANSFERENCIA',
      payload: Axios.post(apiEndpoints.ORCAMENTOS.SOLICITAR_TRANSFERENCIA, values),
    });
  };
};
export const AddComposicaoOrcamento = function (values: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'ADD_COMPOSICAO_ORCAMENTO',
      payload: Axios.post(apiEndpoints.ORCAMENTOS.ADD_COMPOSICAO_ORCAMENTO, values),
    });
  };
};
export const GetDashboardOrcamentos = function (ativo: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_DASHBOARD_ORCAMENTOS',
      payload: Axios.get(apiEndpoints.ORCAMENTOS.GET_DASHBOARD, { params: { ativo } }),
    });
  };
};
