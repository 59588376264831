import { useEffect } from 'react';
import { SetPageHeader } from '@actions/userActions';
import { GetInvestimentos } from '@actions/investidorActions';
import { Table, Descriptions, Button } from 'antd';
import { moneyNegMask } from '@utils/masks';
import { EyeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Investimento } from '@models/Investimento';

function DashboardInvestidor() {
  const dispatch = useAppDispatch();
  const { loadinginvestimentos, investimentos } = useAppSelector((state) => state.investidor);
  useEffect(() => {
    dispatch(GetInvestimentos());
    dispatch(
      SetPageHeader({
        title: 'Portal do Investidor',
        backbutton: false,
        extras: [],
        subtitle: 'Investimentos',
        menu: 'investimentos',
      })
    );
  }, []);
  return (
    <div>
      <Table
        loading={loadinginvestimentos}
        dataSource={investimentos}
        rowKey="id"
        size="small"
        pagination={false}
        scroll={{ x: 1024 }}
        footer={(data) => (
          <Descriptions size="small" title="Totais" bordered column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}>
            <Descriptions.Item label="Quotas">{data.map((c) => c.quotas).reduce((a, b) => a + b, 0)}</Descriptions.Item>
            <Descriptions.Item label="Valor da Quota">
              {moneyNegMask.format(data.map((c) => c.valorQuota).reduce((a, b) => a + b, 0))}
            </Descriptions.Item>
            <Descriptions.Item label="Necessário">
              {moneyNegMask.format(data.map((c) => c.aporteNecessario).reduce((a, b) => a + b, 0))}
            </Descriptions.Item>
            <Descriptions.Item label="Aportado">
              {moneyNegMask.format(data.map((c) => c.aportado).reduce((a, b) => a + b, 0))}
            </Descriptions.Item>
            <Descriptions.Item label="Pendente">
              {moneyNegMask.format(data.map((c) => c.aportePendente).reduce((a, b) => a + b, 0))}
            </Descriptions.Item>
            <Descriptions.Item label="Retornado">
              {moneyNegMask.format(data.map((c) => c.retornado).reduce((a, b) => a + b, 0))}
            </Descriptions.Item>
            <Descriptions.Item label="Resgatado">
              {moneyNegMask.format(data.map((c) => c.resgatado).reduce((a, b) => a + b, 0))}
            </Descriptions.Item>
            <Descriptions.Item label="Resgatado">
              {moneyNegMask.format(
                data.map((c) => c.retornado).reduce((a, b) => a + b, 0) -
                  data.map((c) => c.resgatado).reduce((a, b) => a + b, 0) -
                  data.map((c) => c.aportePendente).reduce((a, b) => a + b, 0)
              )}
            </Descriptions.Item>
          </Descriptions>
        )}
      >
        <Table.Column
          fixed="left"
          render={(_, row: Investimento) => (
            <Link to={`/RelatorioInvestimento/${row.id}`}>
              <Button size="small" icon={<EyeOutlined />} />
            </Link>
          )}
        />
        <Table.Column fixed="left" title="Empreendimento" dataIndex="empreendimento" />
        <Table.Column title="SCP" dataIndex="scp" />
        <Table.Column title="Quotas" dataIndex="quotas" />
        <Table.Column title="Valor da Quota" dataIndex="valorQuota" render={(text) => moneyNegMask.format(text)} />
        <Table.Column
          title="Aporte Necessário"
          dataIndex="aporteNecessario"
          render={(text) => moneyNegMask.format(text)}
        />
        <Table.Column title="Valor Aportado" dataIndex="aportado" render={(text) => moneyNegMask.format(text)} />
        <Table.Column title="Aporte Pendente" dataIndex="aportePendente" render={(text) => moneyNegMask.format(text)} />
        <Table.Column title="Valor Retornado" dataIndex="retornado" render={(text) => moneyNegMask.format(text)} />
        <Table.Column title="Valor Resgatado" dataIndex="resgatado" render={(text) => moneyNegMask.format(text)} />
        <Table.Column
          title="Saldo Disponível"
          render={(_, row: Investimento) => moneyNegMask.format(row.retornado - row.resgatado - row.aportePendente)}
        />
      </Table>
    </div>
  );
}

export default DashboardInvestidor;
