import {
  AprovarPedido,
  CancelarPedido,
  FinalizarPedido,
  GetAllPagination,
  GetPendentes,
} from '@actions/pedidosActions';
import { ExtendedAction } from '@config/reduxStore';
import { OrdemStatus } from '@constants/ordemStatus';
import { PedidoList } from '@models/Pedidos';
import { createReducer } from '@reduxjs/toolkit';
import { notification } from 'antd';

interface PedidosReducer {
  pedidos: PedidoList[];
  loadingPedidos: boolean;
  total: number;
  pedidosPendentes: PedidoList[];
  pedidosPendentesNum: number;
  loadingPendentes: boolean;
  aprovandoPedido: boolean;
  cancelandoPedido: boolean;
}

const initialState: PedidosReducer = {
  pedidos: [],
  loadingPedidos: false,
  total: 0,
  pedidosPendentes: [],
  pedidosPendentesNum: 0,
  loadingPendentes: false,
  aprovandoPedido: false,
  cancelandoPedido: false,
};

const pedidosReducer = createReducer(initialState, (builder) => {
  builder.addCase(FinalizarPedido.pending, (state) => {
    return { ...state, loadingPedidos: true };
  });
  builder.addCase(FinalizarPedido.rejected, (state, action: ExtendedAction) => {
    notification.error({ message: action.payload?.response?.data?.Message });
    return { ...state, loadingPedidos: false };
  });
  builder.addCase(FinalizarPedido.fulfilled, (state) => {
    notification.success({ message: 'Pedido finalizado com sucesso!' });
    return {
      ...state,
      loadingPedidos: false,
    };
  });
  builder.addCase(GetAllPagination.pending, (state) => {
    return { ...state, loadingPedidos: true };
  });
  builder.addCase(GetAllPagination.rejected, (state, action: ExtendedAction) => {
    notification.error({ message: action.payload?.response?.data?.Message });
    return { ...state, loadingPedidos: false };
  });
  builder.addCase(GetAllPagination.fulfilled, (state, action) => {
    return {
      ...state,
      loadingPedidos: false,
      pedidos: action.payload.data.results,
      total: action.payload.data.total,
    };
  });
  builder.addCase(GetPendentes.pending, (state) => {
    return { ...state, loadingPendentes: true };
  });
  builder.addCase(GetPendentes.rejected, (state, action: ExtendedAction) => {
    notification.error({ message: action.payload?.response?.data?.Message });
    return { ...state, loadingPendentes: false };
  });
  builder.addCase(GetPendentes.fulfilled, (state, action) => {
    return {
      ...state,
      loadingPendentes: false,
      pedidosPendentes: action.payload.data,
      pedidosPendentesNum: action.payload.data.length,
    };
  });
  builder.addCase(AprovarPedido.pending, (state) => {
    return { ...state, aprovandoPedido: true };
  });
  builder.addCase(AprovarPedido.rejected, (state, action: ExtendedAction) => {
    notification.error({ message: action.payload?.response?.data?.Message });
    return { ...state, aprovandoPedido: false };
  });
  builder.addCase(AprovarPedido.fulfilled, (state, action) => {
    notification.success({ message: 'Pedido aprovado com sucesso!' });
    return {
      ...state,
      aprovandoPedido: false,
      pedidosPendentes: state.pedidosPendentes.filter((c) => c.id !== action.payload.data),
    };
  });
  builder.addCase(CancelarPedido.pending, (state) => {
    return { ...state, cancelandoPedido: true };
  });
  builder.addCase(CancelarPedido.rejected, (state, action: ExtendedAction) => {
    notification.error({ message: action.payload?.response?.data?.Message });
    return { ...state, cancelandoPedido: false };
  });
  builder.addCase(CancelarPedido.fulfilled, (state, action: ExtendedAction) => {
    notification.success({ message: 'Pedido cancelado com sucesso!' });
    return {
      ...state,
      cancelandoPedido: false,
      pedidos: state.pedidos.map((c) => (c.id === action.meta.arg.id ? { ...c, situacao: OrdemStatus.Cancelada } : c)),
      pedidosPendentes: state.pedidosPendentes.filter((c) => c.id !== action.meta.arg.id),
    };
  });
});

export default pedidosReducer;
