import { AppDispatch } from '@config/reduxStore';
import { apiEndpoints } from '@constants/apiEndpoints';
import { Empresalegalizacao } from '@models/EmpresaLegalizacao';
import { Axios } from '@config/axios';
export const GetEmpresas = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_EMPRESAS',
      payload: Axios.get(apiEndpoints.EMPRESAS_LEGALIZACAO.GET_EMPRESAS),
    });
  };
};
export const SalvarEmpresa = function (values: Empresalegalizacao) {
  return function (dispatch: AppDispatch) {
    if (values.id) {
      dispatch({
        type: 'UPDATE_EMPRESA',
        payload: Axios.post(apiEndpoints.EMPRESAS_LEGALIZACAO.SALVAR_EMPRESA, { ...values }),
      });
    } else {
      dispatch({
        type: 'ADD_EMPRESA',
        payload: Axios.post(apiEndpoints.EMPRESAS_LEGALIZACAO.SALVAR_EMPRESA, { ...values }),
      });
    }
  };
};
export const DeleteEmpresa = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DELETE_EMPRESA',
      payload: Axios.get(apiEndpoints.EMPRESAS_LEGALIZACAO.DELETE_EMPRESA, { params: { id } }),
    });
  };
};
export const FormEmpresa = function (id: number | null, open: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'FORM_EMPRESA',
      payload: { id, open },
    });
  };
};
