import { apiEndpoints } from '@constants/apiEndpoints';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Axios } from '@config/axios';

export const getFisico = createAsyncThunk(
  'fisico/getFisico',
  async (id: number | string) => await Axios.get(apiEndpoints.FISICO.GET_FISICO, { params: { id: id } })
);

export const GetAvancos = createAsyncThunk(
  'fisico/GetAvancos',
  async (id: number | string) => await Axios.get(apiEndpoints.FISICO.GET_AVANCOS, { params: { id: id } })
);
export const getRealizadoMes = createAsyncThunk(
  'fisico/getRealizadoMes',
  async (id: number | string) => await Axios.get(apiEndpoints.FISICO.GET_REALIZADO_MES, { params: { id: id } })
);
export const salvarFisico = createAsyncThunk(
  'fisico/salvarFisico',
  async (data: any) => await Axios.post(apiEndpoints.FISICO.SALVAR_FISICO, data)
);
export const getFisicos = createAsyncThunk(
  'fisico/salvarFisigetFisicosco',
  async (data: { ativo: boolean; mes: string }) => await Axios.get(apiEndpoints.FISICO.GET_FISICOS, { params: data })
);
export const getAvancosFisicos = createAsyncThunk(
  'fisico/getAvancosFisicos',
  async (id: number | string) => await Axios.get(apiEndpoints.FISICO.GET_AVANCOS_FISICOS, { params: { id: id } })
);
export const updateAvancoFisico = createAsyncThunk(
  'fisico/updateAvancoFisico',
  async (
    data: { id: number; categoriaID: number; realizado: number; orcamentoID: number | string; mes: string },
    thunkAPI
  ) =>
    await Axios.get(apiEndpoints.FISICO.UPDATE_AVANCO_FISICO, { params: data }).catch((res) =>
      thunkAPI.rejectWithValue(res)
    )
);
export const getCategoriaItens = createAsyncThunk(
  'fisico/getCategoriaItens',
  async (data: { categoriaID: number; orcamentoID: number | string }) =>
    await Axios.get(apiEndpoints.FISICO.GET_CATEGORIA_ITENS, { params: data })
);
