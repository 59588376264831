import LoadingText from '@components/LoadingText';
import { Descriptions } from 'antd';
import { useOrdemForm } from '../useOrdemForm';

function OrdemResumoFinalEmpresaFaturamento() {
  const { ordem, isLoading } = useOrdemForm();
  if (isLoading) return <LoadingText text="Carregando" />;
  return (
    <Descriptions size="small" bordered column={1} title="Empresa Faturamento">
      <Descriptions.Item label="Nome">{ordem.empresaFaturamento?.nome}</Descriptions.Item>
      <Descriptions.Item label="CPF/CNPJ">{ordem.empresaFaturamento?.cpF_CNPJ}</Descriptions.Item>
      <Descriptions.Item label="Endereço">{ordem.empresaFaturamento?.endereco}</Descriptions.Item>
    </Descriptions>
  );
}

export default OrdemResumoFinalEmpresaFaturamento;
