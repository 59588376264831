import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GetAprovadores, SetPageHeader } from '@actions/userActions';
import OrdemPrintBody from './OrdemPrintBody';
import { Spin, Row, Col } from 'antd';
import { useAppDispatch } from '@config/reduxStore';
import { GetTransferencia } from '@services/api/api.transferencia';
import TransferenciaActions from '../Shared/TransferenciaActions';
import TransferenciaHistorico from '../Shared/TransferenciaHistorico';
import TransferenciaArquivos from '../Shared/TransferenciaArquivos';
import { ordemType } from '@constants/ordemStatus';

function OrdemPrintTransferencia() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { data: transferencia, isLoading } = GetTransferencia(id);

  useEffect(() => {
    dispatch(GetAprovadores(ordemType.transferencia));
  }, [id]);
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Engenharia',
        backbutton: true,
        extras: [<TransferenciaActions key={0} transferencia={transferencia} />],
        subtitle: 'Imprimir Ordem',
        menu: 'transferencias',
      })
    );
  }, [transferencia, isLoading, id]);
  return (
    <Spin spinning={isLoading}>
      <OrdemPrintBody />
      <Row>
        <Col span={12}>
          <TransferenciaArquivos />
        </Col>
        <Col span={12}>
          <TransferenciaHistorico />
        </Col>
      </Row>
    </Spin>
  );
}

export default OrdemPrintTransferencia;
