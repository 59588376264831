import { GetContratadoMes } from '@services/api/api.correspondencia';
import { moneyMask } from '@utils/masks';
import { Card, Spin } from 'antd';
import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
interface Props {
  mes: moment.Moment;
}
function DashboardContratadosMes({ mes }: Props) {
  const { data, isLoading } = GetContratadoMes(mes);
  const options: ApexOptions = {
    title: {
      text: 'Contratado por Mês',
    },
    subtitle: {
      text: 'Contratado por mês em valor financiado',
    },
    xaxis: {
      categories: data.map((c) => `${c.mes}/${c.ano}`),
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: 'top', // top, center, bottom
        },
      },
    },
    dataLabels: {
      offsetY: -20,
      style: {
        colors: ['#304758'],
      },
      formatter(val) {
        return moneyMask.format(val as number);
      },
    },
    yaxis: {
      labels: {
        formatter: (val) => moneyMask.format(val),
      },
    },
  };
  const series: ApexAxisChartSeries = [
    {
      name: 'Contratos',
      data: data.map((c) => c.value),
    },
  ];
  return (
    <Card>
      <Spin spinning={isLoading}>
        <Chart options={options} series={series} type="bar" width="500" />
      </Spin>
    </Card>
  );
}

export default DashboardContratadosMes;
