import ColaboradorSelect from '@components/ColaboradorSelect';
import { Fornecedor } from '@models/Fornecedor';
import { AddColaboradorFolha, RemoveColaboradorFolha } from '@services/api/api.folha';
import { Button, Divider, Form, Table } from 'antd';
import { useFolhaForm } from './useFolhaForm';
import { DeleteOutlined } from '@ant-design/icons';

function FolhaFormColaboradores() {
  const [colaboradroForm] = Form.useForm<Fornecedor>();
  const { folha, isLoading, isFetching } = useFolhaForm();
  const addColaborador = AddColaboradorFolha();
  const deleteColaborador = RemoveColaboradorFolha();
  return (
    <div>
      <Form form={colaboradroForm}>
        <Table
          size="small"
          loading={addColaborador.isLoading || deleteColaborador.isLoading || isLoading || isFetching}
          dataSource={folha?.colaboradores}
          pagination={false}
          scroll={{ y: 400 }}
        >
          <Table.Column render={(_, __, index) => index + 1} width={40} />
          <Table.Column
            dataIndex="id"
            width={40}
            render={(v) => (
              <Button
                icon={<DeleteOutlined />}
                size="small"
                onClick={() => deleteColaborador.mutate({ folhaID: folha.id, colaboradorID: v, cod: folha.cod })}
              />
            )}
          />

          <Table.Column title="Colaborador" dataIndex="nome" />
          <Table.Column title="Função" dataIndex="funcao" />
          <Table.Column title="CPF/CNPJ" dataIndex="cpF_CNPJ" />
        </Table>
        <Divider />
        <Form.List name="rateio">
          {(fields) => (
            <div>
              {fields.map((field) => (
                <div key={field.name} />
              ))}
              <ColaboradorSelect
                disabled={isFetching}
                placeholder="Adicionar colaborador..."
                style={{ width: 400 }}
                onAdd={(v) => v && addColaborador.mutate({ colaboradorID: v, folhaID: folha.id, cod: folha.cod })}
              />
            </div>
          )}
        </Form.List>
      </Form>
    </div>
  );
}

export default FolhaFormColaboradores;
