import { EnviarOrdem, SetEnvioFornecedorForm } from '@actions/novaOrdemActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Checkbox, Form, Input, Modal } from 'antd';

function EnviarParaFornecedorModal() {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const { envioFornecedorForm } = useAppSelector((state) => state.novaOrdem);
  const [form] = Form.useForm();
  return (
    <Modal
      open={envioFornecedorForm.visible}
      title="Enviar Ordem para Fornecedor"
      onCancel={() => {
        dispatch(SetEnvioFornecedorForm({ ordemID: null, visible: false, email: null }));
      }}
      onOk={() => {
        form.submit();
        if (!form.getFieldsError().find((c) => c.errors.length > 0)) {
          dispatch(SetEnvioFornecedorForm({ ordemID: null, visible: false, email: null }));
        }
      }}
      cancelText="Fechar"
    >
      <Form
        name="envioordem"
        form={form}
        onFinish={(values) =>
          dispatch(EnviarOrdem({ ...values, ContaID: user.conta.id, id: envioFornecedorForm.ordemID }))
        }
        labelCol={{ span: 6 }}
      >
        <Form.Item hidden name="ordemID">
          <Input />
        </Form.Item>
        <Form.Item name="manual" label="Envio manual" valuePropName="checked">
          <Checkbox>Sim</Checkbox>
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) =>
            !getFieldValue('manual') && (
              <div>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    { type: 'email', message: 'Email inválido' },
                    { required: true, message: 'Campo obrigatório' },
                  ]}
                  help="O email informado será registrado no cadastro do fornecedor"
                >
                  <Input disabled={envioFornecedorForm.ordemID ? !!envioFornecedorForm.email : false} />
                </Form.Item>
                <Form.Item name="obs" label="Observações">
                  <Input.TextArea />
                </Form.Item>
                <Form.Item name="copia" label="Receber cópia?" valuePropName="checked">
                  <Checkbox>Sim</Checkbox>
                </Form.Item>
              </div>
            )
          }
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default EnviarParaFornecedorModal;
