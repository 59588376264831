import { useEffect } from 'react';
import { Modal, Form, Input, Button, Typography } from 'antd';
import { SalvarPerfil, FormPerfil } from '@actions/perfisActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Role } from '@models/Role';

function PerfilForm() {
  const dispatch = useAppDispatch();
  const perfis = useAppSelector((state) => state.perfis);
  const [form] = Form.useForm();
  const submitForm = (values: Role) => {
    dispatch(SalvarPerfil({ ...values }));
  };
  useEffect(() => {
    form.resetFields();
    dispatch({ type: 'RESET_FEEDBACK' });
    if (perfis.perfil) {
      form.setFieldsValue(perfis.perfis.find((c) => c.id === perfis.perfil));
    }
  }, [perfis.perfil, perfis.perfis, form]);
  return (
    <div>
      <Modal
        open={perfis.formview}
        onCancel={() => dispatch(FormPerfil(null, false))}
        title="Cadastro Perfil"
        footer={[
          <Typography.Text key="1" type="danger">
            {perfis.feedback}
          </Typography.Text>,
          <Button onClick={() => dispatch(FormPerfil(null, false))} key="2">
            Fechar
          </Button>,
          <Button key="3" type="primary" onClick={() => form.submit()} loading={perfis.savingperfil}>
            Salvar
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form} onFinish={submitForm} name="perfilForm">
          <Form.Item style={{ display: 'none' }} name="id">
            <Input />
          </Form.Item>
          <Form.Item label="Nome" name="role" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
export default PerfilForm;
