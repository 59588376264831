import { ExtendedAction } from '@config/reduxStore';
import { Arquivo } from '@models/Arquivo';
import { Fornecedor, RelatorioFornecedor } from '@models/Fornecedor';
import { createReducer } from '@reduxjs/toolkit';
import { notification } from 'antd';
import {
  ativaColaborador,
  deleteArquivo,
  deleteColaborador,
  deleteContrato,
  formColaborador,
  getArquivos,
  getColaboradores,
  getRelatorioPagamentos,
  modalArquivoColaborador,
  salvarColaborador,
  salvarContrato,
  uploadFiles,
} from '@actions/colaboradoresActions';

interface ColaboradoresReducer {
  colaboradores: Fornecedor[];
  arquivos: Arquivo[];
  formview: boolean;
  arquivoview: boolean;
  colaborador: number;
  loadingColaboradores: boolean;
  loadingArquivos: boolean;
  savingColaborador: boolean;
  savingArquivo: boolean;
  feedback: string;
  relatorio: RelatorioFornecedor[];
  loadingRelatorio: boolean;
}
var initialState: ColaboradoresReducer = {
  colaboradores: [],
  arquivos: [],
  formview: false,
  arquivoview: false,
  colaborador: null,
  loadingColaboradores: false,
  loadingArquivos: false,
  savingColaborador: false,
  savingArquivo: false,
  feedback: '',
  relatorio: [],
  loadingRelatorio: false,
};

export default createReducer(initialState, (builder) => {
  builder.addCase(getRelatorioPagamentos.pending, (state) => {
    return {
      ...state,
      loadingRelatorio: true,
    };
  });
  builder.addCase(getRelatorioPagamentos.rejected, (state) => {
    return {
      ...state,
      loadingRelatorio: false,
    };
  });
  builder.addCase(getRelatorioPagamentos.fulfilled, (state, action: ExtendedAction) => {
    return {
      ...state,
      loadingRelatorio: false,
      relatorio: action.payload.data,
    };
  });
  builder.addCase(deleteContrato.pending, (state) => {
    return {
      ...state,
      savingColaborador: true,
    };
  });
  builder.addCase(deleteContrato.rejected, (state, action: ExtendedAction) => {
    notification.error({ message: action.payload.response.data.Message });
    return {
      ...state,
      savingColaborador: false,
    };
  });
  builder.addCase(deleteContrato.fulfilled, (state, action: ExtendedAction) => {
    notification.success({
      message: 'Contrato excluído com sucesso!',
    });
    return {
      ...state,
      savingColaborador: false,
      colaboradores: state.colaboradores.map((c) => {
        if (c.id === action.payload.data.fornecedorID) {
          return { ...c, contratos: c.contratos.filter((d) => d.id !== action.payload.data.id) };
        } else return c;
      }),
    };
  });
  builder.addCase(salvarContrato.pending, (state) => {
    return {
      ...state,
      savingColaborador: true,
    };
  });
  builder.addCase(salvarContrato.rejected, (state) => {
    notification.error({ message: 'Erro no cadastro do contrato!' });
    return {
      ...state,
      savingColaborador: false,
    };
  });
  builder.addCase(salvarContrato.fulfilled, (state, action: ExtendedAction) => {
    notification.success({
      message: action.payload.data.novo ? 'Contrato criado com sucesso!' : 'Contrato alterado com sucesso!',
    });
    return {
      ...state,
      savingColaborador: false,
      colaboradores: state.colaboradores.map((c) => {
        if (c.id === action.payload.data.entity.fornecedorID) {
          if (action.payload.data.novo) return { ...c, contratos: [...c.contratos, action.payload.data.entity] };
          else
            return {
              ...c,
              contratos: c.contratos.map((d) =>
                d.id === action.payload.data.entity.id ? action.payload.data.entity : d
              ),
            };
        } else return c;
      }),
    };
  });
  builder.addCase(ativaColaborador.pending, (state) => {
    return {
      ...state,
      loadingColaboradores: true,
    };
  });
  builder.addCase(ativaColaborador.rejected, (state) => {
    notification.error({ message: 'Erro ao atualizar cadastro!' });
    return {
      ...state,
      loadingColaboradores: false,
    };
  });
  builder.addCase(ativaColaborador.fulfilled, (state, action: ExtendedAction) => {
    notification.success({
      message: action.payload.data.ativo ? 'Colaborador ativado com sucesso!' : 'Colaborador desativado com sucesso!',
    });
    return {
      ...state,
      loadingColaboradores: false,
      colaboradores: state.colaboradores.map((c) => {
        if (c.id === action.payload.data.id) {
          return { ...c, ativo: action.payload.data.ativo };
        } else return c;
      }),
    };
  });
  builder.addCase(salvarColaborador.pending, (state) => {
    return {
      ...state,
      savingColaborador: true,
    };
  });
  builder.addCase(salvarColaborador.rejected, (state, action: ExtendedAction) => {
    notification.error({ message: action.payload.response.data.Message });
    return {
      ...state,
      savingColaborador: false,
    };
  });
  builder.addCase(salvarColaborador.fulfilled, (state, action: ExtendedAction) => {
    notification.success({
      message: action.payload.data.novo ? 'Colaborador cadastrado com sucesso!' : 'Colaborador atualizado com sucesso!',
    });
    return {
      ...state,
      savingColaborador: false,
      colaboradores: action.payload.data.novo
        ? [...state.colaboradores, action.payload.data.entity]
        : state.colaboradores.map((c) => {
            if (c.id === action.payload.data.entity.id) return action.payload.data.entity;
            else return c;
          }),
    };
  });
  builder.addCase(deleteColaborador.pending, (state) => {
    return {
      ...state,
      loadingColaboradores: true,
    };
  });
  builder.addCase(deleteColaborador.rejected, (state, action: ExtendedAction) => {
    notification.error({ message: action.payload.response.data.Message });
    return {
      ...state,
      loadingColaboradores: false,
    };
  });
  builder.addCase(deleteColaborador.fulfilled, (state, action: ExtendedAction) => {
    notification.success({ message: 'Colaborador excluído com sucesso!' });
    return {
      ...state,
      loadingColaboradores: false,
      colaboradores: state.colaboradores.filter((c) => c.id !== action.payload.data),
    };
  });
  builder.addCase(uploadFiles.pending, (state) => {
    return {
      ...state,
      savingArquivo: true,
    };
  });
  builder.addCase(uploadFiles.rejected, (state) => {
    notification.error({ message: 'Erro ao enviar arquivo' });
    return {
      ...state,
      savingArquivo: false,
    };
  });
  builder.addCase(uploadFiles.fulfilled, (state, action: ExtendedAction) => {
    notification.success({ message: 'Arquivo enviado com sucesso!' });
    return {
      ...state,
      savingArquivo: false,
      arquivos: action.payload.data,
    };
  });
  builder.addCase(deleteArquivo.pending, (state, action: ExtendedAction) => {
    notification.error({ message: action.payload });
    return {
      ...state,
      loadingArquivos: true,
    };
  });
  builder.addCase(deleteArquivo.rejected, (state) => {
    notification.error({ message: 'Erro ao excluir arquivo' });
    return {
      ...state,
      loadingArquivos: false,
    };
  });
  builder.addCase(deleteArquivo.fulfilled, (state, action: ExtendedAction) => {
    notification.success({ message: 'Arquivo excluído com sucesso!' });
    return {
      ...state,
      loadingArquivos: false,
      arquivos: state.arquivos.filter((c) => c.id !== action.payload.data),
    };
  });
  builder.addCase(getArquivos.pending, (state) => {
    return {
      ...state,
      loadingArquivos: true,
    };
  });
  builder.addCase(getArquivos.rejected, (state) => {
    return {
      ...state,
      loadingArquivos: false,
    };
  });
  builder.addCase(getArquivos.fulfilled, (state, action: ExtendedAction) => {
    return {
      ...state,
      loadingArquivos: false,
      arquivos: action.payload.data,
    };
  });
  builder.addCase(getColaboradores.pending, (state) => {
    return {
      ...state,
      loadingColaboradores: true,
    };
  });
  builder.addCase(getColaboradores.rejected, (state) => {
    return {
      ...state,
      loadingColaboradores: false,
    };
  });
  builder.addCase(getColaboradores.fulfilled, (state, action: ExtendedAction) => {
    return {
      ...state,
      loadingColaboradores: false,
      colaboradores: action.payload.data,
    };
  });
  builder.addCase(formColaborador, (state, action) => ({
    ...state,
    feedback: '',
    formview: action.payload.open,
    colaborador: action.payload.id ? action.payload.id : null,
  }));
  builder.addCase(modalArquivoColaborador, (state, action: ExtendedAction) => ({
    ...state,
    feedback: '',
    arquivoview: action.payload.open,
    colaborador: action.payload.id ? action.payload.id : null,
  }));
});
