import { SetPageHeader } from '@actions/userActions';
import { useAppDispatch } from '@config/reduxStore';
import { OrdemStatus } from '@constants/ordemStatus';
import { DatePicker, Form, Select, Space } from 'antd';
import { useEffect, useState } from 'react';
import OrdemStatusTag from '../../Ordens/Shared/OrdemStatusTag';
import TableItensObras from './TableItensObras';
import TableItens from './TableItens';

function DashboardPedidos() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(SetPageHeader({ title: 'Dashboard Pedidos', subtitle: '', menu: 'pedidos', backbutton: true }));
  }, []);
  const [status, setStatus] = useState<string>(OrdemStatus.Aprovada);
  const [grupo, setGrupo] = useState<string>('Item');
  const [prazo, setPrazo] = useState<[moment.Moment, moment.Moment]>(null);
  return (
    <div>
      <Space>
        <Form.Item label="Fitro por status">
          <Select style={{ width: 220 }} value={status} onChange={(v) => setStatus(v)}>
            <Select.Option value={OrdemStatus.PendenteAprovacao}>
              <OrdemStatusTag status={OrdemStatus.PendenteAprovacao} />
            </Select.Option>
            <Select.Option value={OrdemStatus.Aprovada}>
              <OrdemStatusTag status={OrdemStatus.Aprovada} />
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Agrupamento por">
          <Select style={{ width: 220 }} value={grupo} onChange={(v) => setGrupo(v)}>
            <Select.Option value="Item">Item</Select.Option>
            <Select.Option value="Orçamento">Orçamento</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Prazo de Entrega">
          <DatePicker.RangePicker
            format="DD/MM/YYYY"
            onChange={(v) => {
              setPrazo(v);
              console.log(v);
            }}
          />
        </Form.Item>
      </Space>
      {grupo === 'Item' && <TableItens status={status} prazo={prazo} />}
      {grupo === 'Orçamento' && <TableItensObras status={status} prazo={prazo} />}
    </div>
  );
}
export default DashboardPedidos;
