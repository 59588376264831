import { AppDispatch } from '@config/reduxStore';
import { apiEndpoints } from '@constants/apiEndpoints';
import { Role } from '@models/Role';
import { Axios } from '@config/axios';
export const GetPerfis = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_PERFIS',
      payload: Axios.get(apiEndpoints.PERFILS.GET_PERFIS),
    });
  };
};
export const GetPermissions = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_PERMISSIONS',
      payload: Axios.get(apiEndpoints.PERFILS.GET_PERMISSIONS),
    });
  };
};
export const SalvarPerfil = function (values: Role) {
  return function (dispatch: AppDispatch) {
    if (values.id) {
      dispatch({
        type: 'UPDATE_PERFIL',
        payload: Axios.post(apiEndpoints.PERFILS.SALVAR_PERFIL, { ...values }),
      });
    } else {
      dispatch({
        type: 'ADD_PERFIL',
        payload: Axios.post(apiEndpoints.PERFILS.ADD_ROLE, { ...values }),
      });
    }
  };
};
export const DeletePerfil = function (id: string) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DELETE_PERFIL',
      payload: Axios.get(apiEndpoints.PERFILS.DELETE_PERFIL, { params: { id } }),
    });
  };
};
export const AddPermissionToRole = function (permission: string, role: string) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'ADD_PERMISSION_TO_ROLE',
      payload: Axios.post(apiEndpoints.PERFILS.ADD_PERMISSION_TO_ROLE, { permission, role }),
    });
  };
};
export const FormPerfil = function (id: string, open: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'FORM_PERFIL',
      payload: { id, open },
    });
  };
};
