import { useEffect } from 'react';
import { Modal, Form, Input, Button } from 'antd';
import { SalvarAgencia, FormAgencia } from '@actions/agenciasActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Agencia } from '@models/Agencia';

function AgenciaForm() {
  const agencias = useAppSelector((state) => state.agencias);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<Agencia>();
  const submitForm = (values: Agencia) => {
    dispatch(SalvarAgencia(values));
  };
  useEffect(() => {
    form.resetFields();
    if (agencias.agencia) {
      form.setFieldsValue(agencias.agencias.find((c) => c.id === agencias.agencia));
    }
  }, [agencias.agencia, agencias.agencias, form]);
  return (
    <div>
      <Modal
        open={agencias.formview}
        onCancel={() => dispatch(FormAgencia({ id: null, open: false }))}
        title="Cadastro Agencia"
        footer={[
          <Button onClick={() => dispatch(FormAgencia({ id: null, open: false }))} key="2">
            Fechar
          </Button>,
          <Button key="3" type="primary" onClick={() => form.submit()} loading={agencias.savingagencia}>
            Salvar
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form} onFinish={submitForm} name="agenciaForm">
          <Form.Item style={{ display: 'none' }} name="id">
            <Input />
          </Form.Item>
          <Form.Item label="Banco" name="banco" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Input />
          </Form.Item>

          <Form.Item label="Nome da Agência" name="nome" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Número" name="numero" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
export default AgenciaForm;
