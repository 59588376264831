import { GetGruposOrcamento } from '@services/api/api.orcamento';
import { SelectProps } from 'antd';
import { Select } from 'antd';

export interface SelectGrupoProps extends SelectProps {
  orcamentoID: number;
  categoriaID: number;
}

function GrupoSelectSelect({ orcamentoID, categoriaID, ...restProps }: SelectGrupoProps) {
  const { isLoading, data, isFetching } = GetGruposOrcamento({ orcamentoID: orcamentoID, categoriaID: categoriaID });
  return (
    <Select
      {...restProps}
      loading={isLoading || isFetching}
      optionFilterProp="children"
      allowClear
      showSearch
      disabled={isLoading}
      dropdownMatchSelectWidth={false}
    >
      {data?.map((c) => (
        <Select.Option key={c.id} value={c.id}>
          {c.descricao}
        </Select.Option>
      ))}
    </Select>
  );
}

export default GrupoSelectSelect;
