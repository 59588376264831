import { GetCategoriasOrcamento } from '@services/api/api.orcamento';
import { SelectProps } from 'antd';
import { Select } from 'antd';

export interface SelectCategoriaProps extends SelectProps {
  orcamentoID: number;
}

function CategoriaSelect({ orcamentoID, ...restProps }: SelectCategoriaProps) {
  const { isLoading, data, isFetching } = GetCategoriasOrcamento({ orcamentoID: orcamentoID });
  return (
    <Select
      {...restProps}
      loading={isLoading || isFetching}
      optionFilterProp="children"
      allowClear
      showSearch
      disabled={isLoading}
      dropdownMatchSelectWidth={false}
    >
      {data?.map((c) => (
        <Select.Option key={c.id} value={c.id}>
          {c.descricao}
        </Select.Option>
      ))}
    </Select>
  );
}

export default CategoriaSelect;
