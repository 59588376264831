import { useEffect, useState } from 'react';
import { Table, Button, Input, Checkbox, Row, Col, Switch } from 'antd';
import { GetUsuarios, GetRoles, AddUserToRole, DeleteUsuario, FormUsuario } from '@actions/usuariosActions';
import { SetPageHeader } from '@actions/userActions';
import { Permission, HasPermission } from '@hooks//usePermissions';
import { EditOutlined } from '@ant-design/icons';
import { sortAlpha, getColumnSearchProps, filterobject } from '@utils/filterandsort';
import UsuarioForm from './UsuarioForm';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { User } from '@models/User';

function UsuariosView() {
  const dispatch = useAppDispatch();
  const usuarios = useAppSelector((state) => state.usuarios);
  const permissions = useAppSelector((state) => state.user.user.permissions);
  const [filter, setFilter] = useState('');
  useEffect(() => {
    dispatch(GetUsuarios());
    dispatch(GetRoles());
    dispatch(
      SetPageHeader({
        title: 'Configurações',
        backbutton: true,
        extras: [
          <Permission key="1" permission="user.add">
            <Button onClick={() => dispatch(FormUsuario(null, true))} type="primary">
              Novo Usuário
            </Button>
          </Permission>,
        ],
        subtitle: 'Usuários',
        menu: 'usuarios',
      })
    );
  }, [dispatch]);
  return (
    <div>
      <UsuarioForm />
      <div style={{ textAlign: 'right' }}>
        <Input style={{ width: 200 }} onChange={(e) => setFilter(e.target.value)} placeholder="Buscar" allowClear />
      </div>
      <Table
        size="small"
        rowKey="id"
        loading={usuarios.loadingusuarios || usuarios.savinguser}
        dataSource={filterobject(usuarios.usuarios, filter)}
        scroll={{ y: 'calc(100vh - 300px)', x: 1024 }}
        pagination={{
          position: ['bottomRight'],
          size: 'small',
          showSizeChanger: true,
          pageSizeOptions: [10, 25, 50, 100],
          showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
        }}
      >
        <Table.Column
          width={80}
          fixed="left"
          render={(_, row: User) => (
            <div>
              <Permission permission="user.update">
                <Button icon={<EditOutlined />} onClick={() => dispatch(FormUsuario(row.id, true))} size="small" />
              </Permission>
            </div>
          )}
        />
        <Table.Column
          title="Status"
          dataIndex="ativo"
          sorter={(a: any, b: any) => a.ativo - b.ativo}
          render={(text, row: User) => <Switch checked={text} onChange={() => dispatch(DeleteUsuario(row.id))} />}
          filters={[
            { value: true, text: 'Ativo' },
            { value: false, text: 'Inativo' },
          ]}
          onFilter={(value, row) => row.ativo === value}
        />
        <Table.Column
          title="Nome"
          dataIndex="nome"
          sorter={(a, b) => sortAlpha(a.nome, b.nome)}
          {...getColumnSearchProps('nome', 'string', 'Nome')}
        />
        <Table.Column
          title="Email"
          dataIndex="email"
          sorter={(a, b) => sortAlpha(a.email, b.email)}
          {...getColumnSearchProps('email', 'string', 'Email')}
        />
        <Table.Column
          title="Perfis"
          dataIndex="roles"
          width={320}
          filters={usuarios.roles.map((c) => ({ value: c, text: c.toUpperCase() }))}
          onFilter={(value, row: User) => row.roles.includes(value as string)}
          render={(text, row) => (
            <Checkbox.Group value={text}>
              <Row>
                {usuarios.roles
                  .filter((c) => c !== 'master')
                  .map((c) => (
                    <Col key={c} span={12}>
                      <Checkbox
                        disabled={!HasPermission('perfil.update', permissions)}
                        onChange={(e) => dispatch(AddUserToRole(e.target.value, row.id))}
                        value={c}
                      >
                        {c.toUpperCase()}
                      </Checkbox>
                    </Col>
                  ))}
              </Row>
            </Checkbox.Group>
          )}
        />
      </Table>
    </div>
  );
}

export default UsuariosView;
