import { GetPedido } from '@services/api/api.pedidos';
import { orderDateDesc } from '@utils/filterandsort';
import { Card, List } from 'antd';
import moment from 'moment';
import { useParams } from 'react-router-dom';

function PedidoHistorico() {
  const { id } = useParams();
  const { data: pedido, isLoading } = GetPedido(id);
  return (
    <Card title="Histórico" size="small" style={{ marginTop: 10 }} loading={isLoading}>
      <div style={{ height: 300, overflowY: 'scroll' }}>
        <List
          dataSource={pedido ? pedido.historicoOrdem?.sort((a, b) => orderDateDesc(b.data, a.data)) : []}
          renderItem={(item) => (
            <List.Item.Meta
              title={item.nota}
              description={`${item.usuario} - ${moment(item.data).format('DD/MM/YYYY HH:mm:SS')}`}
            />
          )}
        />
      </div>
    </Card>
  );
}

export default PedidoHistorico;
