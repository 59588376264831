import { Button, notification, Table } from 'antd';
import { useEffect, useState } from 'react';
import { GetDashboardOrcamentos } from '@actions/orcamentosActions';
import { SetPageHeader } from '@actions/userActions';
import { getColumnSearchProps, naturalSorter } from '@utils/filterandsort';
import { doubleMask, moneyMask, moneyNegMask } from '@utils/masks';
import { FileExcelOutlined } from '@ant-design/icons';
import { Axios } from '@config/axios';
import moment from 'moment';
import AditivosModal from './AditivosModal';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { OrcamentoDashboard } from '@models/Dashboard';
function DashboardOrcamentos() {
  const dispatch = useAppDispatch();
  const [downloading, setDownloading] = useState<boolean>(false);
  const [filters, setFilters] = useState<any>(null);
  const { dashboard, loadingDashboard } = useAppSelector((state) => state.orcamentos);
  const [aditivos, setAditivos] = useState({ casa: null, visible: false, obra: null });
  const exportar = () => {
    setDownloading(true);
    Axios.post(
      '/api/v2/Orcamentos/ExportDashboard',
      dashboard.map((c) => ({
        ...c,
        area: c.area || 0,
        contrato: c.contrato || 0,
        saldoContrato: c.saldoContrato || 0,
        resultadoProjetado: c.resultadoProjetado || 0,
        realizado: c.realizado || 0,
        projetado: c.projetado || 0,
      })),
      {
        responseType: 'blob',
      }
    )
      .then((res) => {
        setDownloading(false);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Dashboard Orcamentos ${moment(new Date()).format('DD_MM_YYYY HH:mm')}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        notification.error({ message: 'Erro ao gerar arquivo!' });
        setDownloading(false);
      });
  };

  useEffect(() => {
    dispatch(GetDashboardOrcamentos(true));
  }, []);
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Engenharia',
        backbutton: true,
        extras: [
          <Button key={0} loading={downloading} onClick={() => exportar()} icon={<FileExcelOutlined />}>
            Exportar
          </Button>,
        ],
        subtitle: 'Dashboard Orçamentos',
        menu: 'orcamentos',
      })
    );
  }, [downloading, dispatch]);
  return (
    <div>
      <AditivosModal {...aditivos} setVisible={setAditivos} />
      <Table
        rowKey="id"
        size="small"
        loading={loadingDashboard}
        dataSource={dashboard}
        pagination={false}
        scroll={{ x: 1200, y: 'calc(100vh - 200px)' }}
        onChange={(pagination, filters, sorter) => setFilters({ pagination, filters, sorter })}
      >
        <Table.Column
          title="Obra"
          dataIndex="obra"
          width={250}
          ellipsis
          sorter={(a, b) => naturalSorter(a.obra, b.obra)}
          render={(v) => <strong>{v}</strong>}
          {...getColumnSearchProps('obra', 'string', 'Obra', filters)}
        />
        <Table.Column
          title="Área"
          dataIndex="area"
          render={(v) => doubleMask.format(v) + ' m2'}
          sorter={(a: OrcamentoDashboard, b: OrcamentoDashboard) => a.area - b.area}
          align="right"
        />
        <Table.Column
          title="Aditivos"
          dataIndex="aditivos"
          render={(v, row: OrcamentoDashboard) => (
            <Button
              type="link"
              size="small"
              onClick={() => setAditivos({ visible: true, obra: row.obra, casa: row.casaID })}
            >
              {moneyMask.format(v)}
            </Button>
          )}
          sorter={(a, b: OrcamentoDashboard) => a.aditivos - b.aditivos}
          align="right"
        />
        <Table.Column
          title="Total Contrato"
          dataIndex="contrato"
          render={(v) => moneyMask.format(v)}
          sorter={(a, b: OrcamentoDashboard) => a.contrato - b.contrato}
          align="right"
        />
        <Table.Column
          title="Realizado"
          dataIndex="realizado"
          render={(v) => moneyMask.format(v)}
          sorter={(a: OrcamentoDashboard, b: OrcamentoDashboard) => a.realizado - b.realizado}
          align="right"
        />
        <Table.Column
          title="Recebido"
          dataIndex="recebido"
          render={(v) => moneyMask.format(v)}
          sorter={(a: OrcamentoDashboard, b: OrcamentoDashboard) => a.recebido - b.recebido}
          align="right"
        />
        <Table.Column
          title="Projetado"
          dataIndex="projetado"
          render={(v) => moneyMask.format(v)}
          sorter={(a, b: OrcamentoDashboard) => a.projetado - b.projetado}
          align="right"
        />
        <Table.Column
          title="Saldo Contrato"
          dataIndex="saldoContrato"
          render={(v) => <span style={{ color: v < 0 ? 'red' : undefined }}>{moneyNegMask.format(v)}</span>}
          sorter={(a, b: OrcamentoDashboard) => a.saldoContrato - b.saldoContrato}
          align="right"
        />
        <Table.Column
          title="Resultado Projetado"
          dataIndex="resultadoProjetado"
          render={(v) => <span style={{ color: v < 0 ? 'red' : undefined }}>{moneyNegMask.format(v)}</span>}
          sorter={(a, b: OrcamentoDashboard) => a.resultadoProjetado - b.resultadoProjetado}
          align="right"
        />
      </Table>
    </div>
  );
}

export default DashboardOrcamentos;
