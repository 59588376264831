import { useEffect } from 'react';
import { Modal, Form, Input, Button } from 'antd';
import { cnpjMask, mobMask } from '@utils/masks';
import { FormEmpresa, SalvarEmpresa } from '@actions/empresasActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Empresalegalizacao } from '@models/EmpresaLegalizacao';

function EmpresaLegalizacaoForm() {
  const dispatch = useAppDispatch();
  const empresas = useAppSelector((state) => state.empresas);
  const [form] = Form.useForm<Empresalegalizacao>();
  const submitForm = (values: Empresalegalizacao) => {
    dispatch(SalvarEmpresa(values));
  };
  useEffect(() => {
    form.resetFields();
    if (empresas.empresa) {
      form.setFieldsValue(empresas.empresas.find((c) => c.id === empresas.empresa));
    }
  }, [empresas.empresa, empresas.empresas, form]);
  return (
    <div>
      <Modal
        open={empresas.formview}
        onCancel={() => dispatch(FormEmpresa(null, false))}
        title="Cadastro Empresa"
        footer={[
          <Button onClick={() => dispatch(FormEmpresa(null, false))} key="2">
            Fechar
          </Button>,
          <Button key="3" type="primary" onClick={() => form.submit()} loading={empresas.savingempresa}>
            Salvar
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form} onFinish={submitForm} name="corretorForm">
          <Form.Item style={{ display: 'none' }} name="id">
            <Input />
          </Form.Item>
          <Form.Item label="Nome" name="nome" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="CNPJ" name="cnpj" normalize={(v) => cnpjMask.format(cnpjMask.normalize(v))}>
            <Input />
          </Form.Item>
          <Form.Item label="Contato" name="contato" normalize={(v) => mobMask.format(mobMask.normalize(v))}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
export default EmpresaLegalizacaoForm;
