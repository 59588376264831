import { useEffect, useState } from 'react';
import { SetPageHeader } from '@actions/userActions';
import { Table, Input, Button, Tooltip, Modal, Descriptions } from 'antd';
import { GetInvestidoresFull } from '@actions/empsActions';
import { filterobject, getColumnSearchProps, sortAlpha } from '@utils/filterandsort';
import { BarChartOutlined, AreaChartOutlined } from '@ant-design/icons';
import { moneyNegMask } from '@utils/masks';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Investimento } from '@models/Investimento';

function InvestidoresView() {
  const dispatch = useAppDispatch();
  const { investidores, loadinginvestidores } = useAppSelector((state) => state.emps);
  const [filter, setFilter] = useState('');
  const [investidor, setInvestidor] = useState(null);
  const [invmodal, setinvmodal] = useState(false);
  useEffect(() => {
    dispatch(GetInvestidoresFull());
    dispatch(
      SetPageHeader({
        title: 'Investidores',
        backbutton: false,
        extras: [],
        subtitle: '',
        menu: 'investidores',
      })
    );
  }, []);
  return (
    <div>
      <div style={{ textAlign: 'right' }}>
        <Input style={{ width: 200 }} onChange={(e) => setFilter(e.target.value)} placeholder="Buscar" allowClear />
      </div>
      <Table
        size="small"
        loading={loadinginvestidores}
        rowKey="id"
        dataSource={filterobject(investidores, filter)}
        scroll={{ y: 'calc(100vh - 300px)' }}
        pagination={{
          position: ['bottomRight'],
          size: 'small',
          showSizeChanger: true,
          pageSizeOptions: [10, 25, 50, 100],
          showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
        }}
      >
        <Table.Column
          width={50}
          render={(_, row: Investimento) => (
            <Tooltip title="Investimentos">
              <Button
                size="small"
                icon={<BarChartOutlined />}
                onClick={() => {
                  setInvestidor(row.id);
                  setinvmodal(true);
                }}
              />
            </Tooltip>
          )}
        />
        <Table.Column
          title="Nome"
          dataIndex="nome"
          sorter={(a, b) => sortAlpha(a.nome, b.nome)}
          {...getColumnSearchProps('nome', 'string', 'Nome')}
        />
        <Table.Column
          title="Email"
          dataIndex="email"
          sorter={(a, b) => sortAlpha(a.cpf, b.cpf)}
          {...getColumnSearchProps('email', 'string', 'Email')}
        />
        <Table.Column
          title="CPF"
          dataIndex="cpf"
          sorter={(a, b) => sortAlpha(a.cpf, b.cpf)}
          {...getColumnSearchProps('cpf', 'string', 'CPF')}
        />
        <Table.Column
          title="Banco"
          dataIndex="banco"
          sorter={(a, b) => sortAlpha(a.banco, b.banco)}
          {...getColumnSearchProps('banco', 'string', 'Banco')}
        />
        <Table.Column
          title="Agência"
          dataIndex="agencia"
          sorter={(a, b) => sortAlpha(a.agencia, b.agencia)}
          {...getColumnSearchProps('Agência', 'string', 'agencia')}
        />
        <Table.Column
          title="Conta"
          dataIndex="conta"
          sorter={(a, b) => sortAlpha(a.conta, b.conta)}
          {...getColumnSearchProps('conta', 'string', 'Conta')}
        />
      </Table>
      <Modal
        open={invmodal}
        title={`Investimentos ${investidor ? investidores.find((c) => c.id === investidor).nome : ''}`}
        width="90%"
        onCancel={() => setinvmodal(false)}
        footer={[
          <Button key="1" onClick={() => setinvmodal(false)}>
            Fechar
          </Button>,
        ]}
      >
        <Table
          rowKey="id"
          dataSource={investidor ? investidores.find((c) => c.id === investidor).investimentos : []}
          size="small"
          pagination={false}
          scroll={{ y: 500, x: 1024 }}
          footer={(data) => (
            <Descriptions size="small" title="Totais" bordered column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}>
              <Descriptions.Item label="Quotas">
                {data.map((c) => c.quotas).reduce((a, b) => a + b, 0)}
              </Descriptions.Item>
              <Descriptions.Item label="Valor da Quota">
                {moneyNegMask.format(data.map((c) => c.valorQuota).reduce((a, b) => a + b, 0))}
              </Descriptions.Item>
              <Descriptions.Item label="Necessário">
                {moneyNegMask.format(data.map((c) => c.aporteNecessario).reduce((a, b) => a + b, 0))}
              </Descriptions.Item>
              <Descriptions.Item label="Aportado">
                {moneyNegMask.format(data.map((c) => c.aportado).reduce((a, b) => a + b, 0))}
              </Descriptions.Item>
              <Descriptions.Item label="Pendente">
                {moneyNegMask.format(data.map((c) => c.aportePendente).reduce((a, b) => a + b, 0))}
              </Descriptions.Item>
              <Descriptions.Item label="Retornado">
                {moneyNegMask.format(data.map((c) => c.retornado).reduce((a, b) => a + b, 0))}
              </Descriptions.Item>
              <Descriptions.Item label="Resgatado">
                {moneyNegMask.format(data.map((c) => c.resgatado).reduce((a, b) => a + b, 0))}
              </Descriptions.Item>
              <Descriptions.Item label="Saldo Disponível">
                {moneyNegMask.format(
                  data.map((c) => c.retornado).reduce((a, b) => a + b, 0) -
                    data.map((c) => c.resgatado).reduce((a, b) => a + b, 0) -
                    data.map((c) => c.aportePendente).reduce((a, b) => a + b, 0)
                )}
              </Descriptions.Item>
            </Descriptions>
          )}
        >
          <Table.Column
            width={50}
            fixed="left"
            render={(_, row: Investimento) => (
              <div>
                <Tooltip title="Relatório">
                  <Link to={`/RelatorioInvestimento/${row.id}`}>
                    <Button size="small" icon={<AreaChartOutlined />} />
                  </Link>
                </Tooltip>
              </div>
            )}
          />
          <Table.Column title="Empreendimento" dataIndex="empreendimento" fixed="left" />
          <Table.Column title="SCP" dataIndex="scp" />
          <Table.Column title="Fração" dataIndex="fracao" />
          <Table.Column title="Quotas" dataIndex="quotas" />
          <Table.Column title="Valor da Quota" dataIndex="valorQuota" render={(text) => moneyNegMask.format(text)} />
          <Table.Column
            title="Aporte Necessário"
            dataIndex="aporteNecessario"
            render={(text) => moneyNegMask.format(text)}
          />
          <Table.Column title="Valor Aportado" dataIndex="aportado" render={(text) => moneyNegMask.format(text)} />
          <Table.Column
            title="Aporte Pendente"
            dataIndex="aportePendente"
            render={(text) => moneyNegMask.format(text)}
          />
          <Table.Column title="Valor Retornado" dataIndex="retornado" render={(text) => moneyNegMask.format(text)} />
          <Table.Column title="Valor Resgatado" dataIndex="resgatado" render={(text) => moneyNegMask.format(text)} />
          <Table.Column
            title="Saldo Disponível"
            render={(_, row: Investimento) => moneyNegMask.format(row.retornado - row.resgatado - row.aportePendente)}
          />
        </Table>
      </Modal>
    </div>
  );
}

export default InvestidoresView;
