import { useState } from 'react';
import { Modal, Table, Button, Descriptions, Input } from 'antd';
import { LoadInvestidores } from '@actions/empsActions';
import { moneyMask, moneyNegMask } from '@utils/masks';
import { filterobject } from '@utils/filterandsort';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Investimento } from '@models/Investimento';

function InvestidoresModal() {
  const dispatch = useAppDispatch();
  const { investidoresmodal, emps, empinvestidores } = useAppSelector((state) => state.emps);
  const [filter, setFilter] = useState('');

  return (
    <div>
      <Modal
        title="Investidores da Operação"
        open={investidoresmodal}
        width={(window.innerWidth * 2) / 3}
        footer={[<Button onClick={() => dispatch(LoadInvestidores())}>Fechar</Button>]}
        onCancel={() => dispatch(LoadInvestidores())}
      >
        <div style={{ textAlign: 'right' }}>
          <Input style={{ width: 200 }} onChange={(e) => setFilter(e.target.value)} placeholder="Buscar" allowClear />
        </div>
        <Table
          pagination={false}
          size="small"
          rowKey="id"
          scroll={{ x: 1024 }}
          dataSource={
            empinvestidores ? filterobject(emps.find((c) => c.id === empinvestidores).investimentos, filter) : []
          }
          footer={(data) => (
            <Descriptions size="small" title="Totais" bordered column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}>
              <Descriptions.Item label="Quotas">
                {data.map((c) => c.quotas).reduce((a, b) => a + b, 0)}
              </Descriptions.Item>
              <Descriptions.Item label="Valor da Quota">
                {moneyMask.format(data.map((c) => c.valorQuota).reduce((a, b) => a + b, 0))}
              </Descriptions.Item>
              <Descriptions.Item label="Necessário">
                {moneyMask.format(data.map((c) => c.aporteNecessario).reduce((a, b) => a + b, 0))}
              </Descriptions.Item>
              <Descriptions.Item label="Aportado">
                {moneyMask.format(data.map((c) => c.aportado).reduce((a, b) => a + b, 0))}
              </Descriptions.Item>
              <Descriptions.Item label="Pendente">
                {moneyMask.format(data.map((c) => c.aportePendente).reduce((a, b) => a + b, 0))}
              </Descriptions.Item>
              <Descriptions.Item label="Retornado">
                {moneyMask.format(data.map((c) => c.retornado).reduce((a, b) => a + b, 0))}
              </Descriptions.Item>
              <Descriptions.Item label="Resgatado">
                {moneyMask.format(data.map((c) => c.resgatado).reduce((a, b) => a + b, 0))}
              </Descriptions.Item>
              <Descriptions.Item label="Disponível">
                {moneyMask.format(
                  data.map((c) => c.retornado).reduce((a, b) => a + b, 0) -
                    data.map((c) => c.resgatado).reduce((a, b) => a + b, 0)
                )}
              </Descriptions.Item>
            </Descriptions>
          )}
        >
          <Table.Column fixed="left" title="Investidor" dataIndex="investidor" />
          <Table.Column title="SCP" dataIndex="scp" />
          <Table.Column title="Fração" dataIndex="fracao" />
          <Table.Column title="Quota" dataIndex="quotas" />
          <Table.Column title="Valor da Quota" dataIndex="valorQuota" render={(text) => moneyNegMask.format(text)} />
          <Table.Column
            title="Aporte Necessário"
            dataIndex="aporteNecessario"
            render={(text) => moneyNegMask.format(text)}
          />
          <Table.Column title="Valor Aportado" dataIndex="aportado" render={(text) => moneyNegMask.format(text)} />
          <Table.Column
            title="Aporte Pendente"
            dataIndex="aportePendente"
            render={(text) => moneyNegMask.format(text)}
          />
          <Table.Column title="Valor Retornado" dataIndex="retornado" render={(text) => moneyNegMask.format(text)} />
          <Table.Column title="Valor Resgatado" dataIndex="resgatado" render={(text) => moneyNegMask.format(text)} />
          <Table.Column
            title="Saldo Disponível"
            render={(_, row: Investimento) => moneyNegMask.format(row.retornado - row.resgatado - row.aportePendente)}
          />
        </Table>
      </Modal>
    </div>
  );
}

export default InvestidoresModal;
