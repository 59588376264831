import { ItemPedido } from '@models/Pedidos';
import { ItensPedido } from '@services/api/api.pedidos';
import { doubleMask } from '@utils/masks';
import { List, Modal, Skeleton, Table } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import OrdemStatusTag from '../../Ordens/Shared/OrdemStatusTag';

function PedidoItensModal({
  item,
  setItem,
}: {
  item: {
    visible: boolean;
    pedidoID: number;
  };
  setItem: Dispatch<SetStateAction<{ visible: boolean; pedidoID: number }>>;
}) {
  const { data: itens, isLoading } = ItensPedido(item.pedidoID);
  return (
    <Modal
      title="Itens solicitados no pedido"
      open={item.visible}
      onCancel={() => setItem({ visible: false, pedidoID: null })}
      width="90%"
      cancelText="Fechar"
      okButtonProps={{ hidden: true }}
    >
      {isLoading && <Skeleton active />}
      {!isLoading && (
        <Table loading={isLoading} size="small" dataSource={itens} pagination={false}>
          <Table.Column title="Categoria" dataIndex="categoria" />
          <Table.Column title="Grupo" dataIndex="grupo" />
          <Table.Column title="Item" dataIndex="item" />
          <Table.Column title="Unidade" dataIndex="unidade" />
          <Table.Column title="Descrição" dataIndex="descricao" />
          <Table.Column title="Quantidade" dataIndex="quantidade" align="center" render={(v) => doubleMask.format(v)} />
          <Table.Column title="Processado" dataIndex="processado" align="center" render={(v) => doubleMask.format(v)} />
          <Table.Column
            title="Ordens"
            width={200}
            dataIndex="ordens"
            render={(_, row: ItemPedido) =>
              row.ordens?.length > 0 && (
                <List
                  dataSource={row.ordens}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        title={<OrdemStatusTag status={item?.situacao} />}
                        description={
                          <Link key={item?.id} to={'/PrintOrdem/' + item?.id} target="_blank">
                            {item?.cod}
                          </Link>
                        }
                      />
                    </List.Item>
                  )}
                />
              )
            }
          />
        </Table>
      )}
    </Modal>
  );
}

export default PedidoItensModal;
