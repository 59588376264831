import { useEffect } from 'react';
import { Table, Button, Divider } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { GetGaleria, OpenGaleria } from '@actions/galeriaActions';
import moment from 'moment';
import GalleryModal from './GalleryModal';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Galeria } from '@models/Galeria';

function TableGaleriaReport() {
  const dispatch = useAppDispatch();
  const galeria = useAppSelector((state) => state.galeria);
  const { user } = useAppSelector((state) => state.user);

  useEffect(() => {
    dispatch(GetGaleria(user.casaid));
  }, [user.casaid]);

  return (
    <div>
      <Divider orientation="left">Galeria de Imagens</Divider>
      <Table pagination={false} loading={galeria.loading} size="small" rowKey="id" dataSource={galeria.items}>
        <Table.Column
          render={(_, row: Galeria) => (
            <Button size="small" icon={<EyeOutlined />} onClick={() => dispatch(OpenGaleria(row.id))} />
          )}
        />
        <Table.Column title="Item" render={(_, __, index) => index + 1} />
        <Table.Column title="Titulo" dataIndex="titulo" />
        <Table.Column title="Data" dataIndex="data" render={(text) => moment(text).format('DD/MM/YYYY')} />
      </Table>
      <GalleryModal />
    </div>
  );
}

export default TableGaleriaReport;
