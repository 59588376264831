import { Categoria } from '@models/Categoria';
import { Grupo } from '@models/Grupo';
import { Item } from '@models/Item';
import { OrdemItem } from '@models/OrdemItem';
import { doubleMask, moneyMask } from '@utils/masks';
import { Descriptions, Table, Typography } from 'antd';
import { useOrdemForm } from '../useOrdemForm';

function OrdemResumoFinalItens() {
  const { ordem } = useOrdemForm();
  return (
    <div>
      <Typography.Title level={4}>Itens</Typography.Title>
      <Table dataSource={ordem.ordensItens} size="small" pagination={false} rowKey="id">
        <Table.Column title="" render={(_, __, index) => index + 1} width={30} />
        <Table.Column
          title="Orçamento"
          width={300}
          dataIndex={['orcamento', 'descricao']}
          ellipsis
          render={(v, row: OrdemItem) => (
            <Descriptions size="small" bordered column={1}>
              <Descriptions.Item label="Orçamento">{v}</Descriptions.Item>
              <Descriptions.Item label="Categoria">{(row.categoria as Categoria).descricao}</Descriptions.Item>
              <Descriptions.Item label="Grupo">{(row.grupo as Grupo).descricao}</Descriptions.Item>
            </Descriptions>
          )}
        />
        <Table.Column
          title="Item"
          width={300}
          dataIndex={['item', 'descricao']}
          render={(v, row: OrdemItem) => (
            <div>
              <div style={{ fontWeight: 'bold' }}>{`${(row.item as Item).unidade} - ${v}`}</div>
              <div style={{ fontStyle: 'italic' }}>{row.descricao}</div>
            </div>
          )}
        />
        <Table.Column
          title="Valor Unitário"
          width={100}
          dataIndex="valorUnitario"
          render={(v) => moneyMask.format(v)}
          align="right"
        />
        <Table.Column
          title="Desconto"
          width={100}
          dataIndex="desconto"
          render={(v) => moneyMask.format(v)}
          align="right"
        />
        <Table.Column
          title="Quantidade"
          width={100}
          dataIndex="quantidade"
          render={(v) => doubleMask.format(v)}
          align="right"
        />
        <Table.Column
          title="Valor Total"
          width={100}
          dataIndex="valorTotal"
          render={(v) => moneyMask.format(v)}
          align="right"
        />
      </Table>
    </div>
  );
}

export default OrdemResumoFinalItens;
