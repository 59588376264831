import OrcamentoItemSelect from '@components/OrcamentoItemSelect';
import { Button, Card, Col, Form, Input, InputNumber, Row, Space, Spin, Typography } from 'antd';
import { MinusOutlined } from '@ant-design/icons';
import { GetOrcamentoItens } from '@services/api/api.orcamentoItem';
import { useTransferenciaForm } from './useTransferenciaForm';
import { doubleMask, moneyMask } from '@utils/masks';
import OrcamentoSelect from '@components/OrcamentoSelect';
import CategoriaSelect from '@components/CategoriaSelect';
import GrupoSelectSelect from '@components/GrupoSelect';

function TransferenciasFormItens() {
  const { form, orcamentoID } = useTransferenciaForm();
  const { data, isLoading } = GetOrcamentoItens(orcamentoID, false);
  return (
    <Spin spinning={isLoading}>
      <Form.List name="ordemItens">
        {(fields, { add, remove }) => (
          <Card
            size="small"
            title="Itens"
            extra={
              <Button ghost type="primary" size="small" onClick={() => add()}>
                Novo Item
              </Button>
            }
          >
            <Space direction="vertical" style={{ width: '100%' }}>
              {fields.map((field, index) => (
                <div key={field.name}>
                  <Card
                    size="small"
                    title={`Item ${index + 1}:`}
                    extra={<Button size="small" danger onClick={() => remove(field.name)} icon={<MinusOutlined />} />}
                  >
                    <Row gutter={[10, 10]}>
                      <Col span={10}>
                        <Form.Item noStyle shouldUpdate>
                          {({ getFieldValue, setFieldValue }) => {
                            return (
                              <Form.Item
                                label="Item a transferir"
                                name={[field.name, 'orcamentoItemID']}
                                rules={[{ required: true, message: 'Campo obrigatório' }]}
                              >
                                <OrcamentoItemSelect
                                  comSaldo={false}
                                  orcamentoID={getFieldValue('orcamentoID')}
                                  placeholder="Orçamento Destino..."
                                  onChange={(v) => {
                                    console.log(v);
                                    const item = data.itens.find((c) => c.id === v);
                                    const quantidade = getFieldValue(['ordemItens', field.name, 'quantidade']) || 0;
                                    const valorUnitario = item?.realizadoValor / item?.realizadoQuantidade || 0;
                                    setFieldValue(['ordemItens', field.name, 'valorTotal'], quantidade * valorUnitario);
                                    setFieldValue(['ordemItens', field.name, 'valorUnitario'], valorUnitario);
                                    setFieldValue(
                                      ['ordemItens', field.name, 'realizadoQuantidade'],
                                      item.realizadoQuantidade
                                    );
                                  }}
                                />
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item noStyle shouldUpdate>
                          {({ getFieldValue }) => (
                            <>
                              <Form.Item label="Quantidade Disp.">
                                <Typography.Text>
                                  {doubleMask.format(getFieldValue(['ordemItens', field.name, 'realizadoQuantidade']))}
                                </Typography.Text>
                              </Form.Item>
                              <Form.Item name={[field.name, 'realizadoQuantidade']} hidden>
                                <Input />
                              </Form.Item>
                              <Form.Item name={[field.name, 'valorUnitario']} hidden>
                                <Input />
                              </Form.Item>
                            </>
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item
                          noStyle
                          shouldUpdate={(pv, nv) =>
                            pv.ordemItens[field.name]?.orcamentoItemID !== nv.ordemItens[field.name]?.orcamentoItemID
                          }
                        >
                          {({ getFieldValue }) => {
                            return (
                              <Form.Item label="Valor Unit.">
                                <Typography.Text>
                                  {moneyMask.format(getFieldValue(['ordemItens', field.name, 'valorUnitario']) || 0)}
                                </Typography.Text>
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col span={8}>
                        <Form.Item
                          label="Orçamento Destino"
                          name={[field.name, 'orcamentoID']}
                          rules={[{ required: true, message: 'Campo obrigatório' }]}
                        >
                          <OrcamentoSelect
                            placeholder="Orçamento Destino..."
                            onChange={() => {
                              form.setFieldValue(['ordemItens', field.name, 'categoriaID'], null);
                              form.setFieldValue(['ordemItens', field.name, 'grupoID'], null);
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          noStyle
                          shouldUpdate={(pv, nv) =>
                            pv.ordemItens[index]?.orcamentoID !== nv.ordemItens[index]?.orcamentoID
                          }
                        >
                          {({ getFieldValue }) => (
                            <Form.Item
                              name={[field.name, 'categoriaID']}
                              label="Categoria Destino"
                              rules={[{ required: true, message: 'Campo obrigatório' }]}
                            >
                              <CategoriaSelect
                                orcamentoID={getFieldValue(['ordemItens', field.name, 'orcamentoID'])}
                                placeholder="Categoria Destino..."
                                onChange={() => {
                                  form.setFieldValue(['ordemItens', field.name, 'grupoID'], null);
                                }}
                              />
                            </Form.Item>
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          noStyle
                          shouldUpdate={(pv, nv) =>
                            pv.ordemItens[index]?.orcamentoID !== nv.ordemItens[index]?.orcamentoID ||
                            pv.ordemItens[index]?.categoriaID !== nv.ordemItens[index]?.categoriaID
                          }
                        >
                          {({ getFieldValue }) => (
                            <Form.Item
                              name={[field.name, 'grupoID']}
                              label="Grupo Destino"
                              rules={[{ required: true, message: 'Campo obrigatório' }]}
                            >
                              <GrupoSelectSelect
                                orcamentoID={getFieldValue(['ordemItens', field.name, 'orcamentoID'])}
                                placeholder="Grupo Destino..."
                                categoriaID={getFieldValue(['ordemItens', field.name, 'categoriaID'])}
                              />
                            </Form.Item>
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item
                          noStyle
                          shouldUpdate={(pv, nv) =>
                            pv.ordemItens[index]?.quantidade !== nv.ordemItens[index]?.quantidade
                          }
                        >
                          {({ setFieldValue, getFieldValue }) => {
                            return (
                              <Form.Item
                                name={[field.name, 'quantidade']}
                                label="Quantidade"
                                initialValue={0}
                                rules={[
                                  () => ({
                                    validator(_, value) {
                                      if (value === 0) {
                                        return Promise.reject('Valor não pode ser zero');
                                      }
                                      return Promise.resolve();
                                    },
                                  }),
                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (value > getFieldValue(['ordemItens', field.name, 'realizadoQuantidade'])) {
                                        return Promise.reject('Saldo insuficiente');
                                      }
                                      return Promise.resolve();
                                    },
                                  }),
                                ]}
                              >
                                <InputNumber
                                  min={0}
                                  precision={2}
                                  decimalSeparator=","
                                  onChange={(v) =>
                                    setFieldValue(
                                      ['ordemItens', field.name, 'valorTotal'],
                                      moneyMask.format(v * getFieldValue(['ordemItens', field.name, 'valorUnitario']))
                                    )
                                  }
                                />
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item name={[field.name, 'valorTotal']} label="Valor Total" valuePropName="children">
                          <Typography.Text></Typography.Text>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </div>
              ))}
            </Space>
          </Card>
        )}
      </Form.List>
    </Spin>
  );
}
export default TransferenciasFormItens;
