import { ExtendedAction } from '@config/reduxStore';
import { CCA } from '@models/CCA';
import { notification } from 'antd';

interface CCAReducer {
  ccas: CCA[];
  formview: boolean;
  cca: number;
  loadingccas: boolean;
  savingcca: boolean;
  feedback: string;
}
var initialState: CCAReducer = {
  ccas: [],
  formview: false,
  cca: null,
  loadingccas: false,
  savingcca: false,
  feedback: '',
};

const ccasReducer = function (state = initialState, action: ExtendedAction) {
  switch (action.type) {
    case 'RESET_FEEDBACK':
      state = {
        ...state,
        feedback: '',
      };
      break;
    case 'FORM_CCA':
      state = {
        ...state,
        feedback: '',
        formview: action.payload.open,
        cca: action.payload.id ? action.payload.id : null,
      };
      break;
    case 'GET_CCAS_PENDING':
      state = {
        ...state,
        loadingccas: true,
        ccas: initialState.ccas,
      };
      break;
    case 'GET_CCAS_REJECTED':
      state = {
        ...state,
        loadingccas: false,
      };
      break;
    case 'GET_CCAS_FULFILLED':
      state = {
        ...state,
        loadingccas: false,
        ccas: action.payload.data,
      };
      break;
    case 'ADD_CCA_PENDING':
      state = {
        ...state,
        savingcca: true,
        feedback: '',
      };
      break;
    case 'ADD_CCA_REJECTED':
      state = {
        ...state,
        savingcca: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: 'Erro ao cadastrar CCA' });
      break;
    case 'ADD_CCA_FULFILLED':
      state = {
        ...state,
        savingcca: false,
        feedback: 'CCA criado com sucesso!',
        ccas: [...state.ccas, action.payload.data],
      };
      notification.success({ message: 'CCA cadastrado com sucesso!' });
      break;
    case 'UPDATE_CCA_PENDING':
      state = {
        ...state,
        savingcca: true,
        feedback: '',
      };
      break;
    case 'UPDATE_CCA_REJECTED':
      state = {
        ...state,
        savingcca: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: 'Erro ao atualizar CCA' });
      break;
    case 'UPDATE_CCA_FULFILLED':
      state = {
        ...state,
        savingcca: false,
        feedback: 'CCA atualizado com sucesso!',
        ccas: state.ccas.map((c) => {
          if (c.id === action.payload.data.id) return action.payload.data;
          else return c;
        }),
      };
      notification.success({ message: 'CCA atualizado com sucesso!' });
      break;
    case 'DELETE_CCA_PENDING':
      state = {
        ...state,
        loadingccas: true,
        feedback: '',
      };
      break;
    case 'DELETE_CCA_REJECTED':
      notification.error({ message: action.payload.response.data.Message });
      state = {
        ...state,
        loadingccas: false,
        feedback: action.payload.response.data.Message,
      };
      break;
    case 'DELETE_CCA_FULFILLED':
      notification.success({ message: 'CCA excluído com sucesso!' });
      state = {
        ...state,
        loadingccas: false,
        feedback: 'CCA excluído com sucesso!',
        ccas: state.ccas.filter((c) => c.id !== action.payload.data),
      };
      break;
    default:
      return state;
  }
  return state;
};

export default ccasReducer;
