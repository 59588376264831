import { useEffect, useState, useRef } from 'react';
import { Modal, Form, Input, Button, Typography, Row, Col, Checkbox, DatePicker, Radio, Divider } from 'antd';
import { FormRecibo } from '@actions/ordensActions';
import { moneyMask } from '@utils/masks';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import '../../../../../assets/css/Recibo.css';
import ReciboToPrint from './ReciboToPrint';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

function Recibo() {
  const dispatch = useAppDispatch();
  const { ordem, reciboview, feedback } = useAppSelector((state) => state.ordens);
  const [valor, setValor] = useState(undefined);
  const [contratante, setContratante] = useState(undefined);
  const [cpF_CNPJ, setCpf_CNPJ] = useState(undefined);
  const [referente, setReferente] = useState(undefined);
  const [endereco, setEndereco] = useState(undefined);
  const [datarecibo, setDatarecibo] = useState(undefined);
  const [nomePrestador, setNomePrestador] = useState(undefined);
  const [cpF_CNPJPrestador, setCpF_CNPJPrestador] = useState(undefined);
  const [dataPagamento, setDataPagamento] = useState(undefined);
  const [pagamento, setPagamento] = useState('Transferência/Deposito');
  const [checked, setChecked] = useState(false);
  const [form] = Form.useForm();
  const componentRef = useRef();

  useEffect(() => {
    dispatch({ type: 'RESET_FEEDBACK' });
    form.resetFields();
    if (ordem) {
      form.setFieldsValue({
        valor: ordem ? moneyMask.format(ordem.total) : moneyMask.format(0) && setValor(form.getFieldValue(valor)),
        contratante: ordem.empresaFaturamento.nome ? ordem.empresaFaturamento.nome : '',
        cpF_CNPJ: ordem.empresaFaturamento.cpF_CNPJ ? ordem.empresaFaturamento.cpF_CNPJ : '',
        vias: checked,
        referente: ordem.ordensItens
          ? ordem.ordensItens.map((c) => {
              return c.item;
            })
          : '',
        endereco: ordem.empresaFaturamento.endereco ? ordem.empresaFaturamento.endereco : '',
        data: ordem.dataFinanceiro ? moment(ordem.dataFinanceiro) : moment(ordem.dataAprovacao1),
        nomePrestador: ordem.fornecedor.nome,
        cpF_CNPJPrestador: ordem.fornecedor.cpF_CNPJ,
        formaPagamento: 'Transferência/Deposito',
        dataFinanceiro: ordem.dataFinanceiro ? moment(ordem.dataFinanceiro) : moment(ordem.dataAprovacao1),
      });
    }
  }, [ordem, form, checked, valor]);
  return (
    <div>
      <Modal
        open={reciboview}
        onCancel={() => dispatch(FormRecibo(null, false))}
        title="Gerar Recibo"
        footer={[
          <Typography.Text key="1" type="danger">
            {feedback}
          </Typography.Text>,
          <Button onClick={() => dispatch(FormRecibo(null, false))} key="2">
            Fechar
          </Button>,
          <>
            <ReactToPrint
              trigger={() => (
                <Button key="3" type="primary">
                  Imprimir
                </Button>
              )}
              content={() => componentRef.current}
            />
            <div style={{ display: 'none' }}>
              <ReciboToPrint
                ref={componentRef}
                ordem={ordem}
                vias={checked}
                pagamento={pagamento}
                valor={valor}
                contratante={contratante}
                cpF_CNPJ={cpF_CNPJ}
                referente={referente}
                endereco={endereco}
                datarecibo={datarecibo}
                nomePrestador={nomePrestador}
                cpF_CNPJPrestador={cpF_CNPJPrestador}
                dataPagamento={dataPagamento}
              />
            </div>
          </>,
        ]}
      >
        <Row>
          <Col>
            <Form layout="horizontal" form={form} name="perfilForm" wrapperCol={{ span: 16 }} labelCol={{ span: 8 }}>
              <Form.Item style={{ display: 'none' }} name="id">
                <Input />
              </Form.Item>
              <Form.Item
                label="Valor"
                name="valor"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
                normalize={(value) => moneyMask.format(moneyMask.normalize(value))}
              >
                <Input
                  value={valor}
                  onChange={(e) => setValor(moneyMask.format(moneyMask.normalize(e.target.value)))}
                />
              </Form.Item>
              <Form.Item
                label="Contratante"
                name="contratante"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input value={contratante} onChange={(e) => setContratante(e.target.value)} />
              </Form.Item>
              <Form.Item label="CPF ou CNPJ" name="cpF_CNPJ">
                <Input value={cpF_CNPJ} onChange={(e) => setCpf_CNPJ(e.target.value)} />
              </Form.Item>
              <Form.Item
                label="Referente à"
                name="referente"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input type="textarea" value={referente} onChange={(e) => setReferente(e.target.value)} />
              </Form.Item>
              <Form.Item label="Endereço" name="endereco">
                <Input value={endereco} onChange={(e) => setEndereco(e.target.value)} />
              </Form.Item>
              <Form.Item label="Data do recibo" name="data" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                <DatePicker
                  format="DD/MM/YYYY"
                  defaultValue={datarecibo}
                  onChange={(value) => setDatarecibo(moment(value).format('DD/MM/YYYY'))}
                />
              </Form.Item>
              <Divider>Dados do Prestador de Serviço</Divider>
              <Form.Item label="Nome" name="nomePrestador" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                <Input value={nomePrestador} onChange={(e) => setNomePrestador(e.target.value)} />
              </Form.Item>
              <Form.Item
                label="CPF/CNPJ"
                name="cpF_CNPJPrestador"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input value={cpF_CNPJPrestador} onChange={(e) => setCpF_CNPJPrestador(e.target.value)} />
              </Form.Item>
              <Form.Item
                label="Dia do pagamento"
                name="dataFinanceiro"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  defaultValue={dataPagamento}
                  onChange={(value) => setDataPagamento(moment(value).format('DD/MM/YYYY'))}
                />
              </Form.Item>
              <Form.Item
                label="Forma  de Pagamento"
                name="formaPagamento"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Radio.Group defaultValue={pagamento} onChange={(e) => setPagamento(e.target.value)}>
                  <Radio value="Dinheiro">Dinheiro</Radio>
                  <Radio value="Transferência/Deposito">Transferência/Deposito</Radio>
                  <Radio value="Boleto">Boleto</Radio>
                  <Radio value="cartao">Cartão</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item label="Duas Vias?" name="vias" valuePropName="checked">
                <Checkbox checked={checked} onChange={(checked) => setChecked(checked.target.checked)}>
                  Sim
                </Checkbox>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}
export default Recibo;
