import { AppDispatch } from '@config/reduxStore';
import { apiEndpoints } from '@constants/apiEndpoints';
import { Axios } from '@config/axios';

export const GetLegalizacao = function (casaid: number) {
    return function (dispatch: AppDispatch) {
        dispatch({
            type: 'GET_LEGALIZACAO',
            payload: Axios.get(apiEndpoints.LEGALIZACAO.GET_LEGALIZACAO, { params: { casaid } }),
        });
    };
};
export const AddLegalizacao = function (descricao: string, casaid: number, tipo: number) {
    return function (dispatch: AppDispatch) {
        dispatch({
            type: 'ADD_LEGALIZACAO',
            payload: Axios.post(apiEndpoints.LEGALIZACAO.ADD_LEGALIZACAO, { descricao, casaid, tipo }),
        });
    };
};
export const DeleteLegalizacao = function (processoid: number) {
    return function (dispatch: AppDispatch) {
        dispatch({
            type: 'DELETE_LEGALIZACAO',
            payload: Axios.post(apiEndpoints.LEGALIZACAO.DELETE_LEGALIZACAO, { processoid }),
        });
    };
};

export const ChangeOrder = function (casaid: number, processoid: number, up: boolean) {
    return function (dispatch: AppDispatch) {
        dispatch({
            type: 'CHANGE_ORDER_LEGALIZACAO',
            payload: Axios.get(apiEndpoints.LEGALIZACAO.CHANGE_ORDER, { params: { casaid, processoid, up } }),
        });
    };
};
export const ChangeProgresso = function (processoid: number, progresso: number) {
    return function (dispatch: AppDispatch) {
        dispatch({
            type: 'CHANGE_PROGRESSO_LEGALIZACAO_CLIENTE',
            payload: Axios.post(apiEndpoints.LEGALIZACAO.CHANGE_PROGRESSO, { processoid, progresso }),
        });
    };
};
