import { CancelarFolha, ViewCancelForm } from '@actions/folhasActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Form, Input, Modal } from 'antd';

type FormData = {
  motivo: string;
};

function CancelFolhaModalRedux() {
  const dispatch = useAppDispatch();
  const { cancelingFolha, cancelForm } = useAppSelector((state) => state.folhas);
  const [form] = Form.useForm<FormData>();
  const handleSubmit = (values: FormData) => {
    dispatch(CancelarFolha({ id: cancelForm.id, motivo: values.motivo }));
  };
  return (
    <Modal
      open={cancelForm.visible}
      title="Razão do cancelamento/substituição"
      onCancel={() => {
        dispatch(ViewCancelForm({ id: null, view: false }));
      }}
      okButtonProps={{ loading: cancelingFolha }}
      onOk={() => form.submit()}
    >
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item rules={[{ required: true, message: 'Campo obrigatório' }]} name="motivo">
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
}
export default CancelFolhaModalRedux;
