import { ExtendedAction } from '@config/reduxStore';
import { User } from '@models/User';
import { notification } from 'antd';

interface UsuariosReducer {
  usuarios: User[];
  usuario: User | string;
  formview: boolean;
  loadingusuarios: boolean;
  savingusuario: boolean;
  roles: string[];
  loadingroles: boolean;
  savinguser: boolean;
  feedback: string;
}
var initialState: UsuariosReducer = {
  usuarios: [],
  usuario: null,
  formview: false,
  loadingusuarios: false,
  savingusuario: false,
  roles: [],
  loadingroles: false,
  savinguser: false,
  feedback: '',
};

const userReducer = function (state = initialState, action: ExtendedAction) {
  switch (action.type) {
    case 'FORM_USUARIO':
      state = {
        ...state,
        formview: action.payload.open,
        usuario: action.payload.id ? action.payload.id : null,
      };
      break;
    case 'DELETE_USUARIO_PENDING':
      state = {
        ...state,
        loadingusuarios: true,
        feedback: '',
      };
      break;
    case 'DELETE_DELETE_REJECTED':
      state = {
        ...state,
        loadingusuarios: false,
        feedback: action.payload.response.data.Message,
      };
      notification.warning({ message: state.feedback });
      break;
    case 'DELETE_USUARIO_FULFILLED':
      state = {
        ...state,
        loadingusuarios: false,
        feedback: `Usuário ${action.payload.data.ativo ? 'ativado' : 'inativado'} com sucesso!`,
        usuarios: state.usuarios.map((c) => {
          if (c.id === action.payload.data.id) {
            c.ativo = action.payload.data.ativo;
            return c;
          } else return c;
        }),
      };
      notification.success({ message: state.feedback });
      break;
    case 'UPDATE_USUARIO_PENDING':
      state = {
        ...state,
        savingusuario: true,
        feedback: '',
      };
      break;
    case 'UPDATE_USUARIO_REJECTED':
      state = {
        ...state,
        savingusuario: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: 'Erro ao atualizar usuário!' });
      break;
    case 'UPDATE_USUARIO_FULFILLED':
      state = {
        ...state,
        savingusuario: false,
        feedback: 'Usuário editado com sucesso! ',
        usuarios: state.usuarios.map((c) => {
          if (c.id === action.payload.data.id) return action.payload.data;
          else return c;
        }),
      };
      notification.success({ message: 'Usuário atualizado com sucesso!' });
      break;
    case 'ADD_USUARIO_PENDING':
      state = {
        ...state,
        savingusuario: true,
        feedback: '',
      };
      break;
    case 'ADD_USUARIO_REJECTED':
      state = {
        ...state,
        savingusuario: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: 'Já existe uma conta para o e-mail informado' });
      break;
    case 'ADD_USUARIO_FULFILLED':
      state = {
        ...state,
        savingusuario: false,
        feedback: 'Usuário criado com sucesso! Um email foi enviado com as instruções.',
        usuarios: [...state.usuarios, action.payload.data],
      };
      notification.success({ message: 'Usuário criado com sucesso! Um email foi enviado com as instruções.' });
      break;
    case 'ADD_USER_TO_ROLE_PENDING':
      state = {
        ...state,
        savinguser: true,
      };
      break;
    case 'ADD_USER_TO_ROLE_REJECTED':
      state = {
        ...state,
        savinguser: false,
      };
      break;
    case 'ADD_USER_TO_ROLE_FULFILLED':
      state = {
        ...state,
        savinguser: false,
        usuarios: state.usuarios.map((c) => {
          if (c.id === action.payload.userid) {
            if (action.payload.added) c.roles.push(action.payload.role);
            else c.roles = c.roles.filter((r) => r !== action.payload.role);
            return c;
          } else {
            return c;
          }
        }),
      };
      notification.success({ message: 'Perfil atualizado com sucesso!' });
      break;
    case 'GET_ROLES_PENDING':
      state = {
        ...state,
        loadingroles: true,
      };
      break;
    case 'GET_ROLES_REJECTED':
      state = {
        ...state,
        loadingroles: false,
      };
      break;
    case 'GET_ROLES_FULFILLED':
      state = {
        ...state,
        loadingroles: false,
        roles: action.payload.data,
      };
      break;
    case 'GET_USUARIOS_PENDING':
      state = {
        ...state,
        loadingusuarios: true,
      };
      break;
    case 'GET_USUARIOS_REJECTED':
      state = {
        ...state,
        loadingusuarios: false,
      };
      break;
    case 'GET_USUARIOS_FULFILLED':
      state = {
        ...state,
        loadingusuarios: false,
        usuarios: action.payload.data,
      };
      break;
    default:
      return state;
  }
  return state;
};

export default userReducer;
