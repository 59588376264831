import { Button, DatePicker, Segmented, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { SetPageHeader } from '@actions/userActions';
import moment from 'moment';
import '../../../../../assets/scss/DashboardFolha.scss';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { GetDashboardFolhas } from '@actions/folhasActions';
import TablePorFornecedor from './TablePorFornecedor';
import TablePorObra from './TablePorObra';
import TableGeral from './TableGeral';

const views = {
  tudo: 'Tudo',
  porobra: 'Por Obra',
  porfornecedor: 'Por Fornecedor',
};
export type ModoDashboard = 'Adiantamento' | 'Medição';

function DashboardFolhas() {
  const [modo, setModo] = useState<ModoDashboard>('Medição');
  const [view, setView] = useState(views.tudo);
  const [mes, setMes] = useState(moment(new Date()));
  const dispatch = useAppDispatch();
  const { loadingDashboard } = useAppSelector((state) => state.folhas);
  const getFolhas = () => {
    dispatch(GetDashboardFolhas({ mes: mes, modo: modo }));
  };
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Engenharia',
        backbutton: true,
        extras: [
          <DatePicker.MonthPicker key={0} format="MMM/YYYY" value={mes} onChange={(v) => setMes(v)} />,
          <Segmented
            type="primary"
            value={modo}
            options={[
              { label: 'Adiantamento', value: 'Adiantamento' },
              { label: 'Medição', value: 'Medição' },
            ]}
            key={2}
            onChange={(v) => {
              setModo(v as ModoDashboard);
              //dispatch(GetDashboardFolhas({ mes: mes, modo: v as ModoDashboard }));
            }}
          />,
          <Segmented
            type="primary"
            value={view}
            options={['Tudo', 'Por Obra', 'Por Fornecedor']}
            key={1}
            onChange={(v) => {
              setView(v as string);
              //dispatch(GetDashboardFolhas({ mes: mes, modo: v as ModoDashboard }));
            }}
          />,
          <Button type="primary" loading={loadingDashboard} onClick={() => getFolhas()}>
            Atualizar
          </Button>,
        ],
        subtitle: 'Dashboard Folhas',
        menu: 'folhas',
      })
    );
  }, [mes, loadingDashboard, modo, view]);

  useEffect(() => {
    getFolhas();
  }, [mes]);
  return (
    <Spin spinning={loadingDashboard}>
      {view === views.tudo && <TableGeral modo={modo} />}
      {view === views.porobra && <TablePorObra modo={modo} />}
      {view === views.porfornecedor && <TablePorFornecedor modo={modo} />}
    </Spin>
  );
}

export default DashboardFolhas;
