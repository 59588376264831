import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SetPageHeader } from '@actions/userActions';
import {
  GetEmpreendimento,
  ChangeProgressoObra,
  DeleteProcessoObra,
  StepProcessoObra,
  ChangeProgressoLegalizacao,
  DeleteProcessoLegalizacao,
  StepProcessoLegalizacao,
  AddProcessoObra,
  AddProcessoLegalizacao,
  DeleteGaleria,
  NovaGaleriaModal,
  OpenGaleria,
  CadastroInvestimentoModal,
} from '@actions/empsActions';
import { Table, Card, Row, Col, Button, Popconfirm, Slider, Form, Input, Spin, Alert } from 'antd';
import { EyeOutlined, DeleteOutlined, ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { HasPermission, Permission } from '@hooks//usePermissions';
import NovaGaleria from './NovaGaleria';
import GalleryModal from './GalleryModal';
import InvestimentosTable from './InvestimentosTable';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Galeria } from '@models/Galeria';
import { ProcessoEmpreendimento } from '@models/ProcessoEmpreendimento';

function EmpreendimentoControl() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { user } = useAppSelector((state) => state.user);
  const {
    empreendimento,
    loadingempreendimento,
    loadingprogressoobra,
    loadingprogressolegalizacao,
    addingprocessoobra,
    addingprocessolegalizacao,
    savinggaleria,
    deletinggaleria,
  } = useAppSelector((state) => state.emps);
  const { permissions } = useAppSelector((state) => state.user.user);
  useEffect(() => {
    dispatch(GetEmpreendimento(id));
  }, [id]);
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Empreendimentos',
        backbutton: true,
        extras: [],
        subtitle: empreendimento?.nome,
        menu: 'empreendimentos',
      })
    );
  }, [id, empreendimento?.nome]);
  return (
    <Spin spinning={loadingempreendimento}>
      {!(empreendimento?.numCasas > 0) && user.modulos.map((c) => c.nome).includes('Investidor') && (
        <Alert
          message="Para adicionar investidores, defina o número de casas do empreendimento no cadastro"
          type="info"
          showIcon
        />
      )}
      {user.modulos.map((c) => c.nome).includes('Investidor') && (
        <Permission permission="empreendimento?.manage.investimentos">
          <Card
            style={{ marginBottom: 8, display: empreendimento?.numCasas > 0 ? null : 'none' }}
            size="small"
            title="Investidores"
            extra={[
              <Button size="small" type="primary" key="1" onClick={() => dispatch(CadastroInvestimentoModal())}>
                Novo Investimento
              </Button>,
            ]}
          >
            <InvestimentosTable />
          </Card>
        </Permission>
      )}
      <Row gutter={[8, 8]}>
        <Col flex="1 1 33%">
          <Card
            size="small"
            title="Processo de Obra"
            extra={[
              <Form
                key="1"
                name="obraForm"
                layout="inline"
                onFinish={(values) => dispatch(AddProcessoObra({ ...values, empreendimentoid: id, tipo: 0 }))}
              >
                <Form.Item name="descricao" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                  <Input size="small" />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" size="small" htmlType="submit" loading={addingprocessoobra}>
                    Adicionar
                  </Button>
                </Form.Item>
              </Form>,
            ]}
          >
            <Table
              rowKey="id"
              dataSource={empreendimento?.processosObra}
              size="small"
              pagination={false}
              loading={loadingprogressoobra}
            >
              {HasPermission('empreendimento?.manage', permissions) && (
                <Table.Column
                  width={100}
                  render={(_, row: ProcessoEmpreendimento, index) => (
                    <div>
                      <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(DeleteProcessoObra(row.id))}>
                        <Button size="small" icon={<DeleteOutlined />} />
                      </Popconfirm>
                      {index > 0 && (
                        <Button
                          size="small"
                          icon={<ArrowUpOutlined />}
                          onClick={() => dispatch(StepProcessoObra(row.id, true))}
                        />
                      )}
                      {index < empreendimento?.processosObra.length - 1 && (
                        <Button
                          size="small"
                          icon={<ArrowDownOutlined />}
                          onClick={() => dispatch(StepProcessoObra(row.id, false))}
                        />
                      )}
                    </div>
                  )}
                />
              )}
              <Table.Column title="Descrição" dataIndex="descricao" />
              <Table.Column
                width={180}
                title="Progresso"
                dataIndex="progresso"
                render={(text, row: ProcessoEmpreendimento) => (
                  <Slider
                    step={10}
                    min={0}
                    max={100}
                    value={text}
                    onChange={(value: number) => dispatch(ChangeProgressoObra(value, row.id))}
                  />
                )}
              />
            </Table>
          </Card>
        </Col>
        <Col flex="1 1 33%">
          <Card
            size="small"
            title="Processo de Legalização"
            extra={[
              <Form
                key="1"
                name="legalizacaoForm"
                layout="inline"
                onFinish={(values) => dispatch(AddProcessoLegalizacao({ ...values, empreendimentoid: id, tipo: 1 }))}
              >
                <Form.Item name="descricao" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                  <Input size="small" />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" size="small" htmlType="submit" loading={addingprocessolegalizacao}>
                    Adicionar
                  </Button>
                </Form.Item>
              </Form>,
            ]}
          >
            <Table
              rowKey="id"
              dataSource={empreendimento?.processosLegalizacao}
              size="small"
              pagination={false}
              loading={loadingprogressolegalizacao}
            >
              {HasPermission('empreendimento?.manage', permissions) && (
                <Table.Column
                  width={100}
                  render={(_, row: ProcessoEmpreendimento, index) => (
                    <div>
                      <Popconfirm
                        title="Está certo disso?"
                        onConfirm={() => dispatch(DeleteProcessoLegalizacao(row.id))}
                      >
                        <Button size="small" icon={<DeleteOutlined />} />
                      </Popconfirm>
                      {index > 0 && (
                        <Button
                          size="small"
                          icon={<ArrowUpOutlined />}
                          onClick={() => dispatch(StepProcessoLegalizacao(row.id, true))}
                        />
                      )}
                      {index < empreendimento?.processosLegalizacao.length - 1 && (
                        <Button
                          size="small"
                          icon={<ArrowDownOutlined />}
                          onClick={() => dispatch(StepProcessoLegalizacao(row.id, false))}
                        />
                      )}
                    </div>
                  )}
                />
              )}
              <Table.Column title="Descrição" dataIndex="descricao" />
              <Table.Column
                width={180}
                title="Progresso"
                dataIndex="progresso"
                render={(text, row: ProcessoEmpreendimento) => (
                  <Slider
                    step={10}
                    min={0}
                    max={100}
                    value={text}
                    onChange={(value: number) => dispatch(ChangeProgressoLegalizacao(value, row.id))}
                  />
                )}
              />
            </Table>
          </Card>
        </Col>
        <Col flex="1 1 33%">
          <Card
            title="Galeria de Imagens"
            size="small"
            extra={[
              <Button
                loading={savinggaleria}
                key="1"
                type="primary"
                size="small"
                onClick={() => dispatch(NovaGaleriaModal())}
              >
                Nova Galeria
              </Button>,
            ]}
          >
            <NovaGaleria />
            <GalleryModal />
            <Table
              pagination={false}
              loading={deletinggaleria}
              size="small"
              rowKey="id"
              dataSource={empreendimento?.galerias}
            >
              <Table.Column
                render={(_, row: Galeria) => (
                  <div>
                    <Permission permission="empreendimento?.manage">
                      <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(DeleteGaleria(row.id))}>
                        <Button size="small" icon={<DeleteOutlined />} />
                      </Popconfirm>
                    </Permission>
                    <Button size="small" icon={<EyeOutlined />} onClick={() => dispatch(OpenGaleria(row.id))} />
                  </div>
                )}
              />
              <Table.Column title="Item" render={(_, __, index) => index + 1} />
              <Table.Column title="Descrição" dataIndex="titulo" />
              <Table.Column title="Fotos" render={(_, row: Galeria) => row.arquivos.length} />
            </Table>
          </Card>
        </Col>
      </Row>
    </Spin>
  );
}

export default EmpreendimentoControl;
