import { useEffect, useState } from 'react';
function LoadingText({ text }: { text: string }) {
  const [step, setStep] = useState('.');
  const nextStep = () => {
    setStep((pv) => {
      if (pv.length === 3) return '';
      return pv + '.';
    });
  };
  useEffect(() => {
    const interval = setInterval(nextStep, 600);
    return () => clearInterval(interval);
  }, []);
  return (
    <span className="loading-text" style={{ textAlign: 'left', width: 120, display: 'flex' }}>
      {text + step}
    </span>
  );
}

export default LoadingText;
