import { apiEndpoints } from '@constants/apiEndpoints';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Axios } from '@config/axios';

export const updateFormData = createAction('novaOrdem/updateFormData', (data) => ({ payload: data }));
export const updateFields = createAction('novaOrdem/updateFields', (data) => ({ payload: data }));
export const clearFormData = createAction('novaOrdem/clearFormData', () => ({ payload: null }));
export const setAlertas = createAction('novaOrdem/setAlertas', (alertas) => ({ payload: alertas }));

export const getFornecedores = createAsyncThunk('novaOrdem/getFornecedores', async () =>
    Axios.get(apiEndpoints.FORNECEDOR.GET_FORNECEDORES)
);
export const getOrcamentos = createAsyncThunk('novaOrdem/getOrcamentos', async (ativo: boolean) =>
    Axios.get(apiEndpoints.ORCAMENTOS.GET_ORCAMENTOS_LIST, { params: { ativo } })
);

export const getEmpresasFaturamento = createAsyncThunk('novaOrdem/getEmpresasFaturamento', async () =>
    Axios.get(apiEndpoints.EMPRESAS_FATURAMENTO.GET_EMPRESAS)
);

export const getOrcamentoItens = createAsyncThunk('novaOrdem/getOrcamentoItens', async (orcamentoid: number) =>
    Axios.get(apiEndpoints.ORCAMENTOS.GET_ORCAMENTO_ITENS_ORDEM, { params: { orcamentoid } })
);
export const salvarOrdemNova = createAsyncThunk('novaOrdem/salvarOrdemNova', async (data: any, apiThunk) =>
    Axios.post(apiEndpoints.ORDENS.SALVAR_ORDEM_NOVA, data)
        .then((res) => (window.location.href = '/NovaOrdem/' + res.data))
        .catch((res) => apiThunk.rejectWithValue(res))
);

export const getNovaOrdem = createAsyncThunk('novaOrdem/getNovaOrdem', async (id: number | string) =>
    Axios.get(apiEndpoints.ORDENS.GET_NOVA_ORDEM, { params: { id } })
);
export const getSolicitantes = createAsyncThunk('novaOrdem/getSolicitantes', async () =>
    Axios.get(apiEndpoints.ORDENS.GET_SOLICITANTES)
);
export const submeterOrdem = createAsyncThunk('novaOrdem/submeterOrdem', async (id: number | string, thunkAPI) =>
    Axios.get(apiEndpoints.ORDENS.SUBMETER_ORDEM, { params: { id } }).catch((res) =>
        thunkAPI.rejectWithValue(res.response.data)
    )
);
export const CopyOrdem = createAsyncThunk(
    'novaOrdem/CopyOrdem',
    async (data: { id: number; motivo: string; replaced: boolean }, apiThunk) =>
        Axios.get(apiEndpoints.ORDENS.COPY_ORDEM, { params: data }).catch((res) => apiThunk.rejectWithValue(res))
);
export const CancelarOrdem = createAsyncThunk(
    'novaOrdem/Cancelar',
    async ({ id, motivo, cod }: { id: number; motivo: string; cod: string }, apiThunk) =>
        Axios.get(apiEndpoints.ORDENS.CANCELAR_ORDEM, { params: { id, motivo } })
            .then((res) => {
                apiThunk.dispatch(ViewCancelForm({ view: false, id: null, replace: false, cod: cod }));
                apiThunk.fulfillWithValue(res);
            })
            .catch((res) => apiThunk.rejectWithValue(res))
);
export const ViewCancelForm = createAction(
    'novaOrdem/ViewCancelForm',
    ({ view, id, replace, cod }: { view: boolean; id: number | null; replace: boolean; cod: string }) => ({
        payload: { view: view, id: id, replace: replace, cod: cod },
    })
);
export const GetOrdensPendentes = createAsyncThunk('novaOrdem/GetOrdensPendentes', async (_, apiThunk) =>
    Axios.get(apiEndpoints.ORDENS.GET_ORDENS_APROVAR).catch((res) => apiThunk.rejectWithValue(res))
);
export const ViewItensModal = createAction(
    'novaOrdem/ViewItensModal',
    ({ ordemID, visible, cod }: { ordemID: number; visible: boolean; cod: string }) => ({
        payload: { ordemID, visible, cod },
    })
);
export const MaterialEntregue = createAsyncThunk('novaOrdem/MaterialRecebido', async (id: number, apiThunk) =>
    Axios.get(apiEndpoints.ORDENS.MATERIAL_RECEBIDO, { params: { id: id } }).then((res) =>
        apiThunk.fulfillWithValue(res.data)
    )
);

export const GetOrdensPagination = createAsyncThunk('novaOrdem/GetOrdensPagination', async (filters: any, apiThunk) =>
    Axios.post(apiEndpoints.ORDENS.GET_ORDENS_SERVER, filters).catch((res) => apiThunk.rejectWithValue(res))
);
export const GetOrdemItens = createAsyncThunk('novaOrdem/GetOrdemItens', async (id: number, apiThunk) =>
    Axios.get(apiEndpoints.ORDENS.GET_ORDENS_ITENS, { params: { id } }).catch((res) => apiThunk.rejectWithValue(res))
);

export const GetOrdem = createAsyncThunk(
    'novaOrdem/GetOrdem',
    async ({ id, contaID }: { id: { id?: number; cod?: string }; contaID?: number }, apiThunk) =>
        Axios.get(apiEndpoints.ORDENS.GET_ORDEM, { params: { ...id, contaID } }).catch((res) =>
            apiThunk.rejectWithValue(res)
        )
);
export const SetEnvioFornecedorForm = createAction(
    'novaOrdem/SetEnvioFornecedor',
    (data: { ordemID: number | null; visible: boolean | null; email: boolean | null }) => ({ payload: data })
);
export const AprovarOrdem = createAsyncThunk('novaOrdem/AprovarOrdem', async (id: number, apiThunk) =>
    Axios.get(apiEndpoints.ORDENS.APROVAR_ORDEM, { params: { id: id } }).catch((res) => apiThunk.rejectWithValue(res))
);

export const AjusteNF = createAsyncThunk(
    'novaOrdem/AjusteNF',
    async (values: { id: number; valor: number }, apiThunk) =>
        Axios.get(apiEndpoints.ORDENS.AJUSTE_NF, { params: { ...values } }).catch((res) =>
            apiThunk.rejectWithValue(res)
        )
);
export const EnviarOrdem = createAsyncThunk(
    'novaOrdem/EnviarOrdem',
    async (
        values: {
            id: number;
            email: string;
            obs?: string;
            manual: boolean;
            copia: boolean;
            contaID: number;
        },
        apiThunk
    ) =>
        Axios.get(apiEndpoints.ORDENS.ENVIAR_ORDEM, { params: { ...values } }).catch((res) =>
            apiThunk.rejectWithValue(res)
        )
);
