import { Button, Checkbox, Table, Tooltip } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { BloquearItem, OpenComprasView, OpenTransferenciaView } from '@actions/orcamentosActions';
import { naturalSorter } from '@utils/filterandsort';
import { HasPermission } from '@hooks//usePermissions';
import { doubleMask, doubleMask1, moneyMask, moneyNegMask } from '@utils/masks';
import { LockFilled, UnlockOutlined, ShopOutlined, SwapOutlined } from '@ant-design/icons';
import { BloquearGrupo } from '@actions/gruposActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { OrcamentoItem } from '@models/OrcamentoItem';

function OrcamentoTableView({
  orcamentosItens,
  ativo,
  deposito = true,
}: {
  orcamentosItens: OrcamentoItem[];
  ativo: boolean;
  deposito: boolean;
}) {
  const [data, setData] = useState<any>([]);
  const { user } = useAppSelector((state) => state.user);
  const [allKeys, setAllKeys] = useState<React.Key[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const total = orcamentosItens?.reduce((a, b) => a + b.valorTotal, 0) || 0;
    orcamentosItens &&
      setData(
        [...new Set(orcamentosItens.map((c) => c.categoriaID))]
          .map((c) => ({
            id: 'c' + c,
            nivel: 1,
            descricao: orcamentosItens.find((d) => d.categoriaID === c)?.categoria,
            unidade: '',
            peso: orcamentosItens.filter((d) => d.categoriaID === c).reduce((a, b) => a + b.valorTotal, 0) / total,
            valorUnitario: null,
            quantidade: null,
            valorTotal: orcamentosItens.filter((d) => d.categoriaID === c).reduce((a, b) => a + b.valorTotal, 0),
            realizadoValor: orcamentosItens
              .filter((d) => d.categoriaID === c)
              .reduce((a, b) => a + b.realizadoValor, 0),
            realizadoQuantidade: orcamentosItens
              .filter((d) => d.categoriaID === c)
              .reduce((a, b) => a + b.realizadoQuantidade, 0),
            children: [...new Set(orcamentosItens.filter((g) => g.categoriaID === c).map((g) => g.grupoID))]
              .map((g) => ({
                id: 'g' + g,
                nivel: 2,
                categoriaID: c,
                descricao: orcamentosItens.find((d) => d.grupoID === g)?.grupo,
                unidade: '',
                peso: orcamentosItens.filter((d) => d.grupoID === g).reduce((a, b) => a + b.valorTotal, 0) / total,
                valorUnitario: null,
                quantidade: null,
                valorTotal: orcamentosItens.filter((d) => d.grupoID === g).reduce((a, b) => a + b.valorTotal, 0),
                realizadoValor: orcamentosItens
                  .filter((d) => d.grupoID === g)
                  .reduce((a, b) => a + b.realizadoValor, 0),
                realizadoQuantidade: orcamentosItens
                  .filter((d) => d.grupoID === g)
                  .reduce((a, b) => a + b.realizadoQuantidade, 0),
                children: orcamentosItens
                  .filter((oi) => oi.grupoID === g && oi.categoriaID === c)
                  .map((oi) => ({
                    id: oi.id,
                    nivel: 3,
                    bloqueado: oi.bloqueado,
                    descricao: oi.item,
                    unidade: oi.unidade,
                    peso: oi.valorTotal / total,
                    valorUnitario: oi.valorUnitario,
                    quantidade: oi.quantidade,
                    valorTotal: oi.valorTotal,
                    itemTipo: oi.itemTipo,
                    realizadoValor: oi.realizadoValor,
                    realizadoQuantidade: oi.realizadoQuantidade,
                  }))
                  .sort((a, b) => naturalSorter(a.descricao as string, b.descricao as string)),
              }))
              .sort((a, b) => naturalSorter(a.descricao as string, b.descricao as string)),
          }))
          .sort((a: any, b: any) => naturalSorter(a.descricao, b.descricao))
      );
  }, [orcamentosItens]);
  useMemo(() => {
    let keys: React.Key[] = [];
    data.forEach((c: any) => {
      keys.push(c.id);
      c.children.forEach((g: any) => {
        keys.push(g.id);
      });
    });
    setAllKeys(keys);
  }, [data]);
  return (
    <div>
      <Checkbox
        onChange={(e) => {
          if (e.target.checked) {
            setExpandedKeys(allKeys);
          } else setExpandedKeys([]);
        }}
      >
        Expandir tudo
      </Checkbox>
      <Table
        className="orcamentoform"
        size="small"
        pagination={false}
        dataSource={data}
        bordered
        expandable={{
          expandedRowKeys: expandedKeys,
          onExpandedRowsChange: (keys) => setExpandedKeys(keys as React.Key[]),
        }}
        rowKey="id"
        scroll={{ x: 1200, y: 'calc(100vh - 210px)' }}
        rowClassName={(row) => `nivel${row.nivel} ${row.itemTipo === 1 ? 'servico' : ''}`}
        summary={(data) => (
          <Table.Summary fixed>
            <Table.Summary.Row style={{ fontWeight: 'bold' }}>
              <Table.Summary.Cell index={0} colSpan={6} align="right">
                TOTAL
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} align="right">
                {moneyMask.format(data.reduce((a, b) => a + b.valorTotal, 0))}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} colSpan={deposito ? 1 : 2} />
              <Table.Summary.Cell index={3} align="right">
                {moneyMask.format(data.reduce((a, b) => a + b.realizadoValor, 0))}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4} align="right">
                {moneyMask.format(data.reduce((a, b) => a + b.valorTotal - b.realizadoValor, 0))}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      >
        <Table.Column
          fixed
          dataIndex="id"
          width={130}
          render={(v, row: any) =>
            row.nivel === 3 ? (
              <>
                <Tooltip title={row.bloqueado ? 'Desbloquear item' : 'Bloquear Item'}>
                  <Button
                    disabled={!ativo || !HasPermission('grupo.lock', user.permissions)}
                    size="small"
                    icon={row.bloqueado ? <LockFilled /> : <UnlockOutlined />}
                    onClick={() => dispatch(BloquearItem(v))}
                  />
                </Tooltip>
                <Tooltip title="Visualizar compras do item">
                  <Button icon={<ShopOutlined />} onClick={() => dispatch(OpenComprasView(row))} size="small" />
                </Tooltip>
                {row.realizadoQuantidade > 0 && (
                  <Tooltip title="Solicitar Transferência">
                    <Button
                      disabled={!ativo}
                      icon={<SwapOutlined />}
                      onClick={() => dispatch(OpenTransferenciaView(orcamentosItens.find((c) => c.id === v)))}
                      size="small"
                    />
                  </Tooltip>
                )}
              </>
            ) : row.nivel === 2 ? (
              <Tooltip
                title={
                  orcamentosItens.filter((g) => g.grupoID === Number(v.slice(1))).some((g) => !g.bloqueado)
                    ? 'Bloquear Grupo'
                    : 'Desbloquear Grupo'
                }
              >
                <Button
                  disabled={!ativo || !HasPermission('grupo.lock', user.permissions)}
                  size="small"
                  icon={
                    orcamentosItens.filter((g) => g.grupoID === Number(v.slice(1))).some((g) => !g.bloqueado) ? (
                      <UnlockOutlined />
                    ) : (
                      <LockFilled />
                    )
                  }
                  onClick={() =>
                    dispatch(
                      BloquearGrupo(
                        Number(v.slice(1)),
                        orcamentosItens.filter((g) => g.grupoID === Number(v.slice(1))).some((g) => !g.bloqueado)
                      )
                    )
                  }
                />
              </Tooltip>
            ) : null
          }
        />
        <Table.ColumnGroup title="Orçado">
          <Table.Column width={50} title="Peso" dataIndex="peso" render={(v) => doubleMask1.format(v * 100)} />
          <Table.Column title="Descrição" dataIndex="descricao" width={400} />
          <Table.Column width={60} title="Unidade" dataIndex="unidade" align="center" />
          <Table.Column
            width={80}
            title="V. Unitário"
            dataIndex="valorUnitario"
            align="right"
            render={(v, row: any) => row.nivel === 3 && moneyMask.format(v)}
          />
          <Table.Column
            width={80}
            title="Quanti."
            dataIndex="quantidade"
            align="right"
            render={(v, row: any) => row.nivel === 3 && doubleMask.format(v)}
          />
          <Table.Column
            width={120}
            title="Total Orçado"
            dataIndex="valorTotal"
            align="right"
            render={(v) => moneyMask.format(v)}
          />
        </Table.ColumnGroup>
        <Table.ColumnGroup title={deposito ? 'Estoque' : 'Realizado'}>
          {!deposito && (
            <Table.Column
              width={80}
              title="Realizado Quant."
              dataIndex="realizadoQuantidade"
              align="right"
              render={(v, row: any) => row.nivel === 3 && doubleMask.format(v)}
            />
          )}
          {deposito && (
            <Table.Column
              width={80}
              title="Estoque Quant."
              dataIndex="realizadoQuantidade"
              align="right"
              render={(v, row: any) => row.nivel === 3 && doubleMask.format(v)}
            />
          )}
          {!deposito && (
            <Table.Column
              width={80}
              title="Saldo Quant."
              align="right"
              render={(_, row: any) => (
                <span style={{ color: row.quantidade - row.realizadoQuantidade < 0 && 'red' }}>
                  {row.nivel === 3 && doubleMask.format(row.quantidade - row.realizadoQuantidade)}
                </span>
              )}
            />
          )}
          {deposito && (
            <Table.Column
              width={120}
              title="Estoque em Valor"
              dataIndex="realizadoValor"
              align="right"
              render={(v) => moneyMask.format(v)}
            />
          )}
          {!deposito && (
            <Table.Column
              width={120}
              title="Realizado Valor"
              dataIndex="realizadoValor"
              align="right"
              render={(v) => moneyMask.format(v)}
            />
          )}
          <Table.Column
            width={120}
            title="Saldo Valor"
            align="right"
            render={(_, row: OrcamentoItem) => (
              <span style={{ color: row.valorTotal - row.realizadoValor < 0 && 'red' }}>
                {moneyNegMask.format(row.valorTotal - row.realizadoValor)}
              </span>
            )}
          />
        </Table.ColumnGroup>
      </Table>
    </div>
  );
}

export default OrcamentoTableView;
