import { useEffect } from 'react';
import { Modal, Button, Input, Form, Divider, Table, Popconfirm } from 'antd';
import { ShowClienteObra, AddClienteObra, RemoveClienteObra } from '@actions/casasActions';
import { DeleteOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { UserCasa } from '@models/UserCasaa';

function AddClienteObraModal(props: { userCasas: UserCasa[] }) {
  const dispatch = useAppDispatch();
  const { clienteobramodal, savingclienteobra, casas, fichaImovel } = useAppSelector((state) => state.casas);
  const [form] = Form.useForm();
  const submitForm = (values: any) => {
    dispatch(AddClienteObra(values));
  };
  useEffect(() => {
    form.resetFields();
  }, [fichaImovel, props, casas, form]);
  return (
    <Modal
      title="Adicionar cliente à obra"
      open={clienteobramodal}
      onCancel={() => dispatch(ShowClienteObra(null, false))}
      footer={[
        <Button key="2" onClick={() => dispatch(ShowClienteObra(null, false))}>
          Fechar
        </Button>,
        <Button key="3" type="primary" onClick={() => form.submit()} loading={savingclienteobra}>
          Adicionar
        </Button>,
      ]}
    >
      <Form layout="vertical" form={form} onFinish={submitForm} name="addClienteObraForm" initialValues={fichaImovel}>
        <Form.Item style={{ display: 'none' }} label="ID" name="id">
          <Input size="small" />
        </Form.Item>
        <Form.Item label="Nome" name="proprietarioNome1" rules={[{ required: true, message: 'Campo obrigatório' }]}>
          <Input size="small" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="porprietarioEmail"
          rules={[{ required: true, type: 'email', message: 'Email inválido' }]}
        >
          <Input size="small" />
        </Form.Item>
      </Form>
      <Divider />
      <Table size="small" rowKey="id" loading={savingclienteobra} dataSource={props.userCasas}>
        <Table.Column
          render={(_, row: UserCasa) => (
            <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(RemoveClienteObra(row.id))}>
              <Button icon={<DeleteOutlined />} size="small" />
            </Popconfirm>
          )}
        />
        <Table.Column title="Nome" dataIndex={['user', 'nome']} />
        <Table.Column title="Email" dataIndex={['user', 'email']} />
      </Table>
    </Modal>
  );
}

export default AddClienteObraModal;
