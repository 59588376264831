import { AppDispatch } from '@config/reduxStore';
import { apiEndpoints } from '@constants/apiEndpoints';
import { EmpresaFaturamento } from '@models/EmpresaFaturamento';
import { Axios } from '@config/axios';
export const GetEmpresasFaturamento = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_EMPRESASFATURAMENTO',
      payload: Axios.get(apiEndpoints.EMPRESAS_FATURAMENTO.GET_EMPRESAS),
    });
  };
};
export const SalvarEmpresaFaturamento = function (values: EmpresaFaturamento) {
  return function (dispatch: AppDispatch) {
    if (values.id) {
      dispatch({
        type: 'UPDATE_EMPRESAFATURAMENTO',
        payload: Axios.post(apiEndpoints.EMPRESAS_FATURAMENTO.UPDATE_EMPRESA, { ...values }),
      });
    } else {
      dispatch({
        type: 'ADD_EMPRESAFATURAMENTO',
        payload: Axios.post(apiEndpoints.EMPRESAS_FATURAMENTO.UPDATE_EMPRESA, { ...values }),
      });
    }
  };
};
export const DeleteEmpresaFaturamento = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DELETE_EMPRESAFATURAMENTO',
      payload: Axios.get(apiEndpoints.EMPRESAS_FATURAMENTO.DELETE_EMPRESA, {
        params: { id },
      }),
    });
  };
};
export const FormEmpresaFaturamento = function (id: number | null, open: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'FORM_EMPRESAFATURAMENTO',
      payload: { id, open },
    });
  };
};
