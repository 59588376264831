import { useEffect, useState } from 'react';
import { Card, Input, Table } from 'antd';
import { GetCorretores } from '@actions/dashboardActions';
import { filterobject, getColumnSearchProps, sortAlpha } from '@utils/filterandsort';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { CorretorDashboard } from '@models/Dashboard';

function MediaVendas() {
  const dispatch = useAppDispatch();
  const { corretores, loadingcorretores } = useAppSelector((state) => state.dashboard.corretores);
  const [filter, setFilter] = useState<string>('');
  useEffect(() => {
    dispatch(GetCorretores());
  }, []);
  return (
    <Card size="small" title="Estatística Parceiros" style={{ height: 370 }}>
      <div style={{ textAlign: 'right' }}>
        <Input style={{ maxWidth: 200 }} onChange={(e) => setFilter(e.target.value)} placeholder="Buscar" allowClear />
      </div>
      <Table
        size="small"
        rowKey="id"
        style={{ minWidth: 400 }}
        dataSource={filterobject(corretores, filter)}
        loading={loadingcorretores}
        pagination={false}
        scroll={{ y: 240, x: 500 }}
      >
        <Table.Column
          fixed="left"
          title="Parceiro"
          dataIndex="nome"
          sorter={(a, b) => sortAlpha(a.nome, b.nome)}
          {...getColumnSearchProps('nome', 'string', 'Corretor')}
        />
        <Table.Column
          title="Vendas Total"
          dataIndex="vendaTotal"
          sorter={(a, b: CorretorDashboard) => a.vendaTotal - b.vendaTotal}
        />
        <Table.Column
          title="Mês Atual"
          dataIndex="totalMesAtual"
          sorter={(a, b: CorretorDashboard) => a.totalMesAtual - b.totalMesAtual}
        />
        <Table.Column
          title="Mês Anterior"
          dataIndex="totalMesAnterior"
          sorter={(a, b: CorretorDashboard) => a.totalMesAnterior - b.totalMesAnterior}
        />
        <Table.Column
          title="Ano Atual"
          dataIndex="totalAnoAtual"
          sorter={(a, b: CorretorDashboard) => a.totalAnoAtual - b.totalAnoAtual}
        />
        <Table.Column
          title="Ano Anterior"
          dataIndex="totalAnoAnterior"
          sorter={(a, b: CorretorDashboard) => a.totalAnoAnterior - b.totalAnoAnterior}
        />
      </Table>
    </Card>
  );
}

export default MediaVendas;
