import { Provider } from 'react-redux';
import store from '@config/reduxStore';
import Main from './views/Main';
import ptBR from 'antd/es/locale/pt_BR';
import { Button, ConfigProvider, Result, Typography } from 'antd';
import { ErrorBoundary } from 'react-error-boundary';
import './assets/scss/App.scss';
import { BrowserRouter } from 'react-router-dom';
import { NotificationsProvider } from '@hooks/useNotifications';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 3,
      refetchOnWindowFocus: true,
    },
  },
});

function ErrorFallback({ error }: { error: any }) {
  return (
    <Result
      status="500"
      title="Algo deu errado"
      subTitle="Entre em contato com o administrador para mais informações ou tente recarregar a página"
      extra={
        <div>
          <div>
            <Typography.Text type="secondary">{error.message}</Typography.Text>
          </div>
          <Button type="primary" onClick={() => window.location.reload()}>
            Recarregar Página
          </Button>
        </div>
      }
    />
  );
}
function App() {
  ConfigProvider.config({
    theme: {
      primaryColor: '#5087b5',
    },
  });
  return (
    <ConfigProvider locale={ptBR}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <QueryClientProvider client={queryClient}>
          {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
          <BrowserRouter>
            <Provider store={store}>
              <NotificationsProvider>
                <Main />
              </NotificationsProvider>
            </Provider>
          </BrowserRouter>
        </QueryClientProvider>
      </ErrorBoundary>
    </ConfigProvider>
  );
}

export default App;
