import { Button, Input, Popconfirm, Space, Table } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getAuditorias, deleteAuditoria } from '@actions/auditoriasAction';
import { SetPageHeader } from '@actions/userActions';
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  PrinterOutlined,
  CheckOutlined,
  StopOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { filterobject, naturalSorter, orderDate } from '@utils/filterandsort';
import { Permission } from '@hooks//usePermissions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Auditoria } from '@models/Auditoria';

function AuditoriasView() {
  const dispatch = useAppDispatch();
  const { loadingAuditorias, auditorias } = useAppSelector((state) => state.auditorias);
  const [filter, setFilter] = useState('');
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Auditoria',
        backbutton: true,
        extras: [
          <Permission key={0} permission="auditoria.add">
            <Link to="/AuditoriaForm">
              <Button type="primary">Nova Auditoria</Button>
            </Link>
          </Permission>,
        ],
        subtitle: 'Auditorias Realizadas',
        menu: 'auditorias',
      })
    );
  }, [dispatch]);
  useEffect(() => {
    dispatch(getAuditorias());
  }, []);
  return (
    <div>
      <Input
        placeholder="Buscar..."
        style={{ width: 300 }}
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
      />
      <Table
        dataSource={filterobject(auditorias, filter)}
        scroll={{ x: 1000, y: 'calc(100vh - 150px)' }}
        loading={loadingAuditorias}
        size="small"
        rowKey="id"
      >
        <Table.Column
          dataIndex="id"
          width={120}
          render={(text) => (
            <Space>
              <Link to={'/Auditorias/' + text}>
                <Button icon={<EyeOutlined />} size="small" />
              </Link>
              <Permission permission="auditoria.update">
                <Link to={'/AuditoriaForm/' + text}>
                  <Button icon={<EditOutlined />} size="small" />
                </Link>
              </Permission>
              <Permission permission="auditoria.delete">
                <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(deleteAuditoria(text))}>
                  <Button icon={<DeleteOutlined />} size="small" />
                </Popconfirm>
              </Permission>
              <Link to={'/PrintAuditoria/' + text} key={0} target="_blank">
                <Button size="small" icon={<PrinterOutlined />} />
              </Link>
            </Space>
          )}
        />
        <Table.Column
          title="Data"
          dataIndex="data"
          width={100}
          render={(text) => moment(text).format('DD/MM/YYYY')}
          sorter={(a, b: Auditoria) => orderDate(a.data, b.data)}
        />
        <Table.Column
          title="Obra"
          ellipsis
          dataIndex="obra"
          sorter={(a: Auditoria, b: Auditoria) => naturalSorter(a.obra as string, b.obra as string)}
        />
        <Table.Column
          title="Auditor"
          dataIndex="auditor"
          sorter={(a: Auditoria, b: Auditoria) => naturalSorter(a.auditor as string, b.auditor as string)}
        />
        <Table.Column
          title="Responsável"
          dataIndex="responsavel"
          sorter={(a: Auditoria, b: Auditoria) => naturalSorter(a.responsavel as string, b.responsavel as string)}
        />
        <Table.Column
          width={100}
          title="Respondido"
          dataIndex="respondido"
          align="center"
          sorter={(a, b: any) => a.respondido - b.respondido}
          render={(v) => (v ? <CheckOutlined style={{ color: 'green' }} /> : <StopOutlined style={{ color: 'red' }} />)}
        />
      </Table>
    </div>
  );
}

export default AuditoriasView;
