import { AppDispatch } from '@config/reduxStore';
import { apiEndpoints } from '@constants/apiEndpoints';
import { ClienteCorrespondencia } from '@models/ClienteCorrespondencia';
import { HistoricoCliente } from '@models/HistoricoCliente';
import { Axios } from '@config/axios';
import { Pagination } from '@models/General';
import { TablePaginationConfig } from 'antd';
import { FilterValue } from 'antd/lib/table/interface';

export const GetQuadro = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_QUADRO',
      payload: Axios.get(apiEndpoints.CORRESPONDENCIA.GET_QUADRO),
    });
  };
};
export interface ItemOrdem {
  id: number;
  index: number;
}
export const SetPagination = function (pagination: TablePaginationConfig, filters: Record<string, FilterValue>) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SET_PAGINATION_CORRESPONDENCIA',
      payload: { pagination, filters },
    });
  };
};
export const ChangeOrder = function (values: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'CHANGE_ORDER',
      payload: Axios.post(apiEndpoints.CORRESPONDENCIA.CHANGE_ORDER, values),
    });
  };
};
export const GetClientesPagination = function ({
  data,
  geral,
}: {
  data: Pagination<ClienteCorrespondencia>;
  geral?: string;
}) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_CLIENTES_PAGINATION',
      payload: Axios.post('/api/v2/Correspondencia/GetClientesPagination', data, { params: { geral: geral } }),
    });
  };
};
export const GetClientesFull = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_CLIENTES_FULL',
      payload: Axios.get(apiEndpoints.CORRESPONDENCIA.GET_CLIENTES_FULL),
    });
  };
};
export const GetClientesMes = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_CLIENTES_FULL',
      payload: Axios.get(apiEndpoints.CORRESPONDENCIA.GET_CLIENTES_MES),
    });
  };
};
export const FormCliente = function (id: number | null, open: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'FORM_CLIENTE',
      payload: { id, open },
    });
  };
};
export const CheckCPF = function (cpf1: string, cpf2: string) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'CHECK_CPF',
      payload: Axios.get(apiEndpoints.CORRESPONDENCIA.CHECK_CPF, { params: { cpf1, cpf2 } }),
    });
  };
};
export const GetCliente = function (id: number | string) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_CLIENTE',
      payload: Axios.get(apiEndpoints.CORRESPONDENCIA.GET_CLIENTE, { params: { id } }),
    });
  };
};
export const DeleteCliente = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DELETE_CLIENTE',
      payload: Axios.get(apiEndpoints.CORRESPONDENCIA.DELETE_CLIENTE, { params: { id } }),
    });
  };
};
export const AddCliente = function (values: ClienteCorrespondencia) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'ADD_CLIENTE_PENDING',
    });
    Axios.post(apiEndpoints.CORRESPONDENCIA.ADD_CLIENTE, values)
      .then(() => {
        dispatch({
          type: 'ADD_CLIENTE_FULFILLED',
        });
        return (window.location.href = `/ClientesCorrespondencia`);
      })
      .catch((res) => dispatch({ type: 'ADD_CLIENTE_REJECTED', payload: res }));
  };
};
export const AddHistorico = function (values: HistoricoCliente) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'ADD_HISTORICO_CLIENTE',
      payload: Axios.post(apiEndpoints.CORRESPONDENCIA.ADD_HISTORICO, values),
    });
  };
};
export const AddHistoricoFicha = function (values: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'ADD_HISTORICO_FICHA_CLIENTE',
      payload: Axios.post(apiEndpoints.CORRESPONDENCIA.ADD_HISTORICO, values),
    });
  };
};
export const BuscarCasaForm = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'BUSCAR_CASA_FORM',
    });
  };
};
export const GetCasasEscolher = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_CASAS_CLIENTE',
      payload: Axios.get(apiEndpoints.CORRESPONDENCIA.GET_CASAS),
    });
  };
};
export const SalvarCliente = function (values: ClienteCorrespondencia) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SALVAR_CLIENTE',
      payload: Axios.post(apiEndpoints.CORRESPONDENCIA.SALVAR_CLIENTE, values),
    });
  };
};
export interface AvancarModel {
  data: Date;
  notas: string;
  aprovado: boolean;
  relacionamento: boolean;
  id: number;
  step: number;
}
export const Avancar = function (values: AvancarModel) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'AVANCAR',
      payload: Axios.post(apiEndpoints.CORRESPONDENCIA.AVANCAR, values),
    });
  };
};
export const CheckList = function (values: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'CHECKLIST',
      payload: Axios.get(apiEndpoints.CORRESPONDENCIA.CHECKLIST, { params: { ...values } }),
    });
  };
};
export const PerspectivaChange = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'PERSPECTIVA_CHANGE',
      payload: Axios.get(apiEndpoints.CORRESPONDENCIA.PERSPECTIVA_CHANGE, { params: { id } }),
    });
  };
};
export const Desistir = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DESISTIR',
      payload: Axios.get(apiEndpoints.CORRESPONDENCIA.DESISTIR, { params: { id } }),
    });
  };
};
export const AvancarView = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'AVANCAR_VIEW',
    });
  };
};
export const GetClientesPerspectiva = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_CLIENTES_PERSPECTIVA',
      payload: Axios.get(apiEndpoints.CORRESPONDENCIA.GET_CLIENTES_PERSPECTIVA),
    });
  };
};
export const GetEngenharias = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_ENGENHARIAS',
      payload: Axios.get(apiEndpoints.CORRESPONDENCIA.GET_ENGENHARIAS),
    });
  };
};
export const SairPerspectiva = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SAIR_PERSPECTIVA',
      payload: Axios.get(apiEndpoints.CORRESPONDENCIA.SAIR_PERSPECTIVA, { params: { id } }),
    });
  };
};
