import { useEffect } from 'react';
import TableLegalizacaoReport from './TableLegalizacaoReport';
import TableObraReport from './TableObraReport';
import { Row, Col } from 'antd';
import TableArquivosReport from './TableArquivosReport';
import TableGaleriaReport from './TableGaleriaReport';
import { GetArquivos } from '@actions/arquivosActions';
import ReportHeader from './ReportHeader';
import { SetPageHeader } from '@actions/userActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

function DashboardClienteObra() {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  useEffect(() => {
    dispatch(GetArquivos(user.casaid));
    dispatch(
      SetPageHeader({
        title: 'Portal do Cliente',
        subtitle: '',
        menu: '',
        backbutton: false,
      })
    );
  }, [user]);
  return (
    <div>
      {user.casaid !== -1 && (
        <div>
          <ReportHeader />
          <Row gutter={8}>
            <Col flex="1 1 300px">
              <TableLegalizacaoReport />
            </Col>
            <Col flex="1 1 300px">
              <TableObraReport />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col flex="1 1 300px">
              <TableArquivosReport />
            </Col>
            <Col flex="1 1 300px">
              <TableGaleriaReport />
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}

export default DashboardClienteObra;
