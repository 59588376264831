import { Button, Input, Popconfirm, Space, Table } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getDiarios, deleteDiario } from '@actions/diariosActions';
import { SetPageHeader } from '@actions/userActions';
import { EditOutlined, DeleteOutlined, EyeOutlined, PrinterOutlined } from '@ant-design/icons';
import moment from 'moment';
import { filterobject, getColumnSearchProps, naturalSorter, orderDate } from '@utils/filterandsort';
import { HasRole, Permission } from '@hooks//usePermissions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Diario } from '@models/Diario';

function DiariosView() {
  const dispatch = useAppDispatch();
  const { loadingDiarios, diarios } = useAppSelector((state) => state.diarios);
  const { user } = useAppSelector((state) => state.user);
  const [filter, setFilter] = useState('');
  const [filters, setFilters] = useState(null);
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Diarios de Obra',
        backbutton: !user.roles.includes('mestre de obras'),
        extras: [
          <Link key={0} to="/DiarioForm">
            <Button type="primary">Novo Diario</Button>
          </Link>,
        ],
        subtitle: 'Diarios de Obra Preenchidos',
        menu: 'diarios',
      })
    );
  }, [dispatch, user]);
  useEffect(() => {
    dispatch(getDiarios());
  }, []);
  return (
    <div>
      <Input
        placeholder="Buscar..."
        style={{ width: 300 }}
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
      />
      <Table
        dataSource={filterobject(diarios, filter)}
        scroll={{ x: 1000, y: 'calc(100vh - 150px)' }}
        loading={loadingDiarios}
        onChange={(pagination, filters, sorter) => setFilters({ pagination, filters, sorter })}
        size="small"
        rowKey="id"
      >
        <Table.Column
          dataIndex="id"
          width={130}
          render={(text, row: Diario) => (
            <Space>
              <Link to={'/Diarios/' + text}>
                <Button icon={<EyeOutlined />} size="small" />
              </Link>
              {(user.id === row.auditorID || HasRole(['master'], user.roles)) && (
                <Link to={'/DiarioForm/' + text}>
                  <Button icon={<EditOutlined />} size="small" />
                </Link>
              )}
              <Permission permission="diario.delete">
                <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(deleteDiario(text))}>
                  <Button icon={<DeleteOutlined />} size="small" />
                </Popconfirm>
              </Permission>
              <Link to={'/PrintDiario/' + text} key={0} target="_blank">
                <Button size="small" icon={<PrinterOutlined />} />
              </Link>
            </Space>
          )}
        />
        <Table.Column
          title="Data"
          dataIndex="data"
          width={100}
          render={(text) => moment(text).format('DD/MM/YYYY')}
          sorter={(a, b) => orderDate(a.data, b.data)}
          {...getColumnSearchProps('data', 'daterange', 'Data', filters)}
        />
        <Table.Column title="Obra" dataIndex="obra" sorter={(a: Diario, b: Diario) => naturalSorter(a.obra, b.obra)} />
        <Table.Column
          title="Coordenador"
          dataIndex="responsavel"
          sorter={(a: Diario, b: Diario) => naturalSorter(a.responsavel, b.responsavel)}
        />
        <Table.Column
          title="Mestre de Obras"
          dataIndex="mestre"
          sorter={(a: Diario, b: Diario) => naturalSorter(a.mestre, b.mestre)}
        />
      </Table>
    </div>
  );
}

export default DiariosView;
