import { AppDispatch } from '@config/reduxStore';
import { apiEndpoints } from '@constants/apiEndpoints';
import { Parceiro } from '@models/Parceiro';
import { Axios } from '@config/axios';
export const GetParceiros = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_PARCEIROS',
      payload: Axios.get(apiEndpoints.PARCEIROS.GET_PARCEIROS),
    });
  };
};
export const SalvarParceiro = function (values: Parceiro) {
  return function (dispatch: AppDispatch) {
    if (values.id) {
      dispatch({
        type: 'UPDATE_PARCEIRO',
        payload: Axios.post(apiEndpoints.PARCEIROS.SALVAR_PARCEIRO, { ...values }),
      });
    } else {
      dispatch({
        type: 'ADD_PARCEIRO',
        payload: Axios.post(apiEndpoints.PARCEIROS.SALVAR_PARCEIRO, { ...values }),
      });
    }
  };
};
export const DeleteParceiro = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DELETE_PARCEIRO',
      payload: Axios.get(apiEndpoints.PARCEIROS.DELETE_PARCEIRO, { params: { id } }),
    });
  };
};
export const FormParceiro = function (id: number | string, open: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'FORM_PARCEIRO',
      payload: { id, open },
    });
  };
};
