import { ExtendedAction } from '@config/reduxStore';
import { Aditivo } from '@models/Aditivo';
import { Casa } from '@models/Casa';
import { notification } from 'antd';
interface CasaReducer {
  casas: Casa[];
  formview: boolean;
  galeriaformview: boolean;
  clienteobramodal: boolean;
  legalizacaomodal: boolean;
  obramodal: boolean;
  casa: number;
  loadingcasas: boolean;
  savingcasa: boolean;
  feedback: string;
  fichaImovel: Casa | null;
  loadingcasa: boolean;
  loadingprocessos: boolean;
  savingfiles: boolean;
  savingchamado: boolean;
  savingclienteobra: boolean;
  savinggaleria: boolean;
  loadingfiles: boolean;
  downloadprogress: number;
  downloadingfile: number;
  aditivos: Aditivo[];
  loadingAditivos: boolean;
  loadingcasauser: boolean;
}
var initialState: CasaReducer = {
  casas: [],
  formview: false,
  galeriaformview: false,
  clienteobramodal: false,
  legalizacaomodal: false,
  obramodal: false,
  casa: null,
  loadingcasas: false,
  savingcasa: false,
  feedback: '',
  fichaImovel: null,
  loadingcasa: false,
  loadingprocessos: false,
  savingfiles: false,
  savingchamado: false,
  savingclienteobra: false,
  savinggaleria: false,
  loadingfiles: false,
  downloadprogress: 0,
  downloadingfile: null,
  aditivos: [],
  loadingAditivos: false,
  loadingcasauser: false,
};
const casasReducer = function (state = initialState, action: ExtendedAction) {
  switch (action.type) {
    case 'RESET_FEEDBACK':
      state = {
        ...state,
        feedback: '',
      };
      break;
    case 'GET_ADIVITOS_PENDING':
      state = {
        ...state,
        loadingAditivos: true,
      };
      break;
    case 'GET_ADIVITOS_REJECTED':
      state = {
        ...state,
        loadingAditivos: false,
      };
      break;
    case 'GET_ADIVITOS_FULFILLED':
      state = {
        ...state,
        loadingAditivos: false,
        aditivos: action.payload.data,
      };
      break;
    case 'UPDATE_VALUES_CASA':
      state = {
        ...state,
        fichaImovel: { ...state.fichaImovel, ...action.payload },
      };
      break;
    case 'ADD_CHAMADO_PENDING':
      state = {
        ...state,
        savingchamado: true,
      };
      break;
    case 'ADD_CHAMADO_REJECTED':
      state = {
        ...state,
        savingchamado: false,
      };
      break;
    case 'ADD_CHAMADO_FULFILLED':
      state = {
        ...state,
        savingchamado: false,
        fichaImovel: {
          ...state.fichaImovel,
          chamados: [action.payload.data, ...state.fichaImovel.chamados],
        },
      };
      notification.success({ message: 'Chamado aberto com sucesso! Um email foi enviado para os responsáveis.' });
      break;
    case 'DOWLOADING_FILE_CASA':
      state = {
        ...state,
        downloadingfile: action.payload,
      };
      break;
    case 'DOWNLOAD_PROGRESS_CASA':
      state = {
        ...state,
        downloadprogress: action.payload,
      };
      break;
    case 'DOWNLOAD_FILE_CASA_PENDING':
      state = {
        ...state,
        downloadprogress: 0,
      };
      break;
    case 'DOWNLOAD_FILE_CASA_FULFILLED':
      state = {
        ...state,
        downloadprogress: 0,
        downloadingfile: null,
      };
      break;
    case 'DELETE_FILE_CASA_PENDING':
      state = {
        ...state,
        loadingfiles: true,
      };
      break;
    case 'DELETE_FILE_CASA_REJECTED':
      state = {
        ...state,
        loadingfiles: false,
      };
      break;
    case 'DELETE_FILE_CASA_FULFILLED':
      state = {
        ...state,
        loadingfiles: false,
        fichaImovel: {
          ...state.fichaImovel,
          arquivos: state.fichaImovel.arquivos.filter((c) => c.id !== action.payload.data),
        },
      };
      notification.success({ message: 'Arquivo excluído com sucesso!' });
      break;
    case 'DISPONIBILIZAR_ARQUIVO_CLIENTE_PENDING':
      state = {
        ...state,
        loadingfiles: true,
      };
      break;
    case 'DISPONIBILIZAR_ARQUIVO_CLIENTE_REJECTED':
      state = {
        ...state,
        loadingfiles: false,
      };
      break;
    case 'DISPONIBILIZAR_ARQUIVO_CLIENTE_FULFILLED':
      state = {
        ...state,
        loadingfiles: false,
        fichaImovel: {
          ...state.fichaImovel,
          arquivos: state.fichaImovel.arquivos.map((c) => {
            if (c.id === action.payload.data.arquivoid) {
              c.disponivel = action.payload.data.disponivel;
              return c;
            } else return c;
          }),
        },
      };
      notification.success({ message: 'Disponibilidade alterada com sucesso!' });
      break;
    case 'UPLOAD_FILES_CASA_PENDING':
      state = {
        ...state,
        savingfiles: true,
      };
      break;
    case 'UPLOAD_FILES_CASA_REJECTED':
      state = {
        ...state,
        savingfiles: false,
      };
      break;
    case 'UPLOAD_FILES_CASA_FULFILLED':
      state = {
        ...state,
        savingfiles: false,
        fichaImovel: {
          ...state.fichaImovel,
          arquivos: action.payload.data,
        },
      };
      notification.success({ message: 'Arquivos adicionados com sucesso!' });
      break;
    case 'GET_CASA_PENDING':
      state = {
        ...state,
        fichaImovel: initialState.fichaImovel,
        loadingcasa: true,
      };
      break;
    case 'GET_CASA_REJECTED':
      state = {
        ...state,
        loadingcasa: false,
      };
      break;
    case 'GET_CASA_FULFILLED':
      state = {
        ...state,
        loadingcasa: false,
        fichaImovel: action.payload.data,
      };
      break;
    case 'FORM_CASA':
      state = {
        ...state,
        formview: action.payload.open,
        casa: action.payload.id ? action.payload.id : null,
      };
      break;
    case 'FORM_GALERIA':
      state = {
        ...state,
        galeriaformview: action.payload.open,
        casa: action.payload.id ? action.payload.id : null,
      };
      break;
    case 'SHOW_PROGRESSO_OBRA':
      state = {
        ...state,
        obramodal: action.payload.open,
        casa: action.payload.id ? action.payload.id : null,
      };
      break;
    case 'SHOW_CLIENTE_OBRA':
      state = {
        ...state,
        clienteobramodal: action.payload.open,
        casa: action.payload.id ? action.payload.id : null,
      };
      break;
    case 'SHOW_PROGRESSO_LEGALIZACAO':
      state = {
        ...state,
        legalizacaomodal: action.payload.open,
        casa: action.payload.id ? action.payload.id : null,
      };
      break;
    case 'GET_CASAS_PENDING':
      state = {
        ...state,
        loadingcasas: true,
        casa: initialState.casa,
      };
      break;
    case 'GET_CASAS_REJECTED':
      state = {
        ...state,
        loadingcasas: false,
      };
      break;
    case 'GET_CASAS_FULFILLED':
      state = {
        ...state,
        loadingcasas: false,
        casas: action.payload.data,
      };
      break;
    case 'ADD_CASA_PENDING':
      state = {
        ...state,
        savingcasa: true,
        feedback: '',
      };
      break;
    case 'ADD_CASA_REJECTED':
      state = {
        ...state,
        savingcasa: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: 'Erro ao criar casa!' });
      break;
    case 'ADD_CASA_FULFILLED':
      state = {
        ...state,
        savingcasa: false,
        feedback: 'Casa criada com sucesso!',
        casas: [...state.casas, action.payload.data],
      };
      notification.success({ message: 'Casa criada com sucesso!' });
      break;
    case 'ADD_CLIENTE_OBRA_PENDING':
      state = {
        ...state,
        savingclienteobra: true,
        feedback: '',
      };
      break;
    case 'ADD_CLIENTE_OBRA_REJECTED':
      state = {
        ...state,
        savingclienteobra: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: action.payload.response.data.Message });
      break;
    case 'ADD_CLIENTE_OBRA_FULFILLED':
      state = {
        ...state,
        savingclienteobra: false,
        feedback: 'Cliente adicionado à obra com sucesso!',
        fichaImovel: {
          ...state.fichaImovel,
          userCasas: [...state.fichaImovel.userCasas, action.payload.data],
        },
      };
      notification.success({ message: 'Cliente adicionado com sucesso!' });
      break;
    case 'REMOVE_CLIENTE_OBRA_PENDING':
      state = {
        ...state,
        savingclienteobra: true,
        feedback: '',
      };
      break;
    case 'REMOVE_CLIENTE_OBRA_REJECTED':
      state = {
        ...state,
        savingclienteobra: false,
      };
      notification.error({ message: action.payload.response.data.Message });
      break;
    case 'REMOVE_CLIENTE_OBRA_FULFILLED':
      state = {
        ...state,
        savingclienteobra: false,
        fichaImovel: {
          ...state.fichaImovel,
          userCasas: state.fichaImovel.userCasas.filter((c) => c.id !== action.payload.data),
        },
      };
      notification.success({ message: 'Cliente adicionado com sucesso!' });
      break;
    case 'UPDATE_CASA_PENDING':
      state = {
        ...state,
        savingcasa: true,
        feedback: '',
      };
      break;
    case 'UPDATE_CASA_REJECTED':
      state = {
        ...state,
        savingcasa: false,
        feedback: action.payload.response?.data,
      };
      notification.error({ message: 'Erro ao atualizar dados!' });
      break;
    case 'UPDATE_CASA_FULFILLED':
      state = {
        ...state,
        savingcasa: false,
        feedback: 'Casa atualizada com sucesso!',
        casas:
          action.payload.data.ficha === false
            ? state.casas.map((c) => {
                if (c.id === action.payload.data.id) return action.payload.data;
                else return c;
              })
            : state.casas,
        fichaImovel:
          action.payload.data.ficha === true
            ? {
                ...state.fichaImovel,
                ...action.payload.data,
              }
            : state.fichaImovel,
      };
      notification.success({ message: 'Dados atualizados com sucesso!' });
      break;
    case 'DELETE_CASA_PENDING':
      state = {
        ...state,
        loadingcasas: true,
        feedback: '',
      };
      break;
    case 'DELETE_CASA_REJECTED':
      state = {
        ...state,
        loadingcasas: false,
        feedback: action.payload.response.data.Message,
      };
      notification.warning({ message: state.feedback });
      break;
    case 'DELETE_CASA_FULFILLED':
      state = {
        ...state,
        loadingcasas: false,
        feedback: 'Casa excluída com sucesso!',
        casas: state.casas.filter((c) => c.id !== action.payload.data),
      };
      notification.success({ message: state.feedback });
      break;
    case 'CARREGAR_PROCESSOS_PENDING':
      state = {
        ...state,
        loadingprocessos: true,
        feedback: '',
      };
      break;
    case 'CARREGAR_PROCESSOS_REJECTED':
      state = {
        ...state,
        loadingprocessos: false,
        feedback: action.payload.response.data.Message,
      };
      notification.warning({ message: state.feedback });
      break;
    case 'CARREGAR_PROCESSOS_FULFILLED':
      state = {
        ...state,
        loadingprocessos: false,
        feedback: 'Processos carregados com sucesso!',
      };
      notification.success({ message: state.feedback });
      break;
    default:
      return state;
  }
  return state;
};

export default casasReducer;
