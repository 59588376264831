import { Col, Row, Spin } from 'antd';
import FolhaFormResumoFinalGeral from './FolhaForm.ResumoFinal.Geral';
import FolhaFormResumoFinalItens from './FolhaForm.ResumoFinal.Itens';
import FolhaFormResumoFinalRateio from './FolhaForm.ResumoFinal.Rateio';
import FolhaArquivos from './Shared/FolhaArquivos';
import FolhaHistorico from './Shared/FolhaHistorico';
import { useFolhaForm } from './useFolhaForm';

function FolhaFormResumoFinal() {
  const { isFetching } = useFolhaForm();
  return (
    <Spin spinning={isFetching}>
      <div style={{ height: 'calc(100vh - 270px)', overflowY: 'scroll' }}>
        <FolhaFormResumoFinalGeral />
        <FolhaFormResumoFinalItens />
        <FolhaFormResumoFinalRateio />
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <FolhaHistorico />
          </Col>
          <Col span={12}>
            <FolhaArquivos />
          </Col>
        </Row>
      </div>
    </Spin>
  );
}

export default FolhaFormResumoFinal;
