import { OrdemItem } from '@models/OrdemItem';
import { doubleMask, moneyMask } from '@utils/masks';
import { Button, Form, Input, Popconfirm, Space, Table } from 'antd';
import { useOrdemForm } from './useOrdemForm';
import { InfoCircleOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { DeleteItemOrdem } from '@services/api/api.ordem';
import OrdemItemInfoModal from './OrdemItem.ItemInfoModal';
import { useState } from 'react';
import OrdemAlertas from './OrdemForm.Alertas';
import OrdemAddItemModal from './OrdemForm.AddItemModal';

function OrdemFormItens() {
  const { ordem } = useOrdemForm();
  const deleteItem = DeleteItemOrdem();
  const [itemInfo, setItemInfo] = useState(false);
  const [item, setItem] = useState<OrdemItem>(null);
  const [addItem, setAdditem] = useState<OrdemItem>(null);
  const [addItemView, setAdditemView] = useState<boolean>(false);
  return (
    <div>
      <Form.List name="ordensItens">
        {(fields) =>
          fields.map((field) => (
            <div key={field.name}>
              <Form.Item name={[field.name, 'valorUnitario']} hidden>
                <Input />
              </Form.Item>
              <Form.Item name={[field.name, 'quantidade']} hidden>
                <Input />
              </Form.Item>
              <Form.Item name={[field.name, 'desconto']} hidden>
                <Input />
              </Form.Item>
              <Form.Item name={[field.name, 'estourado']} hidden>
                <Input />
              </Form.Item>
            </div>
          ))
        }
      </Form.List>
      <OrdemAddItemModal visible={addItemView} setVisible={setAdditemView} item={addItem} />
      <OrdemItemInfoModal visible={itemInfo} setVisible={setItemInfo} item={item} />
      <Table
        className="ordemItens"
        size="small"
        scroll={{ x: 1000 }}
        dataSource={ordem?.ordensItens}
        pagination={false}
        loading={deleteItem.isLoading}
        rowKey="id"
        rowClassName={(row) => {
          return row.estourado && 'estourado';
        }}
      >
        <Table.Column width={40} title="" render={(_, __, i: number) => i + 1} align="center" />
        <Table.Column
          title=""
          width={70}
          dataIndex="id"
          align="center"
          render={(v, row: OrdemItem) => (
            <Space>
              <Button
                size="small"
                icon={<InfoCircleOutlined style={{ color: row.estourado ? 'red' : undefined }} />}
                onClick={() => {
                  setItem(row);
                  setItemInfo(true);
                }}
              />
              <Popconfirm
                title="Está certo disso?"
                onConfirm={() => deleteItem.mutate({ itemID: v, ordemID: ordem.id })}
              >
                <Button size="small" icon={<DeleteOutlined />} />
              </Popconfirm>
              <Button
                size="small"
                icon={<EditOutlined />}
                onClick={() => {
                  setAdditem(row);
                  setAdditemView(true);
                }}
              />
            </Space>
          )}
        />
        <Table.Column width={150} ellipsis title="Orçamento" dataIndex={['orcamento', 'descricao']} />
        <Table.Column
          title="Item"
          width={150}
          dataIndex={['item', 'descricao']}
          render={(v, row: OrdemItem) => (
            <div>
              <div style={{ fontWeight: 'bold' }}>{v}</div>
              <div style={{ fontStyle: 'italic' }}>{row.descricao}</div>
            </div>
          )}
        />
        <Table.Column
          width={100}
          title="Valor Unitário"
          dataIndex="valorUnitario"
          align="right"
          render={(v) => moneyMask.format(v)}
        />
        <Table.Column
          width={100}
          title="Desconto"
          dataIndex="desconto"
          align="right"
          render={(v) => moneyMask.format(v)}
        />
        <Table.Column
          width={80}
          title="Quantidade"
          dataIndex="quantidade"
          align="right"
          render={(v) => doubleMask.format(v)}
        />
        <Table.Column
          width={100}
          title="Total"
          dataIndex="valorTotal"
          align="right"
          render={(v) => moneyMask.format(v)}
        />
      </Table>
      <OrdemAlertas />
    </div>
  );
}

export default OrdemFormItens;
