import OrcamentoSelect from '@components/OrcamentoSelect';
import SolicitanteSelect from '@components/SolicitanteSelect';
import { Card, Form, Input } from 'antd';
import { useTransferenciaForm } from './useTransferenciaForm';

function TransferenciaFormGeral() {
  const { form, setOrcamentoID } = useTransferenciaForm();
  return (
    <Card size="small" title="Geral">
      <Form.Item name="descricao" label="Descrição" rules={[{ required: true, message: 'Campo obrigatório' }]}>
        <Input placeholder="Descrição..." />
      </Form.Item>
      <Form.Item name="compradorID" label="Solicitante" rules={[{ required: true, message: 'Campo obrigatório' }]}>
        <SolicitanteSelect placeholder="Solicitante..." />
      </Form.Item>
      <Form.Item
        name="orcamentoID"
        label="Orçamento de Origem"
        rules={[{ required: true, message: 'Campo obrigatório' }]}
      >
        <OrcamentoSelect
          placeholder="Orçamento de origem"
          onChange={(v) => {
            form.setFieldValue('ordemItens', []);
            setOrcamentoID(v);
          }}
        />
      </Form.Item>
    </Card>
  );
}

export default TransferenciaFormGeral;
