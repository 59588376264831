import { useEffect, useState } from 'react';
import { Button, Row, Col, Select, Card, Checkbox, Spin } from 'antd';
import { GetPerfis, FormPerfil, GetPermissions, AddPermissionToRole } from '@actions/perfisActions';
import PerfilForm from './PerfilForm';
import { SetPageHeader } from '@actions/userActions';
import { Permission } from '@hooks//usePermissions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

function PerfisView() {
  const dispatch = useAppDispatch();
  const perfis = useAppSelector((state) => state.perfis);
  const { permissions } = useAppSelector((state) => state.perfis);
  const [perfil, setPerfil] = useState(null);
  useEffect(() => {
    dispatch(GetPerfis());
    dispatch(GetPermissions());
    dispatch(
      SetPageHeader({
        title: 'Configurações',
        backbutton: true,
        extras: [
          <Permission key="1" permission="perfil.add">
            {' '}
            <Button onClick={() => dispatch(FormPerfil(null, true))} type="primary">
              Novo Perfil
            </Button>
          </Permission>,
        ],
        subtitle: 'Perfis',
        menu: 'perfils',
      })
    );
  }, []);

  return (
    <div>
      <PerfilForm />
      <Select
        showSearch
        showArrow
        style={{ width: 200 }}
        onChange={(v) => {
          setPerfil(v);
        }}
      >
        {perfis.perfis.map((c) => (
          <Select.Option value={c.name} key={c.id}>
            {c.name.toUpperCase()}
          </Select.Option>
        ))}
      </Select>
      {perfil && (
        <Spin spinning={perfis.loadingpermissions}>
          <Row gutter={[8, 8]}>
            {[...new Set(permissions.map((c) => c.groupName))].map((c, index) => (
              <Col flex="1 1 220px" key={index}>
                <Card title={c}>
                  <Checkbox.Group
                    value={
                      perfil ? perfis.perfis.find((p) => p.name === perfil).permissions.map((p) => p.permission) : []
                    }
                  >
                    {permissions
                      .filter((p) => p.groupName === c)
                      .map((p, index) => (
                        <Row key={index}>
                          <Col>
                            <Checkbox
                              key={c}
                              value={p.name}
                              onChange={(e) => dispatch(AddPermissionToRole(e.target.value, perfil))}
                            >
                              {p.shortName}
                            </Checkbox>
                          </Col>
                        </Row>
                      ))}
                  </Checkbox.Group>
                </Card>
              </Col>
            ))}
          </Row>
        </Spin>
      )}
    </div>
  );
}
export default PerfisView;
