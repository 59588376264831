import { Permission } from '@hooks/usePermissions';
import { AddNotaFiscal, DeleteNotaFiscal, EditNotaFiscal, GetOrdemEdit } from '@services/api/api.ordem';
import { Button, Card, Form, Input, Popconfirm, Table } from 'antd';
import { useParams } from 'react-router-dom';
import { DeleteOutlined, PlusOutlined, EditOutlined, StopOutlined, SaveOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { NotaFiscalOrdem } from '@models/NotaFiscalOrdem';

function OrdemNotasFiscais() {
  const [formNF] = Form.useForm();
  const { id } = useParams();
  const { data: ordem, isLoading } = GetOrdemEdit(id);
  const addNota = AddNotaFiscal();
  const deleteNota = DeleteNotaFiscal();
  const editNota = EditNotaFiscal();
  const [edit, setEdit] = useState<number | null>(null);
  const [editvalue, setEditValue] = useState('');
  return (
    <Card
      title="Nota Fiscal"
      size="small"
      style={{ marginTop: 10 }}
      loading={addNota.isLoading || deleteNota.isLoading || isLoading}
    >
      <Form
        name="notafiscal"
        layout="inline"
        form={formNF}
        onFinish={(values) => addNota.mutate({ ordemID: ordem?.id, numeroNF: values.numero })}
      >
        <Form.Item name="numero" rules={[{ required: true, message: 'Campo Obrigatório' }]}>
          <Input size="small" />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            size="small"
            loading={addNota.isLoading || deleteNota.isLoading}
            htmlType="submit"
            icon={<PlusOutlined />}
          >
            Adicionar
          </Button>
        </Form.Item>
      </Form>
      <Table rowKey="id" pagination={false} dataSource={ordem ? ordem.notaFiscalOrdem : []}>
        <Table.Column
          render={(_, row: NotaFiscalOrdem) =>
            edit === row.id ? (
              <div>
                <Permission permission="ordem.update">
                  <Button icon={<StopOutlined />} size="small" onClick={() => setEdit(null)} />
                  <Button
                    size="small"
                    icon={<SaveOutlined />}
                    onClick={() => {
                      editNota.mutate({ ...row, numero: editvalue, ordemID: Number(id) });
                      setEdit(null);
                    }}
                  />
                </Permission>
              </div>
            ) : (
              <div>
                <Permission permission="ordem.update">
                  <Popconfirm
                    title="Está certo disso?"
                    onConfirm={() => deleteNota.mutate({ id: row.id, ordemID: ordem.id })}
                  >
                    <Button icon={<DeleteOutlined />} size="small" />
                  </Popconfirm>
                  <Button
                    size="small"
                    icon={<EditOutlined />}
                    onClick={() => {
                      setEdit(row.id);
                      setEditValue(row.numero);
                    }}
                  />
                </Permission>
              </div>
            )
          }
        />
        <Table.Column
          title="Número da NF"
          dataIndex="numero"
          render={(numero: string, row: NotaFiscalOrdem) =>
            row.id === edit ? <Input value={editvalue} onChange={(e) => setEditValue(e.target.value)} /> : numero
          }
        />
      </Table>
    </Card>
  );
}

export default OrdemNotasFiscais;
