import { ExtendedAction } from '@config/reduxStore';
import { MessageGroup } from '@models/MessageGroup';
import { Notificacao } from '@models/Notificacao';
import { User } from '@models/User';
import { createReducer } from '@reduxjs/toolkit';
import {
  addNotification,
  checkAllRead,
  getGrupos,
  getGruposUser,
  getLastRead,
  getNewNotification,
  getNotifications,
  getOnlineUsers,
  joinGroup,
  resetUnread,
  setLastRead,
  setMessageRead,
  setMessageUnread,
  setUsers,
} from '@actions/messagesActions';
import { naturalSorter } from '@utils/filterandsort';

interface MessagesReducer {
  groups: MessageGroup[];
  groupsUser: MessageGroup[];
  checkingRead: boolean;
  joiningGroup: boolean;
  loadingGroups: boolean;
  loadingGroupsUser: boolean;
  notifications: Notificacao[];
  loadingNotifications: boolean;
  total: number;
  lastRead: Date;
  unread: number;
  users: User[];
  loadingGrupos: boolean;
  loadingGruposUser: boolean;
}
var initialState: MessagesReducer = {
  groups: [],
  groupsUser: [],
  checkingRead: false,
  joiningGroup: false,
  loadingGroups: false,
  loadingGroupsUser: false,
  notifications: [],
  loadingNotifications: false,
  total: 0,
  lastRead: new Date(),
  unread: 0,
  users: [],
  loadingGrupos: false,
  loadingGruposUser: false,
};

export default createReducer(initialState, (builder) => {
  builder.addCase(checkAllRead.pending, (state) => ({
    ...state,
    checkingRead: true,
  }));
  builder.addCase(checkAllRead.rejected, (state) => ({
    ...state,
    checkingRead: false,
  }));
  builder.addCase(checkAllRead.fulfilled, (state) => ({
    ...state,
    checkingRead: false,
    notifications: state.notifications.map((c) => ({ ...c, read: true })),
  }));
  builder.addCase(getGrupos.pending, (state) => ({
    ...state,
    loadingGroups: true,
  }));
  builder.addCase(getGrupos.rejected, (state) => ({
    ...state,
    loadingGroups: false,
  }));
  builder.addCase(getGrupos.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    loadingGroups: false,
    groups: action.payload.data,
  }));
  builder.addCase(getGruposUser.pending, (state) => ({
    ...state,
    loadingGroupsUser: true,
  }));
  builder.addCase(getGruposUser.rejected, (state) => ({
    ...state,
    loadingGroupsUser: false,
  }));
  builder.addCase(getGruposUser.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    groupsUser: action.payload.data,
    loadingGroupsUser: false,
  }));
  builder.addCase(joinGroup.pending, (state) => ({
    ...state,
    joiningGroup: true,
  }));
  builder.addCase(joinGroup.rejected, (state) => ({
    ...state,
    joiningGroup: false,
  }));
  builder.addCase(joinGroup.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    joiningGroup: false,
    groupsUser: action.payload.data,
  }));
  builder.addCase(getNotifications.pending, (state) => ({
    ...state,
    loadingNotifications: true,
  }));
  builder.addCase(getNotifications.rejected, (state) => ({
    ...state,
    loadingNotifications: false,
  }));
  builder.addCase(getNotifications.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    loadingNotifications: false,
    notifications: [...state.notifications, ...action.payload.data.notifications],
    total: action.payload.data.total,
    unread: action.payload.data.unread,
  }));
  builder.addCase(getNewNotification.pending, (state) => ({
    ...state,
    loadingNotifications: true,
  }));
  builder.addCase(getNewNotification.rejected, (state) => ({
    ...state,
    loadingNotifications: false,
  }));
  builder.addCase(getNewNotification.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    loadingNotifications: false,
    notifications: action.payload.data.notifications,
    total: action.payload.data.total,
    unread: action.payload.data.unread,
  }));
  builder.addCase(addNotification, (state, action: ExtendedAction) => ({
    ...state,
    notifications: [action.payload, ...state.notifications],
    total: state.total + 1,
  }));
  builder.addCase(setLastRead.fulfilled, (state) => ({
    ...state,
    lastRead: new Date(),
    unread: 0,
  }));
  builder.addCase(setMessageRead.pending, (state) => ({
    ...state,
    loadingNotifications: true,
  }));
  builder.addCase(setMessageRead.rejected, (state) => ({
    ...state,
    loadingNotifications: false,
  }));
  builder.addCase(setMessageRead.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    notifications: state.notifications.map((c) => {
      if (c.id === action.payload.data) {
        return { ...c, read: true };
      } else return c;
    }),
  }));
  builder.addCase(setMessageUnread.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    notifications: state.notifications.map((c) => {
      if (c.id === action.payload.data) {
        return { ...c, read: false };
      } else return c;
    }),
  }));
  builder.addCase(getLastRead.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    lastRead: action.payload.data,
  }));
  builder.addCase(resetUnread, (state) => ({
    ...state,
    unread: 0,
  }));
  builder.addCase(setUsers, (state, action: ExtendedAction) => ({
    ...state,
    users: action.payload.users,
  }));
  builder.addCase(getOnlineUsers.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    users: action.payload.data.sort((a: any, b: any) => naturalSorter(a.nome, b.nome)),
  }));
});
