import EmpresaFaturamentoSelect from '@components/EmpresaFaturamentoSelect';
import OrcamentoSelect from '@components/OrcamentoSelect';
import SolicitanteSelect from '@components/SolicitanteSelect';
import { DatePicker, Form, Input } from 'antd';

function FolhaFormGeral() {
  return (
    <>
      <Form.Item name="descricao" label="Descrição" rules={[{ required: true, message: 'Campo obrigatório' }]}>
        <Input placeholder="Descrição..." />
      </Form.Item>
      <Form.Item name="compradorID" label="Solicitante" rules={[{ required: true, message: 'Campo obrigatório' }]}>
        <SolicitanteSelect placeholder="Solicitante..." />
      </Form.Item>
      <Form.Item name="mesCorrente" label="Mês Corrente" rules={[{ required: true, message: 'Campo obrigatório' }]}>
        <DatePicker.MonthPicker format="MM/YYYY" />
      </Form.Item>
      <Form.Item
        name="empresaFaturamentoID"
        label="Empresa Faturamento"
        rules={[{ required: true, message: 'Campo obrigatório' }]}
      >
        <EmpresaFaturamentoSelect placeholder="Empresa faturamento..." />
      </Form.Item>
      <Form.Item name="orcamentoID" label="Orçamento" rules={[{ required: true, message: 'Campo obrigatório' }]}>
        <OrcamentoSelect placeholder="Orçamento..." />
      </Form.Item>
    </>
  );
}

export default FolhaFormGeral;
