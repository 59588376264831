import LoadingText from '@components/LoadingText';
import { Orcamento } from '@models/Orcamento';
import { Descriptions } from 'antd';
import moment from 'moment';
import OrdemStatusTag from '../../Shared/OrdemStatusTag';
import { useOrdemForm } from '../useOrdemForm';

function OrdemResumoFinalHeader() {
  const { ordem, isLoading } = useOrdemForm();
  if (isLoading) return <LoadingText text="Carregando" />;
  return (
    <Descriptions size="small" bordered column={1} title="Geral">
      <Descriptions.Item label="Ordem Nº">{ordem.cod}</Descriptions.Item>
      <Descriptions.Item label="Data">{moment(ordem.dataCriacao).format('DD/MM/YYYY')}</Descriptions.Item>
      <Descriptions.Item label="Situação">{<OrdemStatusTag status={ordem.situacao} />}</Descriptions.Item>
      <Descriptions.Item label="Orçamento">
        {ordem.distribuido ? 'Rateado' : ordem.orcamentoID ? (ordem.orcamento as Orcamento).descricao : 'Indefinido'}
      </Descriptions.Item>
      <Descriptions.Item label="Descrição">{ordem.descricao}</Descriptions.Item>
      <Descriptions.Item label="Solicitante">{ordem.comprador}</Descriptions.Item>
    </Descriptions>
  );
}

export default OrdemResumoFinalHeader;
