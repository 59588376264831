import { SetPageHeader } from '@actions/userActions';
import { useAppDispatch } from '@config/reduxStore';
import { GetPedido } from '@services/api/api.pedidos';
import { Col, Row, Skeleton } from 'antd';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PedidoActions from '../shared/PedidoActions';
import PedidoArquivos from '../shared/PedidoArquivos';
import PedidoHistorico from '../shared/PedidoHistorico';
import PedidoViewBody from './PedidoViewBody';

function PedidoView() {
  const { id } = useParams();
  const { isLoading, data: pedido } = GetPedido(id);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Pedidos',
        subtitle: 'Visualizar pedido',
        extras: [<PedidoActions key={0} pedido={pedido} />],
        menu: 'pedidos',
        backbutton: true,
      })
    );
  }, [pedido]);
  if (isLoading || !pedido) return <Skeleton active />;
  return (
    <div>
      <PedidoViewBody />
      <Row gutter={[8, 8]}>
        <Col span={12}>
          <PedidoHistorico />
        </Col>
        <Col span={12}>
          <PedidoArquivos />
        </Col>
      </Row>
    </div>
  );
}

export default PedidoView;
