import { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import logo from '../../../assets/img/main.png';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { getDiario } from '@actions/diariosActions';
import '../../../assets/scss/PrintDiario.scss';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

function PrintDiario() {
  const { diario, loadingDiario } = useAppSelector((state) => state.diarios);
  const { user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const { id } = useParams();
  useEffect(() => {
    dispatch(getDiario(id));
  }, [dispatch, id]);
  useEffect(() => {
    if (diario && !loadingDiario) {
      const body = (
        <div className="diario">
          <table className="header-table">
            <thead>
              <tr>
                <td>
                  <header className="header">
                    <img src={user.configuracao.logo || logo} alt="logo" height={100} />
                    <h2>Diario de Obra</h2>
                    <div className="header-data">
                      <div className="header-data-item">
                        <div className="header-data-item-label">DATA</div>
                        <div className="header-data-item-value">{moment(diario.data).format('DD/MM/YYYY')}</div>
                      </div>
                      <div className="header-data-item">
                        <div className="header-data-item-label">OBRA</div>
                        <div className="header-data-item-value">{diario.obra?.descricao}</div>
                      </div>
                      <div className="header-data-item">
                        <div className="header-data-item-label">AUDITOR</div>
                        <div className="header-data-item-value">{diario.auditor?.nome}</div>
                      </div>
                      <div className="header-data-item">
                        <div className="header-data-item-label">MESTRE</div>
                        <div className="header-data-item-value">{diario.mestre?.nome}</div>
                      </div>
                      <div className="header-data-item">
                        <div className="header-data-item-label">RESPONSÁVEL</div>
                        <div className="header-data-item-value">{diario.responsavel.nome}</div>
                      </div>
                    </div>
                  </header>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="title">SITUAÇÃO DA OBRA</div>
                  <div className="situacao">
                    <table className="situacao-table">
                      <thead>
                        <tr>
                          <th align="center">CHUVA?</th>
                          <th align="center">PESSOAS NA OBRA</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td align="center">{diario?.chuva ? 'SIM' : 'NÃO'}</td>
                          <td align="center">{diario?.equipe?.length ? 'SIM' : 'NÃO'}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="title">EQUIPE PRESENTE</div>
                  {diario.equipe && (
                    <>
                      <table className="info-table">
                        <thead>
                          <tr>
                            <th>NOME</th>
                            <th>FUNCAO</th>
                          </tr>
                        </thead>
                        <tbody>
                          {diario?.equipe?.map((c) => (
                            <tr key={c.nome}>
                              <th>{c.nome}</th>
                              <th>{c.funcao}</th>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  )}
                  <div className="title">MATERIAIS RECEBIDOS</div>
                  {diario.materiais && (
                    <>
                      <table className="info-table">
                        <thead>
                          <tr>
                            <th>MATERIAL</th>
                            <th>NOTAS</th>
                          </tr>
                        </thead>
                        <tbody>
                          {diario?.materiais?.map((c) => (
                            <tr key={c.material}>
                              <th>{c.material}</th>
                              <th>{c.notas}</th>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  )}
                  <div className="title">SERVIÇOS EM EXECUÇÃO</div>
                  {diario.servicos && (
                    <>
                      <table className="info-table">
                        <thead>
                          <tr>
                            <th>SERVIÇO</th>
                            <th>NOTAS</th>
                          </tr>
                        </thead>
                        <tbody>
                          {diario?.servicos?.map((c) => (
                            <tr key={c.servico}>
                              <th>{c.servico}</th>
                              <th>{c.notas}</th>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  )}
                  <div className="title">SERVIÇOS CONCLUÍDOS</div>
                  {diario.concluidos && (
                    <>
                      <table className="info-table">
                        <thead>
                          <tr>
                            <th>SERVIÇO</th>
                            <th>NOTAS</th>
                          </tr>
                        </thead>
                        <tbody>
                          {diario.concluidos.map((c) => (
                            <tr key={c.servico}>
                              <th>{c.servico}</th>
                              <th>{c.notas}</th>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  )}
                  <div className="title">PENDÊNCIAS</div>
                  {diario.pendencias && (
                    <>
                      <table className="info-table">
                        <thead>
                          <tr>
                            <th>PENDÊNCIA</th>
                            <th>NOTAS</th>
                          </tr>
                        </thead>
                        <tbody>
                          {diario.pendencias.map((c) => (
                            <tr key={c.pendencia}>
                              <th>{c.pendencia}</th>
                              <th>{c.notas}</th>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  )}
                  <div className="title">OCORRÊNCIAS</div>
                  {diario.ocorrencias && (
                    <>
                      <table className="info-table">
                        <thead>
                          <tr>
                            <th>OCORRÊNCIA</th>
                          </tr>
                        </thead>
                        <tbody>
                          {diario.ocorrencias?.map((c) => (
                            <tr key={c.ocorrencia}>
                              <th>{c.ocorrencia}</th>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  )}
                  <div className="title">OBSERVAÇÕES GERAIS</div>
                  <div className="observacoes">{diario.notas}</div>
                  <div className="breakline" />
                  <div className="title">FOTOS</div>
                  <div className="fotos">
                    {diario.fotos.map((c) => (
                      <div className="foto">
                        <img src={c.url} alt={c.fileName} />
                      </div>
                    ))}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
      const root = ReactDOM.createRoot(document.getElementById('root'));
      root.render(body);
      window.print();
    }
  }, [diario]);
  return <div />;
}

export default PrintDiario;
