import { useEffect } from 'react';
import { Descriptions, Skeleton } from 'antd';
import { GetCasa } from '@actions/casasActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

function ReportHeader() {
  const dispatch = useAppDispatch();
  const casaid = useAppSelector((state) => state.user.user.casaid);
  const loading = useAppSelector((state) => state.casas.loadingcasauser);
  const { fichaImovel } = useAppSelector((state) => state.casas);
  useEffect(() => {
    dispatch(GetCasa(casaid));
  }, [casaid]);
  return (
    <div>
      <Skeleton loading={loading}>
        {fichaImovel && (
          <div>
            <Descriptions size="small" bordered>
              <Descriptions.Item label="Proprietário">{fichaImovel.proprietarioNome1}</Descriptions.Item>
              <Descriptions.Item label="Endereço">{`${fichaImovel.endereco}, ${fichaImovel.numero}`}</Descriptions.Item>
              <Descriptions.Item label="Complemento">{fichaImovel.complemento}</Descriptions.Item>
              <Descriptions.Item label="Lote">{fichaImovel.lote}</Descriptions.Item>
              <Descriptions.Item label="Quadra">{fichaImovel.quadra}</Descriptions.Item>
              <Descriptions.Item label="CEP">{fichaImovel.cep}</Descriptions.Item>
            </Descriptions>
          </div>
        )}
      </Skeleton>
    </div>
  );
}
export default ReportHeader;
