import LoadingText from '@components/LoadingText';
import { Col, Descriptions, Row } from 'antd';
import { useOrdemForm } from '../useOrdemForm';

function OrdemResumoFinalFornecedor() {
  const { ordem, isLoading } = useOrdemForm();
  if (isLoading) return <LoadingText text="Carregando" />;
  return (
    <Row>
      <Col span={12}>
        <Descriptions size="small" bordered column={1} title="Fornecedor">
          <Descriptions.Item label="Nome">{ordem.fornecedor?.nome}</Descriptions.Item>
          <Descriptions.Item label="CPF/CNPJ">{ordem.fornecedor?.cpF_CNPJ}</Descriptions.Item>
          <Descriptions.Item label="CEP">{ordem.fornecedor?.cep}</Descriptions.Item>
          <Descriptions.Item label="Endereço">{`${ordem.fornecedor?.endereco}, ${ordem.fornecedor?.cidade}/${ordem.fornecedor?.estado}`}</Descriptions.Item>
          <Descriptions.Item label="Email">{ordem.fornecedor?.email}</Descriptions.Item>
          <Descriptions.Item label="Contato">{`${ordem.fornecedor?.representante} - ${ordem.fornecedor?.telefone}`}</Descriptions.Item>
        </Descriptions>
      </Col>
      <Col span={12}>
        <Descriptions size="small" bordered column={1} title="Dados Pagamento">
          <Descriptions.Item label="Dados Bancários">{`Banco: ${ordem.fornecedor?.banco}, AG: ${ordem.fornecedor?.agencia}, Conta: ${ordem.fornecedor?.contaBancaria}, OP: ${ordem.fornecedor?.operacao}`}</Descriptions.Item>
          <Descriptions.Item label="Nome da Conta">{ordem.fornecedor?.nomeConta}</Descriptions.Item>
          <Descriptions.Item label="CPF/CNPJ da Conta">{ordem.fornecedor?.cpF_CNPJ_Conta}</Descriptions.Item>
          <Descriptions.Item label="PIX">{ordem.fornecedor?.pix}</Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  );
}

export default OrdemResumoFinalFornecedor;
