import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Skeleton,
  Form,
  Input,
  Card,
  Row,
  Col,
  Typography,
  DatePicker,
  Select,
  Radio,
  Button,
  Table,
  List,
  Popconfirm,
  Upload,
  Modal,
  Space,
} from 'antd';
import {
  GetChamado,
  GetResponsaveis,
  AddHistorico,
  DeleteHistorico,
  SalvarAvaliacao,
  SalvarGeral,
  UpdateReclamacao,
  AtualizarReclamacao,
  DeletarReclamacao,
  AddReclamacao,
  DeleteArquivo,
  ConcluirChamado,
  VisitarChamado,
  AvisarEncerramento,
  ShowGarantias,
  ShowLembrete,
} from '@actions/chamadosActions';
import { mobMask } from '@utils/masks';
import moment from 'moment';
import { DeleteOutlined, SaveOutlined, UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { SetPageHeader } from '@actions/userActions';
import { Permission, HasRole, HasPermission } from '@hooks//usePermissions';
import { UploadFiles } from '@actions/chamadosActions';
import { DownloadArquivo } from '@actions/chamadosActions';
import Garantias from './Garantias';
import Lembrete from './Lembrete';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Arquivo } from '@models/Arquivo';
import { Reclamacao } from '@models/Reclamacao';

function ChamadoForm() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [fileModal, setFileModal] = useState(false);
  const [encerrarModal, setEncerrarModal] = useState(false);
  const [visitarModal, setVisitarModal] = useState(false);
  const [files, upFile] = useState([]);
  const { roles, permissions } = useAppSelector((state) => state.user.user);
  const chamado = useAppSelector((state) => state.chamados.chamado);
  const {
    savinghistorico,
    savingavaliacao,
    savinggeral,
    savingreclamacao,
    addingreclamacao,
    savingfiles,
    deletingfile,
    concluindochamado,
    visitandochamado,
    avisandoencerramento,
    savinglembrete,
  } = useAppSelector((state) => state.chamados);
  const responsaveis = useAppSelector((state) => state.chamados.responsaveis);
  const loading = useAppSelector((state) => state.chamados.loadingchamados);
  const [geralform] = Form.useForm();
  const [avaliacaoform] = Form.useForm();
  const [historicoform] = Form.useForm();
  const [reclamacaoform] = Form.useForm();
  const [concluirform] = Form.useForm();
  const [visitarform] = Form.useForm();
  useEffect(() => {
    dispatch(GetChamado(Number(id)));
    dispatch(GetResponsaveis());

    dispatch(
      SetPageHeader({
        title: 'SAC',
        backbutton: true,
        extras: [],
        subtitle: `Chamado Nº ${id}`,
        menu: 'chamados',
      })
    );
  }, [id]);
  useEffect(() => {
    if (chamado) {
      geralform.setFieldsValue({
        ...chamado,
        dataChamado: moment(chamado?.dataChamado).isValid() ? moment(chamado?.dataChamado) : null,
        dataVisita: moment(chamado?.dataVisita).isValid() ? moment(chamado?.dataVisita) : null,
        dataConc: moment(chamado?.dataConc).isValid() ? moment(chamado?.dataConc) : null,
      });
    }
  }, [chamado, geralform]);
  const largurabotoespainel = 150;
  return (
    <div style={{ maxWidth: '100%' }}>
      <Skeleton loading={loading} active paragraph={{ rows: 20, width: '100%' }}>
        <div
          style={{
            textAlign: 'center',
            minWidth: 80,
            marginBottom: 8,
          }}
        >
          <Modal
            open={visitarModal}
            onCancel={() => setVisitarModal(false)}
            title="Informar data da visita"
            footer={[
              <Button key="1" type="primary" onClick={() => visitarform.submit()}>
                Salvar
              </Button>,
            ]}
          >
            <Form
              form={visitarform}
              onFinish={(values) => {
                dispatch(VisitarChamado({ ...values, id, visitaFeita: true }));
                setVisitarModal(false);
              }}
            >
              <Form.Item name="dataVisita" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
                <DatePicker format="DD/MM/YYYY" />
              </Form.Item>
            </Form>
          </Modal>
          <Modal
            open={encerrarModal}
            onCancel={() => setEncerrarModal(false)}
            title="Informar data da conclusão"
            footer={[
              <Button key="1" type="primary" onClick={() => concluirform.submit()}>
                Salvar
              </Button>,
            ]}
          >
            <Form
              form={concluirform}
              onFinish={(values) => {
                dispatch(ConcluirChamado({ ...values, id, concluido: true }));
                setEncerrarModal(false);
              }}
            >
              <Form.Item name="dataConc" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
                <DatePicker format="DD/MM/YYYY" />
              </Form.Item>
            </Form>
          </Modal>
          <Space>
            {!chamado?.visitaFeita && HasRole(['sacadmin', 'master', 'engenharia'], roles) && (
              <Button
                size="small"
                type="primary"
                onClick={() => setVisitarModal(true)}
                loading={visitandochamado}
                style={{ width: largurabotoespainel }}
              >
                Visita Realizada
              </Button>
            )}
            {chamado?.visitaFeita && HasRole(['sacadmin', 'master', 'engenharia'], roles) && !chamado?.concluido && (
              <Button
                size="small"
                style={{ width: largurabotoespainel }}
                loading={visitandochamado}
                onClick={() => dispatch(VisitarChamado({ id: Number(id), visitaFeita: false, dataVisita: null }))}
              >
                Cancelar Visita
              </Button>
            )}
            {!chamado?.concluido &&
              HasPermission('chamado.solicitar.encerramento', permissions) &&
              !HasRole(['sacadmin'], roles) &&
              chamado?.visitaFeita && (
                <Button
                  size="small"
                  type="primary"
                  style={{ width: largurabotoespainel }}
                  onClick={() => dispatch(AvisarEncerramento(Number(id)))}
                  loading={avisandoencerramento}
                >
                  Solicitar Encerramento
                </Button>
              )}
            {!chamado?.concluido && HasRole(['sacadmin', 'master'], roles) && chamado?.visitaFeita && (
              <Button
                size="small"
                type="primary"
                style={{ width: largurabotoespainel }}
                onClick={() => setEncerrarModal(true)}
                loading={concluindochamado}
              >
                Encerrar Chamado
              </Button>
            )}
            {chamado?.concluido && HasRole(['sacadmin', 'master'], roles) && (
              <Button
                size="small"
                style={{ width: largurabotoespainel }}
                loading={concluindochamado}
                onClick={() => dispatch(ConcluirChamado({ id: Number(id), concluido: false, dataConc: null }))}
              >
                Reabrir Chamado
              </Button>
            )}
            {!chamado?.concluido && (
              <>
                <Button
                  size="small"
                  type="primary"
                  ghost
                  onClick={() => window.open(`/FormVisita/${chamado?.id}`, '_blank')}
                >
                  Form. Visita
                </Button>
                <Button
                  size="small"
                  type="primary"
                  ghost
                  onClick={() => window.open(`/FormEncerramento/${chamado?.id}`, '_blank')}
                >
                  Form. Encerramento
                </Button>
                <Button
                  size="small"
                  type="primary"
                  ghost
                  onClick={() => window.open(`/ComunicadoEncerramento/${chamado?.id}`, '_blank')}
                >
                  Comunicado Enc.
                </Button>
                <Button
                  size="small"
                  type="primary"
                  ghost
                  onClick={() => window.open(`/ComunicadoEncerramento/${chamado?.id}`, '_blank')}
                >
                  Carta de Enc.
                </Button>
                <Button
                  size="small"
                  type="primary"
                  ghost
                  loading={savinglembrete}
                  style={{ width: largurabotoespainel }}
                  onClick={() => dispatch(ShowLembrete())}
                >
                  Enviar Lembrete
                </Button>
                <Lembrete />
              </>
            )}
            <Button
              size="small"
              type="primary"
              ghost
              style={{ width: largurabotoespainel }}
              onClick={() => dispatch(ShowGarantias())}
            >
              Garantias
            </Button>
            <Garantias
              garantias={chamado?.garantias}
              data={moment(chamado?.dataChamado)}
              dataContrato={chamado?.dataContrato}
            />
          </Space>
        </div>
        <Row gutter={[8, 8]}>
          <Col flex="1 1 320px">
            <Card
              title="Informações Gerais"
              size="small"
              style={{ minWidth: 320 }}
              extra={[
                <Button
                  loading={savinggeral}
                  disabled={chamado?.concluido}
                  key="2"
                  type="primary"
                  size="small"
                  onClick={() => geralform.submit()}
                >
                  Salvar
                </Button>,
              ]}
            >
              <Form
                form={geralform}
                layout="vertical"
                size="small"
                name="geralForm"
                onFinish={(values) => dispatch(SalvarGeral(values))}
                initialValues={{
                  ...chamado,
                  dataChamado: moment(chamado?.dataChamado).isValid() ? moment(chamado?.dataChamado) : null,
                  dataVisita: moment(chamado?.dataVisita).isValid() ? moment(chamado?.dataVisita) : null,
                  dataConc: moment(chamado?.dataConc).isValid() ? moment(chamado?.dataConc) : null,
                }}
              >
                <Form.Item name="id" style={{ display: 'none' }}>
                  <Input />
                </Form.Item>
                <Row gutter={[8, 8]}>
                  <Col flex="1 1 70%">
                    <Form.Item label="Endereço" name="endereco" valuePropName="children">
                      <Typography.Text />
                    </Form.Item>
                  </Col>
                  <Col flex="1 1 30%">
                    <Form.Item label="Loteamento" name="loteamento" valuePropName="children">
                      <Typography.Text />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[8, 8]}>
                  <Col flex="1 1 60%">
                    <Form.Item label="Proprietário" name="proprietario" valuePropName="children">
                      <Typography.Text />
                    </Form.Item>
                  </Col>
                  <Col flex="0 1 40%">
                    <Form.Item label="Contato" name="contato">
                      <Input
                        onChange={(e) =>
                          geralform.setFieldsValue({
                            contato: mobMask.format(mobMask.normalize(e.target.value)),
                          })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[8, 8]}>
                  <Col flex="0 1 40%">
                    <Form.Item
                      name="dataChamado"
                      label="Data"
                      rules={[{ required: true, message: 'Campo obrigatório' }]}
                    >
                      <DatePicker format="DD/MM/YYYY" />
                    </Form.Item>
                  </Col>
                  <Col flex="1 1 60%">
                    <Form.Item
                      name="responsável"
                      label="Responsável"
                      rules={[{ required: true, message: 'Campo obrigatório' }]}
                    >
                      <Select showSearch optionFilterProp="children">
                        {responsaveis.map((c) => (
                          <Select.Option value={c.id} key={c.id}>
                            {c.nome}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item name="notas" label="Notas">
                  <Input.TextArea />
                </Form.Item>
                <Row gutter={8}>
                  {chamado?.visitaFeita && (
                    <Col flex="0 1 50%">
                      <Form.Item name="dataVisita" label="Data Visita">
                        <DatePicker format="DD/MM/YYYY" disabled={!HasRole(['sacadmin', 'master'], roles)} />
                      </Form.Item>
                    </Col>
                  )}
                  {chamado?.concluido && (
                    <Col flex="0 1 50%">
                      <Form.Item name="dataConc" label="Data Conclusão">
                        <DatePicker format="DD/MM/YYYY" disabled={!HasRole(['sacadmin', 'master'], roles)} />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Form>
            </Card>
          </Col>
          <Col flex="1 1 320px">
            <Card
              title="Histórico"
              size="small"
              style={{ maxHeight: 600, minWidth: 320 }}
              extra={[
                <Button
                  key="1"
                  disabled={chamado?.concluido}
                  loading={savinghistorico}
                  size="small"
                  type="primary"
                  onClick={() => historicoform.submit()}
                >
                  Adicionar
                </Button>,
              ]}
            >
              {!chamado?.concluido && (
                <Form
                  layout="vertical"
                  form={historicoform}
                  name="historicoForm"
                  onFinish={(values) => dispatch(AddHistorico({ ...values, id: chamado?.id }))}
                  initialValues={{ data: moment(new Date()), notas: '' }}
                >
                  <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                  </Form.Item>
                  <Form.Item name="data" rules={[{ required: true, message: 'Obrigatório' }]}>
                    <DatePicker size="small" format="DD/MM/YYYY HH:mm" showTime />
                  </Form.Item>
                  <Form.Item name="notas" rules={[{ required: true, message: 'Obrigatório' }]}>
                    <Input.TextArea size="small" />
                  </Form.Item>
                </Form>
              )}
              <div style={{ height: 270, overflowY: 'scroll', paddingRight: 8 }}>
                <List
                  size="small"
                  loading={savinghistorico}
                  dataSource={chamado?.historicoChamados}
                  renderItem={(item) => (
                    <List.Item
                      extra={[
                        <div key="1">
                          {!chamado?.concluido && (
                            <Popconfirm
                              key={item.id}
                              title="Está certo disso?"
                              onConfirm={() => dispatch(DeleteHistorico(item.id))}
                            >
                              <Button size="small" type="dashed">
                                <DeleteOutlined style={{ cursor: 'pointer' }} />
                              </Button>
                            </Popconfirm>
                          )}
                        </div>,
                      ]}
                    >
                      <List.Item.Meta
                        title={item.notas}
                        description={`${moment(item.data).format('DD/MM/YYYY HH:mm')} - ${item.responsavel}`}
                      />
                    </List.Item>
                  )}
                />
              </div>
            </Card>
          </Col>
          <Col flex="1 1 320px">
            <Card
              title="Avaliação"
              size="small"
              style={{ minWidth: 320 }}
              extra={[
                <Button
                  key="2"
                  disabled={chamado?.concluido}
                  type="primary"
                  size="small"
                  loading={savingavaliacao}
                  onClick={() => avaliacaoform.submit()}
                >
                  Salvar
                </Button>,
              ]}
            >
              <Form
                form={avaliacaoform}
                layout="vertical"
                name="avaliacaoForm"
                onFinish={(values) => dispatch(SalvarAvaliacao(values))}
                initialValues={{ ...chamado }}
              >
                <Form.Item name="id" style={{ display: 'none' }}>
                  <Input />
                </Form.Item>
                <Form.Item label="Atendimento" name="nota1">
                  <Radio.Group>
                    <Radio value="Excelente">Excelente</Radio>
                    <Radio value="Bom">Bom</Radio>
                    <Radio value="Ruim">Ruim</Radio>
                    <Radio value="Sem resposta">Sem resposta</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label="Qualidade" name="nota2">
                  <Radio.Group>
                    <Radio value="Excelente">Excelente</Radio>
                    <Radio value="Bom">Bom</Radio>
                    <Radio value="Ruim">Ruim</Radio>
                    <Radio value="Sem resposta">Sem resposta</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label="Limpeza" name="nota3">
                  <Radio.Group>
                    <Radio value="Excelente">Excelente</Radio>
                    <Radio value="Bom">Bom</Radio>
                    <Radio value="Ruim">Ruim</Radio>
                    <Radio value="Sem resposta">Sem resposta</Radio>
                  </Radio.Group>
                </Form.Item>
              </Form>
            </Card>
          </Col>
          <Col flex="1 1 320px">
            <Card
              title="Arquivos"
              size="small"
              style={{ minWidth: 320 }}
              extra={[
                <Permission key="1" permission="chamado.file.upload">
                  <Button
                    size="small"
                    type="primary"
                    disabled={chamado?.concluido}
                    onClick={() => setFileModal(true)}
                    loading={savingfiles}
                  >
                    Enviar Arquivos
                  </Button>
                  <Modal
                    open={fileModal}
                    title="Enviar Arquivos"
                    onCancel={() => setFileModal(false)}
                    footer={[
                      <Button key="2" onClick={() => setFileModal(false)}>
                        Fechar
                      </Button>,
                      <Button
                        key="1"
                        style={{ display: 'inline' }}
                        type="primary"
                        loading={savingfiles}
                        onClick={() => {
                          const formdata = new FormData();
                          files.map((f) => formdata.append('files', f, f.name));
                          formdata.append('chamadoid', id);
                          dispatch(UploadFiles(formdata));
                          setFileModal(false);
                        }}
                      >
                        Enviar
                      </Button>,
                    ]}
                  >
                    <Upload
                      name="arquivo"
                      disabled={savingfiles}
                      style={{ display: 'inline' }}
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      multiple={true}
                      beforeUpload={(_, fileList) => {
                        upFile([...files, ...fileList]);
                        Promise.resolve(false);
                      }}
                      accept=".pdf,.jpg,.jpeg,.png"
                      onRemove={(file) => {
                        Promise.resolve(false);
                        upFile(files.filter((f) => f !== file));
                      }}
                      fileList={files}
                    >
                      <Button icon={<UploadOutlined />} size="small">
                        Arquivos
                      </Button>
                    </Upload>
                  </Modal>
                </Permission>,
              ]}
            >
              <Table
                size="small"
                rowKey="id"
                loading={deletingfile}
                dataSource={chamado?.arquivos}
                pagination={{ pageSize: 5 }}
                showHeader={false}
              >
                <Table.Column
                  render={(_, row: Arquivo) => (
                    <div>
                      <Permission permission="chamado.file.download">
                        <Button
                          onClick={() => dispatch(DownloadArquivo(row.id, row.fileName))}
                          icon={<DownloadOutlined />}
                          size="small"
                        />
                      </Permission>
                      {!chamado?.concluido && (
                        <Permission permission="chamado.file.delete">
                          <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(DeleteArquivo(row.id))}>
                            <Button icon={<DeleteOutlined />} size="small" />
                          </Popconfirm>
                        </Permission>
                      )}
                    </div>
                  )}
                />
                <Table.Column title="Nome do Arquivo" dataIndex="fileName" />
              </Table>
            </Card>
          </Col>
          <Col flex="1 1 320px">
            <Card
              title="Reclamações"
              size="small"
              style={{ minWidth: 320 }}
              extra={[
                <Button
                  style={{
                    display: HasPermission('chamado.reclamacao.update', permissions) ? 'block' : 'none',
                  }}
                  disabled={chamado?.concluido}
                  key="2"
                  type="primary"
                  size="small"
                  loading={addingreclamacao}
                  onClick={() => reclamacaoform.submit()}
                >
                  Adicionar
                </Button>,
              ]}
            >
              {HasPermission('chamado.reclamacao.add', permissions) && !chamado?.concluido && (
                <Form
                  form={reclamacaoform}
                  layout="vertical"
                  name="reclamacaoForm"
                  onFinish={(values) => dispatch(AddReclamacao(values))}
                  initialValues={{ ...chamado }}
                >
                  <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="descricao"
                    label="Reclamação"
                    rules={[{ required: true, message: 'Campo obrigatório' }]}
                  >
                    <Input placeholder="Descrição da reclamação" />
                  </Form.Item>
                </Form>
              )}
              <Table
                dataSource={chamado?.reclamacoes}
                loading={savingreclamacao}
                size="small"
                rowKey="id"
                pagination={false}
                scroll={{ y: 400, x: 680 }}
              >
                {!chamado?.concluido && (
                  <Table.Column
                    width={40}
                    fixed="left"
                    dataIndex="id"
                    render={(text, row: Reclamacao) => (
                      <div>
                        {HasPermission('chamado.reclamacao.update', permissions) && (
                          <Button type="dashed" size="small" onClick={() => dispatch(AtualizarReclamacao(row))}>
                            <SaveOutlined />
                          </Button>
                        )}
                        {HasPermission('chamado.reclamacao.delete', permissions) && (
                          <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(DeletarReclamacao(text))}>
                            <Button type="dashed" size="small">
                              <DeleteOutlined />
                            </Button>
                          </Popconfirm>
                        )}
                      </div>
                    )}
                  />
                )}
                <Table.Column
                  title="Status"
                  width={120}
                  fixed="left"
                  dataIndex="status"
                  render={(text, row: Reclamacao) => (
                    <Radio.Group
                      value={text}
                      onChange={(e) => dispatch(UpdateReclamacao({ ...row, status: e.target.value }))}
                    >
                      <Radio key="1" value="Em aberto">
                        Em aberto
                      </Radio>
                      <Radio key="2" value="Improcedente">
                        Improcedente
                      </Radio>
                      <Radio key="3" value="Agendado">
                        Agendado
                      </Radio>
                      <Radio key="4" value="Concluído">
                        Concluído
                      </Radio>
                    </Radio.Group>
                  )}
                />
                <Table.Column
                  title="Descrição"
                  dataIndex="descricao"
                  render={(text, row: Reclamacao) => (
                    <Input.TextArea
                      rows={4}
                      value={text}
                      onChange={(e) => dispatch(UpdateReclamacao({ ...row, descricao: e.target.value }))}
                    />
                  )}
                />
                <Table.Column
                  title="Parecer"
                  dataIndex="solucao"
                  render={(text, row: Reclamacao) => (
                    <Input.TextArea
                      rows={4}
                      value={text}
                      onChange={(e) => dispatch(UpdateReclamacao({ ...row, solucao: e.target.value }))}
                    />
                  )}
                />
                <Table.Column
                  title="Datas"
                  width={154}
                  render={(_, row: Reclamacao) => (
                    <div>
                      <DatePicker
                        placeholder="Início"
                        value={moment(row.dataInicio).isValid() ? moment(row.dataInicio) : null}
                        onChange={(v) => dispatch(UpdateReclamacao({ ...row, dataInicio: v }))}
                        format="DD/MM/YYYY"
                      />
                      <DatePicker
                        placeholder="Fim"
                        value={moment(row.dataTermino).isValid() ? moment(row.dataTermino) : null}
                        onChange={(v) => dispatch(UpdateReclamacao({ ...row, dataTermino: v }))}
                        format="DD/MM/YYYY"
                      />
                    </div>
                  )}
                />
              </Table>
            </Card>
          </Col>
        </Row>
      </Skeleton>
    </div>
  );
}
export default ChamadoForm;
