import { Axios } from '@config/axios';
import { apiEndpoints } from '@constants/apiEndpoints';
import { TransferenciaList } from '@models/Transferencia';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { ViewCancelForm } from './novaOrdemActions';

export const GetTransferencias = createAsyncThunk(
  'transferencias/GetTransferencias',
  async (data: {
    filtros: {
      filters: Record<string, FilterValue>;
      pagination: TablePaginationConfig;
      sorter: SorterResult<TransferenciaList> | SorterResult<TransferenciaList>[];
    };
    itemID?: number;
    my?: boolean;
  }) =>
    Axios.post(apiEndpoints.TRANSFERENCIAS.GET_TRANSFERENCIAS, data.filtros, {
      params: { itemID: data.itemID, my: data.my },
    })
);
export const GetPendentes = createAsyncThunk('transferencias/GetPendentes', async () =>
  Axios.get(apiEndpoints.TRANSFERENCIAS.PENDENTES)
);
export const CancelarTransferencia = createAsyncThunk(
  'transferencias/Cancelar',
  async ({ id, motivo }: { id: number; motivo: string }, apiThunk) =>
    Axios.get(apiEndpoints.TRANSFERENCIAS.CANCELAR, { params: { id: id, motivo: motivo } })
      .catch((res) => apiThunk.rejectWithValue(res))
      .then((res) => {
        apiThunk.dispatch(ViewCancelForm({ view: false, id: null, replace: false, cod: null }));
        apiThunk.fulfillWithValue(res);
      })
);

export const FinalizarTransferencia = createAsyncThunk('transferencias/Finalizar', async (id: number, apiThunk) =>
  Axios.get(apiEndpoints.TRANSFERENCIAS.FINALIZAR, { params: { id: id } }).catch((res) => apiThunk.rejectWithValue(res))
);
export const AprovarTransferencia = createAsyncThunk('transferencias/Aprovar', async (id: number, apiThunk) =>
  Axios.get(apiEndpoints.TRANSFERENCIAS.APROVAR, { params: { id: Number(id), cod: id } }).catch((res) =>
    apiThunk.rejectWithValue(res)
  )
);
