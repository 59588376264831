import { useEffect } from 'react';
import { Form, Input, Button, Col, Typography, Card, Tabs, Descriptions, Popconfirm, Checkbox, Space, Row } from 'antd';
import { ChangePassword, SetPageHeader } from '@actions/userActions';
import moment from 'moment';
import { HasRole } from '@hooks//usePermissions';
import axios from 'axios';
import { getGrupos, getGruposUser, joinGroup } from '@actions/messagesActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

interface FormData {
  oldpassword: string;
  newpassword: string;
  ConfirmPassword: string;
}
const { Text } = Typography;
function ProfileView() {
  const { user, reset, paypal } = useAppSelector((state) => state.user);
  const { loadingGrupos, loadingGruposUser, groups, groupsUser } = useAppSelector((state) => state.messages);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<FormData>();
  const onFinish = (values: FormData) => {
    dispatch(ChangePassword(values));
  };
  useEffect(() => {
    dispatch(getGrupos());
    dispatch(getGruposUser());
    dispatch(
      SetPageHeader({
        title: 'Perfil do Usuário',
        backbutton: true,
        extras: [],
        subtitle: 'Meu Perfil',
        menu: 'profile',
      })
    );
  }, []);
  return (
    <div>
      <h3>Olá, {user.nome}</h3>
      <Tabs>
        {!HasRole(['clienteobra', 'investidor']) && (
          <Tabs.TabPane tab="Notificações" key={0}>
            <Row gutter={[8, 8]}>
              {[...new Set(groups.map((c) => c.group))].map((c) => (
                <Col key={c}>
                  <Card title={c} loading={loadingGrupos || loadingGruposUser} style={{ height: 294, width: 212 }}>
                    <Space direction="vertical">
                      {groups
                        .filter((g) => g.group === c)
                        .map((g) => (
                          <Checkbox
                            key={g.id}
                            value={g.id}
                            checked={!!groupsUser.find((a) => a.id === g.id)}
                            onChange={() => {
                              dispatch(joinGroup(g.id));
                            }}
                          >
                            {g.name}
                          </Checkbox>
                        ))}
                    </Space>
                  </Card>
                </Col>
              ))}
            </Row>
          </Tabs.TabPane>
        )}

        <Tabs.TabPane key={1} tab="Redefinir Senha">
          <Form style={{ width: 300 }} onFinish={onFinish} form={form} name="resetForm" layout="vertical">
            <Form.Item
              name="oldpassword"
              label="Senha Atual"
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input.Password placeholder="Senha Atual" />
            </Form.Item>
            <Form.Item
              name="newpassword"
              label="Nova Senha"
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório!',
                },
                {
                  pattern: /^(?=.{6,})/,
                  message: 'A senha deve conter no mínimo 6 dígitos',
                },
                {
                  pattern: /^(?=.*[a-z])/,
                  message: 'A senha deve conter ao menos uma letra minúscula',
                },
                {
                  pattern: /^(?=.*[A-Z])/,
                  message: 'A senha deve conter ao menos uma letra maiúscula',
                },
                {
                  pattern: /^(?=.*[0-9])/,
                  message: 'A senha deve conter ao menos um número',
                },
                {
                  pattern: /^(?=.*[!@#$%^&*])/,
                  message: 'A senha deve conter ao menos um caractére especial',
                },
              ]}
            >
              <Input.Password placeholder="Senha" />
            </Form.Item>
            <Form.Item
              name="ConfirmPassword"
              label="Confirmar Senha"
              dependencies={['newpassword']}
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newpassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject('As senhas não conferem!');
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Senha" />
            </Form.Item>
            <Form.Item>
              <Button block loading={reset.loading} type="primary" htmlType="submit">
                Solicitar Renovação
              </Button>
            </Form.Item>
            <Form.Item name="feedback">
              <Text type="danger">{reset.feedback}</Text>
            </Form.Item>
          </Form>
        </Tabs.TabPane>
        {HasRole(['master'], user.roles) && (
          <Tabs.TabPane key={2} tab="Assinatura">
            <Descriptions bordered>
              <Descriptions.Item label="Status">{user.subscription.status}</Descriptions.Item>
              <Descriptions.Item label="Data de Início">
                {moment(user.subscription.start_time).format('DD/MM/YYYY')}
              </Descriptions.Item>
            </Descriptions>
            <div style={{ width: 300, margin: 20 }}>
              <Popconfirm
                title="Está certo disso? O sistema ficará disponível até a data de expiração já paga."
                onConfirm={() => {
                  const params = new URLSearchParams();
                  params.append('grant_type', 'client_credentials');
                  axios
                    .post(`${paypal.api}/v1/oauth2/token`, params, {
                      headers: { Accept: 'application/json', 'Accept-Language': 'en_US' },
                      auth: {
                        username: paypal.clientid,
                        password: paypal.secret,
                      },
                    })
                    .then((res) => {
                      axios
                        .post(
                          `${paypal.api}/v1/billing/subscriptions/${user.conta.subscriptionID}/cancel`,
                          { reason: 'Insatisfeito' },
                          {
                            headers: {
                              Authorization: `Bearer ${res.data.access_token}`,
                              'Content-Type': 'application/json',
                            },
                          }
                        )
                        .then(() => (window.location.href = '/Contratar'));
                    });
                }}
              >
                <Button>Cancelar Assinatura</Button>
              </Popconfirm>
            </div>
          </Tabs.TabPane>
        )}
      </Tabs>
    </div>
  );
}
export default ProfileView;
