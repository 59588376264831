import { ViewCancelForm } from '@actions/novaOrdemActions';
import LoadingText from '@components/LoadingText';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { OrdemStatus } from '@constants/ordemStatus';
import { HasPermission } from '@hooks/usePermissions';
import { AprovarFolha, CancelarFolha, FinalizarFolha, SubmeterFolha } from '@services/api/api.folha';
import { EnviarParaPagamentoOrdem } from '@services/api/api.ordem';
import { Button, Popconfirm, Space } from 'antd';
import { useParams } from 'react-router-dom';
import { useFolhaForm } from '../useFolhaForm';
import FolhaCancelarModal from './FolhaCancelarModal';

function FolhaActions() {
  const { id } = useParams();
  const { folha, isLoading, isFetching } = useFolhaForm();
  const { folhasPendentes } = useAppSelector((state) => state.folhas);
  const submeterFolha = SubmeterFolha();
  const cancelarFolha = CancelarFolha();
  const finalizarFolha = FinalizarFolha();
  const enviarPagamento = EnviarParaPagamentoOrdem();
  const aprovarFolha = AprovarFolha();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  console.log(folha?.situacao === OrdemStatus.PendenteAprovacao);
  if (isLoading || isFetching) return <LoadingText text="Carregando" />;
  return (
    <Space>
      <FolhaCancelarModal />
      {folha?.situacao === OrdemStatus.Rascunho && (
        <Button
          type="primary"
          size="small"
          loading={submeterFolha.isLoading}
          onClick={() => submeterFolha.mutate({ id: folha.id })}
        >
          Submeter
        </Button>
      )}
      {folha?.situacao === OrdemStatus.PendenteAprovacao &&
        folhasPendentes.some((c) => c.id === Number(id) || c.cod === id) && (
          <Button
            type="primary"
            size="small"
            loading={aprovarFolha.isLoading}
            onClick={() => aprovarFolha.mutate({ id: Number(id), cod: id })}
          >
            Aprovar
          </Button>
        )}
      {folha?.situacao === OrdemStatus.Aprovada && (
        <Button
          type="primary"
          size="small"
          loading={enviarPagamento.isLoading}
          onClick={() => enviarPagamento.mutate({ id: folha.id, cod: folha.cod })}
        >
          Enviar para Pagamento
        </Button>
      )}

      {folha?.situacao === OrdemStatus.PendenteDocumentos && <Button type="primary">Finalizar</Button>}
      {folha?.situacao === OrdemStatus.PendenteDocumentos ||
        (folha?.situacao === OrdemStatus.PendentePagamento && HasPermission('ordem.financeiro', user.permissions) && (
          <Popconfirm title="Está certo disso?" onConfirm={() => finalizarFolha.mutate({ id: folha.id })}>
            <Button type="primary" loading={finalizarFolha.isLoading} size="small">
              Finalizar
            </Button>
          </Popconfirm>
        ))}
      {(folha?.situacao === OrdemStatus.Rascunho ||
        folha?.situacao === OrdemStatus.PendenteAprovacao ||
        HasPermission('ordem.cancel', user.permissions)) &&
        folha?.situacao !== OrdemStatus.Cancelada && (
          <Button
            danger
            size="small"
            loading={cancelarFolha.isLoading}
            onClick={() => {
              dispatch(ViewCancelForm({ view: true, id: folha.id, replace: false, cod: folha.cod }));
            }}
          >
            Cancelar
          </Button>
        )}
    </Space>
  );
}

export default FolhaActions;
