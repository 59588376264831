import { PagarFolha, SubmeterFolha } from '@actions/folhasActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Role } from '@hooks/usePermissions';
import { Button, Popconfirm, Space } from 'antd';
import LoadingText from '@components/LoadingText';
import { OrdemStatus } from '@constants/ordemStatus';
import { Link } from 'react-router-dom';
import { PrinterOutlined } from '@ant-design/icons';

function MenuFolha() {
  const dispatch = useAppDispatch();
  const { loadingFolha, folha } = useAppSelector((state) => state.folhas);
  if (loadingFolha) return <LoadingText text="Carregando" />;
  return (
    <Space>
      {folha?.situacao === OrdemStatus.PendentePagamento && (
        <Role roles={['financeiro', 'master']}>
          <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(PagarFolha(folha.id))}>
            <Button type="primary" loading={loadingFolha} size="small">
              Confirmar Pagamento
            </Button>
          </Popconfirm>
        </Role>
      )}
      {folha?.situacao === OrdemStatus.Rascunho && false && (
        <Role roles={['compras', 'master']}>
          <Popconfirm
            title="Está certo disso?"
            onConfirm={() => dispatch(SubmeterFolha({ id: folha.id, estouro: false }, false))}
          >
            <Button
              type="primary"
              disabled={
                folha.tipo === 1
                  ? folha.ordens
                      .map(
                        (c) =>
                          c.ordensItens.map((t) => Number(t.valorTotal)).reduce((a, b) => a + b, 0) -
                          Number(c.adiantamento) -
                          c.valorFalta * c.faltas
                      )
                      .some((x) => x < 0)
                  : false
              }
              loading={loadingFolha}
              size="small"
            >
              Submeter folha
            </Button>
          </Popconfirm>
        </Role>
      )}
      <Link to={`/PrintFolha/${folha?.id}`} target="_blank">
        <Button size="small" icon={<PrinterOutlined />}>
          Imprimir
        </Button>
      </Link>
    </Space>
  );
}

export default MenuFolha;
