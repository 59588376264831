import { AppDispatch } from '@config/reduxStore';
import { apiEndpoints } from '@constants/apiEndpoints';
import { Categoria } from '@models/Categoria';
import { Axios } from '@config/axios';

export const GetCategorias = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_CATEGORIAS',
      payload: Axios.get(apiEndpoints.CATEGORIAS.GET_CATEGORIAS),
    });
  };
};
export const GetCategoriasOC = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_CATEGORIASOC',
      payload: Axios.get(apiEndpoints.CATEGORIAS.GET_CATEGORIAS_OC),
    });
  };
};
export const SalvarCategoria = function (values: Categoria) {
  return function (dispatch: AppDispatch) {
    if (values.id) {
      dispatch({
        type: 'UPDATE_CATEGORIA',
        payload: Axios.post(apiEndpoints.CATEGORIAS.SALVAR_CATEGORIA, { ...values }),
      });
    } else {
      dispatch({
        type: 'ADD_CATEGORIA',
        payload: Axios.post(apiEndpoints.CATEGORIAS.SALVAR_CATEGORIA, { ...values }),
      });
    }
  };
};
export const DeleteCategoria = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DELETE_CATEGORIA',
      payload: Axios.get(apiEndpoints.CATEGORIAS.DELETE_CATEGORIA, { params: { id } }),
    });
  };
};
export const FormCategoria = function (id: number, open: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'FORM_CATEGORIA',
      payload: { id, open },
    });
  };
};
