import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { SetPageHeader } from '@actions/userActions';
import OrdemPrint2 from './OrdemPrint2';
import { Button, Spin,  Row, Col, Form } from 'antd';
import { moneyNegMask } from '@utils/masks';
import Recibo from './Recibo';
import _ from 'lodash';
import PagamentoParcialModal from '../Shared/PagamentoParcialModal';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { isValidType } from '@utils/testType';
import { Categoria } from '@models/Categoria';
import { Orcamento } from '@models/Orcamento';
import OrdemActions from '../Shared/OrdemActions';
import { GetOrdemEdit } from '@services/api/api.ordem';
import OrdemResumoFinalPagamentos from '../Shared/OrdemPagamentos';
import OrdemResumoFinalHistorico from '../Shared/OrdemHistorico';
import { PrinterOutlined } from '@ant-design/icons';
import { useQueryClient } from 'react-query';
import OrdemResumoFinalArquivos from '../Shared/OrdemArquivos';
import OrdemNotasFiscais from '../Shared/OrdemNotasFiscais';

function OrdemPrint() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [formNF] = Form.useForm();
  const { data: ordem, isLoading: loadingOrdem } = GetOrdemEdit(id);
  const { user } = useAppSelector((state) => state.user);
  const [categorias, setCategorias] = useState([]);
  const [parcial, setParcial] = useState(false);
  const queryClient = useQueryClient();
  function onlyUnique(value: any, index: number, self: any[]) {
    return self.indexOf(value) === index;
  }
  useEffect(() => {
    if (ordem !== null && ordem?.ordensItens) {
      var orcamentos = _.groupBy(ordem.ordensItens, 'orcamento.descricao');
      formNF.resetFields();
      setCategorias(
        Object.entries(orcamentos).map((o) => ({
          orcamento: o[0],
          categorias: [...new Set(o[1].map((c) => isValidType<Categoria>(c.categoria) && c.categoria?.descricao))].map(
            (c) => ({
              categoria: c,
              total: moneyNegMask.format(
                ordem.ordensItens
                  ?.filter((d) => isValidType<Categoria>(d.categoria) && d.categoria?.descricao === c)
                  .filter((d) => isValidType<Orcamento>(d.orcamento) && d.orcamento.descricao === o[0])
                  .reduce((a, b) => a + (b.valorTotal as number), 0) +
                  (ordem.ajusteNF
                    ? ordem.valorAjusteNF /
                      ordem.ordensItens
                        ?.map((c) => isValidType<Categoria>(c.categoria) && c.categoria?.descricao)
                        .filter(onlyUnique).length
                    : 0) -
                  (ordem.ordensItens
                    ?.filter((d) => isValidType<Categoria>(d.categoria) && d.categoria?.descricao === c)
                    .filter((d) => isValidType<Orcamento>(d.orcamento) && d.orcamento.descricao === o[0])
                    .reduce((a, b) => a + (b.valorTotal as number), 0) *
                    Number(ordem.desconto)) /
                    ordem.subtotal
              ),
            })
          ),
        }))
      );
    }
  }, [ordem]);
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Engenharia',
        backbutton: true,
        extras: [
          <Link to={`/PrintOrdemPDF/${id}/${user.conta.id}`} target="_blank">
            <Button size="small" icon={<PrinterOutlined />}>
              Imprimir
            </Button>
          </Link>,
        ],
        subtitle: 'Imprimir Ordem',
        menu: 'ordens',
      })
    );
  }, [id, user.conta.id]);
  useEffect(() => {
    queryClient.invalidateQueries(['ordemEdit', String(id)]);
  }, []);
  return (
    <Spin spinning={loadingOrdem}>
      <div style={{ margin: 8, display: 'flex', justifyContent: 'right' }}>
        <OrdemActions />
      </div>
      <PagamentoParcialModal visible={parcial} setVisible={setParcial} />
      <Recibo />
      {ordem && categorias && <OrdemPrint2 ordem={ordem} categorias={categorias} customLogo={user.configuracao.logo} />}
      <Row gutter={[8, 8]}>
        <Col flex="1 1 200px">
          <OrdemNotasFiscais/>
        </Col>
        <Col flex="1 1 300px">
          <OrdemResumoFinalArquivos />
        </Col>
        <Col flex="1 1 300px">
          <OrdemResumoFinalHistorico />
        </Col>
        {ordem?.pagamentosOrdem && (
          <Col flex="1 1 300px">
            <OrdemResumoFinalPagamentos />
          </Col>
        )}
      </Row>
    </Spin>
  );
}

export default OrdemPrint;
