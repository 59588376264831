import { useRef, useEffect } from 'react';
import { Button } from 'antd';
import ReactToPrint from 'react-to-print';
import RelatorioInvestimento2 from './RelatorioInvestimento2';
import { useParams } from 'react-router-dom';
import { GetRelatorio } from '@actions/investidorActions';
import { SetPageHeader } from '@actions/userActions';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

function RelatorioMain() {
  const dispatch = useAppDispatch();
  const investidor = useAppSelector((state) => state.investidor);
  const relatorioprint = useRef();
  const { id } = useParams();
  const { user } = useAppSelector((state) => state.user);
  useEffect(() => {
    dispatch(GetRelatorio(id));
    dispatch(
      SetPageHeader({
        title: 'Portal do Investidor',
        backbutton: true,
        extras: [<span key="1">{moment(new Date()).format('DD/MM/YYYY')}</span>],
        subtitle: 'Relatório Operacional/Financeiro',
        menu: 'reltorioinvestidor',
      })
    );
  }, [id]);

  return (
    <div>
      <ReactToPrint
        trigger={() => (
          <Button size="small" type="primary" ghost>
            Imprimir
          </Button>
        )}
        content={() => relatorioprint.current}
      />
      <RelatorioInvestimento2 ref={relatorioprint} investidor={investidor} customLogo={user.configuracao.logo} />
    </div>
  );
}

export default RelatorioMain;
