import { Axios } from '@config/axios';
import { AppDispatch } from '@config/reduxStore';
import { apiEndpoints } from '@constants/apiEndpoints';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';

export const GetFolhasList = createAsyncThunk('folhas/GetFolhasList', async (fornecedorid?: number | null) =>
  Axios.get(apiEndpoints.FOLHAS.GET_FOLHAS, { params: { fornecedorid: fornecedorid } })
);
export const CancelarFolha = createAsyncThunk(
  'folhas/Cancelar',
  async ({ id, motivo }: { id: number; motivo: string }, apiThunk) =>
    Axios.get(apiEndpoints.FOLHAS.CANCELAR_FOLHA, { params: { id, motivo } })
      .then((res) => {
        apiThunk.dispatch(ViewCancelForm({ view: false, id: null }));
        apiThunk.fulfillWithValue(res);
      })
      .catch((res) => apiThunk.rejectWithValue(res))
);
export const ViewCancelForm = createAction(
  'folhas/ViewCancelForm',
  ({ view, id }: { view: boolean; id: number | null }) => ({ payload: { view: view, id: id } })
);
export const GetDashboardFolhas = createAsyncThunk(
  'folhas/GetDashBoard',
  async ({ mes, modo }: { mes: moment.Moment; modo: 'Adiantamento' | 'Medição' }, apiThunk) =>
    Axios.get(apiEndpoints.FOLHAS.DASHBOARD, { params: { mes: mes.format('YYYY-MM') } }).then((res) =>
      apiThunk.fulfillWithValue({ data: res.data, modo: modo })
    )
);
export const GetFolha = createAsyncThunk('folhas/GetFolhas', async (id: number) =>
  Axios.get(apiEndpoints.FOLHAS.GET_FOLHA, { params: { id: id } })
);
export const GetFolhasPendentes = createAsyncThunk('folhas/GetFolhasPendentes', async (_, apiThunk) =>
  Axios.get(apiEndpoints.FOLHAS.GET_FOLHAS_PENDENTES).catch((res) => apiThunk.rejectWithValue(res))
);
export const AprovarFolha = createAsyncThunk(
  'folhas/Aprovar',
  ({ id, justificativa }: { id: number; justificativa?: string }) =>
    Axios.get(apiEndpoints.FOLHAS.APROVAR_FOLHA, { params: { id, justificativa } })
);

export const SalvarFolhaNova = function (data: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SALVAR_FOLHA_NOVA',
      payload: Axios.post(apiEndpoints.FOLHAS.SALVAR_FOLHA_NOVA, data),
    });
  };
};

export const GetFolhas = function (fornecedorid: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_FOLHAS',
      payload: Axios.get(apiEndpoints.FOLHAS.GET_FOLHAS, { params: { fornecedorid: fornecedorid } }),
    });
  };
};

export const SubmeterFolha: (values: { id: number | string; estouro: boolean }, novo: boolean) => void = function (
  values,
  novo: boolean
) {
  return function (dispatch: AppDispatch) {
    if (novo) {
      dispatch({
        type: 'SUBMETER_FOLHA_PENDING',
        payload: '',
      });
      Axios.get(apiEndpoints.FOLHAS.SUBMETER_FOLHA, { params: { id: values } })
        .then(() => {
          dispatch({
            type: 'SUBMETER_FOLHA_FULFILLED',
            payload: '',
          });
          setTimeout(function () {
            window.location.href = '/Folhas';
          }, 3000);
        })
        .catch(() => {
          dispatch({
            type: 'SUBMETER_FOLHA_REJECTED',
            payload: {
              response: {
                data: '',
              },
            },
          });
        });
    } else {
      if (values.estouro)
        return notification.error({ message: 'A folha não pode ser submetida com valores estourados.' });
      dispatch({
        type: 'SALVAR_FOLHA_PENDING',
      });
      Axios.post(apiEndpoints.FOLHAS.SALVAR_FOLHA, { ...values })
        .then(() => {
          dispatch({
            type: 'SUBMETER_FOLHA',
            payload: Axios.get(apiEndpoints.FOLHAS.SUBMETER_FOLHA, { params: { id: values.id } }),
          });
        })
        .catch((res) => {
          dispatch({
            type: 'SALVAR_FOLHA_REJECTED',
            payload: res,
          });
        });
    }
    return null;
  };
};

export const SalvarFolha = function (values: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SALVAR_FOLHA_PENDING',
    });
    Axios.post(apiEndpoints.FOLHAS.SALVAR_FOLHA, { ...values })
      .then((res) => {
        dispatch({
          type: 'SALVAR_FOLHA_FULFILLED',
          payload: res,
        });
        window.location.href = `/NovaFolha/${res.data.id}`;
      })
      .catch((res) => {
        dispatch({
          type: 'SALVAR_FOLHA_REJECTED',
          payload: res,
        });
      });
  };
};

export const PagarFolha = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'PAGAR_FOLHA',
      payload: Axios.get(apiEndpoints.FOLHAS.PAGAR_FOLHA, { params: { id } }),
    });
  };
};

export const UploadFilesFolha = function (form: FormData) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'UPLOAD_FILES_FOLHA',
      payload: Axios.post(apiEndpoints.ORDENS.POST_FILE, form),
    });
  };
};
export const DeleteArquivoFolha = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DELETE_FILE_FOLHA',
      payload: Axios.post(apiEndpoints.ORDENS.DELETE_ARQUIVO, { arquivoid: id }),
    });
  };
};
export const CompilarArquivosFolha = function (id: number, arquivos: number[]) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'COMPILAR_ARQUIVOS_FOLHA',
      payload: Axios.post(apiEndpoints.ORDENS.COMPILAR, {
        ordemid: id,
        arquivosIds: JSON.stringify(arquivos),
      }),
    });
  };
};
export const CheckSaldo = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'CHECK_SALDO',
      payload: Axios.get(apiEndpoints.ORDENS.CHECK_SALDO, { params: { orcamentoItemID: id } }),
    });
  };
};
export const DownloadArquivo = function (fileid: number, filename: string) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DOWNLOAD_FILE_ORDEM',
      payload: Axios.get(apiEndpoints.ORDENS.DOWNLOAD_FILE, { params: { fileid }, responseType: 'blob' }).then(
        (res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        }
      ),
    });
  };
};
export const SetSelectedArquivos = function (values: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SELECTED_ARQUIVOS',
      payload: values,
    });
  };
};
export const ClearFolha = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'CLEAR_FOLHA',
    });
  };
};
