import { Col, DatePicker, Form, Row, Select } from 'antd';

function Pagamento() {
  return (
    <>
      <Row gutter={[8, 8]}>
        <Col span={7}>
          <Form.Item
            rules={[{ required: true, message: 'Campo obrigatório' }]}
            label="Prazo de Pagamento"
            name="prazoPagamento"
          >
            <DatePicker format="DD/MM/YYYY" />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            rules={[{ required: true, message: 'Campo obrigatório' }]}
            label="Prazo de Entrega"
            name="prazoEntrega"
          >
            <DatePicker format="DD/MM/YYYY" />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            name="formaPagamento"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
            label="Forma de Pagamento"
          >
            <Select showSearch optionFilterProp="children" placeholder="Escolha a forma de pagamento...">
              <Select.Option value="cartao">Cartão</Select.Option>
              <Select.Option value="boleto">Boleto</Select.Option>
              <Select.Option value="boleto30">Boleto 30 Dias</Select.Option>
              <Select.Option value="boleto3060">Boleto 30/60 Dias</Select.Option>
              <Select.Option value="boleto90">Boleto 90 Dias</Select.Option>
              <Select.Option value="transferencia">Transferência</Select.Option>
              <Select.Option value="especie">Espécie</Select.Option>
              <Select.Option value="naopagar">Não Pagar</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}

export default Pagamento;
