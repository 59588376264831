import { Form, DatePicker, Input, Select, Typography, Radio, Button, Col, Row, Divider } from 'antd';
import { MinusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { AddChamado } from '@actions/casasActions';
import { mobMask } from '@utils/masks';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { FormInstance } from 'antd/es/form/Form';
import { Chamado } from '@models/Chamado';

function NovoChamadoView(props: { id: number; form: FormInstance<any> }) {
  const { id } = props;
  const dispatch = useAppDispatch();
  const responsaveis = useAppSelector((state) => state.chamados.responsaveis);
  const nome = useAppSelector((state) => state.user.user.nome);
  const form = props.form;
  const Submit = (values: Chamado) => {
    dispatch(AddChamado({ ...values, casaid: id }));
    form.resetFields();
  };
  return (
    <div>
      <Form
        form={form}
        name="chamadoForm"
        onFinish={Submit}
        wrapperCol={{ span: 18 }}
        labelCol={{ span: 6 }}
        initialValues={{
          atendente: nome,
          tipo: false,
          dataChamado: moment(new Date()),
          reclamacoes: [''],
        }}
      >
        <Form.Item name="atendente" label="Atendente" valuePropName="children">
          <Typography.Text />
        </Form.Item>
        <Form.Item name="dataChamado" label="Data" rules={[{ required: true, message: 'Campo obrigatório' }]}>
          <DatePicker format="DD/MM/YYYY" />
        </Form.Item>
        <Form.Item name="responsável" label="Responsável" rules={[{ required: true, message: 'Campo obrigatório' }]}>
          <Select showSearch optionFilterProp="children">
            {responsaveis.map((c) => (
              <Select.Option value={c.id} key={c.id}>
                {c.nome}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="contato" label="Contato" rules={[{ required: true, message: 'Campo obrigatório' }]}>
          <Input
            onChange={(e) =>
              form.setFieldsValue({
                contato: mobMask.format(mobMask.normalize(e.target.value)),
              })
            }
          />
        </Form.Item>
        <Form.Item name="tipo" label="Tipo">
          <Radio.Group>
            <Radio value={false}>SAC</Radio>
            <Radio value={true}>Entrega</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="notas" label="Notas">
          <Input.TextArea />
        </Form.Item>
        <Divider>Reclamações</Divider>
        <Form.List name="reclamacoes">
          {(fields, { add, remove }) => (
            <div>
              {fields.map((field, index) => (
                <Form.Item key={index} label={index + 1} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
                  <Row>
                    <Col span={20}>
                      <Form.Item {...field} rules={[{ required: true, message: 'Campo obrigatório' }]}>
                        <Input size="small" />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      {fields.length > 1 && (
                        <Button size="small" onClick={() => remove(index)} style={{ position: 'relative' }}>
                          <MinusOutlined />
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Form.Item>
              ))}
              <Form.Item wrapperCol={{ offset: 4 }}>
                <Button
                  size="small"
                  type="dashed"
                  onClick={() => {
                    add();
                  }}
                >
                  Adicionar Reclamação
                </Button>
              </Form.Item>
            </div>
          )}
        </Form.List>
      </Form>
    </div>
  );
}
export default NovoChamadoView;
