import { GetOrcamentosList } from '@services/api/api.orcamento';
import { SelectProps } from 'antd';
import { Select } from 'antd';

export interface SelectSolicitanteProps extends SelectProps {}

function OrcamentoSelect({ ...restProps }: SelectSolicitanteProps) {
  const { isLoading, data, isFetching } = GetOrcamentosList();
  return (
    <Select
      {...restProps}
      loading={isLoading || isFetching}
      optionFilterProp="children"
      allowClear
      showSearch
      disabled={isLoading}
    >
      {data?.map((c) => (
        <Select.Option key={c.id} value={c.id}>
          {c.descricao}
        </Select.Option>
      ))}
    </Select>
  );
}

export default OrcamentoSelect;
