import { Table } from 'antd';
import { useEffect, useState } from 'react';
import { doubleMask, doubleMask2, moneyMask } from '@utils/masks';
import '../../../../assets/scss/CurvaABC.scss';
import { OrcamentoItem } from '@models/OrcamentoItem';

function CurvaABCTableView({
  orcamentosItens,
  tipo,
  orcado,
}: {
  orcamentosItens: OrcamentoItem[];
  tipo: number;
  orcado: boolean;
}) {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (orcamentosItens) {
      const itens = [...new Set(orcamentosItens.filter((c) => c.itemTipo === tipo).map((c) => c.itemID))];
      let pesoAnterior = 0;
      setData(
        itens
          .map((i) => {
            const findItens = orcamentosItens.filter((oi) => oi.itemID === i);
            return {
              id: i,
              item: orcamentosItens.find((oi) => oi.itemID === i)?.item,
              unidade: orcamentosItens.find((oi) => oi.itemID === i)?.unidade,
              quantidade: orcado
                ? findItens.reduce((a, b) => a + b.quantidade, 0)
                : findItens.reduce((a, b) => a + b.realizadoQuantidade, 0),
              valorUnitarioMedio: orcado
                ? findItens.reduce((a, b) => a + b.valorTotal, 0) / findItens.reduce((a, b) => a + b.quantidade, 0)
                : findItens.reduce((a, b) => a + b.realizadoValor, 0) / findItens.reduce((a, b) => a + b.quantidade, 0),
              valorTotal: orcado
                ? findItens.reduce((a, b) => a + b.quantidade * b.valorUnitario, 0)
                : findItens.reduce((a, b) => a + b.realizadoValor, 0),
              peso: orcado
                ? findItens.reduce((a, b) => a + b.quantidade * b.valorUnitario, 0) /
                  orcamentosItens.filter((oi) => oi.itemTipo === tipo).reduce((a, b) => a + b.valorTotal, 0)
                : findItens.reduce((a, b) => a + b.realizadoValor, 0) /
                  orcamentosItens.filter((oi) => oi.itemTipo === tipo).reduce((a, b) => a + b.realizadoValor, 0),
            };
          })
          .sort((a, b) => b.valorTotal - a.valorTotal)
          .map((c) => {
            pesoAnterior = pesoAnterior = c.peso + pesoAnterior;
            return {
              ...c,
              pesoAcumulado: pesoAnterior,
              classificacao: pesoAnterior > 0.95 ? 'C' : pesoAnterior > 0.8 && pesoAnterior <= 0.95 ? 'B' : 'A',
            };
          })
      );
    }
  }, [orcamentosItens, orcado, tipo]);
  return (
    <div>
      <Table
        className="curvaABCTable"
        size="small"
        rowKey="id"
        dataSource={data}
        pagination={false}
        scroll={{ y: 'calc(100vh - 210px)' }}
        rowClassName={(row) =>
          row.pesoAcumulado > 0.95
            ? 'curvaC'
            : row.pesoAcumulado > 0.8 && row.pesoAcumulado <= 0.95
            ? 'curvaB'
            : 'curvaA'
        }
      >
        <Table.Column render={(_, __, index) => index + 1 + 'º'} width={50} align="center" />
        <Table.Column title="Item" dataIndex="item" width={350} />
        <Table.Column title="Unidade" dataIndex="unidade" align="center" />
        <Table.Column title="Quantidade" dataIndex="quantidade" align="right" render={(v) => doubleMask.format(v)} />
        <Table.Column
          title="V. Unit. Médio"
          dataIndex="valorUnitarioMedio"
          align="right"
          render={(v) => moneyMask.format(v)}
        />
        <Table.Column title="V. Total" dataIndex="valorTotal" align="right" render={(v) => moneyMask.format(v)} />
        <Table.Column
          title="Peso Individual"
          dataIndex="peso"
          align="right"
          render={(v) => doubleMask2.format(v * 100)}
        />
        <Table.Column
          title="Peso Acumulado"
          dataIndex="pesoAcumulado"
          align="right"
          render={(v) => doubleMask2.format(v * 100)}
        />
        <Table.Column title="Classificação" dataIndex="classificacao" align="center" />
      </Table>
    </div>
  );
}

export default CurvaABCTableView;
