import { apiEndpoints } from '@constants/apiEndpoints';
import { AddColaboradorFolhaModel, AddItemFolhaModel, IFolhaForm, RemoveItemFolhaModel } from '@models/Folha';
import { CancelarOrdemModel, FinalizarOrdemModel, SubmeterOrdemModel } from '@models/OrdemItem';
import { notification } from 'antd';
import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { fetcher, poster } from './api';

export const GetFolha = (id: string, contaID?: string) => {
  return useQuery<IFolhaForm, AxiosError<any>>(
    ['folhaEdit', String(id)],
    () =>
      fetcher<IFolhaForm>(apiEndpoints.FOLHAS.GET_FOLHA_MEDICAO, {
        params: {
          id: Number(id) || null,
          cod: id,
          contaID: contaID,
        },
      }),
    {
      enabled: !!id,
      retry: false,
      staleTime: 1000 * 60 * 1,
      onError: (err) => {
        notification.error({ message: err?.response?.data });
      },
    }
  );
};
export const SalvarFolhaStep1 = () => {
  const queryClient = useQueryClient();
  return useMutation<IFolhaForm, AxiosError<any>, Partial<IFolhaForm>>(
    'saveStep1Folha',
    (data) => poster<IFolhaForm>(apiEndpoints.FOLHAS.SALVAR_FOLHA_STEP1, data),
    {
      onSuccess: (res, variables) => {
        notification.success({ message: 'Ordem salva com sucesso!' });
        if (!variables.id) window.location.href = `/NovaFolhaMedicao/1/${res.id}`;
        else {
          queryClient.invalidateQueries(['folhaEdit', String(res.id)]);
          queryClient.invalidateQueries(['folhaEdit', String(res.cod)]);
        }
      },
      onError: (err) => notification.error({ message: err.response?.data?.Message }),
    }
  );
};
export const AddColaboradorFolha = () => {
  const queryClient = useQueryClient();
  return useMutation<void, AxiosError<any>, AddColaboradorFolhaModel>(
    'addColaboradorFolha',
    (data) => poster<void>(apiEndpoints.FOLHAS.ADD_COLABORADOR_FOLHA, data),
    {
      onSuccess: (_, variables) => {
        notification.success({ message: 'Colaborador adicionado com sucesso!' });
        queryClient.invalidateQueries(['folhaEdit', String(variables.folhaID)]);
        queryClient.invalidateQueries(['folhaEdit', String(variables.cod)]);
      },
      onError: (err) => notification.error({ message: err.response?.data?.Message }),
    }
  );
};
export const RemoveColaboradorFolha = () => {
  const queryClient = useQueryClient();
  return useMutation<void, AxiosError<any>, AddColaboradorFolhaModel>(
    'removeColaboradorFolha',
    (data) => poster<void>(apiEndpoints.FOLHAS.REMOVE_COLABORADOR_FOLHA, data),
    {
      onSuccess: (_, variables) => {
        notification.success({ message: 'Colaborador removido com sucesso!' });
        console.log(variables);
        queryClient.invalidateQueries(['folhaEdit', String(variables.folhaID)]);
        queryClient.invalidateQueries(['folhaEdit', String(variables.cod)]);
      },
      onError: (err) => notification.error({ message: err.response?.data?.Message }),
    }
  );
};
export const RemoveItemFolha = () => {
  const queryClient = useQueryClient();
  return useMutation<void, AxiosError<any>, RemoveItemFolhaModel>(
    'removeItemFolha',
    (data) => fetcher<void>(apiEndpoints.FOLHAS.REMOVE_ITEM_FOLHA, { params: data }),
    {
      onSuccess: (_, variables) => {
        notification.success({ message: 'Item removido com sucesso!' });
        queryClient.invalidateQueries(['folhaEdit', String(variables.folhaID)]);
        queryClient.invalidateQueries(['folhaEdit', String(variables.cod)]);
      },
      onError: (err) => notification.error({ message: err.response?.data?.Message }),
    }
  );
};
export const AdditemFolha = () => {
  const queryClient = useQueryClient();
  return useMutation<void, AxiosError<any>, AddItemFolhaModel>(
    'additemFolha',
    (data) => poster<void>(apiEndpoints.FOLHAS.ADD_ITEM_FOLHA, data),
    {
      onSuccess: (_, variables) => {
        notification.success({ message: 'Item adicionado com sucesso!' });
        console.log('teste');
        queryClient.invalidateQueries(['folhaEdit', String(variables.folhaID)]);
        queryClient.invalidateQueries(['folhaEdit', String(variables.cod)]);
      },
      onError: (err) => notification.error({ message: err.response?.data?.Message }),
    }
  );
};
export const SalvarFolhaItens = () => {
  const queryClient = useQueryClient();
  return useMutation<number, AxiosError<any>, Partial<IFolhaForm>>(
    'additemFolha',
    (data) => poster<number>(apiEndpoints.FOLHAS.SALVAR_FOLHA_ITENS, data),
    {
      onSuccess: (_, variables) => {
        notification.success({ message: 'Rateio salvo com sucesso!' });
        queryClient.invalidateQueries(['folhaEdit', String(variables.id)]);
        queryClient.invalidateQueries(['folhaEdit', String(variables.cod)]);
      },
      onError: (err) => notification.error({ message: err.response?.data?.Message }),
    }
  );
};
export const SubmeterFolha = () => {
  const queryClient = useQueryClient();
  return useMutation<IFolhaForm, AxiosError<any>, SubmeterOrdemModel>(
    (data) => fetcher<IFolhaForm>(apiEndpoints.FOLHAS.SUBMETER_FOLHA, { params: data }),
    {
      onSuccess: (_, variables) => {
        notification.success({ message: 'Folha submetida com sucesso!' });
        queryClient.invalidateQueries(['folhaEdit', String(variables.id)]);
        queryClient.invalidateQueries(['folhaEdit', String(variables.cod)]);
      },
      onError: (err) => notification.error({ message: err.response?.data?.Message }),
    }
  );
};
export const AprovarFolha = () => {
  const queryClient = useQueryClient();
  return useMutation<number, AxiosError<any>, SubmeterOrdemModel>(
    (data) => fetcher<number>(apiEndpoints.FOLHAS.APROVAR_FOLHA, { params: data }),
    {
      onSuccess: (_, variables) => {
        notification.success({ message: 'Folha aprovada com sucesso!' });
        queryClient.invalidateQueries(['folhaEdit', String(variables.id)]);
        queryClient.invalidateQueries(['folhaEdit', String(variables.cod)]);
      },
      onError: (err) => notification.error({ message: err.response?.data?.Message }),
    }
  );
};
export const FinalizarFolha = () => {
  const queryClient = useQueryClient();
  return useMutation<IFolhaForm, AxiosError<any>, FinalizarOrdemModel>(
    (data) => fetcher<IFolhaForm>(apiEndpoints.FOLHAS.PAGAR_FOLHA, { params: data }),
    {
      onSuccess: (res) => {
        notification.success({ message: 'Folha finalizada com sucesso!' });
        queryClient.invalidateQueries(['folhaEdit', String(res.id)]);
        queryClient.invalidateQueries(['folhaEdit', String(res.cod)]);
      },
      onError: (err) => {
        notification.error({ message: err.response?.data?.Message });
      },
    }
  );
};
export const CancelarFolha = () => {
  const queryClient = useQueryClient();
  return useMutation<IFolhaForm, AxiosError<any>, CancelarOrdemModel>(
    (data) => fetcher<IFolhaForm>(apiEndpoints.FOLHAS.CANCELAR_FOLHA, { params: data }),
    {
      onSuccess: (_, variables) => {
        notification.success({ message: 'Folha cancelada com sucesso!' });
        queryClient.invalidateQueries(['folhaEdit', String(variables.id)]);
        queryClient.invalidateQueries(['folhaEdit', String(variables.cod)]);
      },
      onError: (err) => {
        notification.error({ message: err.response?.data?.Message });
      },
    }
  );
};
