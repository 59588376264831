import { Axios } from '@config/axios';
import { AxiosRequestConfig } from 'axios';

export const fetcher = async <T>(url: string, options?: AxiosRequestConfig) => {
  const res = await Axios.get<T>(url, options);
  return res.data;
};

export const poster = async <T>(url: string, data: object | FormData) => {
  const res = await Axios.post<T>(url, data);
  return res.data;
};
export const put = async <T>(url: string, data?: object | FormData, config?: AxiosRequestConfig) => {
  const res = await Axios.put<T>(url, data, config);
  return res.data;
};
