import { Button, Form, Input, InputNumber, Modal, Select, Typography } from 'antd';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { SalvarItem } from '@actions/orcamentosActions';
import { moneyMask } from '@utils/masks';
import { PlusOutlined } from '@ant-design/icons';
import { FormCategoria } from '@actions/categoriasActions';
import { useParams } from 'react-router-dom';
import GrupoForm from './GrupoForm';
import { OrcamentoItem } from '@models/OrcamentoItem';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

function OrcamentoItemForm({
  visible,
  setVisible,
  item,
}: {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  item: OrcamentoItem;
}) {
  const [itemform] = Form.useForm();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { grupos, loadinggrupos } = useAppSelector((state) => state.grupos);
  const { categorias, loadingcategorias } = useAppSelector((state) => state.categorias);
  const { addingitem } = useAppSelector((state) => state.orcamentos);
  const [grupoForm, setGrupoForm] = useState(false);
  useEffect(() => {
    itemform.setFieldsValue({
      ...item,
      valorUnitario: moneyMask.format(item?.valorUnitario),
      valorTotal: moneyMask.format(item?.valorTotal),
    });
  }, [item, itemform]);
  return (
    <div>
      <GrupoForm visible={grupoForm} setVisible={setGrupoForm} item={itemform.getFieldsValue()} />

      <Modal
        title={`Atualizar Item - ${item?.item}`}
        open={visible}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="1" onClick={() => setVisible(false)}>
            Fechar
          </Button>,
          <Button key="2" loading={addingitem} onClick={() => itemform.submit()} type="primary">
            Salvar
          </Button>,
        ]}
      >
        <Form
          form={itemform}
          name="itemform"
          wrapperCol={{ span: 18 }}
          labelCol={{ span: 6 }}
          onFinish={(values) =>
            dispatch(
              SalvarItem({
                ...values,
                valorUnitario: moneyMask.normalize(values.valorUnitario),
                valorTotal: moneyMask.normalize(values.valorTotal),
                orcamentoID: id,
              })
            )
          }
        >
          <Form.Item name="id" style={{ display: 'none' }}>
            <Input />
          </Form.Item>
          <Form.Item name="itemID" style={{ display: 'none' }}>
            <Input />
          </Form.Item>
          <Form.Item name="item" style={{ display: 'none' }}>
            <Input />
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                label="Categoria"
                name="categoriaID"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input.Group>
                  <Select
                    showSearch
                    onChange={(v) => setFieldsValue({ categoriaID: v })}
                    style={{ width: '100%' }}
                    optionFilterProp="children"
                    loading={loadingcategorias}
                    value={getFieldValue('categoriaID')}
                    suffixIcon={
                      <Button
                        size="small"
                        icon={<PlusOutlined />}
                        onClick={() => dispatch(FormCategoria(null, true))}
                      />
                    }
                  >
                    {categorias
                      //.filter((c) => c.tipo === getFieldValue('tipo'))
                      .map((c) => (
                        <Select.Option key={c.id} value={c.id}>
                          {c.descricao}
                        </Select.Option>
                      ))}
                  </Select>
                </Input.Group>
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item label="Grupo" name="grupoID" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                <Input.Group>
                  <Select
                    showSearch
                    onChange={(v) => setFieldsValue({ grupoID: v })}
                    style={{ width: '100%' }}
                    optionFilterProp="children"
                    loading={loadinggrupos}
                    value={getFieldValue('grupoID')}
                    suffixIcon={
                      <Button
                        size="small"
                        icon={<PlusOutlined />}
                        onClick={() => {
                          setGrupoForm(true);
                        }}
                      />
                    }
                  >
                    {grupos
                      .filter((c) => c.categoriaID == getFieldValue('categoriaID') && c.orcamentoID === Number(id))
                      .map((c) => (
                        <Select.Option key={c.id} value={c.id}>
                          {c.descricao}
                        </Select.Option>
                      ))}
                  </Select>
                </Input.Group>
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item name="quantidade" label="Quantidade" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <InputNumber
              min={0}
              decimalSeparator=","
              onChange={(v) =>
                itemform.setFieldsValue({
                  valorTotal: moneyMask.format(v * moneyMask.normalize(itemform.getFieldValue('valorUnitario'))),
                })
              }
            />
          </Form.Item>
          <Form.Item
            name="valorUnitario"
            label="Valor Unitário"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
            normalize={(v) => moneyMask.format(moneyMask.normalize(v))}
          >
            <Input
              onChange={(e) =>
                itemform.setFieldsValue({
                  valorTotal: moneyMask.format(
                    itemform.getFieldValue('quantidade') * moneyMask.normalize(e.target.value)
                  ),
                })
              }
            />
          </Form.Item>
          <Form.Item
            name="valorTotal"
            label="Valor Total"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
            valuePropName="children"
            normalize={(v) => v}
          >
            <Typography.Text />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default OrcamentoItemForm;
