import { Button, Col, Descriptions, Divider, Form, Input, InputNumber, Modal, Row, Select, Typography } from 'antd';
import { useEffect } from 'react';
import { AddComposicao, SalvarComposicao, SetComposicaoForm } from '@actions/composicoesActions';
import { FormMaterial, GetMateriais } from '@actions/materiaisActions';
import { moneyMask, moneyMaskPrecision } from '@utils/masks';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import MaterialForm from '../Materiais/MaterialForm';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Composicao } from '@models/Composicao';
import { Item } from '@models/Item';
import { ItemComposicao } from '@models/ItemComposicao';

function ComposicaoForm() {
  const dispatch = useAppDispatch();
  const { composicao, composicaoform, savingcomposicao } = useAppSelector((state) => state.composicoes);
  const { materiais, loadingmateriais } = useAppSelector((state) => state.materiais);
  const [form] = Form.useForm<Composicao>();
  useEffect(() => {
    dispatch(GetMateriais());
  }, []);
  useEffect(() => {
    if (!savingcomposicao && !composicao) form.resetFields();
  }, [savingcomposicao, composicao]);
  useEffect(() => {
    if (composicao)
      form.setFieldsValue({
        ...form.getFieldsValue(),
        ...composicao,
        itensComposicao: composicao.itensComposicao.map((c) => ({
          ...c,
          valorUnitario: moneyMask.format(c.valorUnitario),
          valorTotal: moneyMaskPrecision.format((c.valorUnitario as number) * c.quantidade),
        })),
      });
    else form.resetFields();
  }, [composicao]);
  const onChangeItem = (material: Item, key: any) => {
    const fields = form.getFieldsValue();
    const { itensComposicao } = fields;
    Object.assign(itensComposicao[key], {
      valorUnitario: moneyMask.format(material.valorUnitario),
      tipo: material.tipo,
      valorTotal: moneyMaskPrecision.format((material.valorUnitario as number) * itensComposicao[key].quantidade),
    });
    form.setFieldsValue({ itensComposicao });
  };
  const onChangeQuantidade = (value: any, key: any) => {
    const fields = form.getFieldsValue();
    const { itensComposicao } = fields;
    Object.assign(itensComposicao[key], {
      valorTotal: moneyMaskPrecision.format(value * moneyMask.normalize(itensComposicao[key].valorUnitario as string)),
    });
    form.setFieldsValue({ itensComposicao });
  };
  const submitForm = (values: Composicao) => {
    let tempValues = {
      ...values,
      valorTotal: values.itensComposicao.reduce(
        (a, b) => a + b.quantidade * moneyMask.normalize(b.valorUnitario as string),
        0
      ),
      valorServicos: values.itensComposicao
        .filter((c) => c.tipo === 1)
        .reduce((a, b) => a + b.quantidade * moneyMask.normalize(b.valorUnitario as string), 0),
      valorInsumos: values.itensComposicao
        .filter((c) => c.tipo === 0)
        .reduce((a, b) => a + b.quantidade * moneyMask.normalize(b.valorUnitario as string), 0),
      itensComposicao: values.itensComposicao.map((c) => ({
        ...c,
        valorUnitario: moneyMask.normalize(c.valorUnitario as string),
        valorTotal: moneyMaskPrecision.normalize(c.valorTotal as string),
      })),
    };
    if (!values.id) dispatch(AddComposicao(tempValues));
    else dispatch(SalvarComposicao(tempValues));
  };
  return (
    <div>
      <MaterialForm />
      <Modal
        width="xl"
        open={composicaoform}
        onCancel={() => dispatch(SetComposicaoForm(false, null))}
        title={(composicao && composicao.descricao) || (!composicao && 'Nova Composição')}
        okButtonProps={{ loading: savingcomposicao, onClick: () => form.submit() }}
        cancelText="Fechar"
        okText="Salvar"
      >
        <Form
          form={form}
          onFinish={(values) => submitForm(values)}
          layout="vertical"
          scrollToFirstError
          initialValues={{
            itensComposicao: [
              {
                itemID: null,
                tipo: null,
                quantidade: 0,
                valorUnitario: moneyMask.format(0),
                valorTotal: moneyMaskPrecision.format(0),
              },
            ],
          }}
        >
          <Row gutter={[8, 8]}>
            <Col flex="1 1 400px">
              <Form.Item hidden name="id">
                <Input />
              </Form.Item>
              <Form.Item name="descricao" label="Descrição" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                <Input />
              </Form.Item>
              <Form.Item name="unidade" label="Unidade" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                <Select>
                  <Select.Option value="MT">MT</Select.Option>
                  <Select.Option value="M²">M²</Select.Option>
                  <Select.Option value="M³">M³</Select.Option>
                  <Select.Option value="SC">SC</Select.Option>
                  <Select.Option value="PC">PC</Select.Option>
                  <Select.Option value="KG">KG</Select.Option>
                  <Select.Option value="UND">UND</Select.Option>
                  <Select.Option value="Casa">Casa</Select.Option>
                  <Select.Option value="VB">VB</Select.Option>
                  <Select.Option value="CAR">CAR</Select.Option>
                  <Select.Option value="L">L</Select.Option>
                  <Select.Option value="TON">TON</Select.Option>
                  <Select.Option value="H">H</Select.Option>
                  <Select.Option value="Dia">Dia</Select.Option>
                  <Select.Option value="Mês">Mês</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col flex="1 1 400px">
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue, getFieldsValue }) => (
                  <Descriptions bordered>
                    <Descriptions.Item label="Total Serviços">
                      {moneyMaskPrecision.format(
                        getFieldsValue()
                          ?.itensComposicao?.filter((c: ItemComposicao) => c.tipo === 1)
                          .reduce(
                            (a: number, b: ItemComposicao) => a + moneyMaskPrecision.normalize(b.valorTotal as string),
                            0
                          )
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label="Total Insumos">
                      {moneyMaskPrecision.format(
                        getFieldValue('itensComposicao')
                          .filter((c: ItemComposicao) => c.tipo === 0)
                          .reduce(
                            (a: number, b: ItemComposicao) => a + moneyMaskPrecision.normalize(b.valorTotal as string),
                            0
                          )
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label="Total Unitário">
                      {moneyMaskPrecision.format(
                        getFieldValue('itensComposicao').reduce(
                          (a: number, b: ItemComposicao) => a + moneyMaskPrecision.normalize(b.valorTotal as string),
                          0
                        )
                      )}
                    </Descriptions.Item>
                  </Descriptions>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Divider>Itens da Composição</Divider>
          <Button size="small" icon={<PlusOutlined />} onClick={() => dispatch(FormMaterial(null, true))}>
            Adicionar Item
          </Button>
          <Form.List name="itensComposicao">
            {(fields, { add, remove }) => {
              return (
                <div>
                  <div style={{ maxHeight: 400, overflowY: 'scroll' }}>
                    {fields.map((field) => (
                      <div key={field.key}>
                        <Row gutter={[8, 8]}>
                          <Col flex="1 1 50px">
                            <Form.Item hidden name={[field.name, 'id']}>
                              <Input />
                            </Form.Item>
                            <Form.Item label="Excluir">
                              <Button
                                icon={<DeleteOutlined />}
                                onClick={() => remove(field.name)}
                                size="small"
                                disabled={fields.length === 1}
                              />
                            </Form.Item>
                          </Col>
                          <Col flex="1 1 500px">
                            <Form.Item shouldUpdate noStyle>
                              {() => (
                                <Form.Item
                                  name={[field.name, 'itemID']}
                                  label="Item"
                                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                                >
                                  <Select
                                    loading={loadingmateriais}
                                    optionFilterProp="children"
                                    showSearch
                                    onChange={(id) =>
                                      onChangeItem(
                                        materiais.find((c) => c.id === id),
                                        field.name
                                      )
                                    }
                                  >
                                    {materiais.map((m) => (
                                      <Select.Option
                                        key={m.id}
                                        value={m.id}
                                        style={{ color: m.tipo === 1 ? 'green' : null }}
                                      >{`${m.unidade} - ${m.descricao}`}</Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              )}
                            </Form.Item>
                          </Col>
                          <Col flex="1 1 100px">
                            <Form.Item shouldUpdate noStyle>
                              {({ getFieldValue }) => (
                                <Form.Item name={[field.name, 'tipo']} label="Tipo">
                                  <Typography.Text>
                                    {getFieldValue('itensComposicao')[field.name].tipo === 0 ? 'Insumo' : 'Serviço'}
                                  </Typography.Text>
                                </Form.Item>
                              )}
                            </Form.Item>
                          </Col>
                          <Col flex="1 1 100px">
                            <Form.Item
                              name={[field.name, 'valorUnitario']}
                              label="Valor Unitário"
                              valuePropName="children"
                            >
                              <Typography.Text />
                            </Form.Item>
                          </Col>
                          <Col flex="1 1 100px">
                            <Form.Item shouldUpdate noStyle>
                              {({ getFieldValue }) => (
                                <Form.Item
                                  name={[field.name, 'quantidade']}
                                  label="Quantidade"
                                  rules={[
                                    {
                                      validator: async (_, valor) => {
                                        if (valor === 0) {
                                          return Promise.reject(new Error('Valor zero'));
                                        }
                                        return null;
                                      },
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    decimalSeparator=","
                                    precision={4}
                                    min={0}
                                    disabled={!getFieldValue('itensComposicao')[field.name].itemID}
                                    onChange={(v) => onChangeQuantidade(v, field.name)}
                                  />
                                </Form.Item>
                              )}
                            </Form.Item>
                          </Col>
                          <Col flex="1 1 100px">
                            <Form.Item shouldUpdate noStyle>
                              {({ getFieldValue }) => (
                                <Form.Item
                                  name={[field.name, 'valorTotal']}
                                  label="Valor Total"
                                  valuePropName="children"
                                >
                                  <Typography.Text>
                                    {getFieldValue('itensComposicao')[field.name].valorUnitario *
                                      getFieldValue('itensComposicao')[field.name].quantidade}
                                  </Typography.Text>
                                </Form.Item>
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                      </div>
                    ))}
                  </div>
                  <Button
                    onClick={() =>
                      add({
                        itemID: null,
                        tipo: null,
                        valorUnitario: moneyMaskPrecision.format(0),
                        quantidade: 0,
                        valorTotal: moneyMaskPrecision.format(0),
                      })
                    }
                    icon={<PlusOutlined />}
                  >
                    Novo Item
                  </Button>
                </div>
              );
            }}
          </Form.List>
        </Form>
      </Modal>
    </div>
  );
}
export default ComposicaoForm;
