import { useEffect, useState } from 'react';
import { Table, Input, Button, Popconfirm, Descriptions, Checkbox } from 'antd';
import {
  GetFornecedores,
  FormFornecedor,
  DeleteFornecedor,
  ModalArquivoFornecedor,
} from '@actions/fornecedoresActions';
import FornecedorForm from './FornecedorForm';
import { EditOutlined, DeleteOutlined, FolderOutlined } from '@ant-design/icons';
import { getColumnSearchProps, sortAlpha, filterobject } from '@utils/filterandsort';
import { SetPageHeader } from '@actions/userActions';
import { Permission } from '@hooks//usePermissions';
import FornecedorArquivos from './FornecedorArquivos';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Fornecedor } from '@models/Fornecedor';
import { ColumnsType } from 'antd/lib/table';

function FornecedoresView() {
  const dispatch = useAppDispatch();
  const fornecedores = useAppSelector((state) => state.fornecedores);
  const [filter, setFilter] = useState('');
  const [filters, setFilters] = useState(null);
  const [inativos, setInativos] = useState(false);
  useEffect(() => {
    dispatch(GetFornecedores());
    dispatch(
      SetPageHeader({
        title: 'Engenharia',
        backbutton: true,
        extras: [
          <Permission key="1" permission="fornecedor.add">
            <Button onClick={() => dispatch(FormFornecedor(null, true))} type="primary">
              Novo Fornecedor
            </Button>
          </Permission>,
        ],
        subtitle: 'Fornecedores',
        menu: 'fornecedores',
      })
    );
  }, []);
  const columns: ColumnsType<Fornecedor> = [
    {
      width: 90,
      fixed: 'left',
      render: (_, row: Fornecedor) => (
        <div>
          <Button icon={<EditOutlined />} onClick={() => dispatch(FormFornecedor(row.id, true))} size="small" />
          <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(DeleteFornecedor(row.id))}>
            <Button icon={<DeleteOutlined />} size="small" />
          </Popconfirm>
          <Button
            icon={<FolderOutlined />}
            onClick={() => dispatch(ModalArquivoFornecedor(row.id, true))}
            size="small"
          />
        </div>
      ),
    },
    {
      title: 'Nome',
      dataIndex: 'nome',
      width: 150,
      sorter: (a, b) => sortAlpha(a.nome, b.nome),
      ...getColumnSearchProps('nome', 'string', 'Nome', filters),
    },
    {
      title: 'Endereço',
      dataIndex: 'endereco',
      sorter: (a, b) => sortAlpha(a.endereco, b.endereco),
      ...getColumnSearchProps('endereco', 'string', 'Endereço', filters),
    },
    {
      title: 'Telefone',
      dataIndex: 'telefone',
      sorter: (a, b) => sortAlpha(a.telefone, b.telefone),
      ...getColumnSearchProps('telefone', 'string', 'Telefone', filters),
    },
    {
      title: 'Função',
      dataIndex: 'funcao',
      sorter: (a: any, b: any) => a.funcao - b.funcao,
      render: (text) => (text ? text : ''),
      ...getColumnSearchProps('funcao', 'customselect', 'Função', filters, [
        { text: 'Ajudante', value: 'Ajudante' },
        { text: 'Mestre de Obras', value: 'Mestre de Obras' },
        { text: 'Pedreiro', value: 'Pedreiro' },
        { text: 'Servente', value: 'Servente' },
        { text: 'Pintor', value: 'Pintor' },
        { text: 'Eletricista', value: 'Eletricista' },
        { text: 'Instalações', value: 'Instalações' },
        { text: 'Carpinteiro', value: 'Carpinteiro' },
        { text: 'Gesseiro', value: 'Gesseiro' },
        { text: 'Revestimentos', value: 'Revestimentos' },
        { text: 'Armador', value: 'Armador' },
        { text: 'Encanador', value: 'Encanador' },
        { text: 'Piscineiro', value: 'Piscineiro' },
        { text: 'Encarregado', value: 'Encarregado' },
        { text: 'Outro', value: 'Outro' },
      ]),
    },
    {
      title: 'Dados Bancários',
      children: [
        {
          title: 'Titular',
          dataIndex: 'nomeConta',
          sorter: (a, b) => sortAlpha(a.nomeConta, b.nomeConta),
          ...getColumnSearchProps('nomeConta', 'string', 'Nome da Conta', filters),
        },
        {
          title: 'Pix',
          dataIndex: 'pix',
          sorter: (a, b) => sortAlpha(a.pix, b.pix),
          ...getColumnSearchProps('pix', 'string', 'Pix', filters),
        },
        {
          title: 'Banco',
          dataIndex: 'banco',
          sorter: (a, b) => sortAlpha(a.banco, b.banco),
          ...getColumnSearchProps('banco', 'string', 'Banco', filters),
        },
        {
          title: 'Agência',
          dataIndex: 'agencia',
          sorter: (a: Fornecedor, b: Fornecedor) => sortAlpha(a.agencia, b.agencia),
          ...getColumnSearchProps('agencia', 'string', 'Agência', filters),
        },
        {
          title: 'Operação',
          dataIndex: 'operacao',
          sorter: (a: Fornecedor, b: Fornecedor) => sortAlpha(a.operacao, b.operacao),
          ...getColumnSearchProps('operacao', 'string', 'Operação', filters),
        },
        {
          title: 'Conta',
          dataIndex: 'conta',
          sorter: (a: Fornecedor, b: Fornecedor) => sortAlpha(a.conta, b.conta),
          ...getColumnSearchProps('conta', 'string', 'Conta', filters),
        },
        {
          title: 'CPF/CNPJ',
          dataIndex: 'cpF_CNPJ_Conta',
          sorter: (a: Fornecedor, b: Fornecedor) => sortAlpha(a.cpF_CNPJ_Conta, b.cpF_CNPJ_Conta),
          ...getColumnSearchProps('cpF_CNPJ_Conta', 'string', 'CPF/CNPJ', filters),
        },
      ],
    },
  ];
  return (
    <div>
      <FornecedorForm />
      <FornecedorArquivos />
      <div style={{ textAlign: 'right' }}>
        <Checkbox checked={inativos} onChange={(e) => setInativos(e.target.checked)}>
          Inativos
        </Checkbox>
        <Input style={{ width: 200 }} onChange={(e) => setFilter(e.target.value)} placeholder="Buscar" allowClear />
      </div>
      <Table
        size="small"
        rowKey="id"
        onChange={(paginantion, filters, sorter) => setFilters({ paginantion, filters, sorter })}
        loading={fornecedores.loadingfornecedores}
        columns={columns}
        bordered
        dataSource={filterobject(
          fornecedores.fornecedores.filter((c) => (inativos ? c.ativo === false : c.ativo === true)),
          filter
        )}
        scroll={{ y: 'calc(100vh - 300px)', x: 1400 }}
        pagination={{
          position: ['bottomRight'],
          size: 'small',
          showSizeChanger: true,
          pageSizeOptions: [10, 25, 50, 100],
          showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
        }}
        expandedRowRender={(row) => (
          <Descriptions size="small" bordered>
            <Descriptions.Item label="Nome">{row.nome}</Descriptions.Item>
            <Descriptions.Item label="Endereço">{row.endereco}</Descriptions.Item>
            <Descriptions.Item label="Cidade">{row.cidade}</Descriptions.Item>
            <Descriptions.Item label="Estado">{row.estado}</Descriptions.Item>
            <Descriptions.Item label="CEP">{row.cep}</Descriptions.Item>
            <Descriptions.Item label="CPF/CNPJ">{row.cpF_CNPJ}</Descriptions.Item>
            <Descriptions.Item label="Telefone">{row.telefone}</Descriptions.Item>
            <Descriptions.Item label="Email">{row.email}</Descriptions.Item>
            <Descriptions.Item label="Representante">{row.representante}</Descriptions.Item>
            <Descriptions.Item label="Banco">{row.banco}</Descriptions.Item>
            <Descriptions.Item label="Agencia">{row.agencia}</Descriptions.Item>
            <Descriptions.Item label="Conta">{row.contaBancaria}</Descriptions.Item>
            <Descriptions.Item label="Operação">{row.operacao}</Descriptions.Item>
            <Descriptions.Item label="CPF/CNPJ Conta">{row.cpF_CNPJ_Conta}</Descriptions.Item>
            <Descriptions.Item label="Titular Conta">{row.nomeConta}</Descriptions.Item>
            <Descriptions.Item label="Pix">{row.pix}</Descriptions.Item>
          </Descriptions>
        )}
      />
    </div>
  );
}
export default FornecedoresView;
