import { GetContratadoMesAtual } from '@services/api/api.correspondencia';
import { Card, Spin, Statistic } from 'antd';

interface Props {
  mes: moment.Moment;
}
function DashboardContratadoMesAtual({ mes }: Props) {
  const { data, isLoading } = GetContratadoMesAtual(mes);

  return (
    <Card>
      <Spin spinning={isLoading}>
        <Statistic
          title="Contratado no mês"
          value={data}
          precision={0}
          decimalSeparator=","
          groupSeparator="."
          style={{ width: 500, height: 62 }}
          valueStyle={{ color: 'green' }}
          prefix="R$"
        />
      </Spin>
    </Card>
  );
}

export default DashboardContratadoMesAtual;
