import { DatePicker, Space, Table } from 'antd';
import { useState } from 'react';
import { useEffect } from 'react';
import { getFluxoObras } from '@actions/bancoActions';
import { SetPageHeader } from '@actions/userActions';
import moment from 'moment';
import { moneyMask, moneyNegMask } from '@utils/masks';
import { naturalSorter } from '@utils/filterandsort';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { FluxoObra } from '@models/Fisico';

function FluxoObras() {
  const dispatch = useAppDispatch();
  const { fluxoObras, loadingFluxoObras } = useAppSelector((state) => state.banco);
  const [inicial, setInicial] = useState(moment().startOf('month'));
  const [final, setFinal] = useState(moment().add(7, 'month').endOf('month'));
  const [meses, setMeses] = useState(new Array(6).fill(0));

  useEffect(() => {
    dispatch(getFluxoObras({ inicial: inicial.format('YYYY-MM-DD'), final: final.format('YYYY-MM-DD') }));
    dispatch(
      SetPageHeader({
        title: 'Fluxo Físico Financeiro',
        backbutton: true,
        extras: [],
        subtitle: 'Fluxo financeiro das obras',
        menu: 'medicoes',
      })
    );
  }, []);
  useEffect(() => {
    // const min = moment.min(fluxoObras.map((c) => c.medicoes));
  }, [fluxoObras]);
  useEffect(() => {
    setMeses(new Array(final.diff(inicial, 'month') + 1).fill(0));
  }, [inicial, final]);
  return (
    <div>
      <Space>
        <DatePicker.MonthPicker
          placeholder="Mês inicial"
          format="MM/YYYY"
          value={inicial}
          onChange={(v) => {
            setInicial(v.startOf('month'));
            dispatch(
              getFluxoObras({ inicial: v.startOf('month').format('YYYY-MM-DD'), final: final.format('YYYY-MM-DD') })
            );
          }}
          disabledDate={(current) => current && current > final}
        />
        <DatePicker.MonthPicker
          placeholder="Mês final"
          format="MM/YYYY"
          value={final}
          onChange={(v) => {
            setFinal(v.endOf('month'));
            dispatch(
              getFluxoObras({ inicial: inicial.format('YYYY-MM-DD'), final: v.endOf('month').format('YYYY-MM-DD') })
            );
          }}
          disabledDate={(current) => current && current < inicial}
        />
      </Space>
      <Table
        size="small"
        dataSource={[...fluxoObras].sort((a, b) => naturalSorter(a.descricao, b.descricao))}
        loading={loadingFluxoObras}
        pagination={false}
        scroll={{ y: 'calc(100vh - 270px)', x: 1000 }}
        rowKey="id"
        summary={(data) => (
          <Table.Summary fixed>
            <Table.Summary.Row style={{ fontWeight: 'bold' }}>
              <Table.Summary.Cell index={0} colSpan={2} align="right">
                TOTAIS
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} align="right">
                <div>
                  <div>RECEITAS</div>
                  <div>DESPESAS</div>
                  <div>RESULTADO</div>
                </div>
              </Table.Summary.Cell>
              {meses.map((_, index) => {
                const mes = moment(inicial).add(index, 'month');
                return (
                  <Table.Summary.Cell index={2} key={index} align="right">
                    <div>
                      <div style={{ color: 'blue' }}>
                        {moneyMask.format(
                          data.reduce(
                            (a, b) =>
                              a +
                              b.receitas
                                .filter(
                                  (c) =>
                                    moment(`${c.year}-${c.month}`, 'YYYY-MM').format('MM/YYYY') ===
                                    mes.format('MM/YYYY')
                                )
                                .reduce((c, d) => c + d.valor, 0),
                            0
                          )
                        )}
                      </div>
                      <div style={{ color: 'orange' }}>
                        {moneyMask.format(
                          data.reduce(
                            (a, b) =>
                              a +
                              b.despesas
                                .filter(
                                  (c) =>
                                    moment(`${c.year}-${c.month}`, 'YYYY-MM').format('MM/YYYY') ===
                                    mes.format('MM/YYYY')
                                )
                                .reduce((c, d) => c + d.valor, 0),
                            0
                          )
                        )}
                      </div>
                      <div
                        style={{
                          color:
                            data.reduce(
                              (a, b) =>
                                a +
                                b.receitas
                                  .filter(
                                    (c) =>
                                      moment(`${c.year}-${c.month}`, 'YYYY-MM').format('MM/YYYY') ===
                                      mes.format('MM/YYYY')
                                  )
                                  .reduce((c, d) => c + d.valor, 0),
                              0
                            ) -
                              data.reduce(
                                (a, b) =>
                                  a +
                                  b.despesas
                                    .filter(
                                      (c) =>
                                        moment(`${c.year}-${c.month}`, 'YYYY-MM').format('MM/YYYY') ===
                                        mes.format('MM/YYYY')
                                    )
                                    .reduce((c, d) => c + d.valor, 0),
                                0
                              ) >=
                            0
                              ? 'green'
                              : 'red',
                        }}
                      >
                        {moneyNegMask.format(
                          data.reduce(
                            (a, b) =>
                              a +
                              b.receitas
                                .filter(
                                  (c) =>
                                    moment(`${c.year}-${c.month}`, 'YYYY-MM').format('MM/YYYY') ===
                                    mes.format('MM/YYYY')
                                )
                                .reduce((c, d) => c + d.valor, 0),
                            0
                          ) -
                            data.reduce(
                              (a, b) =>
                                a +
                                b.despesas
                                  .filter(
                                    (c) =>
                                      moment(`${c.year}-${c.month}`, 'YYYY-MM').format('MM/YYYY') ===
                                      mes.format('MM/YYYY')
                                  )
                                  .reduce((c, d) => c + d.valor, 0),
                              0
                            )
                        )}
                      </div>
                    </div>
                  </Table.Summary.Cell>
                );
              })}
            </Table.Summary.Row>
          </Table.Summary>
        )}
      >
        <Table.Column render={(_, __, index) => index + 1} align="center" width={50} />
        <Table.Column
          title="Obras"
          dataIndex="descricao"
          width={250}
          ellipsis
          sorter={(a, b: FluxoObra) => naturalSorter(a.descricao, b.descricao)}
        />
        <Table.Column
          align="right"
          width={120}
          render={() => (
            <div>
              <div>RECEITAS</div>
              <div>DESPESAS</div>
              <div>RESULTADO</div>
            </div>
          )}
        />
        {meses.map((_, index) => (
          <Table.Column
            key={index}
            align="right"
            width={120}
            title={moment(inicial).add(index, 'month').format('MM/YYYY')}
            render={(_, row: FluxoObra) => {
              const mes = moment(inicial).add(index, 'month');
              return (
                <div>
                  <div style={{ color: 'blue' }}>
                    {moneyMask.format(
                      row.receitas
                        .filter(
                          (c) => moment(`${c.year}-${c.month}`, 'YYYY-MM').format('MM/YYYY') === mes.format('MM/YYYY')
                        )
                        .reduce((a, b) => a + b.valor, 0)
                    )}
                  </div>
                  <div style={{ color: 'orange' }}>
                    {moneyMask.format(
                      row.despesas
                        .filter((c) => {
                          return moment(`${c.year}-${c.month}`, 'YYYY-MM').format('MM/YYYY') === mes.format('MM/YYYY');
                        })
                        .reduce((a, b) => a + b.valor, 0)
                    )}
                  </div>
                  <div
                    style={{
                      color:
                        row.receitas
                          .filter(
                            (c) => moment(`${c.year}-${c.month}`, 'YYYY-MM').format('MM/YYYY') === mes.format('MM/YYYY')
                          )
                          .reduce((a, b) => a + b.valor, 0) -
                          row.despesas
                            .filter(
                              (c) =>
                                moment(`${c.year}-${c.month}`, 'YYYY-MM').format('MM/YYYY') === mes.format('MM/YYYY')
                            )
                            .reduce((a, b) => a + b.valor, 0) >=
                        0
                          ? 'green'
                          : 'red',
                    }}
                  >
                    {moneyNegMask.format(
                      row.receitas
                        .filter(
                          (c) => moment(`${c.year}-${c.month}`, 'YYYY-MM').format('MM/YYYY') === mes.format('MM/YYYY')
                        )
                        .reduce((a, b) => a + b.valor, 0) -
                        row.despesas
                          .filter(
                            (c) => moment(`${c.year}-${c.month}`, 'YYYY-MM').format('MM/YYYY') === mes.format('MM/YYYY')
                          )
                          .reduce((a, b) => a + b.valor, 0)
                    )}
                  </div>
                </div>
              );
            }}
          />
        ))}
      </Table>
    </div>
  );
}

export default FluxoObras;
