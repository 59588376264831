import { ExtendedAction } from '@config/reduxStore';
import { AreaDashboard, ClienteObra, CorretorDashboard, Media, PrimeiraParcela } from '@models/Dashboard';

interface DashboardReducer {
  medias: { medias: Media[]; loadingmedias: boolean };
  primeiraparcela: PrimeiraParcela;
  corretores: { corretores: CorretorDashboard[]; loadingcorretores: boolean };
  areas: {
    areas: AreaDashboard[];
    loadingareas: boolean;
  };
  clientesobra: {
    clientesobra: ClienteObra[];
    loadingclientesobra: boolean;
  };
}
var initialState: DashboardReducer = {
  medias: {
    medias: [],
    loadingmedias: false,
  },
  primeiraparcela: {
    hoje: [],
    amanha: [],
    depoisdeamanha: [],
    loadingparcelas: false,
  },
  corretores: {
    corretores: [],
    loadingcorretores: false,
  },
  areas: {
    loadingareas: false,
    areas: [],
  },
  clientesobra: {
    loadingclientesobra: false,
    clientesobra: [],
  },
};

const dashboarReducer = function (state = initialState, action: ExtendedAction) {
  switch (action.type) {
    case 'GET_CLIENTESOBRA_PENDING':
      state = {
        ...state,
        clientesobra: {
          ...state.clientesobra,
          loadingclientesobra: true,
        },
      };
      break;
    case 'GET_CLIENTESOBRA_REJECTED':
      state = {
        ...state,
        clientesobra: {
          ...state.clientesobra,
          loadingclientesobra: false,
        },
      };
      break;
    case 'GET_CLIENTESOBRA_FULFILLED':
      state = {
        ...state,
        clientesobra: {
          ...state.clientesobra,
          loadingclientesobra: false,
          clientesobra: action.payload.data,
        },
      };
      break;
    case 'GET_AREA_PENDING':
      state = {
        ...state,
        areas: {
          ...state.areas,
          loadingareas: true,
        },
      };
      break;
    case 'GET_AREA_REJECTED':
      state = {
        ...state,
        areas: {
          ...state.areas,
          loadingareas: false,
        },
      };
      break;
    case 'GET_AREA_FULFILLED':
      state = {
        ...state,
        areas: {
          ...state.areas,
          loadingareas: false,
          areas: action.payload.data,
        },
      };
      break;
    case 'GET_PRIMEIRA_PARCELA_PENDING':
      state = {
        ...state,
        primeiraparcela: {
          ...state.primeiraparcela,
          loadingparcelas: true,
        },
      };
      break;
    case 'GET_PRIMEIRA_PARCELA_REJECTED':
      state = {
        ...state,
        primeiraparcela: {
          ...state.primeiraparcela,
          loadingparcelas: false,
        },
      };
      break;
    case 'GET_PRIMEIRA_PARCELA_FULFILLED':
      state = {
        ...state,
        primeiraparcela: {
          ...state.primeiraparcela,
          loadingparcelas: false,
          hoje: action.payload.data.hoje,
          amanha: action.payload.data.amanha,
          depoisdeamanha: action.payload.data.depoisdeamanha,
        },
      };
      break;
    case 'GET_CORRETORES_PENDING':
      state = {
        ...state,
        corretores: {
          ...state.corretores,
          loadingcorretores: true,
        },
      };
      break;
    case 'GET_CORRETORES_REJECTED':
      state = {
        ...state,
        corretores: {
          ...state.corretores,
          loadingcorretores: false,
        },
      };
      break;
    case 'GET_CORRETORES_FULFILLED':
      state = {
        ...state,
        corretores: {
          ...state.corretores,
          loadingcorretores: false,
          corretores: action.payload.data,
        },
      };
      break;
    case 'GET_MEDIA_PENDING':
      state = {
        ...state,
        medias: {
          ...state.medias,
          loadingmedias: true,
        },
      };
      break;
    case 'GET_MEDIA_REJECTED':
      state = {
        ...state,
        medias: {
          ...state.medias,
          loadingmedias: false,
        },
      };
      break;
    case 'GET_MEDIA_FULFILLED':
      state = {
        ...state,
        medias: {
          ...state.medias,
          loadingmedias: false,
          medias: action.payload.data,
        },
      };
      break;
    default:
      return state;
  }
  return state;
};

export default dashboarReducer;
