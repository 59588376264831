import { AppDispatch } from '@config/reduxStore';
import { apiEndpoints } from '@constants/apiEndpoints';
import { Casa } from '@models/Casa';
import { Chamado } from '@models/Chamado';
import { Axios } from '@config/axios';

export const GetCasas = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_CASAS',
      payload: Axios.get(apiEndpoints.CASAS.GET_CASAS),
    });
  };
};
export const SalvarCasa = function (values: Casa) {
  return function (dispatch: AppDispatch) {
    if (values.id) {
      dispatch({
        type: 'UPDATE_CASA',
        payload: Axios.post(apiEndpoints.CASAS.SALVAR_CASA, { ...values }),
      });
    } else {
      dispatch({
        type: 'ADD_CASA',
        payload: Axios.post(apiEndpoints.CASAS.ADD_CASA, { ...values }),
      });
    }
  };
};
export const GetAditivos = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_ADIVITOS',
      payload: Axios.get(apiEndpoints.CASAS.GET_ADITIVOS, { params: { id } }),
    });
  };
};
export const DeleteCasa = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DELETE_CASA',
      payload: Axios.get(apiEndpoints.CASAS.DELETE_CASA, { params: { id } }),
    });
  };
};
export const AddTarefas = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'ADD_TAREFAS',
      payload: Axios.post(apiEndpoints.CASAS.ADD_TAREFAS, { CasaID: id }),
    });
  };
};
export const FormCasa = function (id: number, open: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'FORM_CASA',
      payload: { id, open },
    });
  };
};

export const GetCasa = function (id: number | string) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_CASA',
      payload: Axios.get(apiEndpoints.CASAS.GET_CASA, { params: { id } }),
    });
  };
};
export const UpdateValues = function (values: Casa) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'UPDATE_VALUES_CASA',
      payload: values,
    });
  };
};
export const UploadFiles = function (form: FormData) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'UPLOAD_FILES_CASA',
      payload: Axios.post(apiEndpoints.CASAS.POST_FILE, form),
    });
  };
};

export const DownloadArquivo = function (fileid: number) {
  return function (dispatch: AppDispatch) {
    dispatch({ type: 'DOWLOADING_FILE_CASA', payload: fileid });
    dispatch({
      type: 'DOWNLOAD_FILE_CASA',
      payload: Axios.get(apiEndpoints.CASAS.DOWNLOAD, {
        params: { fileid },
        responseType: 'stream',
      }).then((res) => {
        const link = document.createElement('a');
        link.href = res.data;
        link.target = '_top';
        document.body.appendChild(link);
        link.click();
      }),
    });
  };
};
export const DeleteArquivo = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DELETE_FILE_CASA',
      payload: Axios.post(apiEndpoints.CASAS.DELETE_ARQUIVO, { arquivoid: id }),
    });
  };
};
export const DisponibilizarArquivoCliente = function (id: number, disponivel: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DISPONIBILIZAR_ARQUIVO_CLIENTE',
      payload: Axios.post(apiEndpoints.CASAS.DISPONIBILIZAR_ARQUIVO_CLIENTE, { arquivoid: id, disponivel: disponivel }),
    });
  };
};
export const AddChamado = function (values: Chamado) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'ADD_CHAMADO',
      payload: Axios.post(apiEndpoints.CASAS.ADD_CHAMADO, { ...values }),
    });
  };
};
export const AddClienteObra = function (values: {
  id: number;
  proprietarionome1: string;
  proprietarioEmail: string;
  proprietarioCPF1?: string;
}) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'ADD_CLIENTE_OBRA',
      payload: Axios.post(apiEndpoints.CASAS.ADD_CLIENTE_OBRA, values),
    });
  };
};
export const RemoveClienteObra = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'REMOVE_CLIENTE_OBRA',
      payload: Axios.get(apiEndpoints.CASAS.REMOVE_CLIENTE_OBRA, { params: { id } }),
    });
  };
};
export const ShowProgressoObra = function (id: number, open: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SHOW_PROGRESSO_OBRA',
      payload: { id, open },
    });
  };
};
export const ShowProgressoLegalizacao = function (id: number, open: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SHOW_PROGRESSO_LEGALIZACAO',
      payload: { id, open },
    });
  };
};
export const ShowClienteObra = function (id: number, open: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SHOW_CLIENTE_OBRA',
      payload: { id, open },
    });
  };
};
export const FormGaleria = function (id: number, open: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'FORM_GALERIA',
      payload: { id, open },
    });
  };
};
export const CarregarProcessos = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'CARREGAR_PROCESSOS',
      payload: Axios.get(apiEndpoints.CASAS.CARREGAR_PROCESSOS, { params: { id } }),
    });
  };
};
