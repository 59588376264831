import { SetPageHeader } from '@actions/userActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { moneyMask } from '@utils/masks';
import { Button, Card, Col, Form, Input, Row, Spin, Steps } from 'antd';
import { useEffect, useState } from 'react';
import General from './OrdemForm.General';
import Itens from './OrdemForm.Itens';
import Navigation from './OrdemForm.Navigation';
import Pagamento from './OrdemForm.Pagamento';
import OrdemResumo from './OrdemForm.Resumo';
import { StepOrdemFormProps, useOrdemForm } from './useOrdemForm';
import { PlusOutlined, PrinterOutlined } from '@ant-design/icons';
import OrdemAddItemModal from './OrdemForm.AddItemModal';
import { IOrdemForm } from '@models/Ordem';
import OrdemResumoFinal from './ResumoFinal.tsx/OrdemForm.ResumoFinal';
import OrdemActions from '../Shared/OrdemActions';
import { Link, useParams } from 'react-router-dom';
import CancelOrdemModal from '../Shared/OrdemForm.CancelarModal';
import LoadingText from '@components/LoadingText';
import { OrdemStatus } from '@constants/ordemStatus';

const steps: StepOrdemFormProps[] = [
  {
    key: 0,
    title: 'Geral',
    content: <General />,
  },
  { key: 1, title: 'Pagamento', content: <Pagamento /> },
  { key: 2, title: 'Itens', content: <Itens /> },
  { key: 4, title: 'Resumo', content: <OrdemResumoFinal /> },
];
function OrdemForm() {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const { step, form, next, numSteps, goTo, save, isLoading, isFetching, ordem } = useOrdemForm();
  const [novo, setNovo] = useState<boolean>(false);
  const { id } = useParams();
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Engenharia',
        backbutton: true,
        extras: [
          <Link to={`/PrintOrdemPDF/${ordem?.id}/${user.conta.id}`} target="_blank">
            <Button size="small" hidden={step !== numSteps - 1} icon={<PrinterOutlined />}>
              Imprimir
            </Button>
          </Link>,
        ],
        subtitle: 'Nova ordem de compra',
        menu: 'ordens',
      })
    );
  }, [step, numSteps, ordem, user.conta.id]);
  const handleSubmit = (values: Partial<IOrdemForm>) => {
    if (step < numSteps - 1) {
      save(values);
      next();
    } else {
      save(values);
    }
  };
  if (isLoading) return <LoadingText text="Carregando" />;
  return (
    <Spin spinning={isLoading || isFetching}>
      <CancelOrdemModal />
      <OrdemAddItemModal visible={novo} setVisible={setNovo} />
      <Steps
        style={{ marginBottom: 10 }}
        current={step}
        items={steps.map((c) => ({
          title: c.title,
          key: c.title,
          disabled: !(ordem?.situacao === OrdemStatus.Rascunho || ordem?.situacao === OrdemStatus.PendenteAprovacao),
        }))}
        type="navigation"
        onChange={(v) => {
          if ((step + 1 === v || v < step) && !!id) {
            if (v > step) {
              if (step === 0 || step === 1) {
                form.submit();
              } else goTo(v);
            } else goTo(v);
          }
        }}
      />
      <Form
        name="ordemGeral"
        form={form}
        onFinish={handleSubmit}
        preserve
        layout="vertical"
        initialValues={{ ordensItens: [], desconto: moneyMask.format(0), subtotal: 0, total: 0 }}
      >
        <Row gutter={[10, 10]}>
          <Col flex="1 0 1000px">
            <Card
              size="small"
              title={steps[step].title}
              actions={[<Navigation />]}
              extra={
                step === 2 ? (
                  <Button size="small" type="primary" icon={<PlusOutlined />} onClick={() => setNovo(true)}>
                    Item
                  </Button>
                ) : step === numSteps - 1 ? (
                  <OrdemActions />
                ) : null
              }
            >
              <Form.Item name="id" hidden>
                <Input />
              </Form.Item>
              <Form.Item name="cod" hidden>
                <Input />
              </Form.Item>
              <Form.Item name="situacao" hidden>
                <Input />
              </Form.Item>
              <div style={{ minHeight: 400, maxHeight: 'calc(100vh - 310px)', overflowY: 'scroll' }}>
                {steps[step].content}
              </div>
            </Card>
          </Col>
          {step >= 2 && (
            <Col flex="1 0 250px">
              <Form.Item noStyle shouldUpdate>
                {({ getFieldsValue }) => <OrdemResumo ordem={getFieldsValue()} />}
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>
    </Spin>
  );
}

export default OrdemForm;
