import { Space, Typography, Form, Input, Select, Button, message, Spin, Row, Col } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Axios } from '@config/axios';
import { mobMask } from '@utils/masks';

function Suporte() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  return (
    <Space direction="vertical">
      <Typography.Title level={2}>Suporte</Typography.Title>
      <Typography.Title level={3}>1. Como criar uma conta</Typography.Title>
      <Typography.Text>
        Para ter acesso ao sistema você precisa ser um colaborador, investidor ou cliente da empresa que utiliza o
        sistema
      </Typography.Text>
      <Typography.Text>
        Se você se enquadra em uma dessas categorias e ainda não possui acesso, entre em contato com o administrador da
        empresa para que seja feita a liberação do seu acesso
      </Typography.Text>
      <Typography.Text>
        Após a liberação, você receberá via email os dados para efetuar login (usuário e senha)
      </Typography.Text>
      <Typography.Text>
        Caso não tenha recebido o email com os dados, verifique sua caixa de span ou entre em contato novamente com a
        administração para averiguar o problema
      </Typography.Text>
      <Typography.Text>
        Você pode também clicar em <Link to="/Forgot">Esqueci Minha Senha</Link> na página de login e digitar o email
        informado no seu cadastro. Você receberá um email com infromações para redefinir sua senha
      </Typography.Text>
      <Typography.Title level={3}>2. Tenho uma empresa do setor e gostaria de utilizar o sistema</Typography.Title>
      <Typography.Text>
        O Elevar Gestão está em fase de aprimoramento e atualmente utilizado pela empresa ELEVAR CONSTRUÇÕES E
        INCORPORAÇÕES. Em breve o sistema será lançado para uso geral e todos poderão se beneficiar dessa poderosa
        ferramenta
      </Typography.Text>
      <Typography.Title level={3}>3. Meu usuário foi desativado. Não consigo logar</Typography.Title>
      <Typography.Text>
        Seu usuário pode ter sido inativado caso você não seja mais um colaborador da empresa ou um cliente que passou
        por um processo de distrato contratual
      </Typography.Text>
      <Typography.Text>
        Se você acha que seu usuário foi desativado equivocadamente, entre em contato com o administrador e explique o
        problema, ou mande uma mensagem para o nosso suporte preenchendo o formulário abaixo
      </Typography.Text>
      <Typography.Title level={3}>4. Suporte interno para uso do sistema</Typography.Title>
      <Typography.Text>
        Caso tenha dúvidas sobre o uso/configuração do sistema você pode entrar em contato com o nosso suporte via email
        preenchendo o formulário a baixo. Ficaremos felizes em respondê-lo
      </Typography.Text>
      <Typography.Title level={3}>5. Continua com dúvida? Mande uma mensagem suporte</Typography.Title>
      <Spin spinning={loading}>
        <Row justify="center">
          <Col flex="0 1 400px">
            <Form
              form={form}
              layout="vertical"
              onFinish={(values) => {
                setLoading(true);
                Axios.post('/api/v2/Usuarios/EnviarEmail', values)
                  .then(() => {
                    message.success('Email enviado com sucesso! Aguarde que em breve entraremos em contato.');
                    form.resetFields();
                    setLoading(false);
                  })
                  .catch((res) => {
                    message.error(res.response.data);
                    setLoading(false);
                  });
              }}
            >
              <Form.Item label="Nome completo" name="nome" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="Telefone"
                name="telefone"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
                normalize={(v) => mobMask.format(mobMask.normalize(v))}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: 'Campo obrigatório' },
                  { type: 'email', message: 'Formado de email incorreto' },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item label="Tipo de usuário" name="tipo" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                <Select>
                  <Select.Option value="Colaborador">Colaborador</Select.Option>
                  <Select.Option value="Cliente">Cliente</Select.Option>
                  <Select.Option value="Investidor">Investidor</Select.Option>
                  <Select.Option value="Outro">Outro</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Motivo do contato"
                name="motivo"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Select>
                  <Select.Option value="Cadastro de conta">Cadastro de conta</Select.Option>
                  <Select.Option value="Relato de bug">Relato de bug</Select.Option>
                  <Select.Option value="Dúvida sobre uso do sistema">Dúvida sobre usos do sistema</Select.Option>
                  <Select.Option value="Outros">Outros</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item label="Descrição" name="descricao" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                <Input.TextArea rows={5} />
              </Form.Item>
              <Form.Item>
                <Button type="primary" onClick={() => form.submit()} style={{ width: '100%' }}>
                  Enviar
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Spin>
    </Space>
  );
}

export default Suporte;
