import { ExtendedAction } from '@config/reduxStore';
import { Empresalegalizacao } from '@models/EmpresaLegalizacao';
import { notification } from 'antd';

interface EmpresasLegalizacaoReducer {
  empresas: Empresalegalizacao[];
  formview: boolean;
  empresa: number;
  loadingempresas: boolean;
  savingempresa: boolean;
  feedback: string;
}
var initialState: EmpresasLegalizacaoReducer = {
  empresas: [],
  formview: false,
  empresa: null,
  loadingempresas: false,
  savingempresa: false,
  feedback: '',
};

const empresasReducer = function (state = initialState, action: ExtendedAction) {
  switch (action.type) {
    case 'RESET_FEEDBACK':
      state = {
        ...state,
        feedback: '',
      };
      break;
    case 'FORM_EMPRESA':
      state = {
        ...state,
        feedback: '',
        formview: action.payload.open,
        empresa: action.payload.id ? action.payload.id : null,
      };
      break;
    case 'GET_EMPRESAS_PENDING':
      state = {
        ...state,
        loadingempresas: true,
        empresas: initialState.empresas,
      };
      break;
    case 'GET_EMPRESAS_REJECTED':
      state = {
        ...state,
        loadingempresas: false,
      };
      break;
    case 'GET_EMPRESAS_FULFILLED':
      state = {
        ...state,
        loadingempresas: false,
        empresas: action.payload.data,
      };
      break;
    case 'ADD_EMPRESA_PENDING':
      state = {
        ...state,
        savingempresa: true,
        feedback: '',
      };
      break;
    case 'ADD_EMPRESA_REJECTED':
      state = {
        ...state,
        savingempresa: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: 'Erro ao cadastrar empresa!' });
      break;
    case 'ADD_EMPRESA_FULFILLED':
      state = {
        ...state,
        savingempresa: false,
        feedback: 'Empresa criado com sucesso!',
        empresas: [...state.empresas, action.payload.data],
      };
      notification.success({ message: 'Empresa cadastrada com sucesso!' });
      break;
    case 'UPDATE_EMPRESA_PENDING':
      state = {
        ...state,
        savingempresa: true,
        feedback: '',
      };
      break;
    case 'UPDATE_EMPRESA_REJECTED':
      state = {
        ...state,
        savingempresa: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: 'Erro ao atualizar empresa!' });
      break;
    case 'UPDATE_EMPRESA_FULFILLED':
      state = {
        ...state,
        savingempresa: false,
        feedback: 'Empresa atualizada com sucesso!',
        empresas: state.empresas.map((c) => {
          if (c.id === action.payload.data.id) return action.payload.data;
          else return c;
        }),
      };
      notification.success({ message: 'Empresa atualizado com sucesso!' });
      break;
    case 'DELETE_EMPRESA_PENDING':
      state = {
        ...state,
        loadingempresas: true,
        feedback: '',
      };
      break;
    case 'DELETE_EMPRESA_REJECTED':
      state = {
        ...state,
        loadingempresas: false,
        feedback: action.payload.response.data.Message,
      };
      notification.warning({ message: state.feedback });
      break;
    case 'DELETE_EMPRESA_FULFILLED':
      state = {
        ...state,
        loadingempresas: false,
        feedback: 'Empresa excluída com sucesso!',
        empresas: state.empresas.filter((c) => c.id !== action.payload.data),
      };
      notification.success({ message: state.feedback });
      break;
    default:
      return state;
  }
  return state;
};

export default empresasReducer;
