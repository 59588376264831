import { useEffect } from 'react';
import { Modal, Form, Input, Button, InputNumber } from 'antd';
import { SalvarProduto, FormProduto } from '@actions/produtosActions';
import { moneyMask } from '@utils/masks';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { ProdutoBancario } from '@models/ProdutoBancario';

function ProdutoForm() {
  const dispatch = useAppDispatch();
  const produtos = useAppSelector((state) => state.produtos);
  const [form] = Form.useForm<ProdutoBancario>();
  const submitForm = (values: ProdutoBancario) => {
    dispatch(SalvarProduto({ ...values, remuneracao: moneyMask.normalize(values.remuneracao as string) }));
  };
  useEffect(() => {
    form.resetFields();
    if (produtos.produto) {
      const produto = produtos.produtos.find((c) => c.id === produtos.produto);
      form.setFieldsValue({ ...produto, remuneracao: moneyMask.format(produto.remuneracao) });
    }
  }, [produtos.produto, produtos.produtos, form]);
  return (
    <div>
      <Modal
        open={produtos.formview}
        onCancel={() => dispatch(FormProduto(null, false))}
        title="Cadastro Produto"
        footer={[
          <Button onClick={() => dispatch(FormProduto(null, false))} key="2">
            Fechar
          </Button>,
          <Button key="3" type="primary" onClick={() => form.submit()} loading={produtos.savingproduto}>
            Salvar
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form} onFinish={submitForm} name="produtoForm">
          <Form.Item style={{ display: 'none' }} name="id">
            <Input />
          </Form.Item>
          <Form.Item label="Descrição" name="descricao" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Pontuação" name="pontuacao" rules={[{ type: 'number', message: 'Formato incorreto' }]}>
            <InputNumber placeholder="0,00" decimalSeparator="," />
          </Form.Item>
          <Form.Item
            label="Remuneração"
            name="remuneracao"
            rules={[{ required: true, message: 'Campo Obrigatório' }]}
            normalize={(v) => moneyMask.format(moneyMask.normalize(v))}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
export default ProdutoForm;
