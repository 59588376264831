import { useEffect, useState } from 'react';
import { Table, Input, Button, Popconfirm } from 'antd';
import { GetCorretores, FormCorretor, DeleteCorretor } from '@actions/corretoresActions';
import CorretorForm from './CorretorForm';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { getColumnSearchProps, sortAlpha, filterobject } from '@utils/filterandsort';
import { SetPageHeader } from '@actions/userActions';
import { Permission } from '@hooks//usePermissions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Corretor } from '@models/Corretor';

function CorretoresView() {
  const dispatch = useAppDispatch();
  const corretores = useAppSelector((state) => state.corretores);
  const [filter, setFilter] = useState('');
  useEffect(() => {
    dispatch(GetCorretores());
    dispatch(
      SetPageHeader({
        title: 'Cadastros',
        backbutton: true,
        extras: [
          <Permission key="1" permission="corretor.add">
            <Button onClick={() => dispatch(FormCorretor(null, true))} type="primary">
              Novo Corretor
            </Button>
          </Permission>,
        ],
        subtitle: 'Corretores',
        menu: 'corretores',
      })
    );
  }, []);

  return (
    <div>
      <CorretorForm />
      <div style={{ textAlign: 'right' }}>
        <Input style={{ width: 200 }} onChange={(e) => setFilter(e.target.value)} placeholder="Buscar" allowClear />
      </div>
      <Table
        size="small"
        rowKey="id"
        loading={corretores.loadingcorretores}
        dataSource={filterobject(corretores.corretores, filter)}
        scroll={{ y: 'calc(100vh - 300px)', x: 1024 }}
        pagination={{
          position: ['bottomRight'],
          size: 'small',
          showSizeChanger: true,
          pageSizeOptions: [10, 25, 50, 100],
          showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
        }}
      >
        <Table.Column
          width={80}
          fixed="left"
          render={(_, row: Corretor) => (
            <div>
              <Permission permission="corretor.update">
                <Button icon={<EditOutlined />} onClick={() => dispatch(FormCorretor(row.id, true))} size="small" />
              </Permission>
              <Permission permission="corretor.delete">
                <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(DeleteCorretor(row.id))}>
                  <Button icon={<DeleteOutlined />} size="small" />
                </Popconfirm>
              </Permission>
            </div>
          )}
        />
        <Table.Column
          title="Nome"
          dataIndex="nome"
          sorter={(a, b) => sortAlpha(a.nome, b.nome)}
          {...getColumnSearchProps('nome', 'string', 'Nome')}
        />
        <Table.Column
          title="Responsável"
          dataIndex="contatoPrincipal"
          sorter={(a, b) => sortAlpha(a.contatoPrincipal, b.contatoPrincipal)}
          {...getColumnSearchProps('contatoPrincipal', 'string', 'Responsável')}
        />
        <Table.Column
          title="Telefone"
          dataIndex="telefone"
          sorter={(a, b) => sortAlpha(a.telefone, b.telefone)}
          {...getColumnSearchProps('telefone', 'string', 'Telefone')}
        />
      </Table>
    </div>
  );
}
export default CorretoresView;
