import { GetPendetesAprovacao } from '@services/api/api.correspondencia';
import { Card, Spin, Statistic } from 'antd';
import { SmileTwoTone, FrownTwoTone } from '@ant-design/icons';
function DashboardPendentesAprovacao() {
  const { data, isLoading } = GetPendetesAprovacao();

  return (
    <Card>
      <Spin spinning={isLoading}>
        <Statistic
          title="Pendentes de Aprovação (pastas dos últimos 7 dias)"
          value={data}
          precision={0}
          style={{ width: 500, height: 62 }}
          valueStyle={{ color: '#cf1322' }}
          prefix={data ? <FrownTwoTone twoToneColor="red" /> : <SmileTwoTone twoToneColor="green" />}
        />
      </Spin>
    </Card>
  );
}

export default DashboardPendentesAprovacao;
