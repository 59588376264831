import { ExtendedAction } from '@config/reduxStore';
import { Configuracao } from '@models/Configuracao';
import { Conta } from '@models/Conta';
import { Modulo } from '@models/Modulo';
import { User } from '@models/User';
import { UserRole } from '@models/UserRole';
import { notification } from 'antd';
import { ReactNode } from 'react';

const dev = false; // process.env.NODE_ENV === 'development';
interface ConfiguracaoReducer extends Configuracao {
  loadingconfiguracao: boolean;
  modulos: string[];
}
interface UserReducer {
  user: {
    authenticated: boolean;
    loadingconfiguracao: boolean;
    loading: boolean;
    nome: string;
    email: string;
    roles: string[];
    permissions: string[];
    checked: boolean;
    nivel: number;
    nivelOrdens: number;
    nivelFolhas: number;
    nivelTransferencias: number;
    nivelPedidos: number;
    aprovaOrdem: boolean;
    aprovaFolha: boolean;
    aprovaPedido: boolean;
    aprovaTransferencia: boolean;
    casaid: number;
    userroles: string[];
    configuracao: ConfiguracaoReducer;
    loadingmailsettings: boolean;
    mailsettings: Configuracao;
    pageheader: {
      title: string;
      backbutton: boolean;
      extras: ReactNode[];
      subtitle: string;
      menu: string[];
    };
    modulos: Modulo[];
    subscription: {
      start_time: Date;
      status: string;
    };
    id: string;
    conta: Conta;
  };
  login: {
    loading: boolean;
    feedback: string;
  };
  forgot: {
    loading: boolean;
    feedback: string;
  };
  reset: {
    loading: boolean;
    feedback: string;
  };
  users: {
    users: User[];
    loadingusers: boolean;
    savinguser: boolean;
    feedback: string;
    userroles: UserRole[];
  };
  aprovadores: User[];
  loadingaprovadores: boolean;
  pageheader: {
    title: string | ReactNode;
    backbutton: boolean;
    extras: ReactNode[];
    subtitle: string;
    menu: string;
  };
  paypal: {
    clientid: string;
    secret: string;
    api: string;
  };
}

var initialState: UserReducer = {
  user: {
    loadingconfiguracao: false,
    conta: null,
    id: null,
    authenticated: false,
    loading: false,
    nome: '',
    email: '',
    aprovaFolha: false,
    aprovaOrdem: false,
    aprovaPedido: false,
    aprovaTransferencia: false,
    nivelFolhas: 0,
    nivelOrdens: 0,
    nivelPedidos: 0,
    nivelTransferencias: 0,
    roles: [],
    nivel: null,
    permissions: [],
    checked: true,
    casaid: null,
    userroles: [],
    pageheader: null,
    configuracao: null,
    loadingmailsettings: false,
    mailsettings: null,
    subscription: null,
    modulos: [],
  },
  login: {
    loading: false,
    feedback: '',
  },
  forgot: {
    loading: false,
    feedback: '',
  },
  reset: {
    loading: false,
    feedback: '',
  },
  users: {
    users: [],
    loadingusers: false,
    savinguser: false,
    feedback: '',
    userroles: [],
  },
  aprovadores: [],
  loadingaprovadores: false,
  pageheader: {
    title: 'Painel de Resultados',
    backbutton: null,
    extras: [],
    subtitle: '',
    menu: null,
  },
  paypal: {
    clientid: dev
      ? 'AVAm8oNha6hcYIAFG9GL2idVfsHbnRQMPfOw54vRnSRWSw24ATZljthZ4movX5muHhRVHYvJs_UN2Xkn'
      : 'ATJF_JVqJ76vzjWwPuboKs8Xpg8q3SkvvS5njg-iMDu4VLsifMnRLGsSyyID6y6PUY7A-mvQzgoKMT21',
    secret: dev
      ? 'EN-h6oSeH-5bZVM8EckNvqCgR9Nn3EW9E5JsBzMn-u17_Oz7VB1o8sSSeZt1rd6UU_FX07fOKib9C4Bs'
      : 'EOOsk5WggAKY-ITkggXkPmBqCgXVP8Jy8icFUMoLmguBDXj68C1kY-KJwwQvrQKJANkA6gd7peiuoZXU',
    api: dev ? 'https://api-m.sandbox.paypal.com' : 'https://api-m.paypal.com',
  },
};

const userReducer = function (state = initialState, action: ExtendedAction) {
  switch (action.type) {
    case 'LOGOUT_FULFILLED':
      window.location.href = '/';
      break;
    case 'SET_PAGE_HEADER':
      state = {
        ...state,
        pageheader: action.payload,
      };
      break;
    case 'SELECT_CASA':
      state = {
        ...state,
        user: {
          ...state.user,
          casaid: action.payload,
        },
      };
      break;
    case 'GET_APROVADORES_PENDING':
      state = {
        ...state,
        loadingaprovadores: true,
      };
      break;
    case 'GET_APROVADORES_REJECTED':
      state = {
        ...state,
        loadingaprovadores: false,
      };
      break;
    case 'GET_APROVADORES_FULFILLED':
      state = {
        ...state,
        loadingaprovadores: false,
        aprovadores: action.payload.data,
      };
      break;
    case 'CHANGE_PASSWORD_PENDING':
      state = {
        ...state,
        reset: {
          ...state.reset,
          loading: true,
          feedback: '',
        },
      };
      break;
    case 'CHANGE_PASSWORD_REJECTED':
      state = {
        ...state,
        reset: {
          ...state.reset,
          loading: false,
          feedback: action.payload.response.data.Message,
        },
      };
      break;
    case 'CHANGE_PASSWORD_FULFILLED':
      state = {
        ...state,
        reset: {
          ...state.reset,
          loading: false,
          feedback: action.payload.data,
        },
      };
      break;
    case 'DELETE_USER_CASA_PENDING':
      state = {
        ...state,
        users: {
          ...state.users,
          loadingusers: true,
          feedback: '',
        },
      };
      break;
    case 'DELETE_USER_CASA_REJECTED':
      state = {
        ...state,
        users: {
          ...state.users,
          loadingusers: false,
          feedback: action.payload.response.data.Message,
        },
      };
      break;
    case 'DELETE_USER_CASA_FULFILLED':
      state = {
        ...state,
        users: {
          ...state.users,
          loadingusers: false,
          users: state.users.users.filter((c) => c.id !== action.payload.data),
        },
      };
      break;
    case 'GET_USERS_CASA_PENDING':
      state = {
        ...state,
        users: {
          ...state.users,
          loadingusers: true,
          feedback: '',
        },
      };
      break;
    case 'GET_USERS_CASA_REJECTED':
      state = {
        ...state,
        users: {
          ...state.users,
          loadingusers: false,
          feedback: action.payload.response.data.Message,
        },
      };
      break;
    case 'GET_USERS_CASA_FULFILLED':
      state = {
        ...state,
        users: {
          ...state.users,
          loadingusers: false,
          users: action.payload.data,
        },
      };
      break;
    case 'INVITE_USER_PENDING':
      state = {
        ...state,
        users: {
          ...state.users,
          loadingusers: true,
          feedback: '',
        },
      };
      break;
    case 'INVITE_USER_REJECTED':
      state = {
        ...state,
        users: {
          ...state.users,
          loadingusers: false,
          feedback: action.payload.response.data.Message,
        },
      };
      break;
    case 'INVITE_USER_FULFILLED':
      state = {
        ...state,
        users: {
          ...state.users,
          loadingusers: false,
          users: [...state.users.users, action.payload.data],
        },
      };
      break;
    case 'RESET_PASSWORD_PENDING':
      state = {
        ...state,
        reset: {
          ...state.reset,
          loading: true,
          feedback: '',
        },
      };
      break;
    case 'RESET_PASSWORD_REJECTED':
      state = {
        ...state,
        reset: {
          ...state.reset,
          loading: false,
          feedback: 'Página expirada',
        },
      };
      break;
    case 'RESET_PASSWORD_FULFILLED':
      state = {
        ...state,
        reset: {
          ...state.reset,
          loading: false,
          feedback: action.payload.data,
        },
      };
      break;
    case 'FORGOT_PASSWORD_PENDING':
      state = {
        ...state,
        forgot: {
          ...state.forgot,
          loading: true,
          feedback: '',
        },
      };
      break;
    case 'FORGOT_PASSWORD_REJECTED':
      state = {
        ...state,
        forgot: {
          ...state.forgot,
          loading: false,
          feedback: action.payload.response.data.Message,
        },
      };
      break;
    case 'FORGOT_PASSWORD_FULFILLED':
      state = {
        ...state,
        forgot: {
          ...state.forgot,
          loading: false,
          feedback: action.payload.data,
        },
      };
      break;
    case 'CHECK_FULFILLED':
      state = {
        ...state,
        user: {
          ...action.payload,
          loading: false,
          checked: true,
        },
      };
      break;
    case 'CHECK_REJECTED':
      state = {
        ...state,
        user: initialState.user,
      };
      break;
    case 'CHECK_PENDING':
      state = {
        ...state,
        user: {
          ...state.user,
          loading: true,
          checked: false,
        },
      };
      break;
    case 'LOGIN_PENDING':
      state = {
        ...state,
        login: {
          ...state.login,
          loading: true,
          feedback: '',
        },
      };
      break;
    case 'LOGIN_REJECTED':
      state = {
        ...state,
        login: {
          ...state.login,
          loading: false,
          feedback: action.payload.response.data.Message.feedback,
        },
      };
      break;
    case 'LOGIN_FULFILLED':
      state = {
        ...state,
        user: {
          ...state.user,
          ...action.payload.data,
        },
      };
      break;
    case 'SALVAR_CONFIGURACAO_PENDING':
      state = {
        ...state,
        user: {
          ...state.user,
          configuracao: {
            ...state.user.configuracao,
            loadingconfiguracao: true,
          },
        },
      };
      break;
    case 'SALVAR_CONFIGURACAO_REJECTED':
      state = {
        ...state,
        user: {
          ...state.user,
          configuracao: {
            ...state.user.configuracao,
            loadingconfiguracao: false,
          },
        },
      };
      notification.error({ message: 'Erro ao salvar configurações!' });
      break;
    case 'SALVAR_CONFIGURACAO_FULFILLED':
      state = {
        ...state,
        user: {
          ...state.user,
          configuracao: {
            ...state.user.configuracao,
            ...action.payload.data,
            loadingconfiguracao: false,
          },
        },
      };
      notification.success({ message: 'Configuração salva com sucesso!' });
      break;
    case 'GET_MAILSETTINGS_PENDING':
      state = {
        ...state,
        user: {
          ...state.user,
          loadingmailsettings: true,
        },
      };
      break;
    case 'GET_MAILSETTINGS_REJECTED':
      state = {
        ...state,
        user: {
          ...state.user,
          loadingmailsettings: false,
        },
      };
      break;
    case 'GET_MAILSETTINGS_FULFILLED':
      let data = action.payload.data;
      let keys = Object.keys(data);
      keys.forEach((k) => {
        data[k].emails = data[k].emails.length > 0 ? data[k].emails.split(',') : [];
        data[k].usuarios = data[k].usuarios.length > 0 ? data[k].usuarios.split(',') : [];
        data[k].perfis = data[k].perfis.length > 0 ? data[k].perfis.split(',') : [];
      });
      state = {
        ...state,
        user: {
          ...state.user,
          loadingmailsettings: false,
          mailsettings: {
            ...data,
          },
        },
      };
      break;
    case 'SALVAR_MAILSETTINGS_PENDING':
      state = {
        ...state,
        user: {
          ...state.user,
          loadingmailsettings: true,
        },
      };
      break;
    case 'SALVAR_MAILSETTINGS_REJECTED':
      state = {
        ...state,
        user: {
          ...state.user,
          loadingmailsettings: false,
        },
      };
      notification.error({ message: 'Erro ao salvar configurações de notificação' });
      break;
    case 'SALVAR_MAILSETTINGS_FULFILLED':
      state = {
        ...state,
        user: {
          ...state.user,
          loadingmailsettings: false,
        },
      };
      notification.success({ message: 'Configurações salvas com sucesso!' });
      break;
    default:
      return state;
  }
  return state;
};

export default userReducer;
