import { Button, Progress, Table } from 'antd';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GetAvancos, getAvancosFisicos, getCategoriaItens, getFisico, getRealizadoMes } from '@actions/fisicoActions';
import { SetPageHeader } from '@actions/userActions';
import { doubleMask1, moneyMask } from '@utils/masks';
import moment from 'moment';
import { naturalSorter } from '@utils/filterandsort';
import '../../../assets/scss/Fisico.scss';
import GruposModal from './GruposModal';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Fisico } from '@models/Fisico';

function FisicoReport() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [meses, setMeses] = useState(new Array(1).fill(0));
  const [inicial, setInicial] = useState(moment(new Date()));
  const [lancamentos, setLancamentos] = useState([]);
  const [grupos, setGrupos] = useState({ visible: false, categoria: '' });
  const {
    fisico,
    loadingFisico,
    avancos,
    loadingAvancos,
    realizados,
    loadingRealizados,
    orcamento,
    avancosFisicos,
    loadingAvancosFisicos,
  } = useAppSelector((state) => state.fisico);
  useEffect(() => {
    dispatch(getFisico(id));
    dispatch(GetAvancos(id));
    dispatch(getRealizadoMes(id));
    dispatch(getAvancosFisicos(id));
  }, [id]);
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Planejamento Físico Financeiro',
        backbutton: true,
        extras: [],
        subtitle: orcamento,
        menu: 'fisico',
      })
    );
  }, [orcamento]);
  useEffect(() => {
    if (avancos && realizados) {
      const mindate = moment.min(realizados.map((c) => c.mes));
      const maxdate = moment.max(realizados.map((c) => c.mes));
      const months = moment(maxdate).diff(mindate, 'month');
      setInicial(avancos.meses > months ? moment(avancos.dataInicio) : moment(mindate));
      setMeses(new Array(Math.max(months, avancos.meses)).fill(0));
      setLancamentos(avancos.fisicoFinanceiro.map((c) => ({ ...c, mes: moment(c.mes) })));
    }
  }, [avancos, realizados]);
  const strokeColor = {
    from: '#108ee9',
    to: '#87d068',
  };

  return (
    <div>
      <GruposModal visible={grupos.visible} setVisible={setGrupos} categoria={grupos.categoria} />
      <Table
        size="small"
        className="fisicoform"
        dataSource={[...fisico].sort((a, b) => naturalSorter(a.descricao, b.descricao))}
        bordered
        pagination={false}
        scroll={{ x: 500 + meses.length * 150, y: 'calc(100vh - 280px)' }}
        rowKey="id"
        loading={loadingFisico || loadingAvancos || loadingRealizados || loadingAvancosFisicos}
        summary={(data) => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} align="right">
                <strong>TOTAL</strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} align="right">
                <div style={{ fontWeight: 'bold' }}>
                  <div>{moneyMask.format(data.reduce((a, b) => a + b.orcado, 0))}</div>
                  <div>100%</div>
                </div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} align="right">
                <strong>
                  <div>
                    <div>AVANÇO PLANEJADO</div>
                    <div>AVANÇO REAL</div>
                    <div>CUSTO PLANEJADO</div>
                    <div>REALIZADO</div>
                  </div>
                </strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3} align="right">
                <strong>
                  <div>
                    <div>
                      <Progress
                        strokeColor={strokeColor}
                        percent={
                          Math.ceil(
                            (lancamentos
                              .filter((c) => moment(c.mes) < moment())
                              .reduce(
                                (a, b) => a + b.planejado * fisico.find((c) => c.id === b.categoriaID)?.orcado,
                                0
                              ) /
                              fisico.reduce((a, b) => a + b.orcado, 0)) *
                              1
                          ) / 1
                        }
                      />
                    </div>
                    <div>
                      <Progress
                        strokeColor={strokeColor}
                        percent={
                          Math.ceil(
                            (avancosFisicos.reduce(
                              (a, b) => a + b.realizado * fisico.find((c) => c.id === b.categoriaID)?.orcado,
                              0
                            ) /
                              fisico.reduce((a, b) => a + b.orcado, 0)) *
                              1
                          ) / 1
                        }
                      />
                    </div>
                    <div>
                      {moneyMask.format(
                        lancamentos
                          .filter((c) => moment(c.mes) <= moment(new Date()))
                          .reduce(
                            (a, b) => a + (b.planejado * fisico.find((c) => c.id === b.categoriaID)?.orcado) / 100,
                            0
                          )
                      )}
                    </div>
                    <div>{moneyMask.format(realizados.reduce((a, b) => a + b.valor, 0))}</div>
                  </div>
                </strong>
              </Table.Summary.Cell>
              {meses.map((_, index) => {
                const current = moment(inicial).add(index, 'month').format('MM/YYYY');
                return (
                  <Table.Summary.Cell index={4} align="right">
                    <strong>
                      <div>
                        <div>
                          <Progress
                            strokeColor={strokeColor}
                            percent={
                              Math.ceil(
                                (lancamentos
                                  .filter((c) => moment(c.mes).format('MM/YYYY') === current)
                                  .reduce(
                                    (a, b) => a + b.planejado * fisico.find((c) => c.id === b.categoriaID)?.orcado,
                                    0
                                  ) /
                                  fisico.reduce((a, b) => a + b.orcado, 0)) *
                                  1
                              ) / 1
                            }
                          />
                        </div>
                        <div>
                          <Progress
                            strokeColor={strokeColor}
                            percent={
                              Math.ceil(
                                (avancosFisicos.reduce(
                                  (a, b) =>
                                    a +
                                    b.realizado *
                                      fisico.find(
                                        (c) => c.id === b.categoriaID && moment(c.mes).format('MM/YYYY') === current
                                      )?.orcado,
                                  0
                                ) /
                                  fisico.reduce((a, b) => a + b.orcado, 0)) *
                                  1
                              ) / 1
                            }
                          />
                        </div>
                        <div>
                          {moneyMask.format(
                            lancamentos
                              .filter((c) => moment(c.mes).format('MM/YYYY') === current)
                              .reduce(
                                (a, b) => a + (b.planejado * fisico.find((c) => c.id === b.categoriaID)?.orcado) / 100,
                                0
                              )
                          )}
                        </div>
                        <div>
                          {moneyMask.format(
                            realizados
                              .filter((c) => moment(c.mes).format('MM/YYYY') === current)
                              .reduce((a, b) => a + b.valor, 0)
                          )}
                        </div>
                      </div>
                    </strong>
                  </Table.Summary.Cell>
                );
              })}
            </Table.Summary.Row>
          </Table.Summary>
        )}
      >
        <Table.Column
          title="Etapa"
          dataIndex="descricao"
          width={200}
          fixed="left"
          render={(v, row: Fisico) => (
            <Button
              type="link"
              onClick={() => {
                dispatch(getCategoriaItens({ categoriaID: row.id, orcamentoID: id }));
                setGrupos({ visible: true, categoria: row.descricao });
              }}
            >
              {v.toUpperCase()}
            </Button>
          )}
        />
        <Table.Column
          title="Orçado"
          dataIndex="orcado"
          fixed="left"
          width={130}
          render={(v) => (
            <div>
              <div>{moneyMask.format(v)}</div>
              <div>{doubleMask1.format((100 * v) / fisico.reduce((a, b) => a + b.orcado, 0))}</div>
            </div>
          )}
          align="right"
        />
        <Table.Column
          align="right"
          fixed="left"
          width={140}
          render={() => (
            <div>
              <div>AVANÇO PLANEJADO</div>
              <div>AVANÇO REAL</div>
              <div>CUSTO PLANEJADO</div>
              <div>REALIZADO</div>
            </div>
          )}
        />
        <Table.Column
          title={'Situação ' + moment(new Date()).format('MM/YYYY')}
          dataIndex="realizado"
          align="right"
          fixed="left"
          width={150}
          render={(v, row: Fisico) => (
            <div>
              <div>
                <Progress
                  strokeColor={strokeColor}
                  percent={lancamentos
                    .filter((c) => moment(c.mes) < moment(new Date()))
                    .filter((c) => c.categoriaID === row.id)
                    .reduce((a, b) => a + b.planejado, 0)}
                />
              </div>
              <div>
                <Progress
                  strokeColor={strokeColor}
                  percent={avancosFisicos.filter((c) => c.categoriaID === row.id).reduce((a, b) => a + b.realizado, 0)}
                />
              </div>
              <div>
                {moneyMask.format(
                  (row.orcado *
                    lancamentos
                      .filter((c) => moment(c.mes) < moment(new Date()))
                      .filter((c) => c.categoriaID === row.id)
                      .reduce((a, b) => a + b.planejado, 0)) /
                    100
                )}
              </div>
              <div
                style={{
                  color:
                    (row.orcado *
                      lancamentos
                        .filter((c) => moment(c.mes) < moment(new Date()))
                        .filter((c) => c.categoriaID === row.id)
                        .reduce((a, b) => a + b.planejado, 0)) /
                      100 <
                    v
                      ? 'red'
                      : 'blue',
                }}
              >
                {moneyMask.format(v)}
              </div>
            </div>
          )}
        />
        {meses.map((_, index) => {
          const current = moment(inicial).add(index, 'month').format('MM/YYYY');
          return (
            <Table.Column
              key={index}
              className={current === moment(new Date()).format('MM/YYYY') && 'today'}
              title={current}
              align="right"
              width={150}
              render={(_, row: Fisico) => (
                <div>
                  <div>
                    <Progress
                      strokeColor={strokeColor}
                      percent={
                        lancamentos?.find(
                          (c) => c.categoriaID === row.id && moment(c.mes).format('MM/YYYY') === current
                        )?.planejado || 0
                      }
                    />
                  </div>
                  <div>
                    <Progress
                      strokeColor={strokeColor}
                      percent={
                        avancosFisicos.find(
                          (c) => c.categoriaID === row.id && moment(c.mes).format('MM/YYYY') === current
                        )?.realizado
                      }
                    />
                  </div>
                  <div>
                    {moneyMask.format(
                      (lancamentos?.find((c) => c.categoriaID === row.id && moment(c.mes).format('MM/YYYY') === current)
                        ?.planejado *
                        row.orcado) /
                        100 || 0
                    )}
                  </div>
                  <div>
                    {moneyMask.format(
                      realizados
                        ?.filter((c) => c.categoriaID === row.id && moment(c.mes).format('MM/YYYY') === current)
                        ?.reduce((a, b) => a + b.valor, 0) || 0
                    )}
                  </div>
                </div>
              )}
            />
          );
        })}
      </Table>
    </div>
  );
}

export default FisicoReport;
