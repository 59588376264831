import { useEffect, useState } from 'react';
import logo from '../../../assets/img/main.png';
import moment from 'moment';
import { GetChamado } from '@actions/chamadosActions';
import { useParams } from 'react-router-dom';
import { Axios } from '@config/axios';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

function OSencerramento() {
  const dispatch = useAppDispatch();
  const { chamado } = useAppSelector((state) => state.chamados);
  const { chamadoid } = useParams();
  const [templates, setTemplates] = useState(null);
  const { user } = useAppSelector((state) => state.user);
  useEffect(() => {
    dispatch(GetChamado(chamadoid));
    Axios.get('/api/v2/Templates/GetTemplates').then((res) => setTemplates(res.data));
  }, []);
  useEffect(() => {
    if (chamado && templates) {
      document.write(
        templates.formEncerramento.body.replace(/{{\w+}}/g, (a: any) => {
          console.log(a);
          if (a === '{{logo}}') return `<img src="${user.configuracao.logo || logo}" alt="logo" height="70"/>`;
          if (a === '{{quebrapagina}}') return '<div style="break-after:page"></div>';
          if (a === '{{dataChamado}}')
            return moment(chamado.dataChamado).isValid() ? moment(chamado.dataChamado).format('DD/MM/YYYY') : '';
          if (a === '{{dataEntrega}}')
            return moment(chamado.dataEntrega).isValid() ? moment(chamado.dataEntrega).format('DD/MM/YYYY') : '';
          if (a === '{{dataContrato}}')
            return moment(chamado.dataContrato).isValid() ? moment(chamado.dataContrato).format('DD/MM/YYYY') : '';
          if (a === '{{reclamacoes}}')
            return `<div><table style="border-collapse: collapse; width: 100%;" border="1">
                <thead>
                  <tr>
                    <th style="width: 23.56%; text-align: center;">Situação</th>
                    <th style="width: 23.56%; text-align: center;">Item</th>
                    <th style="width: 23.56%; text-align: center;">Parecer</th>
                    <th style="width: 23.56%; text-align: center;">Datas</th>
                    <th style="width: 23.56%; text-align: center;">Recebimento</th>
                  </tr>
                </thead>
                <tbody>
                  ${chamado.reclamacoes.map(
                    (r) =>
                      `<tr>
                        <td style="text-align: center;">${r.status}</td>
                        <td>${r.descricao || ''}</td>
                        <td>${r.solucao || ''}</td>
                        <td>${moment(r.dataInicio).isValid() ? moment(r.dataInicio).format('DD/MM/YYYY') : ''}</td>
                        <td></td>
                      </tr>`
                  )}
                </tbody>
              </table></div>`;
          return chamado && chamado[String(a.replace(/{{/g, '').replace(/}}/g, ''))];
        })
      );
      document.close();
      window.print();
    }
  }, [chamado, templates]);
  return <div />;
}
export default OSencerramento;
