import { useEffect, useState } from 'react';
import { Table, Input, Button, Popconfirm } from 'antd';
import { GetParceiros, FormParceiro, DeleteParceiro } from '@actions/parceirosActions';
import ParceiroForm from './ParceiroForm';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { getColumnSearchProps, sortAlpha, filterobject } from '@utils/filterandsort';
import { SetPageHeader } from '@actions/userActions';
import { Permission } from '@hooks//usePermissions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Parceiro } from '@models/Parceiro';

function ParceirosView() {
  const dispatch = useAppDispatch();
  const parceiros = useAppSelector((state) => state.parceiros);
  const [filter, setFilter] = useState('');
  useEffect(() => {
    dispatch(GetParceiros());
    dispatch(
      SetPageHeader({
        title: 'Cadastros',
        backbutton: true,
        extras: [
          <Permission key="1" permission="parceiro.add">
            <Button onClick={() => dispatch(FormParceiro(null, true))} type="primary">
              Novo Parceiro
            </Button>
          </Permission>,
        ],
        subtitle: 'Agências Bancárias',
        menu: 'parceiros',
      })
    );
  }, []);

  return (
    <div>
      <ParceiroForm />
      <div style={{ textAlign: 'right' }}>
        <Input style={{ width: 200 }} onChange={(e) => setFilter(e.target.value)} placeholder="Buscar" allowClear />
      </div>
      <Table
        size="small"
        rowKey="id"
        loading={parceiros.loadingparceiros}
        dataSource={filterobject(parceiros.parceiros, filter)}
        scroll={{ y: 'calc(100vh - 300px)', x: 1024 }}
        pagination={{
          position: ['bottomRight'],
          size: 'small',
          showSizeChanger: true,
          pageSizeOptions: [10, 25, 50, 100],
          showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
        }}
      >
        <Table.Column
          width={80}
          fixed="left"
          render={(_, row: Parceiro) => (
            <div>
              <Button icon={<EditOutlined />} onClick={() => dispatch(FormParceiro(row.id, true))} size="small" />
              <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(DeleteParceiro(row.id))}>
                <Button icon={<DeleteOutlined />} size="small" />
              </Popconfirm>
            </div>
          )}
        />
        <Table.Column
          title="Nome"
          dataIndex="nome"
          sorter={(a, b) => sortAlpha(a.nome, b.nome)}
          {...getColumnSearchProps('nome', 'string', 'Nome')}
        />
      </Table>
    </div>
  );
}
export default ParceirosView;
