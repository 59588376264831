import { apiEndpoints } from '@constants/apiEndpoints';
import { MetaOrcamento } from '@models/MetaOrcamento';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Axios } from '@config/axios';

export const getMetas = createAsyncThunk('metas/getMetas', async (mes: string) =>
  Axios.get(apiEndpoints.METAS.GET_METAS, { params: { mes } })
);
export const getOrcamentosMeta = createAsyncThunk('metas/getOrcamentosMeta', async () =>
  Axios.get(apiEndpoints.METAS.GET_ORCAMENTOS)
);
export const salvarMeta = createAsyncThunk('metas/salvarMeta', async (values: MetaOrcamento) =>
  Axios.post(apiEndpoints.METAS.SALVAR_META, values)
);
export const deleteMeta = createAsyncThunk('metas/deleteMeta', async (id: number) =>
  Axios.get(apiEndpoints.METAS.DELETE_META, { params: { id } })
);
export const bloquearGrupo = createAsyncThunk(
  'metas/bloquearGrupo',
  async (data: { id: number; bloqueado: boolean; metaid: number }) =>
    Axios.get(apiEndpoints.METAS.BLOQUEAR_GRUPO, { params: data })
);
export const bloquearMeta = createAsyncThunk('metas/bloquearMeta', async (data: { id: number; bloquear: boolean }) =>
  Axios.get(apiEndpoints.METAS.BLOQUEAR_META, { params: data })
);
