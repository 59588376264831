import { GetOrdensAprovar } from '@actions/ordensActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { apiEndpoints } from '@constants/apiEndpoints';
import { IOrdemForm } from '@models/Ordem';
import {
  AddItemOrdemModel,
  AddNotaFiscalOrdem,
  CancelarOrdemModel,
  CompilarArquivosOrdemModel,
  DeleteFileOrdem,
  DeleteItemOrdemModel,
  DeleteNotaFiscalOrdem,
  FinalizarOrdemModel,
  PagarParcialOrdemModel,
  SaveDescontoOrdem,
  SaveDescricaoOrdem,
  SaveJustificativaOrdem,
  SaveObservacaoOrdem,
  SubmeterOrdemModel,
  UploadFileOrdem,
} from '@models/OrdemItem';
import { User } from '@models/User';
import { notification } from 'antd';
import { AxiosError } from 'axios';
import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { fetcher, poster } from './api';
import { OrcamentoItensResult } from './api.orcamentoItem';
import { NotaFiscalOrdem } from '@models/NotaFiscalOrdem';

export interface SalvarOrdemResult {
  id: number;
  cod: string;
  situacao: string;
}

export const GetSolicitantes = () => {
  return useQuery<User[], AxiosError<any>>(
    ['solicitantesList'],
    () => fetcher<User[]>(apiEndpoints.ORDENS.GET_SOLICITANTES, { params: { ativo: true } }),
    {
      initialData: [],
      staleTime: 0,
      onError: (err) => console.log(err.response?.data?.Message),
    }
  );
};
export const GetOrcamentoItensAll = (itens: number[]) => {
  const data: { queryKey: [string, number]; queryFn: any }[] = itens.map((c) => ({
    queryKey: ['orcamentoItensList', c],
    queryFn: fetcher<OrcamentoItensResult>(apiEndpoints.ORCAMENTOS.GET_ORCAMENTO_ITENS_ORDEM, {
      params: { orcamentoid: c },
    }),
  }));
  return useQueries<OrcamentoItensResult[]>(data);
};
export const SalvarOrdemStep1 = () => {
  const queryClient = useQueryClient();
  const { id } = useParams();
  const navigate = useNavigate();
  return useMutation<IOrdemForm, AxiosError<any>, Partial<IOrdemForm>>(
    'salvarOrdem',
    (data) => poster<IOrdemForm>(apiEndpoints.ORDENS.SALVAR_ORDEM_STEP1, data),
    {
      onSuccess: (res) => {
        notification.success({ message: 'Ordem salva com sucesso!' });
        queryClient.invalidateQueries(['ordemEdit', String(res.id)]);
        queryClient.invalidateQueries(['ordemEdit', String(res.cod)]);
        if (!id) navigate(`/NovaOrdem/1/${res.id}`);
      },
      onError: (err) => notification.error({ message: err.response?.data?.Message }),
    }
  );
};
export const SalvarOrdemStep2 = () => {
  const queryClient = useQueryClient();
  return useMutation<IOrdemForm, AxiosError<any>, Partial<IOrdemForm>>(
    (data) => poster<IOrdemForm>(apiEndpoints.ORDENS.SALVAR_ORDEM_STEP2, data),
    {
      onSuccess: (res, variables) => {
        notification.success({ message: 'Ordem salva com sucesso!' });
        queryClient.invalidateQueries(['ordemEdit', String(variables.id)]);
        queryClient.invalidateQueries(['ordemEdit', String(res.cod)]);
      },
      onError: (err) => notification.error({ message: err.response?.data?.Message }),
    }
  );
};
export const AddItemOrdem = () => {
  const queryClient = useQueryClient();
  return useMutation<IOrdemForm, AxiosError<any>, AddItemOrdemModel>(
    (data) => poster<IOrdemForm>(apiEndpoints.ORDENS.ADD_ITEM, data),
    {
      onSuccess: (res) => {
        notification.success({ message: 'Ordem salva com sucesso!' });
        queryClient.invalidateQueries(['ordemEdit', String(res.id)]);
        queryClient.invalidateQueries(['ordemEdit', String(res.cod)]);
      },
      onError: (err) => {
        notification.error({ message: err.response?.data?.Message });
      },
    }
  );
};
export const CancelarOrdem = () => {
  const queryClient = useQueryClient();
  return useMutation<IOrdemForm, AxiosError<any>, CancelarOrdemModel>(
    (data) => fetcher<IOrdemForm>(apiEndpoints.ORDENS.CANCELAR_ORDEM, { params: data }),
    {
      onSuccess: (res) => {
        notification.success({ message: 'Ordem salva com sucesso!' });
        queryClient.invalidateQueries(['ordemEdit', String(res.id)]);
        queryClient.invalidateQueries(['ordemEdit', String(res.cod)]);
      },
      onError: (err) => {
        notification.error({ message: err.response?.data?.Message });
      },
    }
  );
};
export const FinalizarOrdem = () => {
  const queryClient = useQueryClient();
  return useMutation<{ id: number; cod: string }, AxiosError<any>, FinalizarOrdemModel>(
    (data) => fetcher<{ id: number; cod: string }>(apiEndpoints.ORDENS.FINALIZAR_ORDEM, { params: data }),
    {
      onSuccess: (res) => {
        notification.success({ message: 'Ordem salva com sucesso!' });
        queryClient.invalidateQueries(['ordemEdit', String(res.id)]);
        queryClient.invalidateQueries(['ordemEdit', String(res.cod)]);
      },
      onError: (err) => {
        notification.error({ message: err.response?.data?.Message });
      },
    }
  );
};
export const PagarParcialOrdem = () => {
  const queryClient = useQueryClient();
  return useMutation<IOrdemForm, AxiosError<any>, PagarParcialOrdemModel>(
    (data) => poster<IOrdemForm>(apiEndpoints.ORDENS.PAGAR_PARCIAL, data),
    {
      onSuccess: (res) => {
        notification.success({ message: 'Ordem salva com sucesso!' });
        queryClient.invalidateQueries(['ordemEdit', String(res.id)]);
        queryClient.invalidateQueries(['ordemEdit', res.cod]);
      },
      onError: (err) => {
        notification.error({ message: err.response?.data?.Message });
      },
    }
  );
};
export const EnviarParaPagamentoOrdem = () => {
  const queryClient = useQueryClient();
  return useMutation<IOrdemForm, AxiosError<any>, SubmeterOrdemModel>(
    (data) => fetcher<IOrdemForm>(apiEndpoints.ORDENS.SOLICITAR_PAGAMENTO, { params: data }),
    {
      onSuccess: (res) => {
        notification.success({ message: 'Ordem enviada com sucesso!' });
        queryClient.invalidateQueries(['ordemEdit', String(res.id)]);
        queryClient.invalidateQueries(['ordemEdit', String(res.cod)]);
      },
      onError: (err) => notification.error({ message: err.response?.data?.Message }),
    }
  );
};
export const DeleteItemOrdem = () => {
  const queryClient = useQueryClient();
  return useMutation<IOrdemForm, AxiosError<any>, DeleteItemOrdemModel>(
    (data) => fetcher<IOrdemForm>(apiEndpoints.ORDENS.DELETE_ITEM, { params: data }),
    {
      onSuccess: (res) => {
        notification.success({ message: 'Ordem salva com sucesso!' });
        queryClient.invalidateQueries(['ordemEdit', String(res.id)]);
        queryClient.invalidateQueries(['ordemEdit', String(res.cod)]);
      },
      onError: (err) => notification.error({ message: err.response?.data?.Message }),
    }
  );
};
export const SaveDesconto = () => {
  const queryClient = useQueryClient();
  return useMutation<IOrdemForm, AxiosError<any>, SaveDescontoOrdem>(
    (data) => fetcher<IOrdemForm>(apiEndpoints.ORDENS.SAVE_DESCONTO, { params: data }),
    {
      onSuccess: (res) => {
        notification.success({ message: 'Ordem salva com sucesso!' });
        queryClient.invalidateQueries(['ordemEdit', String(res.id)]);
        queryClient.invalidateQueries(['ordemEdit', String(res.cod)]);
      },
      onError: (err) => notification.error({ message: err.response?.data?.Message }),
    }
  );
};
export const SaveJustificativa = () => {
  const queryClient = useQueryClient();
  return useMutation<IOrdemForm, AxiosError<any>, SaveJustificativaOrdem>(
    (data) => fetcher<IOrdemForm>(apiEndpoints.ORDENS.SAVE_JUSTIFICATIVA, { params: data }),
    {
      onSuccess: (res) => {
        notification.success({ message: 'Ordem salva com sucesso!' });
        queryClient.invalidateQueries(['ordemEdit', String(res.id)]);
        queryClient.invalidateQueries(['ordemEdit', String(res.cod)]);
      },
      onError: (err) => notification.error({ message: err.response?.data?.Message }),
    }
  );
};
export const SaveDescricao = () => {
  const queryClient = useQueryClient();
  return useMutation<IOrdemForm, AxiosError<any>, SaveDescricaoOrdem>(
    (data) => fetcher<IOrdemForm>(apiEndpoints.ORDENS.SAVE_DESCRICAO, { params: data }),
    {
      onSuccess: (res) => {
        notification.success({ message: 'Ordem salva com sucesso!' });
        queryClient.invalidateQueries(['ordemEdit', String(res.id)]);
        queryClient.invalidateQueries(['ordemEdit', String(res.cod)]);
      },
      onError: (err) => notification.error({ message: err.response?.data?.Message }),
    }
  );
};
export const SaveObservacao = () => {
  const queryClient = useQueryClient();
  return useMutation<IOrdemForm, AxiosError<any>, SaveObservacaoOrdem>(
    (data) => fetcher<IOrdemForm>(apiEndpoints.ORDENS.SAVE_OBSERVACAO, { params: data }),
    {
      onSuccess: (res) => {
        notification.success({ message: 'Ordem salva com sucesso!' });
        queryClient.invalidateQueries(['ordemEdit', String(res.id)]);
        queryClient.invalidateQueries(['ordemEdit', String(res.cod)]);
      },
      onError: (err) => notification.error({ message: err.response?.data?.Message }),
    }
  );
};
export const SubmeterOrdem = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  return useMutation<IOrdemForm, AxiosError<any>, SubmeterOrdemModel>(
    (data) => fetcher<IOrdemForm>(apiEndpoints.ORDENS.SUBMETER_ORDEM, { params: data }),
    {
      onSuccess: (res) => {
        notification.success({ message: 'Ordem submetida com sucesso!' });
        queryClient.invalidateQueries(['ordemEdit', String(res.id)]);
        queryClient.invalidateQueries(['ordemEdit', String(res.cod)]);
        if (user.roles.includes('aprovador')) dispatch(GetOrdensAprovar());
      },
      onError: (err) => notification.error({ message: err.response?.data?.Message }),
    }
  );
};
export const AprovarOrdem = () => {
  const queryClient = useQueryClient();
  return useMutation<IOrdemForm, AxiosError<any>, SubmeterOrdemModel>(
    (data) => fetcher<IOrdemForm>(apiEndpoints.ORDENS.APROVAR_ORDEM, { params: data }),
    {
      onSuccess: (res) => {
        notification.success({ message: 'Ordem aprovada com sucesso!' });
        queryClient.invalidateQueries(['ordemEdit', String(res.id)]);
        queryClient.invalidateQueries(['ordemEdit', String(res.cod)]);
      },
      onError: (err) => notification.error({ message: err.response?.data?.Message }),
    }
  );
};
export const UploadFile = () => {
  const queryClient = useQueryClient();
  return useMutation<any, AxiosError<any>, UploadFileOrdem>(
    (data) => poster<any>(apiEndpoints.ORDENS.POST_FILE, data.data),
    {
      onSuccess: (_, variables) => {
        notification.success({ message: 'Arquivo salvo com sucesso!' });
        queryClient.invalidateQueries(['ordemEdit', String(variables.ordemID)]);
        queryClient.invalidateQueries(['ordemEdit', String(variables.cod)]);
        queryClient.invalidateQueries(['folhaEdit', String(variables.ordemID)]);
        queryClient.invalidateQueries(['folhaEdit', String(variables.cod)]);
        queryClient.invalidateQueries(['transferencia', String(variables.ordemID)]);
        queryClient.invalidateQueries(['transferencia', String(variables.cod)]);
        queryClient.invalidateQueries(['pedido', String(variables.ordemID)]);
        queryClient.invalidateQueries(['pedido', String(variables.cod)]);
      },
      onError: (err) => notification.error({ message: err.response?.data?.Message }),
    }
  );
};
export const DownloadFile = () => {
  return useMutation<Blob, AxiosError<any>, { fileid: number; filename: string }>(
    (data) =>
      fetcher<Blob>(apiEndpoints.ORDENS.DOWNLOAD_FILE, {
        params: { fileid: data.fileid },
        responseType: 'blob',
      }),
    {
      onSuccess: (res, variables) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', variables.filename);
        document.body.appendChild(link);
        link.click();
      },
      onError: (err) => notification.error({ message: err.response?.data?.Message }),
    }
  );
};
export const DeleteFile = () => {
  const queryClient = useQueryClient();
  return useMutation<number, AxiosError<any>, DeleteFileOrdem>(
    (data) => fetcher<number>(apiEndpoints.ORDENS.DELETE_ARQUIVO, { params: { arquivoid: data.fileID } }),
    {
      onSuccess: (_, variables) => {
        console.log(variables);
        notification.success({ message: 'Arquivo excluído com sucesso!' });
        queryClient.invalidateQueries(['ordemEdit', String(variables.ordemID)]);
        queryClient.invalidateQueries(['folhaEdit', String(variables.ordemID)]);
        queryClient.invalidateQueries(['transferencia', String(variables.ordemID)]);
        queryClient.invalidateQueries(['pedido', String(variables.ordemID)]);
        queryClient.invalidateQueries(['ordemEdit', String(variables.cod)]);
        queryClient.invalidateQueries(['folhaEdit', String(variables.cod)]);
        queryClient.invalidateQueries(['transferencia', String(variables.cod)]);
        queryClient.invalidateQueries(['pedido', String(variables.cod)]);
      },
      onError: (err) => notification.error({ message: err.response?.data?.Message }),
    }
  );
};
export const AddNotaFiscal = () => {
  const queryClient = useQueryClient();
  return useMutation<IOrdemForm, AxiosError<any>, AddNotaFiscalOrdem>(
    (data) => poster<IOrdemForm>(apiEndpoints.ORDENS.ADD_NF_ORDEM, data),
    {
      onSuccess: (res) => {
        notification.success({ message: 'Nota fiscal adicionada com sucesso!' });
        queryClient.invalidateQueries(['ordemEdit', String(res.id)]);
        queryClient.invalidateQueries(['ordemEdit', String(res.cod)]);
      },
      onError: (err) => notification.error({ message: err.response?.data?.Message }),
    }
  );
};
export const DeleteNotaFiscal = () => {
  const queryClient = useQueryClient();
  return useMutation<IOrdemForm, AxiosError<any>, DeleteNotaFiscalOrdem>(
    (data) => fetcher<IOrdemForm>(apiEndpoints.ORDENS.DELETE_NF_ORDEM, { params: data }),
    {
      onSuccess: (res) => {
        notification.success({ message: 'Nota fiscal excluída com sucesso!' });
        queryClient.invalidateQueries(['ordemEdit', String(res.id)]);
        queryClient.invalidateQueries(['ordemEdit', String(res.cod)]);
      },
      onError: (err) => notification.error({ message: err.response?.data?.Message }),
    }
  );
};
export const EditNotaFiscal = () => {
  const queryClient = useQueryClient();
  return useMutation<IOrdemForm, AxiosError<any>, NotaFiscalOrdem>(
    (data) => poster<IOrdemForm>(apiEndpoints.ORDENS.EDIT_NF_ORDEM, data),
    {
      onSuccess: (_, variables) => {
        notification.success({ message: 'Nota fiscal alterada com sucesso!' });
        queryClient.invalidateQueries(['ordemEdit', String(variables.ordemID)]);
      },
      onError: (err) => notification.error({ message: err.response?.data?.Message }),
    }
  );
};
export const CompilarArquivosOrdem = () => {
  const queryClient = useQueryClient();
  return useMutation<IOrdemForm, AxiosError<any>, CompilarArquivosOrdemModel>(
    (data) => poster<IOrdemForm>(apiEndpoints.ORDENS.COMPILAR, data),
    {
      onSuccess: (res) => {
        notification.success({ message: 'Arquivos compilados com sucesso!' });
        queryClient.invalidateQueries(['ordemEdit', String(res.id)]);
        queryClient.invalidateQueries(['ordemEdit', String(res.cod)]);
      },
      onError: (err) => notification.error({ message: err.response?.data?.Message }),
    }
  );
};
export const GetOrdemEdit = (id: string, contaID?: string) => {
  return useQuery<IOrdemForm, AxiosError<any>>(
    ['ordemEdit', String(id)],
    () =>
      fetcher<IOrdemForm>(apiEndpoints.ORDENS.GET_ORDEM, {
        params: {
          id: Number(id) || null,
          cod: id,
          contaID: contaID,
        },
      }),
    {
      enabled: !!id,
      retry: false,
      staleTime: 1000 * 60 * 1,
      onError: (err) => {
        notification.error({ message: err?.response?.data });
      },
    }
  );
};
