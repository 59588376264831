import { Button, Card, Col, Popconfirm, Row, Steps, Typography, Upload, List, Table, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  GetFolha,
  SetSelectedArquivos,
  UploadFilesFolha,
  CompilarArquivosFolha,
  DownloadArquivo,
  DeleteArquivoFolha,
  ClearFolha,
} from '@actions/folhasActions';
import { GetAprovadores, SetPageHeader } from '@actions/userActions';
import { Permission } from '@hooks//usePermissions';
import FolhaPrint from '../FolhaPrint';
import moment from 'moment';
import { UploadOutlined, DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { ArquivoOrdem } from '@models/ArquivoOrdem';
import MenuFolha from './MenuFolha';
import { ordemType } from '@constants/ordemStatus';

function FolhaView() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { folha, loadingFolha, savingFiles, loadingFiles, compilando, selectedFiles } = useAppSelector(
    (state) => state.folhas
  );
  const { aprovadores, user } = useAppSelector((state) => state.user);
  const [files, upFile] = useState([]);
  const hasSelected: boolean = selectedFiles.length > 0;
  const rowSelection = {
    onChange: (selectedRowKeys: any[]) => {
      dispatch(SetSelectedArquivos(selectedRowKeys));
    },
    selectedRowKeys: selectedFiles,
  };

  useEffect(() => {
    dispatch(GetFolha(Number(id)));
    dispatch(ClearFolha());
    dispatch(GetAprovadores(ordemType.folha));
  }, [dispatch, id]);
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Engenharia',
        backbutton: true,
        extras: [<MenuFolha key={0} />],
        subtitle: 'Imprimir Folha',
        menu: 'folhas',
      })
    );
  }, [dispatch, folha, loadingFolha, id]);
  return (
    <div>
      <Spin spinning={loadingFolha}>
        <Typography.Text>Criador: {folha ? folha.criador : null}</Typography.Text>
        <Steps direction="horizontal" size="small">
          <Steps.Step
            title="Aprovador 1"
            description={
              folha ? (
                folha.aprovacao1 ? (
                  folha.aprovador1
                ) : (
                  <div>
                    {aprovadores.length > 0
                      ? aprovadores.filter((c) => c.nivelFolhas === 1).map((c) => <div>{c.nome}</div>)
                      : null}
                  </div>
                )
              ) : null
            }
            status={folha ? (folha.aprovacao1 ? 'finish' : 'wait') : null}
          />
          <Steps.Step
            title="Aprovador 2"
            description={
              folha ? (
                folha.aprovacao2 ? (
                  folha.aprovador2
                ) : (
                  <div>
                    {aprovadores.length > 0
                      ? aprovadores.filter((c) => c.nivelFolhas === 2).map((c) => <div>{c.nome}</div>)
                      : null}
                  </div>
                )
              ) : null
            }
            status={folha ? (folha.aprovacao2 ? 'finish' : 'wait') : null}
          />
          <Steps.Step
            title="Aprovador 3"
            description={
              folha ? (
                folha.aprovacao3 ? (
                  folha.aprovador3
                ) : (
                  <div>
                    {aprovadores.length > 0
                      ? aprovadores.filter((c) => c.nivelFolhas === 3).map((c) => <div>{c.nome}</div>)
                      : null}
                  </div>
                )
              ) : null
            }
            status={folha ? (folha.aprovacao3 ? 'finish' : 'wait') : null}
          />
        </Steps>
        <FolhaPrint folha={folha} customLogo={user.configuracao.logo} />
        <Row>
          <Col span={12}>
            <Card title="Arquivos" size="small" style={{ marginTop: 10 }}>
              <Permission permission="ordem.file.upload">
                <Upload
                  name="arquivo"
                  disabled={savingFiles}
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  multiple={true}
                  beforeUpload={(_, fileList) => {
                    upFile([...files, ...fileList]);
                    Promise.resolve(false);
                  }}
                  accept=".pdf,.jpg,.jpeg,.png,.xls,.xlsx"
                  onRemove={(file) => {
                    Promise.resolve(false);
                    upFile(files.filter((f) => f !== file));
                  }}
                  fileList={files}
                >
                  <Button icon={<UploadOutlined />} size="small">
                    Escolher Arquivos
                  </Button>
                </Upload>
                {files.length > 0 && (
                  <Button
                    type="primary"
                    size="small"
                    loading={savingFiles}
                    onClick={() => {
                      const formdata = new FormData();
                      files.map((f) => formdata.append('files', f, f.name));
                      formdata.append('ordemid', id);
                      dispatch(UploadFilesFolha(formdata));
                      upFile([]);
                    }}
                  >
                    Enviar
                  </Button>
                )}
              </Permission>
              <Permission permission="ordem.file.upload">
                <Button
                  type="primary"
                  size="small"
                  disabled={!hasSelected}
                  loading={compilando}
                  onClick={() => {
                    dispatch(CompilarArquivosFolha(folha.id, selectedFiles));
                  }}
                >
                  Compilar
                </Button>
              </Permission>
              <Table
                size="small"
                rowKey="id"
                loading={loadingFiles}
                dataSource={folha?.arquivoOrdem}
                pagination={{ pageSize: 5 }}
                rowSelection={rowSelection}
              >
                <Table.Column
                  render={(_, row: ArquivoOrdem) => (
                    <div>
                      <Permission permission="ordem.file.download">
                        <Button
                          onClick={() => dispatch(DownloadArquivo(row.id, row.fileName))}
                          icon={<DownloadOutlined />}
                          size="small"
                        />
                      </Permission>
                      <Permission permission="ordem.file.delete">
                        <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(DeleteArquivoFolha(row.id))}>
                          <Button icon={<DeleteOutlined />} size="small" />
                        </Popconfirm>
                      </Permission>
                    </div>
                  )}
                />
                <Table.Column title="Nome do Arquivo" dataIndex="fileName" />
              </Table>
            </Card>
          </Col>
          <Col span={12}>
            <Card title="Histórico" size="small" style={{ marginTop: 10 }}>
              <List
                dataSource={folha ? folha.historicoOrdem : []}
                renderItem={(item) => (
                  <List.Item.Meta
                    title={item.nota}
                    description={`${item.usuario} - ${moment(item.data).format('DD/MM/YYYY HH:mm:SS')}`}
                  >
                    Conteudo
                  </List.Item.Meta>
                )}
              ></List>
            </Card>
          </Col>
        </Row>
      </Spin>
    </div>
  );
}
export default FolhaView;
