import { OrdemStatus } from '@constants/ordemStatus';
import { Button } from 'antd';
import { useOrdemForm } from './useOrdemForm';

function Navigation() {
  const { form, step, previous, isFetching, numSteps, ordem } = useOrdemForm();
  return (
    <div style={{ display: 'flex', justifyContent: 'right', gap: '8px 8px' }}>
      <Button
        disabled={
          step === 0 || !(ordem?.situacao === OrdemStatus.Rascunho || ordem?.situacao === OrdemStatus.PendenteAprovacao)
        }
        onClick={previous}
      >
        Voltar
      </Button>
      <Button
        type="primary"
        onClick={() => form.submit()}
        loading={isFetching}
        disabled={
          step === numSteps - 1 ||
          !(
            (step === 0 && !ordem?.id) ||
            ordem?.situacao === OrdemStatus.Rascunho ||
            ordem?.situacao === OrdemStatus.PendenteAprovacao
          )
        }
      >
        Avançar
      </Button>
    </div>
  );
}

export default Navigation;
