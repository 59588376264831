import { useAppSelector } from '@config/reduxStore';
import { Pagination } from '@models/General';
import { DashboardFolhaPorFornecedor } from '@reducers/folhasReducer';
import { getColumnSearchProps, sortAlpha } from '@utils/filterandsort';
import { moneyMask } from '@utils/masks';
import { Table } from 'antd';
import { useState } from 'react';
import { ModoDashboard } from './DashboardFolhas';

function TablePorFornecedor({ modo }: { modo: ModoDashboard }) {
  const [filtersFornecedor, setFiltersFornecedor] = useState<Pagination<any>>(null);
  const { dashboardPorFornecedor, funcoesDashboard, fornecedoresDashboard } = useAppSelector((state) => state.folhas);

  return (
    <Table
      size="small"
      rowKey="id"
      pagination={false}
      dataSource={dashboardPorFornecedor.filter((c: any) => c.obras > 0)}
      rowClassName={(row) => `rownivel${row.nivel}`}
      scroll={{ x: 1000, y: 'calc(100vh - 200px' }}
      onChange={(pagination, filters, sorter) => setFiltersFornecedor({ pagination, filters, sorter })}
      summary={(data) => (
        <Table.Summary fixed>
          <Table.Summary.Row style={{ fontWeight: 'bold' }}>
            <Table.Summary.Cell index={0} colSpan={4} align="right">
              TOTAL
            </Table.Summary.Cell>
            <Table.Summary.Cell index={1} align="right">
              {moneyMask.format(data.reduce((a, b) => a + b.adiantamento, 0))}
            </Table.Summary.Cell>
            {modo === 'Medição' && (
              <Table.Summary.Cell index={2} align="right">
                {moneyMask.format(data.reduce((a, b) => a + b.subtotal, 0))}
              </Table.Summary.Cell>
            )}
            {modo === 'Medição' && (
              <Table.Summary.Cell index={3} align="right">
                {moneyMask.format(data.reduce((a, b) => a + b.total, 0))}
              </Table.Summary.Cell>
            )}
          </Table.Summary.Row>
        </Table.Summary>
      )}
    >
      <Table.Column title="" render={(_, __, index) => index + 1} align="center" width={100} />
      <Table.Column
        title="Fornecedor"
        dataIndex="fornecedor"
        ellipsis
        sorter={(a, b) => sortAlpha(a.fornecedor, b.fornecedor)}
        {...getColumnSearchProps(
          'fornecedor',
          'customselect',
          'Status',
          filtersFornecedor,
          fornecedoresDashboard.map((f) => ({ text: f.nome, value: f.nome }))
        )}
      />
      <Table.Column
        title="Função"
        dataIndex="funcao"
        align="center"
        sorter={(a, b) => sortAlpha(a.funcao, b.funcao)}
        {...getColumnSearchProps(
          'funcao',
          'customselect',
          'Função',
          filtersFornecedor,
          funcoesDashboard.map((f) => ({ text: f, value: f }))
        )}
      />
      <Table.Column
        title="Nº Obras"
        dataIndex="obras"
        width={90}
        align="center"
        sorter={(a, b: DashboardFolhaPorFornecedor) => a.obras - b.obras}
      />
      <Table.Column
        title="Adiantamento"
        dataIndex="adiantamento"
        render={(v) => moneyMask.format(v)}
        align="right"
        sorter={(a, b: DashboardFolhaPorFornecedor) => a.adiantamento - b.adiantamento}
      />
      {modo === 'Medição' && (
        <Table.Column
          title="Medição"
          dataIndex="subtotal"
          render={(v) => moneyMask.format(v)}
          align="right"
          sorter={(a, b: DashboardFolhaPorFornecedor) => a.subtotal - b.subtotal}
        />
      )}
      {modo === 'Medição' && (
        <Table.Column
          title="Total"
          dataIndex="total"
          render={(v) => moneyMask.format(v)}
          align="right"
          sorter={(a, b: DashboardFolhaPorFornecedor) => a.total - b.total}
        />
      )}
    </Table>
  );
}

export default TablePorFornecedor;
