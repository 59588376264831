import { ViewCancelForm } from '@actions/novaOrdemActions';
import LoadingText from '@components/LoadingText';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { OrdemStatus } from '@constants/ordemStatus';
import { HasPermission, HasRole } from '@hooks/usePermissions';
import {
  FinalizarOrdem,
  SubmeterOrdem,
  CancelarOrdem,
  EnviarParaPagamentoOrdem,
  GetOrdemEdit,
  AprovarOrdem,
} from '@services/api/api.ordem';
import { Button, Popconfirm, Space } from 'antd';
import { useState } from 'react';
import PagamentoParcialModal from './PagamentoParcialModal';
import { useParams } from 'react-router-dom';
import CancelOrdemModal from './OrdemForm.CancelarModal';

function OrdemActions() {
  const { id } = useParams();
  const { data: ordem, isLoading, isFetching } = GetOrdemEdit(id);
  const { ordensPendentes } = useAppSelector((state) => state.novaOrdem);
  const submeterOrdem = SubmeterOrdem();
  const cancelarOrdem = CancelarOrdem();
  const finalizarOrdem = FinalizarOrdem();
  const enviarPagamento = EnviarParaPagamentoOrdem();
  const aprovarOrdem = AprovarOrdem();
  const [pagamentoParcialModal, setPagamentoParcialModal] = useState(false);
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  if (isLoading || isFetching) return <LoadingText text="Carregando" />;
  return (
    <Space>
      <PagamentoParcialModal visible={pagamentoParcialModal} setVisible={setPagamentoParcialModal} />
      <CancelOrdemModal />
      {ordem?.situacao === OrdemStatus.Rascunho && (
        <Button
          type="primary"
          size="small"
          loading={submeterOrdem.isLoading}
          onClick={() => submeterOrdem.mutate({ id: ordem.id })}
        >
          Submeter
        </Button>
      )}
      {ordem?.situacao === OrdemStatus.PendenteAprovacao &&
        HasRole(['aprovador'], user.roles) &&
        ordensPendentes.some((c) => c.id === Number(id) || c.cod === id) && (
          <Button
            type="primary"
            size="small"
            loading={aprovarOrdem.isLoading}
            onClick={() => aprovarOrdem.mutate({ id: Number(id), cod: id })}
          >
            Aprovar
          </Button>
        )}
      {ordem?.situacao === OrdemStatus.Aprovada && (
        <Button
          type="primary"
          size="small"
          loading={enviarPagamento.isLoading}
          onClick={() => enviarPagamento.mutate({ id: ordem.id, cod: ordem.cod })}
        >
          Enviar para Pagamento
        </Button>
      )}
      {(ordem?.situacao === OrdemStatus.PendentePagamento || ordem?.situacao === OrdemStatus.PagamentoParcial) &&
        HasRole(['financeiro', 'master'], user.roles) && (
          <Button
            type="primary"
            size="small"
            loading={enviarPagamento.isLoading}
            onClick={() => setPagamentoParcialModal(true)}
          >
            Pagamento Parcial
          </Button>
        )}

      {ordem?.situacao === OrdemStatus.PendenteDocumentos && <Button type="primary">Finalizar</Button>}
      {(ordem?.situacao === OrdemStatus.PendenteDocumentos ||
        ordem?.situacao === OrdemStatus.PendentePagamento ||
        ordem?.situacao === OrdemStatus.PagamentoParcial) &&
        HasPermission('ordem.financeiro', user.permissions) && (
          <Popconfirm title="Está certo disso?" onConfirm={() => finalizarOrdem.mutate({ id: ordem.id })}>
            <Button type="primary" loading={finalizarOrdem.isLoading} size="small">
              Finalizar
            </Button>
          </Popconfirm>
        )}
      {(ordem?.situacao === OrdemStatus.Rascunho ||
        ordem?.situacao === OrdemStatus.PendenteAprovacao ||
        HasPermission('ordem.cancel', user.permissions)) &&
        ordem?.situacao !== OrdemStatus.Cancelada && (
          <Button
            danger
            size="small"
            loading={cancelarOrdem.isLoading}
            onClick={() => {
              dispatch(ViewCancelForm({ view: true, id: ordem.id, replace: false, cod: ordem.cod }));
            }}
          >
            Cancelar
          </Button>
        )}
    </Space>
  );
}

export default OrdemActions;
