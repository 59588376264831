import { AppDispatch } from '@config/reduxStore';
import { apiEndpoints } from '@constants/apiEndpoints';
import { Axios } from '@config/axios';
export const GetInvestimentos = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_INVESTIMENTOS',
      payload: Axios.get(apiEndpoints.INVESTIDOR.GET_INVESTIMENTOS),
    });
  };
};
export const GetRelatorio = function (id: number | string) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_RELATORIO',
      payload: Axios.get(apiEndpoints.INVESTIDOR.GET_RELATORIO, { params: { id } }),
    });
  };
};
