import { ExtendedAction } from '@config/reduxStore';
import { Composicao } from '@models/Composicao';
import { notification } from 'antd';

interface ComposicoesReducer {
  composicoes: Composicao[];
  loadingcomposicoes: boolean;
  composicao: Composicao;
  composicaoform: boolean;
  savingcomposicao: boolean;
}
var initialState: ComposicoesReducer = {
  composicoes: [],
  loadingcomposicoes: false,
  composicao: null,
  composicaoform: false,
  savingcomposicao: false,
};

const composicoesReducer = function (state = initialState, action: ExtendedAction) {
  switch (action.type) {
    case 'DELETE_COMPOSICAO_PENDING':
      state = {
        ...state,
        loadingcomposicoes: true,
      };
      break;
    case 'DELETE_COMPOSICAO_REJECTED':
      state = {
        ...state,
        loadingcomposicoes: false,
      };
      notification.error({ message: 'Erro ao remover composição!' });
      break;
    case 'DELETE_COMPOSICAO_FULFILLED':
      state = {
        ...state,
        loadingcomposicoes: false,
        composicoes: action.payload.data,
      };
      notification.success({ message: 'Composição removida com sucesso!' });
      break;
    case 'SALVAR_COMPOSICAO_PENDING':
      state = {
        ...state,
        savingcomposicao: true,
      };
      break;
    case 'SALVAR_COMPOSICAO_REJECTED':
      state = {
        ...state,
        savingcomposicao: false,
      };
      notification.error({ message: 'Erro ao criar composição!' });
      break;
    case 'SALVAR_COMPOSICAO_FULFILLED':
      state = {
        ...state,
        savingcomposicao: false,
        composicoes: action.payload.data,
      };
      notification.success({ message: 'Composição alterada com sucesso!' });
      break;
    case 'ADD_COMPOSICAO_PENDING':
      state = {
        ...state,
        savingcomposicao: true,
      };
      break;
    case 'ADD_COMPOSICAO_REJECTED':
      state = {
        ...state,
        savingcomposicao: false,
      };
      notification.error({ message: 'Erro ao criar composição!' });
      break;
    case 'ADD_COMPOSICAO_FULFILLED':
      state = {
        ...state,
        savingcomposicao: false,
        composicoes: action.payload.data,
      };
      notification.success({ message: 'Composição criada com sucesso!' });
      break;
    case 'GET_COMPOSICOES_PENDING':
      state = {
        ...state,
        loadingcomposicoes: true,
      };
      break;
    case 'GET_COMPOSICOES_REJECTED':
      state = {
        ...state,
        loadingcomposicoes: false,
      };
      notification.error({ message: 'Erro ao atualizar composições!' });
      break;
    case 'GET_COMPOSICOES_FULFILLED':
      state = {
        ...state,
        loadingcomposicoes: false,
        composicoes: action.payload.data,
      };
      break;
    case 'COMPOSICAO_FORM':
      state = {
        ...state,
        composicaoform: action.payload.visible,
        composicao: action.payload.composicao,
      };
      break;
    default:
      return state;
  }
  return state;
};

export default composicoesReducer;
