import { Modal, Table } from 'antd';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { GetAditivos } from '@actions/casasActions';
import moment from 'moment';
import { moneyNegMask } from '@utils/masks';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

function AditivosModal({
  visible,
  setVisible,
  casa,
  obra,
}: {
  visible: boolean;
  obra: null;
  casa: number;
  setVisible: Dispatch<SetStateAction<{ visible: boolean; obra: number; casa: number }>>;
}) {
  const dispatch = useAppDispatch();
  const { aditivos, loadingAditivos } = useAppSelector((state) => state.casas);
  useEffect(() => {
    dispatch(GetAditivos(casa));
  }, [casa, dispatch]);

  return (
    <Modal
      open={visible}
      okButtonProps={{ hidden: true }}
      cancelText="Fechar"
      onCancel={() => setVisible({ visible: false, obra: null, casa: null })}
      title={`Aditivos - ${obra}`}
    >
      <Table loading={loadingAditivos} size="small" dataSource={aditivos} rowKey="id">
        <Table.Column title="Data" dataIndex="data" render={(v) => moment(v).format('DD/MM/YYYY')} />
        <Table.Column
          title="Valor"
          dataIndex="valor"
          width={100}
          align="right"
          render={(v) => moneyNegMask.format(v)}
        />
        <Table.Column title="Notas" dataIndex="notas" />
      </Table>
    </Modal>
  );
}

export default AditivosModal;
