import { IFolhaItem } from '@models/Folha';
import { GetOrcamentosRealizado } from '@services/api/api.orcamento';
import { doubleMask2, moneyMask } from '@utils/masks';
import { Alert, Button, Form, Input, InputNumber, Skeleton, Table, Typography } from 'antd';
import { useFolhaForm } from './useFolhaForm';
import { DeleteOutlined } from '@ant-design/icons';
import { RemoveItemFolha } from '@services/api/api.folha';

function FolhaFormRateioItens() {
  const { form, folha, isFetching } = useFolhaForm();
  const { data: orcamento, isLoading: loadingOrcamento } = GetOrcamentosRealizado({
    orcamentoID: folha?.orcamentoID,
    ativo: true,
    tipo: 1,
  });
  const removeItemFolha = RemoveItemFolha();

  if (loadingOrcamento) return <Skeleton active />;
  return (
    <div>
      <Alert
        message="Defina o avanço para cada item, em seguida clique na aba Rateio"
        type="info"
        showIcon
        closable
        style={{ width: 'fit-content', float: 'right', margin: 5 }}
      />
      <Form.List name="itens">
        {(fields) => (
          <Table
            size="small"
            dataSource={form.getFieldValue('itens')}
            rowKey="id"
            scroll={{ y: 300 }}
            loading={removeItemFolha.isLoading || isFetching}
            pagination={false}
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row style={{ fontWeight: 'bold' }}>
                  <Table.Summary.Cell index={0}>
                    <Form.Item noStyle shouldUpdate>
                      {({ getFieldValue }) => (
                        <Typography.Text>{`${getFieldValue('itens').length} Itens`}</Typography.Text>
                      )}
                    </Form.Item>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} colSpan={9} align="right">
                    TOTAL
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3} align="right">
                    <Form.Item noStyle shouldUpdate>
                      {({ getFieldValue }) => {
                        return (
                          <Typography.Text>
                            {moneyMask.format(
                              getFieldValue('itens').reduce((a: number, b: IFolhaItem) => a + b.valorMedir, 0)
                            )}
                          </Typography.Text>
                        );
                      }}
                    </Form.Item>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
          >
            <Table.Column dataIndex="id" align="center" width={60} render={(_, __, index) => index + 1} />
            <Table.Column
              dataIndex="id"
              width={60}
              align="center"
              render={(v) => (
                <>
                  <Button
                    size="small"
                    icon={<DeleteOutlined />}
                    onClick={() => removeItemFolha.mutate({ folhaID: folha.id, ordemItemID: v, cod: folha.cod })}
                  />
                </>
              )}
            />
            <Table.Column
              title="Categoria"
              dataIndex="id"
              render={(v) => orcamento.orcamentosItens.find((c) => c.id === v).categoria as string}
            />
            <Table.Column
              title="Grupo"
              dataIndex="id"
              render={(v) => orcamento.orcamentosItens.find((c) => c.id === v).grupo as string}
            />
            <Table.Column
              title="Item"
              dataIndex="id"
              render={(v) => orcamento.orcamentosItens.find((c) => c.id === v).item as string}
            />
            <Table.Column
              title="Unidade"
              dataIndex="id"
              width={80}
              render={(v) => orcamento.orcamentosItens.find((c) => c.id === v).unidade as string}
            />
            <Table.Column
              title="Orçado"
              dataIndex="id"
              align="right"
              render={(v) => moneyMask.format(orcamento.orcamentosItens.find((c) => c.id === v).valorTotal)}
            />
            <Table.Column
              title="Realizado"
              dataIndex="id"
              align="right"
              render={(v, _, index) => {
                const item = orcamento.orcamentosItens.find((c) => c.id === v);
                form.setFieldValue(
                  ['itens', fields[index].name, 'avanco'],
                  (100 * item.realizadoValor) / item.valorTotal
                );

                return `${moneyMask.format(item.realizadoValor)} (${doubleMask2.format(
                  (100 * item.realizadoValor) / item.valorTotal
                )})`;
              }}
            />
            <Table.Column
              title="Saldo"
              dataIndex="id"
              align="right"
              render={(v) => {
                const item = orcamento.orcamentosItens.find((c) => c.id === v);
                return `${moneyMask.format(item.valorTotal - item.realizadoValor)} (${doubleMask2.format(
                  100 - (100 * item.realizadoValor) / item.valorTotal
                )})`;
              }}
            />
            <Table.Column
              title="Medir (%) "
              dataIndex="id"
              align="right"
              render={(v, __, index) => (
                <Form.Item shouldUpdate noStyle>
                  {({ setFieldValue }) => {
                    const item = orcamento.orcamentosItens.find((c) => c.id === v);
                    return (
                      <Form.Item
                        name={[fields[index].name, 'medir']}
                        initialValue={Math.ceil(((100 * item.realizadoValor) / item.valorTotal) * 100) / 100}
                        rules={[
                          () => ({
                            validator(_, value) {
                              if (value === 0) {
                                return Promise.reject('Valor não pode ser zero');
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <InputNumber
                          min={Math.ceil(((100 * item.realizadoValor) / item.valorTotal) * 100) / 100}
                          max={100}
                          precision={2}
                          decimalSeparator=","
                          onChange={(v) =>
                            setFieldValue(
                              ['itens', fields[index].name, 'valorMedir'],
                              ((v - (100 * item.realizadoValor) / item.valorTotal) * item.valorTotal) / 100
                            )
                          }
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              )}
            />
            <Table.Column
              title="Valor Medição"
              dataIndex="id"
              align="right"
              render={(v, __, index) => {
                const item = orcamento.orcamentosItens.find((c) => c.id === v);
                return (
                  <>
                    <Form.Item
                      hidden
                      name={[fields[index].name, 'avanco']}
                      initialValue={(100 * item.realizadoValor) / item.valorTotal}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item noStyle shouldUpdate>
                      {({ getFieldValue }) => {
                        return (
                          <Form.Item name={[fields[index].name, 'valorMedir']}>
                            <Typography.Text>
                              {moneyMask.format(getFieldValue(['itens', fields[index].name, 'valorMedir']))}
                            </Typography.Text>
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </>
                );
              }}
            />
          </Table>
        )}
      </Form.List>
    </div>
  );
}

export default FolhaFormRateioItens;
