import { AppDispatch } from '@config/reduxStore';
import { apiEndpoints } from '@constants/apiEndpoints';
import { HistoricoChamado } from '@models/HistoricoChamado';
import { Reclamacao } from '@models/Reclamacao';
import { Axios } from '@config/axios';

export const GetChamados = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_CHAMADOS',
      payload: Axios.get(apiEndpoints.CHAMADOS.GET_CHAMADOS),
    });
  };
};
export const ShowGarantias = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SHOW_GARANTIAS',
    });
  };
};
export const ShowLembrete = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SHOW_LEMBRETE',
    });
  };
};
export const SendLembrete = function (values: {
  para: string;
  chamadoID: number;
  corpo: string;
  assunto: string;
  titulo: string;
  data: Date;
  local: string;
}) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SEND_LEMBRETE',
      payload: Axios.post(apiEndpoints.CHAMADOS.SEND_LEMBRETE, values),
    });
  };
};
export const GetChamado = function (id: number | string) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_CHAMADO',
      payload: Axios.get(apiEndpoints.CHAMADOS.GET_CHAMADO, { params: { id } }),
    });
  };
};

export const GetResponsaveis = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_RESPONSAVEIS',
      payload: Axios.get(apiEndpoints.CHAMADOS.GET_RESPONSAVEIS),
    });
  };
};
export const DeleteHistorico = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DELETE_HISTORICO',
      payload: Axios.get(apiEndpoints.CHAMADOS.DELETE_HISTORICO, { params: { id } }),
    });
  };
};
export const DeleteChamado = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DELETE_CHAMADO',
      payload: Axios.get(apiEndpoints.CHAMADOS.DELETE_CHAMADO, { params: { id } }),
    });
  };
};
export const AddHistorico = function (values: HistoricoChamado) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'ADD_HISTORICO',
      payload: Axios.post(apiEndpoints.CHAMADOS.ADD_HISTORICO, { ...values }),
    });
  };
};
export const SalvarAvaliacao = function (values: { nota1: string; nota2: string; nota3: string }) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SALVAR_AVALIACAO',
      payload: Axios.post(apiEndpoints.CHAMADOS.SALVAR_AVALIACAO, { ...values }),
    });
  };
};
export const SalvarGeral = function (values: {
  contato: string;
  dataChamado: Date;
  dataVisita: Date;
  dataConc: Date;
  responsavel: string;
  notas: string;
  id: number;
}) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'SALVAR_GERAL',
      payload: Axios.post(apiEndpoints.CHAMADOS.SALVAR_GERAL, { ...values }),
    });
  };
};

export const AtualizarReclamacao = function (values: Reclamacao) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'ATUALIZAR_RECLAMACAO',
      payload: Axios.post(apiEndpoints.CHAMADOS.ATUALIZAR_RECLAMACAO, { ...values }),
    });
  };
};
export const DeletarReclamacao = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DELETAR_RECLAMACAO',
      payload: Axios.get(apiEndpoints.CHAMADOS.DELETAR_RECLAMACAO, { params: { id } }),
    });
  };
};
export const AddReclamacao = function (values: Reclamacao) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'ADD_RECLAMACAO',
      payload: Axios.post(apiEndpoints.CHAMADOS.ADD_RECLAMACAO, { ...values }),
    });
  };
};
export const UploadFiles = function (form: FormData) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'UPLOAD_FILES_CHAMADO',
      payload: Axios.post(apiEndpoints.CHAMADOS.POST_FILE, form),
    });
  };
};
export const DownloadArquivo = function (fileid: number, filename: string) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DOWNLOAD_FILE_CHAMADO',
      payload: Axios.get(apiEndpoints.CHAMADOS.DOWNLOAD, { params: { fileid }, responseType: 'blob' }).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      }),
    });
  };
};
export const UpdateReclamacao = function (values: Reclamacao) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'UPDATE_RECLAMACAO',
      payload: values,
    });
  };
};
export const DeleteArquivo = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DELETE_FILE_CHAMADO',
      payload: Axios.post(apiEndpoints.CHAMADOS.DELETE_ARQUIVO, { arquivoid: id }),
    });
  };
};
export const ConcluirChamado = function (values: { dataConc: Date; concluido: boolean; id: number }) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'CONCLUIR_CHAMADO',
      payload: Axios.post(apiEndpoints.CHAMADOS.CONCLUIR_CHAMADO, { ...values }),
    });
  };
};
export const VisitarChamado = function (values: { dataVisita: Date; visitaFeita: boolean; id: number }) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'VISITAR_CHAMADO',
      payload: Axios.post(apiEndpoints.CHAMADOS.VISITA_FEITA_CHAMADO, { ...values }),
    });
  };
};
export const AvisarEncerramento = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'AVISAR_ENCERRAMENTO',
      payload: Axios.get(apiEndpoints.CHAMADOS.AVISO_ENCERRAMENTO, { params: { id } }),
    });
  };
};
