import { moneyMask } from '@utils/masks';
import { Card, Descriptions, Form, Input, Typography } from 'antd';
import TransferenciaStatusTag from '../Shared/TransferenciaStatusTag';

function TransferenciaFormResumo() {
  return (
    <Card title="Resumo" size="small">
      <Form.Item noStyle shouldUpdate={(nv, pv) => nv.ordemItens !== pv.ordemItens}>
        {({ getFieldValue }) => (
          <Descriptions bordered size="small" column={1}>
            <Descriptions.Item label="Transferência Nº">
              <Form.Item name="cod" valuePropName="children" noStyle>
                <Typography.Text />
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label="Situação">
              <Form.Item name="situacao" noStyle>
                <TransferenciaStatusTag />
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label="Valor Total">
              {moneyMask.format(
                !!getFieldValue('ordemItens') &&
                  getFieldValue('ordemItens')?.reduce(
                    (a: number, b: any) => a + (b.valorTotal ? moneyMask.normalize(b.valorTotal) : 0),
                    0
                  )
              )}
            </Descriptions.Item>
          </Descriptions>
        )}
      </Form.Item>
      <Form.Item name="observacao" label="Observação">
        <Input.TextArea rows={3} placeholder="Observações gerais" />
      </Form.Item>
    </Card>
  );
}

export default TransferenciaFormResumo;
