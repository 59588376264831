import { useEffect } from 'react';
import { bloquearGrupo, bloquearMeta, deleteMeta, getMetas } from '@actions/metasActions';
import { SetPageHeader } from '@actions/userActions';
import moment from 'moment';
import { Button, DatePicker, Input, Popconfirm, Space, Table, Tooltip } from 'antd';
import { useState } from 'react';
import { PlusOutlined, DeleteOutlined, EditOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import NovaMetaModal from './NovaMetaModal';
import { doubleMask1, moneyMask, moneyNegMask } from '@utils/masks';
import { filterobject, naturalSorter } from '@utils/filterandsort';
import { Permission } from '@hooks//usePermissions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { MetaOrcamento } from '@models/MetaOrcamento';

function MetasOrcamento() {
  const dispatch = useAppDispatch();
  const { metas, loadingMetas } = useAppSelector((state) => state.metas);
  const [mes, setMes] = useState(moment());
  const [novaMeta, setNovaMeta] = useState(false);
  const [filtro, setFiltro] = useState('');
  const [item, setItem] = useState(null);
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Metas de Orçamento',
        backbutton: true,
        extras: [
          <Permission permission="metas.add">
            <Button
              icon={<PlusOutlined />}
              type="primary"
              key={0}
              onClick={() => {
                setNovaMeta(true);
                setItem(null);
              }}
            >
              Nova Meta
            </Button>
          </Permission>,
        ],
        subtitle: 'Metas para redução de custo',
        menu: 'metas',
      })
    );
  }, []);
  useEffect(() => {
    dispatch(getMetas(moment().format('YYYY-MM')));
  }, []);
  return (
    <div>
      <NovaMetaModal visible={novaMeta} setVisible={setNovaMeta} item={item} />
      <Space>
        <DatePicker.MonthPicker
          format="MM/YYYY"
          placeholder="Mês"
          value={mes}
          onChange={(v) => {
            setMes(v);
            dispatch(getMetas(v.format('YYYY-MM')));
          }}
        />
        <Input placeholder="Buscar..." value={filtro} onChange={(v) => setFiltro(v.target.value)} />
      </Space>
      <Table
        size="small"
        dataSource={[...filterobject(metas, filtro)].filter(
          (c) => moment(c.mes).month() === mes.month() && moment(c.mes).year() === mes.year()
        )}
        loading={loadingMetas}
        scroll={{ x: 1000, y: 'calc(100vh - 250px)' }}
        rowKey="id"
        summary={(data) => (
          <Table.Summary fixed>
            <Table.Summary.Row style={{ fontWeight: 'bold' }}>
              <Table.Summary.Cell index={0} colSpan={4} align="right">
                TOTAL
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} align="right">
                {moneyMask.format(data.reduce((a, b) => a + b.orcado, 0))}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} align="right">
                {moneyMask.format(data.reduce((a, b) => a + b.valor, 0))}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3} align="right">
                {doubleMask1.format(
                  (100 * (data.reduce((a, b) => a + b.orcado, 0) - data.reduce((a, b) => a + b.valor, 0))) /
                    data.reduce((a, b) => a + b.orcado, 0)
                )}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4} align="right">
                {moneyMask.format(data.reduce((a, b) => a + b.orcado, 0) - data.reduce((a, b) => a + b.valor, 0))}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5} align="right">
                <span
                  style={{
                    color:
                      data.reduce((a, b) => a + b.realizado, 0) > data.reduce((a, b) => a + b.valor, 0)
                        ? 'red'
                        : 'blue',
                  }}
                >
                  {moneyMask.format(data.reduce((a, b) => a + b.realizado, 0))}
                </span>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6} align="right">
                <span
                  style={{
                    color:
                      data.reduce((a, b) => a + b.realizado, 0) > data.reduce((a, b) => a + b.valor, 0)
                        ? 'red'
                        : 'blue',
                  }}
                >
                  {doubleMask1.format(
                    (100 * (data.reduce((a, b) => a + b.orcado, 0) - data.reduce((a, b) => a + b.realizado, 0))) /
                      data.reduce((a, b) => a + b.orcado, 0)
                  )}
                </span>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={7} align="right">
                <span
                  style={{
                    color:
                      data.reduce((a, b) => a + b.realizado, 0) > data.reduce((a, b) => a + b.valor, 0)
                        ? 'red'
                        : 'blue',
                  }}
                >
                  {moneyMask.format(data.reduce((a, b) => a + b.orcado, 0) - data.reduce((a, b) => a + b.realizado, 0))}
                </span>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      >
        <Table.Column
          dataIndex="id"
          width={130}
          align="center"
          render={(v, row: MetaOrcamento) => (
            <Space>
              <Permission permission="metas.update">
                {!row.metaBloqueada && (
                  <Button
                    size="small"
                    icon={<EditOutlined />}
                    onClick={() => {
                      setItem(row);
                      setNovaMeta(true);
                    }}
                  />
                )}
              </Permission>
              <Permission permission="metas.delete">
                <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(deleteMeta(v))}>
                  <Button size="small" icon={<DeleteOutlined />} />
                </Popconfirm>
              </Permission>
              <Permission permission="orcamento.manage">
                <Tooltip title="Bloquear Grupo">
                  <Button
                    size="small"
                    icon={
                      !row.bloqueado ? (
                        <UnlockOutlined style={{ color: 'green' }} />
                      ) : (
                        <LockOutlined style={{ color: 'red' }} />
                      )
                    }
                    onClick={() => {
                      dispatch(bloquearGrupo({ id: row.grupoID, bloqueado: !row.bloqueado, metaid: v }));
                    }}
                  />
                </Tooltip>
              </Permission>
              <Permission permission="metas.bloquear">
                <Tooltip title="Bloquear Meta">
                  <Button
                    size="small"
                    icon={
                      !row.metaBloqueada ? (
                        <UnlockOutlined style={{ color: 'green' }} />
                      ) : (
                        <LockOutlined style={{ color: 'red' }} />
                      )
                    }
                    onClick={() => {
                      dispatch(bloquearMeta({ id: row.id, bloquear: !row.metaBloqueada }));
                    }}
                  />
                </Tooltip>
              </Permission>
            </Space>
          )}
        />
        <Table.Column
          title="Orçamento"
          dataIndex="obra"
          width={250}
          ellipsis
          sorter={(a: MetaOrcamento, b: MetaOrcamento) => naturalSorter(a.obra, b.obra)}
        />
        <Table.Column title="Categoria" dataIndex="categoria" width={200} ellipsis />
        <Table.Column title="Grupo" dataIndex="grupo" width={200} ellipsis />
        <Table.Column title="Orçado" dataIndex="orcado" render={(v) => moneyMask.format(v)} align="right" />
        <Table.Column title="Meta Valor" dataIndex="valor" render={(v) => moneyMask.format(v)} align="right" />
        <Table.Column
          title="Meta %"
          dataIndex="id"
          render={(_, row: MetaOrcamento) => doubleMask1.format((100 * (row.orcado - row.valor)) / row.orcado)}
          align="right"
        />
        <Table.Column
          title="Redução"
          dataIndex="id"
          render={(_, row: MetaOrcamento) => moneyMask.format(row.orcado - row.valor)}
          align="right"
        />
        <Table.Column
          title="Realizado"
          dataIndex="realizado"
          render={(v, row: MetaOrcamento) => (
            <span style={{ color: row.realizado > row.valor ? 'red' : 'blue' }}>{moneyMask.format(v)}</span>
          )}
          align="right"
        />
        <Table.Column
          title="Resultado %"
          dataIndex="id"
          render={(_, row: MetaOrcamento) => (
            <span style={{ color: row.realizado > row.valor ? 'red' : 'blue' }}>
              {doubleMask1.format((100 * (row.orcado - row.realizado)) / row.orcado)}
            </span>
          )}
          align="right"
        />
        <Table.Column
          title="Redução Real"
          dataIndex="id"
          render={(_, row: MetaOrcamento) => (
            <span style={{ color: row.realizado > row.valor ? 'red' : 'blue' }}>
              {moneyNegMask.format(row.orcado - row.realizado)}
            </span>
          )}
          align="right"
        />
      </Table>
    </div>
  );
}
export default MetasOrcamento;
