import { ExtendedAction } from '@config/reduxStore';
import { Chamado } from '@models/Chamado';
import { User } from '@models/User';
import { notification } from 'antd';
import moment from 'moment';

interface ChamadosReducer {
  chamados: Chamado[];
  chamado: Chamado;
  responsaveis: User[];
  loadingresponsaveis: boolean;
  loadingchamados: boolean;
  savingchamados: boolean;
  feedback: string;
  savinghistorico: boolean;
  savingavaliacao: boolean;
  savinggeral: boolean;
  savingreclamacao: boolean;
  addingreclamacao: boolean;
  savinffiles: boolean;
  deletingfile: boolean;
  concluindochamado: boolean;
  visitandochamado: boolean;
  avisandoencerramento: boolean;
  garantiasmodal: boolean;
  lembretemodal: boolean;
  savinglembrete: boolean;
  savingfiles: boolean;
}

var initialState: ChamadosReducer = {
  chamados: [],
  chamado: null,
  responsaveis: [],
  loadingresponsaveis: false,
  loadingchamados: false,
  savingchamados: false,
  feedback: '',
  savinghistorico: false,
  savingavaliacao: false,
  savinggeral: false,
  savingreclamacao: false,
  addingreclamacao: false,
  savinffiles: false,
  deletingfile: false,
  concluindochamado: false,
  visitandochamado: false,
  avisandoencerramento: false,
  garantiasmodal: false,
  lembretemodal: false,
  savinglembrete: false,
  savingfiles: false,
};

const chamadosReducer = function (state = initialState, action: ExtendedAction) {
  switch (action.type) {
    case 'RESET_FEEDBACK':
      state = {
        ...state,
        feedback: '',
      };
      break;
    case 'SHOW_LEMBRETE':
      state = {
        ...state,
        lembretemodal: !state.lembretemodal,
      };
      break;
    case 'SHOW_GARANTIAS':
      state = {
        ...state,
        garantiasmodal: !state.garantiasmodal,
      };
      break;
    case 'SEND_LEMBRETE_PENDING':
      state = {
        ...state,
        savinglembrete: true,
      };
      break;
    case 'SEND_LEMBRETE_REJECTED':
      state = {
        ...state,
        savinglembrete: false,
      };
      notification.error({ message: action.payload.response.data.Message });
      break;
    case 'SEND_LEMBRETE_FULFILLED':
      state = {
        ...state,
        savinglembrete: false,
        lembretemodal: false,
      };
      notification.success({ message: 'Lembrete enviado com sucesso!' });
      break;

    case 'AVISAR_ENCERRAMENTO_PENDING':
      state = {
        ...state,
        avisandoencerramento: true,
      };
      break;
    case 'AVISAR_ENCERRAMENTO_REJECTED':
      state = {
        ...state,
        avisandoencerramento: false,
      };
      break;
    case 'AVISAR_ENCERRAMENTO_FULFILLED':
      state = {
        ...state,
        avisandoencerramento: false,
      };
      notification.success({
        message: 'Notificação enviada com sucesso! Aguarde o responsável revisar o chamado.',
      });
      break;
    case 'VISITAR_CHAMADO_PENDING':
      state = {
        ...state,
        visitandochamado: true,
      };
      break;
    case 'VISITAR_CHAMADO_REJECTED':
      state = {
        ...state,
        visitandochamado: false,
      };
      break;
    case 'VISITAR_CHAMADO_FULFILLED':
      state = {
        ...state,
        visitandochamado: false,
        chamado: {
          ...state.chamado,
          dataVisita: moment(action.payload.data.dataVisita),
          visitaFeita: action.payload.data.visitaFeita,
        },
      };
      notification.success({ message: 'Chamando encerrado com sucesso!' });
      break;
    case 'CONCLUIR_CHAMADO_PENDING':
      state = {
        ...state,
        concluindochamado: true,
      };
      break;
    case 'CONCLUIR_CHAMADO_REJECTED':
      state = {
        ...state,
        concluindochamado: false,
      };
      break;
    case 'CONCLUIR_CHAMADO_FULFILLED':
      state = {
        ...state,
        concluindochamado: false,
        chamado: {
          ...state.chamado,
          dataConc: moment(action.payload.data.dataConcluido),
          concluido: action.payload.data.concluido,
        },
      };
      notification.success({ message: 'Chamando encerrado com sucesso!' });
      break;
    case 'DELETE_FILE_CHAMADO_PENDING':
      state = {
        ...state,
        deletingfile: true,
      };
      break;
    case 'DELETE_FILE_CHAMADO_REJECTED':
      state = {
        ...state,
        deletingfile: false,
      };
      break;
    case 'DELETE_FILE_CHAMADO_FULFILLED':
      state = {
        ...state,
        deletingfile: false,
        chamado: {
          ...state.chamado,
          arquivos: state.chamado.arquivos.filter((c) => c.id !== action.payload.data),
        },
      };
      break;
    case 'UPLOAD_FILES_CHAMADO_PENDING':
      state = {
        ...state,
        savingfiles: true,
      };
      break;
    case 'UPLOAD_FILES_CHAMADO_REJECTED':
      state = {
        ...state,
        savingfiles: false,
      };
      break;
    case 'UPLOAD_FILES_CHAMADO_FULFILLED':
      state = {
        ...state,
        savingfiles: false,
        chamado: {
          ...state.chamado,
          arquivos: [...state.chamado.arquivos, ...action.payload.data],
        },
      };
      break;
    case 'UPDATE_RECLAMACAO':
      state = {
        ...state,
        chamado: {
          ...state.chamado,
          reclamacoes: state.chamado.reclamacoes.map((c) => {
            if (c.id === action.payload.id) {
              return action.payload;
            } else return c;
          }),
        },
      };
      break;
    case 'ADD_RECLAMACAO_PENDING':
      state = {
        ...state,
        addingreclamacao: true,
        feedback: '',
      };
      break;
    case 'ADD_RECLAMACAO_REJECTED':
      state = {
        ...state,
        addingreclamacao: false,
      };
      break;
    case 'ADD_RECLAMACAO_FULFILLED':
      state = {
        ...state,
        addingreclamacao: false,
        chamado: {
          ...state.chamado,
          reclamacoes: [...state.chamado.reclamacoes, action.payload.data],
        },
      };
      notification.success({ message: 'Reclamação adicionada com sucesso!' });
      break;
    case 'DELETAR_RECLAMACAO_PENDING':
      state = {
        ...state,
        savingreclamacao: true,
        feedback: '',
      };
      break;
    case 'DELETAR_RECLAMACAO_REJECTED':
      state = {
        ...state,
        savingreclamacao: false,
      };
      break;
    case 'DELETAR_RECLAMACAO_FULFILLED':
      state = {
        ...state,
        savingreclamacao: false,
        chamado: {
          ...state.chamado,
          reclamacoes: state.chamado.reclamacoes.filter((c) => c.id !== action.payload.data),
        },
      };
      notification.success({ message: 'Reclamação excluía com sucesso!' });
      break;
    case 'ATUALIZAR_RECLAMACAO_PENDING':
      state = {
        ...state,
        savingreclamacao: true,
        feedback: '',
      };
      break;
    case 'ATUALIZAR_RECLAMACAO_REJECTED':
      state = {
        ...state,
        savingreclamacao: false,
      };
      break;
    case 'ATUALIZAR_RECLAMACAO_FULFILLED':
      state = {
        ...state,
        savingreclamacao: false,
      };
      notification.success({ message: 'Reclamação atualizada com sucesso!' });
      break;
    case 'SALVAR_GERAL_PENDING':
      state = {
        ...state,
        savinggeral: true,
        feedback: '',
      };
      break;
    case 'SALVAR_GERAL_REJECTED':
      state = {
        ...state,
        savinggeral: false,
      };
      break;
    case 'SALVAR_GERAL_FULFILLED':
      state = {
        ...state,
        savinggeral: false,
      };
      notification.success({ message: 'Informações salvas com sucesso!' });
      break;
    case 'SALVAR_AVALIACAO_PENDING':
      state = {
        ...state,
        savingavaliacao: true,
        feedback: '',
      };
      break;
    case 'SALVAR_AVALIACAO_REJECTED':
      state = {
        ...state,
        savingavaliacao: false,
      };
      break;
    case 'SALVAR_AVALIACAO_FULFILLED':
      state = {
        ...state,
        savingavaliacao: false,
      };
      notification.success({ message: 'Avaliação salvo com sucesso!' });
      break;
    case 'DELETE_CHAMADO_PENDING':
      state = {
        ...state,
        loadingchamados: true,
        feedback: '',
      };
      break;
    case 'DELETE_CHAMADO_REJECTED':
      state = {
        ...state,
        loadingchamados: false,
      };
      break;
    case 'DELETE_CHAMADO_FULFILLED':
      state = {
        ...state,
        loadingchamados: false,
        chamados: state.chamados.filter((c) => c.id !== action.payload.data),
      };
      notification.success({ message: 'Chamado excluído com sucesso!' });
      break;
    case 'DELETE_HISTORICO_PENDING':
      state = {
        ...state,
        savinghistorico: true,
        feedback: '',
      };
      break;
    case 'DELETE_HISTORICO_REJECTED':
      state = {
        ...state,
        savinghistorico: false,
      };
      break;
    case 'DELETE_HISTORICO_FULFILLED':
      state = {
        ...state,
        savinghistorico: false,
        chamado: {
          ...state.chamado,
          historicoChamados: state.chamado.historicoChamados.filter((c) => c.id !== action.payload.data),
        },
      };
      notification.success({
        message: 'Histórico excluído com sucesso! Um email foi enviado para os responsáveis',
      });
      break;
    case 'ADD_HISTORICO_PENDING':
      state = {
        ...state,
        savinghistorico: true,
        feedback: '',
      };
      break;
    case 'ADD_HISTORICO_REJECTED':
      state = {
        ...state,
        savinghistorico: false,
      };
      break;
    case 'ADD_HISTORICO_FULFILLED':
      state = {
        ...state,
        savinghistorico: false,
        chamado: {
          ...state.chamado,
          historicoChamados: [action.payload.data, ...state.chamado.historicoChamados],
        },
      };
      notification.success({ message: 'Histórico salvo com sucesso!' });
      break;
    case 'GET_RESPONSAVEIS_PENDING':
      state = {
        ...state,
        loadingresponsaveis: true,
        feedback: '',
        responsaveis: initialState.responsaveis,
      };
      break;
    case 'GET_RESPONSAVEIS_REJECTED':
      state = {
        ...state,
        loadingresponsaveis: false,
        feedback: action.payload.response.data.Message,
      };
      break;
    case 'GET_RESPONSAVEIS_FULFILLED':
      state = {
        ...state,
        loadingresponsaveis: false,
        responsaveis: action.payload.data,
      };
      break;
    case 'GET_CHAMADOS_PENDING':
      state = {
        ...state,
        loadingchamados: true,
        feedback: '',
        chamados: initialState.chamados,
        chamado: initialState.chamado,
      };
      break;
    case 'GET_CHAMADOS_REJECTED':
      state = {
        ...state,
        loadingchamados: false,
        feedback: action.payload.response.data.Message,
      };
      break;
    case 'GET_CHAMADOS_FULFILLED':
      state = {
        ...state,
        loadingchamados: false,
        chamados: action.payload.data,
      };
      break;
    case 'GET_CHAMADO_PENDING':
      state = {
        ...state,
        loadingchamados: true,
        feedback: '',
        chamado: initialState.chamado,
      };
      break;
    case 'GET_CHAMADO_REJECTED':
      state = {
        ...state,
        loadingchamados: false,
        feedback: action.payload.response.data.Message,
      };
      break;
    case 'GET_CHAMADO_FULFILLED':
      state = {
        ...state,
        loadingchamados: false,
        chamado: action.payload.data,
      };
      break;
    default:
      return state;
  }
  return state;
};

export default chamadosReducer;
