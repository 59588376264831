import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doubleMask, moneyMask, moneyNegMask } from '@utils/masks';
import { PlusCircleFilled, MinusCircleFilled } from '@ant-design/icons';
import '../../../../assets/scss/OrcamentoDetails.scss';
import moment from 'moment';
import { Orcamento } from '@models/Orcamento';
import { Casa } from '@models/Casa';
import { isValidType } from '@utils/testType';
import { Categoria } from '@models/Categoria';
import { Grupo } from '@models/Grupo';
import { Item } from '@models/Item';
const newApi = axios.create({
  baseURL: process.env.API,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
});
const TitleItem = ({ title, value }: { title: string; value: any }) => {
  return (
    <div className="title-item">
      <span className="title-item-title">{title}:</span>
      <span className="title-item-value">{value}</span>
    </div>
  );
};

const OrdensItens = ({ items = [] }) => {
  return (
    <table className={`ordens-table`}>
      <thead>
        <tr>
          <th>Ordem ID</th>
          <th>Status</th>
          <th>Descrição Ordem</th>
          <th>Descrição Item</th>
          <th>Forma de Pagamento</th>
          <th>Enviado Financeiro</th>
          <th>Valor Unitário</th>
          <th>Quantidade</th>
          <th>Desconto</th>
          <th>Desconto Ordem</th>
          <th>Valor Total</th>
        </tr>
      </thead>
      <tbody>
        {items.map((i) => (
          <tr key={i.id}>
            <td>{i.ordemId}</td>
            <td>{i.ordem.situacao}</td>
            <td>{i.ordem.descricao}</td>
            <td>{i.descricao}</td>
            <td>{i.ordem.formaPagamento}</td>
            <td>
              {moment(i.ordem.dataFinanceiro).isValid() ? moment(i.ordem.dataFinanceiro).format('DD/MM/YYYY') : ''}
            </td>
            <td>{moneyMask.format(i.valorUnitario)}</td>
            <td>{doubleMask.format(i.quantidade)}</td>
            <td>{moneyMask.format(i.desconto)}</td>
            <td>{moneyMask.format(i.ordem.desconto)}</td>
            <td>{moneyMask.format(i.valorTotal)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
const OrcamentoDetails = () => {
  const [orcamento, setOrcamento] = useState<Orcamento>();
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    newApi
      .get('/api/v2/OrcamentoDetails/' + id)
      .then((res) => {
        setOrcamento(res.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);
  const handleExpand = (id: number) => {
    if (expanded.includes(id)) setExpanded(expanded.filter((c) => c !== id));
    else setExpanded([...expanded, id]);
  };

  if (loading) return <div>Carregando...</div>;
  return (
    <div>
      <div>
        <TitleItem title="Orçamento" value={orcamento?.descricao} />
        <TitleItem title="Área" value={doubleMask.format(isValidType<Casa>(orcamento?.casa) && orcamento?.casa.area)} />
      </div>
      <hr />

      <table className="main-table">
        <thead>
          <tr>
            <th></th>
            <th>Categoria</th>
            <th>Grupo</th>
            <th>Item</th>
            <th>Valor Unitário</th>
            <th className="value">Quantidade</th>
            <th className="value">Valor Total</th>
            <th className="value">Realizado</th>
            <th className="value">Saldo</th>
          </tr>
        </thead>
        <tbody>
          {orcamento.orcamentosItens.map((oi, i) => (
            <>
              <tr key={oi.id}>
                {i === 0 && (
                  <td>
                    {expanded.includes(oi.id) ? (
                      <MinusCircleFilled style={{ cursor: 'pointer' }} onClick={() => handleExpand(oi.id)} />
                    ) : (
                      <PlusCircleFilled style={{ cursor: 'pointer' }} onClick={() => handleExpand(oi.id)} />
                    )}
                  </td>
                )}
                {i === 0 && (
                  <td
                    rowSpan={
                      orcamento.orcamentosItens.filter(
                        (c) =>
                          isValidType<Categoria>(c.categoria) &&
                          isValidType<Categoria>(oi.categoria) &&
                          c.categoria.descricao === oi.categoria.descricao
                      ).length
                    }
                  >
                    {isValidType<Categoria>(oi.categoria) && oi.categoria.descricao}
                  </td>
                )}
                <td>{isValidType<Grupo>(oi.grupo) && oi.grupo.descricao}</td>
                <td>{isValidType<Item>(oi.item) && `${oi.item.unidade} - ${oi.item.descricao}`}</td>
                <td className="value">{moneyMask.format(oi.valorUnitario)}</td>
                <td className="value">{doubleMask.format(oi.quantidade)}</td>
                <td className="value">{moneyMask.format(oi.valorTotal)}</td>
                <td className="value">
                  {moneyMask.format(
                    oi.ordensItens
                      .filter((c) => !c.ordem.cancelada && c.ordem.aprovacao3)
                      .reduce((a, b) => a + (b.valorTotal as number), 0)
                  )}
                </td>
                <td
                  className="value"
                  style={{
                    color:
                      oi.valorTotal -
                        oi.ordensItens
                          .filter((c) => !c.ordem.cancelada && c.ordem.aprovacao3)
                          .reduce((a, b) => a + (b.valorTotal as number), 0) <
                      0
                        ? 'red'
                        : undefined,
                  }}
                >
                  {moneyNegMask.format(
                    oi.valorTotal -
                      oi.ordensItens
                        .filter((c) => !c.ordem.cancelada && c.ordem.aprovacao3)
                        .reduce((a, b) => a + (b.valorTotal as number), 0)
                  )}
                </td>
              </tr>
              {expanded.includes(oi.id) && (
                <tr>
                  <td colSpan={9}>
                    <OrdensItens items={oi.ordensItens} />
                  </td>
                </tr>
              )}
            </>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrcamentoDetails;
