import { ExtendedAction } from '@config/reduxStore';
import { Investimento } from '@models/Investimento';
import { RelatorioInvestimento } from '@models/RelatorioInvestimento';

export interface InvestidorReducer {
  loadinginvestimentos: boolean;
  investimentos: Investimento[];
  relatorio: RelatorioInvestimento;
  loadingrelatorio: boolean;
}
var initialState: InvestidorReducer = {
  loadinginvestimentos: false,
  investimentos: [],
  relatorio: null,
  loadingrelatorio: false,
};

const investidorReducer = function (state = initialState, action: ExtendedAction) {
  switch (action.type) {
    case 'GET_RELATORIO_PENDING':
      state = {
        ...state,
        loadingrelatorio: true,
        relatorio: initialState.relatorio,
      };
      break;
    case 'GET_RELATORIO_REJECTED':
      state = {
        ...state,
        loadingrelatorio: false,
      };
      break;
    case 'GET_RELATORIO_FULFILLED':
      state = {
        ...state,
        loadingrelatorio: false,
        relatorio: action.payload.data,
      };
      break;
    case 'GET_INVESTIMENTOS_PENDING':
      state = {
        ...state,
        loadinginvestimentos: true,
        investimentos: initialState.investimentos,
      };
      break;
    case 'GET_INVESTIMENTOS_REJECTED':
      state = {
        ...state,
        loadinginvestimentos: false,
      };
      break;
    case 'GET_INVESTIMENTOS_FULFILLED':
      state = {
        ...state,
        loadinginvestimentos: false,
        investimentos: action.payload.data,
      };
      break;
    default:
      return state;
  }
  return state;
};

export default investidorReducer;
