import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Form, Input, Modal } from 'antd';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { novoItemOrcamentoBanco } from '@actions/bancoActions';
import { moneyMask } from '@utils/masks';

function NovoItemOrcamentoBancoModal({
  visible,
  setVisible,
  item,
}: {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  item: any;
}) {
  const { savingItem } = useAppSelector((state) => state.banco);
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  useEffect(() => {
    if (!savingItem) form.resetFields();
  }, [savingItem]);
  useEffect(() => {
    if (item) form.setFieldsValue({ ...item, valorTotal: moneyMask.format(item.valorTotal) });
    else form.resetFields();
  }, [item]);
  return (
    <Modal
      open={visible}
      onCancel={() => setVisible(false)}
      title="Novo Orçamento Banco"
      onOk={() => form.submit()}
      okButtonProps={{ loading: savingItem }}
      cancelText="Fechar"
    >
      <Form
        form={form}
        onFinish={(values) =>
          dispatch(
            novoItemOrcamentoBanco({
              item: { ...values, valorTotal: moneyMask.normalize(values.valorTotal) },
              id: id,
            })
          )
        }
        wrapperCol={{ span: 20 }}
        labelCol={{ span: 4 }}
      >
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="orcamentoBancoID" hidden>
          <Input />
        </Form.Item>
        <Form.Item label="Código" name="codigo" rules={[{ required: true, message: 'Campo obrigatório' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Descrição" name="descricao" rules={[{ required: true, message: 'Campo obrigatório' }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Valor Total"
          name="valorTotal"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
          normalize={(v) => moneyMask.format(moneyMask.normalize(v))}
          initialValue={moneyMask.format(0)}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default NovoItemOrcamentoBancoModal;
