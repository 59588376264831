import { Button, Modal, Pagination } from 'antd';
import { Dispatch, SetStateAction, useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
function PDFView({
  visible,
  setVisible,
  url,
  fileType,
}: {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  url: string;
  fileType: string;
}) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const cancel = () => {
    setVisible(false);
    setPageNumber(1);
    setNumPages(null);
  };
  return (
    <Modal
      width="90%"
      open={visible}
      footer={[
        <Button key={0} onClick={cancel}>
          Fechar
        </Button>,
      ]}
      onCancel={cancel}
    >
      {fileType.includes('pdf') ? (
        <div style={{ width: '100%' }}>
          <Document
            loading="Carregando arquivo..."
            file={url}
            options={{ menubar: true }}
            onLoadSuccess={(pdf) => setPageNumber(pdf.numPages)}
          >
            <Page pageNumber={pageNumber} />
          </Document>
          <Pagination
            size="small"
            total={numPages}
            current={pageNumber}
            pageSize={1}
            onChange={(page) => setPageNumber(page)}
          />
        </div>
      ) : fileType.includes('jpeg') || fileType.includes('jpg') || fileType.includes('png') ? (
        <img src={url} alt="img" style={{ objectFit: 'scale-down', width: '100%' }} />
      ) : null}
    </Modal>
  );
}

export default PDFView;
