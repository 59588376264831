import { apiEndpoints } from '@constants/apiEndpoints';
import { Categoria } from '@models/Categoria';
import { Grupo } from '@models/Grupo';
import { Orcamento } from '@models/Orcamento';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { fetcher } from './api';

export const GetOrcamentosList = () => {
  return useQuery<Orcamento[], AxiosError<any>>(
    ['orcamentosList'],
    () => fetcher<Orcamento[]>(apiEndpoints.ORCAMENTOS.GET_ORCAMENTOS_LIST, { params: { ativo: true } }),
    {
      onError: (err) => console.log(err.response?.data?.Message),
    }
  );
};
export const GetOrcamentosRealizado = ({
  ativo,
  orcamentoID,
  tipo,
}: {
  ativo: boolean;
  orcamentoID: number;
  tipo?: number;
}) => {
  return useQuery<Orcamento, AxiosError<any>>(
    ['orcamentoRealizado', orcamentoID],
    () =>
      fetcher<Orcamento>(apiEndpoints.ORCAMENTOS.GET_ORCAMENTO_REALIZADO, {
        params: { ativo: ativo, id: orcamentoID, tipo: tipo },
      }),
    {
      onError: (err) => console.log(err.response?.data?.Message),
      enabled: !!orcamentoID,
      staleTime: 0,
    }
  );
};
export const GetCategoriasOrcamento = ({ orcamentoID }: { orcamentoID: number }) => {
  return useQuery<Categoria[], AxiosError<any>>(
    ['categoriasOrcamento', orcamentoID],
    () =>
      fetcher<Categoria[]>(apiEndpoints.ORCAMENTOS.GET_CATEGORIAS, {
        params: { orcamentoID: orcamentoID },
      }),
    {
      onError: (err) => console.log(err.response?.data?.Message),
      enabled: !!orcamentoID,
      staleTime: 1000 * 60 * 1,
    }
  );
};
export const GetGruposOrcamento = ({ orcamentoID, categoriaID }: { orcamentoID: number; categoriaID: number }) => {
  return useQuery<Grupo[], AxiosError<any>>(
    ['gruposOrcamento', orcamentoID, categoriaID],
    () =>
      fetcher<Grupo[]>(apiEndpoints.ORCAMENTOS.GET_GRUPOS, {
        params: { orcamentoID: orcamentoID, categoriaID: categoriaID },
      }),
    {
      onError: (err) => console.log(err.response?.data?.Message),
      enabled: !!orcamentoID && !!categoriaID,
      staleTime: 1000 * 60 * 1,
    }
  );
};
