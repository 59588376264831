import { AppDispatch } from '@config/reduxStore';
import { apiEndpoints } from '@constants/apiEndpoints';
import { Grupo } from '@models/Grupo';
import { Axios } from '@config/axios';
export const GetGrupos = function (id?: number | string) {
  return function (dispatch: AppDispatch) {
    if (id) {
      dispatch({
        type: 'GET_GRUPOS',
        payload: Axios.get(apiEndpoints.GRUPOS.GET_GRUPOS_ORCAMENTO, { params: { orcamentoID: id } }),
      });
    } else
      dispatch({
        type: 'GET_GRUPOS',
        payload: Axios.get(apiEndpoints.GRUPOS.GET_GRUPOS),
      });
  };
};
export const GetGruposOC = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_GRUPOSOC',
      payload: Axios.get(apiEndpoints.GRUPOS.GET_GRUPOS_OC),
    });
  };
};
export const SalvarGrupo = function (values: Grupo) {
  return function (dispatch: AppDispatch) {
    if (values.id) {
      dispatch({
        type: 'UPDATE_GRUPO',
        payload: Axios.post(apiEndpoints.GRUPOS.SALVAR_GRUPO, { ...values }),
      });
    } else {
      dispatch({
        type: 'ADD_GRUPO',
        payload: Axios.post(apiEndpoints.GRUPOS.SALVAR_GRUPO, { ...values }),
      });
    }
  };
};
export const DeleteGrupo = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DELETE_GRUPO',
      payload: Axios.get(apiEndpoints.GRUPOS.DELETE_GRUPO, { params: { id } }),
    });
  };
};
export const BloquearGrupo = function (id: number, bloqueado: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'BLOQUEAR_GRUPO',
      payload: Axios.get(apiEndpoints.GRUPOS.BLOQUEAR_GRUPO, { params: { id, bloqueado } }),
    });
  };
};
export const FormGrupo = function (id: number, open: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'FORM_GRUPO',
      payload: { id, open },
    });
  };
};

export const EditarGrupoNome = function (id: number, nome: string) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'EDITAR_GRUPO_NOME',
      payload: Axios.put(apiEndpoints.GRUPOS.EDITAR_NOME, { id, nome }),
    });
  };
};
