import LoadingText from '@components/LoadingText';
import { OrdemStatus } from '@constants/ordemStatus';
import { PedidoItem } from '@models/Pedidos';
import { GerarPedidoItens, GetPedidoItens } from '@services/api/api.pedidos';
import { filterobject, naturalSorter } from '@utils/filterandsort';
import { doubleMask, moneyMask } from '@utils/masks';
import { Button, Input, Popconfirm, Space, Spin, Table, Tag } from 'antd';
import { useEffect, useState } from 'react';

function TableItens({ status, prazo }: { status: string; prazo: [moment.Moment, moment.Moment] }) {
  const { data, isLoading, isFetching } = GetPedidoItens(status, prazo, 'Item');
  const [filter, setFilter] = useState<string>('');
  const [selectedOrcamentos, setSelectedOrcamentos] = useState<{ [key in number]: PedidoItem[] }>({});
  const gerarPedido = GerarPedidoItens();
  useEffect(() => {
    setSelectedOrcamentos({});
  }, [status]);
  const expandedRow = (data: PedidoItem) => {
    return (
      <Table
        size="small"
        dataSource={data.orcamentos}
        scroll={{ y: 300 }}
        pagination={false}
        rowKey="orcamentoID"
        rowSelection={{
          getCheckboxProps(row) {
            return { disabled: row.saldoPendente === 0 || status === OrdemStatus.PendenteAprovacao };
          },
          selectedRowKeys: selectedOrcamentos[data.itemID]?.map((c) => c.orcamentoID) || [],
          onChange: (_, selectedRows) => {
            setSelectedOrcamentos((pv) => ({ ...pv, [data.itemID]: selectedRows }));
          },
        }}
      >
        <Table.Column
          title="Orcamento"
          dataIndex="orcamento"
          sorter={(a, b: PedidoItem) => naturalSorter(a.orcamento, b.orcamento)}
        />
        <Table.Column
          title="Quantidade Solicitada"
          dataIndex="quantidadeSolicitada"
          render={(v) => doubleMask.format(v)}
          align="right"
          width={160}
          sorter={(a, b: PedidoItem) => a.quantidadeSolicitada - b.quantidadeSolicitada}
        />
        <Table.Column
          title="Quantidade Processada"
          dataIndex="quantidadeProcessada"
          render={(v) => doubleMask.format(v)}
          align="right"
          width={170}
          sorter={(a, b: PedidoItem) => a.quantidadeProcessada - b.quantidadeProcessada}
        />
        <Table.Column
          title="Saldo Pendente"
          dataIndex="saldoPendente"
          render={(v) => doubleMask.format(v)}
          align="right"
          width={120}
          sorter={(a, b: PedidoItem) => a.saldoPendente - b.saldoPendente}
        />
        <Table.Column
          title="Total Orçado"
          dataIndex="valorOrcado"
          width={120}
          sorter={(a, b: PedidoItem) => a.valorOrcado - b.valorOrcado}
          render={(v) => moneyMask.format(v)}
          align="right"
        />
      </Table>
    );
  };
  const TableTitle = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {!isFetching && <div />}
        {isFetching && <LoadingText text="Atualizando dados" />}
        <Space>
          <Button ghost type="primary" size="small" onClick={() => setSelectedOrcamentos({})}>
            Limpar Seleção
          </Button>
          <Popconfirm
            title="Está certo disso?"
            disabled={Object.keys(selectedOrcamentos).every((c) => selectedOrcamentos[Number(c)].length === 0)}
            onConfirm={() => {
              const itens: PedidoItem[] = [];
              Object.keys(selectedOrcamentos).forEach((c) => itens.push(...selectedOrcamentos[Number(c)]));
              gerarPedido.mutate({ itens, situacao: status });
              //console.log(itens);
            }}
          >
            <Button
              size="small"
              type="primary"
              disabled={Object.keys(selectedOrcamentos).every((c) => selectedOrcamentos[Number(c)].length === 0)}
            >
              {`Processar ${Object.keys(selectedOrcamentos).map((c) => c.length > 0).length} Itens`}
            </Button>
          </Popconfirm>
        </Space>
      </div>
    );
  };
  return (
    <Spin spinning={gerarPedido.isLoading}>
      <Input.Search
        placeholder="Buscar..."
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        style={{ width: 300, float: 'right', marginBottom: 8 }}
      />
      <Table
        size="small"
        dataSource={filterobject(data, filter)}
        loading={isLoading}
        rowKey="itemID"
        expandable={{ expandedRowRender: expandedRow }}
        scroll={{ y: `calc(100vh - ${isFetching ? '310px' : '290px'})` }}
        title={() => <TableTitle />}
        pagination={{
          position: ['bottomRight'],
          size: 'small',
          showSizeChanger: true,
          pageSizeOptions: [10, 25, 50, 100],
          showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
        }}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row style={{ fontWeight: 'bold' }}>
              <Table.Summary.Cell index={0} colSpan={8} align="right">
                TOTAL
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} align="right">
                {moneyMask.format(data?.reduce((a, b) => a + b.valorOrcado, 0) || 0)}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      >
        <Table.Column
          dataIndex="itemID"
          width={90}
          align="center"
          title="Selecionados"
          render={(v) => (
            <Tag color={selectedOrcamentos[v]?.length || 0 ? 'blue' : undefined}>
              {selectedOrcamentos[v]?.length || 0}
            </Tag>
          )}
        />
        <Table.Column title="Item" dataIndex="item" sorter={(a, b: PedidoItem) => naturalSorter(a.item, b.item)} />
        <Table.Column title="Unidade" dataIndex="unidade" width={80} align="center" />
        <Table.Column
          title="Quantidade Solicitada"
          dataIndex="quantidadeSolicitada"
          render={(v) => doubleMask.format(v)}
          align="right"
          sorter={(a, b: PedidoItem) => a.quantidadeSolicitada - b.quantidadeSolicitada}
          width={160}
        />
        <Table.Column
          title="Quantidade Processada"
          dataIndex="quantidadeProcessada"
          render={(v) => doubleMask.format(v)}
          align="right"
          sorter={(a, b: PedidoItem) => a.quantidadeProcessada - b.quantidadeProcessada}
          width={170}
        />
        <Table.Column
          title="Saldo Pendente"
          dataIndex="saldoPendente"
          render={(v) => doubleMask.format(v)}
          align="right"
          sorter={(a, b: PedidoItem) => a.saldoPendente - b.saldoPendente}
          width={120}
        />
        <Table.Column
          title="Orçamentos"
          dataIndex="id"
          width={100}
          sorter={(a, b: PedidoItem) => a.orcamentos.length - b.orcamentos.length}
          render={(_, row: PedidoItem) => row.orcamentos.length}
          align="center"
        />
        <Table.Column
          title="Total Orçado"
          dataIndex="valorOrcado"
          width={120}
          sorter={(a, b: PedidoItem) => a.valorOrcado - b.valorOrcado}
          render={(v) => moneyMask.format(v)}
          align="right"
        />
      </Table>
    </Spin>
  );
}
export default TableItens;
