import { Input, Button, Form, Checkbox, Row, Col } from 'antd';
import { Login } from '@actions/userActions';
import Text from 'antd/lib/typography/Text';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

interface FormData {
  Email: string;
  Password: string;
}
function LoginView() {
  const [form] = Form.useForm<FormData>();
  const urlParams = new URLSearchParams(window.location.search);
  const returnURL = urlParams.get('ReturnURL');
  const dispatch = useAppDispatch();
  const { login } = useAppSelector((state) => state.user);

  const onFinishLogin = (values: FormData) => {
    dispatch(Login(values, returnURL));
  };

  return (
    <Row justify="center">
      <Col flex="0 1 300px">
        <Form
          onFinish={onFinishLogin}
          form={form}
          initialValues={{ remember: true }}
          name="loginForm"
          layout="vertical"
          wrapperCol={{ span: 20 }}
          labelCol={{ span: 4 }}
        >
          <Form.Item
            name="Email"
            label="Email"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório!',
              },
            ]}
          >
            <Input placeholder="Usuário" />
          </Form.Item>
          <Form.Item
            name="Password"
            label="Senha"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório!',
              },
            ]}
          >
            <Input.Password placeholder="Senha" />
          </Form.Item>
          <Form.Item name="remember" valuePropName="checked">
            <Checkbox>Lembrar</Checkbox>
          </Form.Item>
          <Form.Item>
            <Button type="link" href="/Forgot">
              Esqueci minha senha
            </Button>
          </Form.Item>
          <Form.Item wrapperCol={{ span: 20 }}>
            <Button loading={login.loading} block type="primary" htmlType="submit">
              Entrar
            </Button>
          </Form.Item>
          <Form.Item name="feedback">
            <Text type="danger">{login.feedback}</Text>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}

export default LoginView;
