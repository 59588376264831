import { ExtendedAction } from '@config/reduxStore';
import { Grupo } from '@models/Grupo';
import { notification } from 'antd';

interface GruposReducer {
  grupos: Grupo[];
  formview: boolean;
  grupo: number;
  loadinggrupos: boolean;
  savinggrupo: boolean;
  feedback: string;
}
var initialState: GruposReducer = {
  grupos: [],
  formview: false,
  grupo: null,
  loadinggrupos: false,
  savinggrupo: false,
  feedback: '',
};

const gruposReducer = function (state = initialState, action: ExtendedAction) {
  switch (action.type) {
    case 'RESET_FEEDBACK':
      state = {
        ...state,
        feedback: '',
      };
      break;
    case 'FORM_GRUPO':
      state = {
        ...state,
        formview: action.payload.open,
        feedback: '',
        grupo: action.payload.id ? action.payload.id : null,
      };
      break;
    case 'GET_GRUPOS_PENDING':
      state = {
        ...state,
        loadinggrupos: true,
        grupos: initialState.grupos,
      };
      break;
    case 'GET_GRUPOS_REJECTED':
      state = {
        ...state,
        loadinggrupos: false,
      };
      break;
    case 'GET_GRUPOS_FULFILLED':
      state = {
        ...state,
        loadinggrupos: false,
        grupos: action.payload.data,
      };
      break;
    case 'GET_GRUPOSOC_PENDING':
      state = {
        ...state,
        loadinggrupos: true,
        grupos: initialState.grupos,
      };
      break;
    case 'GET_GRUPOSOC_REJECTED':
      state = {
        ...state,
        loadinggrupos: false,
      };
      break;
    case 'GET_GRUPOSOC_FULFILLED':
      state = {
        ...state,
        loadinggrupos: false,
        grupos: action.payload.data,
      };
      break;
    case 'ADD_GRUPO_PENDING':
      state = {
        ...state,
        savinggrupo: true,
        feedback: '',
      };
      break;
    case 'ADD_GRUPO_REJECTED':
      state = {
        ...state,
        savinggrupo: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: 'Erro ao criar grupo!' });
      break;
    case 'ADD_GRUPO_FULFILLED':
      state = {
        ...state,
        savinggrupo: false,
        feedback: 'Grupo criado com sucesso!',
        grupos: [...state.grupos, action.payload.data],
      };
      notification.success({ message: 'Grupo criado com sucesso!' });
      break;
    case 'UPDATE_GRUPO_PENDING':
      state = {
        ...state,
        savinggrupo: true,
        feedback: '',
      };
      break;
    case 'UPDATE_GRUPO_REJECTED':
      state = {
        ...state,
        savinggrupo: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: 'Erro ao atualizar grupo!' });
      break;
    case 'UPDATE_GRUPO_FULFILLED':
      state = {
        ...state,
        savinggrupo: false,
        feedback: 'Grupo atualizada com sucesso!',
        grupos: state.grupos.map((c) => {
          if (c.id === action.payload.data.id) return action.payload.data;
          else return c;
        }),
      };
      notification.success({ message: 'Grupo atualizado com sucesso!' });
      break;
    case 'ADD_COMPOSICAO_ORCAMENTO_FULFILLED':
      state = {
        ...state,
        grupos: [...state.grupos.filter((c) => c.id !== action.payload.data.grupo.id), action.payload.data.grupo],
      };
      break;
    case 'DELETE_GRUPO_PENDING':
      state = {
        ...state,
        loadinggrupos: true,
        feedback: '',
      };
      break;
    case 'DELETE_GRUPO_REJECTED':
      state = {
        ...state,
        loadinggrupos: false,
        feedback: action.payload.response.data.Message,
      };
      notification.warning({ message: state.feedback });
      break;
    case 'DELETE_GRUPO_FULFILLED':
      state = {
        ...state,
        loadinggrupos: false,
        feedback: 'Grupo excluído com sucesso!',
        grupos: state.grupos.filter((c) => c.id !== action.payload.data),
      };
      notification.success({ message: state.feedback });
      break;
    case 'EDITAR_GRUPO_NOME_PENDING':
      state = {
        ...state,
      };
      break;
    case 'EDITAR_GRUPO_NOME_REJECTED':
      state = {
        ...state,
      };
      notification.warning({ message: action.payload.response.data.Message });
      break;
    case 'EDITAR_GRUPO_NOME_FULFILLED':
      state = {
        ...state,
        grupos: state.grupos.map((c) => {
          if (c.id === action.payload.data.id) {
            c.descricao = action.payload.data.nome;
            return c;
          } else return c;
        }),
      };
      notification.success({ message: 'Grupo atualizado com sucesso' });
      break;

    default:
      return state;
  }
  return state;
};

export default gruposReducer;
