import { useEffect, useState } from 'react';
import logo from '../../../assets/img/main.png';
import { useParams } from 'react-router-dom';
import { GetCasa } from '@actions/casasActions';
import { Axios } from '@config/axios';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

function TermoVistoriaEntrega() {
  const dispatch = useAppDispatch();
  const { fichaImovel } = useAppSelector((state) => state.casas);
  const { casaid } = useParams();
  const [templates, setTemplates] = useState(null);
  const { user } = useAppSelector((state) => state.user);
  useEffect(() => {
    dispatch(GetCasa(casaid));
    Axios.get('/api/v2/Templates/GetTemplates').then((res) => setTemplates(res.data));
  }, []);
  useEffect(() => {
    if (fichaImovel && templates) {
      document.write(
        templates.vistoriaEntrega.body.replace(/{{\w+}}/g, (a: any) => {
          if (a === '{{logo}}') return `<img src="${user.configuracao.logo || logo}" alt="logo" height="70"/>`;
          if (a === '{{quebrapagina}}') return '<div style="break-after:page"></div>';
          return fichaImovel && fichaImovel[String(a.replace(/{{/g, '').replace(/}}/g, ''))];
        })
      );
      document.close();
      window.print();
    }
  }, [fichaImovel, templates]);
  return <div />;
}

export default TermoVistoriaEntrega;
