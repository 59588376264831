import { useEffect } from 'react';
import { Modal, Form, Input, Select, Button } from 'antd';
import { SalvarCasa, FormCasa } from '@actions/casasActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Casa } from '@models/Casa';

function CasaForm() {
  const dispatch = useAppDispatch();
  const casas = useAppSelector((state) => state.casas);
  const emps = useAppSelector((state) => state.emps);
  const { empresas } = useAppSelector((state) => state.empresas);
  const [form] = Form.useForm<Casa>();
  const submitForm = (values: Casa) => {
    if (values.id) dispatch(SalvarCasa(values));
    else dispatch(SalvarCasa(values));
  };
  useEffect(() => {
    form.resetFields();
    if (casas.casa) {
      form.setFieldsValue(casas.casas.find((c) => c.id === casas.casa));
    }
  }, [casas.casa, casas.casas, form]);
  return (
    <div>
      <Modal
        open={casas.formview}
        onCancel={() => dispatch(FormCasa(null, false))}
        title="Cadastro Casa"
        footer={[
          <Button onClick={() => dispatch(FormCasa(null, false))} key="2">
            Fechar
          </Button>,
          <Button key="3" type="primary" onClick={() => form.submit()} loading={casas.savingcasa}>
            Salvar
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form} onFinish={submitForm} name="casaForm">
          <Form.Item style={{ display: 'none' }} name="id">
            <Input />
          </Form.Item>
          <Form.Item
            label="Empreendimento"
            name="empreendimentoID"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
          >
            <Select showSearch optionFilterProp="children">
              {emps.emps.map((c) => (
                <Select.Option key={c.id} value={c.id}>
                  {c.nome}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Empresa Legalização"
            name="empresaLegalizacaoID"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
          >
            <Select showSearch optionFilterProp="children">
              {empresas.map((c) => (
                <Select.Option key={c.id} value={c.id}>
                  {c.nome}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="CEP" name="cep">
            <Input />
          </Form.Item>
          <Form.Item label="Endereço" name="endereco" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Número" name="numero" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Complemento" name="complemento">
            <Input />
          </Form.Item>
          <Form.Item label="Quadra" name="quadra">
            <Input />
          </Form.Item>
          <Form.Item label="Lote" name="lote">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
export default CasaForm;
