import { useEffect, useState } from 'react';
import { Modal, Table, Button, Input, FormInstance } from 'antd';
import { BuscarCasaForm, GetCasasEscolher } from '@actions/correspondenciaActions';
import { filterobject, getColumnSearchProps, sortAlpha } from '@utils/filterandsort';
import { moneyMask } from '@utils/masks';
import { SearchOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

function BuscarCasa(props: { form: FormInstance }) {
  const dispatch = useAppDispatch();
  const { buscarcasaform, loadingcasas, casas, cliente } = useAppSelector((state) => state.correspondencia);
  const [filter, setfilter] = useState('');
  const [casa, setcasa] = useState(null);
  useEffect(() => {
    dispatch(GetCasasEscolher());
  }, []);
  return (
    <Modal
      title="Buscar casa"
      width={(window.innerWidth * 3) / 4}
      open={buscarcasaform}
      onCancel={() => dispatch(BuscarCasaForm())}
      footer={[
        <Button key="1" onClick={() => dispatch(BuscarCasaForm())}>
          Fechar
        </Button>,
        <Button
          key="2"
          type="primary"
          onClick={() => {
            props.form.setFieldsValue({
              ...casa,
              casaID: casa.id,
              valorCompraVenda: moneyMask.format(casa.valorCompraVenda),
              valorAvaliacao: moneyMask.format(casa.valorAvaliacao),
              id: cliente?.id,
            });
            dispatch(BuscarCasaForm());
          }}
        >
          Escolher Casa
        </Button>,
      ]}
    >
      <div style={{ textAlign: 'right' }}>
        <Input
          size="small"
          style={{ width: 400 }}
          onChange={(e) => setfilter(e.target.value)}
          placeholder="Buscar"
          allowClear
          suffix={<SearchOutlined />}
        />
      </div>
      <Table
        rowSelection={{
          type: 'radio',
          onSelect: (values) => {
            setcasa(values);
          },
        }}
        rowKey="id"
        dataSource={filterobject(casas, filter)}
        loading={loadingcasas}
        size="small"
        scroll={{ y: 'calc(100vh - 300px)', x: 1024 }}
        pagination={{
          position: ['bottomRight'],
          size: 'small',
          showSizeChanger: true,
          pageSizeOptions: [10, 25, 50, 100],
          showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
        }}
      >
        <Table.Column
          title="Endereço"
          dataIndex="endereco"
          sorter={(a, b) => sortAlpha(a.endereco, b.endereco)}
          {...getColumnSearchProps('endereco', 'string', 'Endereço')}
        />
        <Table.Column
          title="Empreendimento"
          dataIndex="empreendimento"
          sorter={(a, b) => sortAlpha(a.empreendimento, b.empreendimento)}
          {...getColumnSearchProps('empreendimento', 'string', 'Empreendimento')}
        />
        <Table.Column
          title="Matrícula"
          dataIndex="matricula"
          sorter={(a, b) => a.matricula - b.matricula}
          {...getColumnSearchProps('matricula', 'string', 'Matrícula')}
        />
        <Table.Column
          title="Valor Compra e Venda"
          dataIndex="valorCompraVenda"
          sorter={(a, b) => a.matricula - b.matricula}
          render={(value) => moneyMask.format(value)}
          {...getColumnSearchProps('valorCompraVenda', 'string', 'Valor Compra e Venda')}
        />
        <Table.Column
          title="Avaliação"
          dataIndex="valorAvaliacao"
          sorter={(a, b) => a.matricula - b.matricula}
          render={(value) => moneyMask.format(value)}
          {...getColumnSearchProps('valorAvaliacao', 'string', 'Valor Avaliação')}
        />
        <Table.Column
          title="Cliente"
          dataIndex="cliente"
          sorter={(a, b) => sortAlpha(a.empreendimento, b.empreendimento)}
          {...getColumnSearchProps('cliente', 'string', 'Cliente')}
        />
      </Table>
    </Modal>
  );
}
export default BuscarCasa;
