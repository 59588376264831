import { Form, DatePicker, Progress, Select, Slider, Space, Table } from 'antd';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getAvancosFisicos, getFisico, updateAvancoFisico } from '@actions/bancoActions';
import { SetPageHeader } from '@actions/userActions';
import { naturalSorter } from '@utils/filterandsort';
import { doubleMask1 } from '@utils/masks';
import moment from 'moment';
import { useState } from 'react';
import GruposModal from './GruposModal';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

function Lancar() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [date, setDate] = useState(moment(new Date()));
  const [grupos, setGrupos] = useState({ visible: false, categoria: '' });
  const { fisico, loadingFisico, avancosFisicos, loadingAvancosFisicos, orcamento, min, max } = useAppSelector(
    (state) => state.banco
  );
  useEffect(() => {
    dispatch(getFisico(id));
    dispatch(getAvancosFisicos(id));
  }, [id]);
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Lançar Avanço Físico',
        backbutton: true,
        extras: [],
        subtitle: orcamento.descricao,
        menu: 'fisico',
      })
    );
  }, [id, orcamento]);
  const marks = { 0: '0%', 25: '25%', 50: '50%', 75: '75%', 100: '100%' };
  const strokeColor = {
    from: '#108ee9',
    to: '#87d068',
  };
  return (
    <div>
      <GruposModal visible={grupos.visible} setVisible={setGrupos} categoria={grupos.categoria} />
      <Space>
        <DatePicker.MonthPicker
          format="MM/YYYY"
          value={date}
          onChange={(v) => setDate(v)}
          disabledDate={(date) => date < min || date > max}
          defaultValue={moment(new Date())}
        />
        <Form>
          <Form.Item name="status" label="Status">
            <Select>
              <Select.Option value="Solicitado">Solcitado</Select.Option>
              <Select.Option value="Realizado">Realizado</Select.Option>
              <Select.Option value="Solicitado">Pendência</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Space>
      <Table
        size="small"
        dataSource={[...fisico].sort((a, b) => naturalSorter(a.descricao, b.descricao))}
        loading={loadingFisico || loadingAvancosFisicos}
        pagination={false}
        rowKey="id"
        scroll={{ y: 'calc(100vh - 190px)' }}
      >
        <Table.Column title="Descrição" dataIndex="descricao" render={(v) => v.toUpperCase()} />
        <Table.Column
          dataIndex="id"
          width={100}
          align="center"
          title="Avanço do Mês"
          render={(value) =>
            doubleMask1.format(
              avancosFisicos.find(
                (c) =>
                  c.orcamentoBancoItemID === value &&
                  moment(c.mes).month() === date.month() &&
                  moment(c.mes).year() === date.year()
              )?.realizado
            )
          }
        />
        <Table.Column
          title="Avanço"
          dataIndex="id"
          render={(v) => (
            <div>
              <div>
                <Slider
                  style={{ width: '92%' }}
                  step={5}
                  marks={marks}
                  value={avancosFisicos
                    .filter((c) => c.orcamentoBancoItemID === v && moment(c.mes) < date)
                    .reduce((a, b) => a + b.realizado, 0)}
                  tooltip={{
                    formatter: (value) => doubleMask1.format(value),
                  }}
                  onAfterChange={(value: any) =>
                    dispatch(
                      updateAvancoFisico({
                        id:
                          avancosFisicos.find(
                            (c) =>
                              c.orcamentoBancoItemID === v &&
                              moment(c.mes).month() === date.month() &&
                              moment(c.mes).year() === date.year()
                          )?.id || 0,
                        orcamentoBancoItemID: v,
                        realizado: value,
                        orcamentoID: id,
                        mes: date.format('YYYY-MM-DD'),
                      })
                    )
                  }
                />
              </div>
              <div>
                <Progress
                  strokeColor={strokeColor}
                  percent={avancosFisicos
                    .filter((c) => c.orcamentoBancoItemID === v)
                    .reduce((a, b) => a + b.realizado, 0)}
                />
              </div>
            </div>
          )}
        />
      </Table>
    </div>
  );
}

export default Lancar;
