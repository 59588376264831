import { AppDispatch } from '@config/reduxStore';
import { apiEndpoints } from '@constants/apiEndpoints';
import { Corretor } from '@models/Corretor';
import { Axios } from '@config/axios';
export const GetCorretores = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_CORRETORES',
      payload: Axios.get(apiEndpoints.CORRETORES.GET_CORRETORES),
    });
  };
};
export const SalvarCorretor = function (values: Corretor) {
  return function (dispatch: AppDispatch) {
    if (values.id) {
      dispatch({
        type: 'UPDATE_CORRETOR',
        payload: Axios.post(apiEndpoints.CORRETORES.ADD_CORRETOR, { ...values }),
      });
    } else {
      dispatch({
        type: 'ADD_CORRETOR',
        payload: Axios.post(apiEndpoints.CORRETORES.ADD_CORRETOR, { ...values }),
      });
    }
  };
};
export const DeleteCorretor = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DELETE_CORRETOR',
      payload: Axios.get(apiEndpoints.CORRETORES.DELETE_CORRETOR, { params: { id } }),
    });
  };
};
export const FormCorretor = function (id: number | null, open: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'FORM_CORRETOR',
      payload: { id, open },
    });
  };
};
