import { TransferenciaForm } from '@models/Transferencia';
import { Form, FormInstance } from 'antd';
import React, { Dispatch, ReactNode, SetStateAction, useState } from 'react';

interface TransferenciaFormContextProps {
  form: FormInstance<TransferenciaForm>;
  setOrcamentoID: Dispatch<SetStateAction<number>>;
  orcamentoID: number;
}

const TransferenciaFormContext = React.createContext<TransferenciaFormContextProps>(null);

interface TransferenciaFormProviderProps {
  children: ReactNode;
}
export const TransferenciaFormProvider = ({ children }: TransferenciaFormProviderProps) => {
  const [form] = Form.useForm<TransferenciaForm>();
  const [orcamentoID, setOrcamentoID] = useState<number>(null);

  return (
    <TransferenciaFormContext.Provider value={{ form, orcamentoID, setOrcamentoID }}>
      {children}
    </TransferenciaFormContext.Provider>
  );
};

export const useTransferenciaForm = () => {
  const context = React.useContext(TransferenciaFormContext);
  if (context == null) throw new Error('Hook must be wrapped by provider');
  return context;
};
