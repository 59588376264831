import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Checkbox, Form, Input, Modal, Select } from 'antd';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { getModelos, getOrcamentos, novoOrcamentobanco } from '@actions/bancoActions';
import { moneyMask } from '@utils/masks';

function NovoOrcamentoModel({
  visible,
  setVisible,
}: {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}) {
  const { modelos, loadingModelos, orcamentos, loadingOrcamentos, savingOrcamentoBanco } = useAppSelector(
    (state) => state.banco
  );
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  useEffect(() => {
    dispatch(getModelos());
    dispatch(getOrcamentos());
  }, []);

  return (
    <Modal
      open={visible}
      onCancel={() => setVisible(false)}
      title="Novo Orçamento Banco"
      onOk={() => form.submit()}
      okButtonProps={{ loading: savingOrcamentoBanco }}
    >
      <Form
        form={form}
        onFinish={(values) =>
          dispatch(novoOrcamentobanco({ ...values, valorTotal: moneyMask.normalize(values.valorTotal) }))
        }
        wrapperCol={{ span: 20 }}
        labelCol={{ span: 4 }}
      >
        <Form.Item label="Modelo" name="modelo" initialValue={false} valuePropName="checked">
          <Checkbox onChange={() => form.setFieldValue('orcamentoID', null)} />
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) =>
            getFieldValue('modelo') ? (
              <Form.Item name="descricao" label="Descrição">
                <Input />
              </Form.Item>
            ) : (
              <Form.Item
                label="Orçamento"
                name="orcamentoID"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Select showSearch optionFilterProp="children" loading={loadingOrcamentos}>
                  {orcamentos.map((c) => (
                    <Select.Option key={c.id} value={c.id}>
                      {c.descricao}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )
          }
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) =>
            !getFieldValue('modelo') && (
              <Form.Item label="Modelo" name="modeloID">
                <Select showSearch optionFilterProp="children" loading={loadingModelos} allowClear>
                  {modelos.map((c) => (
                    <Select.Option key={c.id}>{c.descricao}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )
          }
        </Form.Item>
        <Form.Item
          label="Valor Total"
          name="valorTotal"
          normalize={(v) => moneyMask.format(moneyMask.normalize(v))}
          initialValue={moneyMask.format(0)}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default NovoOrcamentoModel;
