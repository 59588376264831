import { GetEmpresasFaturamentoList } from '@services/api/api.empresaFaturamento';
import { SelectProps } from 'antd';
import { Select } from 'antd';

export interface SelectSolicitanteProps extends SelectProps {}

function EmpresaFaturamentoSelect({ ...restProps }: SelectSolicitanteProps) {
  const { isLoading, data, isFetching } = GetEmpresasFaturamentoList();
  return (
    <Select
      {...restProps}
      loading={isLoading || isFetching}
      optionFilterProp="children"
      allowClear
      showSearch
      disabled={isLoading}
    >
      {data.map((c) => (
        <Select.Option key={c.id} value={c.id}>
          {c.nome}
        </Select.Option>
      ))}
    </Select>
  );
}

export default EmpresaFaturamentoSelect;
