import { GetAprovadosMesAtual } from '@services/api/api.correspondencia';
import { Card, Spin, Statistic } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';

interface Props {
  mes: moment.Moment;
}
function DashboardAprovadosMesAtual({ mes }: Props) {
  const { data, isLoading } = GetAprovadosMesAtual(mes);

  return (
    <Card>
      <Spin spinning={isLoading}>
        <Statistic
          title="Aprovados no mês"
          value={data}
          precision={0}
          style={{ width: 500, height: 62 }}
          valueStyle={{ color: 'green' }}
          prefix={<CheckCircleTwoTone twoToneColor="green" />}
        />
      </Spin>
    </Card>
  );
}

export default DashboardAprovadosMesAtual;
