import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { SetPageHeader } from '@actions/userActions';
import { Table, Skeleton, Descriptions, Button, message, Tooltip, Modal, DatePicker, Tabs, Divider } from 'antd';
import { BloquearItem, GetOrcamentoRealizado } from '@actions/orcamentosActions';
import { moneyNegMask, moneyMask, doubleMask, doubleMask1 } from '@utils/masks';
import { FileExcelOutlined, LockFilled, UnlockOutlined, EditOutlined } from '@ant-design/icons';
import { HasPermission, Permission } from '@hooks//usePermissions';
import ComprasItem from './ComprasItem';
import Transferir from './Transferir';
import moment from 'moment';
import { Axios } from '@config/axios';
import OrcamentoTableView from './OrcamentoTableView';
import { naturalSorter } from '@utils/filterandsort';
import CurvaABCTableView from './CurvaABCTableView';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Orcamento } from '@models/Orcamento';

function OrcamentoView() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const { orcamento, loadingorcamento } = useAppSelector((state) => state.orcamentos);
  const [downloading, setDownloading] = useState(false);
  const [downloadFluxoModal, setDownloadFluxomodal] = useState({ visible: false, data: moment(new Date()) });
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Engenharia',
        backbutton: true,
        extras: [
          <Permission key="1" permission="orcamento.get">
            {orcamento
              ? !orcamento.bloqueado && (
                  <Link target="_blank" to={`/OrcamentoForm/${id}`}>
                    <Button
                      size="small"
                      loading={downloading || loadingorcamento}
                      icon={<EditOutlined />}
                      type="dashed"
                    >
                      Editar Orçamento
                    </Button>
                  </Link>
                )
              : null}
          </Permission>,
          <Button
            key="2"
            size="small"
            hidden
            loading={downloading || loadingorcamento}
            icon={<FileExcelOutlined />}
            onClick={() => {
              setDownloading(true);
              Axios.post('/api/v2/Orcamentos/DownloadExcel', orcamento, { responseType: 'blob' })
                .then((res) => {
                  setDownloading(false);
                  const url = window.URL.createObjectURL(new Blob([res.data]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', 'Orcamento.xlsx');
                  document.body.appendChild(link);
                  link.click();
                })
                .catch(() => {
                  message.error('Erro ao gerar arquivo!');
                  setDownloading(false);
                });
            }}
          >
            Download
          </Button>,
          <Button
            key="4"
            size="small"
            loading={downloading || loadingorcamento}
            icon={<FileExcelOutlined />}
            onClick={() => setDownloadFluxomodal({ visible: true, data: moment(new Date()) })}
          >
            Exportar Excel
          </Button>,
        ],
        subtitle: 'Orçamento',
        menu: 'orcamentos',
      })
    );
  }, [id, orcamento, downloading, loadingorcamento]);
  useEffect(() => {
    //props.dispatch(GetOrcamento(id, false));
    dispatch(GetOrcamentoRealizado(id));
  }, [id]);
  return (
    <div>
      <Modal
        title="Selecione a competência"
        open={downloadFluxoModal.visible}
        onCancel={() => setDownloadFluxomodal({ visible: false, data: moment(new Date()) })}
        okButtonProps={{ loading: downloading || loadingorcamento }}
        onOk={() => {
          setDownloading(true);
          Axios.get('/api/v2/Orcamentos/GetFluxo', {
            responseType: 'blob',
            params: { id: orcamento.id, mes: moment(downloadFluxoModal.data).format('MM-DD-YYYY') },
          })
            .then((res) => {
              setDownloading(false);
              const url = window.URL.createObjectURL(new Blob([res.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `Fluxo_${moment(new Date()).format('DD_MM_YYYY HH:mm')}.xlsx`);
              document.body.appendChild(link);
              link.click();
            })
            .catch(() => {
              message.error('Erro ao gerar arquivo!');
              setDownloading(false);
            });
        }}
      >
        <DatePicker.MonthPicker
          value={downloadFluxoModal.data}
          format="MMM/YYYY"
          onChange={(v) => setDownloadFluxomodal({ visible: true, data: v })}
        />
      </Modal>
      <ComprasItem />
      <Transferir />
      <Skeleton loading={loadingorcamento} active>
        {orcamento && (
          <Descriptions size="small" bordered title="Informações Gerais" style={{ marginBottom: 8 }}>
            <Descriptions.Item label="Descrição">{orcamento.descricao}</Descriptions.Item>
            {!orcamento.deposito && (
              <Descriptions.Item label="Empreendimento">{orcamento.empreendimento}</Descriptions.Item>
            )}
            {!orcamento.deposito && <Descriptions.Item label="Memorial">{orcamento.memorial}</Descriptions.Item>}
            {!orcamento.deposito && <Descriptions.Item label="Área Construída">{orcamento.area} m²</Descriptions.Item>}
            {!orcamento.deposito && (
              <Descriptions.Item label="Valor Contrato">
                {`${moneyMask.format(orcamento.valorContratoObra + orcamento.aditivos)} (${doubleMask.format(
                  (orcamento.valorContratoObra + orcamento.aditivos) / orcamento.area
                )}) R$/m2`}
              </Descriptions.Item>
            )}
            {!orcamento.deposito && (
              <Descriptions.Item label={<Tooltip title="∑ Quantidade x Valor Unitário">Total Orçado</Tooltip>}>
                {`${moneyMask.format(
                  orcamento.orcamentosItens.reduce((a, b) => a + b.valorTotal, 0)
                )} (${doubleMask.format(
                  orcamento.orcamentosItens.reduce((a, b) => a + b.valorTotal, 0) / orcamento.area
                )} R$/m2)`}
              </Descriptions.Item>
            )}
            {!orcamento.deposito && (
              <Descriptions.Item label={<Tooltip title="Total Realizado">Total Realizado</Tooltip>}>
                {`${moneyMask.format(
                  orcamento.orcamentosItens.reduce((a, b) => a + b.realizadoValor, 0)
                )} (${doubleMask.format(
                  orcamento.orcamentosItens.reduce((a, b) => a + b.realizadoValor, 0) / orcamento.area
                )} R$/m2)`}
              </Descriptions.Item>
            )}
            {orcamento.deposito && (
              <Descriptions.Item label={<Tooltip title="Total em Estoque">Total em Estoque</Tooltip>}>
                {`${moneyMask.format(orcamento.orcamentosItens.reduce((a, b) => a + b.realizadoValor, 0))}`}
              </Descriptions.Item>
            )}
            {!orcamento.deposito && (
              <Descriptions.Item label={<Tooltip title="Total Orçado - Total Realizado">Saldo do Orçamento</Tooltip>}>
                <span
                  style={{
                    color:
                      orcamento.orcamentosItens.reduce((a, b) => a + b.valorTotal, 0) -
                        orcamento.orcamentosItens.reduce((a, b) => a + b.realizadoValor, 0) <
                      0
                        ? 'red'
                        : 'blue',
                  }}
                >
                  {moneyNegMask.format(
                    orcamento.orcamentosItens.reduce((a, b) => a + b.valorTotal, 0) -
                      orcamento.orcamentosItens.reduce((a, b) => a + b.realizadoValor, 0)
                  )}
                </span>
              </Descriptions.Item>
            )}
            {!orcamento.deposito && (
              <Descriptions.Item label={<Tooltip title="Valor Contrato - Total Realizado">Saldo do Contrato</Tooltip>}>
                <span
                  style={{
                    color:
                      orcamento.valorContratoObra +
                        orcamento.aditivos -
                        orcamento.orcamentosItens.reduce((a, b) => a + b.realizadoValor, 0) <
                      0
                        ? 'red'
                        : 'blue',
                  }}
                >
                  {moneyNegMask.format(
                    orcamento.valorContratoObra +
                      orcamento.aditivos -
                      orcamento.orcamentosItens.reduce((a, b) => a + b.realizadoValor, 0)
                  )}
                </span>
              </Descriptions.Item>
            )}
            {!orcamento.deposito && (
              <Descriptions.Item
                label={
                  <Tooltip title="Total Realizado + Saldos Positivos de Orçamento de Itens não Bloqueados">
                    Total Projetado
                  </Tooltip>
                }
              >
                {`${moneyMask.format(
                  orcamento.orcamentosItens.reduce(
                    (a, b) =>
                      a +
                      (b.bloqueado
                        ? b.realizadoValor
                        : b.valorTotal - b.realizadoValor > 0
                        ? b.valorTotal
                        : b.realizadoValor),
                    0
                  )
                )} (${doubleMask.format(
                  orcamento.orcamentosItens.reduce(
                    (a, b) =>
                      a +
                      (b.bloqueado
                        ? b.realizadoValor
                        : b.valorTotal - b.realizadoValor > 0
                        ? b.valorTotal
                        : b.realizadoValor),
                    0
                  ) / orcamento.area
                )} R$/m2)`}
              </Descriptions.Item>
            )}
            {!orcamento.deposito && (
              <Descriptions.Item
                label={<Tooltip title="Valor do contrato - Total Projetado">Resultado Projetado</Tooltip>}
              >
                <span
                  style={{
                    color:
                      orcamento.valorContratoObra +
                        orcamento.aditivos -
                        orcamento.orcamentosItens.reduce(
                          (a, b) =>
                            a +
                            (b.bloqueado
                              ? b.realizadoValor
                              : b.valorTotal - b.realizadoValor > 0
                              ? b.valorTotal
                              : b.realizadoValor),
                          0
                        ) >
                      0
                        ? 'blue'
                        : 'red',
                  }}
                >
                  {`${moneyMask.format(
                    orcamento.valorContratoObra +
                      orcamento.aditivos -
                      orcamento.orcamentosItens.reduce(
                        (a, b) =>
                          a +
                          (b.bloqueado
                            ? b.realizadoValor
                            : b.valorTotal - b.realizadoValor > 0
                            ? b.valorTotal
                            : b.realizadoValor),
                        0
                      )
                  )} (${doubleMask.format(
                    orcamento.valorContratoObra +
                      orcamento.aditivos -
                      orcamento.orcamentosItens.reduce(
                        (a, b) =>
                          a +
                          (b.bloqueado
                            ? b.realizadoValor
                            : b.valorTotal - b.realizadoValor > 0
                            ? b.valorTotal
                            : b.realizadoValor),
                        0
                      ) /
                        orcamento.area
                  )} R$/m2)`}
                </span>
              </Descriptions.Item>
            )}
            {!orcamento.deposito && (
              <Descriptions.Item label="Custo Alvo">
                {`${moneyMask.format(
                  orcamento.valorContratoObra + orcamento.aditivos - orcamento.margem * orcamento.area
                )} (${doubleMask.format(
                  (orcamento.valorContratoObra + orcamento.aditivos - orcamento.margem * orcamento.area) /
                    orcamento.area
                )} R$/m2)`}
              </Descriptions.Item>
            )}
            {!orcamento.deposito && (
              <Descriptions.Item label="Total Orçado em Material">
                {`${moneyMask.format(
                  orcamento.orcamentosItens.filter((c) => c.itemTipo === 0).reduce((a, b) => a + b.valorTotal, 0)
                )} (${doubleMask.format(
                  orcamento.orcamentosItens.filter((c) => c.itemTipo === 0).reduce((a, b) => a + b.valorTotal, 0) /
                    orcamento.area
                )} R$/m2)`}
              </Descriptions.Item>
            )}
            {orcamento.deposito && (
              <Descriptions.Item label="Total Orçado">
                {`${moneyMask.format(
                  orcamento.orcamentosItens.filter((c) => c.itemTipo === 0).reduce((a, b) => a + b.valorTotal, 0)
                )}`}
              </Descriptions.Item>
            )}
            {!orcamento.deposito && (
              <Descriptions.Item label="Total Orçado em Serviços">
                {`${moneyMask.format(
                  orcamento.orcamentosItens.filter((c) => c.itemTipo === 1).reduce((a, b) => a + b.valorTotal, 0)
                )} (${doubleMask.format(
                  orcamento.orcamentosItens.filter((c) => c.itemTipo === 1).reduce((a, b) => a + b.valorTotal, 0) /
                    orcamento.area
                )} R$/m2)`}
              </Descriptions.Item>
            )}
            {!orcamento.deposito && (
              <Descriptions.Item label="Avanço">
                {doubleMask1.format(
                  (100 *
                    orcamento.orcamentosItens
                      .filter((c) => c.itemTipo === 1)
                      .reduce((a, b) => a + b.realizadoValor, 0)) /
                    orcamento.orcamentosItens.filter((c) => c.itemTipo === 1).reduce((a, b) => a + b.valorTotal, 0)
                )}
              </Descriptions.Item>
            )}
          </Descriptions>
        )}

        <Divider />

        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab={orcamento?.deposito ? 'Materiais' : 'Orçamento'} key="1">
            {orcamento && (
              <OrcamentoTableView
                orcamentosItens={orcamento?.orcamentosItens}
                ativo={orcamento?.ativo}
                deposito={orcamento?.deposito}
              />
            )}
          </Tabs.TabPane>
          {orcamento && !orcamento.deposito && (
            <>
              <Tabs.TabPane tab="Avanço MDO" key="2">
                <Table
                  size="small"
                  pagination={false}
                  scroll={{ y: 'calc(100vh - 200px)', x: 1200 }}
                  dataSource={
                    orcamento &&
                    orcamento.orcamentosItens
                      .filter((c) => c.itemTipo === 1)
                      .sort((a: any, b: any) => naturalSorter(a.categoria, b.categoria))
                  }
                  summary={(data) => (
                    <Table.Summary fixed>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} align="right" colSpan={4}>
                          <strong>TOTAIS</strong>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1}>
                          <strong>{moneyMask.format(data.reduce((a, b) => a + b.valorTotal, 0))}</strong>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={2}>
                          <strong>{moneyMask.format(data.reduce((a, b) => a + b.realizadoValor, 0))}</strong>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3}>
                          <strong>
                            {doubleMask1.format(
                              (100 * data.reduce((a, b) => a + b.realizadoValor, 0)) /
                                data.reduce((a, b) => a + b.valorTotal, 0)
                            )}
                          </strong>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={4}>
                          <strong>
                            {moneyNegMask.format(
                              data.reduce(
                                (a, b) =>
                                  a +
                                  (b.bloqueado
                                    ? 0
                                    : b.valorTotal - b.realizadoValor > 0
                                    ? b.valorTotal - b.realizadoValor
                                    : 0),
                                0
                              )
                            )}
                          </strong>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  )}
                >
                  <Table.Column
                    title="Bloqueio"
                    dataIndex="id"
                    render={(value, row: Orcamento) => (
                      <Permission permission="grupo.lock">
                        <Tooltip
                          title={
                            orcamento.orcamentosItens.find((g) => g.id === value).bloqueado
                              ? 'Desbloquear item'
                              : 'Bloquear Item'
                          }
                        >
                          <Button
                            disabled={!orcamento.ativo || !HasPermission('grupo.lock', user.permissions)}
                            size="small"
                            icon={
                              orcamento.orcamentosItens.find((g) => g.id === value).bloqueado ? (
                                <LockFilled />
                              ) : (
                                <UnlockOutlined />
                              )
                            }
                            onClick={() => dispatch(BloquearItem(row.id))}
                          />
                        </Tooltip>
                      </Permission>
                    )}
                  />
                  <Table.Column title="Categoria" dataIndex="categoria" />
                  <Table.Column title="Grupo" dataIndex="grupo" />
                  <Table.Column title="Item" dataIndex="item" />
                  <Table.Column title="Orçado" dataIndex="valorTotal" render={(v) => moneyMask.format(v)} />
                  <Table.Column title="Realizado" dataIndex="realizadoValor" render={(v) => moneyMask.format(v)} />
                  <Table.Column
                    title="Avanço"
                    dataIndex="id"
                    render={(_, row: Orcamento) => doubleMask1.format((100 * row.realizadoValor) / row.valorTotal)}
                  />
                  <Table.Column
                    title="Saldo"
                    dataIndex="id"
                    render={(_, row: Orcamento) =>
                      moneyNegMask.format(
                        row.bloqueado
                          ? 0
                          : row.valorTotal - row.realizadoValor > 0
                          ? row.valorTotal - row.realizadoValor
                          : 0
                      )
                    }
                  />
                </Table>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Curva ABC Insumos (Orçado)" key="3">
                <CurvaABCTableView orcamentosItens={orcamento?.orcamentosItens} tipo={0} orcado={true} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Curva ABC Serviços (Orçado)" key="4">
                <CurvaABCTableView orcamentosItens={orcamento?.orcamentosItens} tipo={1} orcado={true} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Curva ABC Insumos (Realizado)" key="5">
                <CurvaABCTableView orcamentosItens={orcamento?.orcamentosItens} tipo={0} orcado={false} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Curva ABC Serviços (Realizado)" key="6">
                <CurvaABCTableView orcamentosItens={orcamento?.orcamentosItens} tipo={1} orcado={false} />
              </Tabs.TabPane>
            </>
          )}
        </Tabs>
      </Skeleton>
    </div>
  );
}

export default OrcamentoView;
