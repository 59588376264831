import { ExtendedAction } from '@config/reduxStore';
import { Galeria } from '@models/Galeria';
interface GaleriaReducer {
  items: Galeria[];
  loading: boolean;
  galeriaid: number;
  viewgaleria: boolean;
  galeriasaving: boolean;
  feedback: string;
}
var initialState: GaleriaReducer = {
  items: [],
  loading: false,
  galeriaid: null,
  viewgaleria: false,
  galeriasaving: false,
  feedback: '',
};

const galeriaReducer = function (state = initialState, action: ExtendedAction) {
  switch (action.type) {
    case 'OPEN_GALERIA':
      state = {
        ...state,
        galeriaid: action.payload,
        viewgaleria: true,
      };
      break;
    case 'CLOSE_GALERIA':
      state = {
        ...state,
        galeriaid: null,
        viewgaleria: false,
      };
      break;
    case 'DELETE_GALERIA_OBRA_PENDING':
      state = {
        ...state,
        loading: true,
        feedback: '',
      };
      break;
    case 'DELETE_GALERIA_OBRA_REJECTED':
      state = {
        ...state,
        loading: false,
        feedback: action.payload.response.data.Message,
      };
      break;
    case 'DELETE_GALERIA_OBRA_FULFILLED':
      state = {
        ...state,
        loading: false,
        items: [...state.items.filter((c) => c.id !== action.payload.data)],
      };
      break;
    case 'ADD_GALERIA_OBRA_PENDING':
      state = {
        ...state,
        galeriasaving: true,
        feedback: '',
      };
      break;
    case 'ADD_GALERIA_OBRA_REJECTED':
      state = {
        ...state,
        galeriasaving: false,
        feedback: action.payload.response.data.Message,
      };
      break;
    case 'ADD_GALERIA_OBRA_FULFILLED':
      state = {
        ...state,
        galeriasaving: false,
        items: [...state.items, action.payload.data],
      };
      break;
    case 'GET_GALERIA_PENDING':
      state = {
        ...state,
        loading: true,
        feedback: '',
      };
      break;
    case 'GET_GALERIA_REJECTED':
      state = {
        ...state,
        loading: false,
        feedback: action.payload.response.data.Message,
      };
      break;
    case 'GET_GALERIA_FULFILLED':
      state = {
        ...state,
        loading: false,
        items: action.payload.data,
      };
      break;
    default:
      return state;
  }
  return state;
};

export default galeriaReducer;
