import { useEffect } from 'react';
import { Modal, Button, Input, Slider, Form, Table, Typography } from 'antd';
import { DeleteOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { ShowProgressoLegalizacao, CarregarProcessos } from '@actions/casasActions';
import {
    AddLegalizacao,
    GetLegalizacao,
    DeleteLegalizacao,
    ChangeOrder,
    ChangeProgresso,
} from '@actions/legalizacaoActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { ProcessoEmpreendimento } from '@models/ProcessoEmpreendimento';

function ProgressoLegalizacao() {
    const dispatch = useAppDispatch();
    const { legalizacaomodal, fichaImovel, casas, feedback, loadingprocessos } = useAppSelector((state) => state.casas);
    const legalizacao = useAppSelector((state) => state.legalizacao);
    const [form] = Form.useForm();
    const submitForm = (values: ProcessoEmpreendimento) => {
        dispatch(AddLegalizacao(values.descricao, fichaImovel.id, 0));
    };
    useEffect(() => {
        form.resetFields();
        dispatch(GetLegalizacao(fichaImovel.id));
    }, [fichaImovel, casas, form]);

    return (
        <Modal
            title="Progresso da legalização"
            open={legalizacaomodal}
            onCancel={() => dispatch(ShowProgressoLegalizacao(null, false))}
            footer={[
                <Typography.Text key="1" type="danger">
                    {feedback}
                </Typography.Text>,
                <Button key="2" onClick={() => dispatch(ShowProgressoLegalizacao(null, false))}>
                    Fechar
                </Button>,
                legalizacao.items.length === 0 ? (
                    <Button
                        loading={loadingprocessos}
                        key="3"
                        type="primary"
                        onClick={() => dispatch(CarregarProcessos(fichaImovel.id))}
                    >
                        Carregar Processos
                    </Button>
                ) : (
                    ''
                ),
            ]}
        >
            <div>
                <h3>Progresso de Legalização</h3>
                <Form onFinish={submitForm} name="legalizacaoForm" form={form}>
                    <Form.Item name="descricao" rules={[{ required: true, message: 'Descrição em branco' }]}>
                        <Input.Search
                            placeholder="Descrição"
                            enterButton="Adicionar"
                            size="small"
                            onSearch={() => form.submit()}
                            //onSearch={() => form.submit()}
                            loading={legalizacao.legalizacaosaving}
                        />
                    </Form.Item>
                </Form>
                <Table
                    pagination={false}
                    loading={legalizacao.loading}
                    size="small"
                    rowKey="id"
                    dataSource={legalizacao.items}
                >
                    <Table.Column
                        width={120}
                        render={(_, row: ProcessoEmpreendimento, index) => (
                            <div>
                                <Button
                                    size="small"
                                    icon={<DeleteOutlined />}
                                    onClick={() => dispatch(DeleteLegalizacao(row.id))}
                                />
                                {index > 0 && (
                                    <Button
                                        size="small"
                                        icon={<ArrowUpOutlined />}
                                        onClick={() => dispatch(ChangeOrder(fichaImovel.id, row.id, true))}
                                    />
                                )}
                                {index + 1 < legalizacao.items.length && (
                                    <Button
                                        size="small"
                                        icon={<ArrowDownOutlined />}
                                        onClick={() => dispatch(ChangeOrder(fichaImovel.id, row.id, false))}
                                    />
                                )}
                            </div>
                        )}
                    />
                    <Table.Column title="Item" render={(_, __, index) => index + 1} />
                    <Table.Column title="Descrição" dataIndex="descricao" />
                    <Table.Column
                        width={150}
                        title="Progresso"
                        dataIndex="progresso"
                        render={(_, row: ProcessoEmpreendimento) => (
                            <Slider
                                style={{ marginRight: 30 }}
                                step={10}
                                marks={{ 0: '0%', 50: '50%', 100: '100%' }}
                                value={legalizacao.items.find((c) => c.id === row.id).progresso}
                                onChange={(value: any) => dispatch(ChangeProgresso(row.id, value))}
                            />
                        )}
                    />
                </Table>
            </div>
        </Modal>
    );
}

export default ProgressoLegalizacao;
