import { FinalizarPedido, GetAllPagination } from '@actions/pedidosActions';
import { SetPageHeader } from '@actions/userActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { ItemPedido, PedidoList } from '@models/Pedidos';
import { getColumnSearchPropsServer } from '@utils/filterandsort';
import { Button, Popconfirm, Progress, Select, Space, Table, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import OrdemStatusTag from '../../Ordens/Shared/OrdemStatusTag';
import { EyeOutlined, PrinterOutlined, StopOutlined, ShoppingCartOutlined, CheckOutlined } from '@ant-design/icons';
import { OrdemStatus } from '@constants/ordemStatus';
import { HasPermission, HasRole } from '@hooks/usePermissions';
import CancelPedidoModalRedux from '../shared/CancelPedidoModalRedux';
import { ViewCancelForm } from '@actions/novaOrdemActions';
import PedidoItensModal from '../PedidosPendentes/PedidoItensModal';
import { GetMateriaisList } from '@actions/materiaisActions';

function PedidosView() {
  const dispatch = useAppDispatch();
  const { pedidos, loadingPedidos, total } = useAppSelector((state) => state.pedidos);
  const { materiaislist, loadingmateriais } = useAppSelector((state) => state.materiais);
  const { user } = useAppSelector((state) => state.user);
  const [itemID, setitemID] = useState<number>(null);
  const [filters, setFilters] = useState<{
    pagination: TablePaginationConfig;
    filters: Record<string, FilterValue>;
    sorter: SorterResult<PedidoList> | SorterResult<PedidoList>[];
  }>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    filters: {
      cod: null,
      descricao: null,
      origem: null,
      comprador: null,
      dataCriacao: null,
      total: null,
    },
    sorter: {
      field: null,
      order: null,
    },
  });
  const [viewItens, setViewItens] = useState<{ visible: boolean; pedidoID: number }>({
    visible: false,
    pedidoID: null,
  });
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Pedidos',
        subtitle: '',
        extras: [
          <Link to="/DashboardPedidos" key={0}>
            <Button size="small" type="primary" ghost>
              Dashboard
            </Button>
          </Link>,
          <Link to="/NovoPedido" key={0}>
            <Button size="small" type="primary">
              Novo Pedido
            </Button>
          </Link>,
        ],
        menu: 'pedidos',
        backbutton: true,
      })
    );
  }, []);
  useEffect(() => {
    dispatch(GetMateriaisList());
    dispatch(
      GetAllPagination({
        filtros: {
          pagination: {
            current: 1,
            pageSize: 10,
          },
          filters: {
            cod: null,
            descricao: null,
            origem: null,
            comprador: null,
            dataCriacao: null,
            total: null,
          },
          sorter: {
            field: null,
            order: null,
          },
        },
        itemID: null,
        my: false,
      })
    );
  }, []);
  return (
    <div>
      <CancelPedidoModalRedux />
      <PedidoItensModal item={viewItens} setItem={setViewItens} />
      <Select
        loading={loadingmateriais}
        showSearch
        optionFilterProp="children"
        dropdownMatchSelectWidth
        allowClear
        value={itemID}
        onChange={(v) => {
          setitemID(v);
          dispatch(GetAllPagination({ filtros: filters, itemID: v, my: false }));
        }}
        style={{ width: 300 }}
        placeholder="Pesquisar por item..."
        //onChange={(v) => dispatch(GetTransferencias(null, v, false))}
      >
        {materiaislist.map((c) => (
          <Select.Option key={c.id} value={c.id}>{`${c.unidade} - ${c.descricao}`}</Select.Option>
        ))}
      </Select>
      <Table
        loading={loadingPedidos}
        dataSource={pedidos}
        size="small"
        rowKey="id"
        scroll={{ y: 'calc(100vh - 240px)' }}
        onChange={(pagination, filters, sorter) => {
          setFilters({ pagination, filters, sorter });
          dispatch(GetAllPagination({ filtros: { pagination, filters, sorter }, itemID: itemID }));
        }}
        pagination={{
          total: total,
          position: ['bottomRight'],
          size: 'small',
          showSizeChanger: true,
          pageSizeOptions: [10, 25, 50, 100],
          showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
        }}
      >
        <Table.Column
          dataIndex="id"
          width={120}
          fixed="left"
          render={(v, row: PedidoList) => (
            <Space>
              <Link to={`/Pedidos/${v}`}>
                <Button icon={<EyeOutlined />} size="small" />
              </Link>
              <Link to={`/PrintPedido/${v}`} target="_blank">
                <Button icon={<PrinterOutlined />} size="small" />
              </Link>
              {row.situacao !== OrdemStatus.Cancelada &&
                row.situacao !== OrdemStatus.Finalizada &&
                HasPermission('pedidos.finalizar', user.permissions) && (
                  <Popconfirm
                    title="Está certo disso"
                    onConfirm={() => dispatch(FinalizarPedido({ filtros: filters, itemID: itemID, id: v }))}
                  >
                    <Button size="small" icon={<CheckOutlined />} />
                  </Popconfirm>
                )}
              {(HasPermission('pedidos.cancelar', user.permissions) || HasRole(['master'], user.roles)) &&
                row.situacao !== OrdemStatus.Cancelada && (
                  <Button
                    size="small"
                    icon={<StopOutlined />}
                    onClick={() => dispatch(ViewCancelForm({ view: true, id: v, replace: false, cod: row.cod }))}
                  />
                )}
              <Button
                icon={<ShoppingCartOutlined />}
                size="small"
                onClick={() => setViewItens({ visible: true, pedidoID: v })}
              />
            </Space>
          )}
        />

        <Table.Column
          title="Pedido Nº"
          dataIndex="cod"
          width={110}
          align="center"
          sorter
          {...getColumnSearchPropsServer('cod', 'string', 'Nº Pedido', filters)}
        />
        <Table.Column
          title="Situação"
          dataIndex="situacao"
          width={200}
          sorter
          {...getColumnSearchPropsServer('situacao', 'customselect', 'Situação', filters, [
            { value: OrdemStatus.PendenteAprovacao, text: OrdemStatus.PendenteAprovacao },
            { value: OrdemStatus.EmProcessamentoCompras, text: OrdemStatus.EmProcessamentoCompras },
            { value: OrdemStatus.Aprovada, text: OrdemStatus.Aprovada },
            { value: OrdemStatus.Finalizada, text: OrdemStatus.Finalizada },
            { value: OrdemStatus.Cancelada, text: OrdemStatus.Cancelada },
          ])}
          render={(v) => <OrdemStatusTag status={v} />}
        />
        <Table.Column
          title="Processamento"
          dataIndex="ordens"
          width={100}
          render={(v: ItemPedido[]) => (
            <Progress
              percent={
                (Math.round((100 * v.reduce((a, b) => a + b.processado, 0)) / v.reduce((a, b) => a + b.solicitado, 0)) *
                  1) /
                1
              }
            />
          )}
        />
        <Table.Column
          title="Criação"
          dataIndex="dataCriacao"
          width={100}
          align="center"
          sorter
          {...getColumnSearchPropsServer('dataCriacao', 'daterange', 'Data de Criação', filters)}
          render={(v) => moment(v).format('DD/MM/YYYY')}
        />
        <Table.Column
          title="Prazo"
          dataIndex="prazoEntrega"
          width={100}
          align="center"
          sorter
          {...getColumnSearchPropsServer('prazoEntrega', 'daterange', 'Prazo de Entrega', filters)}
          render={(v) => moment(v).isValid() && moment(v).format('DD/MM/YYYY')}
        />
        <Table.Column
          title="Descrição"
          dataIndex="descricao"
          sorter
          width={200}
          {...getColumnSearchPropsServer('descricao', 'string', 'Descrição', filters)}
        />
        <Table.Column
          title="Solicitante"
          dataIndex="comprador"
          sorter
          width={200}
          {...getColumnSearchPropsServer('comprador', 'string', 'Solicitante', filters)}
        />
        <Table.Column
          title="Orcamento"
          width={200}
          dataIndex="orcamento"
          sorter
          {...getColumnSearchPropsServer('descricao', 'string', 'Descrição', filters)}
        />
      </Table>
    </div>
  );
}

export default PedidosView;
