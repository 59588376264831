import { useEffect } from 'react';
import { SetPageHeader } from '@actions/userActions';
import { Row, Col } from 'antd';
import MediaVendas from './MediaVendas';
import Corretores from './Corretores';
import { HasRole } from '@hooks//usePermissions';
import PrimeiraParcela from './PrimeiraParcela';
import AreaConstruida from './AreaConstruida';
import JornadaClienteObra from './JornadaClienteObra';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

function DashboardView() {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Painel de Resultados',
        backbutton: false,
        extras: [],
        subtitle: '',
        menu: 'dashboard',
      })
    );
  }, []);
  return (
    <Row gutter={[8, 8]}>
      {user.configuracao.dBorcamentos && <Col flex="1 1 1200px"></Col>}
      {user.configuracao.dBareaconstruida && (
        <Col flex="1 1 350px">
          <AreaConstruida />
        </Col>
      )}

      {user.configuracao.dBclientesobra && (
        <Col flex="1 1 500px">
          <JornadaClienteObra />
        </Col>
      )}
      {user.configuracao.dBvalormedio && HasRole(['comercial', 'master', 'financeiro', 'admin'], user.roles) && (
        <Col flex="1 1 300px">
          <MediaVendas />
        </Col>
      )}
      {user.configuracao.dBcorretores && (
        <Col flex="1 1 300px">
          <Corretores />
        </Col>
      )}
      {user.configuracao.dBparcelas && (
        <Col flex="1 1 300px">
          <PrimeiraParcela />
        </Col>
      )}
    </Row>
  );
}

export default DashboardView;
