import { ExtendedAction } from '@config/reduxStore';
import { Diario, DiarioView } from '@models/Diario';
import { Orcamento } from '@models/Orcamento';
import { User } from '@models/User';
import { createReducer } from '@reduxjs/toolkit';
import { notification } from 'antd';
import {
  deleteDiario,
  getAuditores,
  getDiario,
  getDiarios,
  getDiariosObra,
  getMestres,
  getObras,
  getResponsaveis,
  salvarDiario,
  saveFiles,
} from '@actions/diariosActions';

interface DiariosReducer {
  diarios: Diario[];
  loadingDiarios: boolean;
  diario: DiarioView;
  loadingDiario: boolean;
  savingDiario: boolean;
  responsaveis: User[];
  loadingResponsaveis: boolean;
  mestres: User[];
  loadingMestres: boolean;
  obras: Orcamento[];
  loadingObras: boolean;
  savingFiles: boolean;
  auditores: User[];
  loadingAuditores: boolean;
}
var initialState: DiariosReducer = {
  diarios: [],
  loadingDiarios: false,
  diario: null,
  loadingDiario: false,
  savingDiario: false,
  responsaveis: [],
  loadingResponsaveis: false,
  mestres: [],
  loadingMestres: false,
  obras: [],
  loadingObras: false,
  savingFiles: false,
  auditores: [],
  loadingAuditores: false,
};

export default createReducer(initialState, (builder) => {
  builder.addCase(getAuditores.pending, (state) => ({
    ...state,
    loadingAuditores: true,
    auditores: [],
  }));
  builder.addCase(getAuditores.rejected, (state) => ({
    ...state,
    loadingAuditores: false,
  }));
  builder.addCase(getAuditores.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    loadingAuditores: false,
    auditores: action.payload.data,
  }));
  builder.addCase(getDiariosObra.pending, (state) => ({
    ...state,
    loadingDiarios: true,
    diarios: [],
  }));
  builder.addCase(getDiariosObra.rejected, (state) => ({
    ...state,
    loadingDiarios: false,
  }));
  builder.addCase(getDiariosObra.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    loadingDiarios: false,
    diarios: action.payload.data.map((c: any) => ({ ...c, ...JSON.parse(c.values) })),
  }));
  builder.addCase(getDiarios.pending, (state) => ({
    ...state,
    loadingDiarios: true,
    diario: null,
  }));
  builder.addCase(getDiarios.rejected, (state) => ({
    ...state,
    loadingDiarios: false,
  }));
  builder.addCase(getDiarios.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    loadingDiarios: false,
    diarios: action.payload.data.map((c: any) => ({ ...c, ...JSON.parse(c.values) })),
  }));
  builder.addCase(salvarDiario.pending, (state) => ({
    ...state,
    savingDiario: true,
  }));
  builder.addCase(salvarDiario.rejected, (state, action: ExtendedAction) => {
    notification.error({ message: action.payload.response.data.Message });
    return {
      ...state,
      savingDiario: false,
    };
  });
  builder.addCase(salvarDiario.fulfilled, (state, action: ExtendedAction) => {
    notification.success({ message: 'Diário de obras salvo com sucesso!' });
    return {
      ...state,
      savingDiario: false,
      diario: action.payload.data.entity,
    };
  });
  builder.addCase(getResponsaveis.pending, (state) => ({
    ...state,
    loadingResponsaveis: true,
  }));
  builder.addCase(getResponsaveis.rejected, (state) => ({
    ...state,
    loadingResponsaveis: false,
  }));
  builder.addCase(getResponsaveis.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    loadingResponsaveis: false,
    responsaveis: action.payload.data,
  }));
  builder.addCase(getMestres.pending, (state) => ({
    ...state,
    loadingMestres: true,
  }));
  builder.addCase(getMestres.rejected, (state) => ({
    ...state,
    loadingMestres: false,
  }));
  builder.addCase(getMestres.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    loadingMestres: false,
    mestres: action.payload.data,
  }));
  builder.addCase(getObras.pending, (state) => ({
    ...state,
    loadingObras: true,
  }));
  builder.addCase(getObras.rejected, (state) => ({
    ...state,
    loadingObras: false,
  }));
  builder.addCase(getObras.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    loadingObras: false,
    obras: action.payload.data,
  }));
  builder.addCase(getDiario.pending, (state) => ({
    ...state,
    loadingDiario: true,
    diario: null,
  }));
  builder.addCase(getDiario.rejected, (state) => ({
    ...state,
    loadingDiario: false,
    diario: null,
  }));
  builder.addCase(getDiario.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    loadingDiario: false,
    diario: { ...JSON.parse(action.payload.data.values), ...action.payload.data },
  }));
  builder.addCase(saveFiles.pending, (state) => ({
    ...state,
    savingFiles: true,
  }));
  builder.addCase(saveFiles.rejected, (state) => ({
    ...state,
    savingFiles: false,
  }));
  builder.addCase(saveFiles.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    savingFiles: false,
    diario: { ...state.diario, fotos: action.payload.data },
  }));
  builder.addCase(deleteDiario.pending, (state) => ({
    ...state,
    loadingDiarios: true,
  }));
  builder.addCase(deleteDiario.rejected, (state) => ({
    ...state,
    loadingDiarios: false,
  }));
  builder.addCase(deleteDiario.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    loadingDiarios: false,
    diarios: state.diarios.filter((c) => c.id !== action.payload.data),
  }));
});
