import { ExtendedAction } from '@config/reduxStore';
import { Item } from '@models/Item';
import { notification } from 'antd';

interface MateriaisReducer {
  materiais: Item[];
  materiaislist: Item[];
  formview: boolean;
  material: number;
  loadingmateriais: boolean;
  savingmaterial: boolean;
  feedback: string;
  total: number;
}
var initialState: MateriaisReducer = {
  materiais: [],
  materiaislist: [],
  formview: false,
  material: null,
  loadingmateriais: false,
  savingmaterial: false,
  feedback: '',
  total: 0,
};

const materiaisReducer = function (state = initialState, action: ExtendedAction) {
  switch (action.type) {
    case 'RESET_FEEDBACK':
      state = {
        ...state,
        feedback: '',
      };
      break;
    case 'FORM_MATERIAL':
      state = {
        ...state,
        feedback: '',
        formview: action.payload.open,
        material: action.payload.id ? action.payload.id : null,
      };
      break;
    case 'GET_MATERIAIS_PENDING':
      state = {
        ...state,
        loadingmateriais: true,
        materiais: initialState.materiais,
      };
      break;
    case 'GET_MATERIAIS_REJECTED':
      state = {
        ...state,
        loadingmateriais: false,
      };
      break;
    case 'GET_MATERIAIS_FULFILLED':
      state = {
        ...state,
        loadingmateriais: false,
        materiais: action.payload.data,
      };
      break;
    case 'GET_MATERIAIS_SERVER_PENDING':
      state = {
        ...state,
        loadingmateriais: true,
        materiais: initialState.materiais,
      };
      break;
    case 'GET_MATERIAIS_SERVER_REJECTED':
      state = {
        ...state,
        loadingmateriais: false,
      };
      break;
    case 'GET_MATERIAIS_SERVER_FULFILLED':
      state = {
        ...state,
        loadingmateriais: false,
        materiais: action.payload.data.results,
        total: action.payload.data.total,
      };
      break;
    case 'GET_MATERIAIS_LIST_PENDING':
      state = {
        ...state,
        loadingmateriais: true,
        materiaislist: initialState.materiais,
      };
      break;
    case 'GET_MATERIAIS_LIST_REJECTED':
      state = {
        ...state,
        loadingmateriais: false,
      };
      break;
    case 'GET_MATERIAIS_LIST_FULFILLED':
      state = {
        ...state,
        loadingmateriais: false,
        materiaislist: action.payload.data,
      };
      break;
    case 'ADD_MATERIAL_PENDING':
      state = {
        ...state,
        savingmaterial: true,
        feedback: '',
      };
      break;
    case 'ADD_MATERIAL_REJECTED':
      state = {
        ...state,
        savingmaterial: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: 'Erro ao criar item!' });
      break;
    case 'ADD_MATERIAL_FULFILLED':
      state = {
        ...state,
        savingmaterial: false,
        feedback: 'Item criado com sucesso!',
        materiais: [...state.materiais, action.payload.data],
      };
      notification.success({ message: 'Item criado com sucesso!' });
      break;
    case 'UPDATE_MATERIAL_PENDING':
      state = {
        ...state,
        savingmaterial: true,
        feedback: '',
      };
      break;
    case 'UPDATE_MATERIAL_REJECTED':
      state = {
        ...state,
        savingmaterial: false,
        feedback: action.payload.response.data.Message,
      };
      notification.success({ message: 'Erro ao atualizar item!' });
      break;
    case 'UPDATE_MATERIAL_FULFILLED':
      state = {
        ...state,
        savingmaterial: false,
        feedback: 'Item atualizado com sucesso!',
        materiais: state.materiais.map((c) => {
          if (c.id === action.payload.data.id) return action.payload.data;
          else return c;
        }),
      };
      notification.success({ message: 'Item atualizado com sucesso!' });
      break;
    case 'DELETE_MATERIAL_PENDING':
      state = {
        ...state,
        loadingmateriais: true,
        feedback: '',
      };
      break;
    case 'DELETE_MATERIAL_REJECTED':
      state = {
        ...state,
        loadingmateriais: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: state.feedback });
      break;
    case 'DELETE_MATERIAL_FULFILLED':
      state = {
        ...state,
        loadingmateriais: false,
        feedback: 'Item excluído com sucesso!',
        materiais: state.materiais.filter((c) => c.id !== action.payload.data),
      };
      notification.success({ message: state.feedback });
      break;
    default:
      return state;
  }
  return state;
};

export default materiaisReducer;
