import { combineReducers } from 'redux';
import user from './userReducer';
import casas from './casasReducer';
import emps from './empreendimentosReducer';
import loteamentos from './loteamentosReducer';
import agencias from './agenciasReducer';
import corretores from './corretoresReducer';
import parceiros from './parceirosReducer';
import chamados from './chamadosReducer';
import usuarios from './usuariosReducer';
import perfis from './perfilsReducer';
import investidor from './investidorReducer';
import dashboard from './dashboardReducer';
import modalidades from './modalidadesReducer';
import ccas from './CCAsReducer';
import produtos from './produtosReducer';
import correspondencia from './correspondenciaReducer';
import fornecedores from './fornecedoresReducer';
import materiais from './materiaisReducer';
import ordens from './ordensReducer';
import empresasfaturamento from './empresasfaturamentoReducer';
import categorias from './categoriasReducer';
import orcamentos from './orcamentosReducer';
import grupos from './gruposReducer';
import obra from './obraReducer';
import legalizacao from './legalizacaoReducer';
import galeria from './galeriaReducer';
import arquivos from './arquivosReducer';
import conta from './contaReducer';
import empresas from './empresasReducer';
import composicoes from './composicoesReducer';
import auditorias from './auditoriasReducer';
import diarios from './diariosReducer';
import novaOrdem from './novaOrdemReducer';
import colaboradores from './colaboradoresReducer';
import messages from './messagesReducer';
import fisico from './fisicoReducer';
import banco from './bancoReducer';
import metas from './metasReducer';
import folhas from './folhasReducer';
import transferencias from './transferenciasReducer';
import pedidos from './pedidosReducer';

export default combineReducers({
  auditorias,
  pedidos,
  transferencias,
  folhas,
  diarios,
  novaOrdem,
  colaboradores,
  messages,
  fisico,
  banco,
  metas,
  user,
  casas,
  emps,
  loteamentos,
  agencias,
  corretores,
  parceiros,
  chamados,
  usuarios,
  perfis,
  investidor,
  dashboard,
  modalidades,
  ccas,
  produtos,
  correspondencia,
  fornecedores,
  materiais,
  ordens,
  empresasfaturamento,
  categorias,
  orcamentos,
  grupos,
  obra,
  legalizacao,
  galeria,
  arquivos,
  conta,
  empresas,
  composicoes,
});
