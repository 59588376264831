import { ExtendedAction } from '@config/reduxStore';
import { Parceiro } from '@models/Parceiro';
import { notification } from 'antd';

interface ParceirosReducer {
  parceiros: Parceiro[];
  formview: boolean;
  parceiro: number;
  loadingparceiros: boolean;
  savingparceiro: boolean;
  feedback: string;
}
var initialState: ParceirosReducer = {
  parceiros: [],
  formview: false,
  parceiro: null,
  loadingparceiros: false,
  savingparceiro: false,
  feedback: '',
};

const parceirosReducer = function (state = initialState, action: ExtendedAction) {
  switch (action.type) {
    case 'RESET_FEEDBACK':
      state = {
        ...state,
        feedback: '',
      };
      break;
    case 'FORM_PARCEIRO':
      state = {
        ...state,
        formview: action.payload.open,
        feedback: '',
        parceiro: action.payload.id ? action.payload.id : null,
      };
      break;
    case 'GET_PARCEIROS_PENDING':
      state = {
        ...state,
        loadingparceiros: true,
        parceiros: initialState.parceiros,
      };
      break;
    case 'GET_PARCEIROS_REJECTED':
      state = {
        ...state,
        loadingparceiros: false,
      };
      break;
    case 'GET_PARCEIROS_FULFILLED':
      state = {
        ...state,
        loadingparceiros: false,
        parceiros: action.payload.data,
      };
      break;
    case 'ADD_PARCEIRO_PENDING':
      state = {
        ...state,
        savingparceiro: true,
        feedback: '',
      };
      break;
    case 'ADD_PARCEIRO_REJECTED':
      state = {
        ...state,
        savingparceiro: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: 'Erro ao cadastrar parceiro!' });
      break;
    case 'ADD_PARCEIRO_FULFILLED':
      state = {
        ...state,
        savingparceiro: false,
        feedback: 'Parceiro criado com sucesso!',
        parceiros: [...state.parceiros, action.payload.data],
      };
      notification.success({ message: 'Parceiro cadastrado com sucesso!' });
      break;
    case 'UPDATE_PARCEIRO_PENDING':
      state = {
        ...state,
        savingparceiro: true,
        feedback: '',
      };
      break;
    case 'UPDATE_PARCEIRO_REJECTED':
      state = {
        ...state,
        savingparceiro: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: 'Erro ao atualizar parceiro!' });
      break;
    case 'UPDATE_PARCEIRO_FULFILLED':
      state = {
        ...state,
        savingparceiro: false,
        feedback: 'Parceiro atualizado com sucesso!',
        parceiros: state.parceiros.map((c) => {
          if (c.id === action.payload.data.id) return action.payload.data;
          else return c;
        }),
      };
      notification.success({ message: 'Parceiro atualizado com sucesso!' });
      break;
    case 'DELETE_PARCEIRO_PENDING':
      state = {
        ...state,
        loadingparceiros: true,
        feedback: '',
      };
      break;
    case 'DELETE_PARCEIRO_REJECTED':
      state = {
        ...state,
        loadingparceiros: false,
        feedback: action.payload.response.data.Message,
      };
      notification.warning({ message: state.feedback });
      break;
    case 'DELETE_PARCEIRO_FULFILLED':
      state = {
        ...state,
        loadingparceiros: false,
        feedback: 'Parceiro excluído com sucesso!',
        parceiros: state.parceiros.filter((c) => c.id !== action.payload.data),
      };
      notification.success({ message: state.feedback });
      break;
    default:
      return state;
  }
  return state;
};

export default parceirosReducer;
