import { Table } from 'antd';

function PrintPage(props: any) {
  const { title, dataSource, columns } = props;
  return (
    <div>
      <h3>{title}</h3>
      <Table
        style={{ fontSize: '7px' }}
        rowKey="id"
        columns={columns.filter((c: any) => c.dataIndex !== 'id')}
        dataSource={dataSource}
        size="small"
        pagination={false}
      />
    </div>
  );
}

export default PrintPage;
