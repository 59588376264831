import { ExtendedAction } from '@config/reduxStore';
import { EmpresaFaturamento } from '@models/EmpresaFaturamento';
import { notification } from 'antd';

interface EmpresasFaturamentoReducer {
  empresasfaturamento: EmpresaFaturamento[];
  formview: boolean;
  empresafaturamento: number;
  loadingempresasfaturamento: boolean;
  savingempresafaturamento: boolean;
  feedback: string;
}
var initialState: EmpresasFaturamentoReducer = {
  empresasfaturamento: [],
  formview: false,
  empresafaturamento: null,
  loadingempresasfaturamento: false,
  savingempresafaturamento: false,
  feedback: '',
};

const empresasfaturamentoReducer = function (state = initialState, action: ExtendedAction) {
  switch (action.type) {
    case 'RESET_FEEDBACK':
      state = {
        ...state,
        feedback: '',
      };
      break;
    case 'FORM_EMPRESAFATURAMENTO':
      state = {
        ...state,
        feedback: '',
        formview: action.payload.open,
        empresafaturamento: action.payload.id ? action.payload.id : null,
      };
      break;
    case 'GET_EMPRESASFATURAMENTO_PENDING':
      state = {
        ...state,
        loadingempresasfaturamento: true,
        empresasfaturamento: initialState.empresasfaturamento,
      };
      break;
    case 'GET_EMPRESASFATURAMENTO_REJECTED':
      state = {
        ...state,
        loadingempresasfaturamento: false,
      };
      break;
    case 'GET_EMPRESASFATURAMENTO_FULFILLED':
      state = {
        ...state,
        loadingempresasfaturamento: false,
        empresasfaturamento: action.payload.data,
      };
      break;
    case 'ADD_EMPRESAFATURAMENTO_PENDING':
      state = {
        ...state,
        savingempresafaturamento: true,
        feedback: '',
      };
      break;
    case 'ADD_EMPRESAFATURAMENTO_REJECTED':
      state = {
        ...state,
        savingempresafaturamento: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: 'Erro ao cadastrar empresa faturamento!' });
      break;
    case 'ADD_EMPRESAFATURAMENTO_FULFILLED':
      state = {
        ...state,
        savingempresafaturamento: false,
        feedback: 'Item criado com sucesso!',
        empresasfaturamento: [...state.empresasfaturamento, action.payload.data],
      };
      notification.success({ message: 'Empresa faturamento cadastrada com sucesso!' });
      break;
    case 'UPDATE_EMPRESAFATURAMENTO_PENDING':
      state = {
        ...state,
        savingempresafaturamento: true,
        feedback: '',
      };
      break;
    case 'UPDATE_EMPRESAFATURAMENTO_REJECTED':
      state = {
        ...state,
        savingempresafaturamento: false,
        feedback: action.payload.response.data.Message,
      };
      break;
    case 'UPDATE_EMPRESAFATURAMENTO_FULFILLED':
      state = {
        ...state,
        savingempresafaturamento: false,
        feedback: 'Item atualizado com sucesso!',
        empresasfaturamento: state.empresasfaturamento.map((c) => {
          if (c.id === action.payload.data.id) return action.payload.data;
          else return c;
        }),
      };
      break;
    case 'DELETE_EMPRESAFATURAMENTO_PENDING':
      state = {
        ...state,
        loadingempresasfaturamento: true,
        feedback: '',
      };
      break;
    case 'DELETE_EMPRESAFATURAMENTO_REJECTED':
      state = {
        ...state,
        loadingempresasfaturamento: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: state.feedback });
      break;
    case 'DELETE_EMPRESAFATURAMENTO_FULFILLED':
      state = {
        ...state,
        loadingempresasfaturamento: false,
        feedback: 'Item excluído com sucesso!',
        empresasfaturamento: state.empresasfaturamento.filter((c) => c.id !== action.payload.data),
      };
      notification.success({ message: state.feedback });
      break;
    default:
      return state;
  }
  return state;
};

export default empresasfaturamentoReducer;
