import { useAppSelector } from '@config/reduxStore';
import { GetPedido } from '@services/api/api.pedidos';
import { Skeleton } from 'antd';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import OrdemStatusTag from '../../Ordens/Shared/OrdemStatusTag';
import '../../../../../assets/scss/PedidoView.scss';
import { doubleMask } from '@utils/masks';

function PedidoViewBody() {
  const { id } = useParams();
  const { data: pedido, isLoading } = GetPedido(id);
  const { user } = useAppSelector((state) => state.user);
  if (isLoading) return <Skeleton active />;
  return (
    <div style={{ margin: 10 }}>
      <table style={{ width: '100%' }} className="pedidoView-header">
        <thead>
          <tr>
            <th rowSpan={6}>
              <img src={user?.configuracao?.logo} alt="logo" height={60} />
            </th>
            <th rowSpan={6} style={{ width: '60%' }} className="title">
              PEDIDO
            </th>
            <th>Pedido Nº</th>
            <th>{pedido?.cod}</th>
          </tr>
          <tr>
            <th>Situação</th>
            <th>{<OrdemStatusTag status={pedido?.situacao} />}</th>
          </tr>
          <tr>
            <th>Data</th>
            <th>{moment(pedido?.dataCriacao).format('DD/MM/YYYY')}</th>
          </tr>
          <tr>
            <th>Orçamento</th>
            <th>{pedido?.orcamento}</th>
          </tr>
          <tr>
            <th>Prazo</th>
            <th>{moment(pedido?.prazoEntrega).format('DD/MM/YYYY')}</th>
          </tr>
          <tr>
            <th>Descrição</th>
            <th>{pedido?.descricao}</th>
          </tr>
        </thead>
      </table>
      <table style={{ width: '100%' }} className="pedidoView-body">
        <tbody>
          <tr>
            <th colSpan={7}>ITENS</th>
          </tr>
          <tr>
            <th>Item</th>
            <th>Categoria</th>
            <th>Grupo</th>
            <th>Item</th>
            <th>Unidade</th>
            <th>Especificação</th>
            <th>Quantidade</th>
          </tr>
          {pedido?.itensPedido?.map((item, index) => (
            <tr key={index}>
              <td className="center">{index + 1}</td>
              <td>{item.categoria}</td>
              <td>{item.grupo}</td>
              <td>{item.item}</td>
              <td className="center">{item.unidade}</td>
              <td>{item.descricao}</td>
              <td className="value">{doubleMask.format(item.quantidade)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <table style={{ width: '100%' }} className="pedidoView-body">
        <tbody>
          <tr>
            <th colSpan={5}>Processamento dos Itens</th>
          </tr>
          <tr>
            <th>Item</th>
            <th>Unidade</th>
            <th>Processado</th>
            <th colSpan={2}>Ordens</th>
          </tr>
          {pedido?.ordens?.map((c) => (
            <>
              <tr key={c.id}>
                <td className="center" rowSpan={c.ordens?.length}>
                  {c.item}
                </td>
                <td className="center" rowSpan={c.ordens?.length}>
                  {c.unidade}
                </td>
                <td className="center" rowSpan={c.ordens?.length}>
                  {doubleMask.format(c.processado)}
                </td>
                {c.ordens?.length > 0 && (
                  <>
                    <td>{c.ordens[0].cod}</td>
                    <td>
                      <OrdemStatusTag status={c.ordens[0].situacao} />
                    </td>
                  </>
                )}
                {c.ordens?.length === 0 && <td />}
              </tr>
              {c.ordens
                ?.filter((_, index) => index > 0)
                .map((o) => (
                  <tr>
                    <td>{o.cod}</td>
                    <td colSpan={3}>
                      <OrdemStatusTag status={o.situacao} />
                    </td>
                  </tr>
                ))}
            </>
          ))}
          {pedido?.ordens?.length === 0 && (
            <tr>
              <td colSpan={3}>Nenhuma ordem associada</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default PedidoViewBody;
