import { Button, Checkbox, Input, Popconfirm, Space, Table, Tooltip } from 'antd';
import { useState } from 'react';
import { useEffect } from 'react';
import { deleteOrcamentoBanco, getOrcamentosBanco } from '@actions/bancoActions';
import { SetPageHeader } from '@actions/userActions';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import NovoOrcamentoModel from './NovoOrcamentoModal';
import { moneyMask } from '@utils/masks';
import { Link } from 'react-router-dom';
import { filterobject, naturalSorter } from '@utils/filterandsort';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { OrcamentoBanco } from '@models/OrcamentoBanco';

function OrcamentosBanco() {
  const dispatch = useAppDispatch();
  const [filtros, setFiltros] = useState({ busca: '', ativos: true, modelo: false });
  const { orcamentosBanco, loadingOrcamentosBanco } = useAppSelector((state) => state.banco);
  const [novo, setNovo] = useState(false);
  useEffect(() => {
    dispatch(getOrcamentosBanco(filtros));
  }, []);
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Planejamentos',
        backbutton: true,
        extras: [
          <Button type="primary" key={0} icon={<PlusOutlined />} onClick={() => setNovo(true)}>
            Novo
          </Button>,
        ],
        subtitle: 'Orçamentos Banco',
        menu: 'orcamentosbanco',
      })
    );
  }, []);
  useEffect(() => {
    dispatch(getOrcamentosBanco(filtros));
  }, [filtros.ativos, filtros.modelo]);
  return (
    <div>
      <NovoOrcamentoModel visible={novo} setVisible={setNovo} />
      <Space>
        <Input
          placeholder="Buscar..."
          value={filtros.busca}
          onChange={(e) => setFiltros({ ...filtros, busca: e.target.value })}
        />
        <Checkbox defaultChecked onChange={(e) => setFiltros({ ...filtros, ativos: e.target.checked })}>
          Ativos
        </Checkbox>
        <Checkbox onChange={(e) => setFiltros({ ...filtros, modelo: e.target.checked })}>Modelos</Checkbox>
      </Space>
      <Table
        size="small"
        dataSource={[...filterobject(orcamentosBanco, filtros.busca)].sort((a, b) =>
          naturalSorter(a.descricao, b.descricao)
        )}
        loading={loadingOrcamentosBanco}
        rowKey="id"
      >
        <Table.Column
          dataIndex="id"
          width={70}
          render={(v) => (
            <Space>
              <Tooltip title="Editar">
                <Link to={'/OrcamentoBancoForm/' + v}>
                  <Button icon={<EditOutlined />} size="small" />
                </Link>
              </Tooltip>
              <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(deleteOrcamentoBanco(v))}>
                <Button icon={<DeleteOutlined />} size="small" />
              </Popconfirm>
            </Space>
          )}
        />
        <Table.Column
          title="Descrição"
          dataIndex="descricao"
          ellipsis
          sorter={(a, b: OrcamentoBanco) => naturalSorter(a.descricao, b.descricao)}
        />
        <Table.Column title="Valor Total" dataIndex="valorTotal" align="right" render={(v) => moneyMask.format(v)} />
      </Table>
    </div>
  );
}

export default OrcamentosBanco;
