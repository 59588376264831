import { AppDispatch } from '@config/reduxStore';
import { apiEndpoints } from '@constants/apiEndpoints';
import { ModalidadeCredito } from '@models/ModalidadeCredito';
import { Axios } from '@config/axios';
export const GetModalidades = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_MODALIDADES',
      payload: Axios.get(apiEndpoints.MODALIDADES.GET_MODALIDADES),
    });
  };
};
export const SalvarModalidade = function (values: ModalidadeCredito) {
  return function (dispatch: AppDispatch) {
    if (values.id) {
      dispatch({
        type: 'UPDATE_MODALIDADE',
        payload: Axios.post(apiEndpoints.MODALIDADES.SALVAR_MODALIDADE, { ...values }),
      });
    } else {
      dispatch({
        type: 'ADD_MODALIDADE',
        payload: Axios.post(apiEndpoints.MODALIDADES.SALVAR_MODALIDADE, { ...values }),
      });
    }
  };
};
export const DeleteModalidade = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DELETE_MODALIDADE',
      payload: Axios.get(apiEndpoints.MODALIDADES.DELETE_MODALIDADE, { params: { id } }),
    });
  };
};
export const FormModalidade = function (id: number, open: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'FORM_MODALIDADE',
      payload: { id, open },
    });
  };
};
