import { useEffect } from 'react';
import { Table, Progress, Divider } from 'antd';
import { GetObra } from '@actions/obraActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { ProcessoEmpreendimento } from '@models/ProcessoEmpreendimento';

function TableObraReport() {
  const dispatch = useAppDispatch();
  const obra = useAppSelector((state) => state.obra);
  const { user } = useAppSelector((state) => state.user);
  useEffect(() => {
    dispatch(GetObra(user.casaid));
  }, [user.casaid]);
  return (
    <div>
      <Divider orientation="left">Progresso da Obra</Divider>
      <Table pagination={false} loading={obra.loading} size="small" rowKey="id" dataSource={obra.items}>
        <Table.Column title="Item" render={(_, __, index) => index + 1} />
        <Table.Column title="Descrição" dataIndex="descricao" />
        <Table.Column
          width={150}
          title="Progresso"
          dataIndex="progresso"
          render={(_, row: ProcessoEmpreendimento) => <Progress percent={row.progresso} />}
        />
      </Table>
    </div>
  );
}

export default TableObraReport;
