import { useEffect, useState } from 'react';
import { Table, Button, Tooltip, Modal, Typography, Tag, Steps, Select, Popconfirm } from 'antd';
import { InfoOutlined, EyeOutlined, StopOutlined, FormOutlined, DollarOutlined } from '@ant-design/icons';
import { getColumnSearchProps, sortAlpha, orderDate, naturalSorter } from '@utils/filterandsort';
import { GetAprovadores, SetPageHeader } from '@actions/userActions';
import { Link } from 'react-router-dom';
import { moneyMask, doubleMask, moneyNegMask } from '@utils/masks';
import moment from 'moment';
import { GetMateriaisList } from '@actions/materiaisActions';
import { Permission } from '@hooks//usePermissions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { TransferenciaList } from '@models/Transferencia';
import { FinalizarTransferencia, GetTransferencias } from '@actions/transferenciasActions';
import { OrdemStatus, ordemType } from '@constants/ordemStatus';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { ViewCancelForm } from '@actions/novaOrdemActions';
import CancelTransferenciaModalRedux from '../Shared/CancelTransferenciaModalRedux';

function TransferenciasView() {
  const dispatch = useAppDispatch();
  const { transferencias, loadingTransferencias, total } = useAppSelector((state) => state.transferencias);
  const { aprovadores } = useAppSelector((state) => state.user);
  const { materiaislist, loadingmateriais } = useAppSelector((state) => state.materiais);
  const [filters, setFilters] = useState<{
    pagination: TablePaginationConfig;
    filters: Record<string, FilterValue>;
    sorter: SorterResult<TransferenciaList> | SorterResult<TransferenciaList>[];
  }>(null);
  const [itemID, setitemID] = useState<number>(null);
  const [motivomodal, setmotivomodal] = useState<{ visible: boolean; motivo: string }>({ visible: false, motivo: '' });

  useEffect(() => {
    dispatch(
      GetTransferencias({
        filtros: {
          pagination: {
            current: 1,
            pageSize: 10,
          },
          filters: {
            cod: null,
            descricao: null,
            origem: null,
            comprador: null,
            dataCriacao: null,
            total: null,
          },
          sorter: {
            field: null,
            order: null,
          },
        },
        itemID: null,
        my: false,
      })
    );
    dispatch(GetMateriaisList());
    dispatch(GetAprovadores(ordemType.transferencia));
    dispatch(
      SetPageHeader({
        title: 'Engenharia',
        backbutton: true,
        extras: [
          <Link to="/NovaTransferencia">
            <Button size="small" type="primary">
              Nova Transferência
            </Button>
          </Link>,
        ],
        subtitle: 'Ordens de Transferência',
        menu: 'transferencias',
      })
    );
  }, []);
  const columns: ColumnsType<TransferenciaList> = [
    {
      width: 50,
      fixed: 'left',
      render: (_, row) => (
        <div>
          {row.situacao === OrdemStatus.Aprovada && (
            <>
              <Permission permission="ordem.financeiro">
                <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(FinalizarTransferencia(row.id))}>
                  <Tooltip title="Processar Financeiro">
                    <Button icon={<DollarOutlined />} size="small" />
                  </Tooltip>
                </Popconfirm>
              </Permission>
            </>
          )}
          {!row.cancelada && (
            <>
              <Tooltip title="Imprimir">
                <Link to={`/PrintOrdemTransferencia/${row.id}`}>
                  <Button icon={<EyeOutlined />} size="small" />
                </Link>
              </Tooltip>
              <Tooltip title="Cancelar Ordem">
                <Button
                  icon={<StopOutlined />}
                  size="small"
                  onClick={() => dispatch(ViewCancelForm({ view: true, id: row.id, replace: false, cod: row.cod }))}
                />
              </Tooltip>
            </>
          )}
          {row.cancelada && (
            <>
              <Link to={`/PrintOrdemTransferencia/${row.id}`}>
                <Button icon={<EyeOutlined />} size="small" />
              </Link>
              <Button
                icon={<InfoOutlined />}
                size="small"
                onClick={() => setmotivomodal({ visible: true, motivo: row.motivo })}
              />
            </>
          )}
        </div>
      ),
    },
    {
      title: 'Nº Ordem',
      dataIndex: 'cod',
      sorter: (a, b) => naturalSorter(a.cod, b.cod),
      render: (_, row) => row.cod,
      ...getColumnSearchProps('cod', 'string', 'Nº Ordem', filters),
    },
    {
      title: 'Situação',
      width: 200,
      dataIndex: 'situacao',
      sorter: (a, b) => sortAlpha(a.situacao, b.situacao),
      filters: [
        { text: OrdemStatus.EmProcessamentoFinanceiro, value: OrdemStatus.EmProcessamentoFinanceiro },
        { text: OrdemStatus.PendenteAprovacao, value: OrdemStatus.PendenteAprovacao },
        { text: OrdemStatus.Cancelada, value: OrdemStatus.Cancelada },
        { text: OrdemStatus.Finalizada, value: OrdemStatus.Finalizada },
        { text: OrdemStatus.Aprovada, value: OrdemStatus.Aprovada },
        { text: OrdemStatus.Rascunho, value: OrdemStatus.Rascunho },
      ],
      onFilter: (value, row) => row.situacao.includes(value as string),
      filteredValue: filters ? filters.filters.situacao : null,
      render: (text) => (
        <Tag
          color={
            text === OrdemStatus.EmProcessamentoFinanceiro
              ? 'orange'
              : text === OrdemStatus.PendenteAprovacao
              ? 'default'
              : text === OrdemStatus.Finalizada
              ? 'green'
              : text === OrdemStatus.Cancelada
              ? 'red'
              : text === OrdemStatus.Aprovada
              ? 'blue'
              : 'default'
          }
          icon={text === 'Rascunho' ? <FormOutlined /> : null}
        >
          {text}
        </Tag>
      ),
    },
    {
      title: 'Descrição',
      dataIndex: 'descricao',
      ...getColumnSearchProps('descricao', 'string', 'Descrição', filters),
    },
    {
      title: 'Aprovação',
      dataIndex: 'id',
      width: 160,
      render: (_, row) => (
        <Steps direction="vertical" size="small">
          <Steps.Step
            title={
              row.aprovacao1 ? (
                row.aprovador1
              ) : (
                <div>
                  {aprovadores.filter((c) => !c.aprovaPedido).length > 0
                    ? aprovadores
                        .filter((c) => !c.aprovaPedido)
                        .filter((c) => c.nivelTransferencias === 1)
                        .map((c) => <div>{c.nome}</div>)
                    : null}
                </div>
              )
            }
            status={row.aprovacao1 ? 'finish' : 'wait'}
          />
          <Steps.Step
            title={
              row.aprovacao2 ? (
                row.aprovador2
              ) : (
                <div>
                  {aprovadores.filter((c) => !c.aprovaPedido).length > 0
                    ? aprovadores
                        .filter((c) => !c.aprovaPedido)
                        .filter((c) => c.nivelTransferencias === 2)
                        .map((c) => <div>{c.nome}</div>)
                    : null}
                </div>
              )
            }
            status={row.aprovacao2 ? 'finish' : 'wait'}
          />
          <Steps.Step
            title={
              row.aprovacao3 ? (
                row.aprovador3
              ) : (
                <div>
                  {aprovadores.filter((c) => !c.aprovaPedido).length > 0
                    ? aprovadores
                        .filter((c) => !c.aprovaPedido)
                        .filter((c) => c.nivelTransferencias === 3)
                        .map((c) => <div>{c.nome}</div>)
                    : null}
                </div>
              )
            }
            status={row.aprovacao2 ? 'finish' : 'wait'}
          />
        </Steps>
      ),
    },
    {
      title: 'Solicitante',
      dataIndex: 'comprador',
      sorter: true,
      ...getColumnSearchProps('comprador', 'string', 'Solicitante', filters),
    },
    {
      title: 'Data Criação',
      dataIndex: 'dataCriacao',
      sorter: (a, b) => orderDate(a.dataCriacao, b.dataCriacao),
      ...getColumnSearchProps('dataCriacao', 'daterange', 'Data Criação', filters),
      render: (text) => moment(text).format('DD/MM/YYYY'),
    },
    {
      title: 'Origem',
      dataIndex: 'origem',
      sorter: (a, b) => sortAlpha(a.origem, b.origem),
      ...getColumnSearchProps('origem', 'string', 'Orcamento', filters),
    },
    {
      title: 'Valor',
      dataIndex: 'total',
      sorter: (a, b) => Number(a.total) - Number(b.total),
      render: (text) => moneyNegMask.format(text),
    },
  ];
  return (
    <div>
      <CancelTransferenciaModalRedux />
      <Modal
        title="Motivo Cancelamento"
        open={motivomodal.visible}
        footer={[<Button onClick={() => setmotivomodal({ visible: false, motivo: '' })}>Fechar</Button>]}
        onCancel={() => setmotivomodal({ visible: false, motivo: '' })}
      >
        <Typography.Text>{motivomodal.motivo}</Typography.Text>
      </Modal>
      <Select
        loading={loadingmateriais}
        showSearch
        optionFilterProp="children"
        dropdownMatchSelectWidth
        allowClear
        value={itemID}
        onChange={(v) => {
          setitemID(v);
          dispatch(GetTransferencias({ filtros: filters, itemID: v }));
        }}
        style={{ width: 300 }}
        placeholder="Pesquisar por item..."
        //onChange={(v) => dispatch(GetTransferencias(null, v, false))}
      >
        {materiaislist.map((c) => (
          <Select.Option key={c.id} value={c.id}>{`${c.unidade} - ${c.descricao}`}</Select.Option>
        ))}
      </Select>
      <Table
        size="small"
        rowKey="id"
        onChange={(pagination, filters, sorter) => {
          setFilters({ pagination, filters, sorter });
          dispatch(GetTransferencias({ filtros: { pagination, filters, sorter }, itemID: itemID }));
        }}
        loading={loadingTransferencias}
        columns={columns}
        dataSource={transferencias}
        scroll={{ y: 'calc(100vh - 300px)', x: 1200 }}
        pagination={{
          position: ['bottomRight'],
          total: total,
          size: 'small',
          showSizeChanger: true,
          pageSizeOptions: [10, 25, 50, 100],
          showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
        }}
        expandedRowRender={(row) => (
          <div className="ordem" style={{ overflowX: 'auto' }}>
            <table style={{ width: '100%', fontSize: 10 }}>
              <tbody>
                <tr>
                  <th colSpan={10}>Itens</th>
                </tr>
                <tr>
                  <th style={{ width: 30 }}></th>
                  <th>Orçamento</th>
                  <th>Categoria</th>
                  <th>Grupo</th>
                  <th>Item</th>
                  <th>V. Unitário</th>
                  <th>Quantidade</th>
                  <th>V. Total</th>
                </tr>
                {row.ordemItens.map((c, index) => (
                  <tr key={index} className={c.estourado ? 'naoorcado' : null}>
                    <td>{index + 1}</td>
                    <td>{c.orcamento as string}</td>
                    <td>{c.categoria as string}</td>
                    <td>{c.grupo as string}</td>
                    <td>{c.item as string}</td>
                    <td>{moneyMask.format(c.valorUnitario)}</td>
                    <td>{doubleMask.format(c.quantidade)}</td>
                    <td>{moneyNegMask.format(c.valorTotal)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      />
    </div>
  );
}
export default TransferenciasView;
