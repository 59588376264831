import useFacilitar from '@hooks/useFacilitar';
import { Typography } from 'antd';

function PoliticaPrivacidade() {
  const facilitar = useFacilitar();
  return (
    <div>
      <Typography.Title level={2}>Política de Privacidade</Typography.Title>
      <Typography.Text>
        {facilitar ? 'A Faclitar' : 'O Grupo Elevar'} tem total compromisso com a privacidade e a segurança dos dados
        dos clientes cadastrados no sistema após assinatura de contrato. Todos os dados coletados são utilizados única e
        exclusivamente para database própria, para a qual entendemos que o cliente está ciente e de total acordo, uma
        vez que assinou contrato fornecendo suas informações pessoais para uso interno. Reafirmamos nosso compromisso de
        não vender, trocar ou divulgar os dados coletados para terceiros. Manteremos suas informações em sigilo
        absoluto. Para que estes dados permaneçam intactos, não aconselhamos expressamente a divulgação de sua senha a
        terceiros, mesmo a amigos e parentes. As alterações sobre nossa política de privacidade serão devidamente
        informadas neste espaço.
      </Typography.Text>
    </div>
  );
}

export default PoliticaPrivacidade;
