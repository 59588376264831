import { OrdemStatus } from '@constants/ordemStatus';
import { Tag } from 'antd';
import { EditOutlined, CheckOutlined } from '@ant-design/icons';
function TransferenciaStatusTag({ value }: { value?: string }) {
  const color =
    value === OrdemStatus.Cancelada || value === OrdemStatus.Substituida
      ? 'red'
      : value === OrdemStatus.Finalizada
      ? 'green'
      : value === OrdemStatus.PendenteDocumentos
      ? 'orange'
      : value === OrdemStatus.PendentePagamento || value === OrdemStatus.PagamentoParcial
      ? 'orange'
      : value === OrdemStatus.Aprovada
      ? 'blue'
      : undefined;

  return (
    <Tag
      color={color}
      icon={
        value === OrdemStatus.Rascunho ? (
          <EditOutlined />
        ) : value === OrdemStatus.Aprovada ? (
          <CheckOutlined />
        ) : undefined
      }
    >
      {value}
    </Tag>
  );
}
export default TransferenciaStatusTag;
