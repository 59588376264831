import LoadingText from '@components/LoadingText';
import { OrdemStatus } from '@constants/ordemStatus';
import { OrdemItem } from '@models/OrdemItem';
import { PedidoItem } from '@models/Pedidos';
import { GerarPedidoObras, GetPedidoItens } from '@services/api/api.pedidos';
import { filterobject, naturalSorter } from '@utils/filterandsort';
import { doubleMask, moneyMask } from '@utils/masks';
import { Button, Input, Popconfirm, Space, Spin, Table, Tag } from 'antd';
import { useState } from 'react';

function TableItensObras({ status, prazo }: { status: string; prazo: [moment.Moment, moment.Moment] }) {
  const { data, isLoading, isFetching } = GetPedidoItens(status, prazo, 'Orçamento');
  const [filter, setFilter] = useState<string>('');
  const [selectedItens, setSelectedItens] = useState<{ [key in number]: OrdemItem[] }>([]);
  const gerarPedido = GerarPedidoObras();
  const expandedRow = (data: PedidoItem) => {
    return (
      <div>
        <Table
          size="small"
          dataSource={data.itens.sort((a, b) => naturalSorter(a.item as string, b.item as string))}
          pagination={false}
          scroll={{ y: 400 }}
          rowKey="itemID"
          rowSelection={{
            getCheckboxProps() {
              return { disabled: status === OrdemStatus.PendenteAprovacao };
            },
            selectedRowKeys: selectedItens[data.orcamentoID]?.map((c) => c.itemID) || [],
            onChange: (_, selectedRows) => {
              setSelectedItens((pv) => ({ ...pv, [data.orcamentoID]: selectedRows }));
            },
          }}
        >
          <Table.Column dataIndex="item" title="Item" />
          <Table.Column dataIndex="unidade" title="Unidade" width={80} align="center" />
          <Table.Column
            dataIndex="quantidadeSolicitada"
            title="Quantidade Solicitada"
            align="right"
            render={(v) => doubleMask.format(v)}
            width={160}
          />
          <Table.Column
            dataIndex="quantidadeProcessada"
            title="Quantidade Processada"
            align="right"
            render={(v) => doubleMask.format(v)}
            width={170}
          />
          <Table.Column
            dataIndex="saldoPendente"
            title="Saldo Pendente"
            align="right"
            render={(v) => doubleMask.format(v)}
            width={100}
          />
          <Table.Column
            title="Valor Orçado"
            dataIndex="valorOrcado"
            render={(v) => moneyMask.format(v)}
            align="right"
            width={120}
            sorter={(a, b: PedidoItem) => a.valorOrcado - b.valorOrcado}
          />
        </Table>
      </div>
    );
  };
  const TableTitle = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {!isFetching && <div />}
        {isFetching && <LoadingText text="Atualizando dados" />}
        <Space>
          <Button ghost type="primary" size="small" onClick={() => setSelectedItens({})}>
            Limpar Seleção
          </Button>
          <Popconfirm
            title="Está certo disso?"
            disabled={Object.keys(selectedItens).every((c) => selectedItens[Number(c)].length === 0)}
            onConfirm={() => {
              const itens: OrdemItem[] = [];
              Object.keys(selectedItens).forEach((c) => itens.push(...selectedItens[Number(c)]));
              gerarPedido.mutate({ itens, situacao: status });
              //console.log(itens);
            }}
          >
            <Button
              size="small"
              type="primary"
              disabled={Object.keys(selectedItens).every((c) => selectedItens[Number(c)].length === 0)}
            >
              {`Processar ${Object.keys(selectedItens).map((c) => c.length > 0).length} Orçamentos`}
            </Button>
          </Popconfirm>
        </Space>
      </div>
    );
  };
  return (
    <Spin spinning={gerarPedido.isLoading}>
      <Input.Search
        placeholder="Buscar..."
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        style={{ width: 300, float: 'right', marginBottom: 8 }}
      />
      <Table
        title={TableTitle}
        size="small"
        dataSource={filterobject(data, filter)}
        loading={isLoading}
        rowKey="orcamentoID"
        scroll={{ y: `calc(100vh - ${isFetching ? '310px' : '290px'})` }}
        expandable={{
          expandedRowRender: expandedRow,
        }}
        pagination={{
          position: ['bottomRight'],
          size: 'small',
          showSizeChanger: true,
          pageSizeOptions: [10, 25, 50, 100],
          showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
        }}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row style={{ fontWeight: 'bold' }}>
              <Table.Summary.Cell index={0} align="right" colSpan={4}>
                TOTAL
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} align="right">
                {moneyMask.format(data?.reduce((a, b) => a + b.valorOrcado, 0) || 0)}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      >
        <Table.Column
          dataIndex="orcamentoID"
          width={90}
          align="center"
          title="Selecionados"
          render={(v) => (
            <Tag color={selectedItens[v]?.length || 0 ? 'blue' : undefined}>{selectedItens[v]?.length || 0}</Tag>
          )}
        />
        <Table.Column
          title="Orçamento"
          dataIndex="orcamento"
          sorter={(a, b: PedidoItem) => naturalSorter(a.orcamento, b.orcamento)}
        />
        <Table.Column
          title="Itens"
          dataIndex="id"
          render={(_, row: PedidoItem) => row.itens.length}
          align="center"
          width={80}
          sorter={(a, b) => a.itens.length - b.itens.length}
        />
        <Table.Column
          title="Valor Orçado"
          dataIndex="valorOrcado"
          render={(v) => moneyMask.format(v)}
          align="right"
          width={120}
          sorter={(a, b: PedidoItem) => a.valorOrcado - b.valorOrcado}
        />
      </Table>
    </Spin>
  );
}
export default TableItensObras;
