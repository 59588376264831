import { GetPendentes } from '@actions/transferenciasActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { apiEndpoints } from '@constants/apiEndpoints';
import { CancelarOrdemModel, FinalizarOrdemModel, SubmeterOrdemModel } from '@models/OrdemItem';
import { TransferenciaForm } from '@models/Transferencia';
import { User } from '@models/User';
import { notification } from 'antd';
import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { fetcher, poster } from './api';

export interface SalvarOrdemResult {
  id: number;
  cod: string;
  situacao: string;
}

export const GetSolicitantes = () => {
  return useQuery<User[], AxiosError<any>>(
    ['solicitantesList'],
    () => fetcher<User[]>(apiEndpoints.ORDENS.GET_SOLICITANTES, { params: { ativo: true } }),
    {
      initialData: [],
      staleTime: 0,
      onError: (err) => console.log(err.response?.data?.Message),
    }
  );
};
export const CancelarTransferencia = () => {
  const queryClient = useQueryClient();
  return useMutation<void, AxiosError<any>, CancelarOrdemModel>(
    (data) => fetcher<void>(apiEndpoints.TRANSFERENCIAS.CANCELAR, { params: data }),
    {
      onSuccess: (_, variables) => {
        notification.success({ message: 'Transferência cancelada com sucesso!' });
        queryClient.invalidateQueries(['transferenciaEdit', String(variables.id)]);
        queryClient.invalidateQueries(['transferencia', String(variables.id)]);
      },
      onError: (err) => {
        notification.error({ message: err.response?.data?.Message });
      },
    }
  );
};
export const FinalizarTransferencia = () => {
  const queryClient = useQueryClient();
  return useMutation<number, AxiosError<any>, FinalizarOrdemModel>(
    (data) => fetcher<number>(apiEndpoints.TRANSFERENCIAS.FINALIZAR, { params: data }),
    {
      onSuccess: (_, variables) => {
        notification.success({ message: 'Transferência finalizada com sucesso!' });
        queryClient.invalidateQueries(['transferenciaEdit', String(variables.id)]);
        queryClient.invalidateQueries(['transferencia', String(variables.id)]);
      },
      onError: (err) => {
        notification.error({ message: err.response?.data?.Message });
      },
    }
  );
};
export const SalvarTransferencia = () => {
  return useMutation<TransferenciaForm, AxiosError<any>, TransferenciaForm>(
    'salvarTransferencia',
    (data) => poster<TransferenciaForm>(apiEndpoints.TRANSFERENCIAS.SALVAR, data),
    {
      onSuccess: (res) => {
        notification.success({ message: 'Transferência finalizada com sucesso!' });
        window.location.href = '/Transferencia/' + res;
      },
      onError: (err) => {
        notification.error({ message: err.response?.data?.Message });
      },
    }
  );
};
export const SubmeterTransferencia = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  return useMutation<void, AxiosError<any>, SubmeterOrdemModel>(
    (data) => fetcher<void>(apiEndpoints.TRANSFERENCIAS.SUBMETER, { params: data }),
    {
      onSuccess: (_, variables) => {
        notification.success({ message: 'Transferência submetida com sucesso!' });
        queryClient.invalidateQueries(['transferenciaEdit', String(variables.id)]);
        queryClient.invalidateQueries(['transferencia', String(variables.id)]);
        if (user.roles.includes('aprovador')) dispatch(GetPendentes());
      },
      onError: (err) => notification.error({ message: err.response?.data?.Message }),
    }
  );
};
export const AprovarTransferencia = () => {
  const queryClient = useQueryClient();
  return useMutation<number, AxiosError<any>, SubmeterOrdemModel>(
    (data) => fetcher<number>(apiEndpoints.TRANSFERENCIAS.APROVAR, { params: data }),
    {
      onSuccess: (_, variables) => {
        notification.success({ message: 'Transferência aprovada com sucesso!' });
        queryClient.invalidateQueries(['transferenciaEdit', String(variables.id)]);
        queryClient.invalidateQueries(['transferencia', String(variables.id)]);
      },
      onError: (err) => notification.error({ message: err.response?.data?.Message }),
    }
  );
};
export const GetTransferenciaEdit = (id: string) => {
  return useQuery<TransferenciaForm, AxiosError<any>>(
    ['transferenciaEdit', String(id)],
    () =>
      fetcher<TransferenciaForm>(apiEndpoints.TRANSFERENCIAS.GET_TRANSFERENCIA, {
        params: {
          id: Number(id) || null,
          cod: id,
        },
      }),
    {
      enabled: !!id,
      retry: false,
      staleTime: 1000 * 60 * 1,
      onError: (err) => {
        notification.error({ message: err?.response?.data });
      },
    }
  );
};
export const GetTransferencia = (id: string) => {
  return useQuery<TransferenciaForm, AxiosError<any>>(
    ['transferencia', String(id)],
    () =>
      fetcher<TransferenciaForm>(apiEndpoints.TRANSFERENCIAS.GET_TRANSFERENCIA_VIEW, {
        params: {
          transferenciaID: Number(id) || null,
          cod: id,
        },
      }),
    {
      enabled: !!id,
      retry: false,
      staleTime: 1000 * 60 * 1,
      onError: (err) => {
        notification.error({ message: err?.response?.data });
      },
    }
  );
};
