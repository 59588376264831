import logo from '../../../../assets/img/main.png';
import moment from 'moment';
import { moneyMask, doubleMask, moneyNegMask } from '@utils/masks';
import imgpendente from '../../../../assets/img/ordempendente.png';
import imgcancelada from '../../../../assets/img/ordemcancelada.png';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Ordem } from '@models/Ordem';
import { Orcamento } from '@models/Orcamento';
import { Item } from '@models/Item';
import { OrdemItem } from '@models/OrdemItem';
import { Categoria } from '@models/Categoria';
function FolhaPrint(props: { customLogo: string; folha: Ordem }) {
  const { customLogo, folha } = props;
  let categorias: any = [];
  let itens: any = [];
  if (folha) {
    folha.ordens.forEach((o) => {
      o.ordensItens.forEach((oi) =>
        itens.push({
          categoria: (oi.categoria as Categoria).descricao,
          colaborador: o.fornecedor.nome,
          valor: oi.valorTotal,
        })
      );
    });
    categorias = _.groupBy(itens, 'categoria');
    categorias = Object.entries(categorias).map((c) => ({
      categoria: c[0],
      valor: itens.filter((d: any) => d.categoria === c[0]).reduce((a: any, b: any) => a + b.valor, 0),
    }));
  }
  return (
    folha && (
      <div
        style={{
          overflowX: 'scroll',
          backgroundColor: 'white',
          backgroundImage:
            folha.situacao === 'Pendente de Aprovação'
              ? `url(${imgpendente})`
              : folha.situacao === 'Cancelada'
              ? `url(${imgcancelada})`
              : folha.situacao === 'Rascunho'
              ? `url(${imgpendente})`
              : null,
        }}
      >
        <table className="ordem" style={{ width: '100%', minWidth: '600px' }}>
          <tbody>
            <tr>
              <td style={{ width: 130 }} rowSpan={7}>
                <img style={{ border: 0 }} alt="logo" src={customLogo || logo} height={90} />
              </td>
              <td rowSpan={7} style={{ width: 400 }}>
                <strong style={{ border: 0 }}>ORDEM DE FOLHA</strong>
              </td>
            </tr>
            <tr>
              <td>Folha Nº</td>
              <td>{folha.cod}</td>
            </tr>
            <tr>
              <td>{folha.tipo === 0 ? 'Medido em' : 'Adiantamento em'}</td>
              <td>
                <Link
                  style={{ borderWidth: 0 }}
                  target="_blank"
                  to={`/Folhas/${
                    folha?.tipo === 0 ? folha.adiantamentoPagoPor?.id : folha?.ordens[0].adiantamentoPagoPor?.id
                  }`}
                >
                  {folha?.tipo === 0 ? folha.adiantamentoPagoPor?.cod : folha?.ordens[0].adiantamentoPagoPor?.cod}
                </Link>
              </td>
            </tr>
            <tr>
              <td>Situação</td>
              <td>{folha?.situacao}</td>
            </tr>
            <tr>
              <td>Obra</td>
              <td>{(folha.orcamento as Orcamento).descricao}</td>
            </tr>
            <tr>
              <td>Mês Corrente</td>
              <td>{moment(folha.mesCorrente).format('MM/YYYY')}</td>
            </tr>
            <tr>
              <td>Tipo</td>
              <td>{folha.tipo === 0 ? 'Adiantamento' : 'Medição'}</td>
            </tr>
            <tr>
              <td colSpan={4}>
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th colSpan={folha.tipo === 0 || folha.situacao === 'Pendente de Medição' ? 3 : 10}>
                        Detalhamento da Folha
                      </th>
                    </tr>
                    <tr>
                      <th rowSpan={2}>Fornecedor</th>
                      <th rowSpan={2}>Tipo de Serviço</th>
                      <th rowSpan={2}>Adiantamento</th>
                      {folha.tipo === 1 && folha.situacao !== 'Pendente de Medição' && (
                        <>
                          <th colSpan={5}>Serviços</th>
                          <th rowSpan={2}>Total Medição</th>
                          <th rowSpan={2}>Valor à Receber</th>
                        </>
                      )}
                    </tr>
                    <tr>
                      {folha.tipo === 1 && folha.situacao !== 'Pendente de Medição' && (
                        <>
                          <th>Categoria</th>
                          <th>Serviço</th>
                          <th>Valor Unitário</th>
                          <th>Quantidade</th>
                          <th>Valor Total</th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {folha.ordens.map((o) => {
                        if (folha.tipo === 0 || folha.situacao === 'Pendente de Medição') {
                          return (
                            <tr>
                              <td style={{ textAlign: 'left' }}>{o.fornecedor.nome}</td>
                              <td>{o.fornecedor.funcao}</td>
                              <td style={{ textAlign: 'right' }}>{moneyMask.format(o.adiantamento)}</td>
                            </tr>
                          );
                        } else
                          return o.ordensItens.map((oi, index) => (
                            <>
                              {index === 0 && (
                                <tr>
                                  <td rowSpan={o.ordensItens.length}>{o.fornecedor.nome}</td>
                                  <td rowSpan={o.ordensItens.length}>{o.fornecedor.funcao}</td>
                                  <td rowSpan={o.ordensItens.length}>{moneyMask.format(o.adiantamento)}</td>
                                  {folha.tipo === 1 && (
                                    <>
                                      <td>{(oi.categoria as Categoria).descricao}</td>
                                      <td>{`${(oi.item as Item).unidade} - ${(oi.item as Item).descricao}`}</td>
                                      <td style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
                                        {moneyMask.format(oi.valorUnitario)}
                                      </td>
                                      <td style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
                                        {doubleMask.format(oi.quantidade)}
                                      </td>
                                      <td style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
                                        {moneyMask.format(oi.valorTotal)}
                                      </td>
                                      <td
                                        style={{ textAlign: 'right', whiteSpace: 'nowrap' }}
                                        rowSpan={o.ordensItens.length}
                                      >
                                        {moneyMask.format(
                                          o.ordensItens.reduce((a, b) => a + (b.valorTotal as number), 0)
                                        )}
                                      </td>
                                      <td
                                        style={{ textAlign: 'right', whiteSpace: 'nowrap' }}
                                        rowSpan={o.ordensItens.length}
                                      >
                                        {moneyNegMask.format(
                                          o.ordensItens.reduce((a, b: OrdemItem) => a + (b.valorTotal as number), 0) -
                                            Number(o.adiantamento) -
                                            o.valorFalta * o.faltas
                                        )}
                                      </td>
                                    </>
                                  )}
                                </tr>
                              )}
                              {index > 0 && folha.tipo === 1 && (
                                <tr>
                                  <td>{(oi.categoria as Categoria).descricao}</td>
                                  <td>{`${(oi.item as Item).unidade} - ${(oi.item as Item).descricao}`}</td>
                                  <td style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
                                    {moneyMask.format(oi.valorUnitario)}
                                  </td>
                                  <td style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
                                    {doubleMask.format(oi.quantidade)}
                                  </td>
                                  <td style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
                                    {moneyMask.format(oi.valorTotal)}
                                  </td>
                                </tr>
                              )}
                            </>
                          ));
                      })}
                      <tr>
                        <td colSpan={2} style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
                          <strong style={{ border: 0 }}>{folha.tipo === 0 ? 'TOTAL' : 'TOTAIS'}</strong>
                        </td>
                        <td style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
                          <strong style={{ border: 0 }}>{moneyMask.format(folha.adiantamento)}</strong>
                        </td>
                        {folha.tipo === 1 && folha.situacao !== 'Pendente de Medição' && (
                          <>
                            <td colSpan={5}></td>
                            <td style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
                              <strong style={{ border: 0 }}>
                                {moneyMask.format(
                                  folha.ordens.reduce(
                                    (a, b) => a + b.ordensItens.reduce((aa, bb) => aa + Number(bb.valorTotal), 0),
                                    0
                                  )
                                )}
                              </strong>
                            </td>
                            <td style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
                              <strong style={{ border: 0 }}>
                                {moneyMask.format(
                                  folha.ordens.reduce(
                                    (a, b) =>
                                      a +
                                      b.ordensItens.reduce((aa, bb) => aa + Number(bb.valorTotal), 0) -
                                      Number(b.adiantamento) -
                                      b.faltas * b.valorFalta,
                                    0
                                  )
                                )}
                              </strong>
                            </td>
                          </>
                        )}
                      </tr>
                    </>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td colSpan={4}>
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th>Observações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{folha.observacao}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td colSpan={4}>
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th colSpan={9}>Forma de Pagamento</th>
                    </tr>
                    <tr>
                      <th>Fornecedor</th>
                      <th>Banco</th>
                      <th>Agência</th>
                      <th>Conta</th>
                      <th>Operação</th>
                      <th>CPF/CNPJ</th>
                      <th>Nome do Titular</th>
                      <th>PIX</th>
                      <th>Receber</th>
                    </tr>
                  </thead>
                  <tbody>
                    {folha.ordens.map((o) => (
                      <tr>
                        <td style={{ textAlign: 'left' }}>{o.fornecedor.nome}</td>
                        <td>{o.fornecedor.banco}</td>
                        <td>{o.fornecedor.agencia}</td>
                        <td>{o.fornecedor.contaBancaria}</td>
                        <td>{o.fornecedor.operacao}</td>
                        <td>{o.fornecedor.cpF_CNPJ_Conta}</td>
                        <td>{o.fornecedor.nomeConta}</td>
                        <td>{o.fornecedor.pix}</td>
                        <td>
                          {moneyNegMask.format(
                            folha.tipo === 1
                              ? o.ordensItens.reduce((a, b) => a + Number(b.valorTotal), 0) - Number(o.adiantamento)
                              : o.adiantamento
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </td>
            </tr>
            {folha.tipo === 1 && folha.situacao !== 'Pendente de Medição' && (
              <tr>
                <td colSpan={4}>
                  <table style={{ width: '100%' }}>
                    <thead>
                      <tr>
                        <th colSpan={3}>Resumo Financeiro</th>
                      </tr>
                      <tr>
                        <th>Categoria</th>
                        <th>Valor</th>
                      </tr>
                    </thead>
                    <tbody>
                      {categorias.map((o: any, index: number) => (
                        <tr key={index}>
                          <td>{o.categoria}</td>
                          <td>{moneyNegMask.format(o.valor)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    )
  );
}
export default FolhaPrint;
