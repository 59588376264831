import { useEffect, useState } from 'react';
import { Table, Input, Button, Popconfirm } from 'antd';
import { GetCCAs, FormCCA, DeleteCCA } from '@actions/ccasActions';
import CCAForm from './CCAForm';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { filterobject, getColumnSearchProps, sortAlpha } from '@utils/filterandsort';
import { Permission } from '@hooks//usePermissions';
import { SetPageHeader } from '@actions/userActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { CCA } from '@models/CCA';

function CCAsView() {
  const ccas = useAppSelector((state) => state.ccas);
  const dispatch = useAppDispatch();
  const [filter, setFilter] = useState('');
  useEffect(() => {
    dispatch(GetCCAs());
    dispatch(
      SetPageHeader({
        title: 'Cadastros',
        backbutton: true,
        extras: [
          <Permission key="1" permission="cca.add">
            <Button onClick={() => dispatch(FormCCA(null, true))} type="primary">
              Novo CCA
            </Button>
          </Permission>,
        ],
        subtitle: 'CCAs',
        menu: 'ccas',
      })
    );
  }, [dispatch]);

  return (
    <div>
      <CCAForm />
      <div style={{ textAlign: 'right' }}>
        <Input style={{ width: 200 }} onChange={(e) => setFilter(e.target.value)} placeholder="Buscar" allowClear />
      </div>
      <Table
        size="small"
        rowKey="id"
        loading={ccas.loadingccas}
        dataSource={filterobject(ccas.ccas, filter)}
        scroll={{ y: 'calc(100vh - 300px)', x: 1024 }}
        pagination={{
          position: ['bottomRight'],
          size: 'small',
          showSizeChanger: true,
          pageSizeOptions: [10, 25, 50, 100],
          showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
        }}
      >
        <Table.Column
          width={80}
          fixed="left"
          render={(_, row: CCA) => (
            <div>
              <Permission permission="cca.update">
                <Button icon={<EditOutlined />} onClick={() => dispatch(FormCCA(row.id, true))} size="small" />
              </Permission>
              <Permission permission="cca.delete">
                <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(DeleteCCA(row.id))}>
                  <Button icon={<DeleteOutlined />} size="small" />
                </Popconfirm>
              </Permission>
            </div>
          )}
        />
        <Table.Column
          title="Nome"
          dataIndex="nome"
          sorter={(a, b) => sortAlpha(a.nome, b.nome)}
          {...getColumnSearchProps('nome', 'string', 'Nome')}
        />
        <Table.Column
          title="Código"
          dataIndex="codigo"
          sorter={(a, b) => sortAlpha(a.codigo, b.codigo)}
          {...getColumnSearchProps('codigo', 'string', 'Código')}
        />
        <Table.Column
          title="Responsável"
          dataIndex="responsavel"
          sorter={(a, b) => sortAlpha(a.responsavel, b.responsavel)}
          {...getColumnSearchProps('noresponsavelme', 'string', 'Responsável')}
        />
        <Table.Column
          title="Contato"
          dataIndex="contato"
          sorter={(a, b) => sortAlpha(a.contato, b.contato)}
          {...getColumnSearchProps('contato', 'string', 'Contato')}
        />
      </Table>
    </div>
  );
}
export default CCAsView;
