import { ExtendedAction } from '@config/reduxStore';
import { Conta } from '@models/Conta';
import { Plano } from '@models/Plano';

interface ContaReducer {
  planos: Plano[];
  modulos: string[];
  loadingmodulos: boolean;
  loadingplanos: boolean;
  creatingaccount: boolean;
  conta: Conta;
  contaID: number;
}

var initialState: ContaReducer = {
  planos: [],
  modulos: [],
  loadingmodulos: false,
  loadingplanos: false,
  creatingaccount: false,
  conta: null,
  contaID: null,
};

const contaReducer = function (state = initialState, action: ExtendedAction) {
  switch (action.type) {
    case 'GET_PLANOS_PENDING':
      state = {
        ...state,
        loadingplanos: true,
      };
      break;
    case 'GET_PLANOS_REJECTED':
      state = {
        ...state,
        loadingplanos: false,
      };
      break;
    case 'GET_PLANOS_FULFILLED':
      state = {
        ...state,
        loadingplanos: false,
        planos: action.payload.data,
      };
      break;
    case 'GET_MODULOS_PENDING':
      state = {
        ...state,
        loadingmodulos: true,
      };
      break;
    case 'GET_MODULOS_REJECTED':
      state = {
        ...state,
        loadingmodulos: false,
      };
      break;
    case 'GET_MODULOS_FULFILLED':
      state = {
        ...state,
        loadingmodulos: false,
        modulos: action.payload.data,
      };
      break;
    case 'CRIAR_CONTA_PENDING':
      state = {
        ...state,
        creatingaccount: true,
      };
      break;
    case 'CRIAR_CONTA_REJECTED':
      state = {
        ...state,
        creatingaccount: false,
      };
      break;
    case 'CRIAR_CONTA_FULFILLED':
      state = {
        ...state,
        creatingaccount: false,
        contaID: action.payload.data,
      };
      break;
    case 'GET_CONTA_PENDING':
      state = {
        ...state,
        creatingaccount: true,
      };
      break;
    case 'GET_CONTA_REJECTED':
      state = {
        ...state,
        creatingaccount: false,
      };
      break;
    case 'GET_CONTA_FULFILLED':
      state = {
        ...state,
        creatingaccount: false,
        conta: action.payload.data,
      };
      break;
    case 'SET_SUBSCRIPTION_PENDING':
      state = {
        ...state,
        creatingaccount: true,
      };
      break;
    case 'SET_SUBSCRIPTION_REJECTED':
      state = {
        ...state,
        creatingaccount: false,
      };
      break;
    case 'SET_SUBSCRIPTION_FULFILLED':
      state = {
        ...state,
        creatingaccount: false,
        conta: action.payload.data,
      };
      break;

    default:
      return state;
  }
  return state;
};

export default contaReducer;
