import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Ordem } from '@models/Ordem';
import { Alert, Button, Input, Modal, Table, Typography } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CloseComprasView, GetComprasItem } from '@actions/orcamentosActions';
import { filterobject, getColumnSearchProps } from '@utils/filterandsort';
import { doubleMask, moneyMask, moneyNegMask } from '@utils/masks';

function ComprasItem() {
  const dispatch = useAppDispatch();
  const { comprasdados, compras, comprasview, loadingcompras } = useAppSelector((state) => state.orcamentos);
  const [filters, setFilters] = useState(null);
  const [filter, setFilter] = useState(null);
  useEffect(() => {
    if (comprasdados?.itemID !== null) dispatch(GetComprasItem(comprasdados));
  }, [comprasdados]);
  return (
    <Modal
      width="90%"
      open={comprasview}
      title={'Compras Realizadas - ' + comprasdados?.descricao}
      onCancel={() => dispatch(CloseComprasView())}
      footer={[
        <Button key="1" onClick={() => dispatch(CloseComprasView())}>
          Fechar
        </Button>,
      ]}
    >
      <div style={{ textAlign: 'right' }}>
        <Input style={{ width: 200 }} onChange={(e) => setFilter(e.target.value)} placeholder="Buscar" allowClear />
      </div>
      <Table
        loading={loadingcompras}
        size="small"
        rowKey="ordemID"
        dataSource={filterobject(compras, filter)}
        scroll={{ x: 1000 }}
        onChange={(paginantion, filters, sorter) => setFilters({ paginantion, filters, sorter })}
        pagination={{
          position: ['bottomRight'],
          size: 'small',
          showSizeChanger: true,
          pageSizeOptions: [10, 25, 50, 100],
          showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
        }}
      >
        <Table.Column
          title="Ordem"
          dataIndex="cod"
          render={(value, row) => (
            <Link
              to={
                row.transferencia
                  ? `/PrintOrdemTransferencia/${row.ordemID}`
                  : row.isFolha
                  ? `/Folhas/${row.ordemID}`
                  : `/PrintOrdem/${row.ordemID}`
              }
            >
              {value}
            </Link>
          )}
          {...getColumnSearchProps('descricao', 'string', 'Descrição', filters)}
        />
        <Table.Column
          title="Tipo"
          dataIndex="id"
          render={(_, row: Ordem) => (row.isFolha ? 'Folha' : row.transferencia ? 'Transferência' : 'Ordem')}
        />
        <Table.Column
          title="Data"
          dataIndex="dataCriacao"
          render={(value) => moment(value).format('DD/MM/YYYY')}
          {...getColumnSearchProps('dataCriacao', 'daterange', 'Data', filters)}
        />
        <Table.Column
          title="Descrição"
          dataIndex="descricao"
          render={(value, row) => (value ? value : row.item)}
          {...getColumnSearchProps('descricao', 'string', 'Descrição', filters)}
        />
        <Table.Column
          title="Quantidade"
          dataIndex="quantidade"
          render={(value) => doubleMask.format(value)}
          {...getColumnSearchProps('quantidade', 'string', 'Quantidade', filters)}
        />
        <Table.Column
          title="Valor Unitário"
          dataIndex="valorUnitario"
          render={(value) => moneyNegMask.format(value)}
          {...getColumnSearchProps('valorUnitario', 'string', 'Valor Unitario', filters)}
        />
        <Table.Column
          title="Desconto"
          dataIndex="desconto"
          render={(value) => moneyMask.format(value)}
          {...getColumnSearchProps('desconto', 'string', 'Desconto', filters)}
        />
        <Table.Column
          title="Valor Total"
          dataIndex="valorTotal"
          render={(value) => moneyNegMask.format(value)}
          {...getColumnSearchProps('valorTotal', 'string', 'Valor Total', filters)}
        />
        <Table.Column
          title="Criador"
          dataIndex="criador"
          {...getColumnSearchProps('criador', 'string', 'Criador', filters)}
        />
        <Table.Column
          title="Solicitante"
          dataIndex="comprador"
          {...getColumnSearchProps('comprador', 'string', 'Solicitante', filters)}
        />
        <Table.Column
          title="Estouro"
          dataIndex="razaoEstouro"
          {...getColumnSearchProps('razaoEstouro', 'string', 'Estouro', filters)}
          render={(value) => (
            <Typography.Text type="danger">
              {value === 0
                ? 'Não orçado'
                : value === 1
                ? 'Valor estourado'
                : value === 2
                ? 'Quantidade estourada'
                : value === 3
                ? 'Valor e quantidade estourados'
                : value === 4
                ? 'Grupo bloqueado'
                : ''}
            </Typography.Text>
          )}
        />
      </Table>
      <Alert message="Valores dos itens não levam em conta descontos globais da ordem de compra" type="info" showIcon />
    </Modal>
  );
}

export default ComprasItem;
