import { AppDispatch } from '@config/reduxStore';
import { apiEndpoints } from '@constants/apiEndpoints';
import { Axios } from '@config/axios';
export const GetMedia = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_MEDIA',
      payload: Axios.get(apiEndpoints.DASHBOARD.GET_MEDIA),
    });
  };
};
export const GetCorretores = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_CORRETORES',
      payload: Axios.get(apiEndpoints.DASHBOARD.GET_CORRETORES),
    });
  };
};
export const GetPrimeiraParcela = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_PRIMEIRA_PARCELA',
      payload: Axios.get(apiEndpoints.DASHBOARD.GET_PRIMEIRA_PARCELA),
    });
  };
};
export const GetArea = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_AREA',
      payload: Axios.get(apiEndpoints.DASHBOARD.GET_AREA),
    });
  };
};
export const GetClientesObra = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_CLIENTESOBRA',
      payload: Axios.get(apiEndpoints.DASHBOARD.GET_CLIENTESOBRA),
    });
  };
};
