import { ExtendedAction } from '@config/reduxStore';
import { MetaOrcamento } from '@models/MetaOrcamento';
import { Orcamento } from '@models/Orcamento';
import { createReducer } from '@reduxjs/toolkit';
import { notification } from 'antd';
import {
  bloquearGrupo,
  bloquearMeta,
  deleteMeta,
  getMetas,
  getOrcamentosMeta,
  salvarMeta,
} from '@actions/metasActions';

interface MetasReducer {
  metas: MetaOrcamento[];
  loadingMetas: boolean;
  orcamentos: Orcamento[];
  loadingOrcamentos: boolean;
}
const initialState: MetasReducer = {
  metas: [],
  loadingMetas: false,
  orcamentos: [],
  loadingOrcamentos: false,
};

export default createReducer(initialState, (builder) => {
  builder.addCase(getMetas.pending, (state) => {
    return {
      ...state,
      loadingMetas: true,
    };
  });
  builder.addCase(getMetas.rejected, (state) => {
    notification.error({ message: 'Erro ao carregar metas' });
    return {
      ...state,
      loadingMetas: false,
    };
  });
  builder.addCase(getMetas.fulfilled, (state, action: ExtendedAction) => {
    return {
      ...state,
      loadingMetas: false,
      metas: action.payload.data,
    };
  });
  builder.addCase(getOrcamentosMeta.pending, (state) => {
    return {
      ...state,
      loadingOrcamentos: true,
    };
  });
  builder.addCase(getOrcamentosMeta.rejected, (state) => {
    notification.error({ message: 'Erro ao carregar orçamentos' });
    return {
      ...state,
      loadingOrcamentos: false,
    };
  });
  builder.addCase(getOrcamentosMeta.fulfilled, (state, action: ExtendedAction) => {
    return {
      ...state,
      loadingOrcamentos: false,
      orcamentos: action.payload.data,
    };
  });
  builder.addCase(salvarMeta.pending, (state) => {
    return {
      ...state,
      loadingMetas: true,
    };
  });
  builder.addCase(salvarMeta.rejected, (state) => {
    notification.error({ message: 'Erro ao criar meta' });
    return {
      ...state,
      loadingMetas: false,
    };
  });
  builder.addCase(salvarMeta.fulfilled, (state, action: ExtendedAction) => {
    notification.success({ message: 'Meta salva com sucesso!' });
    return {
      ...state,
      loadingMetas: false,
      metas: action.payload.data.novo
        ? [...state.metas, action.payload.data.entity]
        : [...state.metas.filter((c) => c.id !== action.payload.data.entity.id), action.payload.data.entity],
    };
  });
  builder.addCase(deleteMeta.pending, (state) => {
    return {
      ...state,
      loadingMetas: true,
    };
  });
  builder.addCase(deleteMeta.rejected, (state) => {
    notification.error({ message: 'Erro ao excluir meta' });
    return {
      ...state,
      loadingMetas: false,
    };
  });
  builder.addCase(deleteMeta.fulfilled, (state, action: ExtendedAction) => {
    notification.success({ message: 'Meta excluída com sucesso!' });
    return {
      ...state,
      loadingMetas: false,
      metas: state.metas.filter((c) => c.id !== action.payload.data),
    };
  });
  builder.addCase(bloquearGrupo.pending, (state) => {
    return {
      ...state,
      loadingMetas: true,
    };
  });
  builder.addCase(bloquearGrupo.rejected, (state) => {
    notification.error({ message: 'Erro ao bloquear grupo' });
    return {
      ...state,
      loadingMetas: false,
    };
  });
  builder.addCase(bloquearGrupo.fulfilled, (state, action: ExtendedAction) => {
    notification.success({
      message: 'Grupo ' + (action.payload.data.bloqueado ? 'bloqueado' : 'desbloqueado') + ' com sucesso!',
    });
    return {
      ...state,
      loadingMetas: false,
      metas: state.metas.map((c) => {
        if (c.id === action.payload.data.metaid) {
          return {
            ...c,
            bloqueado: action.payload.data.bloqueado,
          };
        } else return c;
      }),
    };
  });
  builder.addCase(bloquearMeta.pending, (state) => {
    return {
      ...state,
      loadingMetas: true,
    };
  });
  builder.addCase(bloquearMeta.rejected, (state) => {
    notification.error({ message: 'Erro ao bloquear meta' });
    return {
      ...state,
      loadingMetas: false,
    };
  });
  builder.addCase(bloquearMeta.fulfilled, (state, action: ExtendedAction) => {
    notification.success({
      message: 'Meta ' + (action.payload.data.bloquear ? 'bloqueada' : 'desbloqueada') + ' com sucesso!',
    });
    return {
      ...state,
      loadingMetas: false,
      metas: state.metas.map((c) => {
        if (c.id === action.payload.data.id) {
          return {
            ...c,
            metaBloqueada: action.payload.data.bloquear,
          };
        } else return c;
      }),
    };
  });
});
