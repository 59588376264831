import { useState } from 'react';
import { Statistic, Row, Col, Card, Select, Radio, Progress, Divider } from 'antd';
import { CheckOutlined, SettingOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { useAppSelector } from '@config/reduxStore';

function DashboardChamados() {
  const [filterDados, setFilterDados] = useState<string>('todos');
  const [filterOption, setFilterOption] = useState<string>('todos');
  const chamados = useAppSelector((state) => state.chamados);
  const totaltotal = chamados.chamados;
  const totalencerrados = chamados.chamados.filter((c) => c.concluido);
  const totalemaberto = chamados.chamados.filter((c) => !c.concluido);
  const dados: {
    [key: string]: any;
    todos: any[];
    empresas: string[];
    loteamentos: string[];
    empreendimentos: string[];
  } = {
    todos: [],
    empresas: [...new Set(chamados.chamados.map((c) => c.empresa))],
    loteamentos: [...new Set(chamados.chamados.map((c) => c.loteamento))],
    empreendimentos: [...new Set(chamados.chamados.map((c) => c.empreendimento))],
  };
  const nota1 =
    Math.ceil(
      (chamados.chamados.map((c) => c.nota1).reduce((a, b) => a + (b === 'Excelente' || b === 'Bom' ? 1 : 0), 0) /
        chamados.chamados.filter((c) => c.nota1 === 'Excelente' || c.nota1 === 'Bom' || c.nota1 === 'Ruim').length) *
        100 *
        10
    ) / 10 || 100;
  const nota2 =
    Math.ceil(
      (chamados.chamados.map((c) => c.nota2).reduce((a, b) => a + (b === 'Excelente' || b === 'Bom' ? 1 : 0), 0) /
        chamados.chamados.filter((c) => c.nota2 === 'Excelente' || c.nota2 === 'Bom' || c.nota2 === 'Ruim').length) *
        100 *
        10
    ) / 10 || 100;
  const nota3 =
    Math.ceil(
      (chamados.chamados.map((c) => c.nota3).reduce((a, b) => a + (b === 'Excelente' || b === 'Bom' ? 1 : 0), 0) /
        chamados.chamados.filter((c) => c.nota3 === 'Excelente' || c.nota3 === 'Bom' || c.nota3 === 'Ruim').length) *
        100 *
        10
    ) / 10 || 100;
  return (
    <div>
      <Row gutter={[8, 8]}>
        <Col flex="1 1 385px">
          <Card size="small" title="Chamados" style={{ minHeight: 218 }}>
            <Radio.Group
              size="small"
              value={filterDados}
              onChange={(e) => {
                setFilterDados(e.target.value);
                setFilterOption('todos');
              }}
            >
              <Radio key="4" value="todos">
                Todos
              </Radio>
              <Radio key="1" value="empresas">
                Empresas
              </Radio>
              <Radio key="2" value="loteamentos">
                Lotementos
              </Radio>
              <Radio key="3" value="empreendimentos">
                Empreendimentos
              </Radio>
            </Radio.Group>
            {filterDados !== 'todos' && (
              <Select
                style={{ width: 100 }}
                key="1"
                size="small"
                value={filterOption}
                onChange={(e) => setFilterOption(e)}
              >
                <Select.Option key="1" value="todos">
                  Todos
                </Select.Option>
                {dados[filterDados].map((c: any, index: number) => (
                  <Select.Option key={index} value={c}>
                    {c}
                  </Select.Option>
                ))}
              </Select>
            )}
            <Divider />
            <Row>
              <Col span={8}>
                <Statistic
                  title="Total de Chamados"
                  value={
                    filterDados === 'todos'
                      ? totaltotal.length
                      : filterDados === 'empresas'
                      ? totaltotal.filter((c) => filterOption === 'todos' || c.empresa === filterOption).length
                      : filterDados === 'loteamentos'
                      ? totaltotal.filter((c) => filterOption === 'todos' || c.loteamento === filterOption).length
                      : totaltotal.filter((c) => filterOption === 'todos' || c.empreendimento === filterOption).length
                  }
                  precision={0}
                  valueStyle={{ color: 'blue' }}
                  prefix={<SettingOutlined />}
                />
              </Col>
              <Col span={8}>
                <Statistic
                  title="Chamados Encerrados"
                  value={
                    filterDados === 'todos'
                      ? totalencerrados.length
                      : filterDados === 'empresas'
                      ? totalencerrados.filter((c) => filterOption === 'todos' || c.empresa === filterOption).length
                      : filterDados === 'loteamentos'
                      ? totalencerrados.filter((c) => filterOption === 'todos' || c.loteamento === filterOption).length
                      : totalencerrados.filter((c) => filterOption === 'todos' || c.empreendimento === filterOption)
                          .length
                  }
                  precision={0}
                  valueStyle={{ color: '#3f8600' }}
                  prefix={<CheckOutlined />}
                />
              </Col>
              <Col span={8}>
                <Statistic
                  title="Chamados em Aberto"
                  value={
                    filterDados === 'todos'
                      ? totalemaberto.length
                      : filterDados === 'empresas'
                      ? totalemaberto.filter((c) => filterOption === 'todos' || c.empresa === filterOption).length
                      : filterDados === 'loteamentos'
                      ? totalemaberto.filter((c) => filterOption === 'todos' || c.loteamento === filterOption).length
                      : totalemaberto.filter((c) => filterOption === 'todos' || c.empreendimento === filterOption)
                          .length
                  }
                  precision={0}
                  valueStyle={{ color: 'red' }}
                  prefix={<ClockCircleOutlined />}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col flex="1 1 385px">
          <Card size="small" title="Satisfação" style={{ minHeight: 218 }}>
            <Row style={{ textAlign: 'center' }}>
              <Col span={8}>
                <h3>Atendimento</h3>
                <Progress type="circle" percent={nota1} style={{ width: '80%' }} format={(v) => `${v}%`} />
              </Col>
              <Col span={8}>
                <h3>Qualidade</h3>
                <Progress type="circle" percent={nota2} style={{ width: '80%' }} format={(v) => `${v}%`} />
              </Col>
              <Col span={8}>
                <h3>Limpeza</h3>
                <Progress type="circle" percent={nota3} style={{ width: '80%' }} format={(v) => `${v}%`} />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
export default DashboardChamados;
