import { ExtendedAction } from '@config/reduxStore';
import { Fornecedor } from '@models/Fornecedor';
import { notification } from 'antd';
import { Arquivo } from '@models/Arquivo';
interface FornecedoresReducer {
  fornecedores: Fornecedor[];
  arquivos: Arquivo[];
  formview: boolean;
  arquivoview: boolean;
  fornecedor: number;
  loadingfornecedores: boolean;
  loadingarquivos: boolean;
  savingfornecedor: boolean;
  savingarquivo: boolean;
  feedback: string;
}
var initialState: FornecedoresReducer = {
  fornecedores: [],
  arquivos: [],
  formview: false,
  arquivoview: false,
  fornecedor: null,
  loadingfornecedores: false,
  loadingarquivos: false,
  savingfornecedor: false,
  savingarquivo: false,
  feedback: '',
};

const fornecedoresReducer = function (state = initialState, action: ExtendedAction) {
  switch (action.type) {
    case 'RESET_FEEDBACK':
      state = {
        ...state,
        feedback: '',
      };
      break;
    case 'FORM_FORNECEDOR':
      state = {
        ...state,
        feedback: '',
        formview: action.payload.open,
        fornecedor: action.payload.id ? action.payload.id : null,
      };
      break;
    case 'MODAL_ARQUIVOS_FORNECEDOR':
      state = {
        ...state,
        feedback: '',
        arquivoview: action.payload.open,
        fornecedor: action.payload.id ? action.payload.id : null,
      };
      break;
    case 'UPLOAD_FILES_FORNECEDOR_PENDING':
      state = {
        ...state,
        savingarquivo: true,
      };
      break;
    case 'UPLOAD_FILES_FORNECEDOR_REJECTED':
      state = {
        ...state,
        savingarquivo: false,
      };
      notification.error({ message: 'Erro ao enviar arquivos!' });
      break;
    case 'UPLOAD_FILES_FORNECEDOR_FULFILLED':
      state = {
        ...state,
        savingarquivo: false,
        arquivos: action.payload.data,
      };
      notification.success({ message: 'Arquivos adicionados com sucesso!' });
      break;
    case 'GET_ARQUIVOS_FORNECEDOR_PENDING':
      state = {
        ...state,
        loadingarquivos: true,
        feedback: '',
      };
      break;
    case 'GET_ARQUIVOS_FORNECEDOR_REJECTED':
      state = {
        ...state,
        loadingarquivos: false,
        feedback: action.payload.response.data.Message,
      };
      break;
    case 'GET_ARQUIVOS_FORNECEDOR_FULFILLED':
      state = {
        ...state,
        loadingarquivos: false,
        arquivos: action.payload.data,
      };
      break;
    case 'DELETE_FILE_FORNECEDOR_PENDING':
      state = {
        ...state,
        loadingarquivos: true,
        feedback: '',
      };
      break;
    case 'DELETE_FILE_FORNECEDOR_REJECTED':
      state = {
        ...state,
        loadingarquivos: false,
        feedback: action.payload.response.data.Message,
      };
      break;
    case 'DELETE_FILE_FORNECEDOR_FULFILLED':
      state = {
        ...state,
        loadingarquivos: false,
        arquivos: [...state.arquivos.filter((c) => c.id !== action.payload.data)],
      };
      break;
    case 'GET_FORNECEDORES_PENDING':
      state = {
        ...state,
        loadingfornecedores: true,
        fornecedores: initialState.fornecedores,
      };
      break;
    case 'GET_FORNECEDORES_REJECTED':
      state = {
        ...state,
        loadingfornecedores: false,
      };
      break;
    case 'GET_FORNECEDORES_FULFILLED':
      state = {
        ...state,
        loadingfornecedores: false,
        fornecedores: action.payload.data,
      };
      break;
    case 'ADD_FORNECEDOR_PENDING':
      state = {
        ...state,
        savingfornecedor: true,
        feedback: '',
      };
      break;
    case 'ADD_FORNECEDOR_REJECTED':
      state = {
        ...state,
        savingfornecedor: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: 'Erro ao cadastrar fornecedor!' });
      break;
    case 'ADD_FORNECEDOR_FULFILLED':
      state = {
        ...state,
        savingfornecedor: false,
        feedback: 'Fornecedor criado com sucesso!',
        fornecedores: [...state.fornecedores, action.payload.data.entity],
      };
      notification.success({ message: 'Fornecedor cadastrado com sucesso!' });
      break;
    case 'UPDATE_FORNECEDOR_PENDING':
      state = {
        ...state,
        savingfornecedor: true,
        feedback: '',
      };
      break;
    case 'UPDATE_FORNECEDOR_REJECTED':
      state = {
        ...state,
        savingfornecedor: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: 'Erro ao atualizar fornecedor' });
      break;
    case 'UPDATE_FORNECEDOR_FULFILLED':
      state = {
        ...state,
        savingfornecedor: false,
        feedback: 'Fornecedor atualizado com sucesso!',
        fornecedores: state.fornecedores.map((c) => {
          if (c.id === action.payload.data.entity.id) return action.payload.data.entity;
          else return c;
        }),
      };
      notification.success({ message: 'Fornecedor atualizado com sucesso' });
      break;
    case 'DELETE_FORNECEDOR_PENDING':
      state = {
        ...state,
        loadingfornecedores: true,
        feedback: '',
      };
      break;
    case 'DELETE_FORNECEDOR_REJECTED':
      notification.error({ message: action.payload.response.data.Message });
      state = {
        ...state,
        loadingfornecedores: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: state.feedback });
      break;
    case 'DELETE_FORNECEDOR_FULFILLED':
      state = {
        ...state,
        loadingfornecedores: false,
        feedback: 'Fornecedor excluído com sucesso!',
        fornecedores: state.fornecedores.filter((c) => c.id !== action.payload.data),
      };
      notification.success({ message: state.feedback });
      break;
    default:
      return state;
  }
  return state;
};

export default fornecedoresReducer;
