import { apiEndpoints } from '@constants/apiEndpoints';
import { OrdemItem } from '@models/OrdemItem';
import { ItemPedido, PedidoItem, PedidoList } from '@models/Pedidos';
import { notification } from 'antd';
import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { fetcher, poster, put } from './api';

export const GetPedido = (id: string) => {
  return useQuery<PedidoList, AxiosError<any>>(
    ['pedido', String(id)],
    () =>
      fetcher<PedidoList>(apiEndpoints.PEDIDOS.PEDIDO, {
        params: {
          id: Number(id) || null,
          cod: id,
        },
      }),
    {
      enabled: !!id,
      retry: false,
      staleTime: 1000 * 60 * 1,
      onError: (err) => {
        notification.error({ message: err?.response?.data });
      },
    }
  );
};
export const EnviarPedido = () => {
  return useMutation<number, AxiosError<any>, PedidoList>(
    'enviarPedido',
    (data) => poster<number>(apiEndpoints.PEDIDOS.SAVE, data),
    {
      onError: (err) => {
        notification.error({ message: err?.response?.data });
      },
      onSuccess: (res) => {
        notification.success({ message: 'Pedido enviado com sucesso!' });
        window.location.href = `/Pedidos/${res}`;
      },
    }
  );
};

export const GerarPedidoItens = () => {
  return useMutation<number, AxiosError<any>, { itens: PedidoItem[]; situacao: string }>(
    'enviarPedido',
    (data) => poster<number>(apiEndpoints.PEDIDOS.GERAR_PEDIDO_ITENS, data),
    {
      onError: (err) => {
        notification.error({ message: err?.response?.data });
      },
      onSuccess: (res) => {
        notification.success({ message: 'Pedido gerado com sucesso!' });
        window.location.href = `/NovaOrdem/0/${res}`;
      },
    }
  );
};

export const GerarPedidoObras = () => {
  return useMutation<number, AxiosError<any>, { itens: OrdemItem[]; situacao: string }>(
    'enviarPedido',
    (data) => poster<number>(apiEndpoints.PEDIDOS.GERAR_PEDIDO_ITENS, data),
    {
      onError: (err) => {
        notification.error({ message: err?.response?.data });
      },
      onSuccess: (res) => {
        notification.success({ message: 'Pedido gerado com sucesso!' });
        window.location.href = `/NovaOrdem/0/${res}`;
      },
    }
  );
};
export const ItensPedido = (id: number) => {
  return useQuery<ItemPedido[], AxiosError<any>>(
    ['pedido', String(id)],
    () =>
      fetcher<ItemPedido[]>(apiEndpoints.PEDIDOS.ITENS, {
        params: {
          pedidoID: id,
        },
      }),
    {
      enabled: !!id,
      retry: false,
      staleTime: 0,
      onError: (err) => {
        notification.error({ message: err?.response?.data });
      },
    }
  );
};
export const GetPedidoItens = (status: string, prazo: [moment.Moment, moment.Moment], grupo: string) => {
  return useQuery<PedidoItem[], AxiosError<any>>(
    ['pedidoItens', status, prazo, grupo],
    () =>
      fetcher<PedidoItem[]>(
        grupo === 'Item' ? apiEndpoints.PEDIDOS.PEDIDO_ITENS : apiEndpoints.PEDIDOS.PEDIDO_ITENS_OBRAS,
        { params: { status: status, grupo: grupo, prazo: JSON.stringify(prazo) } }
      ),
    {
      staleTime: 0,
      onError: (err) => {
        notification.error({ message: err?.response?.data });
      },
    }
  );
};
export const AprovarPedido = () => {
  const query = useQueryClient();
  return useMutation<number, AxiosError<any>, string | number>(
    'aprovarPedido',
    (id) =>
      put<number>(apiEndpoints.PEDIDOS.APROVAR, null, {
        params: {
          id: Number(id) || null,
          cod: id,
        },
      }),
    {
      retry: false,
      onError: (err) => {
        notification.error({ message: err?.response?.data });
      },
      onSuccess: (res) => {
        notification.success({ message: 'Pedido aprovado com sucesso!' });
        query.invalidateQueries(['pedido', String(res)]);
      },
    }
  );
};
export const CancelarPedido = (id: string, motivo: string) => {
  const query = useQueryClient();
  return useQuery<number, AxiosError<any>>(
    ['pedido', String(id)],
    () =>
      put<number>(apiEndpoints.PEDIDOS.CANCELAR, null, {
        params: {
          id: Number(id) || null,
          cod: id,
          motivo: motivo,
        },
      }),
    {
      enabled: !!id,
      retry: false,
      onError: (err) => {
        notification.error({ message: err?.response?.data });
      },
      onSuccess: (res) => {
        notification.success({ message: 'Pedido cancelado com sucesso!' });
        query.invalidateQueries(['pedido', res]);
      },
    }
  );
};
