import { OrdemStatus } from '@constants/ordemStatus';
import { IOrdemForm } from '@models/Ordem';
import { SaveDesconto, SaveJustificativa, SaveObservacao } from '@services/api/api.ordem';
import { moneyMask, moneyNegMask } from '@utils/masks';
import { Card, Descriptions, Form, Input } from 'antd';
import OrdemStatusTag from '../Shared/OrdemStatusTag';
var timeoutDesconto: NodeJS.Timeout = null;
var timeoutJustificativa: NodeJS.Timeout = null;
var timeoutObservacao: NodeJS.Timeout = null;
function OrdemResumo({ ordem }: { ordem: IOrdemForm }) {
  const saveDesconto = SaveDesconto();
  const saveJustificativa = SaveJustificativa();
  const saveObservacao = SaveObservacao();

  const handleSaveDesconto = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearTimeout(timeoutDesconto);
    timeoutDesconto = setTimeout(() => {
      saveDesconto.mutate({ ordemID: ordem.id, desconto: moneyMask.normalize(e.target.value) });
    }, 2000);
  };
  const handleSaveJustificativa = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    clearTimeout(timeoutJustificativa);
    timeoutJustificativa = setTimeout(() => {
      saveJustificativa.mutate({ ordemID: ordem.id, justificativa: e.target.value });
    }, 2000);
  };
  const handleSaveObservacao = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    clearTimeout(timeoutObservacao);
    timeoutObservacao = setTimeout(() => {
      saveObservacao.mutate({ ordemID: ordem.id, observacao: e.target.value });
    }, 2000);
  };
  return (
    <Card size="small" title="Resumo">
      <Form.Item name="subtotal" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="total" hidden>
        <Input />
      </Form.Item>
      <Descriptions size="small" column={1} bordered labelStyle={{ textAlign: 'right' }}>
        <Descriptions.Item label="ID">{ordem.id ? ordem.cod : 'Avance para salvar'}</Descriptions.Item>
        <Descriptions.Item label="Situação">
          <OrdemStatusTag status={ordem.situacao} />
        </Descriptions.Item>
        <Descriptions.Item label="Subtotal">{moneyMask.format(ordem.subtotal)}</Descriptions.Item>
        <Descriptions.Item label="Desconto">
          <Form.Item noStyle name="desconto" normalize={(v) => moneyMask.format(moneyMask.normalize(v))}>
            <Input
              onChange={handleSaveDesconto}
              disabled={
                !ordem.id ||
                !(ordem?.situacao === OrdemStatus.Rascunho || ordem?.situacao === OrdemStatus.PendenteAprovacao)
              }
            />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item label="Total">{moneyNegMask.format(ordem.total)}</Descriptions.Item>
        <Descriptions.Item label="Observações">
          <Form.Item noStyle name="observacao">
            <Input.TextArea
              rows={3}
              placeholder="Observações..."
              onChange={handleSaveObservacao}
              disabled={
                !ordem.id ||
                !(ordem?.situacao === OrdemStatus.Rascunho || ordem?.situacao === OrdemStatus.PendenteAprovacao)
              }
            />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item label="Justificativa">
          <Form.Item
            name="justificativa"
            rules={[{ required: ordem?.ordensItens?.some((c) => c.estourado), message: 'Jutifique o estouro' }]}
          >
            <Input.TextArea
              rows={3}
              placeholder="Jutificativa do estouro..."
              onChange={handleSaveJustificativa}
              disabled={
                !ordem.id ||
                !(ordem?.situacao === OrdemStatus.Rascunho || ordem?.situacao === OrdemStatus.PendenteAprovacao)
              }
            />
          </Form.Item>
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
}

export default OrdemResumo;
