import { Button, DatePicker, Form, Modal, notification, Radio } from 'antd';
import { useState } from 'react';
import { FileExcelOutlined } from '@ant-design/icons';
import { Axios } from '@config/axios';
import moment from 'moment';

function ExportFolhasModal() {
  const [downloadModal, setDownloadModal] = useState<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [downloadForm] = Form.useForm();
  return (
    <>
      <Button key="3" onClick={() => setDownloadModal(true)} icon={<FileExcelOutlined />}>
        Exportar
      </Button>
      <Modal
        open={downloadModal}
        title="Exportar Folhas"
        onCancel={() => setDownloadModal(false)}
        onOk={() => downloadForm.submit()}
        okButtonProps={{ loading: downloading }}
        cancelText="Fechar"
        okText="Baixar"
      >
        <Form
          form={downloadForm}
          onFinish={(values) => {
            setDownloading(true);
            Axios.post('/api/v2/Folhas/DownloadExcel', values, { responseType: 'blob' })
              .then((res) => {
                setDownloading(false);
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Folhas_${moment(new Date()).format('DD_MM_YYYY HH_mm')}.xlsx`);
                document.body.appendChild(link);
                link.click();
              })
              .catch(() => {
                notification.error({ message: 'Erro ao gerar arquivo!' });
                setDownloading(false);
              });
          }}
        >
          <Form.Item name="tipo" label="Tipo" initialValue={0} hidden>
            <Radio.Group>
              <Radio value={0} key="1">
                Por fornecedor
              </Radio>
              <Radio value={1} key="2">
                Por folha
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="mes" label="Competência" initialValue={moment(new Date())}>
            <DatePicker.MonthPicker format="MMM/YYYY" />
          </Form.Item>
          <Form.Item name="tipo" label="Tipo" initialValue={moment(new Date())}>
            <Radio.Group>
              <Radio value={0} key="1">
                Adiantamento
              </Radio>
              <Radio value={1} key="2">
                Medição
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default ExportFolhasModal;
