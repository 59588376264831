import { Button, DatePicker, Popconfirm, Select, Space, Table } from 'antd';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { deleteMedicao, getMedicoes, updateMedicao } from '@actions/bancoActions';
import { SetPageHeader } from '@actions/userActions';
import { PlusOutlined, EditOutlined, SaveOutlined, StopOutlined, DeleteOutlined } from '@ant-design/icons';
import { useState } from 'react';
import NovaMedicaoModal from './NovaMedicaoModal';
import moment from 'moment';
import { doubleMask1, moneyMask } from '@utils/masks';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Medicao } from '@models/Medicao';

function Medicoes() {
  const dispatch = useAppDispatch();
  const { orcamento, medicoes, loadingMedicoes, responsaveis, savingMedicao } = useAppSelector((state) => state.banco);
  const { id } = useParams();
  const [novo, setNovo] = useState(false);

  const initialState: Medicao = {
    id: null,
    status: null,
    responsavelID: null,
    dataSolicitado: null,
    dataRealizado: null,
  };
  const [edit, setEdit] = useState<Medicao>(initialState);
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Medições',
        backbutton: true,
        extras: [
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setNovo(true)}
            disabled={medicoes.some((c) => !c.realizado)}
          >
            Nova Medição
          </Button>,
        ],
        subtitle: orcamento.descricao,
        menu: 'planejamentos',
      })
    );
  }, [id, orcamento, medicoes]);
  useEffect(() => {
    dispatch(getMedicoes(id));
  }, [id]);
  return (
    <div>
      <NovaMedicaoModal visible={novo} setVisible={setNovo} />
      <Table
        size="small"
        dataSource={medicoes}
        loading={loadingMedicoes || savingMedicao}
        pagination={false}
        scroll={{ x: 1000, y: 'calc(100vh - 200px)' }}
        summary={(data) => (
          <Table.Summary fixed>
            <Table.Summary.Row style={{ fontWeight: 'bold' }}>
              <Table.Summary.Cell index={0} colSpan={7} align="right">
                TOTAL
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} align="right">
                {moneyMask.format(data.reduce((a, b) => a + b.medicaoItens.reduce((c, d) => c + d.valorTotal, 0), 0))}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} align="right">
                {doubleMask1.format(
                  data.reduce(
                    (a, b) =>
                      a + (100 * b.medicaoItens.reduce((c, d) => c + d.valorTotal, 0)) / b.orcamentoBanco?.valorTotal,
                    0
                  )
                )}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      >
        <Table.Column align="center" width={40} render={(_, __, index) => index + 1} />
        <Table.Column
          dataIndex="id"
          width={100}
          align="center"
          render={(v, row: Medicao) =>
            v === edit.id ? (
              <Space>
                <Button
                  size="small"
                  icon={<SaveOutlined />}
                  onClick={() => {
                    dispatch(updateMedicao(edit));
                    setEdit(initialState);
                  }}
                />
                <Button size="small" icon={<StopOutlined />} onClick={() => setEdit(initialState)} />
                <Popconfirm
                  title="Está certo disso?"
                  onConfirm={() => {
                    dispatch(deleteMedicao(v));
                    setEdit(initialState);
                  }}
                >
                  <Button size="small" icon={<DeleteOutlined />} />
                </Popconfirm>
              </Space>
            ) : (
              <Space>
                <Button size="small" icon={<EditOutlined />} onClick={() => setEdit(row)} />
              </Space>
            )
          }
        />
        <Table.Column align="center" title="Mês" dataIndex="mes" render={(v) => moment(v).format('MM/YYYY')} />
        <Table.Column
          title="Situação"
          dataIndex="status"
          align="center"
          render={(v, row: Medicao) =>
            edit.id === row.id ? (
              <Select
                value={edit.status}
                onChange={(v) => setEdit({ ...edit, status: v })}
                style={{ width: '100%' }}
                disabled={row.realizado}
              >
                <Select.Option value="Solicitado">Solicitado</Select.Option>
                <Select.Option value="Realizado">Realizado</Select.Option>
                <Select.Option value="Pendência">Pendência</Select.Option>
                <Select.Option value="Finalizado">Finalizado</Select.Option>
              </Select>
            ) : (
              v
            )
          }
        />
        <Table.Column
          title="Responsável"
          dataIndex={['responsavel', 'nome']}
          render={(v, row: Medicao) =>
            edit.id === row.id ? (
              <Select
                value={edit.responsavelID}
                onChange={(v) => setEdit({ ...edit, responsavelID: v })}
                style={{ width: '100%' }}
              >
                {responsaveis.map((c) => (
                  <Select.Option key={c.id}>{c.nome}</Select.Option>
                ))}
              </Select>
            ) : (
              v
            )
          }
        />
        <Table.Column
          title="Data Solicitado"
          dataIndex="dataSolicitado"
          render={(v, row: Medicao) =>
            edit.id === row.id ? (
              <DatePicker
                value={moment(edit.dataSolicitado).isValid() && moment(edit.dataSolicitado)}
                onChange={(v) => setEdit({ ...edit, dataSolicitado: v })}
                allowClear
                format="DD/MM/YYYY"
              />
            ) : (
              moment(v).isValid() && moment(v).format('DD/MM/YYYY')
            )
          }
        />
        <Table.Column
          title="Data Finalizado"
          dataIndex="dataRealizado"
          render={(v, row: Medicao) =>
            edit.id === row.id ? (
              <DatePicker
                value={moment(edit.dataRealizado).isValid() && moment(edit.dataRealizado)}
                onChange={(v) => setEdit({ ...edit, dataRealizado: v })}
                allowClear
                format="DD/MM/YYYY"
              />
            ) : (
              moment(v).isValid() && moment(v).format('DD/MM/YYYY')
            )
          }
        />
        <Table.Column
          title="Valor Medido"
          dataIndex="medicaoItens"
          align="right"
          render={(v) => moneyMask.format(v.reduce((a: any, b: any) => a + b.valorTotal, 0))}
        />
        <Table.Column
          title="Avanço"
          dataIndex="medicaoItens"
          align="right"
          render={(v, row: Medicao) =>
            doubleMask1.format(
              (100 * v.reduce((a: any, b: any) => a + b.valorTotal, 0)) / row.orcamentoBanco?.valorTotal
            )
          }
        />
      </Table>
    </div>
  );
}
export default Medicoes;
