import { Layout, Menu } from 'antd';
import logo from '../../assets/img/main.png';
import logofacilitar from '../../assets/img/logofacilitar.png';
import { Route, Routes, Link } from 'react-router-dom';
import PoliticaPrivacidade from './PoliticaPrivacidade';
import Suporte from './Suporte';
import LoginView from '../Account/LoginView';
import WelcomeView from './WelcomeView';
import ForgotView from '../Account/ForgotView';
import ContratarView from './ContratarView';
import ResetPasswordView from '../Account/ResetPasswordView';
import Planos from './Planos';
import { useAppSelector } from '@config/reduxStore';
import useFacilitar from '@hooks/useFacilitar';

function HomeView() {
  const { user } = useAppSelector((state) => state.user);
  const facilitar = useFacilitar();
  return (
    <Layout>
      <Layout.Header>
        <Link to="/" style={{ float: 'left' }}>
          <img alt="logo" src={facilitar ? logofacilitar : logo} height={50}></img>
        </Link>
        <Menu theme="light" mode="horizontal" defaultSelectedKeys={['2']} style={{ justifyContent: 'flex-end' }}>
          {!facilitar && (
            <Menu.Item>
              <Link to="/Planos">Planos e Preços</Link>
            </Menu.Item>
          )}
          {!facilitar && (
            <Menu.Item>
              <Link to="/Contratar">Criar Conta</Link>
            </Menu.Item>
          )}
          <Menu.Item>
            <Link to="/Login">Login</Link>
          </Menu.Item>
        </Menu>
      </Layout.Header>
      <Layout.Content style={{ margin: 20, textAlign: 'center', height: '100%' }}>
        <Routes>
          <Route path="/PoliticaPrivacidade" element={<PoliticaPrivacidade />}></Route>
          <Route path="/Suporte" element={<Suporte />}></Route>
          <Route path="/Planos" element={<Planos ContaID={user.conta && user.conta.id} />}></Route>
          <Route path="/Contratar/:ContaID" element={<ContratarView ContaID={user.conta && user.conta.id} />}></Route>
          <Route path="/Contratar" element={<ContratarView ContaID={user.conta && user.conta.id} />}></Route>
          <Route path="/Login" element={<LoginView />}></Route>
          <Route path="/Forgot" element={<ForgotView />}></Route>
          <Route path="/ResetPassword" element={<ResetPasswordView />}></Route>
          <Route path="/" element={<WelcomeView />}></Route>
        </Routes>
      </Layout.Content>
      <Layout.Footer style={{ textAlign: 'center' }}>
        <p>
          {facilitar ? 'Facilitar' : 'Elevar'} Gestão ©2018 {!facilitar && 'Created by Daniel Freitas'}
        </p>
        <p>
          <Link to="/PoliticaPrivacidade">Política de privacidade</Link>
        </p>
        <p>{!facilitar && <Link to="/Suporte">Suporte</Link>}</p>
      </Layout.Footer>
    </Layout>
  );
}

export default HomeView;
