import { useAppSelector } from '@config/reduxStore';
import { Pagination } from '@models/General';
import { DashboardFolhaPorObra } from '@reducers/folhasReducer';
import { getColumnSearchProps, sortAlpha } from '@utils/filterandsort';
import { moneyMask } from '@utils/masks';
import { Table } from 'antd';
import { useState } from 'react';

function TablePorObra({ modo }: { modo: 'Adiantamento' | 'Medição' }) {
  const { dashboardPorObra, obrasDashboard } = useAppSelector((state) => state.folhas);
  const [filtersObra, setFiltersObra] = useState<Pagination<any>>(null);
  return (
    <Table
      size="small"
      rowKey="id"
      pagination={false}
      dataSource={dashboardPorObra.filter((c) => c.fornecedores > 0)}
      rowClassName={(row) => `rownivel${row.nivel}`}
      scroll={{ x: 1000, y: 'calc(100vh - 200px' }}
      onChange={(pagination, filters, sorter) => setFiltersObra({ pagination, filters, sorter })}
      summary={(data) => (
        <Table.Summary fixed>
          <Table.Summary.Row style={{ fontWeight: 'bold' }}>
            <Table.Summary.Cell index={0} colSpan={3} align="right">
              TOTAL
            </Table.Summary.Cell>
            <Table.Summary.Cell index={1} align="right">
              {moneyMask.format(data.reduce((a, b) => a + b.adiantamento, 0))}
            </Table.Summary.Cell>
            {modo === 'Medição' && (
              <Table.Summary.Cell index={2} align="right">
                {moneyMask.format(data.reduce((a, b) => a + b.subtotal, 0))}
              </Table.Summary.Cell>
            )}
            {modo === 'Medição' && (
              <Table.Summary.Cell index={3} align="right">
                {moneyMask.format(data.reduce((a, b) => a + b.total, 0))}
              </Table.Summary.Cell>
            )}
          </Table.Summary.Row>
        </Table.Summary>
      )}
    >
      <Table.Column title="" render={(_, __, index) => index + 1} align="center" width={100} />
      <Table.Column
        title="Obra"
        dataIndex="obra"
        ellipsis
        sorter={(a, b) => sortAlpha(a.obra, b.obra)}
        {...getColumnSearchProps(
          'obra',
          'customselect',
          'Status',
          filtersObra,
          obrasDashboard.map((f) => ({ text: f, value: f }))
        )}
      />
      <Table.Column
        title="Nº Fornecedores"
        dataIndex="fornecedores"
        width={120}
        align="center"
        sorter={(a, b: DashboardFolhaPorObra) => a.fornecedores - b.fornecedores}
      />
      <Table.Column
        title="Adiantamento"
        dataIndex="adiantamento"
        render={(v) => moneyMask.format(v)}
        align="right"
        sorter={(a, b: DashboardFolhaPorObra) => a.adiantamento - b.adiantamento}
      />
      {modo === 'Medição' && (
        <Table.Column
          title="Medição"
          dataIndex="subtotal"
          render={(v) => moneyMask.format(v)}
          align="right"
          sorter={(a, b: DashboardFolhaPorObra) => a.subtotal - b.subtotal}
        />
      )}
      {modo === 'Medição' && (
        <Table.Column
          title="Total"
          dataIndex="total"
          render={(v) => moneyMask.format(v)}
          align="right"
          sorter={(a, b: DashboardFolhaPorObra) => a.total - b.total}
        />
      )}
    </Table>
  );
}
export default TablePorObra;
