import { useEffect, useState, useRef } from 'react';
import { SetPageHeader } from '@actions/userActions';
import {
  FormCliente,
  DeleteCliente,
  Desistir,
  GetCliente,
  GetClientesPagination,
  SetPagination,
} from '@actions/correspondenciaActions';
import { HistoryOutlined, DeleteOutlined, ProfileOutlined, FrownOutlined } from '@ant-design/icons';
import { Table, Input, Button, Tooltip, Popconfirm, Modal, List, Form, DatePicker, Divider, Typography } from 'antd';
import { sortAlpha, orderDate, getColumnSearchPropsServer } from '@utils/filterandsort';
import { Link } from 'react-router-dom';
import { Permission } from '@hooks//usePermissions';
import { moneyMask } from '@utils/masks';
import { GetAgencias } from '@actions/agenciasActions';
import { GetCCAs } from '@actions/ccasActions';
import { GetCorretores } from '@actions/corretoresActions';
import moment from 'moment';
import { GetLoteamentos } from '@actions/loteamentosActions';
import { GetModalidades } from '@actions/modalidadesActions';
import { GetParceiros } from '@actions/parceirosActions';
import ClienteForm from './ClienteForm';
import { AddHistorico } from '@actions/correspondenciaActions';
import PrintPage from '../PrintPage';
import ReactToPrint from 'react-to-print';
import FichaPop from './FichaPop';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { ClienteCorrespondencia } from '@models/ClienteCorrespondencia';

function ClientesView() {
  const dispatch = useAppDispatch();
  const { clientes, loadingclientes, savinghistorico, pagination } = useAppSelector((state) => state.correspondencia);
  const { corretores } = useAppSelector((state) => state.corretores);
  const { parceiros } = useAppSelector((state) => state.parceiros);
  const { loteamentos } = useAppSelector((state) => state.loteamentos);
  const { modalidades } = useAppSelector((state) => state.modalidades);
  const { ccas } = useAppSelector((state) => state.ccas);
  const [historicoview, sethistoricoview] = useState(false);
  const [historico, sethistorico] = useState(null);
  const [historicoForm] = Form.useForm();
  const printRef = useRef();
  const [filtroGeral, setFiltroGeral] = useState<string>('');
  const [ficha, setFicha] = useState<{ ficha: ClienteCorrespondencia; visible: boolean }>({
    ficha: null,
    visible: false,
  });
  useEffect(() => {
    dispatch(GetClientesPagination({ data: pagination }));
    dispatch(GetLoteamentos());
    dispatch(GetModalidades());
    dispatch(GetParceiros());
    dispatch(GetAgencias());
    dispatch(GetCCAs());
    dispatch(GetCorretores());
    dispatch(
      SetPageHeader({
        title: 'Correspondência',
        backbutton: true,
        extras: [
          <Permission key="1" permission="clientecorrespondencia.add">
            <Button onClick={() => dispatch(FormCliente(null, true))} type="primary">
              Novo Cliente
            </Button>
          </Permission>,
        ],
        subtitle: 'Clientes',
        menu: 'clientes',
      })
    );
  }, []);
  const colunas: any[] = [
    {
      dataIndex: 'id',
      width: 50,
      allowed: false,
      fixed: 'left',
      render: (_: any, row: ClienteCorrespondencia) => (
        <div>
          <Tooltip title="Ficha do Cliente">
            <Button
              icon={<ProfileOutlined />}
              size="small"
              onClick={() => {
                setFicha({ visible: true, ficha: null });
                dispatch(GetCliente(row.id));
              }}
            />
          </Tooltip>
          {false && (
            <Tooltip title="Ficha do Cliente">
              <Link to={`/ClientesCorrespondencia/${row.id}`}>
                <Button icon={<ProfileOutlined />} size="small" />
              </Link>
            </Tooltip>
          )}
          <Permission permission="clientecorrespondencia.delete">
            <Tooltip title="Excluir Cliente">
              <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(DeleteCliente(row.id))}>
                <Button icon={<DeleteOutlined />} size="small" />
              </Popconfirm>
            </Tooltip>
          </Permission>
          <Tooltip title="Desistiu">
            <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(Desistir(row.id))}>
              <Button size="small" icon={<FrownOutlined />} />
            </Popconfirm>
          </Tooltip>
          <Tooltip title="Histórico">
            <Button
              icon={<HistoryOutlined />}
              size="small"
              onClick={() => {
                sethistoricoview(true);
                sethistorico(row.id);
                historicoForm.resetFields();
                historicoForm.setFieldsValue({ id: row.id });
              }}
            />
          </Tooltip>
        </div>
      ),
      visible: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 100,
      visible: true,
      allowed: true,
      filteredValue: pagination.filters ? pagination.filters.status : null,
      sorter: (a: any, b: any) => sortAlpha(a.status, b.status),
      onFilter: (value: any, row: ClienteCorrespondencia) => row.status === value,
      filters: [
        { text: 'Desistiu', value: 'Desistiu' },
        { text: 'Pendente de Avaliação', value: 'Pendente de Avaliação' },
        { text: 'Aprovado', value: 'Aprovado' },
        { text: 'Reprovado', value: 'Reprovado' },
        { text: 'Relacionamento', value: 'Relacionamento' },
        { text: 'GIRET/CEHOP', value: 'GIRET/CEHOP' },
        { text: 'Conforme', value: 'Conforme' },
        { text: 'Contrato Assinado', value: 'Contrato Assinado' },
        { text: 'Concluído', value: 'Concluído' },
      ],
    },
    {
      title: 'Criador',
      dataIndex: 'criador',
      visible: false,
      allowed: true,
      sorter: (a: any, b: any) => sortAlpha(a.criador, b.criador),
      ...getColumnSearchPropsServer('criador', 'string', 'Criador', pagination.filters),
    },
    {
      title: 'Data Cadastro',
      dataIndex: 'dataCadastro',
      visible: true,
      allowed: true,
      width: 100,
      sorter: (a: any, b: any) => orderDate(a.dataCadastro, b.dataCadastro),
      ...getColumnSearchPropsServer('dataCadastro', 'daterange', 'Data Cadastro', pagination.filters),
      render: (text: Date) => (moment(text).isValid() ? moment(text).format('DD/MM/YYYY') : ''),
    },
    {
      title: 'Data Assinatura',
      dataIndex: 'dataAssinatura',
      visible: true,
      allowed: true,
      width: 100,
      sorter: (a: any, b: any) => orderDate(a.dataAssinatura, b.dataAssinatura),
      ...getColumnSearchPropsServer('dataAssinatura', 'daterange', 'Data Assinatura', pagination.filters),
      render: (text: Date) => (moment(text).isValid() ? moment(text).format('DD/MM/YYYY') : ''),
    },
    {
      title: 'Proponente',
      dataIndex: 'clienteNome1',
      visible: true,
      allowed: true,
      width: 200,
      sorter: true,
      ...getColumnSearchPropsServer('clienteNome1', 'string', 'Cliente 1 - Nome', pagination.filters),
    },
    {
      title: 'Proponente 2',
      dataIndex: 'clienteNome2',
      visible: true,
      allowed: true,
      width: 200,
      sorter: (a: any, b: any) => sortAlpha(a.clienteNome2, b.clienteNome2),
      ...getColumnSearchPropsServer('clienteNome2', 'string', 'Cliente 2 - Nome', pagination.filters),
    },
    {
      title: 'CPF',
      dataIndex: 'clienteCPF1',
      visible: true,
      allowed: true,
      width: 120,
      sorter: (a: any, b: any) => sortAlpha(a.clienteCPF1, b.clienteCPF1),
      ...getColumnSearchPropsServer('clienteCPF1', 'string', 'Cliente 1 - CPF', pagination.filters),
    },
    {
      title: 'CPF2',
      dataIndex: 'clienteCPF2',
      visible: true,
      allowed: true,
      width: 120,
      sorter: (a: any, b: any) => sortAlpha(a.clienteCPF2, b.clienteCPF2),
      ...getColumnSearchPropsServer('clienteCPF2', 'string', 'Cliente 2 - CPF', pagination.filters),
    },
    {
      title: 'Telefone',
      dataIndex: 'clienteTelefone1',
      visible: false,
      allowed: true,
      sorter: (a: any, b: any) => sortAlpha(a.clienteTelefone1, b.clienteTelefone1),
      ...getColumnSearchPropsServer('clienteTelefone1', 'string', 'Cliente 1 - Telefone', pagination.filters),
    },

    {
      title: 'Modalidade',
      dataIndex: 'modalidadeCredito',
      visible: true,
      allowed: true,
      width: 120,
      filteredValue: pagination.filters ? pagination.filters.modalidadeCredito : null,
      sorter: (a: any, b: any) => sortAlpha(a.modalidadeCredito, b.modalidadeCredito),
      filters: modalidades.map((c) => ({ value: c.descricao, text: c.descricao })),
      onFilter: (value: any, row: ClienteCorrespondencia) => row.modalidadeCredito.includes(value as string),
    },
    {
      title: 'Parceiro',
      dataIndex: 'parceiro',
      visible: true,
      allowed: true,
      width: 100,
      filteredValue: pagination.filters ? pagination.filters.parceiro : null,
      sorter: (a: any, b: any) => sortAlpha(a.parceiro, b.parceiro),
      filters: parceiros.map((c) => ({ value: c.nome, text: c.nome })),
      onFilter: (value: any, row: ClienteCorrespondencia) => row.parceiro.includes(value as string),
    },
    {
      title: 'Agência',
      dataIndex: 'agencia',
      visible: false,
      allowed: true,
      sorter: (a: any, b: any) => sortAlpha(a.agencia, b.agencia),
      ...getColumnSearchPropsServer('agencia', 'string', 'Agência', pagination.filters),
    },
    {
      title: 'Alocação',
      dataIndex: 'alocacao',
      visible: false,
      allowed: true,
      filteredValue: pagination.filters ? pagination.filters.alocacao : null,
      sorter: (a: any, b: any) => sortAlpha(a.alocacao, b.alocacao),
      filters: [
        { value: true, text: 'Sim' },
        { value: false, text: 'Não' },
      ],
      onFilter: (value: any, row: ClienteCorrespondencia) => row.alocacao === value,
    },
    {
      title: 'Avaliação',
      dataIndex: 'aprovado',
      width: 120,
      visible: true,
      allowed: true,
      filteredValue: pagination.filters ? pagination.filters.aprovado : null,
      render: (text: any) => (text === true ? 'Aprovado' : text === false ? 'Reprovado' : 'Não avaliado'),
      sorter: (a: any, b: any) => sortAlpha(a.aprovado, b.aprovado),
      filters: [
        { value: true, text: 'Aprovado' },
        { value: false, text: 'Reprovado' },
        { value: null, text: 'Não avaliado' },
      ],
      onFilter: (value: any, row: ClienteCorrespondencia) => row.aprovado === value,
    },
    {
      title: 'Data Avaliação',
      dataIndex: 'dataAprovado',
      visible: false,
      allowed: true,
      sorter: (a: any, b: any) => orderDate(a.dataAprovado, b.dataAprovado),
      ...getColumnSearchPropsServer('dataAprovado', 'daterange', 'Data Aprovado', pagination.filters),
      render: (text: any) => (moment(text).isValid() ? moment(text).format('DD/MM/YYYY') : ''),
    },

    {
      title: 'Data Conformidade',
      dataIndex: 'dataConformidade',
      visible: false,
      allowed: true,
      filteredValue: pagination.filters ? pagination.filters.status : null,
      sorter: (a: any, b: any) => orderDate(a.dataConformidade, b.dataConformidade),
      ...getColumnSearchPropsServer('dataConformidade', 'daterange', 'Data Conformidade', pagination.filters),
      render: (text: any) => (moment(text).isValid() ? moment(text).format('DD/MM/YYYY') : ''),
    },

    {
      title: 'Data Conforme',
      dataIndex: 'dataConfore',
      visible: false,
      allowed: true,
      sorter: (a: any, b: any) => orderDate(a.dataConforme, b.dataConforme),
      ...getColumnSearchPropsServer('dataConforme', 'daterange', 'Data Conforme', pagination.filters),
      render: (text: any) => (moment(text).isValid() ? moment(text).format('DD/MM/YYYY') : ''),
    },
    {
      title: 'Data Assinatura',
      dataIndex: 'dataAssinatura',
      visible: false,
      allowed: true,
      sorter: (a: any, b: any) => orderDate(a.dataAssinatura, b.dataAssinatura),
      ...getColumnSearchPropsServer('dataAssinatura', 'daterange', 'Data Assinatura', pagination.filters),
      render: (text: any) => (moment(text).isValid() ? moment(text).format('DD/MM/YYYY') : ''),
    },
    {
      title: 'Competêcia',
      dataIndex: 'competencia',
      visible: false,
      allowed: true,
      ...getColumnSearchPropsServer('competencia', 'mes', 'Competência', pagination.filters),
      render: (text: any) => (moment(text).isValid() ? moment(text).format('MMM/YYYY') : ''),
    },
    {
      title: 'Casa',
      dataIndex: 'casa',
      visible: false,
      allowed: true,
      sorter: (a: any, b: any) => sortAlpha(a.casa, b.casa),
      ...getColumnSearchPropsServer('casa', 'string', 'Casa', pagination.filters),
    },
    {
      title: 'Loteamento',
      dataIndex: 'loteamento',
      visible: false,
      allowed: true,
      filteredValue: pagination.filters ? pagination.filters.loteamento : null,
      sorter: (a: any, b: any) => sortAlpha(a.loteamento, b.loteamento),
      filters: loteamentos.map((c) => ({ value: c.nome, text: c.nome })),
      onFilter: (value: any, row: ClienteCorrespondencia) => row.loteamento.includes(value as string),
    },
    {
      title: 'CCA',
      dataIndex: 'cca',
      visible: false,
      allowed: true,
      filteredValue: pagination.filters ? pagination.filters.cca : null,
      sorter: (a: any, b: any) => sortAlpha(a.cca, b.cca),
      filters: ccas.map((c) => ({ value: c.nome, text: c.nome })),
      onFilter: (value: any, row: ClienteCorrespondencia) => row.cca.includes(value as string),
    },
    {
      title: 'Parceiro',
      dataIndex: 'corretor',
      visible: true,
      allowed: true,
      width: 120,
      sorter: true,
      ...getColumnSearchPropsServer(
        'corretor',
        'customselect',
        'Parceiro',
        pagination.filters,
        corretores.map((c) => ({ value: c.nome, text: c.nome }))
      ),
    },
    {
      title: 'Valor Compra e Venda',
      dataIndex: 'valorCompraVenda',
      visible: false,
      allowed: true,
      sorter: (a: any, b: any) => a.valorCompraVenda - b.valorCompraVenda,
      render: (text: any) => moneyMask.format(text),
      ...getColumnSearchPropsServer('valorCompraVenda', 'string', 'Valor Compra e Venda', pagination.filters),
    },
    {
      title: 'Valor Avaliação',
      dataIndex: 'valorAvaliacao',
      visible: false,
      allowed: true,
      sorter: (a: any, b: any) => a.valorAvaliacao - b.valorAvaliacao,
      render: (text: any) => moneyMask.format(text),
      ...getColumnSearchPropsServer('valorAvaliacao', 'string', 'Valor Avaliação', pagination.filters),
    },
    {
      title: 'Valor Financiamento',
      dataIndex: 'valorFinanciamento',
      visible: false,
      allowed: true,
      sorter: (a: any, b: any) => a.valorFinanciamento - b.valorFinanciamento,
      render: (text: any) => moneyMask.format(text),
      ...getColumnSearchPropsServer('valorFinanciamento', 'string', 'Valor Financiamento', pagination.filters),
    },
    {
      title: 'Valor Subsídio',
      dataIndex: 'valorSubisidio',
      visible: false,
      allowed: true,
      sorter: (a: any, b: any) => a.valorSubisidio - b.valorSubisidio,
      render: (text: any) => moneyMask.format(text),
      ...getColumnSearchPropsServer('valorSubisidio', 'string', 'Valor Subsídio', pagination.filters),
    },
    {
      title: 'Valor FGTS',
      dataIndex: 'valorFGTS',
      visible: false,
      allowed: true,
      sorter: (a: any, b: any) => a.valorFGTS - b.valorFGTS,
      render: (text: any) => moneyMask.format(text),
      ...getColumnSearchPropsServer('valorFGTS', 'string', 'Valor FGTS', pagination.filters),
    },
    {
      title: 'Valor Entrada',
      dataIndex: 'valorEntrada',
      visible: false,
      allowed: true,
      sorter: (a: any, b: any) => a.valorEntrada - b.valorEntrada,
      render: (text: any) => moneyMask.format(text),
      ...getColumnSearchPropsServer('valorEntrada', 'string', 'Valor Entrada', pagination.filters),
    },
    {
      title: 'Produtos',
      dataIndex: 'produtos',
      visible: false,
      allowed: true,
      render: (ps: any) => (
        <ul>
          {ps.map((p: any) => (
            <li>{p.Descricao}</li>
          ))}
        </ul>
      ),
      //sorter: (a:any, b:any) => sortAlpha(a.clienteCPF2, b.clienteCPF2),
    },
    {
      title: 'Histórico',
      dataIndex: 'historico',
      width: 250,
      visible: true,
      allowed: true,
      render: (text: any) =>
        text ? (
          <div>
            <Typography.Text strong>{text[0].notas}</Typography.Text>
            <Typography.Paragraph>
              {`${moment(text[0].data).format('DD/MM/YYYY HH:mm')} - ${text[0].responsavel}`}
            </Typography.Paragraph>
          </div>
        ) : (
          ''
        ),
    },
  ];
  return (
    <div>
      <ClienteForm />
      <FichaPop visible={ficha.visible} setVisible={setFicha} />
      <Modal
        open={historicoview}
        title="Histórico"
        onCancel={() => {
          sethistoricoview(false);
          sethistorico(null);
        }}
        footer={[
          <Button
            onClick={() => {
              sethistoricoview(false);
              sethistorico(null);
            }}
          >
            Fechar
          </Button>,
        ]}
      >
        <Form
          name="historicoForm"
          wrapperCol={{ span: 18 }}
          labelCol={{ span: 6 }}
          form={historicoForm}
          initialValues={{ data: moment(new Date()) }}
          onFinish={(values) => dispatch(AddHistorico(values))}
        >
          <Form.Item label="id" name="id" style={{ display: 'none' }}>
            <Input />
          </Form.Item>
          <Form.Item label="Data" name="data">
            <DatePicker format="DD/MM/YYYY HH:mm" showTime />
          </Form.Item>
          <Form.Item label="Notas" name="notas">
            <Input.TextArea />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6 }}>
            <Button size="small" type="primary" htmlType="submit" loading={savinghistorico}>
              Salvar
            </Button>
          </Form.Item>
        </Form>
        <Divider />
        <div style={{ maxHeight: 400, overflow: 'scroll' }}>
          <List
            size="small"
            loadMore
            dataSource={historico ? clientes.find((c) => c.id === historico).historico : []}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={item.notas}
                  description={`${moment(item.data).format('DD/MM/YYYY HH:mm')} - ${item.responsavel}`}
                />
              </List.Item>
            )}
          />
        </div>
      </Modal>
      <ReactToPrint
        trigger={() => (
          <Button size="small" type="primary" ghost style={{ width: 100 }}>
            Imprimir
          </Button>
        )}
        content={() => printRef.current}
      />
      <div style={{ display: 'none' }}>
        <PrintPage
          ref={printRef}
          title={`Lista de clientes - ${moment(new Date()).format('DD/MM/YYYY')}`}
          columns={colunas.filter((c) => c.visible)}
          dataSource={clientes}
        />
      </div>
      <Input.Search
        placeholder="Buscar..."
        style={{ width: 250, float: 'right', marginBottom: 10 }}
        onSearch={(v) => {
          setFiltroGeral(v);
          dispatch(GetClientesPagination({ data: pagination, geral: v }));
        }}
      />
      <Table
        size="small"
        rowKey="id"
        loading={loadingclientes}
        columns={colunas.filter((c) => c.visible)}
        dataSource={clientes}
        scroll={{ y: 'calc(100vh - 280px)', x: 1024 }}
        onChange={(pagination, filters, sorter) => {
          dispatch(SetPagination(pagination, filters));
          dispatch(GetClientesPagination({ data: { pagination, filters, sorter }, geral: filtroGeral }));
        }}
        pagination={{
          position: ['bottomRight'],
          size: 'small',
          showSizeChanger: true,
          pageSizeOptions: [10, 25, 50, 100],
          showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
          ...pagination.pagination,
        }}
      />
    </div>
  );
}

export default ClientesView;
