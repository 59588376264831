import logo from '../../../../../assets/img/main.png';
import moment from 'moment';
import { moneyMask, doubleMask, moneyNegMask } from '@utils/masks';
import { isValidType } from '@utils/testType';
import { GetTransferencia } from '@services/api/api.transferencia';
import { useParams } from 'react-router-dom';
import { Skeleton } from 'antd';
import { useAppSelector } from '@config/reduxStore';
import OrdemStatusTag from '../../Ordens/Shared/OrdemStatusTag';

function OrdemPrintBody() {
  const { id } = useParams();
  const { data: ordem, isLoading } = GetTransferencia(id);
  const { user } = useAppSelector((state) => state.user);
  if (isLoading) return <Skeleton active />;
  if (ordem) {
    return (
      <div style={{ backgroundColor: 'white' }}>
        <table className="ordem" style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td rowSpan={5}>
                <img style={{ border: 0 }} alt="logo" src={user?.configuracao?.logo || logo} height={90} />
              </td>
              <td rowSpan={5} style={{ width: '70%', textAlign: 'center' }}>
                <h1 style={{ border: 0, fontSize: 28 }}>ORDEM DE TRANSFERÊNCIA</h1>
              </td>
              <th>Data</th>
              <td>{moment(ordem.dataCriacao).format('DD/MM/YYYY')}</td>
            </tr>
            <tr>
              <th>Nº Ordem</th>
              <td>{ordem.cod}</td>
            </tr>
            <tr>
              <th>Situação</th>
              <td style={{ fontWeight: 900 }}>
                <OrdemStatusTag status={ordem.situacao} />
              </td>
            </tr>
            <tr>
              <th>Solicitante</th>
              <td>{ordem.comprador}</td>
            </tr>
            <tr>
              <th>Origem</th>
              <td>{ordem.origem}</td>
            </tr>
            <tr>
              <td colSpan={4}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th colSpan={8}>Itens</th>
                    </tr>
                    <tr>
                      <th style={{ width: 30 }}></th>
                      <th>Orçamento</th>
                      <th>Categoria</th>
                      <th>Item</th>
                      <th>Unidade</th>
                      <th>V. Unitário</th>
                      <th>Quantidade</th>
                      <th style={{ minWidth: 80 }}>V. Total</th>
                    </tr>
                    {ordem.ordemItens.map((c, index) => {
                      if (
                        isValidType<string>(c.orcamento) &&
                        isValidType<string>(c.categoria) &&
                        isValidType<string>(c.item)
                      )
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{String(c.orcamento)}</td>
                            <td>{String(c.categoria)}</td>
                            <td>
                              {!c.descricao && <p style={{ border: 0 }}>{c.item}</p>}
                              {c.descricao && <p style={{ border: 0 }}>{c.descricao}</p>}
                            </td>
                            <td>{c.unidade}</td>
                            <td style={{ textAlign: 'right' }}>{moneyMask.format(c.valorUnitario)}</td>
                            <td style={{ textAlign: 'right' }}>{doubleMask.format(c.quantidade)}</td>
                            <td style={{ textAlign: 'right' }}>{moneyNegMask.format(c.valorTotal)}</td>
                          </tr>
                        );
                      return null;
                    })}
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  } else return null;
}

export default OrdemPrintBody;
