import { Categoria } from './Categoria';
import { Grupo } from './Grupo';
import { Item } from './Item';
import { Orcamento } from './Orcamento';
import { OrcamentoItem } from './OrcamentoItem';
import { Ordem } from './Ordem';

export enum RazaoEstouro {
  NaoOrcado = 0,
  Valor = 1,
  Quantidade = 2,
  ValoreQuantidade = 3,
  Bloqueado = 4,
  Nenhum = 5,
}
export interface OrdemItem {
  id: number;
  index: number;
  transferencia: boolean;
  unidade: string;
  aprovacao: boolean;
  quantidade: number;
  desconto: number;
  descricao: string;
  valorTransferir: string;
  quantidadeTransferir: string;
  valor: string;
  itens: OrdemItem[];
  valorUnitario: number | string;
  valorTotal: number | string;
  valorReembolso: number | string;
  estourado: boolean;
  razaoEstouro: RazaoEstouro;
  itemID: number;
  ordemID: number;
  categoriaID: number;
  grupoID: number;
  orcamentoID: number;
  orcamentoItemID: number;
  grupo: Grupo | string;
  categoria: Categoria | string;
  item: Item | string;
  ordem: Ordem;
  orcamento: Orcamento | string;
  orcamentoItem: OrcamentoItem;
}
export interface OrdemItemView {
  items: OrdemItem[];
  total: number;
  adiantamento: number;
  desconto: number;
  subtotal: number;
  observacao: number;
  justificativa: string;
  aprovacao: boolean;
  situacao: string;
  cancelada: boolean;
  id: number;
}
export interface AddItemOrdemModel {
  orcamentoID: number;
  orcamentoItemID: number;
  quantidade: number;
  valorUnitario: number;
  desconto: number;
  ordemID: number;
  descricao: string;
}

export interface DeleteItemOrdemModel {
  itemID: number;
  ordemID: number;
}
export interface SaveDescontoOrdem {
  ordemID: number;
  desconto: number;
}
export interface SaveJustificativaOrdem {
  ordemID: number;
  justificativa: string;
}
export interface SaveObservacaoOrdem {
  ordemID: number;
  observacao: string;
}
export interface SaveDescricaoOrdem {
  ordemID: number;
  descricao: string;
}
export interface SubmeterOrdemModel {
  id?: number;
  cod?: string;
}
export interface CancelarOrdemModel {
  id: number;
  motivo: string;
  cod: string;
  replaced: boolean;
}
export interface FinalizarOrdemModel {
  id?: number;
  cod?: string;
}
export interface UploadFileOrdem {
  data: FormData;
  ordemID: number;
  cod: string;
}
export interface DeleteFileOrdem {
  fileID: number;
  ordemID: number;
  cod: string;
}
export interface AddNotaFiscalOrdem {
  numeroNF: number;
  ordemID: number;
}
export interface DeleteNotaFiscalOrdem {
  id: number;
  ordemID: number;
}
export interface PagarParcialOrdemModel {
  valor: number;
  data: Date;
  ordemID: number;
  id: number;
  cod: String;
  formaPagamento: number;
}
export interface CompilarArquivosOrdemModel {
  ordemid: number;
  arquivosIds: number[];
}
