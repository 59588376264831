import { useEffect, useState } from 'react';
import { Table, Input, Button, Popconfirm, Descriptions } from 'antd';
import {
  GetEmpresasFaturamento,
  FormEmpresaFaturamento,
  DeleteEmpresaFaturamento,
} from '@actions/empresasfaturamentoActions';
import EmpresaFaturamentoForm from './EmpresaFaturamentoForm';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { getColumnSearchProps, sortAlpha, filterobject } from '@utils/filterandsort';
import { SetPageHeader } from '@actions/userActions';
import { Permission } from '@hooks//usePermissions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { ColumnsType } from 'antd/lib/table';
import { EmpresaFaturamento } from '@models/EmpresaFaturamento';

function EmpresasFaturamentoView() {
  const dispatch = useAppDispatch();
  const empresasfaturamento = useAppSelector((state) => state.empresasfaturamento);
  const [filter, setFilter] = useState('');
  const [filters, setFilters] = useState(null);
  useEffect(() => {
    dispatch(GetEmpresasFaturamento());
    dispatch(
      SetPageHeader({
        title: 'Engenharia',
        backbutton: true,
        extras: [
          <Permission key="1" permission="empresafaturamento.add">
            <Button onClick={() => dispatch(FormEmpresaFaturamento(null, true))} type="primary">
              Nova Empresa para Faturamento
            </Button>
          </Permission>,
        ],
        subtitle: 'EmpresasFaturamento',
        menu: 'empresasfaturamento',
      })
    );
  }, []);
  const columns: ColumnsType<EmpresaFaturamento> = [
    {
      width: 80,
      fixed: 'left',
      render: (_, row) => (
        <div>
          <Button icon={<EditOutlined />} onClick={() => dispatch(FormEmpresaFaturamento(row.id, true))} size="small" />
          <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(DeleteEmpresaFaturamento(row.id))}>
            <Button icon={<DeleteOutlined />} size="small" />
          </Popconfirm>
        </div>
      ),
    },
    {
      title: 'Nome',
      dataIndex: 'nome',
      sorter: (a, b) => sortAlpha(a.nome, b.nome),
      ...getColumnSearchProps('nome', 'string', 'Nome', filters),
    },
    {
      title: 'Endereço',
      dataIndex: 'endereco',
      sorter: (a, b) => sortAlpha(a.endereco, b.endereco),
      ...getColumnSearchProps('endereco', 'string', 'Endereço', filters),
    },
    {
      title: 'Telefone',
      dataIndex: 'telefone',
      sorter: (a, b) => sortAlpha(a.telefone, b.telefone),
      ...getColumnSearchProps('telefone', 'string', 'Telefone', filters),
    },
  ];
  return (
    <div>
      <EmpresaFaturamentoForm />
      <div style={{ textAlign: 'right' }}>
        <Input style={{ width: 200 }} onChange={(e) => setFilter(e.target.value)} placeholder="Buscar" allowClear />
      </div>
      <Table
        size="small"
        rowKey="id"
        onChange={(paginantion, filters, sorter) => setFilters({ paginantion, filters, sorter })}
        loading={empresasfaturamento.loadingempresasfaturamento}
        columns={columns}
        dataSource={filterobject(empresasfaturamento.empresasfaturamento, filter)}
        scroll={{ y: 'calc(100vh - 300px)', x: 1024 }}
        pagination={{
          position: ['bottomRight'],
          size: 'small',
          showSizeChanger: true,
          pageSizeOptions: [10, 25, 50, 100],
          showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
        }}
        expandedRowRender={(row) => (
          <Descriptions size="small" bordered>
            <Descriptions.Item label="Nome">{row.nome}</Descriptions.Item>
            <Descriptions.Item label="Endereço">{row.endereco}</Descriptions.Item>
            <Descriptions.Item label="Cidade">{row.cidade}</Descriptions.Item>
            <Descriptions.Item label="Estado">{row.estado}</Descriptions.Item>
            <Descriptions.Item label="CEP">{row.cep}</Descriptions.Item>
            <Descriptions.Item label="CPF/CNPJ">{row.cpF_CNPJ}</Descriptions.Item>
            <Descriptions.Item label="Telefone">{row.telefone}</Descriptions.Item>
          </Descriptions>
        )}
      />
    </div>
  );
}
export default EmpresasFaturamentoView;
