import { apiEndpoints } from '@constants/apiEndpoints';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Axios } from '@config/axios';

export const getGrupos = createAsyncThunk(
  'messages/getGrupos',
  async () => await Axios.get(apiEndpoints.MESSAGES.GET_GRUPOS)
);
export const getGruposUser = createAsyncThunk(
  'messages/getGruposUser',
  async () => await Axios.get(apiEndpoints.MESSAGES.GET_GRUPOS_USER)
);
export const joinGroup = createAsyncThunk(
  'messages/joinGroup',
  async (id: number) => await Axios.get(apiEndpoints.MESSAGES.JOIN_GROUP, { params: { id: id } })
);
export const getNotifications = createAsyncThunk(
  'messages/getNotifications',
  async ({ take, skip }: { take: any; skip: any }) =>
    await Axios.get('/api/v2/Notificacoes/getNotifications', { params: { take: take, skip: skip } })
);
export const getNewNotification = createAsyncThunk(
  'messages/getNewNotification',
  async () => await Axios.get(apiEndpoints.MESSAGES.GET_NEW_NOTIFICATION)
);
export const setLastRead = createAsyncThunk(
  'messages/setLastRead',
  async () => await Axios.get(apiEndpoints.MESSAGES.SET_LAST_READ)
);
export const getLastRead = createAsyncThunk(
  'messages/getLastRead',
  async () => await Axios.get(apiEndpoints.MESSAGES.GET_LAST_READ)
);
export const addNotification = createAction('messages/addNotification', (data) => ({ payload: data }));
export const resetUnread = createAction('messages/resetUnread');
export const setMessageRead = createAsyncThunk(
  'messages/setMessageRead',
  async (id: number) => await Axios.get(apiEndpoints.MESSAGES.SET_MESSAGE_READ, { params: { id: id } })
);
export const setMessageUnread = createAsyncThunk(
  'messages/setMessageUnread',
  async (id: number) => await Axios.get(apiEndpoints.MESSAGES.SET_MESSAGE_UNREAD, { params: { id: id } })
);
export const setUsers = createAction('messages/setMessageUnread', (users) => ({
  payload: { users },
}));
export const getOnlineUsers = createAsyncThunk(
  'messages/getOnlineUsers',
  async () => await Axios.get(apiEndpoints.MESSAGES.GET_ONLINE_USERS)
);
export const checkAllRead = createAsyncThunk(
  'messages/checkAllRead',
  async () => await Axios.get(apiEndpoints.MESSAGES.CHECK_ALL_READ)
);
