import { AppDispatch } from '@config/reduxStore';
import { apiEndpoints } from '@constants/apiEndpoints';
import { Axios } from '@config/axios';

export const GetObra = function (casaid: number) {
    return function (dispatch: AppDispatch) {
        dispatch({
            type: 'GET_OBRA',
            payload: Axios.get(apiEndpoints.OBRA.GET_OBRA, { params: { casaid } }),
        });
    };
};
export const AddObra = function (descricao: string, casaid: number, tipo: number) {
    return function (dispatch: AppDispatch) {
        dispatch({
            type: 'ADD_OBRA',
            payload: Axios.post(apiEndpoints.OBRA.ADD_OBRA, { descricao, casaid, tipo }),
        });
    };
};
export const DeleteObra = function (processoid: number | string) {
    return function (dispatch: AppDispatch) {
        dispatch({
            type: 'DELETE_OBRA',
            payload: Axios.post(apiEndpoints.OBRA.DELETE_OBRA, { processoid }),
        });
    };
};

export const ChangeOrder = function (casaid: number, processoid: number | string, up: boolean) {
    return function (dispatch: AppDispatch) {
        dispatch({
            type: 'CHANGE_ORDER_OBRA',
            payload: Axios.get(apiEndpoints.OBRA.CHANGE_ORDER, { params: { casaid, processoid, up } }),
        });
    };
};
export const ChangeProgresso = function (processoid: number, progresso: number) {
    return function (dispatch: AppDispatch) {
        dispatch({
            type: 'CHANGE_PROGRESSO_OBRA_CLIENTE',
            payload: Axios.post(apiEndpoints.OBRA.CHANGE_PROGRESSO, { processoid, progresso }),
        });
    };
};
