import { Button, Space } from 'antd';
import { useFolhaForm } from './useFolhaForm';

function FolhaFormNavigation() {
  const { step, numSteps, previous, form, folha } = useFolhaForm();
  return (
    <Space>
      <Button key={0} size="small" type="primary" ghost disabled={step === 0} onClick={() => previous()}>
        Voltar
      </Button>
      <Button
        key={1}
        type="primary"
        size="small"
        disabled={
          step === numSteps - 1 ||
          (step === 1 && folha?.colaboradores.length === 0) ||
          (step === 2 && folha?.itens.length === 0)
        }
        onClick={() => form.submit()}
      >
        Avançar
      </Button>
    </Space>
  );
}

export default FolhaFormNavigation;
