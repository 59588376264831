import React from 'react';
import logo from '../../../assets/img/main.png';
import { Table, Descriptions, Button, Skeleton, Row, Col, Progress, Divider, Modal, Carousel } from 'antd';
import { moneyMask, moneyNegMask } from '@utils/masks';
import { EyeOutlined } from '@ant-design/icons';
import moment from 'moment';
import GalleryModal from '../Cadastros/Empreendimentos/GalleryModal';
import { GaleriaInvestimento } from '@models/RelatorioInvestimento';
import { LancamentoInvestimento } from '@models/LancamentoInvestimento';
import { InvestidorReducer } from '@reducers/investidorReducer';

class RelatorioInvestimento2 extends React.Component<
  {
    investidor: InvestidorReducer;
    customLogo: string;
  },
  { viewGaleria: boolean; galeriaid: null | number }
> {
  state: { viewGaleria: false; galeriaid: null };
  render() {
    const { loadingrelatorio, relatorio } = this.props.investidor;
    return (
      <div>
        <Skeleton loading={loadingrelatorio} active>
          <div style={{ textAlign: 'center' }} className="onprint">
            <img src={this.props.customLogo || logo} alt="logo" height={100} />
            <br />
            <br />
            <br />
            <h3>Relatório do Investidor</h3>
            <br />
            <br />
            <br />
          </div>
          <Divider>Informações Gerais</Divider>
          <Descriptions bordered size="small" style={{ marginBottom: 8 }} layout="vertical">
            <Descriptions.Item label="Data">{moment(new Date()).format('DD/MM/YYYY')}</Descriptions.Item>
            <Descriptions.Item label="Última Atualização">
              {moment(relatorio?.lastUpdate).isValid() ? moment(relatorio?.lastUpdate).format('DD/MM/YYYY') : ''}
            </Descriptions.Item>
            <Descriptions.Item label="Investidor">{relatorio?.investidor}</Descriptions.Item>
            <Descriptions.Item label="Empreendimento">{relatorio?.empreendimento}</Descriptions.Item>
            <Descriptions.Item label="Nº de Casas">{relatorio?.numCasas}</Descriptions.Item>
            <Descriptions.Item label="Descrição" span={3}>
              {relatorio?.descricao}
            </Descriptions.Item>
          </Descriptions>
          <Row gutter={[8, 8]}>
            <Col flex="1 1 320px">
              <Divider>Progresso da Obra</Divider>
              <Table
                className="tabela"
                rowKey="id"
                size="small"
                dataSource={relatorio?.processosObra || []}
                pagination={false}
              >
                <Table.Column title="Item" render={(_, __, index) => index + 1} />
                <Table.Column title="Descrição" dataIndex="descricao" />
                <Table.Column title="Progresso" dataIndex="progresso" render={(text) => <Progress percent={text} />} />
              </Table>
            </Col>
            <Col flex="1 1 320px">
              <Divider>Progresso da Legalização</Divider>
              <Table
                className="tabela"
                rowKey="id"
                size="small"
                dataSource={relatorio?.processosLegalizacao || []}
                pagination={false}
              >
                <Table.Column title="Item" render={(_, __, index) => index + 1} />
                <Table.Column title="Descrição" dataIndex="descricao" />
                <Table.Column title="Progresso" dataIndex="progresso" render={(text) => <Progress percent={text} />} />
              </Table>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col flex="1 1 320px">
              <Divider>Distribuição</Divider>
              <Table size="small" rowKey="id" className="tabela" dataSource={relatorio?.casas || []} pagination={false}>
                <Table.Column title="Item" render={(_, __, index) => index + 1} />
                <Table.Column title="Unidade" dataIndex="endereço" />
                <Table.Column title="Proprietário" dataIndex="proprietario" />
                <Table.Column
                  title="Data Distribuição"
                  dataIndex="dataDistribuido"
                  render={(text) => (moment(text).isValid() ? moment(text).format('DD/MM/YYYY') : '')}
                />
                <Table.Column
                  title="Valor Distribuído"
                  dataIndex="distribuido"
                  render={(text) => moneyMask.format(text)}
                />
              </Table>
            </Col>
            <Col flex="1 1 320px">
              <Row gutter={[8, 8]}>
                <Col flex="1 1 300px">
                  <Divider>Lançamentos</Divider>
                  <Table
                    className="tabela"
                    size="small"
                    dataSource={relatorio?.lancamentosInvestimento || []}
                    pagination={false}
                    rowKey="id"
                  >
                    <Table.Column title="Item" render={(_, __, index) => index + 1} />
                    <Table.Column
                      title="Data"
                      dataIndex="data"
                      render={(text) => (moment(text).isValid() ? moment(text).format('DD/MM/YYYY') : '')}
                    />
                    <Table.Column title="Descrição" dataIndex="descricao" />
                    <Table.Column
                      title="Tipo"
                      dataIndex="tipo"
                      render={(text) => (text === 0 ? 'Aporte' : 'Resgate')}
                    />
                    <Table.Column title="Valor" dataIndex="valor" render={(text) => moneyMask.format(text)} />
                  </Table>
                </Col>
                <Col flex="1 1 500px">
                  <Divider>Resumo Financeiro</Divider>
                  <Descriptions size="small" bordered className="tabela">
                    <Descriptions.Item label="Quotas Adquiridas">{relatorio?.quotas}</Descriptions.Item>
                    <Descriptions.Item label="Valor da Quota">
                      {moneyNegMask.format(relatorio?.valorQuota)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Aporte Necessário">
                      {moneyNegMask.format(relatorio?.aporteNecessario)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Total Aportado">
                      {moneyNegMask.format(relatorio?.aportado)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Aporte Pendente">
                      {moneyNegMask.format(relatorio?.aportePendente)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Total Retornado">
                      {moneyNegMask.format(relatorio?.retornado)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Total Resgatado">
                      {moneyNegMask.format(relatorio?.resgatado)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Saldo Disponível">
                      {moneyNegMask.format(relatorio?.retornado - relatorio?.resgatado - relatorio?.aportePendente)}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                <Col flex="1 1 300px">
                  <Divider>Galeria de Fotos</Divider>
                  <GalleryModal />
                  <Table pagination={false} size="small" rowKey="id" dataSource={relatorio?.galerias || []}>
                    <Table.Column
                      render={(_, row: LancamentoInvestimento) => (
                        <Button
                          size="small"
                          icon={<EyeOutlined />}
                          onClick={() => {
                            this.setState({ viewGaleria: true, galeriaid: row.id });
                          }}
                        />
                      )}
                    />
                    <Table.Column title="Item" render={(_, __, index) => index + 1} />
                    <Table.Column title="Descrição" dataIndex="titulo" />
                    <Table.Column title="Fotos" render={(_, row: GaleriaInvestimento) => row.arquivos.length} />
                  </Table>
                  <Modal
                    onCancel={() => {
                      this.setState({ viewGaleria: false, galeriaid: null });
                    }}
                    open={this.state?.viewGaleria}
                    title={
                      this.state?.galeriaid
                        ? `${relatorio?.galerias.find((c) => c.id === this.state?.galeriaid).titulo}`
                        : ''
                    }
                    width="95%"
                    footer={[
                      <Button
                        key="1"
                        onClick={() => {
                          this.setState({ viewGaleria: false, galeriaid: null });
                        }}
                      >
                        Fechar
                      </Button>,
                    ]}
                  >
                    <Carousel>
                      {this.state?.galeriaid !== null ? (
                        relatorio?.galerias
                          .find((c) => c.id === this.state?.galeriaid)
                          ?.arquivos.map((c, index) => (
                            <div key={index}>
                              <img
                                alt={c as string}
                                src={c as string}
                                style={{
                                  height: 'calc(100vh - 200px)',
                                  textAlign: 'center',
                                  margin: 'auto',
                                }}
                              />
                            </div>
                          ))
                      ) : (
                        <div>Galeria sem fotos</div>
                      )}
                    </Carousel>
                  </Modal>
                </Col>
              </Row>
            </Col>
          </Row>
        </Skeleton>
      </div>
    );
  }
}

export default RelatorioInvestimento2;
