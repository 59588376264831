import { useEffect } from 'react';
import { GetPrimeiraParcela } from '@actions/dashboardActions';
import { Card, Table, Tabs, Button, Tooltip } from 'antd';
import { EyeFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Contrato } from '@models/Dashboard';

function PrimeiraParcela() {
  const dispatch = useAppDispatch();
  const { hoje, amanha, depoisdeamanha, loadingparcelas } = useAppSelector((state) => state.dashboard.primeiraparcela);
  useEffect(() => {
    dispatch(GetPrimeiraParcela());
  }, []);
  return (
    <Card size="small" title="Primeiras Parcelas a Vencer" style={{ height: 370 }}>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane key="1" tab="Hoje">
          <Table
            size="small"
            showHeader={false}
            rowKey="id"
            dataSource={hoje}
            loading={loadingparcelas}
            pagination={false}
            scroll={{ y: 280 }}
          >
            <Table.Column
              render={(_, row: Contrato) => (
                <Tooltip title="Ficha do Imóvel">
                  <Link to={`/Casas/${row.id}`}>
                    <Button size="small" icon={<EyeFilled />} />
                  </Link>
                </Tooltip>
              )}
            />
            <Table.Column title="Endereço" dataIndex="endereco" />
            <Table.Column title="Proprietario" dataIndex="proprietarioNome1" />
            <Table.Column title="Telefone" dataIndex="proprietarioTelefone1" />
          </Table>
        </Tabs.TabPane>
        <Tabs.TabPane key="2" tab="Amanhã">
          <Table
            size="small"
            showHeader={false}
            rowKey="id"
            dataSource={amanha}
            loading={loadingparcelas}
            pagination={false}
            scroll={{ y: 280 }}
          >
            <Table.Column
              render={(_, row: Contrato) => (
                <Tooltip title="Ficha do Imóvel">
                  <Link to={`/Casas/${row.id}`}>
                    <Button size="small" icon={<EyeFilled />} />
                  </Link>
                </Tooltip>
              )}
            />
            <Table.Column title="Endereço" dataIndex="endereco" />
            <Table.Column title="Proprietario" dataIndex="proprietarioNome1" />
            <Table.Column title="Telefone" dataIndex="proprietarioTelefone1" />
          </Table>
        </Tabs.TabPane>
        <Tabs.TabPane key="3" tab="Depois de Amanhã">
          <Table
            size="small"
            showHeader={false}
            rowKey="id"
            dataSource={depoisdeamanha}
            loading={loadingparcelas}
            pagination={false}
            scroll={{ y: 280 }}
          >
            <Table.Column
              render={(_, row: Contrato) => (
                <Tooltip title="Ficha do Imóvel">
                  <Link to={`/Casas/${row.id}`}>
                    <Button size="small" icon={<EyeFilled />} />
                  </Link>
                </Tooltip>
              )}
            />
            <Table.Column title="Endereço" dataIndex="endereco" />
            <Table.Column title="Proprietario" dataIndex="proprietarioNome1" />
            <Table.Column title="Telefone" dataIndex="proprietarioTelefone1" />
          </Table>
        </Tabs.TabPane>
      </Tabs>
    </Card>
  );
}
export default PrimeiraParcela;
