import { useEffect, useState } from 'react';
import { SetPageHeader } from '@actions/userActions';
import { Permission } from '@hooks//usePermissions';
import { Link } from 'react-router-dom';
import { Button, Popconfirm, Input, Table, Tooltip, Modal, Form, Select, Typography, message, Space } from 'antd';
import {
  DeleteOrcamento,
  CopyOrcamento,
  BloquearOrcamento,
  AtivarOrcamento,
  SalvarDescricao,
  GetOrcamentosServer,
} from '@actions/orcamentosActions';
import { sortAlpha, getColumnSearchProps, getColumnSearchPropsServer } from '@utils/filterandsort';
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  CopyOutlined,
  AuditOutlined,
  LockFilled,
  UnlockOutlined,
  FileOutlined,
  PoweroffOutlined,
  HighlightOutlined,
} from '@ant-design/icons';
import { GetEmps } from '@actions/empsActions';
import { GetCasas } from '@actions/casasActions';
import { GetUsuarios } from '@actions/usuariosActions';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { moneyMask } from '@utils/masks';
import { Axios } from '@config/axios';
import AuditoriasModal from './AuditoriasModal';
import DiariosModal from './DiariosModal';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { ColumnsType } from 'antd/lib/table';
import { Orcamento } from '@models/Orcamento';
import { isValidType } from '@utils/testType';
import { Casa } from '@models/Casa';

function OrcamentosView() {
  const dispatch = useAppDispatch();
  const { orcamentos, loadingorcamentos, copiandoorcamento, resumoGlobal, loadingGlobal, total } = useAppSelector(
    (state) => state.orcamentos
  );
  const { emps, loadingemps } = useAppSelector((state) => state.emps);
  const { casas, loadingcasas } = useAppSelector((state) => state.casas);
  const [filters, setFilters] = useState<any>({
    pagination: { pageSize: 10, current: 1 },
    filters: {
      id: [],
      bloqueado: [],
      ativo: [],
      descricao: [],
      empreendimento: [],
      total: [],
      ativoButton: true,
    },
    sorter: { field: 'id', order: 'descend' },
  });

  const [addmodal, setaddmodal] = useState(false);
  const [resumoModal, setResumoModal] = useState(false);
  const [addingorcamento, setaddingorcamento] = useState(false);
  const [copymodal, setcopymodal] = useState(false);
  const [form] = Form.useForm();
  const [addform] = Form.useForm();
  const [ativo, setAtivo] = useState(true);
  const [auditoriasView, setAuditoriasView] = useState(false);
  const [diariosView, setDiariosView] = useState(false);
  const [obraid, setObraId] = useState(null);
  useEffect(() => {
    if (copiandoorcamento === false) form.resetFields();
  }, [copiandoorcamento, form]);
  useEffect(() => {
    dispatch(GetUsuarios());
    dispatch(GetOrcamentosServer(filters));
    dispatch(GetEmps());
    dispatch(GetCasas());
    dispatch(
      SetPageHeader({
        title: 'Engenharia',
        backbutton: true,
        extras: [
          <Space direction="horizontal" key={1}>
            <Link to="/DashboardOrcamentos">
              <Button>Dashboard</Button>
            </Link>

            <Permission key="2" permission="orcamento.add">
              <Button type="primary" onClick={() => setaddmodal(true)}>
                Novo Orçamento
              </Button>
            </Permission>
          </Space>,
        ],
        subtitle: 'Orçamentos',
        menu: 'orcamentos',
      })
    );
  }, []);

  const columns: ColumnsType<Orcamento> = [
    {
      width: 150,
      fixed: 'left',
      render: (_, row) => (
        <div>
          <Permission permission="orcamento.block">
            {row.bloqueado && (
              <Tooltip title="Desbloquear Orçamento">
                <Button icon={<LockFilled />} size="small" onClick={() => dispatch(BloquearOrcamento(row.id))} />
              </Tooltip>
            )}
            {!row.bloqueado && (
              <Tooltip title="Bloquear Orçamento">
                <Button icon={<UnlockOutlined />} size="small" onClick={() => dispatch(BloquearOrcamento(row.id))} />
              </Tooltip>
            )}
            {row.ativo && (
              <Tooltip title="Desativar Orçamento">
                <Button
                  icon={<PoweroffOutlined style={{ color: 'green' }} />}
                  size="small"
                  onClick={() => dispatch(AtivarOrcamento(row.id))}
                />
              </Tooltip>
            )}
            {!row.ativo && (
              <Tooltip title="Ativar Orçamento">
                <Button
                  icon={<PoweroffOutlined style={{ color: 'red' }} />}
                  size="small"
                  onClick={() => dispatch(AtivarOrcamento(row.id))}
                />
              </Tooltip>
            )}
          </Permission>
          <Permission permission="orcamento.update">
            {!row.bloqueado && (
              <Link to={`/OrcamentoForm/${row.id}`}>
                <Button icon={<EditOutlined />} size="small" />
              </Link>
            )}
          </Permission>
          <Permission permission="orcamento.get">
            <Link to={`/OrcamentoView/${row.id}`}>
              <Button icon={<EyeOutlined />} size="small" />
            </Link>
          </Permission>
          <Permission permission="orcamento.delete">
            <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(DeleteOrcamento(row.id))}>
              <Button icon={<DeleteOutlined />} size="small" />
            </Popconfirm>
          </Permission>
          <Permission permission="orcamento.add">
            <Tooltip title="Copiar Orçamento">
              <Button
                icon={<CopyOutlined />}
                size="small"
                onClick={() => {
                  form.setFieldsValue({ id: row.id });
                  setcopymodal(true);
                }}
              />
            </Tooltip>
          </Permission>
          <Tooltip title="Auditorias">
            <Button
              size="small"
              icon={<AuditOutlined />}
              onClick={() => {
                setObraId(row.id);
                setAuditoriasView(true);
              }}
            />
          </Tooltip>
          <Tooltip title="Diário de Obra">
            <Button
              size="small"
              icon={<FileOutlined />}
              onClick={() => {
                setObraId(row.id);
                setDiariosView(true);
              }}
            />
          </Tooltip>
        </div>
      ),
    },
    {
      title: 'Descrição',
      dataIndex: 'descricao',
      sorter: (a, b) => sortAlpha(a.descricao, b.descricao),
      ...getColumnSearchPropsServer('descricao', 'string', 'Descrição', filters),
      render: (text, row) => (
        <Typography.Paragraph
          ellipsis={{ expandable: true }}
          editable={{
            icon: <HighlightOutlined />,
            tooltip: 'Click aqui para editar!',
            onChange: (value) => dispatch(SalvarDescricao(row.id, value)),
          }}
        >
          {text ? text : ''}
        </Typography.Paragraph>
      ),
    },
    {
      title: 'Empreendimento',
      dataIndex: 'empreendimento',
      sorter: (a, b) => sortAlpha(a.empreendimento, b.empreendimento),
      filteredValue: filters ? filters.filters.empreendimento : null,
      filters: emps.map((c) => ({ text: c.nome, value: c.nome })),
      onFilter: (value, row) => row.empreendimento === value,
    },
    {
      title: 'Casa',
      dataIndex: 'casa',
      sorter: (a, b) => isValidType<string>(a.casa) && isValidType<string>(b.casa) && sortAlpha(a.casa, b.casa),
      ...getColumnSearchPropsServer('casa', 'string', 'Casa', filters),
    },
  ];
  const colResumoGlobal = [
    {
      title: 'Orcamentos',
      dataIndex: 'descricao',
      sorter: (a: Casa, b: Casa) => sortAlpha(a.descricao, b.descricao),
      ...getColumnSearchProps('descricao', 'string', 'Orçamentos', filters),
    },
    {
      title: 'Orçado',
      dataIndex: 'orcado',
      sorter: (a: Casa, b: Casa) => sortAlpha(a.orcado, b.orcado),
      ...getColumnSearchProps('orcado', 'string', 'Orçado', filters),
      render: (v: string) => moneyMask.format(v),
    },
    {
      title: 'Realizado',
      dataIndex: 'custos',
      sorter: (a: Casa, b: Casa) => sortAlpha(a.custos, b.custos),
      ...getColumnSearchProps('custos', 'string', 'Realizado', filters),
      render: (v: number) => moneyMask.format(v),
    },
  ];
  return (
    <div>
      <AuditoriasModal visible={auditoriasView} setVisible={setAuditoriasView} id={obraid} />
      <DiariosModal visible={diariosView} setVisible={setDiariosView} id={obraid} />
      <Modal
        open={resumoModal}
        title="Resumo Financeiro dos Orçamentos"
        onCancel={() => setResumoModal(false)}
        footer={[
          <Button key="1" onClick={() => setResumoModal(false)}>
            Fechar
          </Button>,
        ]}
      >
        <Table
          rowKey="id"
          loading={loadingGlobal}
          scroll={{ y: 'calc(100vh - 220px)', x: 400 }}
          bordered
          pagination={false}
          size="small"
          columns={colResumoGlobal}
          dataSource={resumoGlobal}
          summary={() => (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} align="right">
                <Typography.Text strong>TOTAL</Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} align="right">
                <Typography.Text strong>
                  {moneyMask.format(
                    resumoGlobal.map((c: any) => c.orcado.length > 0)
                      ? resumoGlobal.map((c: any) => c.orcado).reduce((a: number, b: any) => a + b, 0)
                      : 0
                  )}
                </Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} align="right">
                <Typography.Text strong>
                  {moneyMask.format(
                    resumoGlobal.map((c: any) => c.custos.length > 0)
                      ? resumoGlobal.map((c: any) => c.custos).reduce((a: number, b: any) => a + b, 0)
                      : 0
                  )}
                </Typography.Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          )}
        />
      </Modal>
      <Modal
        open={addmodal}
        title="Novo Orçamento"
        onCancel={() => setaddmodal(false)}
        footer={[
          <Button key="1" loading={addingorcamento} onClick={() => setaddmodal(false)}>
            Fechar
          </Button>,
          <Button key="2" type="primary" loading={addingorcamento} onClick={() => addform.submit()}>
            Salvar
          </Button>,
        ]}
      >
        <Form
          form={addform}
          layout="vertical"
          name="orcamentoForm"
          scrollToFirstError
          initialValues={{ orcamentosItens: [], planejavel: true }}
          onFinish={(values) => {
            setaddingorcamento(true);
            Axios.post('/api/v2/Orcamentos/AddOrcamento', { ...values })
              .then((res) => {
                message.success('Orçamento criado com sucesso!');
                setaddingorcamento(false);
                setaddingorcamento(false);
                window.location.href = '/OrcamentoForm/' + res.data;
              })
              .catch(() => {
                message.error('Erro ao criar orçamento!');
                setaddingorcamento(false);
              });
          }}
        >
          <Form.Item name="id" style={{ display: 'none' }}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Empreendimento"
            name="empreendimentoID"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              loading={loadingemps}
              onChange={() => form.setFieldsValue({ casaID: null })}
            >
              {emps.map((c) => (
                <Select.Option key={c.id} value={c.id}>
                  {c.nome}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => (
              <Form.Item label="Casa" name="casaID" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                <Select showSearch optionFilterProp="label" loading={loadingcasas}>
                  {casas
                    .filter((c) => c.empreendimentoID === Number(getFieldValue('empreendimentoID')))
                    .map((c) => (
                      <Select.Option
                        value={c.id}
                        key={c.id}
                        label={c.endereco + c.numero + c.complemento + c.lote + c.quadra + c.proprietarioNome1}
                      >
                        <div>
                          <div>{c.proprietarioNome1}</div>
                          <div>{`${c.endereco}, ${c.numero} ${c.complemento || ''} Lote/Quadra ${c.lote}/${
                            c.quadra
                          }`}</div>
                        </div>
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item label="Descrição" name="descricao" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="deposito" initialValue={false}>
            <Checkbox>Depósito/Almoxarifado</Checkbox>
          </Form.Item>
          <Form.Item name="planejavel" initialValue={true}>
            <Checkbox defaultChecked={true}>Planejável</Checkbox>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        open={copymodal}
        title="Copiar orçamento"
        onCancel={() => setcopymodal(false)}
        footer={[
          <Button key="1" onClick={() => setcopymodal(false)}>
            Fechar
          </Button>,
          <Button key="1" type="primary" onClick={() => form.submit()} loading={copiandoorcamento}>
            Salvar
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" onFinish={(values) => dispatch(CopyOrcamento(values))}>
          <Form.Item name="id" style={{ display: 'none' }}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Descrição do novo orçamento"
            name="descricao"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Empreendimento"
            name="empreendimentoID"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              loading={loadingemps}
              onChange={() => form.setFieldsValue({ casaID: null })}
            >
              {emps.map((c) => (
                <Select.Option key={c.id} value={c.id}>
                  {c.nome}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => (
              <Form.Item label="Casa" name="casaID" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                <Select showSearch optionFilterProp="label" loading={loadingcasas}>
                  {casas
                    .filter((c) => c.empreendimentoID === Number(getFieldValue('empreendimentoID')))
                    .map((c) => (
                      <Select.Option
                        value={c.id}
                        key={c.id}
                        label={c.endereco + c.numero + c.complemento + c.lote + c.quadra + c.proprietarioNome1}
                      >
                        <div>
                          <div>{c.proprietarioNome1}</div>
                          <div>{`${c.endereco}, ${c.numero} ${c.complemento || ''} Lote/Quadra ${c.lote}/${
                            c.quadra
                          }`}</div>
                        </div>
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item name="deposito" initialValue={false}>
            <Checkbox>Depósito/Almoxarifado</Checkbox>
          </Form.Item>
        </Form>
      </Modal>

      <div style={{ textAlign: 'right' }}>
        <Checkbox
          value={ativo}
          disabled={loadingorcamentos}
          onChange={(e) => {
            setAtivo(!e.target.value);
            dispatch(
              GetOrcamentosServer({ ...filters, filters: { ...filters.filters, ativoButton: !e.target.checked } })
            );
          }}
        >
          Inativos
        </Checkbox>
      </div>
      <Table
        size="small"
        rowKey="id"
        onChange={(pagination, filters, sorter: any) => {
          let f = {
            ...filters,
            id: filters.id || [],
            descricao: filters.descricao || [],
            bloqueado: filters.bloqueado || [],
            ativo: filters.ativo || [],
            empreendimento: filters.empreendimento || [],
            total: filters.total || [],
            ativoButton: ativo,
          };
          setFilters({ pagination, filters: f, sorter });
          dispatch(
            GetOrcamentosServer({
              pagination,
              filters: f,
              sorter: {
                ...sorter,
                field: sorter.field || 'id',
                order: sorter.order || 'descend',
              },
            })
          );
        }}
        loading={loadingorcamentos}
        columns={columns}
        dataSource={orcamentos}
        scroll={{ y: 'calc(100vh - 230px)', x: 400 }}
        pagination={{
          position: ['bottomRight'],
          size: 'small',
          showSizeChanger: true,
          pageSizeOptions: [10, 25, 50, 100],
          total: total,
          showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
        }}
      />
    </div>
  );
}

export default OrcamentosView;
