import { DatePicker, Descriptions, Form, Input, Modal, Select } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import { moneyMask, moneyNegMask } from '@utils/masks';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { GetOrdemEdit, PagarParcialOrdem } from '@services/api/api.ordem';

const PagamentoParcialModal = ({
  visible,
  setVisible,
}: {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}) => {
  const { id } = useParams();
  const { data: ordem } = GetOrdemEdit(id);
  const pagarParcial = PagarParcialOrdem();
  const [form] = Form.useForm();

  return (
    <Modal
      title="Pagamento Parcial"
      open={visible}
      onCancel={() => setVisible(false)}
      okButtonProps={{ loading: pagarParcial.isLoading }}
      onOk={() => form.submit()}
      cancelText="Fechar"
    >
      <Form
        form={form}
        labelCol={{ span: 8 }}
        onFinish={(values) =>
          pagarParcial.mutate({
            ...values,
            ordemID: Number(id) ? id : null,
            cod: Number(id) ? null : id,
            valor: moneyMask.normalize(values.valor),
          })
        }
      >
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Form.Item
          name="valor"
          label="Valor"
          initialValue={moneyMask.format(0)}
          normalize={(v) => moneyMask.format(moneyMask.normalize(v))}
          rules={[
            { required: true, message: 'Campo obrigatório' },
            {
              validator: async (_, valor) => {
                if (moneyMask.normalize(valor) === 0) {
                  return Promise.reject(new Error('Valor zero'));
                }
                return null;
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="data" label="Data do pagamento" initialValue={moment()}>
          <DatePicker format="DD/MM/YYYY" allowClear={false} />
        </Form.Item>
        <Form.Item
          label="Forma de pagamento"
          name="formaPagamento"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Select>
            <Select.Option value={1}>Transferência</Select.Option>
            <Select.Option value={0}>Cartão</Select.Option>
            <Select.Option value={2}>Boleto</Select.Option>
            <Select.Option value={3}>Outros</Select.Option>
          </Select>
        </Form.Item>
      </Form>
      <Descriptions colon bordered layout="horizontal" column={1} size="small">
        <Descriptions.Item label="Total a Pagar">{moneyMask.format(ordem?.total)}</Descriptions.Item>
        <Descriptions.Item label="Total a Pagar">
          {moneyMask.format(ordem?.pagamentosOrdem.reduce((a, b) => a + b.valor, 0))}
        </Descriptions.Item>
        <Descriptions.Item label="Total Pago">
          {moneyMask.format(ordem?.pagamentosOrdem.reduce((a, b) => a + b.valor, 0))}
        </Descriptions.Item>
        <Descriptions.Item label="Saldo a Pagar">
          {moneyNegMask.format((ordem?.total as number) - ordem?.pagamentosOrdem.reduce((a, b) => a + b.valor, 0))}
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
};

export default PagamentoParcialModal;
