import { apiEndpoints } from '@constants/apiEndpoints';
import { ContratoFornecedor } from '@models/ContratoFornecedor';
import { Fornecedor } from '@models/Fornecedor';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Axios } from '@config/axios';

export const getColaboradores = createAsyncThunk(
  'colaboradores/getColaboradores',
  async (ativo: boolean | null) => await Axios.get(apiEndpoints.COLABORADORES.GET_COLABORADORES, { params: { ativo } })
);
export const deleteColaborador = createAsyncThunk(
  'colaboradores/deleteColaborador',
  async (id: number, thunkAPI) =>
    await Axios.get(apiEndpoints.COLABORADORES.DELETE_COLABORADOR, { params: { id } }).catch((res) =>
      thunkAPI.rejectWithValue(res)
    )
);
export const getArquivos = createAsyncThunk(
  'colaboradores/getArquivos',
  async (colaboradorid: number) =>
    await Axios.get(apiEndpoints.COLABORADORES.GET_ARQUIVOS, { params: { colaboradorid } })
);
export const deleteArquivo = createAsyncThunk(
  'colaboradores/deleteArquivo',
  async (arquivoid: number) => await Axios.post(apiEndpoints.COLABORADORES.DELETE_ARQUIVO, { arquivoid })
);
export const uploadFiles = createAsyncThunk(
  'colaboradores/uploadFiles',
  async (form: FormData) => await Axios.post(apiEndpoints.COLABORADORES.POST_FILE, form)
);
export const salvarColaborador = createAsyncThunk(
  'colaboradores/salvarFornecedor',
  async (values: Fornecedor) =>
    await Axios.post(apiEndpoints.COLABORADORES.SALVAR_COLABORADOR, { ...values, colaborador: true })
);
export const ativaColaborador = createAsyncThunk(
  'colaboradores/ativaColaborador',
  async (id: number) => await Axios.get(apiEndpoints.COLABORADORES.ATIVA_COLABORADOR, { params: { id } })
);
export const salvarContrato = createAsyncThunk(
  'colaboradores/salvarContrato',
  async (data: ContratoFornecedor) => await Axios.post(apiEndpoints.COLABORADORES.SALVAR_CONTRATO, data)
);
export const deleteContrato = createAsyncThunk(
  'colaboradores/deleteContrato',
  async (id: number) => await Axios.delete(apiEndpoints.COLABORADORES.DELETE_CONTRATO, { params: { id } })
);
export const getRelatorioPagamentos = createAsyncThunk(
  'colaboradores/getRelatorioPagamentos',
  async (id: number | string) =>
    await Axios.get(apiEndpoints.COLABORADORES.GET_RELATORIO_PAGAMENTOS, { params: { id } })
);

export const formColaborador = createAction('colaboradores/formColaborador', (id: number | null, open: boolean) => ({
  payload: { id, open },
}));
export const modalArquivoColaborador = createAction(
  'colaboradores/modalArquivoColaborador',
  (id: number | null, open: boolean) => ({
    payload: { id, open },
  })
);
