import { ExtendedAction } from '@config/reduxStore';
import { Categoria } from '@models/Categoria';
import { notification } from 'antd';

interface CategoriasReducer {
  categorias: Categoria[];
  formview: boolean;
  categoria: number;
  loadingcategorias: boolean;
  savingcategoria: boolean;
  feedback: string;
}
var initialState: CategoriasReducer = {
  categorias: [],
  formview: false,
  categoria: null,
  loadingcategorias: false,
  savingcategoria: false,
  feedback: '',
};

const categoriasReducer = function (state = initialState, action: ExtendedAction) {
  switch (action.type) {
    case 'RESET_FEEDBACK':
      state = {
        ...state,
        feedback: '',
      };
      break;
    case 'FORM_CATEGORIA':
      state = {
        ...state,
        formview: action.payload.open,
        feedback: '',
        categoria: action.payload.id ? action.payload.id : null,
      };
      break;
    case 'GET_CATEGORIAS_PENDING':
      state = {
        ...state,
        loadingcategorias: true,
        categorias: initialState.categorias,
      };
      break;
    case 'GET_CATEGORIAS_REJECTED':
      state = {
        ...state,
        loadingcategorias: false,
      };
      break;
    case 'GET_CATEGORIAS_FULFILLED':
      state = {
        ...state,
        loadingcategorias: false,
        categorias: action.payload.data,
      };
      break;
    case 'GET_CATEGORIASOC_PENDING':
      state = {
        ...state,
        loadingcategorias: true,
        categorias: initialState.categorias,
      };
      break;
    case 'GET_CATEGORIASOC_REJECTED':
      state = {
        ...state,
        loadingcategorias: false,
      };
      break;
    case 'GET_CATEGORIASOC_FULFILLED':
      state = {
        ...state,
        loadingcategorias: false,
        categorias: action.payload.data,
      };
      break;
    case 'ADD_CATEGORIA_PENDING':
      state = {
        ...state,
        savingcategoria: true,
        feedback: '',
      };
      break;
    case 'ADD_CATEGORIA_REJECTED':
      state = {
        ...state,
        savingcategoria: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: 'Erro ao criar categoria!' });
      break;
    case 'ADD_CATEGORIA_FULFILLED':
      state = {
        ...state,
        savingcategoria: false,
        feedback: 'Categoria criado com sucesso!',
        categorias: [...state.categorias, action.payload.data.entity],
      };
      notification.success({ message: 'Categoria criada com sucesso!' });
      break;
    case 'UPDATE_CATEGORIA_PENDING':
      state = {
        ...state,
        savingcategoria: true,
        feedback: '',
      };
      break;
    case 'UPDATE_CATEGORIA_REJECTED':
      state = {
        ...state,
        savingcategoria: false,
        feedback: action.payload.response.data.Message,
      };
      break;
    case 'UPDATE_CATEGORIA_FULFILLED':
      state = {
        ...state,
        savingcategoria: false,
        feedback: 'Categoria atualizada com sucesso!',
        categorias: state.categorias.map((c) => {
          if (c.id === action.payload.data.entity.id) return action.payload.data.entity;
          else return c;
        }),
      };
      notification.success({ message: 'Categoria alterada com sucesso' });
      break;
    case 'DELETE_CATEGORIA_PENDING':
      state = {
        ...state,
        loadingcategorias: true,
        feedback: '',
      };
      break;
    case 'DELETE_CATEGORIA_REJECTED':
      notification.error({ message: action.payload.response.data.Message });
      state = {
        ...state,
        loadingcategorias: false,
        feedback: action.payload.response.data.Message,
      };
      notification.warning({ message: state.feedback });
      break;
    case 'DELETE_CATEGORIA_FULFILLED':
      state = {
        ...state,
        loadingcategorias: false,
        feedback: 'Categoria excluída com sucesso!',
        categorias: state.categorias.filter((c) => c.id !== action.payload.data),
      };
      notification.success({ message: state.feedback });
      break;
    default:
      return state;
  }
  return state;
};

export default categoriasReducer;
