import { Alert, Button, Input, Modal, Form, InputNumber, Typography, Select, Divider, Card } from 'antd';
import { useEffect } from 'react';
import { GetCategoriasOC } from '@actions/categoriasActions';
import { GetGruposOC } from '@actions/gruposActions';
import { CloseTransferenciaView, GetOrcamentosOC, SolicitarTransferencia } from '@actions/orcamentosActions';
import { doubleMask, moneyMask } from '@utils/masks';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { OrdemItem } from '@models/OrdemItem';

function Transferir() {
  const dispatch = useAppDispatch();
  const { transferenciadados, transferenciaview, realizandotransferencia } = useAppSelector(
    (state) => state.orcamentos
  );
  const { orcamentos, loadingorcamentos } = useAppSelector((state) => state.orcamentos);
  const { categorias, loadingcategorias } = useAppSelector((state) => state.categorias);
  const { grupos } = useAppSelector((state) => state.grupos);
  const [formtrans] = Form.useForm();
  useEffect(() => {
    if (transferenciadados.itemID !== null) {
      formtrans.setFieldsValue({
        itens: [
          {
            quantidade: transferenciadados.realizadoQuantidade,
            orcamentoID: null,
            grupoID: null,
            categoriaID: null,
            valor: moneyMask.format(
              (transferenciadados.realizadoQuantidade * transferenciadados.realizadoValor) /
                transferenciadados.realizadoQuantidade
            ),
          },
        ],
        orcamentoID: transferenciadados.orcamentoID,
        categoriaID: transferenciadados.categoriaID,
        grupoID: transferenciadados.grupoID,
        itemID: transferenciadados.itemID,
        quantidadeTransferir: doubleMask.format(transferenciadados.quantidade),
        quantidade: doubleMask.format(transferenciadados.realizadoQuantidade),
        valorUnitario: moneyMask.format(transferenciadados.realizadoValor / transferenciadados.realizadoQuantidade),
        valorTransferir: moneyMask.format(
          (transferenciadados.realizadoQuantidade * transferenciadados.realizadoValor) /
            transferenciadados.realizadoQuantidade
        ),
      });
    }
  }, [transferenciadados]);
  useEffect(() => {
    dispatch(GetGruposOC());
    dispatch(GetCategoriasOC());
    dispatch(GetOrcamentosOC(true));
  }, []);
  return (
    <Modal
      open={transferenciaview}
      title={'Realizar Transferência: ' + transferenciadados.unidade + ' - ' + transferenciadados.item}
      onCancel={() => dispatch(CloseTransferenciaView())}
      footer={[
        <Button type="primary" key="2" onClick={() => formtrans.submit()} loading={realizandotransferencia}>
          Solicitar Transferência
        </Button>,
        <Button key="1" onClick={() => dispatch(CloseTransferenciaView())}>
          Fechar
        </Button>,
      ]}
    >
      <Divider>Resumo</Divider>
      <Form
        form={formtrans}
        name="formtrans"
        onFinish={(values: OrdemItem) =>
          dispatch(
            SolicitarTransferencia({
              ...values,
              valorUnitario: moneyMask.normalize(values.valorUnitario as string),
              valorTransferir: moneyMask.normalize(values.valorTransferir as string),
              quantidadeTransferir: doubleMask.normalize(values.quantidadeTransferir as string),
              itens: values.itens.map((c) => ({
                ...c,
                valor: moneyMask.normalize(c.valor),
              })),
            })
          )
        }
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        initialValues={{
          quantidade: 0,
          quantidadeTransferir: doubleMask.format(0),
          valorTransferir: moneyMask.format(0),
          itens: [
            {
              quantidade: 0,
              itemID: null,
              categoriaID: null,
              grupoID: null,
              orcamentoID: null,
              valor: moneyMask.format(0),
            },
          ],
        }}
      >
        <Form.Item style={{ display: 'none' }} name="itemID">
          <Input />
        </Form.Item>
        <Form.Item style={{ display: 'none' }} name="categoriaID">
          <Input />
        </Form.Item>
        <Form.Item style={{ display: 'none' }} name="grupoID">
          <Input />
        </Form.Item>
        <Form.Item style={{ display: 'none' }} name="orcamentoID">
          <Input />
        </Form.Item>
        <Form.Item label="Quant. Disponível" name="quantidade" valuePropName="children">
          <Typography.Text />
        </Form.Item>
        <Form.Item label="Quant. a Transferir" name="quantidadeTransferir" valuePropName="children">
          <Typography.Text />
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            return (
              doubleMask.normalize(getFieldValue('quantidadeTransferir')) > transferenciadados.realizadoQuantidade && (
                <Alert type="error" message="Quantidades de destino somadas é superior à quantidade disponível" />
              )
            );
          }}
        </Form.Item>
        <Form.Item
          label="Valor Unitário Médio"
          name="valorUnitario"
          valuePropName="children"
          help="Valor médio calculado (valor realizado / quantidade realizada)"
        >
          <Typography.Text />
        </Form.Item>
        <Form.Item
          label="Valor Total"
          name="valorTransferir"
          valuePropName="children"
          help="Valor total a transferir (Valor unitário médio x Quantidade a Tranferir)"
        >
          <Typography.Text />
        </Form.Item>
        <Form.Item label="Descrição" name="descricao" rules={[{ required: true, message: 'Campo obrigatório' }]}>
          <Input />
        </Form.Item>
        <Divider>Destino</Divider>
        <Form.List name="itens">
          {(fields, { add, remove }) => (
            <div>
              {fields.map((_, index) => {
                return (
                  <Card
                    key={index}
                    title={index + 1}
                    size="small"
                    extra={[
                      <Button
                        key="1"
                        size="small"
                        ghost
                        disabled={fields.length === 1}
                        icon={<MinusOutlined />}
                        onClick={() => remove(index)}
                      />,
                    ]}
                  >
                    <Form.Item
                      name={[index, 'orcamentoID']}
                      label="Orçamento"
                      rules={[{ required: true, message: 'Campo obrigatório' }]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        loading={loadingorcamentos}
                        onChange={() =>
                          formtrans.setFieldsValue({
                            itens: formtrans.getFieldValue('itens').map((c: OrdemItem, i: number) => {
                              if (i === index) {
                                c.grupoID = null;
                                return c;
                              } else return c;
                            }),
                          })
                        }
                      >
                        {orcamentos.map((c) => (
                          <Select.Option key={c.id} value={c.id}>
                            {c.descricao}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name={[index, 'categoriaID']}
                      label="Categoria"
                      rules={[{ required: true, message: 'Campo obrigatório' }]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        loading={loadingcategorias}
                        onChange={() =>
                          formtrans.setFieldsValue({
                            itens: formtrans.getFieldValue('itens').map((c: OrdemItem, i: number) => {
                              if (i === index) {
                                c.grupoID = null;
                                return c;
                              } else return c;
                            }),
                          })
                        }
                      >
                        {categorias.map((c) => (
                          <Select.Option key={c.id} value={c.id}>
                            {c.descricao}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item noStyle shouldUpdate>
                      {({ getFieldValue }) => (
                        <Form.Item
                          name={[index, 'grupoID']}
                          label="Grupo"
                          rules={[{ required: true, message: 'Campo obrigatório' }]}
                        >
                          <Select showSearch optionFilterProp="children" loading={loadingcategorias}>
                            {grupos
                              .filter(
                                (c) =>
                                  c.categoriaID === getFieldValue('itens')[index].categoriaID &&
                                  c.orcamentoID === getFieldValue('itens')[index].orcamentoID
                              )
                              .map((c) => (
                                <Select.Option key={c.id} value={c.id}>
                                  {c.descricao}
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                      )}
                    </Form.Item>
                    <Form.Item noStyle shouldUpdate>
                      {({ getFieldValue, setFieldsValue }) => (
                        <Form.Item
                          name={[index, 'quantidade']}
                          label="Quantidade"
                          rules={[{ required: true, message: 'Campo obrigatório' }]}
                        >
                          <InputNumber
                            decimalSeparator=","
                            min={0}
                            onChange={(v) =>
                              setFieldsValue({
                                quantidadeTransferir: doubleMask.format(
                                  getFieldValue('itens').reduce((a: number, b: OrdemItem) => a + b.quantidade, 0)
                                ),
                                valorTransferir: moneyMask.format(
                                  getFieldValue('itens').reduce((a: number, b: OrdemItem) => a + b.quantidade, 0) *
                                    moneyMask.normalize(getFieldValue('valorUnitario'))
                                ),
                                itens: getFieldValue('itens').map((c: OrdemItem, tempindex: number) => {
                                  if (tempindex === index) {
                                    c.valor = moneyMask.format(v * moneyMask.normalize(getFieldValue('valorUnitario')));
                                    return c;
                                  } else return c;
                                }),
                              })
                            }
                          />
                        </Form.Item>
                      )}
                    </Form.Item>
                    <Form.Item label="Valor" name={[index, 'valor']} valuePropName="children">
                      <Typography.Text />
                    </Form.Item>
                  </Card>
                );
              })}
              <Button
                size="small"
                type="primary"
                ghost
                icon={<PlusOutlined />}
                onClick={() =>
                  add({
                    quantidade: 0,
                    orcamentoID: null,
                    grupoID: null,
                    categoriaID: null,
                    valor: moneyMask.format(0),
                  })
                }
              >
                Adicionar Destino
              </Button>
            </div>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
}

export default Transferir;
