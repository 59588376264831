import { apiEndpoints } from '@constants/apiEndpoints';
import { OrcamentoItem } from '@models/OrcamentoItem';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { fetcher } from './api';

export type OrcamentoItensResult = {
  orcamentoID: number;
  itens: OrcamentoItem[];
};

export const GetOrcamentoItens = (orcamentoID: number, comSaldo: boolean = true) => {
  return useQuery<OrcamentoItensResult, AxiosError<any>>(
    ['orcamentoItensList', orcamentoID, comSaldo],
    () =>
      fetcher(
        comSaldo
          ? apiEndpoints.ORCAMENTOS.GET_ORCAMENTO_ITENS_ORDEM
          : apiEndpoints.ORCAMENTOS.GET_ORCAMENTO_ITENS_GERAL_ORDEM,
        { params: { orcamentoID: orcamentoID } }
      ),
    {
      initialData: { orcamentoID: null, itens: [] },
      enabled: !!orcamentoID,
      staleTime: 0,
      onError: (err) => console.log(err.response?.data?.Message),
    }
  );
};
