import { AppDispatch } from '@config/reduxStore';
import { apiEndpoints } from '@constants/apiEndpoints';
import { Fornecedor } from '@models/Fornecedor';
import { Axios } from '@config/axios';
export const GetFornecedores = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_FORNECEDORES',
      payload: Axios.get(apiEndpoints.FORNECEDOR.GET_FORNECEDORES),
    });
  };
};
export const SalvarFornecedor = function (values: Fornecedor) {
  return function (dispatch: AppDispatch) {
    if (values.id) {
      dispatch({
        type: 'UPDATE_FORNECEDOR',
        payload: Axios.post(apiEndpoints.FORNECEDOR.SALVAR_FORNECEDOR, { ...values, colaborador: false }),
      });
    } else {
      dispatch({
        type: 'ADD_FORNECEDOR',
        payload: Axios.post(apiEndpoints.FORNECEDOR.SALVAR_FORNECEDOR, { ...values, colaborador: false }),
      });
    }
  };
};
export const DeleteFornecedor = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DELETE_FORNECEDOR',
      payload: Axios.get(apiEndpoints.FORNECEDOR.DELETE_FORNECEDOR, { params: { id } }),
    });
  };
};
export const FormFornecedor = function (id: number, open: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'FORM_FORNECEDOR',
      payload: { id, open },
    });
  };
};
export const ModalArquivoFornecedor = function (id: number, open: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'MODAL_ARQUIVOS_FORNECEDOR',
      payload: { id, open },
    });
  };
};
export const UploadFiles = function (form: FormData) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'UPLOAD_FILES_FORNECEDOR',
      payload: Axios.post(apiEndpoints.FORNECEDOR.POST_FILE, form),
    });
  };
};
export const DownloadArquivo = function (fileid: number, filename: string) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DOWNLOAD_FILE_FORNECEDOR',
      payload: Axios.get(apiEndpoints.FORNECEDOR.DOWNLOAD, { params: { fileid }, responseType: 'blob' }).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      }),
    });
  };
};
export const DeleteArquivo = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DELETE_FILE_FORNECEDOR',
      payload: Axios.post(apiEndpoints.FORNECEDOR.DELETE_FILE, { arquivoid: id }),
    });
  };
};
export const GetArquivos = function (fornecedorid: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_ARQUIVOS_FORNECEDOR',
      payload: Axios.get(apiEndpoints.FORNECEDOR.GET_ARQUIVOS, { params: { fornecedorid } }),
    });
  };
};
