import { useEffect, useState } from 'react';
import { Table, Input, Button, Popconfirm, Checkbox, Switch } from 'antd';
import {
  getColaboradores,
  formColaborador,
  deleteColaborador,
  modalArquivoColaborador,
  ativaColaborador,
} from '@actions/colaboradoresActions';
import ColaboradorForm from './ColaboradorForm';
import { EditOutlined, DeleteOutlined, FolderOutlined, AreaChartOutlined } from '@ant-design/icons';
import { getColumnSearchProps, sortAlpha, filterobject } from '@utils/filterandsort';
import { SetPageHeader } from '@actions/userActions';
import { Permission } from '@hooks//usePermissions';
import ColaboradorArquivos from './ColaboradorArquivos';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { ColumnsType } from 'antd/lib/table';
import { Fornecedor } from '@models/Fornecedor';

function ColaboradoresView() {
  const { colaboradores, loadingColaboradores } = useAppSelector((state) => state.colaboradores);
  const [filter, setFilter] = useState('');
  const [filters, setFilters] = useState(null);
  const [inativos, setInativos] = useState(false);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getColaboradores(null));
    dispatch(
      SetPageHeader({
        title: 'Engenharia',
        backbutton: true,
        extras: [
          <Permission key="1" permission="colaborador.add">
            <Button onClick={() => dispatch(formColaborador(null, true))} type="primary">
              Novo Colaborador
            </Button>
          </Permission>,
        ],
        subtitle: 'Colaboradores',
        menu: 'colaboradores',
      })
    );
  }, [dispatch]);
  const columns: ColumnsType<Fornecedor> = [
    {
      width: 90,
      fixed: 'left',
      render: (_, row) => (
        <div>
          <Permission permission="colaborador.update">
            <Button icon={<EditOutlined />} onClick={() => dispatch(formColaborador(row.id, true))} size="small" />
          </Permission>
          <Permission permission="colaborador.delete">
            <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(deleteColaborador(row.id))}>
              <Button icon={<DeleteOutlined />} size="small" />
            </Popconfirm>
          </Permission>
          <Permission permission="colaborador.update">
            <Button
              icon={<FolderOutlined />}
              onClick={() => dispatch(modalArquivoColaborador(row.id, true))}
              size="small"
            />
          </Permission>
          <Link to={`/Colaboradores/${row.id}`}>
            <Button icon={<AreaChartOutlined />} size="small" />
          </Link>
        </div>
      ),
    },
    {
      title: 'Ativo',
      dataIndex: 'ativo',
      align: 'center',
      width: 80,
      sorter: (a: any, b: any) => a.ativo - b.ativo,
      render: (v, row) => <Switch checked={v} onChange={() => dispatch(ativaColaborador(row.id))} title="Ativo" />,
      ...getColumnSearchProps('ativo', 'string', 'Ativo', filters, [
        { text: 'SIM', value: true },
        { text: 'NÃO', value: false },
      ]),
    },
    {
      title: 'Nome',
      dataIndex: 'nome',
      width: 150,
      sorter: (a, b) => sortAlpha(a.nome, b.nome),
      ...getColumnSearchProps('nome', 'string', 'Nome', filters),
    },
    {
      title: 'Endereço',
      dataIndex: 'endereco',
      sorter: (a, b) => sortAlpha(a.endereco, b.endereco),
      ...getColumnSearchProps('endereco', 'string', 'Endereço', filters),
    },
    {
      title: 'Telefone',
      dataIndex: 'telefone',
      sorter: (a, b) => sortAlpha(a.telefone, b.telefone),
      ...getColumnSearchProps('telefone', 'string', 'Telefone', filters),
    },
    {
      title: 'Função',
      dataIndex: 'funcao',
      sorter: (a: any, b: any) => a.funcao - b.funcao,
      render: (text) => (text ? text : ''),
      ...getColumnSearchProps('funcao', 'customselect', 'Função', filters, [
        { text: 'Ajudante', value: 'Ajudante' },
        { text: 'Mestre de Obras', value: 'Mestre de Obras' },
        { text: 'Pedreiro', value: 'Pedreiro' },
        { text: 'Servente', value: 'Servente' },
        { text: 'Pintor', value: 'Pintor' },
        { text: 'Eletricista', value: 'Eletricista' },
        { text: 'Instalações', value: 'Instalações' },
        { text: 'Carpinteiro', value: 'Carpinteiro' },
        { text: 'Gesseiro', value: 'Gesseiro' },
        { text: 'Revestimentos', value: 'Revestimentos' },
        { text: 'Armador', value: 'Armador' },
        { text: 'Encanador', value: 'Encanador' },
        { text: 'Piscineiro', value: 'Piscineiro' },
        { text: 'Encarregado', value: 'Encarregado' },
        { text: 'Outro', value: 'Outro' },
      ]),
    },
    {
      title: 'Dados Bancários',
      children: [
        {
          title: 'Titular',
          dataIndex: 'nomeConta',
          sorter: (a, b) => sortAlpha(a.nomeConta, b.nomeConta),
          ...getColumnSearchProps('nomeConta', 'string', 'Nome da Conta', filters),
        },
        {
          title: 'Pix',
          dataIndex: 'pix',
          sorter: (a: Fornecedor, b: Fornecedor) => sortAlpha(a.pix, b.pix),
          ...getColumnSearchProps('pix', 'string', 'Pix', filters),
        },
        {
          title: 'Banco',
          dataIndex: 'banco',
          sorter: (a, b) => sortAlpha(a.banco, b.banco),
          ...getColumnSearchProps('banco', 'string', 'Banco', filters),
        },
        {
          title: 'Agência',
          dataIndex: 'agencia',
          sorter: (a, b) => sortAlpha(a.agencia, b.agencia),
          ...getColumnSearchProps('agencia', 'string', 'Agência', filters),
        },
        {
          title: 'Operação',
          dataIndex: 'operacao',
          sorter: (a, b) => sortAlpha(a.operacao, b.operacao),
          ...getColumnSearchProps('operacao', 'string', 'Operação', filters),
        },
        {
          title: 'Conta',
          dataIndex: 'conta',
          sorter: (a, b) => sortAlpha(a.conta, b.conta),
          ...getColumnSearchProps('conta', 'string', 'Conta', filters),
        },
        {
          title: 'CPF/CNPJ',
          dataIndex: 'cpF_CNPJ_Conta',
          sorter: (a, b) => sortAlpha(a.cpF_CNPJ_Conta, b.cpF_CNPJ_Conta),
          ...getColumnSearchProps('cpF_CNPJ_Conta', 'string', 'CPF/CNPJ', filters),
        },
      ],
    },
  ];
  return (
    <div>
      <ColaboradorForm />
      <ColaboradorArquivos />
      <div style={{ textAlign: 'right' }}>
        <Checkbox checked={inativos} onChange={(e) => setInativos(e.target.checked)}>
          Inativos
        </Checkbox>
        <Input style={{ width: 200 }} onChange={(e) => setFilter(e.target.value)} placeholder="Buscar" allowClear />
      </div>
      <Table
        size="small"
        rowKey="id"
        onChange={(paginantion, filters, sorter) => setFilters({ paginantion, filters, sorter })}
        loading={loadingColaboradores}
        columns={columns}
        bordered
        dataSource={filterobject(
          colaboradores.filter((c) => (inativos ? c.ativo === false : c.ativo === true)),
          filter
        )}
        scroll={{ y: 'calc(100vh - 300px)', x: 1400 }}
        pagination={{
          position: ['bottomRight'],
          size: 'small',
          showSizeChanger: true,
          pageSizeOptions: [10, 25, 50, 100],
          showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
        }}
      />
    </div>
  );
}
export default ColaboradoresView;
