import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Divider,
  Modal,
  Row,
  Form,
  Input,
  Typography,
  Checkbox,
  Tabs,
  Select,
  Radio,
  Space,
  DatePicker,
  List,
  Spin,
} from 'antd';
import { cpfMask, mobMask, moneyMask, moneyNegMask } from '@utils/masks';
import { SwapOutlined, SendOutlined } from '@ant-design/icons';
import { AddHistoricoFicha, SalvarCliente } from '@actions/correspondenciaActions';
import moment from 'moment';
import { Axios } from '@config/axios';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { ClienteCorrespondencia } from '@models/ClienteCorrespondencia';

function FichaPop(props: {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<{ ficha: ClienteCorrespondencia; visible: boolean }>>;
}) {
  const dispatch = useAppDispatch();
  const { visible, setVisible } = props;
  const { cliente, loadingcliente, savinghistorico } = useAppSelector((state) => state.correspondencia);
  const { loteamentos } = useAppSelector((state) => state.loteamentos);
  const { corretores } = useAppSelector((state) => state.corretores);
  const { ccas } = useAppSelector((state) => state.ccas);
  const { agencias } = useAppSelector((state) => state.agencias);
  const { modalidades } = useAppSelector((state) => state.modalidades);
  const { parceiros } = useAppSelector((state) => state.parceiros);
  const [comentario, setComentario] = useState<string>('');
  const [formcliente] = Form.useForm();
  const [titulo, setTitulo] = useState<string>('');
  const [swap, setSwap] = useState(false);
  useEffect(() => {
    var temp = [
      formcliente?.getFieldValue('clienteNome1'),
      formcliente?.getFieldValue('clienteCPF1'),
      formcliente?.getFieldValue('clienteTelefone1'),
    ];
    formcliente?.setFieldsValue({
      clienteNome1: formcliente?.getFieldValue('clienteNome2'),
      clienteCPF1: formcliente?.getFieldValue('clienteCPF2'),
      clienteTelefone1: formcliente?.getFieldValue('clienteTelefone2'),
      clienteNome2: temp[0],
      clienteCPF2: temp[1],
      clienteTelefone2: temp[2],
    });
  }, [swap, formcliente]);
  useEffect(() => {
    formcliente?.setFieldsValue({
      ...cliente,
      dataAssinatura: moment(cliente?.dataAssinatura).isValid() ? moment(cliente?.dataAssinatura) : null,
      dataAprovado: moment(cliente?.dataAprovado).isValid() ? moment(cliente?.dataAprovado) : null,
      dataConformidade: moment(cliente?.dataConformidade).isValid() ? moment(cliente?.dataConformidade) : null,
      dataConforme: moment(cliente?.dataConforme).isValid() ? moment(cliente?.dataConforme) : null,
      competencia: moment(cliente?.competencia).isValid() ? moment(cliente?.competencia) : null,
      valorCompraVenda: moneyMask.format(cliente?.valorCompraVenda),
      valorAvaliacao: moneyMask.format(cliente?.valorAvaliacao),
      valorFinanciamento: moneyMask.format(cliente?.valorFinanciamento),
      valorSubsidio: moneyMask.format(cliente?.valorSubsidio),
      fgts: moneyMask.format(cliente?.fgts),
      valorEntrada: moneyMask.format(cliente?.valorEntrada),
      valorLiquido: moneyMask.format(cliente?.valorLiquido),
      dataSolicitacaoOS: moment(cliente?.dataSolicitacaoOS).isValid() ? moment(cliente?.dataSolicitacaoOS) : null,
      dataCertidao: moment(cliente?.dataCertidao).isValid() ? moment(cliente?.dataCertidao) : null,
      vencimentoCredito: moment(cliente?.vencimentoCredito).isValid() ? moment(cliente?.vencimentoCredito) : null,
    });
    setTitulo(`${cliente?.clienteNome1} - ${cliente?.clienteCPF1}`);
  }, [cliente]);
  return (
    <Modal
      width="90%"
      style={{ height: '90%' }}
      footer={[
        <Button
          key="1"
          onClick={() => {
            setVisible({ ficha: cliente, visible: false });
            setComentario('');
          }}
        >
          Fechar
        </Button>,
        <Button key="2" type="primary" loading={loadingcliente} onClick={() => formcliente?.submit()}>
          Salvar
        </Button>,
      ]}
      open={visible}
      title={
        <Spin spinning={loadingcliente}>
          <Space>
            <strong style={{ fontSize: 20 }}>{titulo}</strong>
            <Button type="primary" size="small" loading={loadingcliente} onClick={() => formcliente?.submit()}>
              Salvar
            </Button>
          </Space>
        </Spin>
      }
      onCancel={() => {
        setComentario('');
        setVisible({ ficha: cliente, visible: false });
      }}
    >
      <Spin spinning={loadingcliente}>
        <div style={{ height: 500, overflowY: 'scroll' }}>
          <Form
            form={formcliente}
            wrapperCol={{ span: 18 }}
            layout="vertical"
            labelCol={{ span: 6 }}
            size="small"
            scrollToFirstError
            onFinish={(values) => {
              var formdata = new FormData();
              const newValues = {
                ...values,
                valorCompraVenda: moneyMask.normalize(values.valorCompraVenda),
                valorAvaliacao: moneyMask.normalize(values.valorAvaliacao),
                valorFinanciamento: moneyMask.normalize(values.valorFinanciamento),
                valorSubsidio: moneyMask.normalize(values.valorSubsidio),
                fgts: moneyMask.normalize(values.fgts),
                valorLiquido: moneyMask.normalize(values.valorLiquido),
                valorEntrada: moneyMask.normalize(values.valorEntrada),
                dataCertidao: moment(values.dataCertidao).isValid()
                  ? moment(values.dataCertidao).format('YYYY-MM-DD')
                  : '',
                dataCadastro: moment(values.dataCadastro).isValid()
                  ? moment(values.dataCadastro).format('YYYY-MM-DD')
                  : '',
                dataConforme: moment(values.dataConforme).isValid()
                  ? moment(values.dataConforme).format('YYYY-MM-DD')
                  : '',
                dataConformidade: moment(values.dataConformidade).isValid()
                  ? moment(values.dataConformidade).format('YYYY-MM-DD')
                  : '',
                dataAprovado: moment(values.dataAprovado).isValid()
                  ? moment(values.dataAprovado).format('YYYY-MM-DD')
                  : '',
                dataAssinatura: moment(values.dataAssinatura).isValid()
                  ? moment(values.dataAssinatura).format('YYYY-MM-DD')
                  : '',
                competencia: moment(values.competencia).isValid()
                  ? moment(values.competencia).format('YYYY-MM-DD')
                  : '',
                vencimentoCredito: moment(values.vencimentoCredito).isValid()
                  ? moment(values.vencimentoCredito).format('YYYY-MM-DD')
                  : '',
              };
              for (var key in newValues) {
                formdata.append(key, newValues[key] || '');
              }

              dispatch(SalvarCliente(newValues));
            }}
            initialValues={{
              ...cliente,
              dataAssinatura: moment(cliente?.dataAssinatura).isValid() ? moment(cliente?.dataAssinatura) : null,
              dataAprovado: moment(cliente?.dataAprovado).isValid() ? moment(cliente?.dataAprovado) : null,
              dataConformidade: moment(cliente?.dataConforme).isValid() ? moment(cliente?.dataConformidade) : null,
              dataConforme: moment(cliente?.dataConforme).isValid() ? moment(cliente?.dataConforme) : null,
              competencia: moment(cliente?.competencia).isValid() ? moment(cliente?.competencia) : null,
              valorCompraVenda: moneyMask.format(cliente?.valorCompraVenda),
              valorAvaliacao: moneyMask.format(cliente?.valorAvaliacao),
              valorFinanciamento: moneyMask.format(cliente?.valorFinanciamento),
              valorSubsidio: moneyMask.format(cliente?.valorSubsidio),
              fgts: moneyMask.format(cliente?.fgts),
              valorEntrada: moneyMask.format(cliente?.valorEntrada),
              valorLiquido: moneyMask.format(cliente?.valorLiquido),
              dataSolicitacaoOS: moment(cliente?.dataSolicitacaoOS),
              dataCertidao: moment(cliente?.dataCertidao),
            }}
            onChange={() =>
              formcliente?.setFieldsValue({
                valorLiquido: moneyNegMask.format(
                  moneyNegMask.normalize(formcliente?.getFieldValue('valorFinanciamento')) +
                    moneyNegMask.normalize(formcliente?.getFieldValue('valorSubsidio')) +
                    moneyNegMask.normalize(formcliente?.getFieldValue('valorEntrada')) +
                    moneyNegMask.normalize(formcliente?.getFieldValue('fgts'))
                ),
              })
            }
          >
            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>
            <Row gutter={[8, 10]}>
              <Col span={18}>
                <Tabs defaultActiveKey="1">
                  <Tabs.TabPane tab="Cadastro" key="1" forceRender>
                    <Row gutter={[8, 8]}>
                      <Col span={10}>
                        <Form.Item label="Proponente 1" name="clienteNome1">
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={7}>
                        <Form.Item
                          label="CPF"
                          name="clienteCPF1"
                          labelCol={{ span: 12 }}
                          normalize={(value) => cpfMask.format(cpfMask.normalize(value))}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={7}>
                        <Form.Item
                          label="Telefone"
                          name="clienteTelefone1"
                          normalize={(value) => mobMask.format(mobMask.normalize(value))}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <SwapOutlined onClick={() => setSwap(formcliente?.getFieldValue('clienteNome1'))} />
                    <Row gutter={[8, 8]}>
                      <Col span={10}>
                        <Form.Item
                          label="Proponente 2"
                          name="clienteNome2"
                          wrapperCol={{ span: 18 }}
                          labelCol={{ span: 6 }}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={7}>
                        <Form.Item
                          label="CPF"
                          name="clienteCPF2"
                          labelCol={{ span: 12 }}
                          normalize={(value) => cpfMask.format(cpfMask.normalize(value))}
                          rules={[
                            {
                              required: false,
                              pattern: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
                              message: 'CPF inválido',
                            },
                            {
                              validator: (_, value) => {
                                if (value)
                                  if (value.length === 14)
                                    return Axios.get('/api/v2/Correspondencia/CheckCPF', {
                                      params: {
                                        cpf1: value,
                                        clienteid: cliente?.id,
                                      },
                                    });
                                return Promise.resolve();
                              },

                              message: 'CPF já cadastrado',
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (value) {
                                  if (value.length === 14 && getFieldValue('clienteCPF1') === value) {
                                    return Promise.reject('CPFs não podem ser iguais!');
                                  }
                                }
                                return Promise.resolve('erro');
                              },
                            }),
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={7}>
                        <Form.Item
                          label="Telefone"
                          name="clienteTelefone2"
                          normalize={(value) => mobMask.format(mobMask.normalize(value))}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col span={12}>
                        <Form.Item label="Anotações" name="tratativa" wrapperCol={{ span: 24 }}>
                          <Input.TextArea rows={22} />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Checklist" shouldUpdate>
                          {() => (
                            <Space direction="vertical" size={8} style={{ marginLeft: 30 }}>
                              <Form.Item key="1" name="clDocumentacao" valuePropName="checked" noStyle>
                                <Checkbox>Documentação</Checkbox>
                              </Form.Item>
                              <Form.Item key="2" name="clCadastroSICAQ" valuePropName="checked" noStyle>
                                <Checkbox>Cadastro SICAQ</Checkbox>
                              </Form.Item>
                              <Form.Item key="3" name="clConferencia" valuePropName="checked" noStyle>
                                <Checkbox>Conferência</Checkbox>
                              </Form.Item>
                              <Form.Item key="4" name="clAprovacao" valuePropName="checked" noStyle>
                                <Checkbox>Aprovação</Checkbox>
                              </Form.Item>
                              <Form.Item key="5" name="clSolicitarEngenharia" valuePropName="checked" noStyle>
                                <Checkbox>Solicitar Engenharia</Checkbox>
                              </Form.Item>
                              <Form.Item key="6" name="clLaudoSistema" valuePropName="checked" noStyle>
                                <Checkbox>Laudo no Sistema</Checkbox>
                              </Form.Item>
                              <Form.Item key="7" name="clDocumentosImovelSistema" valuePropName="checked" noStyle>
                                <Checkbox>Documentos do Imóvel no Sistema</Checkbox>
                              </Form.Item>
                              <Form.Item key="8" name="clDocumentosVendedorSistema" valuePropName="checked" noStyle>
                                <Checkbox>Documentos do Vendedor no Sistema</Checkbox>
                              </Form.Item>
                              <Form.Item key="9" name="clSIOPI" valuePropName="checked" noStyle>
                                <Checkbox>SIOPI</Checkbox>
                              </Form.Item>
                              <Form.Item key="10" name="clPF3" valuePropName="checked" noStyle>
                                <Checkbox>PF3</Checkbox>
                              </Form.Item>
                              <Form.Item key="11" name="clEnviarSICTD" valuePropName="checked" noStyle>
                                <Checkbox>Enviar SICTD</Checkbox>
                              </Form.Item>
                              <Form.Item key="12" name="clConforme" valuePropName="checked" noStyle>
                                <Checkbox>Conforme</Checkbox>
                              </Form.Item>
                              <Form.Item key="13" name="clContratoAssinado" valuePropName="checked" noStyle>
                                <Checkbox>Contrato Assinado</Checkbox>
                              </Form.Item>
                              <Form.Item key="14" name="clEnviarGarantia" valuePropName="checked" noStyle>
                                <Checkbox>Enviar Garantia</Checkbox>
                              </Form.Item>
                            </Space>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Dados Gerais" key="2" forceRender>
                    <Form.Item
                      label="Modalidade"
                      name="modalidadeCreditoID"
                      rules={[{ required: true, message: 'Campo obrigatório' }]}
                      wrapperCol={{ span: 18 }}
                      labelCol={{ span: 6 }}
                    >
                      <Select optionFilterProp="children" showSearch allowClear>
                        {modalidades.map((c) => (
                          <Select.Option key={c.id} value={c.id}>
                            {c.descricao}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label="Tipo" name="alocacao" wrapperCol={{ span: 18 }} labelCol={{ span: 6 }}>
                      <Radio.Group>
                        <Radio value={true}>Alocação</Radio>
                        <Radio value={false}>Inidividual</Radio>
                        <Radio value={null}>N/A</Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item
                      label="CCA"
                      name="ccaid"
                      normalize={(value) => (value === null ? '' : value)}
                      wrapperCol={{ span: 18 }}
                      labelCol={{ span: 6 }}
                    >
                      <Select optionFilterProp="children" showSearch allowClear>
                        {ccas.map((c) => (
                          <Select.Option key={c.id} value={c.id}>
                            {c.nome}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label="Agência" name="agenciaID" wrapperCol={{ span: 18 }} labelCol={{ span: 6 }}>
                      <Select optionFilterProp="children" showSearch allowClear>
                        {agencias.map((c) => (
                          <Select.Option key={c.id} value={c.id}>
                            {`Banco: ${c.banco} Agência: ${c.nome} (${c.numero})`}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label="Corretor" name="corretorID" wrapperCol={{ span: 18 }} labelCol={{ span: 6 }}>
                      <Select optionFilterProp="children" showSearch allowClear>
                        {corretores.map((c) => (
                          <Select.Option key={c.id} value={c.id}>
                            {c.nome}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label="Parceiro" name="parceiroID" wrapperCol={{ span: 18 }} labelCol={{ span: 6 }}>
                      <Select optionFilterProp="children" showSearch allowClear>
                        {parceiros.map((c) => (
                          <Select.Option key={c.id} value={c.id}>
                            {c.nome}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item shouldUpdate noStyle>
                      {({ getFieldValue }) => {
                        return (
                          <Form.Item
                            label="Loteamento"
                            name="loteamentoID"
                            wrapperCol={{ span: 18 }}
                            labelCol={{ span: 6 }}
                          >
                            <Select
                              optionFilterProp="children"
                              showSearch
                              allowClear
                              disabled={getFieldValue('casaID')}
                            >
                              {loteamentos.map((c) => (
                                <Select.Option key={c.id} value={c.id}>
                                  {c.nome}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Valores" key="3" forceRender>
                    <Row gutter={[8, 8]}>
                      <Col span={10}>
                        <Form.Item shouldUpdate noStyle>
                          {({ getFieldValue }) => (
                            <Form.Item
                              wrapperCol={{ span: 16 }}
                              labelCol={{ span: 8 }}
                              label="Avaliação"
                              name="valorAvaliacao"
                              initialValue={moneyNegMask.format(0)}
                              normalize={(value) => moneyMask.format(moneyMask.normalize(value))}
                            >
                              <Input disabled={getFieldValue('casaID')} />
                            </Form.Item>
                          )}
                        </Form.Item>
                        <Form.Item shouldUpdate noStyle>
                          {({ getFieldValue }) => (
                            <Form.Item
                              style={{ display: ' none' }}
                              wrapperCol={{ span: 16 }}
                              labelCol={{ span: 8 }}
                              label="Compra e Venda"
                              name="valorCompraVenda"
                              initialValue={moneyNegMask.format(0)}
                              normalize={(value) => moneyMask.format(moneyMask.normalize(value))}
                            >
                              <Input disabled={getFieldValue('casaID')} />
                            </Form.Item>
                          )}
                        </Form.Item>
                        <Form.Item
                          wrapperCol={{ span: 16 }}
                          labelCol={{ span: 8 }}
                          label="Financiamento"
                          name="valorFinanciamento"
                          initialValue={moneyNegMask.format(0)}
                          normalize={(value) => moneyMask.format(moneyMask.normalize(value))}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          wrapperCol={{ span: 16 }}
                          labelCol={{ span: 8 }}
                          label="Subsídio"
                          name="valorSubsidio"
                          initialValue={moneyNegMask.format(0)}
                          normalize={(value) => moneyMask.format(moneyMask.normalize(value))}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <Form.Item
                          wrapperCol={{ span: 16 }}
                          labelCol={{ span: 8 }}
                          label="Entrada"
                          name="valorEntrada"
                          initialValue={moneyNegMask.format(0)}
                          normalize={(value) => moneyMask.format(moneyMask.normalize(value))}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          wrapperCol={{ span: 16 }}
                          labelCol={{ span: 8 }}
                          label="FGTS"
                          name="fgts"
                          initialValue={moneyNegMask.format(0)}
                          normalize={(value) => moneyMask.format(moneyMask.normalize(value))}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          wrapperCol={{ span: 16 }}
                          labelCol={{ span: 8 }}
                          label="Valor Líquido"
                          name="valorLiquido"
                          valuePropName="children"
                          initialValue={moneyNegMask.format(0)}
                        >
                          <Typography.Text strong />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item label="Tabela" name="priceSAC">
                          <Select>
                            <Select.Option value="PRICE">PRICE</Select.Option>
                            <Select.Option value="SAC">SAC</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Engenharia" key="4" forceRender>
                    <Form.Item label="Data Solicitação OS" name="dataSolicitacaoOS">
                      <DatePicker format="DD/MM/YYYY" />
                    </Form.Item>
                    <Form.Item hidden label="Endereço" name="endereco">
                      <Input />
                    </Form.Item>
                    <Form.Item label="Matrícula" name="matricula">
                      <Input />
                    </Form.Item>
                    <Form.Item hidden label="ART" name="art">
                      <Input />
                    </Form.Item>
                    <Form.Item label="OS" name="os">
                      <Input />
                    </Form.Item>
                    <Form.Item label="Município" name="municipio">
                      <Select>
                        <Select.Option value="Natal">Natal</Select.Option>
                        <Select.Option value="Parnamirim">Parnamirim</Select.Option>
                        <Select.Option value="Ceará-Mirim">Ceará-Mirim</Select.Option>
                        <Select.Option value="Extremoz">Extremoz</Select.Option>
                        <Select.Option value="São Gonçalo do Amarante">São Gonçalo do Amarante</Select.Option>
                        <Select.Option value="Macaíba">Macaíba</Select.Option>
                        <Select.Option value="Nísia Floresta">Nísia Floresta</Select.Option>
                        <Select.Option value="Tibau">Tibau</Select.Option>
                        <Select.Option value="Outro">Outro</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item label="Data Certidão" name="dataCertidao">
                      <DatePicker format="DD/MM/YYYY" />
                    </Form.Item>
                    <Form.Item label="Observações" name="observacaoCasa">
                      <Input.TextArea />
                    </Form.Item>
                  </Tabs.TabPane>
                </Tabs>
                <Divider orientation="left">Comentários</Divider>
                <Input
                  value={comentario}
                  onPressEnter={() => {
                    dispatch(AddHistoricoFicha({ id: formcliente?.getFieldValue('id'), notas: comentario }));
                    setComentario('');
                  }}
                  onChange={(value) => setComentario(value.target.value)}
                  suffix={
                    <Button
                      size="small"
                      icon={<SendOutlined />}
                      loading={savinghistorico}
                      onClick={() => {
                        dispatch(AddHistoricoFicha({ id: formcliente?.getFieldValue('id'), notas: comentario }));
                        setComentario('');
                      }}
                    />
                  }
                />
                <List
                  size="small"
                  dataSource={cliente?.historico ? cliente?.historico : []}
                  renderItem={(item, index) => (
                    <List.Item key={index}>
                      <List.Item.Meta
                        title={item.notas}
                        description={`${moment(item.data).format('DD/MM/YYYY HH:mm')} - ${item.responsavel}`}
                      />
                    </List.Item>
                  )}
                />
              </Col>
              <Col span={6}>
                <Form.Item labelCol={{ span: 12 }} name="perspectiva" valuePropName="checked">
                  <Checkbox>Assina esse mês</Checkbox>
                </Form.Item>
                <Form.Item name="desistiu" valuePropName="checked">
                  <Checkbox>Desistiu</Checkbox>
                </Form.Item>
                <Form.Item shouldUpdate noStyle>
                  {({ getFieldValue, setFieldsValue }) => (
                    <Form.Item
                      label="Link Documentação"
                      name="linkPasta"
                      valuePropName="children"
                      labelCol={{ span: 24 }}
                    >
                      <Typography.Link
                        target="_blank"
                        href={getFieldValue('linkPasta')}
                        editable={{ onChange: (v) => setFieldsValue({ linkPasta: v }) }}
                      />
                    </Form.Item>
                  )}
                </Form.Item>
                <Form.Item noStyle shouldUpdate>
                  {({ setFieldsValue }) => (
                    <Form.Item name="dataAprovado" label="Avaliação" labelCol={{ span: 24 }}>
                      <DatePicker
                        format="DD/MM/YYYY"
                        onChange={(v) => (v ? setFieldsValue({ vencimentoCredito: moment(v).add(6, 'months') }) : null)}
                      />
                    </Form.Item>
                  )}
                </Form.Item>

                <Form.Item shouldUpdate noStyle>
                  {({ getFieldValue }) =>
                    getFieldValue('dataAprovado') && (
                      <Form.Item name="vencimentoCredito" label="Venc. da Carta">
                        <DatePicker format="DD/MM/YYYY" />
                      </Form.Item>
                    )
                  }
                </Form.Item>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue, setFieldsValue }) => (
                    <Form.Item name="aprovado" label="Status" labelCol={{ span: 24 }}>
                      <Radio.Group
                        disabled={!getFieldValue('dataAprovado')}
                        onChange={(e) => (e.target.value ? setFieldsValue({ relacionamento: false }) : null)}
                      >
                        <Space direction="vertical">
                          <Radio value={true}>Aprovado</Radio>
                          <Radio value={false}>Reprovado</Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  )}
                </Form.Item>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) =>
                    getFieldValue('aprovado') === false && (
                      <Form.Item
                        name="relacionamento"
                        wrapperCol={{ offset: 6 }}
                        valuePropName="checked"
                        labelCol={{ span: 24 }}
                      >
                        <Checkbox>Relacionamento</Checkbox>
                      </Form.Item>
                    )
                  }
                </Form.Item>
                <Form.Item name="dataConformidade" label="Conformidade" labelCol={{ span: 24 }}>
                  <DatePicker format="DD/MM/YYYY" />
                </Form.Item>
                <Form.Item name="dataConforme" label="Conforme" labelCol={{ span: 24 }}>
                  <DatePicker format="DD/MM/YYYY" />
                </Form.Item>
                <Form.Item name="dataAssinatura" label="Assinatura" labelCol={{ span: 24 }}>
                  <DatePicker format="DD/MM/YYYY" />
                </Form.Item>
                <Form.Item name="competencia" label="Competência" labelCol={{ span: 24 }}>
                  <DatePicker.MonthPicker format="MMM/YYYY" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Spin>
    </Modal>
  );
}

export default FichaPop;
