import { AppDispatch } from '@config/reduxStore';
import { apiEndpoints } from '@constants/apiEndpoints';
import { Empreendimento } from '@models/Empreendimento';
import { Investimento } from '@models/Investimento';
import { LancamentoInvestimento } from '@models/LancamentoInvestimento';
import { ProcessoEmpreendimento } from '@models/ProcessoEmpreendimento';
import { Axios } from '@config/axios';
export const GetEmps = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_EMPS',
      payload: Axios.get(apiEndpoints.EMPREENDIMENTOS.GET_EMPREENDIMENTOS),
    });
  };
};
export const GetInvestidoresFull = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_INVESTIDORES_FULL',
      payload: Axios.get(apiEndpoints.EMPREENDIMENTOS.GET_INVESTIDORES_FULL),
    });
  };
};
export const GetEmpreendimento = function (id: number | string) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_EMPREENDIMENTO',
      payload: Axios.get(apiEndpoints.EMPREENDIMENTOS.GET_EMPREENDIMENTO, { params: { id } }),
    });
  };
};
export const DeleteProcessoObra = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DELETE_PROCESSO_OBRA',
      payload: Axios.get(apiEndpoints.EMPREENDIMENTOS.DELETE_PROCESSO, { params: { id } }),
    });
  };
};
export const RemoverInvestimento = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'REMOVER_INVESTIMENTO',
      payload: Axios.get(apiEndpoints.EMPREENDIMENTOS.REMOVER_INVESTIMENTO, { params: { id } }),
    });
  };
};
export const StepProcessoObra = function (id: number, up: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'STEP_PROCESSO_OBRA',
      payload: Axios.get(apiEndpoints.EMPREENDIMENTOS.STEP_PROCESSO, { params: { id, up } }),
    });
  };
};
export const ChangeProgressoObra = function (value: number, id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'CHANGE_PROGRESSO_OBRA',
      payload: Axios.post(apiEndpoints.EMPREENDIMENTOS.CHANGE_PROCESSO, { progresso: value, processoid: id }),
    });
  };
};
export const DeleteProcessoLegalizacao = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DELETE_PROCESSO_LEGALIZACAO',
      payload: Axios.get(apiEndpoints.EMPREENDIMENTOS.DELETE_PROCESSO, { params: { id } }),
    });
  };
};
export const StepProcessoLegalizacao = function (id: number, up: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'STEP_PROCESSO_LEGALIZACAO',
      payload: Axios.get(apiEndpoints.EMPREENDIMENTOS.STEP_PROCESSO, { params: { id, up } }),
    });
  };
};
export const AddProcessoLegalizacao = function (values: ProcessoEmpreendimento) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'ADD_PROCESSO_LEGALIZACAO',
      payload: Axios.post(apiEndpoints.EMPREENDIMENTOS.ADD_PROCESSO, { ...values }),
    });
  };
};
export const AddProcessoObra = function (values: ProcessoEmpreendimento) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'ADD_PROCESSO_OBRA',
      payload: Axios.post(apiEndpoints.EMPREENDIMENTOS.ADD_PROCESSO, { ...values }),
    });
  };
};
export const ChangeProgressoLegalizacao = function (value: number, id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'CHANGE_PROGRESSO_LEGALIZACAO',
      payload: Axios.post(apiEndpoints.EMPREENDIMENTOS.CHANGE_PROCESSO, { progresso: value, processoid: id }),
    });
  };
};
export const SalvarEmpreendimento = function (values: Empreendimento) {
  return function (dispatch: AppDispatch) {
    if (values.id) {
      dispatch({
        type: 'UPDATE_EMP',
        payload: Axios.post(apiEndpoints.EMPREENDIMENTOS.SALVAR_EMPREENDIMENTO, { ...values }),
      });
    } else {
      dispatch({
        type: 'ADD_EMP',
        payload: Axios.post(apiEndpoints.EMPREENDIMENTOS.SALVAR_EMPREENDIMENTO, { ...values }),
      });
    }
  };
};
export const DeleteEmprreendimento = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DELETE_EMP',
      payload: Axios.get(apiEndpoints.EMPREENDIMENTOS.DELETE_EMPREENDIMENTO, { params: { id } }),
    });
  };
};
export const FormEmpreendimento = function (id: number, open: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'FORM_EMP',
      payload: { id, open },
    });
  };
};
export const GetGaleria = function (casaid: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_GALERIA',
      payload: Axios.get(apiEndpoints.EMPREENDIMENTOS.GET_GALERIA, { params: { casaid } }),
    });
  };
};
export const AddGaleria = function (formdata: FormData) {
  return function (dispatch: AppDispatch) {
    //const form = new FormData();
    //form.append()
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    dispatch({
      type: 'ADD_GALERIA',
      payload: Axios.post(apiEndpoints.EMPREENDIMENTOS.POST_FILE, formdata, config),
    });
  };
};
export const DeleteGaleria = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DELETE_GALERIA',
      payload: Axios.get(apiEndpoints.EMPREENDIMENTOS.DELETE_GALERIA, { params: { id } }),
    });
  };
};

export const OpenGaleria = function (galeriaid: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'OPEN_GALERIA',
      payload: galeriaid,
    });
  };
};
export const CloseGaleria = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'CLOSE_GALERIA',
    });
  };
};
export const NovaGaleriaModal = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'NOVA_GALERIA_MODAL',
    });
  };
};
export const CadastroInvestimentoModal = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'CADASTRO_INVESTIMENTO_MODAL',
    });
  };
};
export const GetInvestidores = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_INVESTIDORES',
      payload: Axios.get(apiEndpoints.EMPREENDIMENTOS.GET_INVESTIDORES),
    });
  };
};
export const AddInvestimento = function (values: Investimento) {
  return function (dispatch: AppDispatch) {
    if (values.id) {
      dispatch({
        type: 'SALVAR_INVESTIMENTO',
        payload: Axios.post(apiEndpoints.EMPREENDIMENTOS.SALVAR_INVESTIMENTO, values),
      });
    } else {
      dispatch({
        type: 'ADD_INVESTIMENTO',
        payload: Axios.post(apiEndpoints.EMPREENDIMENTOS.ADD_INVESTIMENTO, values),
      });
    }
  };
};

export const LoadInvestimento = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'LOAD_INVESTIMENTO',
      payload: id,
    });
  };
};
export const LoadInvestidores = function (id?: number) {
  return function (dispatch: AppDispatch) {
    if (id) {
      dispatch({
        type: 'LOAD_INVESTIDORES_MODAL',
        payload: id,
      });
    } else {
      dispatch({
        type: 'CLOSE_INVESTIDORES_MODAL',
      });
    }
  };
};
export const LoadLancamentos = function (id?: number | string) {
  return function (dispatch: AppDispatch) {
    if (id) {
      dispatch({
        type: 'LOAD_LANCAMENTOS_MODAL',
        payload: id,
      });
    } else {
      dispatch({
        type: 'CLOSE_LANCAMENTOS_MODAL',
      });
    }
  };
};
export const GetLancamentos = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_LANCAMENTOS',
      payload: Axios.get(apiEndpoints.EMPREENDIMENTOS.GET_LANCAMENTOS, { params: { id } }),
    });
  };
};
export const AddLancamento = function (values: LancamentoInvestimento) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'ADD_LANCAMENTO',
      payload: Axios.post(apiEndpoints.EMPREENDIMENTOS.ADD_LANCAMENTO, values),
    });
  };
};
export const DeleteLancamento = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DELETE_LANCAMENTO',
      payload: Axios.get(apiEndpoints.EMPREENDIMENTOS.DELETE_LANCAMENTO, { params: { id } }),
    });
  };
};
