import { useAppSelector } from '@config/reduxStore';
import { Pagination } from '@models/General';
import { FolhaDashboard } from '@reducers/folhasReducer';
import { getColumnSearchProps, sortAlpha } from '@utils/filterandsort';
import { moneyMask } from '@utils/masks';
import { Table } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';

function TableGeral({ modo }: { modo: 'Adiantamento' | 'Medição' }) {
  const { folhasDashboard, obrasDashboard, fornecedoresDashboard, funcoesDashboard } = useAppSelector(
    (state) => state.folhas
  );
  const [filtersAll, setFiltersAll] = useState<Pagination<any>>(null);
  return (
    <Table
      dataSource={folhasDashboard.filter((c) => c.tipo === modo)}
      size="small"
      rowKey="id"
      pagination={false}
      scroll={{ x: 1000, y: 'calc(100vh - 180px' }}
      onChange={(pagination, filters, sorter) => setFiltersAll({ pagination, filters, sorter })}
      summary={(data) => (
        <Table.Summary fixed>
          <Table.Summary.Row style={{ fontWeight: 'bold' }}>
            <Table.Summary.Cell index={0} colSpan={5} align="right">
              TOTAL
            </Table.Summary.Cell>
            <Table.Summary.Cell index={1} align="right">
              {moneyMask.format(data.reduce((a, b) => a + b.adiantamento, 0))}
            </Table.Summary.Cell>
            {modo === 'Medição' && (
              <Table.Summary.Cell index={2} align="right">
                {moneyMask.format(data.reduce((a, b) => a + b.subtotal, 0))}
              </Table.Summary.Cell>
            )}
            {modo === 'Medição' && (
              <Table.Summary.Cell index={3} align="right">
                {moneyMask.format(data.reduce((a, b) => a + b.total, 0))}
              </Table.Summary.Cell>
            )}
          </Table.Summary.Row>
        </Table.Summary>
      )}
    >
      <Table.Column
        title="Ordem ID"
        dataIndex="cod"
        width={100}
        render={(v, row) => (
          <Link to={`/Folhas/${row.id}`} target="_blank">
            {v}
          </Link>
        )}
        sorter={(a, b) => sortAlpha(a.cod, b.cod)}
        {...getColumnSearchProps('cod', 'string', 'Ordem ID', filtersAll)}
      />
      <Table.Column
        title="Status"
        dataIndex="situacao"
        width={150}
        sorter={(a, b) => sortAlpha(a.situacao, b.situacao)}
        {...getColumnSearchProps('situacao', 'customselect', 'Status', filtersAll, [
          { label: 'Rascunho', value: 'Rascunho' },
          { label: 'Cancelada', value: 'Cancelada' },
          { label: 'Finalizada', value: 'Finalizada' },
          { label: 'Pendente de Medição', value: 'Pendente de Medição' },
          { label: 'Pendente de Pagamento', value: 'Pendente de pagamento' },
          { label: 'Pendente de Aprovação', value: 'Pendente de Aprovação' },
        ])}
      />
      <Table.Column
        title="Obra"
        dataIndex={['orcamento', 'descricao']}
        ellipsis
        sorter={(a, b) => sortAlpha(a.orcamento?.descricao, b.orcamento?.descricao)}
        {...getColumnSearchProps(
          ['orcamento', 'descricao'],
          'customselect',
          'Obra',
          filtersAll,
          obrasDashboard.map((c) => ({ text: c, value: c }))
        )}
      />
      <Table.Column
        title="Fornecedor"
        dataIndex={['fornecedor', 'nome']}
        width={200}
        sorter={(a, b) => sortAlpha(a.fornecedor?.nome, b.fornecedor?.nome)}
        {...getColumnSearchProps(
          ['fornecedor', 'nome'],
          'customselect',
          'Fornecedor',
          filtersAll,
          fornecedoresDashboard.map((c) => ({ text: c.nome, value: c.nome }))
        )}
      />
      <Table.Column
        title="Função"
        dataIndex={['fornecedor', 'funcao']}
        width={120}
        sorter={(a, b) => sortAlpha(a.fornecedor?.funcao, b.fornecedor?.funcao)}
        {...getColumnSearchProps(
          ['fornecedor', 'funcao'],
          'customselect',
          'Função',
          filtersAll,
          funcoesDashboard.map((c) => ({ text: c, value: c }))
        )}
      />
      <Table.Column
        title="Adiantamento"
        dataIndex="adiantamento"
        render={(v) => moneyMask.format(v)}
        align="right"
        sorter={(a, b: FolhaDashboard) => a.adiantamento - b.adiantamento}
      />
      {modo === 'Medição' && (
        <Table.Column
          title="Medição"
          dataIndex="subtotal"
          render={(v) => moneyMask.format(v)}
          align="right"
          sorter={(a, b: FolhaDashboard) => a.subtotal - b.subtotal}
        />
      )}
      {modo === 'Medição' && (
        <Table.Column
          title="Total"
          dataIndex="total"
          render={(v) => moneyMask.format(v)}
          align="right"
          sorter={(a, b: FolhaDashboard) => a.total - b.total}
        />
      )}
    </Table>
  );
}

export default TableGeral;
