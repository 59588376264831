import { ExtendedAction } from '@config/reduxStore';
import { Auditoria, AuditoriaView } from '@models/Auditoria';
import { Orcamento } from '@models/Orcamento';
import { User } from '@models/User';
import { createReducer } from '@reduxjs/toolkit';
import { notification } from 'antd';
import {
  deleteAuditoria,
  getAuditoria,
  getAuditorias,
  getAuditoriasObra,
  getAuditoriasPendentes,
  getMestres,
  getObras,
  getResponsaveis,
  responderAuditoria,
  salvarAuditoria,
  saveFiles,
} from '@actions/auditoriasAction';

interface AuditoriasReducer {
  auditorias: Auditoria[];
  loadingAuditorias: boolean;
  auditoria: AuditoriaView;
  loadingAuditoria: boolean;
  savingAuditoria: boolean;
  responsaveis: User[];
  loadingResponsaveis: boolean;
  mestres: User[];
  loadingMestres: boolean;
  obras: Orcamento[];
  loadingObras: boolean;
  savingFiles: boolean;
  auditoriasPendentes: number;
}
var initialState: AuditoriasReducer = {
  auditorias: [],
  loadingAuditorias: false,
  auditoria: null,
  loadingAuditoria: false,
  savingAuditoria: false,
  responsaveis: [],
  loadingResponsaveis: false,
  mestres: [],
  loadingMestres: false,
  obras: [],
  loadingObras: false,
  savingFiles: false,
  auditoriasPendentes: 0,
};

export default createReducer(initialState, (builder) => {
  builder.addCase(getAuditoriasPendentes.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    auditoriasPendentes: action.payload.data,
  }));
  builder.addCase(responderAuditoria.pending, (state) => ({
    ...state,
    savingAuditoria: true,
    auditorias: [],
  }));
  builder.addCase(responderAuditoria.rejected, (state) => ({
    ...state,
    savingAuditoria: false,
  }));
  builder.addCase(responderAuditoria.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    savingAuditoria: false,
    auditoriasPendentes: state.auditoriasPendentes - 1,
    auditoria: { ...state.auditoria, ...action.payload.data },
  }));
  builder.addCase(getAuditoriasObra.pending, (state) => ({
    ...state,
    loadingAuditorias: true,
    auditorias: [],
  }));
  builder.addCase(getAuditoriasObra.rejected, (state) => ({
    ...state,
    loadingAuditorias: false,
  }));
  builder.addCase(getAuditoriasObra.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    loadingAuditorias: false,
    auditorias: action.payload.data.map((c: any) => ({ ...c, ...JSON.parse(c.values) })),
  }));
  builder.addCase(getAuditorias.pending, (state) => ({
    ...state,
    loadingAuditorias: true,
    auditoria: null,
  }));
  builder.addCase(getAuditorias.rejected, (state) => ({
    ...state,
    loadingAuditorias: false,
  }));
  builder.addCase(getAuditorias.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    loadingAuditorias: false,
    auditorias: action.payload.data.map((c: any) => ({ ...c, ...JSON.parse(c.values) })),
  }));
  builder.addCase(salvarAuditoria.pending, (state) => ({
    ...state,
    savingAuditoria: true,
  }));
  builder.addCase(salvarAuditoria.rejected, (state) => {
    notification.error({ message: 'Erro ao salvar auditoria!' });
    return {
      ...state,
      savingAuditoria: false,
    };
  });
  builder.addCase(salvarAuditoria.fulfilled, (state, action: ExtendedAction) => {
    notification.success({ message: 'Auditoria salva com sucesso!' });
    return {
      ...state,
      savingAuditoria: false,
      auditoria: action.payload.data.entity,
    };
  });
  builder.addCase(getResponsaveis.pending, (state) => ({
    ...state,
    loadingResponsaveis: true,
  }));
  builder.addCase(getResponsaveis.rejected, (state) => ({
    ...state,
    loadingResponsaveis: false,
  }));
  builder.addCase(getResponsaveis.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    loadingResponsaveis: false,
    responsaveis: action.payload.data,
  }));
  builder.addCase(getMestres.pending, (state) => ({
    ...state,
    loadingMestres: true,
  }));
  builder.addCase(getMestres.rejected, (state) => ({
    ...state,
    loadingMestres: false,
  }));
  builder.addCase(getMestres.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    loadingMestres: false,
    mestres: action.payload.data,
  }));
  builder.addCase(getObras.pending, (state) => ({
    ...state,
    loadingObras: true,
  }));
  builder.addCase(getObras.rejected, (state) => ({
    ...state,
    loadingObras: false,
  }));
  builder.addCase(getObras.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    loadingObras: false,
    obras: action.payload.data,
  }));
  builder.addCase(getAuditoria.pending, (state) => ({
    ...state,
    loadingAuditoria: true,
    auditoria: null,
  }));
  builder.addCase(getAuditoria.rejected, (state) => ({
    ...state,
    loadingAuditoria: false,
    auditoria: null,
  }));
  builder.addCase(getAuditoria.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    loadingAuditoria: false,
    auditoria: { ...JSON.parse(action.payload.data.values), ...action.payload.data },
  }));
  builder.addCase(saveFiles.pending, (state) => ({
    ...state,
    savingFiles: true,
  }));
  builder.addCase(saveFiles.rejected, (state) => ({
    ...state,
    savingFiles: false,
  }));
  builder.addCase(saveFiles.fulfilled, (state, action: ExtendedAction) => {
    return {
      ...state,
      savingFiles: false,
      auditoria: { ...state.auditoria, fotos: action.payload.data },
    };
  });
  builder.addCase(deleteAuditoria.pending, (state) => ({
    ...state,
    loadingAuditorias: true,
  }));
  builder.addCase(deleteAuditoria.rejected, (state) => ({
    ...state,
    loadingAuditorias: false,
  }));
  builder.addCase(deleteAuditoria.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    loadingAuditorias: false,
    auditorias: state.auditorias.filter((c) => c.id !== action.payload.data),
  }));
});
