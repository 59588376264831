import { GetOrdemItens, ViewItensModal } from '@actions/novaOrdemActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { RazaoEstouro } from '@models/OrdemItem';
import { doubleMask, moneyMask, moneyNegMask } from '@utils/masks';
import { Button, Modal, Skeleton, Typography } from 'antd';
import { useEffect } from 'react';

function OrdemItensModal() {
  const { ordemItensModal, loadingOrdemItens, ordemItens } = useAppSelector((state) => state.novaOrdem);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (ordemItensModal.visible) {
      dispatch(GetOrdemItens(ordemItensModal.ordemID));
    }
  }, [ordemItensModal.visible]);
  return (
    <Modal
      width="90%"
      open={ordemItensModal.visible}
      title={`Itens - Ordem Nº ${ordemItensModal.cod}`}
      onCancel={() => dispatch(ViewItensModal({ ordemID: null, cod: '', visible: false }))}
      footer={[
        <Button key="1" onClick={() => dispatch(ViewItensModal({ ordemID: null, cod: '', visible: false }))}>
          Fechar
        </Button>,
      ]}
    >
      <Skeleton loading={loadingOrdemItens} active>
        {ordemItens?.items.length > 0 && (
          <div className="ordem" style={{ overflowX: 'auto' }}>
            <table style={{ width: '100%', fontSize: 10 }}>
              <tbody>
                <tr>
                  <th colSpan={12}>Itens</th>
                </tr>
                <tr>
                  <th style={{ width: 30 }}></th>
                  <th>Orçamento</th>
                  <th>Categoria</th>
                  <th>Grupo</th>
                  <th>Item</th>
                  <th>Unidade</th>
                  <th>V. Unitário</th>
                  <th>Quantidade</th>
                  <th>Desconto</th>
                  <th>Reembolso</th>
                  <th>V. Total</th>
                  <th>Razão Estouro</th>
                </tr>
                {ordemItens.items.map((c, index: number) => (
                  <tr key={index} className={c.estourado ? 'naoorcado' : null}>
                    <td>{index + 1}</td>
                    <td>{c.orcamento as string}</td>
                    <td>{c.categoria as string}</td>
                    <td>{c.grupo as string}</td>
                    <td>
                      <p style={{ border: 0 }}>{c.item as string}</p>
                      <p style={{ border: 0, fontSize: 9, fontStyle: 'italic' }}>{c.descricao}</p>
                    </td>
                    <td>{c.unidade}</td>
                    <td>{moneyMask.format(c.valorUnitario)}</td>
                    <td>{doubleMask.format(c.quantidade)}</td>
                    <td>{moneyMask.format(c.desconto)}</td>
                    <td>{moneyMask.format(c.valorReembolso)}</td>
                    <td>{moneyNegMask.format(c.valorTotal)}</td>
                    <td>
                      {c.razaoEstouro === RazaoEstouro.NaoOrcado
                        ? 'Não orçado'
                        : c.razaoEstouro === RazaoEstouro.Valor
                        ? 'Valor estourado'
                        : c.razaoEstouro === RazaoEstouro.Quantidade
                        ? 'Quantidade estourada'
                        : c.razaoEstouro === RazaoEstouro.ValoreQuantidade
                        ? 'Valor e quantidade estourados'
                        : c.razaoEstouro === RazaoEstouro.Bloqueado
                        ? 'Grupo bloqueado'
                        : ''}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td rowSpan={5} colSpan={8}>
                    {ordemItens.aprovacao && (
                      <div style={{ border: 0 }}>
                        Justificativa:
                        {
                          <Typography.Paragraph style={{ border: 0 }} type="danger">
                            {ordemItens.justificativa}
                          </Typography.Paragraph>
                        }
                      </div>
                    )}
                    Observação:
                    {
                      <Typography.Paragraph style={{ border: 0 }} type="danger">
                        {ordemItens.observacao}
                      </Typography.Paragraph>
                    }
                  </td>
                </tr>
                <tr>
                  <th>Subtotal</th>
                  <td>{moneyMask.format(ordemItens.subtotal)}</td>
                </tr>
                <tr>
                  <th>Desconto</th>
                  <td>{moneyMask.format(ordemItens.desconto)}</td>
                </tr>
                <tr>
                  <th>Adiantamento</th>
                  <td>{moneyMask.format(ordemItens.adiantamento)}</td>
                </tr>
                <tr>
                  <th>Total</th>
                  <td>{moneyMask.format(ordemItens.total)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </Skeleton>
    </Modal>
  );
}

export default OrdemItensModal;
