import logo from '../../../../../assets/img/main.png';
import moment from 'moment';
import { moneyMask, doubleMask, moneyNegMask } from '@utils/masks';
import imgpendente from '../../../../../assets/img/ordempendente.png';
import imgcancelada from '../../../../../assets/img/ordemcancelada.png';
import imgrascunho from '../../../../../assets/img/ordemrascunho.png';
import imgsubstituida from '../../../../../assets/img/ordemsubstituida.png';
import { Link } from 'react-router-dom';
import '../../../../../assets/scss/Ordem.scss';
import { IOrdemForm } from '@models/Ordem';
import { Categoria } from '@models/Categoria';
import { isValidType } from '@utils/testType';
import { Orcamento } from '@models/Orcamento';
import { Casa } from '@models/Casa';
import { Item } from '@models/Item';
import { OrdemStatus } from '@constants/ordemStatus';
import OrdemStatusTag from '../Shared/OrdemStatusTag';
import LoadingText from '@components/LoadingText';

interface OrdemPrintProps {
  ordem: IOrdemForm;
  categorias: Categoria[];
  customLogo: string;
}
function OrdemPrint2(props: OrdemPrintProps) {
  const { ordem, categorias, customLogo } = props;
  if (ordem) {
    return (
      <div
        className="ordemprint"
        style={{
          backgroundImage:
            ordem.situacao === OrdemStatus.PendenteAprovacao
              ? `url(${imgpendente})`
              : ordem.situacao === OrdemStatus.Cancelada
              ? `url(${imgcancelada})`
              : ordem.situacao === OrdemStatus.Substituida
              ? `url(${imgsubstituida})`
              : ordem.situacao === OrdemStatus.Rascunho
              ? `url(${imgrascunho})`
              : null,
        }}
      >
        <table>
          <thead>
            <tr>
              <td>
                <div className="header">
                  <img className="header-column" src={customLogo || logo} height={100} alt="logo" />
                  <h2 className="header-column">Ordem de Compra</h2>
                  <div className="header-data header-column">
                    <div className="header-item">
                      <div className="header-item-label">Data</div>
                      <div className="header-item-value">{moment(ordem.dataCriacao).format('DD/MM/YYYY')}</div>
                    </div>
                    <div className="header-item">
                      <div className="header-item-label">Descrição</div>
                      <div className="header-item-value">{ordem.descricao}</div>
                    </div>
                    <div className="header-item">
                      <div className="header-item-label">Nº Ordem</div>
                      <div className="header-item-value">
                        {ordem.cod}
                        {ordem.replacedBy ? (
                          <span style={{ border: 0, color: 'red' }}>
                            (Substituída por:
                            <Link style={{ border: 0 }} to={`/PrintOrdem/${ordem.replacedBy?.replacedByID}`}>
                              {ordem.replacedBy?.replacedByCOD}
                            </Link>
                            )
                          </span>
                        ) : ordem.replacedFrom ? (
                          <span style={{ border: 0, color: 'red' }}>
                            (Em substituição a:
                            <Link style={{ border: 0 }} to={`/PrintOrdem/${ordem.replacedFrom?.replacedFromID}`}>
                              {ordem.replacedFrom?.replacedFromCOD}
                            </Link>
                            )
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="header-item">
                      <div className="header-item-label">Situação</div>
                      <div className="header-item-value" style={{ fontWeight: 'bold' }}>
                        <OrdemStatusTag status={ordem.situacao} />
                      </div>
                    </div>
                    <div className="header-item">
                      <div className="header-item-label">Solicitante</div>
                      <div className="header-item-value">{ordem.comprador}</div>
                    </div>
                    <div className="header-item">
                      <div className="header-item-label">Orcamento</div>
                      <div className="header-item-value">
                        {ordem.distribuido
                          ? 'Rateado'
                          : isValidType<Orcamento>(ordem.orcamento) && ordem.orcamento?.descricao}
                      </div>
                    </div>
                    <div className="header-item">
                      <div className="header-item-label">Endereço</div>
                      <div className="header-item-value">
                        {ordem.distribuido
                          ? 'Verificar observações'
                          : isValidType<Orcamento>(ordem?.orcamento) &&
                            isValidType<Casa>(ordem?.orcamento?.casa) &&
                            ordem.orcamento?.casa &&
                            `${ordem.orcamento?.casa?.endereco} ${ordem.orcamento?.casa?.numero}, ${ordem.orcamento?.casa?.complemento}, Quadra ${ordem.orcamento?.casa?.quadra} Lote ${ordem.orcamento?.casa?.lote}`}
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="geral">
                  <div className="fornecedor">
                    <div className="fornecedor-title">Dados do Fornecedor</div>
                    <div className="fornecedor-item" style={{ height: 40 }}>
                      <div className="fornecedor-item-label">Nome</div>
                      <div className="fornecedor-item-value">{ordem.fornecedor?.nome}</div>
                    </div>
                    <div className="fornecedor-item">
                      <div className="fornecedor-item-label">CPF/CNPJ</div>
                      <div className="fornecedor-item-value">{ordem.fornecedor?.cpF_CNPJ}</div>
                    </div>
                    <div className="fornecedor-item" style={{ height: 40 }}>
                      <div className="fornecedor-item-label">Endereço</div>
                      <div className="fornecedor-item-value">{ordem.fornecedor?.endereco}</div>
                    </div>
                    <div className="fornecedor-item">
                      <div className="fornecedor-item-label">Telefone</div>
                      <div className="fornecedor-item-value">{ordem.fornecedor?.telefone}</div>
                    </div>
                    <div className="fornecedor-item">
                      <div className="fornecedor-item-label">Estado</div>
                      <div className="fornecedor-item-value">{ordem.fornecedor?.estado}</div>
                    </div>
                    <div className="fornecedor-item" style={{ height: 40 }}>
                      <div className="fornecedor-item-label">Cidade</div>
                      <div className="fornecedor-item-value">{ordem.fornecedor?.cidade}</div>
                    </div>
                    <div className="fornecedor-item">
                      <div className="fornecedor-item-label">CEP</div>
                      <div className="fornecedor-item-value">{ordem.fornecedor?.cep}</div>
                    </div>
                    <div className="fornecedor-item">
                      <div className="fornecedor-item-label">E-mail</div>
                      <div className="fornecedor-item-value">{ordem.fornecedor?.email}</div>
                    </div>
                  </div>
                  <div className="pagamento">
                    <div className="pagamento-title">Informações para Pagamento</div>
                    <div className="pagamento-item" style={{ height: 40 }}>
                      <div className="pagamento-item-label">Banco</div>
                      <div className="pagamento-item-value">{ordem.fornecedor?.banco}</div>
                    </div>
                    <div className="pagamento-item">
                      <div className="pagamento-item-label">Agência</div>
                      <div className="pagamento-item-value">{ordem.fornecedor?.agencia}</div>
                    </div>
                    <div className="pagamento-item" style={{ height: 40 }}>
                      <div className="pagamento-item-label">Conta</div>
                      <div className="pagamento-item-value">{ordem.fornecedor?.contaBancaria}</div>
                    </div>
                    <div className="pagamento-item">
                      <div className="pagamento-item-label">Operação</div>
                      <div className="pagamento-item-value">{ordem.fornecedor?.operacao}</div>
                    </div>
                    <div className="pagamento-item">
                      <div className="pagamento-item-label">CPF/CNPJ</div>
                      <div className="pagamento-item-value">{ordem.fornecedor?.cpF_CNPJ_Conta}</div>
                    </div>
                    <div className="pagamento-item" style={{ height: 40 }}>
                      <div className="pagamento-item-label">Titular</div>
                      <div className="pagamento-item-value">{ordem.fornecedor?.nomeConta}</div>
                    </div>
                    <div className="pagamento-item">
                      <div className="pagamento-item-label">PIX</div>
                      <div className="pagamento-item-value">{ordem.fornecedor?.pix}</div>
                    </div>
                    <div className="pagamento-item">
                      <div className="pagamento-item-label">Forma de Pagamento</div>
                      <div className="pagamento-item-value">
                        {ordem.formaPagamento === 'naopagar'
                          ? 'NÃO PAGAR'
                          : ordem.formaPagamento
                          ? ordem.formaPagamento.toUpperCase()
                          : ordem.formaPagamento}
                      </div>
                    </div>
                  </div>
                  <div className="faturamento">
                    <div className="faturamento-title">Dados para Faturamento</div>
                    <div className="faturamento-item" style={{ height: 40 }}>
                      <div className="faturamento-item-label">Nome</div>
                      <div className="faturamento-item-value">{ordem.empresaFaturamento?.nome}</div>
                    </div>
                    <div className="faturamento-item">
                      <div className="faturamento-item-label">CPF/CNPJ</div>
                      <div className="faturamento-item-value">{ordem.empresaFaturamento?.cpF_CNPJ}</div>
                    </div>
                    <div className="faturamento-item" style={{ height: 40 }}>
                      <div className="faturamento-item-label">Endereço</div>
                      <div className="faturamento-item-value">{ordem.empresaFaturamento?.endereco}</div>
                    </div>
                    <div className="faturamento-item">
                      <div className="faturamento-item-label">Telefone</div>
                      <div className="faturamento-item-value">{ordem.empresaFaturamento?.telefone}</div>
                    </div>
                    <div className="faturamento-item">
                      <div className="faturamento-item-label">Estado</div>
                      <div className="faturamento-item-value">{ordem.empresaFaturamento?.estado}</div>
                    </div>
                    <div className="faturamento-item" style={{ height: 40 }}>
                      <div className="faturamento-item-label">Cidade</div>
                      <div className="faturamento-item-value">{ordem.empresaFaturamento?.cidade}</div>
                    </div>
                    <div className="faturamento-item">
                      <div className="faturamento-item-label">CEP</div>
                      <div className="faturamento-item-value">{ordem.empresaFaturamento?.cep}</div>
                    </div>
                    <div className="faturamento-item">
                      <div className="faturamento-item-label">E-mail</div>
                      <div className="faturamento-item-value">{ordem.empresaFaturamento?.email}</div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="itens">
                  <div className="itens-title">Itens</div>
                  <table style={{ width: '100%' }}>
                    <thead>
                      <tr>
                        <th>Item</th>
                        <th style={{ maxWidth: '200px' }}>Obra</th>
                        <th>Categoria</th>
                        <th>Item</th>
                        <th>Unidade</th>
                        <th>V. Unitário</th>
                        <th>Quantidade</th>
                        <th>Desconto</th>
                        <th>V. Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ordem.ordensItens?.map((oi, index) => (
                        <tr key={index}>
                          <td style={{ textAlign: 'center' }}>{index + 1}</td>
                          <td style={{ maxWidth: '200px' }}>
                            {isValidType<Orcamento>(oi.orcamento) && oi.orcamento?.descricao}
                          </td>
                          <td>{isValidType<Categoria>(oi.categoria) && oi.categoria?.descricao}</td>
                          <td>
                            {!oi.descricao && isValidType<Item>(oi.item) && oi.item?.descricao}
                            {oi.descricao && oi.descricao}
                          </td>
                          <td>{isValidType<Item>(oi.item) && oi.item?.unidade}</td>
                          <td className="value">{moneyMask.format(oi.valorUnitario)}</td>
                          <td className="value">{doubleMask.format(oi.quantidade)}</td>
                          <td className="value">{moneyMask.format(oi.desconto)}</td>
                          <td className="value">{moneyNegMask.format(oi.valorTotal)}</td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan={7} rowSpan={4} />
                        <td className="ordem-value-label">Subtotal</td>
                        <td className="value">{moneyMask.format(ordem.subtotal)}</td>
                      </tr>
                      <tr>
                        <td className="ordem-value-label">Desconto</td>
                        <td className="value">{moneyMask.format(ordem.desconto)}</td>
                      </tr>
                      <tr>
                        <td className="ordem-value-label">Ajuste NF</td>
                        <td className="value">{moneyMask.format(ordem.valorAjusteNF)}</td>
                      </tr>
                      <tr>
                        <td className="ordem-value-label">Total</td>
                        <td className="value" style={{ fontWeight: 'bold' }}>
                          {moneyMask.format(ordem.total)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="section itens">
                  <div className="subtitle">Pagamento e Entrega</div>
                  <table style={{ width: '100%' }}>
                    <thead>
                      <tr>
                        <th>Forma de Pagamento</th>
                        <th>Prazo de Pagamento</th>
                        <th>Prazo de Entrega</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style={{ textAlign: 'center' }}>
                        <td>
                          {ordem.formaPagamento === 'naopagar'
                            ? 'NÃO PAGAR'
                            : ordem.formaPagamento
                            ? ordem.formaPagamento.toUpperCase()
                            : ordem.formaPagamento}
                        </td>
                        <td>
                          {moment(ordem.prazoPagamento).isValid()
                            ? moment(ordem.prazoPagamento).format('DD/MM/YYYY')
                            : 'Data não definida'}
                        </td>
                        <td>
                          {moment(ordem.prazoEntrega).isValid()
                            ? moment(ordem.prazoEntrega).format('DD/MM/YYYY')
                            : 'Data não definida'}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
            {ordem.observacao && (
              <tr>
                <td>
                  <div className="section itens">
                    <div className="subtitle">Observações</div>
                    <div style={{ padding: 5 }}>{ordem.observacao}</div>
                  </div>
                </td>
              </tr>
            )}
            <tr>
              <td>
                <div className="section">
                  <div className="subtitle">Requisitos para Recebimento</div>
                  <div style={{ padding: 5 }}>
                    <p>
                      1. PARA UMA MELHOR RASTREABILIDADE, O NÚMERO DA ORDEM DE COMPRA DEVERÁ OBRIGATÓRIAMENTE SER
                      ESPECIFICADO NA NOTA FISCAL, E O MATERIAL SÓ SERÁ RECEBIDO SE O MESMO FOR ATENDIDO;
                    </p>
                    <p>2. ESCLARECER SE A DESCARGA ESTÁ OU NÃO INCLUSA NO FORNECIMENTO;</p>
                    <p>3. O MATERIAL SERÁ INSPECIONADO DURANTE A ENTREGA.</p>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="section itens">
                  <div className="subtitle">Resumo Categorias</div>
                  <table style={{ width: '100%' }}>
                    <tbody>
                      <tr>
                        <th>Obra</th>
                        <th>Categoria</th>
                        <th>Valor</th>
                      </tr>
                      {categorias.map((o) =>
                        o.categorias.map((c, index) => (
                          <tr key={index}>
                            {index === 0 && (
                              <td
                                style={{
                                  maxWidth: 200,
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                }}
                                rowSpan={index === 0 ? o.categorias.length : 0}
                              >
                                {o.orcamento}
                              </td>
                            )}
                            <td>{c.categoria}</td>
                            <td className="value">{c.total}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  } else return <LoadingText text="Carregando" />;
}

export default OrdemPrint2;
