import {
  CancelarFolha,
  GetFolhasList,
  ViewCancelForm,
  GetDashboardFolhas,
  GetFolha,
  GetFolhasPendentes,
  AprovarFolha,
} from '@actions/folhasActions';
import { ExtendedAction } from '@config/reduxStore';
import { DashboardFolha, FolhaList } from '@models/Folha';
import { Ordem } from '@models/Ordem';
import { createReducer } from '@reduxjs/toolkit';
import { notification } from 'antd';

export interface FornecedorDashboardFolha {
  nome: string;
  funcao: string;
}

interface FolhasReducer {
  folhasList: FolhaList[];
  loadingFolhasList: boolean;
  cancelingFolha: boolean;
  folhasPendentes: FolhaList[];
  folhasPendentesNumber: number;
  folha: Ordem;
  loadingFolha: boolean;
  cancelForm: {
    id: number | null;
    visible: boolean;
  };
  dashboard: DashboardFolha[];
  loadingDashboard: boolean;
  fornecedoresDashboard: FornecedorDashboardFolha[];
  funcoesDashboard: string[];
  obrasDashboard: string[];
  dashboardPorFornecedor: DashboardFolhaPorFornecedor[];
  dashboardPorObra: DashboardFolhaPorObra[];
  folhasDashboard: FolhaDashboard[];
  savingFiles: boolean;
  loadingFiles: boolean;
  compilando: boolean;
  selectedFiles: number[];
  loadingPendentes: boolean;
}
export interface FolhaDashboard extends Omit<DashboardFolha, 'tipo'> {
  tipo: 'Adiantamento' | 'Medição';
}
export interface DashboardFolhaPorObra {
  id: string;
  obra: string;
  nivel: number;
  fornecedores: number;
  adiantamento: number;
  subtotal: number;
  total: number;
  children?: DashboardFolhaPorObra[];
}
export interface DashboardFolhaPorFornecedor {
  id: string;
  fornecedor: string;
  funcao: string;
  nivel: number;
  obras: number;
  adiantamento: number;
  subtotal: number;
  total: number;
  children?: DashboardFolhaPorFornecedor[];
}
const initialState: FolhasReducer = {
  folhasList: [],
  loadingFolhasList: false,
  cancelingFolha: false,
  folhasPendentes: [],
  folhasPendentesNumber: 0,
  cancelForm: {
    id: null,
    visible: false,
  },
  dashboard: [],
  dashboardPorFornecedor: [],
  loadingDashboard: false,
  fornecedoresDashboard: [],
  funcoesDashboard: [],
  obrasDashboard: [],
  folhasDashboard: [],
  dashboardPorObra: [],
  folha: null,
  loadingFolha: false,
  savingFiles: false,
  loadingFiles: false,
  compilando: false,
  selectedFiles: [],
  loadingPendentes: false,
};

export default createReducer(initialState, (builder) => {
  builder.addCase(AprovarFolha.pending, (state) => {
    return { ...state, loadingPendentes: true, folha: null };
  });
  builder.addCase(AprovarFolha.rejected, (state, action: ExtendedAction) => {
    notification.error({ message: action.payload?.response?.data?.Message });
    return { ...state, loadingPendentes: false };
  });
  builder.addCase(AprovarFolha.fulfilled, (state, action: ExtendedAction) => {
    notification.success({ message: 'Folha aprovada com sucesso!' });
    return {
      ...state,
      loadingPendentes: false,
      folhasPendentes: state.folhasPendentes.filter((c) => c.id !== action.payload.data),
    };
  });
  builder.addCase(GetFolha.pending, (state) => {
    return { ...state, loadingFolha: true, folha: null };
  });
  builder.addCase(GetFolha.rejected, (state) => {
    notification.error({ message: 'Erro ao carregar folhas' });
    return { ...state, loadingFolha: false };
  });
  builder.addCase(GetFolha.fulfilled, (state, action: ExtendedAction) => {
    return { ...state, loadingFolha: false, folha: action.payload.data };
  });
  builder.addCase(GetFolhasList.pending, (state) => {
    return { ...state, loadingFolhasList: true };
  });
  builder.addCase(GetFolhasList.rejected, (state) => {
    notification.error({ message: 'Erro ao carregar folhas' });
    return { ...state, loadingFolhasList: false };
  });
  builder.addCase(GetFolhasList.fulfilled, (state, action: ExtendedAction) => {
    return { ...state, loadingFolhasList: false, folhasList: action.payload.data };
  });
  builder.addCase(CancelarFolha.pending, (state) => {
    return { ...state, cancelingFolha: true };
  });
  builder.addCase(CancelarFolha.rejected, (state) => {
    notification.error({ message: 'Erro ao cancelar folha' });
    return { ...state, cancelingFolha: false };
  });
  builder.addCase(CancelarFolha.fulfilled, (state, action: ExtendedAction) => {
    return {
      ...state,
      cancelingFolha: false,
      folhasPendentes: state.folhasPendentes.filter((c) => c.id !== action.meta.arg.id),
      folhasList: state.folhasList.map((c) =>
        c.id === action.meta.arg.id ? { ...c, ...action.meta.arg, cancelada: true, situacao: 'Cancelada' } : c
      ),
    };
  });
  builder.addCase(GetFolhasPendentes.pending, (state) => {
    return { ...state, loadingPendentes: true };
  });
  builder.addCase(GetFolhasPendentes.rejected, (state, action: ExtendedAction) => {
    notification.error({ message: action.payload.response.data.Message });
    return { ...state, loadingPendentes: false };
  });
  builder.addCase(GetFolhasPendentes.fulfilled, (state, action: ExtendedAction) => {
    return {
      ...state,
      loadingPendentes: false,
      folhasPendentes: action.payload.data,
    };
  });
  builder.addCase(ViewCancelForm, (state, action: ExtendedAction) => {
    return {
      ...state,
      cancelForm: {
        id: action.payload.id,
        visible: action.payload.view,
      },
    };
  });
  builder.addCase(GetDashboardFolhas.pending, (state) => {
    return { ...state, loadingDashboard: true };
  });
  builder.addCase(GetDashboardFolhas.rejected, (state) => {
    notification.error({ message: 'Erro ao carregar folhas' });
    return { ...state, loadingDashboard: false };
  });
  builder.addCase(GetDashboardFolhas.fulfilled, (state, action: ExtendedAction) => {
    const data: DashboardFolha[] = action.payload.data;
    const modo = action.payload.modo;
    const fornecedores = [...new Set(data.map((c: DashboardFolha) => c.fornecedor.nome))].map((c) => {
      return {
        nome: c,
        funcao: data.find((d) => d.fornecedor.nome == c)?.fornecedor.funcao,
      };
    });
    const obras = [...new Set(data.map((c) => c.orcamento.descricao))];
    const funcoes = [...new Set(data.map((c) => c.fornecedor.funcao))];
    const folhas = data.map((c) => ({ ...c, tipo: c.tipo === 0 ? 'Adiantamento' : 'Medição' }));
    return {
      ...state,
      loadingDashboard: false,
      dashboard: data,
      fornecedoresDashboard: fornecedores,
      funcoesDashboard: funcoes,
      obrasDashboard: obras,
      folhasDashboard: folhas as FolhaDashboard[],
      dashboardPorFornecedor: fornecedores.map((c) => ({
        id: c.nome,
        fornecedor: c.nome,
        funcao: c.funcao,
        nivel: 1,
        obras: obras.filter((o) =>
          folhas.some((f) => f.fornecedor.nome === c.nome && f.tipo === modo && f.orcamento.descricao === o)
        ).length,
        adiantamento: folhas
          .filter((f) => f.fornecedor.nome === c.nome && f.tipo === modo)
          .reduce((a, b) => a + b.adiantamento, 0),
        subtotal: folhas
          .filter((f) => f.fornecedor.nome === c.nome && f.tipo === modo)
          .reduce((a, b) => a + b.subtotal, 0),
        total: folhas.filter((f) => f.fornecedor.nome === c.nome && f.tipo === modo).reduce((a, b) => a + b.total, 0),
        children: obras
          .filter((o) =>
            folhas.some((f) => f.orcamento.descricao === o && f.fornecedor.nome === c.nome && f.tipo === modo)
          )
          .map((o) => ({
            id: o,
            fornecedor: o,
            nivel: 2,
            funcao: '',
            obras: null,
            adiantamento: folhas
              .filter((f) => f.fornecedor.nome === c.nome && f.orcamento.descricao === o && f.tipo === modo)
              .reduce((a, b) => a + b.adiantamento, 0),
            subtotal: folhas
              .filter((f) => f.fornecedor.nome === c.nome && f.orcamento.descricao === o && f.tipo === modo)
              .reduce((a, b) => a + b.subtotal, 0),
            total: folhas
              .filter((f) => f.fornecedor.nome === c.nome && f.orcamento.descricao === o && f.tipo === modo)
              .reduce((a, b) => a + b.total, 0),
          })),
      })),
      dashboardPorObra: obras.map((c) => ({
        id: c,
        obra: c,
        nivel: 1,
        fornecedores: fornecedores.filter((o) =>
          folhas.some((f) => f.fornecedor.nome === o.nome && f.tipo === modo && f.orcamento.descricao === c)
        ).length,
        adiantamento: folhas
          .filter((f) => f.orcamento.descricao === c && f.tipo === modo)
          .reduce((a, b) => a + b.adiantamento, 0),
        subtotal: folhas
          .filter((f) => f.orcamento.descricao === c && f.tipo === modo)
          .reduce((a, b) => a + b.subtotal, 0),
        total: folhas.filter((f) => f.orcamento.descricao === c && f.tipo === modo).reduce((a, b) => a + b.total, 0),
        children: fornecedores
          .filter((o) =>
            folhas.some((f) => f.orcamento.descricao === c && f.fornecedor.nome === o.nome && f.tipo === modo)
          )
          .map((o) => ({
            id: o.nome,
            obra: o.nome,
            nivel: 2,
            funcao: '',
            fornecedores: null,
            adiantamento: folhas
              .filter((f) => f.fornecedor.nome === o.nome && f.orcamento.descricao === c && f.tipo === modo)
              .reduce((a, b) => a + b.adiantamento, 0),
            subtotal: folhas
              .filter((f) => f.fornecedor.nome === o.nome && f.orcamento.descricao === c && f.tipo === modo)
              .reduce((a, b) => a + b.subtotal, 0),
            total: folhas
              .filter((f) => f.fornecedor.nome === o.nome && f.orcamento.descricao === c && f.tipo === modo)
              .reduce((a, b) => a + b.total, 0),
          })),
      })),
    };
  });
});
