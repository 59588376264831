import { GetFornecedoresList } from '@services/api/api.fornecedor';
import { SelectProps } from 'antd';
import { Select } from 'antd';

export interface SelectSolicitanteProps extends SelectProps {}

function FornecedorSelect({ ...restProps }: SelectSolicitanteProps) {
  const { isLoading, data, isFetching } = GetFornecedoresList();
  return (
    <Select {...restProps} loading={isLoading || isFetching} optionFilterProp="label" showSearch disabled={isLoading}>
      {data?.map((c) => (
        <Select.Option key={c.id} value={c.id} label={c.nome + c.cpF_CNPJ}>
          <div>
            <div>{c.nome}</div>
            <div>{c.cpF_CNPJ}</div>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
}

export default FornecedorSelect;
