import { useEffect, useState } from 'react';
import { Card, Input, Table, Tag } from 'antd';
import { GetClientesObra } from '@actions/dashboardActions';
import { filterobject, getColumnSearchProps } from '@utils/filterandsort';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

function ClientesObra() {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const { clientesobra, loadingclientesobra } = useAppSelector((state) => state.dashboard.clientesobra);
  const [filter, setFilter] = useState('');
  const [filters, setFilters] = useState(null);
  useEffect(() => {
    dispatch(GetClientesObra());
  }, []);
  return (
    <Card size="small" title="Jornada Clientes Construção" style={{ height: 370 }}>
      <div style={{ textAlign: 'right' }}>
        <Input style={{ width: 200 }} onChange={(e) => setFilter(e.target.value)} placeholder="Buscar" allowClear />
      </div>
      <Table
        size="small"
        rowKey="id"
        dataSource={filterobject(clientesobra, filter)}
        loading={loadingclientesobra}
        pagination={false}
        scroll={{ y: 220 }}
        onChange={(paginantion, filters, sorter) => setFilters({ paginantion, filters, sorter })}
      >
        <Table.Column
          title="Cliente"
          width={150}
          dataIndex="cliente"
          {...getColumnSearchProps('cliente', 'string', 'Clientes', filters)}
        />
        <Table.Column
          title="Status"
          dataIndex="status"
          {...getColumnSearchProps('status', 'string', 'Status', filters)}
        />
        <Table.Column
          title="Dias Projeto"
          dataIndex="diasProjeto"
          render={(text) => (
            <Tag color={text >= user.configuracao.metaProjetoArquitetonico ? 'red' : 'green'}>{text}</Tag>
          )}
          sorter={(a, b) => a.diasProjeto - b.diasProjeto}
          {...getColumnSearchProps('diasProjeto', 'string', 'Dias Projeto', filters)}
        />
        <Table.Column
          title="Dias Projeto-Obra"
          dataIndex="diasProjetoObra"
          render={(text) => <Tag color={text > user.configuracao.metaInicioObra ? 'red' : 'green'}>{text}</Tag>}
          sorter={(a, b) => a.diasProjetoObra - b.diasProjetoObra}
          {...getColumnSearchProps('diasProjetoObra', 'string', 'Dias Projeto Obra', filters)}
        />
        <Table.Column
          title="Total Início Obra"
          dataIndex="diasObra"
          render={(text) => <Tag color={text > user.configuracao.metaJornada ? 'red' : 'green'}>{text}</Tag>}
          sorter={(a, b) => a.diasObra - b.diasObra}
          {...getColumnSearchProps('diasObra', 'string', 'Dias Obra', filters)}
        />
      </Table>
    </Card>
  );
}

export default ClientesObra;
