import { useEffect } from 'react';
import { Modal, Form, Input, Button, Typography, Row, Col, Select } from 'antd';
import { FormCliente, AddCliente } from '@actions/correspondenciaActions';
import { cpfMask } from '@utils/masks';
import { Link } from 'react-router-dom';
import { GetModalidades } from '@actions/modalidadesActions';
import { Axios } from '@config/axios';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { ClienteCorrespondencia } from '@models/ClienteCorrespondencia';

function ClienteForm() {
  const dispatch = useAppDispatch();
  const { formview, feedback, savingcliente, feedbackcpf } = useAppSelector((state) => state.correspondencia);
  const { modalidades } = useAppSelector((state) => state.modalidades);
  const [form] = Form.useForm<ClienteCorrespondencia>();
  const submitForm = (values: ClienteCorrespondencia) => {
    dispatch(AddCliente(values));
  };
  useEffect(() => {
    dispatch(GetModalidades());
    form.resetFields();
  }, [form]);
  return (
    <div>
      <Modal
        open={formview}
        onCancel={() => dispatch(FormCliente(null, false))}
        title="Cadastro Clientes"
        footer={[
          <Typography.Text key="1" type="danger">
            {feedbackcpf ? (
              <span>
                CPF já cadastrado. Clique
                <Link to={`/ClientesCorrespondencia/${feedbackcpf}`}> aqui</Link> para ver ficha.
              </span>
            ) : (
              feedback
            )}
          </Typography.Text>,
          <Button onClick={() => dispatch(FormCliente(null, false))} key="2">
            Fechar
          </Button>,
          <Button key="3" type="primary" onClick={() => form.submit()} loading={savingcliente}>
            Salvar
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form} onFinish={submitForm} name="ClienteForm">
          <Form.Item label="Modalidade" name="modalidade" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Select optionFilterProp="children" showSearch>
              {modalidades.map((c, index) => (
                <Select.Option key={index} value={c.id}>
                  {c.descricao}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Row gutter={[8, 8]}>
            <Col flex="0 1 35%">
              <Form.Item
                label="CPF Proponente 1"
                name="clienteCPF1"
                rules={[
                  { required: true, message: 'Campo obrigatório' },
                  {
                    pattern: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
                    message: 'CPF inválido',
                  },
                  {
                    validator: (_, value) => {
                      if (value)
                        if (value.length === 14)
                          return Axios.get('/api/v2/Correspondencia/CheckCPF', {
                            params: { cpf1: value, clienteid: null },
                          });
                      return Promise.resolve();
                    },

                    message: 'CPF já cadastrado',
                  },
                ]}
                normalize={(value) => cpfMask.format(cpfMask.normalize(value))}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col flex="1 1 65%">
              <Form.Item
                label="Nome Proponente 1"
                name="ClienteNome1"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col flex="0 1 35%">
              <Form.Item
                label="CPF Proponente 2"
                name="clienteCPF2"
                rules={[
                  {
                    pattern: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
                    message: 'CPF inválido',
                  },
                  {
                    validator: (_, value) => {
                      if (value)
                        if (value.length === 14)
                          return Axios.get('/api/v2/Correspondencia/CheckCPF', {
                            params: { cpf1: value, clienteid: null },
                          });
                      return Promise.resolve();
                    },

                    message: 'CPF já cadastrado',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value) {
                        if (value.length === 14 && getFieldValue('clienteCPF1') === value) {
                          return Promise.reject('CPFs não podem ser iguais!');
                        }
                      }
                      return Promise.resolve('erro');
                    },
                  }),
                ]}
                normalize={(value) => cpfMask.format(cpfMask.normalize(value))}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col flex="1 1 65%">
              <Form.Item
                label="Nome Proponente 2"
                name="ClienteNome2"
                rules={[
                  {
                    required: false,
                    message: 'Campo obrigatório',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}
export default ClienteForm;
/*
Nomes e CPFs
CPF
Modalidade
Parceiro
Loteamento
Corretor
*/
