import { useEffect, useState } from 'react';
import { Table, Button, Popconfirm } from 'antd';
import { GetMateriaisServer, FormMaterial, DeleteMaterial } from '@actions/materiaisActions';
import MaterialForm from './MaterialForm';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { getColumnSearchPropsServer, sortAlpha } from '@utils/filterandsort';
import { SetPageHeader } from '@actions/userActions';
import { Permission } from '@hooks//usePermissions';
import { moneyMask } from '@utils/masks';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { ColumnsType } from 'antd/lib/table';
import { Item } from '@models/Item';

function MateriaisView() {
  const dispatch = useAppDispatch();
  const { materiais, total, loadingmateriais } = useAppSelector((state) => state.materiais);
  const [filters, setFilters] = useState<any>({
    pagination: { pageSize: 10, current: 1 },
    filters: {
      id: [],
      descricao: [],
      tipo: [],
      unidade: [],
      valor: [],
      minimo: [],
      ultimo: [],
    },
    sorter: { field: 'id', order: 'descend' },
  });
  useEffect(() => {
    dispatch(GetMateriaisServer(filters));
    dispatch(
      SetPageHeader({
        title: 'Engenharia',
        backbutton: true,
        extras: [
          <Permission key="1" permission="material.add">
            <Button onClick={() => dispatch(FormMaterial(null, true))} type="primary">
              Novo Material
            </Button>
          </Permission>,
        ],
        subtitle: 'Materiais e Serviços',
        menu: 'materiaisservicos',
      })
    );
  }, []);

  const columns: ColumnsType<Item> = [
    {
      width: 80,
      fixed: 'left',
      render: (_, row) => (
        <div>
          <Button icon={<EditOutlined />} onClick={() => dispatch(FormMaterial(row.id, true))} size="small" />
          <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(DeleteMaterial(row.id))}>
            <Button icon={<DeleteOutlined />} size="small" />
          </Popconfirm>
        </div>
      ),
    },
    {
      width: '50%',
      title: 'Descrição',
      dataIndex: 'descricao',
      sorter: (a, b) => sortAlpha(a.descricao, b.descricao),
      ...getColumnSearchPropsServer('descricao', 'string', 'Descrição', filters),
    },
    {
      title: 'Tipo',
      dataIndex: 'tipo',
      sorter: true,
      ...getColumnSearchPropsServer('tipo', 'customselect', 'Tipo', filters, [
        { text: 'Material', value: 0 },
        { text: 'Serviço', value: 1 },
      ]),
      render: (text) => (text === 0 ? 'Material' : 'Serviço'),
    },
    {
      title: 'Unidade',
      dataIndex: 'unidade',
      sorter: (a, b) => sortAlpha(a.unidade, b.unidade),
    },
    {
      align: 'right',
      title: 'Valor Unitário',
      dataIndex: 'valorUnitario',
      sorter: (a: any, b: any) => a.valorUnitario - b.valorUnitario,
      render: (text) => moneyMask.format(text),
    },
  ];

  return (
    <div>
      <MaterialForm />
      <Table
        size="small"
        rowKey="id"
        onChange={(pagination, filters, sorter: any) => {
          let f = {
            ...filters,
            id: filters.id || [],
            descricao: filters.descricao || [],
            tipo: filters.tipo || [],
            unidade: filters.unidade || [],
            valor: filters.valor || [],
            minimo: filters.minimo || [],
            ultimo: filters.ultimo || [],
          };
          setFilters({ pagination, filters: f, sorter });
          dispatch(
            GetMateriaisServer({
              pagination,
              filters: f,
              sorter: {
                ...sorter,
                field: sorter.field || 'id',
                order: sorter.order || 'descend',
              },
            })
          );
        }}
        loading={loadingmateriais}
        columns={columns}
        dataSource={materiais}
        scroll={{ y: 'calc(100vh - 300px)', x: 400 }}
        pagination={{
          position: ['bottomRight'],
          size: 'small',
          showSizeChanger: true,
          pageSizeOptions: [10, 25, 50, 100],
          total: total,
          showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
        }}
      />
    </div>
  );
}
export default MateriaisView;
