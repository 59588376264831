import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Table,
  Input,
  List,
  Button,
  Tooltip,
  Modal,
  Form,
  DatePicker,
  Divider,
  Typography,
  Popconfirm,
  Radio,
  Tag,
} from 'antd';
import { HistoryOutlined, ProfileOutlined, FrownOutlined, SearchOutlined } from '@ant-design/icons';
import { GetQuadro, AddHistorico, Desistir, GetCliente } from '@actions/correspondenciaActions';
import { GetModalidades } from '@actions/modalidadesActions';
import { filterobject, getColumnSearchProps, sortAlpha } from '@utils/filterandsort';
import { GetCorretores } from '@actions/corretoresActions';
import { moneyNegMask } from '@utils/masks';
import { GetParceiros } from '@actions/parceirosActions';
import { GetCCAs } from '@actions/ccasActions';
import moment from 'moment';
import { SetPageHeader } from '@actions/userActions';
import { GetEmps } from '@actions/empsActions';
import { GetAgencias } from '@actions/agenciasActions';
import { GetLoteamentos } from '@actions/loteamentosActions';
import ReactToPrint from 'react-to-print';
import PrintPage from '../PrintPage';
import FichaPop from './FichaPop';
import { setCookie, getCookie } from '@services/localStorage';
import { useAppDispatch } from '@config/reduxStore';

function QuadroMain() {
  const dispatch = useAppDispatch();
  const { modalidades } = useSelector((state) => state.modalidades);
  const { corretores } = useSelector((state) => state.corretores);
  const { agencias } = useSelector((state) => state.agencias);
  const { loteamentos } = useSelector((state) => state.loteamentos);
  const { clientes, loadingclientes, savinghistorico } = useSelector((state) => state.correspondencia);
  const { parceiros } = useSelector((state) => state.parceiros);
  const { ccas } = useSelector((state) => state.ccas);
  const [filters, setFilters] = useState([]);
  const [historicoview, sethistoricoview] = useState(false);
  const [historicoForm] = Form.useForm();
  const [historico, sethistorico] = useState(null);
  const [defaulttab, setdefaulttab] = useState(Number(getCookie('quadrokey')) || null);
  const cookievalues = JSON.parse(getCookie('quadrofilters') || null) || null;
  const [tablefilters, settablefilters] = useState(cookievalues);
  const [ficha, setFicha] = useState({ cliente: null, visible: false });
  const printRef = useRef();
  useEffect(() => {
    dispatch(GetModalidades());
    dispatch(GetCCAs());
    dispatch(GetParceiros());
    dispatch(GetQuadro());
    dispatch(GetCorretores());
    dispatch(GetEmps());
    dispatch(GetAgencias());
    dispatch(GetLoteamentos());
  }, []);

  useEffect(() => {
    setFilters(modalidades.map((c) => ({ modalidade: c.id, filter: '' })));
    if (defaulttab === null && modalidades.length > 0) setdefaulttab(Number(modalidades[0].id));

    dispatch(
      SetPageHeader({
        title: 'Correspondência',
        backbutton: true,
        extras: [
          <Radio.Group
            key="1"
            buttonStyle="solid"
            onChange={(e) => {
              setdefaulttab(Number(e.target.value));
              setCookie('quadrokey', Number(e.target.value));
            }}
            defaultValue={defaulttab}
          >
            {modalidades.map((c) => (
              <Radio.Button key={c.id} value={c.id}>
                {c.descricao}
                <Tag style={{ marginLeft: 8 }}>{clientes.filter((e) => e.modalidadeCreditoID === c.id).length}</Tag>
              </Radio.Button>
            ))}
          </Radio.Group>,
        ],
        subtitle: 'Quadro de Vendas',
        menu: 'quadrovendas',
      })
    );
  }, [modalidades, clientes]);
  const columns = [
    {
      visible: ['all'],
      fixed: 'left',
      width: 40,
      render: (text, row, index) => index + 1,
    },
    {
      visible: ['all'],
      fixed: 'left',
      width: 90,
      dataIndex: 'id',
      render: (text, row) => (
        <div>
          <Tooltip title="Ficha do Cliente">
            <Button
              icon={<ProfileOutlined />}
              size="small"
              onClick={() => {
                setFicha({ visible: true });
                dispatch(GetCliente(row.id));
              }}
            />
          </Tooltip>
          <Tooltip title="Desistiu">
            <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(Desistir(row.id))}>
              <Button size="small" icon={<FrownOutlined />} />
            </Popconfirm>
          </Tooltip>
          <Tooltip title="Histórico">
            <Button
              size="small"
              onClick={() => {
                sethistoricoview(true);
                sethistorico(row.id);
                historicoForm.resetFields();
                historicoForm.setFieldsValue({ id: row.id });
              }}
              icon={<HistoryOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
    {
      visible: [],
      fixed: 'left',
      title: 'Status',
      dataIndex: 'status',
      width: 100,
      filteredValue: tablefilters ? tablefilters.filters.status : null,
      sorter: (a, b) => sortAlpha(a.status, b.status),
      onFilter: (value, row) => row.status.includes(value),
      filters: [
        //{ text: 'Desistiu', value: 'Desistiu' },
        //{ text: 'Pendente de Aprovação', value: 'Pendente de Aprovação' },
        { text: 'Aprovado', value: 'Aprovado' },
        //{ text: 'Reprovado', value: 'Reprovado' },
        { text: 'Relacionamento', value: 'Relacionamento' },
        { text: 'GIRET/CEHOP', value: 'GIRET/CEHOP' },
        { text: 'Conforme', value: 'Conforme' },
        { text: 'Contrato Assinado', value: 'Contrato Assinado' },
        { text: 'Concluído', value: 'Concluído' },
      ],
    },
    {
      visible: ['all'],
      fixed: 'left',
      title: 'Proponente',
      width: '8%',
      dataIndex: 'clienteNome1',
      sorter: (a, b) => sortAlpha(a.clienteNome1, b.clienteNome1),
      ...getColumnSearchProps('clienteNome1', 'string', 'Cliente', tablefilters),
    },
    {
      visible: ['all'],
      title: 'CPF',
      dataIndex: 'clienteCPF1',
      width: '8%',
      sorter: (a, b) => sortAlpha(a.clienteCPF1, b.clienteCPF1),
      ...getColumnSearchProps('clienteCPF1', 'string', 'CPF'),
    },
    {
      visible: ['MCMV', 'FGTS'],
      width: 120,
      title: 'Valor Liquido',
      dataIndex: 'valorLiquido',
      sorter: (a, b) => a.valorLiquido - b.valorLiquido,
      render: (text) => moneyNegMask.format(text),
      ...getColumnSearchProps('valorLiquido', 'string', 'Valor Final'),
    },
    {
      visible: ['SBPE'],
      width: '8%',
      title: 'Valor Financiado',
      dataIndex: 'valorFinanciamento',
      sorter: (a, b) => a.valorFinanciamento - b.valorFinanciamento,
      render: (text) => moneyNegMask.format(text),
      ...getColumnSearchProps('valorFinanciamento', 'string', 'Valor Financiado'),
    },
    {
      title: 'Agência',
      width: '8%',
      visible: ['all'],
      dataIndex: 'agencia',
      sorter: (a, b) => sortAlpha(a.agencia, b.agencia),
      filteredValue: tablefilters ? tablefilters.filters.agencia : null,
      filters: agencias.map((co) => ({
        value: co.banco + ' - ' + co.nome + '(' + co.numero + ')',
        text: co.banco + ' - ' + co.nome + '(' + co.numero + ')',
      })),
      onFilter: (value, row) => row.agencia.includes(value),
    },
    {
      visible: ['MCMV', 'FGTS'],
      title: 'Corretor',
      width: '8%',
      dataIndex: 'corretor',
      filteredValue: tablefilters ? tablefilters.filters.corretor : null,
      sorter: (a, b) => sortAlpha(a.corretor, b.corretor),
      filters: corretores.map((co) => ({ value: co.nome, text: co.nome })),
      onFilter: (value, row) => row.corretor.includes(value),
    },
    {
      visible: ['all'],
      title: 'Parceiro',
      dataIndex: 'parceiro',
      width: '8%',
      filteredValue: tablefilters ? tablefilters.filters.parceiro : null,
      sorter: (a, b) => sortAlpha(a.parceiro, b.parceiro),
      filters: parceiros.map((co) => ({ value: co.nome, text: co.nome })),
      onFilter: (value, row) => row.parceiro.includes(value),
    },
    {
      visible: ['MCMV'],
      title: 'Loteamento',
      width: '8%',
      dataIndex: 'loteamento',
      filteredValue: tablefilters ? tablefilters.filters.loteamento : null,
      sorter: (a, b) => sortAlpha(a.loteamento, b.loteamento),
      filters: loteamentos.map((co) => ({ value: co.nome, text: co.nome })),
      onFilter: (value, row) => row.loteamento === value,
    },
    {
      visible: ['MCMV', 'FGTS'],
      title: 'CCA',
      width: '8%',
      dataIndex: 'cca',
      filteredValue: tablefilters ? tablefilters.filters.cca : null,
      sorter: (a, b) => sortAlpha(a.cca, b.cca),
      filters: ccas.map((co) => ({ value: co.nome, text: co.nome })),
      onFilter: (value, row) => row.cca.includes(value),
    },
    {
      visible: ['MCMV'],
      title: 'Imóvel',
      width: '8%',
      dataIndex: 'casa',
      sorter: (a, b) => sortAlpha(a.casa, b.casa),
      ...getColumnSearchProps('casa', 'string', 'Casa'),
    },
    {
      visible: ['all'],
      title: 'Histórico',
      width: '20%',
      dataIndex: 'historico',
      render: (text, row) =>
        text.length > 0 ? (
          <div>
            <Typography.Text strong>{text[0].notas}</Typography.Text>
          </div>
        ) : (
          ''
        ),
    },
  ];

  return (
    <div>
      <FichaPop visible={ficha.visible} setVisible={setFicha} cliente={ficha.cliente} />
      <div style={{ textAlign: 'left' }}>
        <ReactToPrint
          trigger={() => (
            <Button size="small" type="primary" ghost style={{ width: 100 }}>
              Imprimir
            </Button>
          )}
          content={() => printRef.current}
        />
        <div style={{ display: 'none' }}>
          <PrintPage
            ref={printRef}
            title={`Quadro de clientes ${
              modalidades.length > 0 && defaulttab ? modalidades.find((c) => c.id === defaulttab).descricao : ''
            } - ${moment(new Date()).format('DD/MM/YYYY')}`}
            columns={columns.filter(
              (v) =>
                v.visible.includes(
                  modalidades.length > 0 && defaulttab ? modalidades.find((c) => c.id === defaulttab).descricao : ''
                ) || v.visible.includes('all')
            )}
            dataSource={
              filters.length > 0
                ? filterobject(
                    clientes.filter((e) => e.modalidadeCreditoID === defaulttab),
                    filters.find((f) => f.modalidade === defaulttab).filter
                  )
                : clientes.filter((e) => e.modalidadeCreditoID === defaulttab)
            }
          />
        </div>
        <Button
          size="small"
          onClick={() => {
            settablefilters(null);
            localStorage.removeItem('quadrofilters');
          }}
        >
          Limpar Filtros
        </Button>
        <Input
          style={{ width: 200 }}
          onChange={(e) =>
            setFilters(
              modalidades.map((m) => {
                if (defaulttab === m.id) return { modalidade: m.id, filter: e.target.value };
                else return { modalidade: m.id, filter: '' };
              })
            )
          }
          suffix={<SearchOutlined />}
          placeholder="Buscar"
          allowClear
        />
      </div>
      <Table
        style={{ fontSize: 7 }}
        rowKey="id"
        loading={loadingclientes}
        columns={columns.filter(
          (v) =>
            v.visible.includes(
              modalidades.length > 0 && defaulttab ? modalidades.find((c) => c.id === defaulttab).descricao : ''
            ) || v.visible.includes('all')
        )}
        dataSource={
          filters.length > 0
            ? filterobject(
                clientes.filter((e) => e.modalidadeCreditoID === defaulttab),
                filters.find((f) => f.modalidade === defaulttab).filter
              )
            : clientes.filter((e) => e.modalidadeCreditoID === defaulttab)
        }
        size="small"
        onChange={(pagination, filters, sorter) => {
          settablefilters({ pagination, filters, sorter, id: defaulttab });
          setCookie('quadrofilters', JSON.stringify({ pagination, filters, sorter }));
        }}
        scroll={{ y: 'calc(100vh - 300px)', x: 1200 }}
        pagination={{
          position: 'top',
          size: 'small',
          current: tablefilters ? tablefilters.pagination.current : 1,
          pageSize: tablefilters ? tablefilters.pagination.pageSize : 10,
          showSizeChanger: true,
          pageSizeOptions: [10, 25, 50, 100],
          showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
        }}
      />
      <Modal
        open={historicoview}
        title="Histórico"
        onCancel={() => {
          sethistoricoview(false);
          sethistorico(null);
        }}
        footer={[
          <Button
            onClick={() => {
              sethistoricoview(false);
              sethistorico(null);
            }}
          >
            Fechar
          </Button>,
        ]}
      >
        <Form
          name="historicoForm"
          wrapperCol={{ span: 18 }}
          labelCol={{ span: 6 }}
          form={historicoForm}
          initialValues={{ data: moment(new Date()) }}
          onFinish={(values) => dispatch(AddHistorico(values))}
        >
          <Form.Item label="id" name="id" style={{ display: 'none' }}>
            <Input />
          </Form.Item>
          <Form.Item label="Data" name="data">
            <DatePicker format="DD/MM/YYYY HH:mm" showTime />
          </Form.Item>
          <Form.Item label="Notas" name="notas">
            <Input.TextArea />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6 }}>
            <Button size="small" type="primary" htmlType="submit" loading={savinghistorico}>
              Salvar
            </Button>
          </Form.Item>
        </Form>
        <Divider />
        <div style={{ maxHeight: 400, overflow: 'scroll' }}>
          <List
            size="small"
            loadMore
            dataSource={historico ? clientes.find((c) => c.id === historico).historico : []}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={item.notas}
                  description={`${moment(item.data).format('DD/MM/YYYY HH:mm')} - ${item.responsavel}`}
                />
              </List.Item>
            )}
          />
        </div>
      </Modal>
    </div>
  );
}

export default QuadroMain;
