import { Col, Form, Input, Radio, Row } from 'antd';

function AndamentoItem({ name, title }: { name: string; title: string }) {
  return (
    <Row gutter={[8, 8]} align="bottom">
      <Col flex="1 1 60%">
        <Form.Item name={['andamento', name, 'status']} label={title}>
          <Radio.Group>
            <Radio value="Não iniciou">Não Iniciou</Radio>
            <Radio value="Iniciado">Iniciado</Radio>
            <Radio value="Atrasado">Atrasado</Radio>
            <Radio value="Concluído">Concluído</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>
      <Col flex="1 1 40%">
        <Form.Item name={['andamento', name, 'notas']}>
          <Input style={{ width: '100%' }} placeholder="Notas" />
        </Form.Item>
      </Col>
    </Row>
  );
}

export default AndamentoItem;
