import { createReducer } from '@reduxjs/toolkit';
import { notification } from 'antd';

import {
  GetAvancos,
  getAvancosFisicos,
  getCategoriaItens,
  getFisico,
  getFisicos,
  getRealizadoMes,
  salvarFisico,
  updateAvancoFisico,
} from '@actions/fisicoActions';
import moment from 'moment';
import { AvancoFisico, RealizadoMes, ValorFisico } from '@models/AvancoFisico';
import { Fisico } from '@models/Fisico';
import { ExtendedAction } from '@config/reduxStore';

interface FisicoReducer {
  fisicos: [];
  loadingFisicos: boolean;
  avancosFisicos: AvancoFisico[];
  loadingAvancosFisicos: boolean;
  fisico: Fisico[];
  loadingFisico: boolean;
  savingFisico: boolean;
  avancos: AvancoFisico;
  loadingAvancos: boolean;
  realizados: RealizadoMes[];
  loadingRealizados: boolean;
  orcamento: '';
  min: moment.Moment;
  max: moment.Moment;
  valores: ValorFisico[];
  grupos: [];
  loadingGrupos: boolean;
}
var initialState: FisicoReducer = {
  fisicos: [],
  loadingFisicos: false,
  avancosFisicos: [],
  loadingAvancosFisicos: false,
  fisico: [],
  loadingFisico: false,
  savingFisico: false,
  avancos: null,
  loadingAvancos: false,
  realizados: [],
  loadingRealizados: false,
  orcamento: '',
  min: moment(new Date()),
  max: moment(new Date()),
  valores: [],
  grupos: [],
  loadingGrupos: false,
};

export default createReducer(initialState, (builder) => {
  builder.addCase(getCategoriaItens.pending, (state) => ({
    ...state,
    loadingGrupos: true,
    grupos: [],
  }));
  builder.addCase(getCategoriaItens.rejected, (state) => ({
    ...state,
    loadingGrupos: false,
  }));
  builder.addCase(getCategoriaItens.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    loadingGrupos: false,
    grupos: action.payload.data,
  }));
  builder.addCase(getFisico.pending, (state) => ({
    ...state,
    loadingFisico: true,
  }));
  builder.addCase(getFisico.rejected, (state) => ({
    ...state,
    loadingFisico: false,
  }));
  builder.addCase(getFisico.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    loadingFisico: false,
    fisico: action.payload.data.categorias,
    orcamento: action.payload.data.orcamento,
  }));
  builder.addCase(GetAvancos.pending, (state) => ({
    ...state,
    loadingAvancos: true,
  }));
  builder.addCase(GetAvancos.rejected, (state) => ({
    ...state,
    loadingAvancos: false,
  }));
  builder.addCase(GetAvancos.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    loadingAvancos: false,
    avancos: action.payload.data,
  }));
  builder.addCase(getRealizadoMes.pending, (state) => ({
    ...state,
    loadingRealizados: true,
  }));
  builder.addCase(getRealizadoMes.rejected, (state) => ({
    ...state,
    loadingRealizados: false,
  }));
  builder.addCase(getRealizadoMes.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    loadingRealizados: false,
    realizados: action.payload.data.map((c: RealizadoMes) => ({ ...c, mes: moment(`${c.mes}/${c.ano}`, 'MM/YYYY') })),
  }));
  builder.addCase(salvarFisico.pending, (state) => ({
    ...state,
    savingFisico: true,
  }));
  builder.addCase(salvarFisico.rejected, (state) => {
    notification.error({ message: 'Erro ao salvar planejamento!' });
    return {
      ...state,
      savingFisico: false,
    };
  });
  builder.addCase(salvarFisico.fulfilled, (state) => {
    notification.success({ message: 'Planejamento salvo com sucesso!' });
    return {
      ...state,
      savingFisico: false,
    };
  });
  builder.addCase(getFisicos.pending, (state) => ({
    ...state,
    loadingFisicos: true,
  }));
  builder.addCase(getFisicos.rejected, (state) => ({
    ...state,
    loadingFisicos: false,
  }));
  builder.addCase(getFisicos.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    loadingFisicos: false,
    fisicos: action.payload.data.fisicos,
    valores: action.payload.data.valores,
  }));
  builder.addCase(getAvancosFisicos.pending, (state) => ({
    ...state,
    loadingAvancosFisicos: true,
  }));
  builder.addCase(getAvancosFisicos.rejected, (state) => ({
    ...state,
    loadingAvancosFisicos: false,
  }));
  builder.addCase(getAvancosFisicos.fulfilled, (state, action: ExtendedAction) => ({
    ...state,
    loadingAvancosFisicos: false,
    avancosFisicos: action.payload.data.fisicos,
    min: moment(action.payload.data.min),
    max: moment(action.payload.data.max),
  }));
  builder.addCase(updateAvancoFisico.pending, (state) => ({
    ...state,
    loadingAvancosFisicos: true,
  }));
  builder.addCase(updateAvancoFisico.rejected, (state, action: ExtendedAction) => {
    notification.error({ message: action.payload.response.data.Message });
    return {
      ...state,
      loadingAvancosFisicos: false,
    };
  });
  builder.addCase(updateAvancoFisico.fulfilled, (state, action: ExtendedAction) => {
    notification.success({ message: 'Progresso atualizado com sucesso' });
    return {
      ...state,
      loadingAvancosFisicos: false,
      avancosFisicos: action.payload.data.novo
        ? [...state.avancosFisicos, action.payload.data.avanco]
        : state.avancosFisicos.map((c) => {
            if (c.id === action.payload.data.avanco.id) {
              return action.payload.data.avanco;
            } else return c;
          }),
    };
  });
});
