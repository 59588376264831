import { ViewCancelForm } from '@actions/novaOrdemActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { OrdemStatus } from '@constants/ordemStatus';
import { HasPermission, HasRole } from '@hooks/usePermissions';
import { TransferenciaForm } from '@models/Transferencia';
import {
  AprovarTransferencia,
  CancelarTransferencia,
  FinalizarTransferencia,
  SubmeterTransferencia,
} from '@services/api/api.transferencia';
import { Button, Space } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CancelTransferenciaModal from './CancelarTransferenciaModal';

function TransferenciaActions({ transferencia }: { transferencia: TransferenciaForm }) {
  const { transferenciasPendentes } = useAppSelector((state) => state.transferencias);
  const submeterTransferencia = SubmeterTransferencia();
  const cancelarTransferencia = CancelarTransferencia();
  const finalizarTransferencia = FinalizarTransferencia();
  const aprovarTransferencia = AprovarTransferencia();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  return (
    <Space>
      <CancelTransferenciaModal />
      {transferencia?.situacao === OrdemStatus.Rascunho && (
        <Button
          type="primary"
          size="small"
          loading={submeterTransferencia.isLoading}
          onClick={() => submeterTransferencia.mutate({ id: transferencia.id })}
        >
          Submeter
        </Button>
      )}
      {transferencia?.situacao === OrdemStatus.PendenteAprovacao &&
        transferenciasPendentes.some((c) => c.id === Number(transferencia?.id) || c.cod === transferencia?.cod) && (
          <Button
            type="primary"
            size="small"
            loading={aprovarTransferencia.isLoading}
            onClick={() => aprovarTransferencia.mutate({ id: Number(transferencia?.id), cod: transferencia?.cod })}
          >
            Aprovar
          </Button>
        )}
      {transferencia?.situacao === OrdemStatus.Aprovada && HasRole(['financeiro', 'master'], user.roles) && (
        <Button
          type="primary"
          size="small"
          loading={finalizarTransferencia.isLoading}
          onClick={() => finalizarTransferencia.mutate({ id: transferencia?.id, cod: transferencia?.cod })}
        >
          Finalizar
        </Button>
      )}
      {(transferencia?.situacao === OrdemStatus.Rascunho ||
        transferencia?.situacao === OrdemStatus.PendenteAprovacao ||
        HasPermission('ordem.cancel', user.permissions)) &&
        transferencia?.id &&
        transferencia?.situacao !== OrdemStatus.Cancelada && (
          <Button
            danger
            size="small"
            loading={cancelarTransferencia.isLoading}
            onClick={() => {
              dispatch(ViewCancelForm({ view: true, id: transferencia.id, replace: false, cod: transferencia.cod }));
            }}
          >
            Cancelar
          </Button>
        )}
      {transferencia?.id && (
        <Link to={`/PrintTransferencia/${transferencia?.id}`} target="_blank">
          <Button size="small" type="primary" ghost icon={<PrinterOutlined />}>
            Imprimir
          </Button>
        </Link>
      )}
    </Space>
  );
}

export default TransferenciaActions;
