import { useEffect } from 'react';
import { Modal, Form, Input, Select, Button } from 'antd';
import { SalvarEmpreendimento, FormEmpreendimento } from '@actions/empsActions';
import TextArea from 'antd/lib/input/TextArea';
import { moneyMask, intMask } from '@utils/masks';
import { PlusOutlined } from '@ant-design/icons';
import { FormLoteamento } from '@actions/loteamentosActions';
import LoteamentoForm from '../Loteamentos/LoteamentoForm';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Empreendimento } from '@models/Empreendimento';

function EmpreendimentoForm() {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const emps = useAppSelector((state) => state.emps);
  const loteamentos = useAppSelector((state) => state.loteamentos);
  const [form] = Form.useForm<Empreendimento>();
  const submitForm = (values: Empreendimento) => {
    values = {
      ...values,
      aporte: values.aporte ? moneyMask.normalize(values.aporte).toString() : '0',
    };
    dispatch(SalvarEmpreendimento(values));
  };
  useEffect(() => {
    form.resetFields();
    if (emps.emp) {
      const values = emps.emps.find((c) => c.id === emps.emp);
      form.setFieldsValue({ ...values, aporte: moneyMask.format(values.aporte) });
    }
  }, [emps.emp, form, emps.emps]);
  return (
    <div>
      <LoteamentoForm />
      <Modal
        open={emps.formview}
        onCancel={() => dispatch(FormEmpreendimento(null, false))}
        title="Cadastro Empreendimento"
        footer={[
          <Button onClick={() => dispatch(FormEmpreendimento(null, false))} key="2">
            Fechar
          </Button>,
          <Button key="3" type="primary" onClick={() => form.submit()} loading={emps.savingemp}>
            Salvar
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form} onFinish={submitForm} name="empForm" initialValues={{ loteamento: null }}>
          <Form.Item style={{ display: 'none' }} name="id">
            <Input />
          </Form.Item>
          <Form.Item label="Nome" name="nome" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Input />
          </Form.Item>
          <Form.Item>
            <Form.Item label="Loteamento/Condomínio/Região" name="loteamentoID">
              <Select
                showSearch
                optionFilterProp="children"
                allowClear
                suffixIcon={<PlusOutlined onClick={() => dispatch(FormLoteamento(null, true))} />}
              >
                {loteamentos.loteamentos.map((c) => (
                  <Select.Option key={c.id} value={c.id}>
                    {c.nome}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form.Item>
          {user.modulos.map((c) => c.nome).includes('Investidor') && (
            <>
              <Form.Item label="Aporte" name="aporte" help="Preencha se o empreendimento possui investidores">
                <Input
                  onChange={(e) =>
                    form.setFieldsValue({
                      aporte: moneyMask.format(moneyMask.normalize(e.target.value)),
                    })
                  }
                />
              </Form.Item>
              <Form.Item label="Casas" name="numCasas" help="Preencha se o empreendimento possui investidores">
                <Input
                  onChange={(e) =>
                    form.setFieldsValue({
                      numCasas: intMask.format(intMask.normalize(e.target.value)),
                    })
                  }
                />
              </Form.Item>
            </>
          )}
          <Form.Item label="Descrição" name="descricao" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <TextArea />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
export default EmpreendimentoForm;
