import { Button, Card, Col, Form, Input, InputNumber, Row, Typography } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import OrcamentoItemSelect from '@components/OrcamentoItemSelect';
import { doubleMask } from '@utils/masks';
import { useQueryClient } from 'react-query';
import { OrcamentoItensResult } from '@services/api/api.orcamentoItem';
function PedidoFormItens() {
  const queryClient = useQueryClient();
  return (
    <Form.List name="itensPedido" initialValue={[]}>
      {(fields, { add, remove }) => (
        <Card
          size="small"
          title="Itens"
          extra={
            <Button size="small" onClick={() => add()} icon={<PlusOutlined />} type="primary" ghost>
              Novo Item
            </Button>
          }
        >
          {fields.map((field, index) => (
            <Row gutter={[10, 10]} key={field.name}>
              <Col span={1}>
                <Form.Item label={index + 1}>
                  <Button danger icon={<MinusOutlined />} size="small" onClick={() => remove(field.name)} />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item noStyle shouldUpdate={(nv, pv) => pv.orcamentoID !== nv.orcamentoID}>
                  {({ getFieldValue, setFieldValue }) => (
                    <Form.Item
                      name={[field.name, 'orcamentoItemID']}
                      label="Item"
                      rules={[{ required: true, message: 'Campo obrigatório' }]}
                    >
                      <OrcamentoItemSelect
                        orcamentoID={getFieldValue('orcamentoID')}
                        onChange={(v) => {
                          const data = queryClient.getQueryData<OrcamentoItensResult>([
                            'orcamentoItensList',
                            getFieldValue('orcamentoID'),
                            true,
                          ]);
                          const item = data?.itens?.find((c) => c.id === v);
                          setFieldValue(['itensPedido', field.name, 'saldoQuantidade'], item.saldoQuantidade);
                          setFieldValue(['itensPedido', field.name, 'itemID'], item.itemID);
                          setFieldValue(['itensPedido', field.name, 'categoriaID'], item.categoriaID);
                          setFieldValue(['itensPedido', field.name, 'grupoID'], item.grupoID);
                        }}
                      />
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item name={[field.name, 'descricao']} label="Especificação(opcional)">
                  <Input placeholder="Descrição..." />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item
                  noStyle
                  shouldUpdate={(pv, nv) =>
                    pv.itensPedido[field.name]?.orcamentoItemID !== nv.itensPedido[field.name]?.orcamentoItemID
                  }
                >
                  {({ getFieldValue }) => (
                    <>
                      <Form.Item name={[field.name, 'saldoQuantidade']} hidden>
                        <Input />
                      </Form.Item>
                      <Form.Item name={[field.name, 'itemID']} hidden>
                        <Input />
                      </Form.Item>
                      <Form.Item name={[field.name, 'categoriaID']} hidden>
                        <Input />
                      </Form.Item>
                      <Form.Item name={[field.name, 'grupoID']} hidden>
                        <Input />
                      </Form.Item>
                      <Form.Item label="Quant. Disponível">
                        <Typography.Text>
                          {doubleMask.format(getFieldValue(['itensPedido', field.name, 'saldoQuantidade']))}
                        </Typography.Text>
                      </Form.Item>
                    </>
                  )}
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => (
                    <Form.Item
                      name={[field.name, 'quantidade']}
                      initialValue={0}
                      label="Quantidade"
                      rules={[
                        { required: true, message: 'Campo obrigatório' },
                        ({ getFieldValue }) => ({
                          validator: async (_, val) => {
                            if (val === 0) return Promise.reject('Valor zero');
                            else if (val > getFieldValue(['itensPedido', field.name, 'saldoQuantidade']))
                              return Promise.reject('Saldo insificiente');
                            else return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <InputNumber
                        min={0}
                        decimalSeparator=","
                        precision={2}
                        max={getFieldValue(['itensList', field.name, 'saldoQuantidade'])}
                      />
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
            </Row>
          ))}
        </Card>
      )}
    </Form.List>
  );
}
export default PedidoFormItens;
