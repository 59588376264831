import { useEffect } from 'react';
import { Modal, Form, Input, Button } from 'antd';
import { SalvarParceiro, FormParceiro } from '@actions/parceirosActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Parceiro } from '@models/Parceiro';

function ParceiroForm() {
  const dispatch = useAppDispatch();
  const parceiros = useAppSelector((state) => state.parceiros);
  const [form] = Form.useForm<Parceiro>();
  const submitForm = (values: Parceiro) => {
    dispatch(SalvarParceiro(values));
  };
  useEffect(() => {
    form.resetFields();
    if (parceiros.parceiro) {
      form.setFieldsValue(parceiros.parceiros.find((c) => c.id === parceiros.parceiro));
    }
  }, [parceiros.parceiro, form, parceiros.parceiros]);
  return (
    <div>
      <Modal
        open={parceiros.formview}
        onCancel={() => dispatch(FormParceiro(null, false))}
        title="Cadastro Parceiro"
        footer={[
          <Button onClick={() => dispatch(FormParceiro(null, false))} key="2">
            Fechar
          </Button>,
          <Button key="3" type="primary" onClick={() => form.submit()} loading={parceiros.savingparceiro}>
            Salvar
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form} onFinish={submitForm} name="parceiroForm">
          <Form.Item style={{ display: 'none' }} name="id">
            <Input />
          </Form.Item>
          <Form.Item label="Nome" name="nome" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
export default ParceiroForm;
