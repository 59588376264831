import { useEffect, useState } from 'react';
import {
  DeleteArquivo,
  DisponibilizarArquivoCliente,
  DownloadArquivo,
  FormGaleria,
  GetCasa,
  SalvarCasa,
  ShowClienteObra,
  ShowProgressoLegalizacao,
  ShowProgressoObra,
  UploadFiles,
} from '@actions/casasActions';
import { SetPageHeader } from '@actions/userActions';
import { Link, useNavigate, useParams } from 'react-router-dom';
import NovoChamadoView from '../Chamados/NovoChamadoView';
import AddGaleriaModal from '../ClienteObra/AddGaleriaModal';
import {
  DownloadOutlined,
  EyeOutlined,
  UploadOutlined,
  DeleteOutlined,
  PlusOutlined,
  MinusOutlined,
} from '@ant-design/icons';
import {
  Divider,
  Skeleton,
  Row,
  Tabs,
  Col,
  Form,
  Input,
  Checkbox,
  Select,
  DatePicker,
  InputNumber,
  Radio,
  Typography,
  Button,
  Upload,
  Table,
  Space,
  Popconfirm,
  Descriptions,
} from 'antd';
import { cpfMask, doubleMask, intMask, mobMask, moneyMask, moneyNegMask } from '@utils/masks';
import { HasPermission, HasRole, Permission } from '@hooks//usePermissions';
import Modal from 'antd/lib/modal/Modal';
import moment from 'moment';
import { filterobject, getColumnSearchProps, orderDate, sortAlpha } from '@utils/filterandsort';
import { ShowGarantias } from '@actions/chamadosActions';
import Garantias from '../Chamados/Garantias';
import AddClienteObraModal from '../ClienteObra/AddClienteObraModal';
import ProgressoObra from '../ClienteObra/ProgressoObra';
import ProgressoLegalizacao from '../ClienteObra/ProgressoLegalizacao';
import PDFView from '../PDFView/PDFView';
import { Axios } from '@config/axios';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Casa } from '@models/Casa';
import { Arquivo } from '@models/Arquivo';
import { Chamado } from '@models/Chamado';
const required = { required: true, message: 'Campo obrigatório' };
const tabHeight = 500;

function FichaNova() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const emps = useAppSelector((state) => state.emps.emps);
  const agencias = useAppSelector((state) => state.agencias.agencias);
  const corretores = useAppSelector((state) => state.corretores.corretores);
  const { empresas } = useAppSelector((state) => state.empresas);
  const [filtersArquivos, setFiltersArquivos] = useState(null);
  const { user } = useAppSelector((state) => state.user);
  const [chamadoForm] = Form.useForm();
  const { fichaImovel, savingcasa, loadingcasa, savingchamado, downloadingfile, savingfiles, loadingfiles } =
    useAppSelector((state) => state.casas);
  const [casa] = Form.useForm();
  const [filter, setFilter] = useState('');
  const [aditivos, setaditivos] = useState(false);
  const [receitas, setreceitas] = useState(false);
  const userpermissions = useAppSelector((state) => state.user.user.permissions);
  const roles = useAppSelector((state) => state.user.user.roles);
  const [files, upFile] = useState([]);
  const [formChamado, setFormChamado] = useState(false);
  const navigate = useNavigate();
  const saveForm = (values: Casa) => {
    values = {
      ...values,
      laudo: values.laudo ? moneyMask.normalize(values.laudo as string) : 0,
      valorCompraVenda: values.valorEntradaParcelada ? moneyMask.normalize(values.valorCompraVenda as string) : 0,
      valorFinanciado: values.valorFinanciado ? moneyMask.normalize(values.valorFinanciado as string) : 0,
      valorFGTS: values.valorFGTS ? moneyMask.normalize(values.valorFGTS as string) : 0,
      valorSubsidio: values.valorSubsidio ? moneyMask.normalize(values.valorSubsidio as string) : 0,
      valorEntrada: values.valorEntrada ? moneyMask.normalize(values.valorEntrada as string) : 0,
      valorDesconto: values.valorDesconto ? moneyMask.normalize(values.valorDesconto as string) : 0,
      valorTotalLiquido: values.valorTotalLiquido ? moneyMask.normalize(values.valorTotalLiquido as string) : 0,
      valorEntradaParcelada: values.valorEntradaParcelada
        ? moneyMask.normalize(values.valorEntradaParcelada as string)
        : 0,
      valorDoc: values.valorDoc ? moneyMask.normalize(values.valorDoc as string) : 0,
      distribuido: values.distribuido ? moneyMask.normalize(values.distribuido as string) : 0,
      produtoAgencia: values.produtoAgencia ? moneyMask.normalize(values.produtoAgencia as string) : 0,
      taxaAvista: values.taxaAvista ? moneyMask.normalize(values.taxaAvista as string) : 0,
      area: values.area ? doubleMask.normalize(values.area as string) : 0,
      valorm2: values.valorm2 ? moneyMask.normalize(values.valorm2) : 0,
      margem: values.margem ? moneyMask.normalize(values.margem as string) : 0,
      valorContratoObra: values.valorContratoObra ? moneyMask.normalize(values.valorContratoObra as string) : 0,
      aditivos: values.aditivos.map((c) => ({ ...c, valor: moneyMask.normalize(c.valor as string) })),
      receitas: values.receitas.map((c) => ({ ...c, valor: moneyMask.normalize(c.valor as string) })),
    };
    dispatch(SalvarCasa(values));
  };
  useEffect(() => {
    dispatch(GetCasa(id));
    dispatch(
      SetPageHeader({
        title: 'Unidades',
        backbutton: true,
        extras: [],
        subtitle: 'Ficha do Imóvel',
        menu: 'casas',
      })
    );
  }, [id]);
  useEffect(() => {
    if (fichaImovel) {
      casa.setFieldsValue({
        ...fichaImovel,
        area: doubleMask.format(fichaImovel.area),
        laudo: moneyMask.format(fichaImovel.laudo),
        valorCompraVenda: moneyMask.format(fichaImovel.valorCompraVenda),
        valorFinanciado: moneyMask.format(fichaImovel.valorFinanciado),
        valorFGTS: moneyMask.format(fichaImovel.valorFGTS),
        valorSubsidio: moneyMask.format(fichaImovel.valorSubsidio),
        valorEntrada: moneyMask.format(fichaImovel.valorEntrada),
        valorDesconto: moneyMask.format(fichaImovel.valorDesconto),
        valorTotalLiquido: moneyMask.format(fichaImovel.valorTotalLiquido),
        valorEntradaParcelada: moneyMask.format(fichaImovel.valorEntradaParcelada),
        valorContratoObra: moneyMask.format(fichaImovel.valorContratoObra),
        distribuido: moneyMask.format(fichaImovel.distribuido),
        margem: moneyMask.format(fichaImovel.margem),
        valorDoc: moneyMask.format(fichaImovel.valorDoc),
        produtoAgencia: moneyMask.format(fichaImovel.produtoAgencia),
        taxaAvista: moneyMask.format(fichaImovel.taxaAvista),
        dataAlvara: moment(fichaImovel.dataAlvara).isValid() ? moment(fichaImovel.dataAlvara) : null,
        dataHabitese: moment(fichaImovel.dataHabitese).isValid() ? moment(fichaImovel.dataHabitese) : null,
        projetoArquitetonico: moment(fichaImovel.projetoArquitetonico).isValid()
          ? moment(fichaImovel.projetoArquitetonico)
          : null,
        dataInicioObra: moment(fichaImovel.dataInicioObra).isValid() ? moment(fichaImovel.dataInicioObra) : null,
        dataFinalObra: moment(fichaImovel.dataFinalObra).isValid() ? moment(fichaImovel.dataFinalObra) : null,
        aditivos: fichaImovel?.aditivos.map((c) => ({
          valor: moneyMask.format(c.valor),
          data: moment(c.data),
          notas: c.notas,
        })),
        receitas: fichaImovel.receitas.map((c) => ({
          valor: moneyMask.format(c.valor),
          data: moment(c.data),
          notas: c.notas,
          pago: c.pago,
        })),
        dataEntrega: moment(fichaImovel.dataEntrega).isValid() ? moment(fichaImovel.dataEntrega) : null,
        proprietarioNascimento1: moment(fichaImovel.proprietarioNascimento1).isValid()
          ? moment(fichaImovel.proprietarioNascimento1)
          : null,
        proprietarioNascimento2: moment(fichaImovel.proprietarioNascimento2).isValid()
          ? moment(fichaImovel.proprietarioNascimento2)
          : null,
        dataContratoObra: moment(fichaImovel.dataContratoObra).isValid() ? moment(fichaImovel.dataContratoObra) : null,
        dataContrato: moment(fichaImovel.dataContrato).isValid() ? moment(fichaImovel.dataContrato) : null,
        dataDistribuido: moment(fichaImovel.dataDistribuido).isValid() ? moment(fichaImovel.dataDistribuido) : null,
      });
    }
  }, [fichaImovel]);
  const [open, setOpen] = useState(true);
  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        navigate(-1);
      }, 500);
    }
  }, [open, navigate]);
  const [viewpdf, setViewPdf] = useState(false);
  const [pdfFile, setPdfFile] = useState({ url: '', type: '' });
  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      title="Ficha do Imóvel"
      onOk={() => casa.submit()}
      okText="Salvar"
      cancelText="Fechar"
      okButtonProps={{ loading: loadingcasa || savingcasa }}
    >
      <Skeleton loading={loadingcasa}>
        <PDFView visible={viewpdf} setVisible={setViewPdf} url={pdfFile.url} fileType={pdfFile.type} />
        <Form layout="vertical" form={casa} name="casaForm" onFinish={(values) => saveForm(values)} scrollToFirstError>
          <Form.Item hidden name="id">
            <Input />
          </Form.Item>
          <Tabs defaultActiveKey="0">
            <Tabs.TabPane tab="Dados Gerais" forceRender key="0">
              <div style={{ maxHeight: tabHeight, overflowY: 'auto' }}>
                <Row gutter={[8, 8]}>
                  <Col span={4}>
                    <Form.Item name="vendida" label="Vendida" valuePropName="checked">
                      <Checkbox disabled={!HasPermission('casa.vender', userpermissions)}>Sim</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Empresa"
                      name="empresaLegalizacaoID"
                      rules={[{ required: true, message: 'Campo Obrigatório' }]}
                    >
                      <Select>
                        {empresas.length > 0 &&
                          empresas.map((c) => (
                            <Select.Option key={c.id} value={c.id}>
                              {c.nome}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Área Construida"
                      name="area"
                      initialValue={doubleMask.format(0)}
                      normalize={(v) => doubleMask.format(doubleMask.normalize(v))}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[8, 8]}>
                  <Col span={6}>
                    <Form.Item label="Pavimentos" name="pavimentos">
                      <InputNumber step={1} min={0} />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Banheiros" name="banheiros">
                      <InputNumber step={1} min={0} />
                    </Form.Item>
                  </Col>
                  {HasRole(['master', 'admin', 'financeiro', 'comercial'], roles) && (
                    <Col span={6}>
                      <Form.Item
                        label="Valor/m² "
                        name="valorm2"
                        initialValue={moneyMask.format(0)}
                        normalize={(v) => moneyMask.format(moneyMask.normalize(v))}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  )}
                  <Col span={6}>
                    <Form.Item label="Empreendimento" name="empreendimentoID" rules={[required]}>
                      <Select
                        showSearch
                        disabled={!HasPermission('empreendimento.list', userpermissions)}
                        optionFilterProp="children"
                      >
                        {emps.map((c) => (
                          <Select.Option key={c.id} value={c.id}>
                            {c.nome}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={10}>
                  <Col flex="0 1 70%">
                    <Form.Item label="Endereço" name="endereco" rules={[required]}>
                      <Input size="small" />
                    </Form.Item>
                  </Col>
                  <Col flex="1 1 30%">
                    <Form.Item name="numero" label="Número" rules={[required]}>
                      <Input size="small" />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item name="complemento" label="Complemento">
                  <Input size="small" />
                </Form.Item>
                <Row gutter={10}>
                  <Col flex="1 1 33%">
                    <Form.Item name="cep" label="CEP">
                      <Input size="small" />
                    </Form.Item>
                  </Col>
                  <Col flex="1 1 33%">
                    <Form.Item name="quadra" label="Quadra">
                      <Input size="small" />
                    </Form.Item>
                  </Col>
                  <Col flex="1 1 33%">
                    <Form.Item name="lote" label="Lote">
                      <Input size="small" />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item name="clienteObra" valuePropName="checked">
                  <Checkbox>Cliente para Construção</Checkbox>
                </Form.Item>
                <Form.Item name="notas" label="Notas">
                  <Input.TextArea />
                </Form.Item>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Legalização" forceRender key="1">
              <div style={{ maxHeight: tabHeight, overflowY: 'auto' }}>
                <Row gutter={10}>
                  <Col span={12}>
                    <Form.Item label="Nº Alvará" name="alvara">
                      <Input size="small" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Data Alvará" name="dataAlvara">
                      <DatePicker format="DD/MM/YYYY" size="small" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={12}>
                    <Form.Item label="Nº Habite-se" name="habitese">
                      <Input size="small" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Data Habite-se" name="dataHabitese">
                      <DatePicker format="DD/MM/YYYY" size="small" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={10}>
                  <Col span={12}>
                    <Form.Item label="ART" name="art">
                      <Input size="small" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Matrícula CEI" name="matriculaCEI">
                      <Input size="small" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={12}>
                    <Form.Item label="Matrícula Filha" name="matriculaFilha">
                      <Input size="small" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Matrícula Mãe" name="matriculaMae">
                      <Input size="small" />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item label="Sequencial" name="sequencial">
                  <Input size="small" />
                </Form.Item>
                <Row gutter={8}>
                  <Col span={10}>
                    <Form.Item
                      label="Laudo"
                      name="laudo"
                      initialValue={moneyMask.format(0)}
                      normalize={(v) => moneyMask.format(moneyMask.normalize(v))}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={14}>
                    <Form.Item label="Empresa Laudo" name="empresaLaudo">
                      <Input size="small" />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item label="Notas Engenharia" name="notasEngenharia">
                  <Input size="small" />
                </Form.Item>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Proprietário" forceRender key="2">
              <div style={{ maxHeight: tabHeight, overflowY: 'auto' }}>
                <Row gutter={10}>
                  <Col span={14}>
                    <Form.Item label="Parceiro" name="corretorID">
                      <Select
                        allowClear
                        disabled={!HasPermission('corretor.list', userpermissions)}
                        size="small"
                        showSearch
                        optionFilterProp="children"
                      >
                        {corretores.map((c) => (
                          <Select.Option key={c.id} value={c.id}>
                            {c.nome}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={10}></Col>
                </Row>
                <Divider>Proprietário 1</Divider>
                <Row gutter={10}>
                  <Col span={14}>
                    <Form.Item label="Nome" name="proprietarioNome1">
                      <Input size="small" />
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item label="Sexo" name="proprietarioSexo1">
                      <Radio.Group>
                        <Radio value="masculino">Masculino</Radio>
                        <Radio value="feminino">Feminino</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={14}>
                    <Form.Item
                      label="CPF"
                      name="proprietarioCPF1"
                      normalize={(v) => cpfMask.format(cpfMask.normalize(v))}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item label="Nascimento" name="proprietarioNascimento1">
                      <DatePicker format="DD/MM/YYYY" size="small" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={10}>
                  <Col span={24}>
                    <Form.Item
                      label="Email"
                      name="porprietarioEmail"
                      rules={[{ type: 'email', message: 'Email inválido' }]}
                    >
                      <Input size="small" />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider>Proprietário 2</Divider>
                <Row gutter={10}>
                  <Col span={14}>
                    <Form.Item label="Nome" name="proprietarioNome2">
                      <Input size="small" />
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item label="Sexo" name="proprietarioSexo2">
                      <Radio.Group>
                        <Radio value="masculino">Masculino</Radio>
                        <Radio value="fiminino">Feminino</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={10}>
                  <Col span={14}>
                    <Form.Item
                      label="CPF"
                      name="proprietarioCPF2"
                      normalize={(v) => cpfMask.format(cpfMask.normalize(v))}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item label="Nascimento" name="proprietarioNascimento2">
                      <DatePicker format="DD/MM/YYYY" size="small" />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider>Contatos</Divider>
                <Row gutter={10}>
                  <Col span={12}>
                    <Form.Item
                      label="Telefone 1"
                      name="proprietarioTelefone1"
                      normalize={(value) => mobMask.format(mobMask.normalize(value))}
                    >
                      <Input size="small" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Telefone 2"
                      name="proprietarioTelefone2"
                      normalize={(value) => mobMask.format(mobMask.normalize(value))}
                    >
                      <Input size="small" />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Jornada" forceRender key="3">
              <div style={{ maxHeight: tabHeight, overflowY: 'auto' }}>
                <Form.Item label="Data Contrato Obra" name="dataContratoObra">
                  <DatePicker format="DD/MM/YYYY" size="small" allowClear />
                </Form.Item>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) =>
                    getFieldValue('dataContratoObra') && (
                      <>
                        <Form.Item label="Data Projeto Arquitetônico" name="projetoArquitetonico">
                          <DatePicker format="DD/MM/YYYY" size="small" allowClear />
                        </Form.Item>
                        <Form.Item noStyle shouldUpdate>
                          {({ getFieldValue }) =>
                            getFieldValue('projetoArquitetonico') && (
                              <>
                                <Form.Item label="Data Início da Obra" name="dataInicioObra">
                                  <DatePicker format="DD/MM/YYYY" size="small" allowClear />
                                </Form.Item>
                                <Form.Item noStyle shouldUpdate>
                                  {({ getFieldValue }) =>
                                    getFieldValue('dataInicioObra') && (
                                      <>
                                        <Form.Item label="Data Final da Obra" name="dataFinalObra">
                                          <DatePicker format="DD/MM/YYYY" size="small" allowClear />
                                        </Form.Item>
                                        <Form.Item noStyle shouldUpdate>
                                          {({ getFieldValue }) =>
                                            getFieldValue('dataFinalObra') && (
                                              <Form.Item
                                                label="Data Entrega"
                                                name="dataEntrega"
                                                help="Esta data define o início das garantias do imóvel"
                                              >
                                                <DatePicker format="DD/MM/YYYY" size="small" />
                                              </Form.Item>
                                            )
                                          }
                                        </Form.Item>
                                      </>
                                    )
                                  }
                                </Form.Item>
                              </>
                            )
                          }
                        </Form.Item>
                      </>
                    )
                  }
                </Form.Item>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane
              tab="Contrato"
              forceRender
              key="4"
              disabled={!HasPermission('casa.contrato.view', userpermissions)}
            >
              <div style={{ maxHeight: tabHeight, overflowY: 'auto' }}>
                <Row gutter={8}>
                  <Col flex="1 1 60%">
                    <Form.Item label="Agência" name="agenciaID">
                      <Select
                        allowClear
                        disabled={!HasPermission('agencia.list', userpermissions)}
                        size="small"
                        showSearch
                        optionFilterProp="children"
                      >
                        {agencias.map((c) => (
                          <Select.Option key={c.id} value={c.id}>
                            {c.nome}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col flex="1 1 40%">
                    <Form.Item label="Data Contrato" name="dataContrato">
                      <DatePicker format="DD/MM/YYYY" />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item label="Contrato" name="contrato">
                  <Input />
                </Form.Item>
                <Row gutter={8}>
                  <Col flex="1 1 50%">
                    <Form.Item shouldUpdate noStyle>
                      {({ getFieldValue, setFieldsValue }) => (
                        <Form.Item
                          label="Valor Compra e Venda"
                          name="valorCompraVenda"
                          initialValue={moneyMask.format(0)}
                        >
                          <Input
                            size="small"
                            onChange={(e) =>
                              setFieldsValue({
                                valorCompraVenda: moneyMask.format(moneyMask.normalize(e.target.value)),
                                valorEntrada: moneyNegMask.format(
                                  Number(moneyMask.normalize(e.target.value)) -
                                    (Number(moneyMask.normalize(getFieldValue('valorFinanciado'))) +
                                      Number(moneyMask.normalize(getFieldValue('valorFGTS'))) +
                                      Number(moneyMask.normalize(getFieldValue('valorSubsidio'))))
                                ),
                                valorTotalLiquido: moneyNegMask.format(
                                  moneyMask.normalize(e.target.value) -
                                    (moneyMask.normalize(getFieldValue('valorFinanciado')) +
                                      moneyMask.normalize(getFieldValue('valorFGTS')) +
                                      moneyMask.normalize(getFieldValue('valorSubsidio'))) +
                                    moneyMask.normalize(getFieldValue('valorFinanciado')) +
                                    moneyMask.normalize(getFieldValue('valorFGTS')) +
                                    moneyMask.normalize(getFieldValue('valorSubsidio')) -
                                    moneyMask.normalize(getFieldValue('valorDesconto'))
                                ),
                              })
                            }
                          />
                        </Form.Item>
                      )}
                    </Form.Item>
                  </Col>
                  <Col flex="1 1 50%">
                    <Form.Item shouldUpdate noStyle>
                      {({ getFieldValue, setFieldsValue }) => (
                        <Form.Item label="Valor Financiado" name="valorFinanciado" initialValue={moneyMask.format(0)}>
                          <Input
                            size="small"
                            onChange={(e) =>
                              setFieldsValue({
                                valorFinanciado: moneyMask.format(moneyMask.normalize(e.target.value)),
                                valorEntrada: moneyNegMask.format(
                                  moneyMask.normalize(getFieldValue('valorCompraVenda')) -
                                    (moneyMask.normalize(e.target.value) +
                                      moneyMask.normalize(getFieldValue('valorFGTS')) +
                                      moneyMask.normalize(getFieldValue('valorSubsidio')))
                                ),
                                valorTotalLiquido: moneyNegMask.format(
                                  moneyMask.normalize(getFieldValue('valorCompraVenda')) -
                                    (moneyMask.normalize(e.target.value) +
                                      moneyMask.normalize(getFieldValue('valorFGTS')) +
                                      moneyMask.normalize(getFieldValue('valorSubsidio'))) +
                                    moneyMask.normalize(e.target.value) +
                                    moneyMask.normalize(getFieldValue('valorFGTS')) +
                                    moneyMask.normalize(getFieldValue('valorSubsidio')) -
                                    moneyMask.normalize(getFieldValue('valorDesconto'))
                                ),
                              })
                            }
                          />
                        </Form.Item>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col flex="1 1 50%">
                    <Form.Item shouldUpdate noStyle>
                      {({ getFieldValue, setFieldsValue }) => (
                        <Form.Item label="Valor FGTS" name="valorFGTS" initialValue={moneyMask.format(0)}>
                          <Input
                            size="small"
                            onChange={(e) =>
                              setFieldsValue({
                                valorFGTS: moneyMask.format(moneyMask.normalize(e.target.value)),
                                valorEntrada: moneyNegMask.format(
                                  moneyMask.normalize(getFieldValue('valorCompraVenda')) -
                                    (moneyMask.normalize(getFieldValue('valorFinanciado')) +
                                      moneyMask.normalize(e.target.value) +
                                      moneyMask.normalize(getFieldValue('valorSubsidio')))
                                ),
                                valorTotalLiquido: moneyNegMask.format(
                                  moneyMask.normalize(getFieldValue('valorCompraVenda')) -
                                    (moneyMask.normalize(getFieldValue('valorFinanciado')) +
                                      moneyMask.normalize(e.target.value) +
                                      moneyMask.normalize(getFieldValue('valorSubsidio'))) +
                                    moneyMask.normalize(getFieldValue('valorFinanciado')) +
                                    moneyMask.normalize(e.target.value) +
                                    moneyMask.normalize(getFieldValue('valorSubsidio')) -
                                    moneyMask.normalize(getFieldValue('valorDesconto'))
                                ),
                              })
                            }
                          />
                        </Form.Item>
                      )}
                    </Form.Item>
                  </Col>
                  <Col flex="1 1 50%">
                    <Form.Item shouldUpdate noStyle>
                      {({ getFieldValue, setFieldsValue }) => (
                        <Form.Item label="Valor Subsídio" name="valorSubsidio" initialValue={moneyMask.format(0)}>
                          <Input
                            size="small"
                            onChange={(e) =>
                              setFieldsValue({
                                valorSubsidio: moneyMask.format(moneyMask.normalize(e.target.value)),
                                valorEntrada: moneyNegMask.format(
                                  moneyMask.normalize(getFieldValue('valorCompraVenda')) -
                                    (moneyMask.normalize(getFieldValue('valorFinanciado')) +
                                      moneyMask.normalize(getFieldValue('valorFGTS')) +
                                      moneyMask.normalize(e.target.value))
                                ),
                                valorTotalLiquido: moneyNegMask.format(
                                  moneyMask.normalize(getFieldValue('valorCompraVenda')) -
                                    (moneyMask.normalize(getFieldValue('valorFinanciado')) +
                                      moneyMask.normalize(getFieldValue('valorFGTS')) +
                                      moneyMask.normalize(e.target.value)) +
                                    moneyMask.normalize(getFieldValue('valorFinanciado')) +
                                    moneyMask.normalize(getFieldValue('valorFGTS')) +
                                    moneyMask.normalize(e.target.value) -
                                    moneyMask.normalize(getFieldValue('valorDesconto'))
                                ),
                              })
                            }
                          />
                        </Form.Item>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col flex="1 1 50%">
                    <Form.Item
                      label="Valor Entrada"
                      name="valorEntrada"
                      valuePropName="children"
                      initialValue={moneyMask.format(0)}
                    >
                      <Typography.Text />
                    </Form.Item>
                  </Col>
                  <Col flex="1 1 50%">
                    <Form.Item noStyle shouldUpdate>
                      {({ getFieldValue, setFieldsValue }) => (
                        <Form.Item label="Valor Desconto" name="valorDesconto" initialValue={moneyMask.format(0)}>
                          <Input
                            size="small"
                            onChange={(e) =>
                              setFieldsValue({
                                valorDesconto: moneyMask.format(moneyMask.normalize(e.target.value)),
                                valorTotalLiquido: moneyNegMask.format(
                                  Number(moneyMask.normalize(getFieldValue('valorCompraVenda'))) -
                                    (moneyMask.normalize(getFieldValue('valorFinanciado')) +
                                      moneyMask.normalize(getFieldValue('valorFGTS')) +
                                      moneyMask.normalize(getFieldValue('valorSubsidio'))) +
                                    moneyMask.normalize(getFieldValue('valorFinanciado')) +
                                    moneyMask.normalize(getFieldValue('valorFGTS')) +
                                    moneyMask.normalize(getFieldValue('valorSubsidio')) -
                                    moneyMask.normalize(e.target.value)
                                ),
                              })
                            }
                          />
                        </Form.Item>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col flex="1 1 40%">
                    <Form.Item
                      label="Valor Líquido"
                      name="valorTotalLiquido"
                      valuePropName="children"
                      initialValue={moneyMask.format(0)}
                    >
                      <Typography.Text />
                    </Form.Item>
                  </Col>
                  <Col flex="1 1 40%">
                    <Form.Item
                      label="Entrada Parcelada"
                      name="valorEntradaParcelada"
                      initialValue={moneyMask.format(0)}
                      normalize={(v) => moneyMask.format(moneyMask.normalize(v))}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col flex="1 1 20%">
                    <Form.Item
                      label="Parcelas"
                      name="parcelasEntrada"
                      initialValue={intMask.format(0)}
                      normalize={(v) => intMask.format(intMask.normalize(v))}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item shouldUpdate noStyle>
                  {({ getFieldValue }) =>
                    getFieldValue('clienteObra') && (
                      <>
                        <Row gutter={[8, 8]}>
                          <Col span={12}>
                            <Form.Item
                              label="Documentação Inclusa (SBPE)"
                              name="documentacaoInclusa"
                              initialValue={null}
                            >
                              <Select size="small">
                                <Select.Option value={true}>Sim</Select.Option>
                                <Select.Option value={false}>Não</Select.Option>
                                <Select.Option value={null}>N/A</Select.Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item shouldUpdate noStyle>
                              {({ getFieldValue }) =>
                                getFieldValue('documentacaoInclusa') && (
                                  <Form.Item
                                    name="valorDoc"
                                    label="Valor"
                                    initialValue={moneyMask.format(0)}
                                    normalize={(value) => moneyMask.format(moneyMask.normalize(value))}
                                  >
                                    <Input />
                                  </Form.Item>
                                )
                              }
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={[8, 8]}>
                          <Col span={12}>
                            <Form.Item label="Memorial" name="memorial">
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={8}>
                          <Col span={12}>
                            <Form.Item
                              label="Valor Contrato Obra"
                              name="valorContratoObra"
                              initialValue={moneyMask.format(0)}
                              normalize={(value) => moneyMask.format(moneyMask.normalize(value))}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label="Margem (R$/m2)"
                              name="margem"
                              initialValue={moneyMask.format(0)}
                              normalize={(value) => moneyMask.format(moneyMask.normalize(value))}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Space direction="vertical">
                          <Space direction="horizontal">
                            <Button size="small" onClick={() => setaditivos(true)} style={{ width: '100%' }}>
                              Aditivos
                            </Button>
                            <Descriptions bordered column={1}>
                              <Descriptions.Item label="Total">
                                {moneyMask.format(fichaImovel?.aditivos?.reduce((a, b) => a + (b.valor as number), 0))}
                              </Descriptions.Item>
                            </Descriptions>
                          </Space>
                          <Space direction="horizontal">
                            <Button size="small" onClick={() => setreceitas(true)} style={{ width: '100%' }}>
                              Receitas
                            </Button>
                            <Descriptions bordered column={1}>
                              <Descriptions.Item label="Total">
                                {moneyMask.format(fichaImovel?.receitas.reduce((a, b) => a + (b.valor as number), 0))}
                              </Descriptions.Item>
                              <Descriptions.Item label="Recebido">
                                {moneyMask.format(
                                  fichaImovel.receitas
                                    .filter((c) => c.pago)
                                    .reduce((a, b) => a + (b.valor as number), 0)
                                )}
                              </Descriptions.Item>
                              <Descriptions.Item label="Pendente">
                                {moneyMask.format(
                                  fichaImovel.receitas
                                    .filter((c) => !c.pago)
                                    .reduce((a, b) => a + (b.valor as number), 0)
                                )}
                              </Descriptions.Item>
                              <Descriptions.Item label="Vencido">
                                {moneyMask.format(
                                  fichaImovel.receitas
                                    .filter((c) => !c.pago && moment(c.data) < moment())
                                    .reduce((a, b) => a + (b.valor as number), 0)
                                )}
                              </Descriptions.Item>
                            </Descriptions>
                          </Space>
                        </Space>
                      </>
                    )
                  }
                </Form.Item>
                <Modal
                  title="Aditivos"
                  open={aditivos}
                  width={600}
                  onCancel={() => setaditivos(false)}
                  forceRender={true}
                  footer={[<Button onClick={() => setaditivos(false)}>Fechar</Button>]}
                >
                  <Form.List name="aditivos">
                    {(fields, { add, remove }) => (
                      <div>
                        {fields.map((_, aditivoindex) => (
                          <Row gutter={[8, 8]} align="middle" key={aditivoindex}>
                            <Col span={6}>
                              <Form.Item name={[aditivoindex, 'data']} label="Data" initialValue={moment(new Date())}>
                                <DatePicker format="DD/MM/YYYY" />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                name={[aditivoindex, 'valor']}
                                label="Valor"
                                initialValue={moneyMask.format(0)}
                                normalize={(value) => moneyMask.format(moneyMask.normalize(value))}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col span={9}>
                              <Form.Item name={[aditivoindex, 'notas']} label="Notas">
                                <Input width="100%" />
                              </Form.Item>
                            </Col>
                            <Col span={2}>
                              <Button
                                size="small"
                                style={{ position: 'relative' }}
                                icon={<MinusOutlined />}
                                onClick={() => remove(aditivoindex)}
                              />
                            </Col>
                          </Row>
                        ))}
                        <Button
                          style={{ width: '100%' }}
                          size="small"
                          icon={<PlusOutlined />}
                          onClick={() => add({ data: moment(new Date()), valor: moneyMask.format(0) })}
                        />
                      </div>
                    )}
                  </Form.List>
                </Modal>
                <Modal
                  title="Receitas"
                  open={receitas}
                  width={600}
                  onCancel={() => setreceitas(false)}
                  forceRender={true}
                  footer={[<Button onClick={() => setreceitas(false)}>Fechar</Button>]}
                >
                  <Form.List name="receitas">
                    {(fields, { add, remove }) => (
                      <div>
                        {fields.map((_, receitaindex) => (
                          <Row gutter={[8, 8]} align="middle" key={receitaindex}>
                            <Col span={5}>
                              <Form.Item name={[receitaindex, 'data']} label="Data" initialValue={moment(new Date())}>
                                <DatePicker format="DD/MM/YYYY" />
                              </Form.Item>
                            </Col>
                            <Col span={5}>
                              <Form.Item
                                name={[receitaindex, 'valor']}
                                label="Valor"
                                initialValue={moneyMask.format(0)}
                                normalize={(value) => moneyMask.format(moneyMask.normalize(value))}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item name={[receitaindex, 'notas']} label="Notas">
                                <Input width="100%" />
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <Form.Item name={[receitaindex, 'pago']} label="Pago" initialValue={false}>
                                <Radio.Group>
                                  <Radio value={false}>Não</Radio>
                                  <Radio value={true}>Sim</Radio>
                                </Radio.Group>
                              </Form.Item>
                            </Col>
                            <Col span={2}>
                              <Button
                                size="small"
                                style={{ position: 'relative' }}
                                icon={<MinusOutlined />}
                                onClick={() => remove(receitaindex)}
                              />
                            </Col>
                          </Row>
                        ))}
                        <Button
                          style={{ width: '100%' }}
                          size="small"
                          icon={<PlusOutlined />}
                          onClick={() => add({ data: moment(new Date()), valor: moneyMask.format(0) })}
                        />
                      </div>
                    )}
                  </Form.List>
                </Modal>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane
              forceRender
              tab="Financeiro"
              key="5"
              disabled={!HasPermission('casa.financeiro.view', userpermissions)}
            >
              <div style={{ maxHeight: tabHeight, overflowY: 'auto' }}>
                <Form.Item label="Contrato Entrou" name="contratoEntrou" valuePropName="checked">
                  <Checkbox>Sim</Checkbox>
                </Form.Item>
                <Row gutter={8}>
                  <Col flex="1 1 60%">
                    <Form.Item
                      label="V. Distribuído"
                      name="distribuido"
                      normalize={(v) => moneyMask.format(moneyMask.normalize(v))}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col flex="0 1 40%">
                    <Form.Item label="Data Distribuição" name="dataDistribuido">
                      <DatePicker size="small" format="DD/MM/YYYY" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col flex="1 1 50%">
                    <Form.Item
                      label="Prod. Agência"
                      name="produtoAgencia"
                      normalize={(v) => moneyMask.format(moneyMask.normalize(v))}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col flex="1 1 50%">
                    <Form.Item
                      label="Taxa a Vista"
                      name="taxaAvista"
                      normalize={(v) => moneyMask.format(moneyMask.normalize(v))}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane forceRender tab="Arquivos" key="6">
              <div style={{ maxHeight: tabHeight, overflowY: 'auto' }}>
                <Permission permission="casa.file.upload">
                  <Upload
                    name="arquivo"
                    disabled={savingfiles}
                    style={{ display: 'inline' }}
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    multiple={true}
                    beforeUpload={(_, fileList) => {
                      upFile([...files, ...fileList]);
                      Promise.resolve(false);
                    }}
                    accept=".pdf,.jpg,.jpeg,.png,.dwg,.xls,.xlsx,.zip"
                    onRemove={(file) => {
                      Promise.resolve(false);
                      upFile(files.filter((f) => f !== file));
                    }}
                    fileList={files}
                  >
                    <Button icon={<UploadOutlined />} size="small">
                      Arquivos
                    </Button>
                  </Upload>
                  <Button
                    style={{ display: 'inline' }}
                    type="primary"
                    size="small"
                    loading={savingfiles}
                    onClick={() => {
                      const formdata = new FormData();
                      files.map((f) => formdata.append('files', f, f.name));
                      formdata.append('casaid', fichaImovel.id.toString());
                      dispatch(UploadFiles(formdata));
                      upFile([]);
                    }}
                  >
                    Enviar
                  </Button>
                </Permission>
                <div style={{ textAlign: 'right' }}>
                  <Input
                    style={{ width: 200 }}
                    onChange={(e) => setFilter(e.target.value)}
                    placeholder="Buscar"
                    allowClear
                  />
                </div>
                <Table
                  size="small"
                  rowKey="id"
                  loading={loadingcasa || loadingfiles}
                  dataSource={fichaImovel && filterobject(fichaImovel.arquivos, filter)}
                  pagination={{ pageSize: 5 }}
                  onChange={(paginantion, filters, sorter) => setFiltersArquivos({ paginantion, filters, sorter })}
                >
                  <Table.Column
                    width={100}
                    render={(_, row: Arquivo) => (
                      <div>
                        <Permission permission="casa.file.download">
                          <Button
                            onClick={() => dispatch(DownloadArquivo(row.id))}
                            icon={<DownloadOutlined />}
                            size="small"
                            loading={downloadingfile === row.id}
                          />
                        </Permission>
                        <Permission permission="casa.file.delete">
                          <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(DeleteArquivo(row.id))}>
                            <Button icon={<DeleteOutlined />} size="small" />
                          </Popconfirm>
                        </Permission>
                        <Button
                          icon={<EyeOutlined />}
                          size="small"
                          onClick={() => {
                            Axios.get('/api/v2/Casas/Download', { params: { fileid: row.id } }).then((res) => {
                              setPdfFile({ url: res.data, type: row.contentType });
                              setViewPdf(true);
                            });
                          }}
                        />
                      </div>
                    )}
                  />
                  <Table.Column
                    title="Nome do Arquivo"
                    dataIndex="fileName"
                    sorter={(a, b) => sortAlpha(a.fileName, b.fileName)}
                    {...getColumnSearchProps('fileName', 'string', 'Nome', filtersArquivos)}
                  />
                  <Table.Column
                    title="Data"
                    dataIndex="data"
                    sorter={(a: Arquivo, b: Arquivo) => orderDate(a.data, b.data)}
                    render={(text) => (moment(text).isValid() ? moment(text).format('DD/MM/YYYY') : '')}
                    {...getColumnSearchProps('data', 'daterange', 'Data', filtersArquivos)}
                  />
                  <Table.Column
                    title="Disponível Cliente"
                    dataIndex="disponivel"
                    render={(_, row: Arquivo) => (
                      <Checkbox
                        disabled={!HasPermission('casa.manage', user.permissions)}
                        checked={row.disponivel}
                        onChange={() => dispatch(DisponibilizarArquivoCliente(row.id, !row.disponivel))}
                      />
                    )}
                  />
                </Table>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane forceRender tab="Chamados" key="7">
              <div style={{ maxHeight: tabHeight, overflowY: 'auto' }}>
                <Permission key="1" permission="chamado.add">
                  <Button size="small" type="primary" loading={savingchamado} onClick={() => setFormChamado(true)}>
                    Abrir Chamado
                  </Button>
                  <Table
                    size="small"
                    rowKey="id"
                    pagination={{ pageSize: 5 }}
                    dataSource={fichaImovel && fichaImovel.chamados}
                    expandedRowRender={(row) => (
                      <Table size="small" dataSource={row.reclamacoes}>
                        <Table.Column title="Descrição" dataIndex="descricao" />
                        <Table.Column title="Situação" dataIndex="status" />
                        <Table.Column
                          title="Data Início"
                          dataIndex="dataInicio"
                          render={(text) => (moment(text).isValid() ? moment(text).format('DD/MM/YYYY') : '')}
                        />
                        <Table.Column
                          title="Data Término"
                          dataIndex="dataTermino"
                          render={(text) => (moment(text).isValid() ? moment(text).format('DD/MM/YYYY') : '')}
                        />
                        <Table.Column title="Solução" dataIndex="solucao" />
                      </Table>
                    )}
                  >
                    <Table.Column
                      render={(_, row: Chamado) => (
                        <Link to={`/Chamados/${row.id}`}>
                          <Button size="small">
                            <EyeOutlined />
                          </Button>
                        </Link>
                      )}
                    />
                    <Table.Column title="Nº" dataIndex="id" />
                    <Table.Column title="Concluído" dataIndex="concluido" render={(text) => (text ? 'Sim' : 'Não')} />
                    <Table.Column
                      title="Data Abertura"
                      dataIndex="dataChamado"
                      render={(text) => (moment(text).isValid() ? moment(text).format('DD/MM/YYYY') : '')}
                    />
                    <Table.Column
                      title="Data Visita"
                      dataIndex="dataVisita"
                      render={(text) => (moment(text).isValid() ? moment(text).format('DD/MM/YYYY') : '')}
                    />
                    <Table.Column
                      title="Data Conclusão"
                      dataIndex="dataConc"
                      render={(text) => (moment(text).isValid() ? moment(text).format('DD/MM/YYYY') : '')}
                    />
                  </Table>
                </Permission>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane forceRender tab="Ações" key="8">
              <div style={{ maxHeight: tabHeight, overflowY: 'auto' }}>
                <Space direction="vertical">
                  {HasRole(['engenharia', 'master', 'admin', 'contabilidade'], roles) && fichaImovel && (
                    <>
                      <Button
                        size="small"
                        type="primary"
                        ghost
                        onClick={() => window.open(`/TermoVistoriaEntrega/${fichaImovel.id}`, '_blank')}
                      >
                        Termo Vistoria/Entrega
                      </Button>
                      <Button
                        size="small"
                        type="primary"
                        ghost
                        onClick={() => window.open(`/TermoRevistoria/${fichaImovel.id}`, '_blank')}
                      >
                        Termo Revistoria
                      </Button>
                      <Button
                        size="small"
                        type="primary"
                        ghost
                        onClick={() => window.open(`/TermoChavesManual/${fichaImovel.id}`, '_blank')}
                      >
                        Termo Entrega de Chaves
                      </Button>
                      <Button size="small" type="primary" ghost onClick={() => dispatch(ShowGarantias())}>
                        Garantias
                      </Button>
                      <Garantias
                        garantias={fichaImovel.garantias}
                        data={moment(new Date())}
                        dataContrato={moment(fichaImovel.dataContrato)}
                      />
                    </>
                  )}
                  {HasPermission('casa.manage', user.permissions) && fichaImovel && (
                    <>
                      <Button
                        size="small"
                        type="primary"
                        ghost
                        onClick={() => dispatch(ShowClienteObra(fichaImovel.id, true))}
                      >
                        Acesso Clientes
                      </Button>
                      <AddClienteObraModal userCasas={fichaImovel.userCasas} />
                      <Button
                        size="small"
                        type="primary"
                        ghost
                        onClick={() => dispatch(ShowProgressoObra(fichaImovel.id, true))}
                      >
                        Progresso da obra
                      </Button>
                      <ProgressoObra />
                      <Button
                        size="small"
                        type="primary"
                        ghost
                        onClick={() => dispatch(ShowProgressoLegalizacao(fichaImovel.id, true))}
                      >
                        Progresso da Legalização
                      </Button>
                      <ProgressoLegalizacao />
                      <Button
                        size="small"
                        type="primary"
                        ghost
                        onClick={() => dispatch(FormGaleria(fichaImovel.id, true))}
                      >
                        Galerias
                      </Button>
                      <AddGaleriaModal id={fichaImovel.id} />
                    </>
                  )}
                </Space>
              </div>
            </Tabs.TabPane>
          </Tabs>
        </Form>
        <Modal
          open={formChamado}
          title="Novo Chamado"
          onCancel={() => setFormChamado(false)}
          footer={[
            <Button key="1" onClick={() => setFormChamado(false)}>
              Cancelar
            </Button>,
            <Button
              key="2"
              type="primary"
              loading={savingchamado}
              onClick={() => {
                chamadoForm.submit();
                //setFormChamado(false);
                //chamadoForm.resetFields();
              }}
            >
              Salvar
            </Button>,
          ]}
        >
          <NovoChamadoView form={chamadoForm} id={fichaImovel ? fichaImovel.id : null} />
        </Modal>
      </Skeleton>
    </Modal>
  );
}

export default FichaNova;
