import { DeleteAgencia, FormAgencia, GetAgencias, SalvarAgencia } from '@actions/agenciasActions';
import { ExtendedAction } from '@config/reduxStore';
import { Agencia } from '@models/Agencia';
import { createReducer } from '@reduxjs/toolkit';
import { notification } from 'antd';

interface AgenciasReducer {
  agencias: Agencia[];
  formview: boolean;
  agencia: number;
  loadingagencias: boolean;
  savingagencia: boolean;
  feedback: string;
}

var initialState: AgenciasReducer = {
  agencias: [],
  formview: false,
  agencia: null,
  loadingagencias: false,
  savingagencia: false,
  feedback: '',
};
export default createReducer(initialState, (builder) => {
  builder.addCase(FormAgencia, (state, action) => {
    return {
      ...state,
      formview: action.payload.open,
      agencia: action.payload.id,
    };
  });
  builder.addCase(GetAgencias.pending, (state) => {
    return {
      ...state,
      loadingagencias: true,
    };
  });
  builder.addCase(GetAgencias.rejected, (state) => {
    notification.error({ message: 'Erro ao carregar agências!' });
    return { ...state, loadingagencias: false };
  });
  builder.addCase(GetAgencias.fulfilled, (state, action: ExtendedAction) => {
    return { ...state, loadingagencias: false, agencias: action.payload.data };
  });
  builder.addCase(SalvarAgencia.pending, (state) => {
    return {
      ...state,
      savingagencia: true,
    };
  });
  builder.addCase(SalvarAgencia.rejected, (state) => {
    notification.error({ message: 'Erro ao salvar agência!' });
    return { ...state, savingagencia: false };
  });
  builder.addCase(SalvarAgencia.fulfilled, (state, action: ExtendedAction) => {
    notification.success({ message: 'Agência salva com sucesso!' });
    return {
      ...state,
      savingagencia: false,
      agencias: action.payload.data.novo
        ? [...state.agencias, action.payload.data.agencia]
        : state.agencias.map((c) => (c.id === action.payload.data.agencia.id ? action.payload.data.agencia : c)),
    };
  });
  builder.addCase(DeleteAgencia.pending, (state) => {
    return {
      ...state,
      loadingagencias: true,
    };
  });
  builder.addCase(DeleteAgencia.rejected, (state, action: ExtendedAction) => {
    notification.error({ message: action.payload.response.data.Message });
    return { ...state, loadingagencias: false };
  });
  builder.addCase(DeleteAgencia.fulfilled, (state, action: ExtendedAction) => {
    notification.success({ message: 'Agência excluída com sucesso!' });
    return {
      ...state,
      loadingagencias: false,
      agencias: state.agencias.filter((c) => c.id !== action.payload.data),
    };
  });
});
