import { Button, Input, Steps, Table, Tooltip, Select } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { GetAprovadores, SetPageHeader } from '@actions/userActions';
import { filterobject, getColumnSearchProps, orderDate, sortAlpha } from '@utils/filterandsort';
import { HasPermission, Permission } from '@hooks//usePermissions';
import { EditOutlined, EyeOutlined, StopOutlined, PrinterOutlined } from '@ant-design/icons';
import moment from 'moment';
import { GetFolhasList, ViewCancelForm } from '@actions/folhasActions';
import { moneyMask } from '@utils/masks';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { ColumnsType } from 'antd/lib/table';
import { FolhaList } from '@models/Folha';
import ExportFolhasModal from './ExportFolhasModal';
import CancelFolhaModal from '../Shared/CancelFolhaModal';
import { OrdemStatus, ordemType } from '@constants/ordemStatus';
import FolhaStatusTag from '../FolhaForm/Shared/FolhaStatusTag';
import { getColaboradores } from '@actions/colaboradoresActions';

function FolhasView() {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const [filter, setFilter] = useState('');
  const [filters, setFilters] = useState(null);
  const { folhasList, loadingFolhasList } = useAppSelector((state) => state.folhas);
  const { aprovadores } = useAppSelector((state) => state.user);
  const { colaboradores, loadingColaboradores } = useAppSelector((state) => state.colaboradores);
  useEffect(() => {
    dispatch(GetFolhasList(null));
    dispatch(getColaboradores(true));
    dispatch(GetAprovadores(ordemType.folha));
    dispatch(
      SetPageHeader({
        title: 'Engenharia',
        backbutton: true,
        extras: [
          <ExportFolhasModal key="0" />,
          <Link to={`/DashboardFolhas`}>
            <Button type="primary">Dashboard Folhas</Button>
          </Link>,
          <Permission key="1" permission="folha.add">
            <Link to={`/NovaFolha`}>
              <Button hidden type="primary">
                Novo Adiantamento
              </Button>
            </Link>
          </Permission>,
          <Permission key="2" permission="folha.add">
            <Link to={`/NovaFolhaMedicao/0`}>
              <Button type="primary">Nova Folha</Button>
            </Link>
          </Permission>,
        ],
        subtitle: 'Folhas',
        menu: 'folhas',
      })
    );
  }, [dispatch]);
  const columns: ColumnsType<FolhaList> = [
    {
      width: 100,
      fixed: 'left',
      render: (_, row) => (
        <div>
          <Link to={`/Folhas/${row.id}`}>
            <Button size="small" icon={<EyeOutlined />} />
          </Link>
          <Link to={`/PrintFolha/${row.id}`} target="_blank">
            <Button size="small" icon={<PrinterOutlined />} />
          </Link>
          {!row.cancelada && (
            <>
              {row.situacao === OrdemStatus.Rascunho && (
                <Link to={row.tipo === 0 ? `/NovaFolha/${row.id}` : `/NovaFolhaMedicao/4/${row.id}`}>
                  <Button size="small" icon={<EditOutlined />} />
                </Link>
              )}
              {(!(
                row.situacao === OrdemStatus.Finalizada ||
                row.situacao === OrdemStatus.PendenteMedicao ||
                row.situacao === OrdemStatus.PendentePagamento
              ) ||
                (HasPermission('ordem.cancel', user.permissions) && row.situacao !== OrdemStatus.Finalizada)) && (
                <Tooltip title="Cancelar Folha">
                  <Button
                    icon={<StopOutlined />}
                    size="small"
                    onClick={() => {
                      dispatch(ViewCancelForm({ id: row.id, view: true }));
                    }}
                  />
                </Tooltip>
              )}
            </>
          )}
        </div>
      ),
    },
    {
      title: 'Nº Folha',
      dataIndex: 'cod',
      sorter: (a, b) => sortAlpha(a.cod, b.cod),
      render: (_, row) => row.cod,
      ...getColumnSearchProps('cod', 'string', 'ID', filters),
    },
    {
      title: 'Situação',
      dataIndex: 'situacao',
      width: 170,
      sorter: (a, b) => sortAlpha(a.situacao, b.situacao),
      filters: [
        { value: OrdemStatus.Rascunho, text: OrdemStatus.Rascunho },
        { value: OrdemStatus.PendenteAprovacao, text: OrdemStatus.PendenteAprovacao },
        { value: OrdemStatus.PendenteMedicao, text: OrdemStatus.PendenteMedicao },
        { value: OrdemStatus.PendentePagamento, text: OrdemStatus.PendentePagamento },
        { value: OrdemStatus.Finalizada, text: OrdemStatus.Finalizada },
        { value: OrdemStatus.Cancelada, text: OrdemStatus.Cancelada },
      ],
      filteredValue: filters ? filters.filters.situacao : null,
      onFilter: (value, row) => row.situacao === value,
      render: (v) => <FolhaStatusTag status={v} />,
    },
    {
      title: 'Aprovação',
      dataIndex: 'id',
      width: 160,
      render: (_, row) => (
        <Steps direction="vertical" size="small">
          <Steps.Step
            title={
              row.aprovacao1 ? (
                row.aprovador1
              ) : (
                <div>
                  {aprovadores.filter((c) => !c.aprovaPedido).length > 0
                    ? aprovadores
                        .filter((c) => !c.aprovaPedido)
                        .filter((c) => c.nivelFolhas === 1)
                        .map((c) => <div>{c.nome}</div>)
                    : null}
                </div>
              )
            }
            status={row.aprovacao1 ? 'finish' : 'wait'}
          />
          <Steps.Step
            title={
              row.aprovacao2 ? (
                row.aprovador2
              ) : (
                <div>
                  {aprovadores.filter((c) => !c.aprovaPedido).length > 0
                    ? aprovadores
                        .filter((c) => !c.aprovaPedido)
                        .filter((c) => c.nivelFolhas === 2)
                        .map((c) => <div>{c.nome}</div>)
                    : null}
                </div>
              )
            }
            status={row.aprovacao2 ? 'finish' : 'wait'}
          />
          <Steps.Step
            title={
              row.aprovacao3 ? (
                row.aprovador3
              ) : (
                <div>
                  {aprovadores.filter((c) => !c.aprovaPedido).length > 0
                    ? aprovadores
                        .filter((c) => !c.aprovaPedido)
                        .filter((c) => c.nivelFolhas === 3)
                        .map((c) => <div>{c.nome}</div>)
                    : null}
                </div>
              )
            }
            status={row.aprovacao3 ? 'finish' : 'wait'}
          />
        </Steps>
      ),
    },
    {
      title: 'Descricao',
      dataIndex: 'descricao',
      sorter: (a, b) => sortAlpha(a.descricao, b.descricao),
      ...getColumnSearchProps('descricao', 'string', 'Descrição', filters),
    },
    {
      title: 'Tipo',
      dataIndex: 'tipo',
      sorter: (a, b) => a.tipo - b.tipo,
      filters: [
        { value: 0, text: 'Adiantamento' },
        { value: 1, text: 'Medição' },
      ],
      filteredValue: filters ? filters.filters.tipo : null,
      render: (text) => (text === 0 ? 'Adiantamento' : 'Medição'),
      onFilter: (value, row) => row.tipo === value,
    },
    {
      title: 'Data Criação',
      dataIndex: 'dataCriacao',
      sorter: (a, b) => orderDate(a.dataCriacao, b.dataCriacao),
      ...getColumnSearchProps('dataCriacao', 'daterange', 'Data de Criação', filters),
      render: (text) => (moment(text).isValid() ? moment(text).format('DD/MM/YYYY') : ''),
    },
    {
      title: 'Mês',
      dataIndex: 'mesCorrente',
      sorter: (a, b) => orderDate(a.mesCorrente, b.mesCorrente),
      ...getColumnSearchProps('fim', 'daterange', 'Fim', filters),
      render: (text) => (moment(text).isValid() ? moment(text).format('MM/YYYY') : ''),
    },
    {
      title: 'Orçamento',
      dataIndex: 'orcamento',
      sorter: (a, b) => sortAlpha(a.orcamento, b.orcamento),
      ...getColumnSearchProps('orcamento', 'string', 'Orçamento', filters),
    },
    {
      title: 'Valor Total',
      dataIndex: 'total',
      sorter: (a, b) => a.total - b.total,
      ...getColumnSearchProps('total', 'string', 'Valor Total', filters),
      render: (text) => moneyMask.format(text),
    },
  ];
  return (
    <div>
      <CancelFolhaModal />
      <div style={{ textAlign: 'right' }}>
        <Select
          optionFilterProp="children"
          loading={loadingColaboradores}
          allowClear
          showSearch
          style={{ minWidth: 200 }}
          placeholder="Colaborador..."
          onChange={(v) => dispatch(GetFolhasList(v))}
        >
          {colaboradores.map((c) => (
            <Select.Option key={c.id}>{c.nome}</Select.Option>
          ))}
        </Select>
        <Input style={{ width: 200 }} onChange={(e) => setFilter(e.target.value)} placeholder="Buscar" allowClear />
      </div>
      <Table
        size="small"
        rowKey="id"
        bordered
        onChange={(paginantion, filters, sorter) => setFilters({ paginantion, filters, sorter })}
        loading={loadingFolhasList}
        columns={columns}
        dataSource={filterobject(folhasList, filter)}
        scroll={{ y: 'calc(100vh - 300px)', x: 1200 }}
        pagination={{
          position: ['bottomRight'],
          size: 'small',
          showSizeChanger: true,
          pageSizeOptions: [10, 25, 50, 100],
          showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
        }}
      />
    </div>
  );
}
export default FolhasView;
