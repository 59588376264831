import { useEffect } from 'react';
import { Form, Input, Select, DatePicker, Modal, Button } from 'antd';
import { useParams } from 'react-router-dom';
import { ShowLembrete, SendLembrete } from '@actions/chamadosActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

function Lembrete() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { responsaveis, lembretemodal, chamado, savinglembrete } = useAppSelector((state) => state.chamados);
  const [form] = Form.useForm();
  useEffect(() => {
    if (chamado) {
      form.setFieldsValue({
        para: chamado?.responsável,
        local: chamado?.endereco,
      });
    }
  }, [form, chamado, chamado?.responsável, chamado?.endereco]);
  return (
    <Modal
      title="Lembrete"
      open={lembretemodal}
      onCancel={() => dispatch(ShowLembrete())}
      footer={[
        <Button key="1" onClick={() => dispatch(ShowLembrete())}>
          Fechar
        </Button>,
        <Button type="primary" key="2" onClick={() => form.submit()} loading={savinglembrete}>
          Salvar
        </Button>,
      ]}
    >
      <Form
        wrapperCol={{ span: 18 }}
        form={form}
        labelCol={{ span: 6 }}
        name="lembreteForm"
        onFinish={(values) => dispatch(SendLembrete({ ...values, chamadoid: id }))}
      >
        <Form.Item name="para" label="Para" rules={[{ required: true, message: 'Campo obrigatório' }]}>
          <Select showSearch optionFilterProp="children">
            {responsaveis.map((c) => (
              <Select.Option value={c.id} key={c.id}>
                {c.nome}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="corpo" label="Mensagem">
          <Input.TextArea />
        </Form.Item>
        <Form.Item name="data" label="Quando" rules={[{ required: true, message: 'Campo obrigatório' }]}>
          <DatePicker format="DD/MM/YYYY HH:mm" showTime />
        </Form.Item>
        <Form.Item name="local" label="Local">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
export default Lembrete;
