import { OrdemStatus } from '@constants/ordemStatus';
import { HasRole } from '@hooks/usePermissions';
import { PedidoList } from '@models/Pedidos';
import { Button, Popconfirm, Space } from 'antd';
import { Link } from 'react-router-dom';
import { PrinterOutlined } from '@ant-design/icons';
import { useAppSelector } from '@config/reduxStore';
import { AprovarPedido } from '@services/api/api.pedidos';

function PedidoActions({ pedido }: { pedido: PedidoList }) {
  const { user } = useAppSelector((state) => state.user);
  const { pedidosPendentes } = useAppSelector((state) => state.pedidos);
  const aprovarPedido = AprovarPedido();
  return (
    <Space>
      {pedido?.situacao === OrdemStatus.Rascunho && (
        <Button size="small" type="primary">
          Submeter
        </Button>
      )}
      {pedido?.situacao === OrdemStatus.PendenteAprovacao &&
        HasRole(['aprovador'], user.roles) &&
        pedidosPendentes.map((c) => c.id).includes(pedido?.id) && (
          <Button size="small" type="primary" onClick={() => aprovarPedido.mutate(pedido?.id)}>
            Aprovar
          </Button>
        )}
      {pedido?.situacao === OrdemStatus.Aprovada && (
        <Button size="small" type="primary">
          Finalizar
        </Button>
      )}
      {(pedido?.situacao === OrdemStatus.Aprovada ||
        pedido?.situacao === OrdemStatus.Rascunho ||
        pedido?.situacao === OrdemStatus.EmProcessamentoFinanceiro) && (
        <Popconfirm title="Está certo disso?">
          <Button size="small" type="primary" danger>
            Cancelar
          </Button>
        </Popconfirm>
      )}
      <Link to={`/PrintPedido/${pedido?.id}`} target="_blank">
        <Button size="small" icon={<PrinterOutlined />}>
          Imprimir
        </Button>
      </Link>
    </Space>
  );
}
export default PedidoActions;
