import { Button, Modal, Spin, Table } from 'antd';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { getAuditoriasObra } from '@actions/auditoriasAction';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';
import { getColumnSearchProps, naturalSorter, orderDate } from '@utils/filterandsort';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

function AuditoriasModal({
  visible,
  setVisible,
  id,
}: {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  id: number;
}) {
  const { auditorias, loadingAuditorias } = useAppSelector((state) => state.auditorias);
  const dispatch = useAppDispatch();
  const [filters, setFilters] = useState<any>(null);
  useEffect(() => {
    if (id) dispatch(getAuditoriasObra(id));
  }, [id, dispatch]);
  return (
    <Modal
      open={visible}
      onCancel={() => setVisible(false)}
      title="Auditorias"
      footer={[<Button onClick={() => setVisible(false)}>Fechar</Button>]}
    >
      <Spin spinning={loadingAuditorias}>
        <Table
          size="small"
          dataSource={auditorias}
          rowKey="id"
          onChange={(pagination, filters, sorter) => setFilters({ pagination, filters, sorter })}
        >
          <Table.Column
            dataIndex="id"
            render={(v) => (
              <Link to={`/Auditorias/${v}`} target="_blank">
                <Button size="small" icon={<EyeOutlined />} />
              </Link>
            )}
          />
          <Table.Column
            title="Data"
            dataIndex="data"
            sorter={(a, b) => orderDate(a.data, b.data)}
            render={(v) => moment(v).format('DD/MM/YYYY')}
            {...getColumnSearchProps('data', 'daterange', 'Data', filters)}
          />
          <Table.Column
            title="Auditor"
            dataIndex="auditor"
            sorter={(a, b) => naturalSorter(a.auditor, b.auditor)}
            {...getColumnSearchProps('auditor', 'string', 'Auditor', filters)}
          />
        </Table>
      </Spin>
    </Modal>
  );
}

export default AuditoriasModal;
