import { Button, Col, Divider, Drawer, List, Row, Skeleton, Tooltip, Typography } from 'antd';
import InfinityScroll from 'react-infinite-scroll-component';
import { checkAllRead, getNotifications, setMessageRead, setMessageUnread } from '@actions/messagesActions';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { EyeOutlined, CheckCircleOutlined, CheckCircleFilled } from '@ant-design/icons';
import '../assets/scss/Notifications.scss';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Dispatch, SetStateAction } from 'react';

function Notifications({ visible, setVisible }: { visible: boolean; setVisible: Dispatch<SetStateAction<boolean>> }) {
  const { loadingNotifications, notifications, total, checkingRead } = useAppSelector((state) => state.messages);
  const dispatch = useAppDispatch();
  const loadMoreData = () => {
    if (loadingNotifications) return;
    dispatch(getNotifications({ take: 10, skip: notifications.length }));
  };
  return (
    <Drawer
      width={500}
      style={{ maxWidth: '100%' }}
      placement="right"
      open={visible}
      className="notifications"
      onClose={() => setVisible(false)}
      title="Notificações"
      extra={
        <Tooltip title="Marcar todos como lido">
          <Button
            type="text"
            loading={checkingRead}
            style={{ color: 'green', fontSize: 14 }}
            icon={<CheckCircleFilled />}
            onClick={() => dispatch(checkAllRead())}
          />
        </Tooltip>
      }
    >
      <div id="scrollableDiv" style={{ height: 'calc(100vh - 100px)', overflowY: 'scroll', padding: 8 }}>
        <InfinityScroll
          dataLength={notifications.length}
          next={loadMoreData}
          hasMore={notifications.length < total}
          loader={
            <Skeleton
              paragraph={{
                rows: 1,
              }}
              active
            />
          }
          endMessage={<Divider plain>Sem mais notificações 🤐</Divider>}
          scrollableTarget="scrollableDiv"
        >
          <List
            dataSource={notifications}
            renderItem={(item) => (
              <List.Item key={item.date.toString()}>
                <Row
                  align="middle"
                  gutter={[8, 8]}
                  wrap={false}
                  justify="space-between"
                  style={{ display: 'flex', width: '100%' }}
                >
                  <Col span={2}>
                    <Tooltip title={item.read ? 'Marcar como não lida' : 'Marcar como lida'}>
                      <CheckCircleOutlined
                        style={{ color: item.read ? 'green' : 'orange', fontSize: 16 }}
                        onClick={() =>
                          item.read ? dispatch(setMessageUnread(item.id)) : dispatch(setMessageRead(item.id))
                        }
                      />
                    </Tooltip>
                  </Col>
                  <Col span={20}>
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                      <Typography.Title level={5}>{item.title}</Typography.Title>
                      <Typography.Text>{item.message}</Typography.Text>
                      <Typography.Text>{item.who}</Typography.Text>
                      <Typography.Text>{moment(item.date).format('DD/MM/YYYY HH:mm')}</Typography.Text>
                    </div>
                  </Col>
                  <Col span={2}>
                    <div key={0} onClick={() => dispatch(setMessageRead(item.id))}>
                      <Link to={item.link}>
                        <EyeOutlined style={{ fontSize: 16 }} />
                      </Link>
                    </div>
                  </Col>
                </Row>
              </List.Item>
            )}
          />
        </InfinityScroll>
      </div>
    </Drawer>
  );
}

export default Notifications;
