import { Button, Card, Col, Row, Space, Typography } from 'antd';
import { AndroidFilled, AppleFilled } from '@ant-design/icons';
import useFacilitar from '@hooks/useFacilitar';
import LoginView from '@views/Account/LoginView';

function WelcomeView() {
  const facilitar = useFacilitar();
  return (
    <div>
      <Typography.Title style={{ margin: 40 }} level={2}>
        Seja bem-vindo ao {facilitar ? 'Facilitar Gestão' : 'Elevar Gestão'}
      </Typography.Title>
      {facilitar && <LoginView />}
      {!facilitar && (
        <div>
          <Row style={{ marginBottom: 8 }} justify="center">
            <Col flex="1 1 443px">
              <Card title="Sistema" size="small">
                <Space direction="vertical">
                  <Typography.Title level={5}>
                    O Elevar Gestão é um sistema completamente online desenhado para controle de processos internos da
                    empresa do setor de construção civil.
                  </Typography.Title>
                  <Typography.Text italic>Controle de orçamentos</Typography.Text>
                  <Typography.Text italic>Controle de pagamentos</Typography.Text>
                  <Typography.Text italic>Cadastro de imóveis</Typography.Text>
                  <Typography.Text italic>Acompanhamento de obra para clientes</Typography.Text>
                  <Typography.Text italic>Acompanhamento de empreendimentos para investidores</Typography.Text>
                  <Typography.Text italic>Correspondência Bancária</Typography.Text>
                  <Typography.Text italic>Assistência técnica</Typography.Text>
                </Space>
              </Card>
            </Col>
          </Row>
          <Row gutter={[8, 8]} justify="center">
            <Col flex="1 1 300px">
              <Card title="Colaboradores" size="small" style={{ height: 120 }}>
                <Space direction="vertical">
                  <Typography.Text>Se você é um colaborador, solicite seu acesso à administração</Typography.Text>
                </Space>
              </Card>
            </Col>
            <Col flex="1 1 300px">
              <Card title="Clientes" size="small" style={{ height: 120 }}>
                <Space direction="vertical">
                  <Typography.Text>
                    Se você é cliente, solicite seu acesso à administração para poder acompanhar sua obra
                  </Typography.Text>
                </Space>
              </Card>
            </Col>
            <Col flex="1 1 300px">
              <Card title="Investidores" size="small" style={{ height: 120 }}>
                <Typography.Text>
                  Se você é um investidor, solicite seu acesso à administração para poder acompanhar a evolução dos
                  empreendimentos que você participa
                </Typography.Text>
              </Card>
            </Col>
          </Row>
          <Space direction="vertical" style={{ margin: 30 }}>
            <Typography.Text>Aplicativo para dispositivos móveis</Typography.Text>
            <Space direction="horizontal">
              <a href="https://apps.apple.com/br/app/elevar-gest%C3%A3o/id1610591155" target="_blank" rel="noreferrer">
                <Button type="ghost" icon={<AppleFilled />}>
                  App Store
                </Button>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.grupoelevar.elevargestao"
                target="_blank"
                rel="noreferrer"
              >
                <Button type="ghost" icon={<AndroidFilled />}>
                  Play Store
                </Button>
              </a>
            </Space>
          </Space>
        </div>
      )}
    </div>
  );
}

export default WelcomeView;
