import { Button, Col, DatePicker, Divider, Form, Image, Input, Radio, Row, Select, Spin, Upload } from 'antd';
import { useEffect, useState } from 'react';
import { SetPageHeader } from '@actions/userActions';
import moment from 'moment';
import { PlusOutlined, MinusOutlined, UploadOutlined } from '@ant-design/icons';
import {
  getAuditores,
  getDiario,
  getMestres,
  getObras,
  getResponsaveis,
  salvarDiario,
  saveFiles,
} from '@actions/diariosActions';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

function DiarioForm() {
  const dispatch = useAppDispatch();
  const [files, upFile] = useState([]);
  const { user } = useAppSelector((state) => state.user);
  const {
    loadingResponsaveis,
    responsaveis,
    loadingMestres,
    mestres,
    obras,
    loadingObras,
    savingDiario,
    diario,
    loadingDiario,
    savingFiles,
    loadingAuditores,
    auditores,
  } = useAppSelector((state) => state.diarios);
  const [form] = Form.useForm();
  const { id } = useParams();
  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Diario',
        backbutton: true,
        extras: [
          <Button type="primary" onClick={() => form.submit()}>
            Salvar
          </Button>,
        ],
        subtitle: 'Formulário de diario de obra',
        menu: 'diarios',
      })
    );
  }, [dispatch, form]);
  useEffect(() => {
    dispatch(getResponsaveis());
    dispatch(getMestres());
    dispatch(getObras());
    dispatch(getAuditores());
    if (id) dispatch(getDiario(id));
  }, []);
  useEffect(() => {
    if (user.roles.includes('mestre de obras')) {
      form.setFieldValue('mestreID', user.id);
      form.setFieldValue('auditorID', user.id);
    }
  }, [form]);
  useEffect(() => {
    if (diario) {
      form.setFieldsValue({ ...diario, data: moment(diario.data) });
    }
  }, [diario]);
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  return (
    <div style={{ height: 'calc(100vh - 140px)', overflowY: 'scroll' }}>
      <Spin spinning={loadingDiario || savingDiario}>
        <Form
          layout="vertical"
          form={form}
          onFinish={(values) => {
            dispatch(
              salvarDiario({
                ...values,
                values: JSON.stringify(values),
              })
            );
          }}
          initialValues={{ data: moment(new Date()) }}
        >
          <Form.Item hidden name="id">
            <Input />
          </Form.Item>
          <Divider>Informações Gerais</Divider>
          <Form.Item name="data" label="Data" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <DatePicker format="DD/MM/YYYY" />
          </Form.Item>
          <Form.Item name="auditorID" label="Auditor" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Select
              loading={loadingAuditores}
              optionFilterProp="children"
              showSearch
              disabled={user.roles.includes('mestre de obras')}
            >
              {auditores.map((c) => (
                <Select.Option key={c.id}>{c.nome}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="obraID" label="Obra" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Select loading={loadingObras} optionFilterProp="children" showSearch>
              {obras.map((c) => (
                <Select.Option key={c.id} value={c.id}>
                  {c.descricao}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="mestreID" label="Mestre de Obras" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Select
              loading={loadingMestres}
              optionFilterProp="children"
              showSearch
              disabled={user.roles.includes('mestre de obras')}
            >
              {mestres.map((c) => (
                <Select.Option key={c.id}>{c.nome}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="responsavelID"
            label="Coordenador"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
          >
            <Select loading={loadingResponsaveis} optionFilterProp="children" showSearch>
              {responsaveis.map((c) => (
                <Select.Option key={c.id}>{c.nome}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Divider>Situação da Obra</Divider>
          <Row gutter={[8, 8]}>
            <Col>
              <Form.Item name="chuva" label="Chuva?" initialValue={false}>
                <Radio.Group>
                  <Radio value={true}>Sim</Radio>
                  <Radio value={false}>Não</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="pessoalOcioso" label="Pessoas Ociosas" initialValue={false}>
                <Radio.Group>
                  <Radio value={true}>Sim</Radio>
                  <Radio value={false}>Não</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Divider>Equipe Presente</Divider>
          <Form.List name="equipe">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field) => (
                    <Row gutter={[8, 8]} align="bottom" wrap={false}>
                      <Col flex="1 1 60%">
                        <Form.Item name={[field.name, 'nome']}>
                          <Input placeholder="Nome" />
                        </Form.Item>
                      </Col>
                      <Col flex="1 1 30%">
                        <Form.Item name={[field.name, 'funcao']}>
                          <Select placeholder="Função" style={{ width: 150 }} optionFilterProp="children" showSearch>
                            <Select.Option value="Pedreiro">Pedreiro</Select.Option>
                            <Select.Option value="Servente">Servente</Select.Option>
                            <Select.Option value="Mestre">Mestre</Select.Option>
                            <Select.Option value="Encanador">Encanador</Select.Option>
                            <Select.Option value="Eletricista">Eletricista</Select.Option>
                            <Select.Option value="Pintor">Pintor</Select.Option>
                            <Select.Option value="Carpinteiro">Carpinteiro</Select.Option>
                            <Select.Option value="Betoneiro">Betoneiro</Select.Option>
                            <Select.Option value="Encarregado">Encarregado</Select.Option>
                            <Select.Option value="Outro">Outro</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col flex="1 1 10%">
                        <Form.Item>
                          <Button icon={<MinusOutlined />} onClick={() => remove(field.name)} />
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                  <Button icon={<PlusOutlined />} onClick={() => add()} type="primary">
                    Pessoa
                  </Button>
                </div>
              );
            }}
          </Form.List>
          <Divider>Serviço em Execução</Divider>
          <Form.List name="servicos">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field) => (
                    <Row gutter={[8, 8]} align="bottom">
                      <Col flex="1 1 40%">
                        <Form.Item name={[field.name, 'servico']}>
                          <Input placeholder="Serviço" />
                        </Form.Item>
                      </Col>
                      <Col flex="1 1 50%">
                        <Form.Item name={[field.name, 'notas']}>
                          <Input placeholder="Observações" />
                        </Form.Item>
                      </Col>
                      <Col flex="1 1 10%">
                        <Form.Item>
                          <Button icon={<MinusOutlined />} onClick={() => remove(field.name)} />
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                  <Button icon={<PlusOutlined />} onClick={() => add()} type="primary">
                    Serviço
                  </Button>
                </div>
              );
            }}
          </Form.List>
          <Divider>Serviço Concluídos</Divider>
          <Form.List name="concluidos">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field) => (
                    <Row gutter={[8, 8]} align="bottom">
                      <Col flex="1 1 40%">
                        <Form.Item name={[field.name, 'servico']}>
                          <Input placeholder="Serviço" />
                        </Form.Item>
                      </Col>
                      <Col flex="1 1 50%">
                        <Form.Item name={[field.name, 'notas']}>
                          <Input placeholder="Observações" />
                        </Form.Item>
                      </Col>
                      <Col flex="1 1 10%">
                        <Form.Item>
                          <Button icon={<MinusOutlined />} onClick={() => remove(field.name)} />
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                  <Button icon={<PlusOutlined />} onClick={() => add()} type="primary">
                    Serviço
                  </Button>
                </div>
              );
            }}
          </Form.List>
          <Divider>Recebimento de Materiais</Divider>
          <Form.List name="materiais">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field) => (
                    <Row gutter={[8, 8]} align="bottom">
                      <Col flex="1 1 40%">
                        <Form.Item name={[field.name, 'material']}>
                          <Input placeholder="Material" />
                        </Form.Item>
                      </Col>
                      <Col flex="1 1 50%">
                        <Form.Item name={[field.name, 'notas']}>
                          <Input placeholder="Observações" />
                        </Form.Item>
                      </Col>
                      <Col flex="1 1 10%">
                        <Form.Item>
                          <Button icon={<MinusOutlined />} onClick={() => remove(field.name)} />
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                  <Button icon={<PlusOutlined />} onClick={() => add()} type="primary">
                    Material
                  </Button>
                </div>
              );
            }}
          </Form.List>
          <Divider>Pendências</Divider>
          <Form.List name="pendencias">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field) => (
                    <Row gutter={[8, 8]} align="bottom">
                      <Col flex="1 1 40%">
                        <Form.Item name={[field.name, 'pendencia']}>
                          <Input placeholder="Pendência" />
                        </Form.Item>
                      </Col>
                      <Col flex="1 1 50%">
                        <Form.Item name={[field.name, 'notas']}>
                          <Input placeholder="Observações" />
                        </Form.Item>
                      </Col>
                      <Col flex="1 1 10%">
                        <Form.Item>
                          <Button icon={<MinusOutlined />} onClick={() => remove(field.name)} />
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                  <Button icon={<PlusOutlined />} onClick={() => add()} type="primary">
                    Pendência
                  </Button>
                </div>
              );
            }}
          </Form.List>
          <Divider>Ocorrências</Divider>
          <Form.List name="ocorrencias">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field) => (
                    <Row gutter={[8, 8]} align="bottom">
                      <Col flex="1 1 90%">
                        <Form.Item name={[field.name, 'ocorrencia']}>
                          <Input placeholder="Ocorrência" />
                        </Form.Item>
                      </Col>
                      <Col flex="1 1 10%">
                        <Form.Item>
                          <Button icon={<MinusOutlined />} onClick={() => remove(field.name)} />
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                  <Button icon={<PlusOutlined />} onClick={() => add()} type="primary">
                    Ocorrência
                  </Button>
                </div>
              );
            }}
          </Form.List>
          <Divider>Observações Gerais</Divider>
          <Form.Item name="notas">
            <Input.TextArea rows={5} />
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) =>
              (getFieldValue('id') !== undefined || id) && (
                <div>
                  {diario?.fotos && (
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px 8px' }}>
                      <Divider>Fotos</Divider>
                      <Image.PreviewGroup>
                        {diario.fotos.map((f) => (
                          <Image src={f.url} alt={f.fileName} width={80} key={f.fileName} />
                        ))}
                      </Image.PreviewGroup>
                    </div>
                  )}
                  <Form.Item label="Imagens" name="arquivos" valuePropName="fileList" getValueFromEvent={normFile}>
                    <Upload
                      name="arquivo"
                      disabled={savingFiles}
                      style={{ display: 'inline' }}
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      multiple={true}
                      beforeUpload={(_, fileList) => {
                        upFile([...files, ...fileList]);
                        Promise.resolve(false);
                      }}
                      accept=".jpg,.jpeg,.png"
                      onRemove={(file) => {
                        Promise.resolve(false);
                        upFile(files.filter((f) => f !== file));
                      }}
                      fileList={files}
                    >
                      <Button icon={<UploadOutlined />} size="small">
                        Arquivos
                      </Button>
                    </Upload>
                    <Button
                      style={{ display: 'inline' }}
                      type="primary"
                      size="small"
                      loading={savingFiles}
                      onClick={() => {
                        const formdata = new FormData();
                        files.map((f) => formdata.append('files', f, f.name));
                        formdata.append('diarioid', form.getFieldValue('id'));
                        dispatch(saveFiles(formdata));
                        upFile([]);
                      }}
                    >
                      Enviar
                    </Button>
                  </Form.Item>
                </div>
              )
            }
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
}

export default DiarioForm;
