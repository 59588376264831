import { AppDispatch } from '@config/reduxStore';
import { apiEndpoints } from '@constants/apiEndpoints';
import { User } from '@models/User';
import { Axios } from '@config/axios';

export const GetUsuarios = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_USUARIOS',
      payload: Axios.get(apiEndpoints.USUARIOS.GET_USUARIOS),
    });
  };
};
export const GetRoles = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_ROLES',
      payload: Axios.get('/api/v2/Role/GetRoles'),
    });
  };
};
export const AddUserToRole = function (role: string, userid: string) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'ADD_USER_TO_ROLE_PENDING',
    });
    Axios.post(apiEndpoints.USUARIOS.ADD_USER_TO_ROLE, { role, userid })
      .then((res) => {
        dispatch({
          type: 'ADD_USER_TO_ROLE_FULFILLED',
          payload: {
            role: role,
            userid: userid,
            added: res.data,
          },
        });
      })
      .catch(() => {
        Axios.post(apiEndpoints.USUARIOS.ADD_USER_TO_ROLE, { role }).then((res) => {
          dispatch({
            type: 'ADD_USER_TO_ROLE_REJECTED',
            payload: res,
          });
        });
      });
  };
};

export const SalvarUsuario = function (values: User) {
  return function (dispatch: AppDispatch) {
    if (values.id) {
      dispatch({
        type: 'UPDATE_USUARIO',
        payload: Axios.post(apiEndpoints.USUARIOS.UPDATE_USUARIO, values),
      });
    } else {
      dispatch({
        type: 'ADD_USUARIO',
        payload: Axios.post(apiEndpoints.USUARIOS.ADD_USER, values),
      });
    }
  };
};
export const DeleteUsuario = function (id: string) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DELETE_USUARIO',
      payload: Axios.get(apiEndpoints.USUARIOS.DELETE_USER, { params: { id } }),
    });
  };
};
export const FormUsuario = function (id: number | string, open: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'FORM_USUARIO',
      payload: { id, open },
    });
  };
};
