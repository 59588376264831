import { ViewCancelForm } from '@actions/novaOrdemActions';
import { CancelarTransferencia } from '@actions/transferenciasActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Form, Input, Modal } from 'antd';

type FormData = {
  motivo: string;
};

function CancelTransferenciaModalRedux() {
  const dispatch = useAppDispatch();
  const { cancelingOrdem, cancelForm } = useAppSelector((state) => state.novaOrdem);
  const [form] = Form.useForm<FormData>();
  const handleSubmit = (values: FormData) => {
    dispatch(CancelarTransferencia({ id: cancelForm.id, motivo: values.motivo }));
  };
  return (
    <Modal
      open={cancelForm.visible}
      title="Razão do cancelamento/substituição"
      onCancel={() => {
        dispatch(ViewCancelForm({ id: null, view: false, replace: false, cod: null }));
      }}
      okButtonProps={{ loading: cancelingOrdem }}
      onOk={() => form.submit()}
    >
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item rules={[{ required: true, message: 'Campo obrigatório' }]} name="motivo">
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
}
export default CancelTransferenciaModalRedux;
