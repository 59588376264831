import { useEffect, useState } from 'react';
import { Modal, Form, Input, Button, Divider, Row, Col, Select, Checkbox, Table, Popconfirm } from 'antd';
import { cnpjMask, mobMask, cpfMask, fixoMask, cepMask } from '@utils/masks';
import moment from 'moment';
import { deleteContrato, formColaborador, salvarColaborador } from '@actions/colaboradoresActions';
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import ContratosModal from './ContratosModal';
import { Permission } from '@hooks//usePermissions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Fornecedor } from '@models/Fornecedor';
import { ContratoFornecedor } from '@models/ContratoFornecedor';

function ColaboradorForm() {
  const { colaborador, colaboradores, formview, savingColaborador } = useAppSelector((state) => state.colaboradores);
  const [form] = Form.useForm<Fornecedor>();
  const dispatch = useAppDispatch();
  const [contratoModal, setContratoModal] = useState({ id: null, visible: false, fornecedorID: null });
  const submitForm = (values: Fornecedor) => {
    dispatch(salvarColaborador(values));
  };
  useEffect(() => {
    form.resetFields();
    if (colaborador) {
      form.setFieldsValue({
        ...colaboradores.find((c) => c.id === colaborador),
      });
    }
  }, [colaborador, colaboradores, form]);
  return (
    <div>
      <Modal
        open={formview}
        onCancel={() => dispatch(formColaborador(null, false))}
        title="Cadastro Fornecedor"
        footer={[
          <Button onClick={() => dispatch(formColaborador(null, false))} key="2">
            Fechar
          </Button>,
          <Permission key="3" permission="colaborador.update">
            <Button type="primary" onClick={() => form.submit()} loading={savingColaborador}>
              Salvar
            </Button>
          </Permission>,
        ]}
      >
        <div style={{ height: 'calc(100vh - 300px)', overflowY: 'scroll' }}>
          <Form
            layout="vertical"
            form={form}
            scrollToFirstError
            onFinish={submitForm}
            name="fornecedorForm"
            initialValues={{ ativo: true, colaborador: true }}
          >
            <Form.Item style={{ display: 'none' }} name="id">
              <Input />
            </Form.Item>
            <Form.Item name="ativo" valuePropName="checked">
              <Checkbox>Ativo</Checkbox>
            </Form.Item>
            <Form.Item
              label="CPF/CNPJ"
              name="cpF_CNPJ"
              normalize={(v) => {
                if (v.length > 14) return cnpjMask.format(cnpjMask.normalize(v));
                else return cpfMask.format(cpfMask.normalize(v));
              }}
            >
              <Input />
            </Form.Item>
            <Row gutter={[8, 8]}>
              <Col span={16}>
                <Form.Item label="Nome" name="nome" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="funcao" label="Função" rules={[{ required: true, message: 'Campo Obrigatório' }]}>
                  <Select showSearch optionFilterProp="children">
                    <Select.Option value="Pedreiro">Pedreiro</Select.Option>
                    <Select.Option value="Servente">Servente</Select.Option>
                    <Select.Option value="Mestre de Obras">Mestre de Obras</Select.Option>
                    <Select.Option value="Ajudante">Ajudante</Select.Option>
                    <Select.Option value="Pintor">Pintor</Select.Option>
                    <Select.Option value="Eletricista">Eletricista</Select.Option>
                    <Select.Option value="Instalações">Instalações</Select.Option>
                    <Select.Option value="Carpinteiro">Carpinteiro</Select.Option>
                    <Select.Option value="Gesseiro">Gesseiro</Select.Option>
                    <Select.Option value="Revestimentos">Revestimentos</Select.Option>
                    <Select.Option value="Armador">Armador</Select.Option>
                    <Select.Option value="Encanador">Encanador</Select.Option>
                    <Select.Option value="Piscineiro">Piscineiro</Select.Option>
                    <Select.Option value="Encarregado">Encarregado</Select.Option>
                    <Select.Option value="Outro">Outro</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item label="Endereço" name="endereco">
              <Input />
            </Form.Item>
            <Row gutter={8}>
              <Col span={8}>
                <Form.Item label="Cidade" name="cidade">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Estado" name="estado">
                  <Select showSearch>
                    <Select.Option value="Acre">Acre</Select.Option>
                    <Select.Option value="Alagoas">Alagoas</Select.Option>
                    <Select.Option value="Amazonas">Amazonas</Select.Option>
                    <Select.Option value="Amapá">Amapá</Select.Option>
                    <Select.Option value="Bahia">Bahia</Select.Option>
                    <Select.Option value="Ceará">Ceará</Select.Option>
                    <Select.Option value="Distrito Federal">Distrito Federal</Select.Option>
                    <Select.Option value="Espírito Santo">Espírito Santo</Select.Option>
                    <Select.Option value="Goiás">Goiás</Select.Option>
                    <Select.Option value="Maranhão">Maranhão</Select.Option>
                    <Select.Option value="Mato Grosso">Mato Grosso</Select.Option>
                    <Select.Option value="Mato Grosso do Sul">Mato Grosso do Sul</Select.Option>
                    <Select.Option value="Minas Gerais">Minas Gerais</Select.Option>
                    <Select.Option value="Pará">Pará</Select.Option>
                    <Select.Option value="Paraíba">Paraíba</Select.Option>
                    <Select.Option value="Paraná">Paraná</Select.Option>
                    <Select.Option value="Pernambuco">Pernambuco</Select.Option>
                    <Select.Option value="Piauí">Piauí</Select.Option>
                    <Select.Option value="Rio de Janeiro">Rio de Janeiro</Select.Option>
                    <Select.Option value="Rio Grande do Norte">Rio Grande do Norte</Select.Option>
                    <Select.Option value="Rondônia">Rondônia</Select.Option>
                    <Select.Option value="Rio Grande do Sul">Rio Grande do Sul</Select.Option>
                    <Select.Option value="Roraima">Roraima</Select.Option>
                    <Select.Option value="Santa Catarina">Santa Catarina</Select.Option>
                    <Select.Option value="Sergipe">Sergipe</Select.Option>
                    <Select.Option value="São Paulo">São Paulo</Select.Option>
                    <Select.Option value="Tocantins">Tocantins</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="CEP" name="cep" normalize={(v) => cepMask.format(cepMask.normalize(v))}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={10}>
                <Form.Item
                  label="Telefone"
                  name="telefone"
                  normalize={(v) => {
                    if (v.length > 13) return mobMask.format(mobMask.normalize(v));
                    else return fixoMask.format(fixoMask.normalize(v));
                  }}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={14}>
                <Form.Item label="Email" name="email" rules={[{ type: 'email', message: 'Email inválido' }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Divider>Contratação</Divider>
            {!form.getFieldValue('id') && 'Cadastre o colaborador para inserir informações do contrato'}
            {form.getFieldValue('id') && (
              <div>
                <Table
                  size="small"
                  loading={savingColaborador}
                  dataSource={
                    colaboradores && colaborador && colaboradores.find((c) => c.id === colaborador)?.contratos
                  }
                >
                  <Table.Column title="Item" render={(_, __, i) => i + 1} />
                  <Table.Column
                    dataIndex="id"
                    render={(v, r: ContratoFornecedor) => (
                      <>
                        <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(deleteContrato(v))}>
                          <Button size="small" icon={<DeleteOutlined />} />
                        </Popconfirm>
                        <Button
                          size="small"
                          icon={<EditOutlined />}
                          onClick={() => setContratoModal({ ...r, visible: true })}
                        />
                      </>
                    )}
                  />
                  <Table.Column title="Tipo Cont." dataIndex="tipoContrato" />
                  <Table.Column
                    title="Admissão"
                    dataIndex="dataAdmissao"
                    render={(v) => (moment(v).isValid() ? moment(v).format('DD/MM/YYYY') : '')}
                  />
                  <Table.Column
                    title="Início"
                    dataIndex="dataInicio"
                    render={(v) => (moment(v).isValid() ? moment(v).format('DD/MM/YYYY') : '')}
                  />
                  <Table.Column
                    title="Fim"
                    dataIndex="dataFinal"
                    render={(v) => (moment(v).isValid() ? moment(v).format('DD/MM/YYYY') : '')}
                  />
                  <Table.Column title="Obra" dataIndex="obra" />
                </Table>
                <Form.Item name="colaborador" valuePropName="checked" hidden>
                  <Checkbox>Colaborador</Checkbox>
                </Form.Item>
                <ContratosModal {...contratoModal} setVisible={setContratoModal} />
                <Button
                  size="small"
                  icon={<PlusOutlined />}
                  onClick={() => setContratoModal({ visible: true, id: null, fornecedorID: colaborador })}
                  type="primary"
                  ghost
                >
                  Contrato
                </Button>
              </div>
            )}
            <Divider>Informações para pagamento</Divider>
            <Row gutter={8}>
              <Col span={8}>
                <Form.Item label="Banco" name="banco">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Agência" name="agencia">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Conta" name="contaBancaria">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Operação" name="operacao">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label="CPF/CNPJ"
              name="cpF_CNPJ_Conta"
              normalize={(v) => {
                if (v.length > 14) return cnpjMask.format(cnpjMask.normalize(v));
                else return cpfMask.format(cpfMask.normalize(v));
              }}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Nome Titular" name="nomeConta">
              <Input />
            </Form.Item>
            <Form.Item label="Pix" name="pix">
              <Input />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
}
export default ColaboradorForm;
