import { Table, Input, Button, Switch, Tooltip, Space } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { GetCliente, GetEngenharias, PerspectivaChange } from '@actions/correspondenciaActions';
import { filterobject, getColumnSearchProps, orderDate, sortAlpha } from '@utils/filterandsort';
import { ProfileOutlined, WarningTwoTone, ClockCircleTwoTone } from '@ant-design/icons';
import { SetPageHeader } from '@actions/userActions';
import moment from 'moment';
import PrintPage from '../PrintPage';
import ReactToPrint from 'react-to-print';
import FichaPop from './FichaPop';
import { GetLoteamentos } from '@actions/loteamentosActions';
import { GetCorretores } from '@actions/corretoresActions';
import { GetCCAs } from '@actions/ccasActions';
import { GetAgencias } from '@actions/agenciasActions';
import { GetModalidades } from '@actions/modalidadesActions';
import { GetParceiros } from '@actions/parceirosActions';
import { GetProdutos } from '@actions/produtosActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { ColumnsType } from 'antd/lib/table';
import { Engenharia } from '@models/Engenharias';
import { ClienteCorrespondencia } from '@models/ClienteCorrespondencia';

function EngenhariasView() {
  const dispatch = useAppDispatch();
  const { loadingclientes, engenharias } = useAppSelector((state) => state.correspondencia);
  const [filter, setFilter] = useState('');
  const [filters, setfilters] = useState(null);
  const [ficha, setFicha] = useState<{ ficha: ClienteCorrespondencia; visible: boolean }>({
    ficha: null,
    visible: false,
  });

  const printRef = useRef();
  const columns: ColumnsType<Engenharia> = [
    {
      align: 'center',
      width: 60,
      fixed: 'left',
      render: (_, __, index) => index + 1,
    },
    {
      align: 'center',
      width: 80,
      fixed: 'left',
      render: (_, row) => (
        <Space>
          <Tooltip title={`Vencimento da certidão: ${moment(row.dataCertidao).add(30, 'days').format('DD/MM/YYYY')}`}>
            {moment(row.dataCertidao).add(30, 'days').diff(moment(new Date()), 'days') <= 5 &&
            moment(row.dataCertidao).add(30, 'days').diff(moment(new Date()), 'days') >= 0 ? (
              <ClockCircleTwoTone twoToneColor="orange" />
            ) : moment(row.dataCertidao).add(30, 'days').diff(moment(new Date()), 'days') <= 5 ? (
              <WarningTwoTone twoToneColor="red" />
            ) : null}
          </Tooltip>
          <Tooltip title="Ficha do Cliente">
            <Button
              icon={<ProfileOutlined />}
              size="small"
              onClick={() => {
                setFicha({ visible: true, ficha: null });
                dispatch(GetCliente(row.id));
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
    {
      title: 'Assina esse mês',
      dataIndex: 'perspectiva',
      width: 110,
      align: 'center',
      sorter: (a: any, b: any) => a.perspectiva - b.perspectiva,
      ...getColumnSearchProps('perspectiva', 'string', 'Perspectiva', filters),
      render: (text, row) => (
        <Switch size="small" checked={text} onChange={() => dispatch(PerspectivaChange(row.id))} />
      ),
    },
    {
      title: 'Proponente',
      dataIndex: 'clienteNome1',
      width: 180,
      sorter: (a, b) => sortAlpha(a.clienteNome1, b.clienteNome1),
      ...getColumnSearchProps('clienteNome1', 'string', 'Proponente', filters),
    },
    {
      title: 'OS',
      dataIndex: 'os',
      width: 160,
      sorter: (a, b) => sortAlpha(a.os, b.os),
      ...getColumnSearchProps('os', 'string', 'OS', filters),
    },
    {
      title: 'Matrícula',
      dataIndex: 'matricula',
      width: 100,
      sorter: (a, b) => sortAlpha(a.matricula, b.matricula),
      ...getColumnSearchProps('matricula', 'string', 'Matrícula', filters),
    },
    {
      title: 'Município',
      dataIndex: 'municipio',
      width: 110,
      sorter: (a, b) => sortAlpha(a.municipio, b.municipio),
      ...getColumnSearchProps('municipio', 'string', 'Município', filters),
    },
    {
      title: 'Modalidade',
      dataIndex: 'modalidade',
      width: 110,
      sorter: (a, b) => sortAlpha(a.modalidade, b.modalidade),
      ...getColumnSearchProps('modalidade', 'string', 'Modalidade', filters),
    },
    {
      title: 'Parceiro',
      dataIndex: 'parceiro',
      width: 100,
      sorter: (a, b) => sortAlpha(a.parceiro, b.parceiro),
      ...getColumnSearchProps('parceiro', 'string', 'Parceiro', filters),
    },
    {
      title: 'Data de Abertura',
      dataIndex: 'dataSolicitacaoOS',
      width: 120,
      sorter: (a, b) => orderDate(a.dataSolicitacaoOS, b.dataSolicitacaoOS),
      ...getColumnSearchProps('dataSolicitacaoOS', 'daterange', 'Data de Abertura', filters),
      render: (text) => moment(text).format('DD/MM/YYYY'),
    },
    {
      title: 'Dias Passados',
      dataIndex: 'dataSolicitacaoOS',
      width: 120,
      align: 'center',
      sorter: (a, b) => orderDate(a.dataSolicitacaoOS, b.dataSolicitacaoOS),
      ...getColumnSearchProps('dataSolicitacaoOS', 'daterange', 'Data de Abertura', filters),
      render: (text) => moment(new Date()).diff(moment(text), 'd'),
    },
    {
      title: 'Observação OS',
      dataIndex: 'observacaoCasa',
      width: 130,
      sorter: (a, b) => sortAlpha(a.observacaoCasa, b.observacaoCasa),
      ...getColumnSearchProps('observacaoCasa', 'string', 'Observação OS', filters),
    },
  ];
  useEffect(() => {
    dispatch(GetEngenharias());
    dispatch(GetLoteamentos());
    dispatch(GetCorretores());
    dispatch(GetCCAs());
    dispatch(GetAgencias());
    dispatch(GetModalidades());
    dispatch(GetParceiros());
    dispatch(GetProdutos());
  }, []);

  useEffect(() => {
    dispatch(
      SetPageHeader({
        title: 'Correspondência',
        backbutton: true,
        extras: [
          <div>
            <ReactToPrint
              trigger={() => (
                <Button size="small" type="primary" ghost style={{ width: 100 }}>
                  Imprimir
                </Button>
              )}
              content={() => printRef.current}
            />
            <div style={{ display: 'none' }}>
              <PrintPage
                ref={printRef}
                title={`Perspectiva de Contratos a assinar no mês - ${moment(new Date()).format('DD/MM/YYYY')}`}
                columns={columns}
                dataSource={filterobject(engenharias, filter)}
              />
            </div>
          </div>,
        ],
        subtitle: 'Controle de Engenharias',
        menu: 'engenharias',
      })
    );
  }, [engenharias, printRef, filter, PrintPage]);

  return (
    <div>
      <FichaPop visible={ficha.visible} setVisible={setFicha} />
      <div style={{ textAlign: 'right' }}>
        <Input
          size="small"
          style={{ width: 400 }}
          onChange={(e) => setFilter(e.target.value)}
          placeholder="Buscar"
          allowClear
        />
      </div>
      <Table
        columns={columns}
        size="small"
        loading={loadingclientes}
        dataSource={filterobject(engenharias, filter)}
        onChange={(pagination, filters, sorter) => setfilters({ pagination, filters, sorter })}
        pagination={false}
        scroll={{ y: 'calc(100vh - 280px)', x: 1324 }}
        summary={(pagedata) => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} align="right" colSpan={2}>
                <strong>TOTAL OSs:</strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                <strong>{pagedata.length}</strong>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </div>
  );
}

export default EngenhariasView;
