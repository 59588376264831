import { AppDispatch } from '@config/reduxStore';
import { apiEndpoints } from '@constants/apiEndpoints';
import { Item } from '@models/Item';
import { Axios } from '@config/axios';
export const GetMateriais = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_MATERIAIS',
      payload: Axios.get(apiEndpoints.MATERIAIS.GET_MATERIAIS),
    });
  };
};
export const GetMateriaisServer = function (values: any) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_MATERIAIS_SERVER',
      payload: Axios.post(apiEndpoints.MATERIAIS.GET_MATERIAIS_SERVER, values),
    });
  };
};
export const GetMateriaisList = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_MATERIAIS_LIST',
      payload: Axios.get(apiEndpoints.MATERIAIS.GET_MATERIAIS_LIST),
    });
  };
};
export const SalvarMaterial = function (values: Item) {
  return function (dispatch: AppDispatch) {
    if (values.id) {
      dispatch({
        type: 'UPDATE_MATERIAL',
        payload: Axios.post(apiEndpoints.MATERIAIS.SALVAR_MATERIAL, { ...values }),
      });
    } else {
      dispatch({
        type: 'ADD_MATERIAL',
        payload: Axios.post(apiEndpoints.MATERIAIS.SALVAR_MATERIAL, { ...values }),
      });
    }
  };
};
export const DeleteMaterial = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DELETE_MATERIAL',
      payload: Axios.get(apiEndpoints.MATERIAIS.DELETE_MATERIAL, { params: { id } }),
    });
  };
};
export const FormMaterial = function (id: number, open: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'FORM_MATERIAL',
      payload: { id, open },
    });
  };
};
