import { AppDispatch } from '@config/reduxStore';
import { apiEndpoints } from '@constants/apiEndpoints';
import { Loteamento } from '@models/Loteamento';
import { Axios } from '@config/axios';
export const GetLoteamentos = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_LOTEAMENTOS',
      payload: Axios.get(apiEndpoints.LOTEAMENTOS.GET_LOTEAMENTOS),
    });
  };
};
export const SalvarLoteamento = function (values: Loteamento) {
  return function (dispatch: AppDispatch) {
    if (values.id) {
      dispatch({
        type: 'UPDATE_LOTEAMENTO',
        payload: Axios.post(apiEndpoints.LOTEAMENTOS.SALVAR_LOTEAMENTO, { ...values }),
      });
    } else {
      dispatch({
        type: 'ADD_LOTEAMENTO',
        payload: Axios.post(apiEndpoints.LOTEAMENTOS.SALVAR_LOTEAMENTO, { ...values }),
      });
    }
  };
};
export const DeleteLoteamento = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DELETE_LOTEAMENTO',
      payload: Axios.get(apiEndpoints.LOTEAMENTOS.DELETE_LOTEAMENTO, { params: { id } }),
    });
  };
};
export const FormLoteamento = function (id: number, open: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'FORM_LOTEAMENTO',
      payload: { id, open },
    });
  };
};
