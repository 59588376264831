import { useState, useEffect } from 'react';
import { Button, Popconfirm, Table, Modal, Upload } from 'antd';
import {
  ModalArquivoFornecedor,
  UploadFiles,
  DownloadArquivo,
  DeleteArquivo,
  GetArquivos,
} from '@actions/fornecedoresActions';
import { UploadOutlined, DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Arquivo } from '@models/Arquivo';

function FornecedorArquivos() {
  const dispatch = useAppDispatch();
  const { savingarquivo, arquivoview, fornecedor, loadingarquivos } = useAppSelector((state) => state.fornecedores);
  const arquivos = useAppSelector((state) => state.fornecedores.arquivos);
  const [files, upFile] = useState([]);
  useEffect(() => {
    dispatch(GetArquivos(fornecedor));
  }, [fornecedor]);
  return (
    <Modal
      open={arquivoview}
      title="Adicionar Arquivos do Fornecedor"
      onCancel={() => dispatch(ModalArquivoFornecedor(null, false))}
      footer={[
        <Button key="2" onClick={() => dispatch(ModalArquivoFornecedor(null, false))}>
          Fechar
        </Button>,
      ]}
    >
      <Upload
        name="arquivo"
        disabled={savingarquivo}
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        multiple={true}
        beforeUpload={(_, fileList) => {
          upFile([...files, ...fileList]);
          Promise.resolve(false);
        }}
        accept=".pdf,.jpg,.jpeg,.png,.xls,.xlsx"
        onRemove={(file) => {
          Promise.resolve(false);
          upFile(files.filter((f) => f !== file));
        }}
        fileList={files}
      >
        <Button icon={<UploadOutlined />} size="small">
          Escolher Arquivos
        </Button>
      </Upload>
      {files.length > 0 && (
        <Button
          type="primary"
          size="small"
          loading={savingarquivo}
          onClick={() => {
            const formdata = new FormData();
            files.map((f) => formdata.append('files', f, f.name));
            formdata.append('fornecedorid', fornecedor.toString());
            dispatch(UploadFiles(formdata));
            upFile([]);
          }}
        >
          Enviar
        </Button>
      )}

      <Table size="small" rowKey="id" loading={loadingarquivos} dataSource={arquivos} pagination={{ pageSize: 5 }}>
        <Table.Column
          render={(_, row: Arquivo) => (
            <div>
              <Button
                onClick={() => dispatch(DownloadArquivo(row.id, row.fileName))}
                icon={<DownloadOutlined />}
                size="small"
              />
              <Popconfirm title="Está certo disso?" onConfirm={() => dispatch(DeleteArquivo(row.id))}>
                <Button icon={<DeleteOutlined />} size="small" />
              </Popconfirm>
            </div>
          )}
        />
        <Table.Column title="Nome do Arquivo" dataIndex="fileName" />
      </Table>
    </Modal>
  );
}

export default FornecedorArquivos;
