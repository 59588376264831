import { GetFunilStatus } from '@services/api/api.correspondencia';
import { Card, Spin } from 'antd';
import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';

function DashboardFunil() {
  const { data, isLoading } = GetFunilStatus();
  const options: ApexOptions = {
    title: {
      text: 'Cadastros por status',
    },
    legend: { show: false },
    colors: [
      '#3B93A5',
      '#F7B844',
      '#ADD8C7',
      '#EC3C65',
      '#CDD7B6',
      '#C1F666',
      '#D43F97',
      '#1E5D8C',
      '#421243',
      '#7F94B0',
      '#EF6537',
      '#C0ADDB',
    ],
    plotOptions: {
      treemap: {
        distributed: true,
        enableShades: false,
      },
    },
  };
  const series: ApexAxisChartSeries = [
    { data: data.filter((c) => c.status !== null).map((c) => ({ x: c.status, y: c.quantidade })) },
  ];
  console.log(series);
  return (
    <Card>
      <Spin spinning={isLoading}>
        <Chart options={options} series={series} type="treemap" width="500" />
      </Spin>
    </Card>
  );
}

export default DashboardFunil;
