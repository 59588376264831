import { useEffect } from 'react';
import { Table, Button, Divider } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { GetArquivos, DownloadArquivo } from '@actions/arquivosActions';
import moment from 'moment';
import { Axios } from '@config/axios';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Arquivo } from '@models/Arquivo';

function TableArquivosReport() {
  const dispatch = useAppDispatch();
  const arquivos = useAppSelector((state) => state.arquivos);
  const { user } = useAppSelector((state) => state.user);

  useEffect(() => {
    dispatch(GetArquivos(user.casaid));
  }, [user.casaid]);

  return (
    <div>
      <Divider orientation="left">Arquivos</Divider>

      <Table pagination={false} loading={arquivos.loading} size="small" rowKey="id" dataSource={arquivos.items}>
        <Table.Column
          render={(_, row: Arquivo) => (
            <div>
              <Button
                size="small"
                icon={<DownloadOutlined />}
                onClick={() => {
                  dispatch(DownloadArquivo());
                  Axios.get('/api/v2/Arquivos/Download', {
                    params: { fileid: row.id },
                    responseType: 'blob',
                  })
                    .then((res) => {
                      console.log(res.data);
                      dispatch(DownloadArquivo());
                      const url = window.URL.createObjectURL(new Blob([res.data]));
                      const link = document.createElement('a');
                      link.href = url;
                      link.setAttribute('download', row.fileName);
                      document.body.appendChild(link);
                      link.click();
                    })
                    .catch(() => dispatch(DownloadArquivo()));
                }}
              />
            </div>
          )}
        />
        <Table.Column title="Item" render={(_, __, index) => index + 1} />
        <Table.Column title="Arquivo" dataIndex="fileName" />
        <Table.Column
          title="Data"
          dataIndex="data"
          render={(text) => (moment(text).isValid() ? moment(text).format('DD/MM/YYYY') : '')}
        />
      </Table>
    </div>
  );
}

export default TableArquivosReport;
