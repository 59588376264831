import {
  AprovarTransferencia,
  CancelarTransferencia,
  FinalizarTransferencia,
  GetPendentes,
  GetTransferencias,
} from '@actions/transferenciasActions';
import { ExtendedAction } from '@config/reduxStore';
import { TransferenciaForm, TransferenciaList } from '@models/Transferencia';
import { createReducer } from '@reduxjs/toolkit';
import { notification } from 'antd';

interface TransferenciasReducer {
  transferencias: TransferenciaList[];
  loadingTransferencias: boolean;
  total: number;
  transferenciasPendentes: TransferenciaList[];
  loadingPendentes: boolean;
  transferencia: TransferenciaForm;
  loadingTransferencia: boolean;
}

const initialState: TransferenciasReducer = {
  transferencias: [],
  loadingTransferencias: false,
  transferenciasPendentes: [],
  loadingPendentes: false,
  total: 0,
  transferencia: null,
  loadingTransferencia: false,
};
const transferenciasReducer = createReducer(initialState, (builder) => {
  builder.addCase(GetTransferencias.pending, (state) => {
    return { ...state, loadingTransferencias: true };
  });
  builder.addCase(GetTransferencias.rejected, (state) => {
    return { ...state, loadingTransferencias: false };
  });
  builder.addCase(GetTransferencias.fulfilled, (state, action: ExtendedAction) => {
    return {
      ...state,
      loadingTransferencias: false,
      transferencias: action.payload.data.results,
      total: action.payload.data.total,
    };
  });
  builder.addCase(GetPendentes.pending, (state) => {
    return { ...state, loadingPendentes: true };
  });
  builder.addCase(GetPendentes.rejected, (state) => {
    return { ...state, loadingPendentes: false };
  });
  builder.addCase(GetPendentes.fulfilled, (state, action: ExtendedAction) => {
    return {
      ...state,
      loadingPendentes: false,
      transferenciasPendentes: action.payload.data,
    };
  });
  builder.addCase(CancelarTransferencia.pending, (state) => {
    return { ...state, loadingPendentes: true };
  });
  builder.addCase(CancelarTransferencia.rejected, (state) => {
    return { ...state, loadingPendentes: false };
  });
  builder.addCase(CancelarTransferencia.fulfilled, (state, action: ExtendedAction) => {
    notification.success({ message: 'Transferência cancelada com sucesso!' });
    return {
      ...state,
      loadingPendentes: false,
      transferenciasPendentes: state.transferenciasPendentes.filter((c) => c.id !== action.meta.arg.id),
      transferencias: state.transferencias.map((c) => (c.id === action.meta.arg.id ? { ...c, ...action.meta.arg } : c)),
    };
  });
  builder.addCase(FinalizarTransferencia.pending, (state) => {
    return { ...state, loadingPendentes: true, loadingTransferencia: true };
  });
  builder.addCase(FinalizarTransferencia.rejected, (state, action: ExtendedAction) => {
    notification.error({ message: action.payload.response.data.Message });
    return { ...state, loadingPendentes: false, loadingTransferencia: false };
  });
  builder.addCase(FinalizarTransferencia.fulfilled, (state, action: ExtendedAction) => {
    notification.success({ message: 'Transferência finalizada com sucesso!' });
    return {
      ...state,
      loadingPendentes: false,
      loadingTransferencia: false,
      transferencias: state.transferencias.map((c) =>
        c.id === action.payload.data.id ? { ...c, ...action.payload.data } : c
      ),
    };
  });
  builder.addCase(AprovarTransferencia.pending, (state) => {
    return { ...state, loadingPendentes: true };
  });
  builder.addCase(AprovarTransferencia.rejected, (state, action: ExtendedAction) => {
    notification.error({ message: action.payload.response.data.Message });
    return { ...state, loadingPendentes: false };
  });
  builder.addCase(AprovarTransferencia.fulfilled, (state, action: ExtendedAction) => {
    notification.success({ message: 'Transferência aprovada com sucesso!' });
    return {
      ...state,
      loadingPendentes: false,
      transferenciasPendentes: state.transferenciasPendentes.filter((c) => c.id !== action.payload.data.id),
    };
  });
});

export default transferenciasReducer;
