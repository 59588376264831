import { ExtendedAction } from '@config/reduxStore';
import { OrcamentoDashboard } from '@models/Dashboard';
import { Grupo } from '@models/Grupo';
import { Orcamento } from '@models/Orcamento';
import { OrcamentoItem } from '@models/OrcamentoItem';
import { notification } from 'antd';

interface OrcamentosReducer {
  orcamentos: Orcamento[];
  fluxomensal: [];
  loadingfluxomensal: boolean;
  formview: boolean;
  orcamento: Orcamento;
  orcamentorealizado: number;
  loadingorcamentos: boolean;
  loadingorcamento: boolean;
  savingorcamento: boolean;
  selectedItens: number[];
  feedback: string;
  grupos: Grupo[];
  verificandoorcamento: boolean;
  orcamentoitens: OrcamentoItem[];
  copiandoorcamento: boolean;
  resumoGlobal: any;
  loadingGlobal: boolean;
  addingitem: boolean;
  loagingaprovador: boolean;
  comprasview: boolean;
  compras: [];
  loadingcompras: boolean;
  comprasdados: {
    id: number;
    itemID: number;
    categgoriaID: number;
    grupoID: number;
    orcamentoID: number;
    descricao: string;
  };
  transferenciaview: boolean;
  realizandotransferencia: boolean;
  transferenciadados: TransferenciaDados;
  loadingresumo: boolean;
  resumofinanceiro: {};
  servicos: [];
  itensOrcamento: [];
  loadingservicos: boolean;
  total: number;
  atualizandomassa: boolean;
  loadingDashboard: boolean;
  dashboard: OrcamentoDashboard[];
}
export interface TransferenciaDados {
  itemID: null;
  item: '';
  quantidade: 0;
  unidade: '';
  categoriaID: null;
  grupoID: null;
  orcamentoID: null;
  realizadoQuantidade: 0;
  realizadoValor: 0;
}
var initialState: OrcamentosReducer = {
  orcamentos: [],
  fluxomensal: [],
  loadingfluxomensal: false,
  formview: false,
  orcamento: null,
  orcamentorealizado: null,
  loadingorcamentos: false,
  loadingorcamento: false,
  savingorcamento: false,
  loadingGlobal: false,
  resumoGlobal: null,
  selectedItens: [],
  feedback: '',
  grupos: [],
  verificandoorcamento: false,
  orcamentoitens: [],
  copiandoorcamento: false,
  addingitem: false,
  loagingaprovador: false,
  comprasview: false,
  compras: [],
  loadingcompras: false,
  comprasdados: null,
  transferenciaview: false,
  realizandotransferencia: false,
  transferenciadados: {
    itemID: null,
    item: '',
    quantidade: 0,
    unidade: '',
    categoriaID: null,
    grupoID: null,
    orcamentoID: null,
    realizadoQuantidade: 0,
    realizadoValor: 0,
  },
  loadingresumo: false,
  resumofinanceiro: {},
  servicos: [],
  itensOrcamento: [],
  loadingservicos: false,
  total: 0,
  atualizandomassa: false,
  loadingDashboard: false,
  dashboard: [],
};
const orcamentosReducer = function (state = initialState, action: ExtendedAction) {
  switch (action.type) {
    case 'GET_DASHBOARD_ORCAMENTOS_PENDING':
      state = {
        ...state,
        loadingDashboard: true,
      };
      break;
    case 'GET_DASHBOARD_ORCAMENTOS_REJECTED':
      state = {
        ...state,
        loadingDashboard: false,
      };
      break;
    case 'GET_DASHBOARD_ORCAMENTOS_FULFILLED':
      state = {
        ...state,
        loadingDashboard: false,
        dashboard: action.payload.data,
      };
      break;
    case 'GET_GRUPOS_FULFILLED':
      state = {
        ...state,
        grupos: action.payload.data,
      };
      break;
    case 'GET_SERVICOS_PENDING':
      state = {
        ...state,
        servicos: [],
        loadingservicos: true,
      };
      break;
    case 'GET_SERVICOS_REJECTED':
      state = {
        ...state,
        loadingservicos: false,
      };
      notification.error({ message: action.payload.reponse.data });
      break;
    case 'GET_SERVICOS_FULFILLED':
      state = {
        ...state,
        loadingservicos: false,
        servicos: action.payload.data.tree,
        itensOrcamento: action.payload.data.itens,
      };
      break;
    case 'SOLICITAR_TRANSFERENCIA_PENDING':
      state = {
        ...state,
        realizandotransferencia: true,
      };
      break;
    case 'SOLICITAR_TRANSFERENCIA_REJECTED':
      state = {
        ...state,
        realizandotransferencia: false,
      };
      notification.error({ message: action.payload.response.data.Message });
      break;
    case 'SOLICITAR_TRANSFERENCIA_FULFILLED':
      state = {
        ...state,
        realizandotransferencia: false,
        transferenciaview: false,
        transferenciadados: initialState.transferenciadados,
      };
      notification.success({ message: 'Transferência solicitada com sucesso!' });
      break;
    case 'GET_RESUMO_FINANCEIRO_PENDING':
      state = {
        ...state,
        loadingresumo: true,
      };
      break;
    case 'GET_RESUMO_FINANCEIRO_REJECTED':
      state = {
        ...state,
        loadingresumo: false,
      };
      break;
    case 'GET_RESUMO_FINANCEIRO_FULFILLED':
      state = {
        ...state,
        loadingresumo: false,
        resumofinanceiro: action.payload.data,
      };
      break;
    case 'GET_COMPRAS_ITEM_PENDING':
      state = {
        ...state,
        loadingcompras: true,
      };
      break;
    case 'GET_COMPRAS_ITEM_REJECTED':
      state = {
        ...state,
        loadingcompras: false,
      };
      break;
    case 'GET_COMPRAS_ITEM_FULFILLED':
      state = {
        ...state,
        loadingcompras: false,
        compras: action.payload.data,
      };
      break;
    case 'SELECTED_ITENS':
      state = {
        ...state,
        selectedItens: action.payload,
      };
      break;
    case 'DELETE_ITENS_PENDING':
      state = {
        ...state,
        loadingorcamentos: true,
        feedback: '',
      };
      break;
    case 'DELETE_ITENS_REJECTED':
      state = {
        ...state,
        loadingorcamentos: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: action.payload.response.data.Message });
      break;
    case 'DELETE_ITENS_FULFILLED':
      state = {
        ...state,
        loadingorcamentos: false,
        orcamento: {
          ...state.orcamento,
          orcamentosItens: state.orcamento.orcamentosItens.filter((c) => !action.payload.data.includes(c.id)),
        },
        feedback: 'Item removido com sucesso!',
        selectedItens: [],
      };
      notification.success({ message: 'Item removido com sucesso' });
      break;
    case 'OPEN_COMPRAS_VIEW':
      state = {
        ...state,
        comprasview: true,
        comprasdados: action.payload,
      };
      break;
    case 'CLOSE_COMPRAS_VIEW':
      state = {
        ...state,
        compras: [],
        comprasview: false,
        comprasdados: initialState.comprasdados,
      };
      break;
    case 'OPEN_TRANSFERENCIA_VIEW':
      state = {
        ...state,
        transferenciaview: true,
        transferenciadados: action.payload,
      };
      break;
    case 'CLOSE_TRANSFERENCIA_VIEW':
      state = {
        ...state,
        transferenciaview: false,
        transferenciadados: initialState.transferenciadados,
      };
      break;
    case 'CHANGE_ITEM_ORCAMENTO':
      state = {
        ...state,
        orcamento: {
          ...state.orcamento,
          orcamentosItens: state.orcamento.orcamentosItens.map((c) => {
            if (c.id === action.payload.id) {
              return action.payload;
            }
            return c;
          }),
        },
      };
      break;
    case 'DELETE_ITEM_ORCAMENTO_PENDING':
      state = {
        ...state,
        loadingorcamentos: true,
        feedback: '',
      };
      break;
    case 'DELETE_ITEM_ORCAMENTO_REJECTED':
      state = {
        ...state,
        loadingorcamentos: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: action.payload.response.data.Message });
      break;
    case 'DELETE_ITEM_ORCAMENTO_FULFILLED':
      state = {
        ...state,
        loadingorcamentos: false,
        orcamento: {
          ...state.orcamento,
          orcamentosItens: state.orcamento.orcamentosItens.filter((c) => c.id !== action.payload.data),
        },
        feedback: 'Item removido com sucesso!',
      };
      notification.success({ message: 'Item atualizado com sucesso' });
      break;
    case 'UPDATE_ITEM_ORCAMENTO_PENDING':
      state = {
        ...state,
        addingitem: true,
        feedback: '',
      };
      break;
    case 'UPDATE_ITEM_ORCAMENTO_REJECTED':
      state = {
        ...state,
        addingitem: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: 'Erro ao atualizar item!' });
      break;
    case 'UPDATE_ITEM_ORCAMENTO_FULFILLED':
      state = {
        ...state,
        addingitem: false,
        orcamento: {
          ...state.orcamento,
          orcamentosItens: state.orcamento.orcamentosItens.map((c) => {
            if (c.id === action.payload.data.id) return action.payload.data;
            else return c;
          }),
        },
        feedback: 'Item atualizado com sucesso!',
      };
      notification.success({ message: 'Item atualizado com sucesso' });
      break;
    case 'ADD_COMPOSICAO_ORCAMENTO_PENDING':
      state = {
        ...state,
        addingitem: true,
      };
      break;
    case 'ADD_COMPOSICAO_ORCAMENTO_REJECTED':
      state = {
        ...state,
        addingitem: false,
      };
      notification.error({ message: 'Erro ao adicionar composição!' });
      break;
    case 'ADD_COMPOSICAO_ORCAMENTO_FULFILLED':
      state = {
        ...state,
        addingitem: false,
        grupos: [...state.grupos.filter((c) => c.id !== action.payload.data.grupo.id), action.payload.data.grupo],
        orcamento: {
          ...state.orcamento,
          orcamentosItens: [...state.orcamento.orcamentosItens, ...action.payload.data.itens],
        },
      };
      notification.success({ message: 'Composição adicionada com sucesso!' });
      break;
    case 'ADD_ITEM_ORCAMENTO_PENDING':
      state = {
        ...state,
        addingitem: true,
        feedback: '',
      };
      break;
    case 'ADD_ITEM_ORCAMENTO_REJECTED':
      state = {
        ...state,
        addingitem: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: 'Erro ao adicionar item!' });
      break;
    case 'ADD_ITEM_ORCAMENTO_FULFILLED':
      state = {
        ...state,
        addingitem: false,
        orcamento: {
          ...state.orcamento,
          orcamentosItens: [...state.orcamento.orcamentosItens, action.payload.data],
        },
        feedback: 'Item adicionado com sucesso!',
      };
      notification.success({ message: 'Item adicionado com sucesso' });
      break;
    case 'COPY_ORCAMENTO_PENDING':
      state = {
        ...state,
        copiandoorcamento: true,
        feedback: '',
      };
      break;
    case 'COPY_ORCAMENTO_REJECTED':
      state = {
        ...state,
        copiandoorcamento: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: 'Erro ao copiar orçamento!' });
      break;
    case 'COPY_ORCAMENTO_FULFILLED':
      state = {
        ...state,
        copiandoorcamento: false,
        feedback: 'Orcamento copiado com sucesso!',
        orcamentos: action.payload.data,
      };
      notification.success({ message: 'Orçamento copiado com sucesso' });
      break;
    case 'RESET_FEEDBACK':
      state = {
        ...state,
        feedback: '',
      };
      break;
    case 'FORM_ORCAMENTO':
      state = {
        ...state,
        formview: action.payload.open,
        feedback: '',
        orcamento: action.payload.id ? action.payload.id : null,
      };
      break;
    case 'ATUALIZA_MASSA_PENDING':
      state = {
        ...state,
        atualizandomassa: true,
        feedback: '',
      };
      break;
    case 'ATUALIZA_MASSA_REJECTED':
      state = {
        ...state,
        atualizandomassa: false,
      };
      notification.error({ message: `Erro ao atualizar itens!` });
      break;
    case 'ATUALIZA_MASSA_FULFILLED':
      state = {
        ...state,
        atualizandomassa: false,
        orcamento: {
          ...state.orcamento,
          orcamentosItens: state.orcamento.orcamentosItens.map((c) => {
            if (c.itemID === action.payload.data.itemID) {
              c.valorUnitario = action.payload.data.valor;
              c.valorTotal = action.payload.data.valor * c.quantidade;
              return c;
            } else return c;
          }),
        },
      };
      notification.success({
        message: 'Itens atualizados com sucesso!',
      });
      break;
    case 'BLOQUEAR_ITEM_PENDING':
      state = {
        ...state,
        //loadingorcamentos: true,
        feedback: '',
      };
      break;
    case 'BLOQUEAR_ITEM_REJECTED':
      state = {
        ...state,
        loadingorcamentos: false,
      };
      notification.error({ message: `Erro ao bloquear/desbloquear grupo!` });
      break;
    case 'BLOQUEAR_ITEM_FULFILLED':
      state = {
        ...state,
        loadingorcamentos: false,
        orcamento: {
          ...state.orcamento,
          orcamentosItens: state.orcamento.orcamentosItens.map((c) => {
            if (c.id === action.payload.data.id) {
              c.bloqueado = action.payload.data.bloqueado;
              return c;
            } else return c;
          }),
        },
      };
      notification.success({
        message: `Item ${action.payload.data.bloqueado ? 'bloqueado' : 'desbloqueado'} com sucesso!`,
      });
      break;
    case 'BLOQUEAR_GRUPO_PENDING':
      state = {
        ...state,
        //loadingorcamentos: true,
        feedback: '',
      };
      break;
    case 'BLOQUEAR_GRUPO_REJECTED':
      state = {
        ...state,
        loadingorcamentos: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: `Erro ao bloquear/desbloquear grupo!` });
      break;
    case 'BLOQUEAR_GRUPO_FULFILLED':
      state = {
        ...state,
        loadingorcamentos: false,
        orcamento: {
          ...state.orcamento,
          orcamentosItens: state.orcamento.orcamentosItens.map((c) => {
            if (c.grupoID === action.payload.data.id) {
              c.bloqueado = action.payload.data.bloqueado;
              return c;
            } else return c;
          }),
        },
      };
      notification.success({
        message: `Grupo ${action.payload.data.bloqueado ? 'bloqueado' : 'desbloqueado'} com sucesso!`,
      });
      break;
    case 'ATIVAR_ORCAMENTO_ITENS_PENDING':
      state = {
        ...state,
        loadingorcamentos: true,
        orcamentoitens: initialState.orcamentoitens,
      };
      break;
    case 'ATIVAR_ORCAMENTO_ITENS_REJECTED':
      state = {
        ...state,
        loadingorcamentos: false,
      };
      notification.error({ message: 'Erro ao bloquear/desbloquear orçamento.' });
      break;
    case 'ATIVAR_ORCAMENTO_ITENS_FULFILLED':
      state = {
        ...state,
        loadingorcamentos: false,
        orcamentos: state.orcamentos.map((c) => {
          if (c.id === action.payload.data.id) {
            c.ativo = action.payload.data.ativo;
            return c;
          }
          return c;
        }),
      };
      notification.success({
        message: `Orçamento ${action.payload.data.ativo ? 'ativado' : 'desativado'} com sucesso!`,
      });
      break;
    case 'BLOQUEAR_ORCAMENTO_ITENS_PENDING':
      state = {
        ...state,
        loadingorcamentos: true,
        orcamentoitens: initialState.orcamentoitens,
      };
      break;
    case 'BLOQUEAR_ORCAMENTO_ITENS_REJECTED':
      state = {
        ...state,
        loadingorcamentos: false,
      };
      notification.error({ message: 'Erro ao bloquear/desbloquear orçamento.' });
      break;
    case 'BLOQUEAR_ORCAMENTO_ITENS_FULFILLED':
      state = {
        ...state,
        loadingorcamentos: false,
        orcamentos: state.orcamentos.map((c) => {
          if (c.id === action.payload.data.id) {
            c.bloqueado = action.payload.data.bloqueado;
            return c;
          }
          return c;
        }),
      };
      notification.success({
        message: `Orçamento ${action.payload.data.bloqueado ? 'bloqueado' : 'desbloqueado'} com sucesso!`,
      });
      break;
    case 'GET_ORCAMENTO_ITENS_PENDING':
      state = {
        ...state,
        verificandoorcamento: true,
        orcamentoitens: initialState.orcamentoitens,
      };
      break;
    case 'GET_ORCAMENTO_ITENS_REJECTED':
      state = {
        ...state,
        verificandoorcamento: false,
      };
      break;
    case 'GET_ORCAMENTO_ITENS_FULFILLED':
      state = {
        ...state,
        verificandoorcamento: false,
        orcamentoitens: action.payload.data,
      };
      break;
    case 'CHECK_SALDO_PENDING':
      state = {
        ...state,
        verificandoorcamento: true,
        //orcamentoitens: initialState.orcamentoitens,
      };
      break;
    case 'CHECK_SALDO_REJECTED':
      state = {
        ...state,
        verificandoorcamento: false,
      };
      break;
    case 'CHECK_SALDO_FULFILLED':
      state = {
        ...state,
        verificandoorcamento: false,
        orcamentoitens: state.orcamentoitens.find((c) => c.id === action.payload.data.id)
          ? state.orcamentoitens.map((c) => {
              if (c.id === action.payload.data.id) return action.payload.data;
              else return c;
            })
          : [...state.orcamentoitens, action.payload.data],
      };
      break;
    case 'GET_ORCAMENTO_PENDING':
      state = {
        ...state,
        loadingorcamento: true,
        orcamento: initialState.orcamento,
      };
      break;
    case 'GET_ORCAMENTO_REJECTED':
      state = {
        ...state,
        loadingorcamento: false,
      };
      notification.error({ message: action.payload.response.data.Message });
      break;
    case 'GET_ORCAMENTO_FULFILLED':
      state = {
        ...state,
        loadingorcamento: false,
        orcamento: action.payload.data,
      };
      break;
    case 'GET_ORCAMENTO_REALIZADO_PENDING':
      state = {
        ...state,
        loadingorcamento: true,
        orcamentoitens: initialState.orcamentoitens,
        orcamentorealizado: initialState.orcamentorealizado,
      };
      break;
    case 'GET_ORCAMENTO_REALIZADO_REJECTED':
      state = {
        ...state,
        loadingorcamento: false,
      };
      break;
    case 'GET_ORCAMENTO_REALIZADO_FULFILLED':
      state = {
        ...state,
        loadingorcamento: false,
        orcamento: {
          ...action.payload.data,
          /* orcamentosItens: action.payload.data.orcamentosItens.map((oi) => ({
            ...oi,
            valorReembolso: action.payload.data.realizado
              .filter((a) => a.orcamentoitemid === oi.id)
              .reduce((a, b) => a + b.reembolsavel, 0),
            realizadoValor: action.payload.data.realizado
              .filter((a) => a.orcamentoitemid === oi.id)
              .reduce((a, b) => a + b.valor, 0),
            realizadoQuantidade: action.payload.data.realizado
              .filter((a) => a.orcamentoitemid === oi.id)
              .reduce((a, b) => a + b.quantidade, 0),
          })), */
        },
      };
      break;
    case 'GET_ORCAMENTOSOC_PENDING':
      state = {
        ...state,
        loadingorcamentos: true,
        orcamentos: initialState.orcamentos,
        orcamento: initialState.orcamento,
      };
      break;
    case 'GET_ORCAMENTOSOC_REJECTED':
      state = {
        ...state,
        loadingorcamentos: false,
      };
      break;
    case 'GET_ORCAMENTOSOC_FULFILLED':
      state = {
        ...state,
        loadingorcamentos: false,
        orcamentos: action.payload.data,
      };
      break;
    case 'GET_ORCAMENTOS_LIST_PENDING':
      state = {
        ...state,
        loadingorcamentos: true,
        orcamentos: initialState.orcamentos,
        orcamento: initialState.orcamento,
      };
      break;
    case 'GET_ORCAMENTOS_LIST_REJECTED':
      state = {
        ...state,
        loadingorcamentos: false,
      };
      break;
    case 'GET_ORCAMENTOS_LIST_FULFILLED':
      state = {
        ...state,
        loadingorcamentos: false,
        orcamentos: action.payload.data,
      };
      break;
    case 'GET_FLUXO_MENSAL_PENDING':
      state = {
        ...state,
        loadingfluxomensal: true,
        fluxomensal: initialState.fluxomensal,
      };
      break;
    case 'GET_FLUXO_MENSAL_REJECTED':
      state = {
        ...state,
        loadingfluxomensal: false,
      };
      break;
    case 'GET_FLUXO_MENSAL_FULFILLED':
      state = {
        ...state,
        loadingfluxomensal: false,
        fluxomensal: action.payload.data,
        //total: action.payload.data.total,
      };
      break;
    case 'GET_ORCAMENTOS_PENDING':
      state = {
        ...state,
        loadingorcamentos: true,
        orcamentos: initialState.orcamentos,
        orcamento: initialState.orcamento,
      };
      break;
    case 'GET_ORCAMENTOS_REJECTED':
      state = {
        ...state,
        loadingorcamentos: false,
      };
      break;
    case 'GET_ORCAMENTOS_FULFILLED':
      state = {
        ...state,
        loadingorcamentos: false,
        orcamentos: action.payload.data,
      };
      break;
    case 'GET_ORCAMENTOS_SERVER_PENDING':
      state = {
        ...state,
        loadingorcamentos: true,
        orcamentos: initialState.orcamentos,
      };
      break;
    case 'GET_ORCAMENTOS_SERVER_REJECTED':
      state = {
        ...state,
        loadingorcamentos: false,
      };
      break;
    case 'GET_ORCAMENTOS_SERVER_FULFILLED':
      state = {
        ...state,
        loadingorcamentos: false,
        orcamentos: action.payload.data.results,
        total: action.payload.data.total,
      };
      break;
    case 'ADD_ORCAMENTO_PENDING':
      state = {
        ...state,
        savingorcamento: true,
        feedback: '',
      };
      break;
    case 'ADD_ORCAMENTO_REJECTED':
      state = {
        ...state,
        savingorcamento: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: 'Erro ao salvar orçamento!' });
      break;
    case 'ADD_ORCAMENTO_FULFILLED':
      state = {
        ...state,
        savingorcamento: false,
        feedback: 'Orcamento criado com sucesso!',
      };
      notification.success({ message: 'Orçamento salvo com sucesso' });
      break;
    case 'UPDATE_ORCAMENTO_PENDING':
      state = {
        ...state,
        savingorcamento: true,
        feedback: '',
      };
      break;
    case 'UPDATE_ORCAMENTO_REJECTED':
      state = {
        ...state,
        savingorcamento: false,
        feedback: action.payload.response.data.Message,
      };
      break;
    case 'UPDATE_ORCAMENTO_FULFILLED':
      state = {
        ...state,
        savingorcamento: false,
        feedback: 'Orcamento atualizada com sucesso!',
      };
      break;
    case 'DELETE_ORCAMENTO_PENDING':
      state = {
        ...state,
        loadingorcamentos: true,
        feedback: '',
      };
      break;
    case 'DELETE_ORCAMENTO_REJECTED':
      state = {
        ...state,
        loadingorcamentos: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: action.payload.response.data.Message });
      break;
    case 'DELETE_ORCAMENTO_FULFILLED':
      state = {
        ...state,
        loadingorcamentos: false,
        feedback: 'Orcamento excluída com sucesso!',
        orcamentos: state.orcamentos.filter((c) => c.id !== action.payload.data),
      };
      notification.success({ message: state.feedback });
      break;
    case 'SALVAR_DESCRICAO_PENDING':
      state = {
        ...state,
        loadingorcamentos: true,
        feedback: '',
      };
      break;
    case 'SALVAR_DESCRICAO_REJECTED':
      state = {
        ...state,
        loadingorcamentos: false,
        feedback: action.payload.response.data.Message,
      };
      notification.error({ message: action.payload.response.data.Message.Message });
      break;
    case 'SALVAR_DESCRICAO_FULFILLED':
      state = {
        ...state,
        loadingorcamentos: false,
        feedback: 'Orcamento editado com sucesso!',
        orcamentos: state.orcamentos.map((c) => {
          if (c.id === action.payload.data.id) {
            c.descricao = action.payload.data.descricao;
            return c;
          } else return c;
        }),
      };
      notification.success({ message: state.feedback });
      break;
    case 'EDITAR_GRUPO_NOME_FULFILLED':
      state = {
        ...state,
        orcamento: {
          ...state.orcamento,
          orcamentosItens: state.orcamento.orcamentosItens.map((c) => {
            if (c.grupoID === action.payload.data.id) {
              c.grupo = action.payload.data.nome;
              return c;
            } else return c;
          }),
        },
      };
      break;
    default:
      return state;
  }
  return state;
};

export default orcamentosReducer;
