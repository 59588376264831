import { ExtendedAction } from '@config/reduxStore';
import { Casa } from '@models/Casa';
import { ClienteCorrespondencia } from '@models/ClienteCorrespondencia';
import { Engenharia } from '@models/Engenharias';
import { notification } from 'antd';
import { orderDateDesc } from '@utils/filterandsort';
import { Pagination } from '@models/General';
interface CorrespondenciaReducer {
  clientes: ClienteCorrespondencia[];
  clientesp: ClienteCorrespondencia[];
  engenharias: Engenharia[];
  cliente: ClienteCorrespondencia;
  loadingclientes: boolean;
  savingcliente: boolean;
  formview: boolean;
  feedback: string;
  quadro: {
    clientes: ClienteCorrespondencia[];
    loading: boolean;
  };
  checkingcpf: boolean;
  feedbackcpf: string;
  blockform: boolean;
  savinghistorico: boolean;
  loadingcliente: boolean;
  buscarcasaform: boolean;
  casas: Casa[];
  loadingcasas: boolean;
  avancando: boolean;
  avancarview: boolean;
  pagination: Pagination<ClienteCorrespondencia>;
}
var initialState: CorrespondenciaReducer = {
  clientes: [],
  clientesp: [],
  engenharias: [],
  cliente: null,
  loadingclientes: false,
  savingcliente: false,
  formview: false,
  feedback: '',
  quadro: {
    clientes: [],
    loading: false,
  },
  checkingcpf: false,
  feedbackcpf: null,
  blockform: true,
  savinghistorico: false,
  loadingcliente: false,
  buscarcasaform: false,
  casas: [],
  loadingcasas: false,
  avancando: false,
  avancarview: false,
  pagination: {
    filters: null,
    pagination: {
      current: 1,
      total: 0,
      pageSize: 10,
    },
    sorter: {
      field: null,
      order: null,
    },
  },
};

const correspondenciaReducer = function (state = initialState, action: ExtendedAction) {
  switch (action.type) {
    case 'GET_CLIENTES_FULL_PENDING':
      state = {
        ...state,
        loadingclientes: true,
      };
      break;
    case 'GET_CLIENTES_FULL_REJECTED':
      state = {
        ...state,
        loadingclientes: false,
      };
      notification.error({ message: 'Erro ao alterar perspectiva' });
      break;
    case 'GET_CLIENTES_FULL_FULFILLED':
      state = {
        ...state,
        loadingclientes: false,
        clientes: action.payload.data,
      };
      notification.success({ message: 'Perspectiva alterada com sucesso!' });
      break;
    case 'PERSPECTIVA_CHANGE_PENDING':
      state = {
        ...state,
        loadingclientes: true,
      };
      break;
    case 'PERSPECTIVA_CHANGE_REJECTED':
      state = {
        ...state,
        loadingclientes: false,
      };
      notification.error({ message: 'Erro ao alterar perspectiva' });
      break;
    case 'PERSPECTIVA_CHANGE_FULFILLED':
      state = {
        ...state,
        loadingclientes: false,
        engenharias: state.engenharias.map((c) => {
          if (c.id === action.payload.data) {
            c.perspectiva = !c.perspectiva;
            return c;
          } else return c;
        }),
      };
      notification.success({ message: 'Perspectiva alterada com sucesso!' });
      break;
    case 'CHECKLIST_PENDING':
      state = {
        ...state,
        loadingclientes: true,
      };
      break;
    case 'CHECKLIST_REJECTED':
      state = {
        ...state,
        loadingclientes: false,
      };
      break;
    case 'CHECKLIST_FULFILLED':
      state = {
        ...state,
        loadingclientes: false,
        clientesp: state.clientesp.map((c) => {
          if (c.id === action.payload.data.id) {
            c = { ...c, ...action.payload.data };
            return c;
          } else return c;
        }),
      };
      break;
    case 'SAIR_PERSPECTIVA_PENDING':
      state = {
        ...state,
        loadingclientes: true,
      };
      break;
    case 'SAIR_PERSPECTIVA_REJECTED':
      state = {
        ...state,
        loadingclientes: false,
      };
      break;
    case 'SAIR_PERSPECTIVA_FULFILLED':
      state = {
        ...state,
        loadingclientes: false,
        clientesp: state.clientesp.filter((c) => c.id !== action.payload.data),
      };
      break;
    case 'SET_PAGINATION_CORRESPONDENCIA':
      state = {
        ...state,
        pagination: {
          ...state.pagination,
          pagination: action.payload.pagination,
          filters: action.payload.filters,
        },
      };
      break;
    case 'GET_ENGENHARIAS_PENDING':
      state = {
        ...state,
        loadingclientes: true,
      };
      break;
    case 'GET_ENGENHARIAS_REJECTED':
      state = {
        ...state,
        loadingclientes: false,
      };
      break;
    case 'GET_ENGENHARIAS_FULFILLED':
      state = {
        ...state,
        loadingclientes: false,
        engenharias: action.payload.data,
      };
      break;
    case 'GET_CLIENTES_PERSPECTIVA_PENDING':
      state = {
        ...state,
        loadingclientes: true,
      };
      break;
    case 'GET_CLIENTES_PERSPECTIVA_REJECTED':
      state = {
        ...state,
        loadingclientes: false,
      };
      break;
    case 'GET_CLIENTES_PERSPECTIVA_FULFILLED':
      state = {
        ...state,
        loadingclientes: false,
        clientesp: action.payload.data,
      };
      break;
    case 'AVANCAR_VIEW':
      state = {
        ...state,
        avancarview: !state.avancarview,
      };
      break;
    case 'DESISTIR_PENDING':
      state = {
        ...state,
        loadingclientes: true,
      };
      break;
    case 'DESISTIR_REJECTED':
      state = {
        ...state,
        loadingclientes: false,
      };
      break;
    case 'DESISTIR_FULFILLED':
      state = {
        ...state,
        loadingclientes: false,
        clientes: state.clientes.filter((c) => c.id !== action.payload.data.id),
      };
      notification.success({ message: 'Cliente atualizado com sucesso!' });
      break;
    case 'AVANCAR_PENDING':
      state = {
        ...state,
        avancando: true,
      };
      break;
    case 'AVANCAR_REJECTED':
      state = {
        ...state,
        avancando: false,
      };
      break;
    case 'AVANCAR_FULFILLED':
      state = {
        ...state,
        avancando: false,
        avancarview: false,
        clientes: state.clientes.map((c) => {
          if (c.id === action.payload.data.id) return action.payload.data;
          else return c;
        }),
      };
      notification.success({ message: 'Cliente atualizado com sucesso!' });
      break;
    case 'FORM_CLIENTE':
      state = {
        ...state,
        formview: action.payload.open,
      };
      break;

    case 'BUSCAR_CASA_FORM':
      state = {
        ...state,
        buscarcasaform: !state.buscarcasaform,
      };
      break;
    case 'ADD_HISTORICO_FICHA_CLIENTE_PENDING':
      state = {
        ...state,
        savinghistorico: true,
      };
      break;
    case 'ADD_HISTORICO_FICHA_CLIENTE_REJECTED':
      state = {
        ...state,
        savinghistorico: false,
      };
      break;
    case 'ADD_HISTORICO_FICHA_CLIENTE_FULFILLED':
      state = {
        ...state,
        savinghistorico: false,
        cliente: {
          ...state.cliente,
          historico: [action.payload.data, ...state.cliente.historico],
        },
      };
      notification.success({ message: 'Histórico adicionado com sucesso!' });
      break;
    case 'GET_CASAS_CLIENTE_PENDING':
      state = {
        ...state,
        loadingcasas: true,
      };
      break;
    case 'GET_CASAS_CLIENTE_REJECTED':
      state = {
        ...state,
        loadingcasas: false,
      };
      break;
    case 'GET_CASAS_CLIENTE_FULFILLED':
      state = {
        ...state,
        loadingcasas: false,
        casas: action.payload.data,
      };
      break;
    case 'ADD_HISTORICO_CLIENTE_PENDING':
      state = {
        ...state,
        savinghistorico: true,
      };
      break;
    case 'ADD_HISTORICO_CLIENTE_REJECTED':
      state = {
        ...state,
        savinghistorico: false,
      };
      break;
    case 'ADD_HISTORICO_CLIENTE_FULFILLED':
      state = {
        ...state,
        savinghistorico: false,
        clientes: state.clientes.map((c) => {
          if (c.id === action.payload.data.clienteCorrespondenciaID) {
            c.historico = [action.payload.data, ...c.historico].sort((a, b) => orderDateDesc(a.data, b.data));
            return c;
          } else return c;
        }),
      };
      notification.success({ message: 'Histórico adicionado com sucesso!' });
      break;
    case 'DELETE_CLIENTE_PENDING':
      state = {
        ...state,
        loadingclientes: true,
      };
      break;
    case 'DELETE_CLIENTE_REJECTED':
      state = {
        ...state,
        loadingclientes: false,
      };
      break;
    case 'DELETE_CLIENTE_FULFILLED':
      state = {
        ...state,
        loadingclientes: false,
        clientes: state.clientes.filter((c) => c.id !== action.payload.data),
      };
      notification.success({ message: 'Cliente excluído com sucesso!' });
      break;
    case 'ADD_CLIENTE_PENDING':
      state = {
        ...state,
        savingcliente: true,
      };
      break;
    case 'ADD_CLIENTE_REJECTED':
      notification.error({ message: action.payload?.response?.data?.Message });
      state = {
        ...state,
        savingcliente: false,
      };
      break;
    case 'ADD_CLIENTE_FULFILLED':
      state = {
        ...state,
        savingcliente: false,
      };
      notification.success({ message: 'Cliente criado com sucesso!' });
      break;
    case 'CHECK_CPF_PENDING':
      state = {
        ...state,
        checkingcpf: true,
        feedbackcpf: null,
      };
      break;
    case 'CHECK_CPF_REJECTED':
      state = {
        ...state,
        checkingcpf: false,
        blockform: true,
        feedbackcpf: action.payload.response.data.Message,
      };
      break;
    case 'CHECK_CPF_FULFILLED':
      state = {
        ...state,
        checkingcpf: false,
        blockform: false,
        feedbackcpf: null,
      };
      break;
    case 'SALVAR_CLIENTE_PENDING':
      state = {
        ...state,
        loadingcliente: true,
      };
      break;
    case 'SALVAR_CLIENTE_REJECTED':
      state = {
        ...state,
        loadingcliente: false,
      };
      break;
    case 'SALVAR_CLIENTE_FULFILLED':
      state = {
        ...state,
        loadingcliente: false,
        cliente: action.payload.data,
        clientes: state.clientes.map((c) => {
          if (c.id === action.payload.data.id) {
            c = action.payload.data;
            return c;
          } else return c;
        }),
        clientesp: state.clientesp.map((c) => {
          if (c.id === action.payload.data.id) {
            c = action.payload.data;
            return c;
          } else return c;
        }),
        engenharias: state.engenharias.map((c) => {
          if (c.id === action.payload.data.id) {
            c = action.payload.data;
            return c;
          } else return c;
        }),
      };
      notification.success({ message: 'Ficha atualizada com sucesso!' });
      break;
    case 'GET_CLIENTE_PENDING':
      state = {
        ...state,
        loadingcliente: true,
        cliente: initialState.cliente,
      };
      break;
    case 'GET_CLIENTE_REJECTED':
      state = {
        ...state,
        loadingcliente: false,
      };
      break;
    case 'GET_CLIENTE_FULFILLED':
      state = {
        ...state,
        loadingcliente: false,
        cliente: action.payload.data,
      };
      break;
    case 'GET_CLIENTES_PAGINATION_PENDING':
      state = {
        ...state,
        loadingclientes: true,
        clientes: initialState.clientes,
      };
      break;
    case 'GET_CLIENTES_PAGINATION_REJECTED':
      state = {
        ...state,
        loadingclientes: false,
      };
      break;
    case 'GET_CLIENTES_PAGINATION_FULFILLED':
      state = {
        ...state,
        loadingclientes: false,
        clientes: action.payload.data.results,
        pagination: {
          ...state.pagination,
          pagination: {
            ...state.pagination.pagination,
            current: action.payload.data.current,
            total: action.payload.data.total,
          },
        },
      };
      break;
    case 'GET_QUADRO_PENDING':
      state = {
        ...state,
        loadingclientes: true,
      };
      break;
    case 'GET_QUADRO_REJECTED':
      state = {
        ...state,
        loadingclientes: false,
      };
      break;
    case 'GET_QUADRO_FULFILLED':
      state = {
        ...state,
        loadingclientes: false,
        clientes: action.payload.data,
      };
      break;
    default:
      return state;
  }
  return state;
};

export default correspondenciaReducer;
