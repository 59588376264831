import { IFolhaItem } from '@models/Folha';
import { GetOrcamentosRealizado } from '@services/api/api.orcamento';
import { doubleMask2, moneyMask } from '@utils/masks';
import { Skeleton, Table, Typography } from 'antd';
import { useFolhaForm } from './useFolhaForm';

function FolhaFormResumoFinalItens() {
  const { folha, isLoading } = useFolhaForm();
  const { data: orcamento, isLoading: loadingOrcamento } = GetOrcamentosRealizado({
    ativo: true,
    orcamentoID: folha?.orcamentoID,
    tipo: 1,
  });
  if (isLoading || loadingOrcamento) return <Skeleton active />;
  return (
    <Table
      title={() => 'Itens Medidos'}
      dataSource={folha.itens}
      size="small"
      pagination={false}
      summary={(data) => (
        <Table.Summary fixed>
          <Table.Summary.Row style={{ fontWeight: 'bold' }}>
            <Table.Summary.Cell index={0} colSpan={2}>
              <Typography.Text>{`${data.length} Itens`}</Typography.Text>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={1} colSpan={7} align="right">
              TOTAL FOLHA
            </Table.Summary.Cell>
            <Table.Summary.Cell index={3} align="right">
              <Typography.Text>
                {moneyMask.format(data.reduce((a: number, b: IFolhaItem) => a + b.valorMedir, 0))}
              </Typography.Text>
            </Table.Summary.Cell>
          </Table.Summary.Row>
        </Table.Summary>
      )}
    >
      <Table.Column dataIndex="id" align="center" width={40} render={(_, __, index) => index + 1} />
      <Table.Column
        title="Categoria"
        dataIndex="id"
        render={(v) => orcamento.orcamentosItens.find((c) => c.id === v).categoria as string}
      />
      <Table.Column
        title="Grupo"
        dataIndex="id"
        render={(v) => orcamento.orcamentosItens.find((c) => c.id === v).grupo as string}
      />
      <Table.Column
        title="Item"
        dataIndex="id"
        render={(v) => orcamento.orcamentosItens.find((c) => c.id === v).item as string}
      />
      <Table.Column
        title="Unidade"
        dataIndex="id"
        width={80}
        render={(v) => orcamento.orcamentosItens.find((c) => c.id === v).unidade as string}
      />
      <Table.Column
        title="Orçado"
        dataIndex="id"
        width={100}
        align="right"
        render={(v) => moneyMask.format(orcamento.orcamentosItens.find((c) => c.id === v).valorTotal)}
      />
      <Table.Column
        title="Realizado"
        dataIndex="id"
        align="right"
        render={(v, _) => {
          const item = orcamento.orcamentosItens.find((c) => c.id === v);
          return `${moneyMask.format(item.realizadoValor)} (${doubleMask2.format(
            (100 * item.realizadoValor) / item.valorTotal
          )})`;
        }}
      />
      <Table.Column
        title="Saldo"
        dataIndex="id"
        align="right"
        render={(v) => {
          const item = orcamento.orcamentosItens.find((c) => c.id === v);
          return `${moneyMask.format(item.valorTotal - item.realizadoValor)} (${doubleMask2.format(
            100 - (100 * item.realizadoValor) / item.valorTotal
          )})`;
        }}
      />
      <Table.Column
        title="Medir (%) "
        dataIndex="medir"
        align="right"
        render={(v) => doubleMask2.format(v)}
        width={100}
      />
      <Table.Column
        title="Valor Medição"
        dataIndex="valorMedir"
        align="right"
        render={(v) => moneyMask.format(v)}
        width={100}
      />
    </Table>
  );
}

export default FolhaFormResumoFinalItens;
