import { apiEndpoints } from '@constants/apiEndpoints';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Axios } from '@config/axios';

export const GetArquivos = createAsyncThunk('arquivos/GetArquivos', async (casaid: number) =>
  Axios.get(apiEndpoints.ARQUIVOS.GET_ARQUIVOS, { params: { casaid } })
);
export const AddArquivo = createAsyncThunk('arquivos/AddArquivo', async (formData: FormData) => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return Axios.post(apiEndpoints.ARQUIVOS.ADD_ARQUIVO, formData, config);
});
export const DeleteArquivo = createAsyncThunk('arquivos/DeleteArquivo', async (arquivoid: number) =>
  Axios.post(apiEndpoints.ARQUIVOS.DELETE_ARQUIVO, { arquivoid })
);
export const DownloadArquivo = createAction('arquivos/DownloadArquivo');
