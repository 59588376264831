import { apiEndpoints } from '@constants/apiEndpoints';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Axios } from '@config/axios';

export const getFisico = createAsyncThunk(
  'Banco/getFisico',
  async (id: number | string) => await Axios.get(apiEndpoints.BANCO.GET_FISICO, { params: { id: id } })
);

export const GetAvancos = createAsyncThunk(
  'Banco/GetAvancos',
  async (id: number | string) => await Axios.get(apiEndpoints.BANCO.GET_AVANCOS, { params: { id: id } })
);
export const getRealizadoMes = createAsyncThunk(
  'Banco/getRealizadoMes',
  async (id: number | string) => await Axios.get(apiEndpoints.BANCO.GET_REALIZADO_MES, { params: { id: id } })
);
export const salvarFisico = createAsyncThunk(
  'Banco/salvarFisico',
  async (data: any) => await Axios.post(apiEndpoints.BANCO.SALVAR_FISICO, data)
);
export const getFisicos = createAsyncThunk(
  'Banco/getFisicos',
  async (data: { ativo: boolean; mes: string }) => await Axios.get(apiEndpoints.BANCO.GET_FISICOS, { params: data })
);
export const getAvancosFisicos = createAsyncThunk(
  'Banco/getAvancosFisicos',
  async (id: number | string) => await Axios.get(apiEndpoints.BANCO.GET_AVANCOS_FISICOS, { params: { id: id } })
);
export const updateAvancoFisico = createAsyncThunk(
  'Banco/updateAvancoFisico',
  async (data: any, thunkAPI) =>
    await Axios.get(apiEndpoints.BANCO.UPDATE_AVANCO_FISICO, { params: data }).catch((res) =>
      thunkAPI.rejectWithValue(res)
    )
);
export const getCategoriaItens = createAsyncThunk(
  'Banco/getCategoriaItens',
  async (data) => await Axios.get(apiEndpoints.BANCO.GET_CATEGORIA_ITENS, { params: data })
);
export const getOrcamentosBanco = createAsyncThunk(
  'Banco/getOrcamentosBanco',
  async (data: any) => await Axios.get(apiEndpoints.BANCO.GET_ORCAMENTOS_BANCO, { params: data })
);
export const getModelos = createAsyncThunk('Banco/getModelos', async () => await Axios.get('/api/v2/Banco/getModelos'));
export const getOrcamentos = createAsyncThunk(
  'Banco/getOrcamentos',
  async () => await Axios.get(apiEndpoints.BANCO.GET_ORCAMENTOS)
);
export const novoOrcamentobanco = createAsyncThunk(
  'Banco/novoOrcamentoBanco',
  async (data: any) => await Axios.post(apiEndpoints.BANCO.NOVO_ORCAMENTO_BANCO, data)
);
export const getOrcamento = createAsyncThunk(
  'Banco/getOrcamento',
  async (id: number | string) => await Axios.get(apiEndpoints.BANCO.GET_ORCAMENTO_BANCO, { params: { id: id } })
);
export const novoItemOrcamentoBanco = createAsyncThunk(
  'Banco/novoItemOrcamentoBanco',
  async (data: any) => await Axios.post(apiEndpoints.BANCO.NOVO_ITEM_ORCAMENTO_BANCO, data)
);
export const deleteOrcamentoBancoItem = createAsyncThunk(
  'Banco/deleteOrcamentoBancoItem',
  async (id: number) => await Axios.get(apiEndpoints.BANCO.DELETE_ORCAMENTO_BANCO_ITEM, { params: { id: id } })
);
export const updateValorTotal = createAsyncThunk(
  'Banco/updateValorTotal',
  async (data: { id: number | string; valor: number }) =>
    await Axios.get(apiEndpoints.BANCO.UPDATE_VALOR_TOTAL, { params: data })
);
export const deleteOrcamentoBanco = createAsyncThunk(
  'Banco/deleteOrcamentoBanco',
  async (id: number) => await Axios.get(apiEndpoints.BANCO.DELETE_ORCAMENTO_BANCO, { params: { id: id } })
);
export const getMedicoes = createAsyncThunk(
  'Banco/getMedicoes',
  async (id: number | string) => await Axios.get(apiEndpoints.BANCO.GET_MEDICOES, { params: { id: id } })
);
export const getResponsaveis = createAsyncThunk(
  'Banco/getResponsaveis',
  async () => await Axios.get(apiEndpoints.BANCO.GET_RESPONSAVEIS)
);
export const novaMedicao = createAsyncThunk(
  'Banco/novaMedicao',
  async (data: any) => await Axios.post(apiEndpoints.BANCO.NOVA_MEDICAO, data)
);
export const updateMedicao = createAsyncThunk(
  'Banco/updateMedicao',
  async (data: any) => await Axios.post(apiEndpoints.BANCO.UPDATE_MEDICAO, data)
);
export const deleteMedicao = createAsyncThunk(
  'Banco/deleteMedicao',
  async (id: number) => await Axios.get(apiEndpoints.BANCO.DELETE_MEDICAO, { params: { id: id } })
);
export const getFluxoObras = createAsyncThunk(
  'Banco/getFluxoObras',
  async (data: { inicial: string; final: string }) =>
    await Axios.get(apiEndpoints.BANCO.GET_FLUXO_OBRAS, { params: data })
);
