import { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import logo from '../../../assets/img/main.png';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { getAuditoria } from '@actions/auditoriasAction';
import '../../../assets/scss/PrintAuditoria.scss';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

function PrintAuditoria() {
  const { auditoria, loadingAuditoria } = useAppSelector((state) => state.auditorias);
  const { user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const { id } = useParams();
  useEffect(() => {
    dispatch(getAuditoria(Number(id)));
  }, [dispatch, id]);
  useEffect(() => {
    if (auditoria && !loadingAuditoria) {
      const body = (
        <div className="auditoria">
          <table className="header-table">
            <thead>
              <tr>
                <td>
                  <header className="header">
                    <img src={user.configuracao.logo || logo} alt="logo" height={100} />
                    <h2>Relatório de Auditoria de Obra</h2>
                    <div className="header-data">
                      <div className="header-data-item">
                        <div className="header-data-item-label">DATA</div>
                        <div className="header-data-item-value">{moment(auditoria.data).format('DD/MM/YYYY')}</div>
                      </div>
                      <div className="header-data-item">
                        <div className="header-data-item-label">OBRA</div>
                        <div className="header-data-item-value">{auditoria.obra?.descricao}</div>
                      </div>
                      <div className="header-data-item">
                        <div className="header-data-item-label">AUDITOR</div>
                        <div className="header-data-item-value">{auditoria.auditor?.nome}</div>
                      </div>
                      <div className="header-data-item">
                        <div className="header-data-item-label">ACOMPANHANTE</div>
                        <div className="header-data-item-value">{auditoria.acompanhante?.nome}</div>
                      </div>
                      <div className="header-data-item">
                        <div className="header-data-item-label">MESTRE</div>
                        <div className="header-data-item-value">{auditoria.mestre?.nome}</div>
                      </div>
                      <div className="header-data-item">
                        <div className="header-data-item-label">RESPONSÁVEL</div>
                        <div className="header-data-item-value">{auditoria.responsavel?.nome}</div>
                      </div>
                      <div className="header-data-item">
                        <div className="header-data-item-label">RESPONDIDO</div>
                        <div className="header-data-item-value">{auditoria.respondido ? 'SIM' : 'NÃO'}</div>
                      </div>
                    </div>
                  </header>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="title">SITUAÇÃO DA OBRA</div>
                  <div className="situacao">
                    <table className="situacao-table">
                      <thead>
                        <tr>
                          <th align="center">LOTE DE APOIO</th>
                          <th align="center">PESSOAS NA OBRA</th>
                          <th align="center">PESSOAL OCIOSO</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td align="center">{auditoria?.loteApoio ? 'SIM' : 'NÃO'}</td>
                          <td align="center">{auditoria?.pessoas}</td>
                          <td align="center">{auditoria?.pessoalOcioso ? 'SIM' : 'NÃO'}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="title">ANDAMENTO DA OBRA</div>
                  <table className="andamento-table">
                    <thead>
                      <tr>
                        <th>ETAPA</th>
                        <th>STATUS</th>
                        <th>NOTAS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(auditoria.andamento).map((c) => (
                        <tr key={c}>
                          <th>{c}</th>
                          <th>{auditoria.andamento[c].status}</th>
                          <th>{auditoria.andamento[c].notas}</th>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="title">AVALIAÇÃO</div>
                  <table className="andamento-table">
                    <thead>
                      <tr>
                        <th>ITEM</th>
                        <th>AVALIAÇÃO</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(auditoria.avaliacao).map((c) => (
                        <tr key={c}>
                          <th>{c}</th>
                          <th>{auditoria.avaliacao[c]}</th>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {auditoria.servicos && (
                    <>
                      <div className="title">SERVIÇOS EM EXECUÇÃO</div>
                      <table className="andamento-table">
                        <thead>
                          <tr>
                            <th>SERVIÇO</th>
                            <th>NOTAS</th>
                          </tr>
                        </thead>
                        <tbody>
                          {auditoria.servicos.map((c: any) => (
                            <tr key={c.servico}>
                              <th>{c.servico}</th>
                              <th>{c.notas}</th>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  )}
                  <div className="title">OBSERVAÇÕES GERAIS</div>
                  <div className="observacoes">{auditoria.notas}</div>
                  <div className="breakline" />
                  <div className="title">RESPOSTA DO RESPONSÁVEL</div>
                  <div className="observacoes">{auditoria.resposta}</div>
                  <div className="title">FOTOS</div>
                  <div className="fotos">
                    {auditoria.fotos.map((c) => (
                      <div className="foto">
                        <img src={c.url} alt={c.fileName} />
                      </div>
                    ))}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
      const root = ReactDOM.createRoot(document.getElementById('root'));
      root.render(body);
      window.print();
    }
  }, [auditoria]);
  return <div />;
}

export default PrintAuditoria;
