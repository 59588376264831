import { Button, Descriptions, Divider, Form, Image, Input, Table } from 'antd';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getAuditoria, responderAuditoria } from '@actions/auditoriasAction';
import { SetPageHeader } from '@actions/userActions';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';

function AuditoriaView() {
  const dispatch = useAppDispatch();
  const { loadingAuditoria, auditoria, savingAuditoria } = useAppSelector((state) => state.auditorias);
  const { id } = useParams();
  const { user } = useAppSelector((state) => state.user);
  useEffect(() => {
    dispatch(getAuditoria(id));
    dispatch(
      SetPageHeader({
        title: 'Auditoria',
        backbutton: true,
        extras: [
          <Link to={'/PrintAuditoria/' + id} key={0} target="_blank">
            <Button ghost type="primary">
              Imprimir
            </Button>
          </Link>,
        ],
        subtitle: 'Auditoria Realizada',
        menu: 'auditorias',
      })
    );
  }, []);
  if (loadingAuditoria && !auditoria) return <div>Carregando...</div>;
  else
    return (
      <div>
        {auditoria && (
          <div>
            <Divider>Informações Gerais</Divider>
            <Descriptions bordered size="small" layout="vertical">
              <Descriptions.Item label="OBRA">{auditoria.obra?.descricao}</Descriptions.Item>
              <Descriptions.Item label="DATA">{moment(auditoria.data).format('DD/MM/YYYY')}</Descriptions.Item>
              <Descriptions.Item label="AUDITOR">{auditoria.auditor?.nome}</Descriptions.Item>
              <Descriptions.Item label="ACOMPANHANTE">{auditoria.acompanhante?.nome}</Descriptions.Item>
              <Descriptions.Item label="MESTRE">{auditoria.mestre?.nome}</Descriptions.Item>
              <Descriptions.Item label="RESPONSÁVEL">{auditoria.responsavel?.nome}</Descriptions.Item>
            </Descriptions>
            <Divider>Situação da Obra</Divider>
            <Descriptions bordered size="small" layout="vertical">
              <Descriptions.Item label="LOTE DE APOIO">{auditoria.loteApoio ? 'SIM' : 'NÃO'}</Descriptions.Item>
              <Descriptions.Item label="PESSOAS NA OBRA">{auditoria.pessoas}</Descriptions.Item>
              <Descriptions.Item label="PESSOAL OCIOSO">{auditoria.pessoalOcioso ? 'SIM' : 'NÃO'}</Descriptions.Item>
            </Descriptions>
            <Divider>Andamento</Divider>
            <Table
              size="small"
              pagination={false}
              dataSource={Object.keys(auditoria.andamento).map((c) => ({
                etapa: c,
                status: auditoria.andamento[c].status,
                notas: auditoria.andamento[c].notas,
              }))}
            >
              <Table.Column title="Etapa" dataIndex="etapa" />
              <Table.Column title="Status" dataIndex="status" />
              <Table.Column title="Notas" dataIndex="notas" />
            </Table>
            <Divider>Avaliacao</Divider>
            <Table
              size="small"
              pagination={false}
              dataSource={Object.keys(auditoria.avaliacao).map((c) => ({
                item: c,
                nota: auditoria.avaliacao[c],
              }))}
            >
              <Table.Column title="Item" dataIndex="item" />
              <Table.Column title="Avaliação" dataIndex="nota" />
            </Table>
            <Divider>Serviços em Execução</Divider>
            {auditoria.servicos && (
              <Table
                size="small"
                pagination={false}
                dataSource={Object.keys(auditoria.servicos).map((c) => ({
                  item: c,
                  servico: auditoria.servicos[c].servico,
                  notas: auditoria.servicos[c].notas,
                }))}
              >
                <Table.Column title="Serviço" dataIndex="servico" />
                <Table.Column title="Notas" dataIndex="notas" />
              </Table>
            )}
            <Divider>Observações Gerais</Divider>
            <Descriptions style={{ backgroundColor: 'white', padding: 5 }}>
              <Descriptions.Item>{auditoria.notas}</Descriptions.Item>
            </Descriptions>
            {auditoria.respondido && (
              <div>
                <Divider>Resposta do responsável</Divider>
                <Descriptions style={{ backgroundColor: 'white', padding: 5 }}>
                  <Descriptions.Item>{auditoria.resposta}</Descriptions.Item>
                </Descriptions>
              </div>
            )}
            {auditoria?.fotos && (
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px 8px' }}>
                <Divider>Fotos</Divider>
                <Image.PreviewGroup>
                  {auditoria.fotos.map((f) => (
                    <Image key={f.fileName} width={100} alt={f.fileName} src={f.url} />
                  ))}
                </Image.PreviewGroup>
              </div>
            )}

            {user.id === auditoria.responsavelID && !auditoria.respondido && (
              <Form
                layout="vertical"
                onFinish={(values) => dispatch(responderAuditoria({ id: id, resposta: values.resposta }))}
              >
                <Form.Item name="resposta" label="Resposta do Responsável">
                  <Input.TextArea
                    rows={10}
                    placeholder="Escreva aqui sua resposta detalhando todos os itens importantes virificados na auditoria"
                  />
                </Form.Item>
                <Button loading={savingAuditoria} type="primary" htmlType="submit">
                  Enviar Resposta
                </Button>
              </Form>
            )}
          </div>
        )}
      </div>
    );
}

export default AuditoriaView;
