import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SetPageHeader } from '@actions/userActions';
import { SearchOutlined, DeleteOutlined, SwapOutlined } from '@ant-design/icons';
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Select,
  Button,
  Checkbox,
  DatePicker,
  Typography,
  List,
  Spin,
  Radio,
  Tooltip,
  Modal,
} from 'antd';
import { cpfMask, mobMask, moneyMask, moneyNegMask } from '@utils/masks';
import { GetLoteamentos } from '@actions/loteamentosActions';
import { GetCorretores } from '@actions/corretoresActions';
import { GetCCAs } from '@actions/ccasActions';
import { GetAgencias } from '@actions/agenciasActions';
import { GetModalidades } from '@actions/modalidadesActions';
import { GetParceiros } from '@actions/parceirosActions';
import moment from 'moment';
import {
  CheckCPF,
  GetCliente,
  AddHistoricoFicha,
  BuscarCasaForm,
  SalvarCliente,
} from '@actions/correspondenciaActions';
import BucarCasa from './BucarCasa';
import { GetProdutos } from '@actions/produtosActions';
import { Axios } from '@config/axios';

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
function FichaCliente(props) {
  const { id } = useParams();
  const [form] = Form.useForm();
  const { loteamentos } = useSelector((state) => state.loteamentos);
  const { corretores } = useSelector((state) => state.corretores);
  const { ccas } = useSelector((state) => state.ccas);
  const { agencias } = useSelector((state) => state.agencias);
  const { modalidades } = useSelector((state) => state.modalidades);
  const { parceiros } = useSelector((state) => state.parceiros);
  const { cliente, loadingcliente } = useSelector((state) => state.correspondencia);
  const [historicoForm] = Form.useForm();
  const [historicoform, sethistoricoform] = useState(false);
  const [swap, setSwap] = useState(false);

  useEffect(() => {
    var temp = [
      form.getFieldValue('clienteNome1'),
      form.getFieldValue('clienteCPF1'),
      form.getFieldValue('clienteTelefone1'),
    ];
    form.setFieldsValue({
      clienteNome1: form.getFieldValue('clienteNome2'),
      clienteCPF1: form.getFieldValue('clienteCPF2'),
      clienteTelefone1: form.getFieldValue('clienteTelefone2'),
      clienteNome2: temp[0],
      clienteCPF2: temp[1],
      clienteTelefone2: temp[2],
    });
  }, [swap, form]);

  useEffect(() => {
    props.dispatch(GetCliente(id));
    props.dispatch(GetLoteamentos());
    props.dispatch(GetCorretores());
    props.dispatch(GetCCAs());
    props.dispatch(GetAgencias());
    props.dispatch(GetModalidades());
    props.dispatch(GetParceiros());
    props.dispatch(GetProdutos());
    props.dispatch(
      SetPageHeader({
        title: 'Correspondência',
        backbutton: true,
        extras: [],
        subtitle: 'Ficha do Cliente',
        menu: 'clientes',
      })
    );
  }, [props, id]);
  useEffect(() => {
    if (cliente) {
      form.setFieldsValue({
        ...cliente,
        dataAssinatura: moment(cliente?.dataAssinatura).isValid() ? moment(cliente?.dataAssinatura) : null,
        dataAprovado: moment(cliente?.dataAprovado).isValid() ? moment(cliente?.dataAprovado) : null,
        dataConformidade: moment(cliente?.dataConformidade).isValid() ? moment(cliente?.dataConformidade) : null,
        dataConforme: moment(cliente?.dataConforme).isValid() ? moment(cliente?.dataConforme) : null,
        competencia: moment(cliente?.competencia).isValid() ? moment(cliente?.competencia) : null,
        valorCompraVenda: moneyMask.format(cliente?.valorCompraVenda),
        valorAvaliacao: moneyMask.format(cliente?.valorAvaliacao),
        valorFinanciamento: moneyMask.format(cliente?.valorFinanciamento),
        valorSubsidio: moneyMask.format(cliente?.valorSubsidio),
        fgts: moneyMask.format(cliente?.fgts),
        valorEntrada: moneyMask.format(cliente?.valorEntrada),
        valorLiquido: moneyMask.format(cliente?.valorLiquido),
        dataSolicitacaoOS: moment(cliente?.dataSolicitacaoOS).isValid() ? moment(cliente?.dataSolicitacaoOS) : null,
      });
    }
  }, [cliente, form]);
  const SubmitForm = (values) => {
    var formdata = new FormData();
    const newValues = {
      ...values,
      valorCompraVenda: moneyMask.normalize(values.valorCompraVenda),
      valorAvaliacao: moneyMask.normalize(values.valorAvaliacao),
      valorFinanciamento: moneyMask.normalize(values.valorFinanciamento),
      valorSubsidio: moneyMask.normalize(values.valorSubsidio),
      fgts: moneyMask.normalize(values.fgts),
      valorLiquido: moneyMask.normalize(values.valorLiquido),
      valorEntrada: moneyMask.normalize(values.valorEntrada),
      dataCadastro: moment(values.dataCadastro).isValid() ? moment(values.dataCadastro).format('YYYY-MM-DD') : '',
      dataConforme: moment(values.dataConforme).isValid() ? moment(values.dataConforme).format('YYYY-MM-DD') : '',
      dataConformidade: moment(values.dataConformidade).isValid()
        ? moment(values.dataConformidade).format('YYYY-MM-DD')
        : '',
      dataAprovado: moment(values.dataAprovado).isValid() ? moment(values.dataAprovado).format('YYYY-MM-DD') : '',
      dataAssinatura: moment(values.dataAssinatura).isValid() ? moment(values.dataAssinatura).format('YYYY-MM-DD') : '',
      competencia: moment(values.competencia).isValid() ? moment(values.competencia).format('YYYY-MM-DD') : '',
    };
    for (var key in newValues) {
      formdata.append(key, newValues[key] || '');
    }

    props.dispatch(SalvarCliente(newValues));
  };

  return (
    <Spin spinning={loadingcliente}>
      <Button onClick={() => form.resetFields()}>Restaurar Mudanças</Button>
      <Button type="primary" onClick={() => form.submit()}>
        Salvar Alterações
      </Button>
      <h3 style={{ float: 'right' }}>Status: {cliente?.status}</h3>
      <Modal
        open={historicoform}
        title="Novo Histórico"
        onCancel={() => sethistoricoform(false)}
        footer={[
          <Button key="1" size="small" onClick={() => sethistoricoform(false)}>
            Fechar
          </Button>,
          <Button key="2" size="small" type="primary" onClick={() => historicoForm.submit()}>
            Salvar
          </Button>,
        ]}
      >
        <Form
          name="historicoForm"
          wrapperCol={{ span: 18 }}
          labelCol={{ span: 6 }}
          form={historicoForm}
          initialValues={{ id: id, data: moment(new Date()) }}
          onFinish={(values) => props.dispatch(AddHistoricoFicha(values))}
        >
          <Form.Item
            label="id"
            name="id"
            style={{ display: 'none' }}
            rules={[{ required: true, message: 'Campo obrigatório' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Data" name="data" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <DatePicker format="DD/MM/YYYY HH:mm" showTime />
          </Form.Item>
          <Form.Item label="Notas" name="notas" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
      {cliente && (
        <Form
          form={form}
          name="geralForm"
          layout="horizontal"
          onFinish={SubmitForm}
          {...formItemLayout}
          onChange={() =>
            form.setFieldsValue({
              valorLiquido: moneyNegMask.format(
                moneyNegMask.normalize(form.getFieldValue('valorFinanciamento')) +
                  moneyNegMask.normalize(form.getFieldValue('valorSubsidio')) +
                  moneyNegMask.normalize(form.getFieldValue('valorEntrada')) +
                  moneyNegMask.normalize(form.getFieldValue('fgts'))
              ),
            })
          }
          initialValues={{
            ...cliente,
            dataAssinatura: moment(cliente?.dataAssinatura).isValid() ? moment(cliente?.dataAssinatura) : null,
            dataAprovado: moment(cliente?.dataAprovado).isValid() ? moment(cliente?.dataAprovado) : null,
            dataConformidade: moment(cliente?.dataConforme).isValid() ? moment(cliente?.dataConformidade) : null,
            dataConforme: moment(cliente?.dataConforme).isValid() ? moment(cliente?.dataConforme) : null,
            competencia: moment(cliente?.competencia).isValid() ? moment(cliente?.competencia) : null,
            valorCompraVenda: moneyMask.format(cliente?.valorCompraVenda),
            valorAvaliacao: moneyMask.format(cliente?.valorAvaliacao),
            valorFinanciamento: moneyMask.format(cliente?.valorFinanciamento),
            valorSubsidio: moneyMask.format(cliente?.valorSubsidio),
            fgts: moneyMask.format(cliente?.fgts),
            valorEntrada: moneyMask.format(cliente?.valorEntrada),
            valorLiquido: moneyMask.format(cliente?.valorLiquido),
            dataSolicitacaoOS: moment(cliente?.dataSolicitacaoOS),
          }}
        >
          <Form.Item name="id" style={{ display: 'none' }} />
          <Form.Item name="casaID" style={{ display: 'none' }} />
          <div>
            <Row gutter={[8, 8]}>
              <Col flex="2 1 350px">
                <Card
                  title="Proponente 1"
                  size="small"
                  extra={<SwapOutlined onClick={() => setSwap(form.getFieldValue('clienteNome1'))} />}
                >
                  <Form.Item label="Nome" name="clienteNome1" wrapperCol={{ span: 18 }} labelCol={{ span: 6 }}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="CPF"
                    name="clienteCPF1"
                    wrapperCol={{ span: 18 }}
                    labelCol={{ span: 6 }}
                    rules={[
                      { required: true, message: 'Campo obrigatório' },
                      {
                        pattern: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
                        message: 'CPF inválido',
                      },
                      {
                        validator: (_, value) => {
                          if (value)
                            if (value.length === 14)
                              return Axios.get('/api/v2/Correspondencia/CheckCPF', {
                                params: {
                                  cpf1: value,
                                  clienteid: id,
                                },
                              });
                          return Promise.resolve();
                        },

                        message: 'CPF já cadastrado',
                      },
                    ]}
                    normalize={(value) => cpfMask.format(cpfMask.normalize(value))}
                  >
                    <Input onBlur={(e) => props.dispatch(CheckCPF(e.target.value))} />
                  </Form.Item>
                  <Form.Item
                    label="Telefone 1"
                    name="clienteTelefone1"
                    wrapperCol={{ span: 18 }}
                    labelCol={{ span: 6 }}
                    normalize={(value) => mobMask.format(mobMask.normalize(value))}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item shouldUpdate noStyle>
                    {({ getFieldValue, setFieldsValue }) => (
                      <Form.Item label="Link Documentação" name="linkPasta" valuePropName="children">
                        <Typography.Link
                          target="_blank"
                          href={getFieldValue('linkPasta')}
                          editable={{ onChange: (v) => setFieldsValue({ linkPasta: v }) }}
                        />
                      </Form.Item>
                    )}
                  </Form.Item>
                  <Form.Item label="Assina esse mês" name="perspectiva" valuePropName="checked">
                    <Checkbox />
                  </Form.Item>
                </Card>
              </Col>
              <Col flex="2 1 350px">
                <Card title="Proponente 2" size="small">
                  <Form.Item label="Nome" name="clienteNome2" wrapperCol={{ span: 18 }} labelCol={{ span: 6 }}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="CPF"
                    name="clienteCPF2"
                    wrapperCol={{ span: 18 }}
                    labelCol={{ span: 6 }}
                    normalize={(value) => cpfMask.format(cpfMask.normalize(value))}
                    rules={[
                      {
                        required: false,
                        pattern: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
                        message: 'CPF inválido',
                      },
                      {
                        validator: (_, value) => {
                          if (value)
                            if (value.length === 14)
                              return Axios.get('/api/v2/Correspondencia/CheckCPF', {
                                params: {
                                  cpf1: value,
                                  clienteid: id,
                                },
                              });
                          return Promise.resolve();
                        },

                        message: 'CPF já cadastrado',
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (value) {
                            if (value.length === 14 && getFieldValue('clienteCPF1') === value) {
                              return Promise.reject('CPFs não podem ser iguais!');
                            }
                          }
                          return Promise.resolve('erro');
                        },
                      }),
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Telefone 2"
                    name="clienteTelefone2"
                    wrapperCol={{ span: 18 }}
                    labelCol={{ span: 6 }}
                    normalize={(value) => mobMask.format(mobMask.normalize(value))}
                  >
                    <Input />
                  </Form.Item>
                </Card>
              </Col>
              <Col flex="2 1 350px">
                <Card title="Checklist" size="small" style={{ minWidth: 350, minHeight: 211 }}>
                  <p>
                    <Form.Item name="clDocumentacao" valuePropName="checked" noStyle>
                      <Checkbox>Documentação</Checkbox>
                    </Form.Item>
                  </p>
                  <p>
                    <Form.Item name="clCadastroSICAQ" valuePropName="checked" noStyle>
                      <Checkbox>Cadastro SICAQ</Checkbox>
                    </Form.Item>
                  </p>
                  <p>
                    <Form.Item name="clConferencia" valuePropName="checked" noStyle>
                      <Checkbox>Conferência</Checkbox>
                    </Form.Item>
                  </p>
                  <p>
                    <Form.Item name="clAprovacao" valuePropName="checked" noStyle>
                      <Checkbox>Aprovação</Checkbox>
                    </Form.Item>
                  </p>
                  <p>
                    <Form.Item name="clSolicitarEngenharia" valuePropName="checked" noStyle>
                      <Checkbox>Solicitar Engenharia</Checkbox>
                    </Form.Item>
                  </p>
                  <p>
                    <Form.Item name="clLaudoSistema" valuePropName="checked" noStyle>
                      <Checkbox>Laudo no Sistema</Checkbox>
                    </Form.Item>
                  </p>
                  <p>
                    <Form.Item name="clDocumentosImovelSistema" valuePropName="checked" noStyle>
                      <Checkbox>Documentos do Imóvel no Sistema</Checkbox>
                    </Form.Item>
                  </p>
                  <p>
                    <Form.Item name="clDocumentosVendedorSistema" valuePropName="checked" noStyle>
                      <Checkbox>Documentos do Vendedor no Sistema</Checkbox>
                    </Form.Item>
                  </p>
                  <p>
                    <Form.Item name="clSIOPI" valuePropName="checked" noStyle>
                      <Checkbox>SIOPI</Checkbox>
                    </Form.Item>
                  </p>
                  <p>
                    <Form.Item name="clPF3" valuePropName="checked" noStyle>
                      <Checkbox>PF3</Checkbox>
                    </Form.Item>
                  </p>
                  <p>
                    <Form.Item name="clEnviarSICTD" valuePropName="checked" noStyle>
                      <Checkbox>Enviar SICTD</Checkbox>
                    </Form.Item>
                  </p>
                  <p>
                    <Form.Item name="clConforme" valuePropName="checked" noStyle>
                      <Checkbox>Conforme</Checkbox>
                    </Form.Item>
                  </p>
                  <p>
                    <Form.Item name="clContratoAssinado" valuePropName="checked" noStyle>
                      <Checkbox>Contrato Assinado</Checkbox>
                    </Form.Item>
                  </p>
                  <p>
                    <Form.Item name="clEnviarGarantia" valuePropName="checked" noStyle>
                      <Checkbox>Enviar Garantia</Checkbox>
                    </Form.Item>
                  </p>
                </Card>
              </Col>
              <Col flex="1 1 300px" style={{ display: 'none' }}>
                <Card title="Cadastro" size="small" style={{ minWidth: 350, minHeight: 211 }}>
                  <Form.Item
                    label="Criador"
                    name="criador"
                    valuePropName="children"
                    wrapperCol={{ span: 18 }}
                    labelCol={{ span: 6 }}
                  >
                    <Typography.Text strong />
                  </Form.Item>
                  <Form.Item
                    label="Cadastro"
                    name="dataCadastro"
                    wrapperCol={{ span: 18 }}
                    labelCol={{ span: 6 }}
                    normalize={(value) => (moment(value).isValid() ? moment(value).format('DD/MM/YYYY HH:mm') : '')}
                  >
                    <Typography.Text strong />
                  </Form.Item>
                  <Form.Item
                    label="Status"
                    name="status"
                    wrapperCol={{ span: 18 }}
                    labelCol={{ span: 6 }}
                    valuePropName="children"
                  >
                    <Typography.Text strong />
                  </Form.Item>
                </Card>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col flex="1 1 33%">
                <Card title="Dados Gerais" size="small" style={{ minWidth: 280, height: 400 }}>
                  <Form.Item
                    label="Modalidade"
                    name="modalidadeCreditoID"
                    rules={[{ required: true, message: 'Campo obrigatório' }]}
                    wrapperCol={{ span: 18 }}
                    labelCol={{ span: 6 }}
                  >
                    <Select optionFilterProp="children" showSearch allowClear>
                      {modalidades.map((c) => (
                        <Select.Option key={c.id} value={c.id}>
                          {c.descricao}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Tipo" name="alocacao" wrapperCol={{ span: 18 }} labelCol={{ span: 6 }}>
                    <Radio.Group>
                      <Radio value={true}>Alocação</Radio>
                      <Radio value={false}>Inidividual</Radio>
                      <Radio value={null}>N/A</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    label="CCA"
                    name="ccaid"
                    normalize={(value) => (value === null ? '' : value)}
                    wrapperCol={{ span: 18 }}
                    labelCol={{ span: 6 }}
                  >
                    <Select optionFilterProp="children" showSearch allowClear>
                      {ccas.map((c) => (
                        <Select.Option key={c.id} value={c.id}>
                          {c.nome}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Agência" name="agenciaID" wrapperCol={{ span: 18 }} labelCol={{ span: 6 }}>
                    <Select optionFilterProp="children" showSearch allowClear>
                      {agencias.map((c) => (
                        <Select.Option key={c.id} value={c.id}>
                          {`Banco: ${c.banco} Agência: ${c.nome} (${c.numero})`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Corretor" name="corretorID" wrapperCol={{ span: 18 }} labelCol={{ span: 6 }}>
                    <Select optionFilterProp="children" showSearch allowClear>
                      {corretores.map((c) => (
                        <Select.Option key={c.id} value={c.id}>
                          {c.nome}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Parceiro" name="parceiroID" wrapperCol={{ span: 18 }} labelCol={{ span: 6 }}>
                    <Select optionFilterProp="children" showSearch allowClear>
                      {parceiros.map((c) => (
                        <Select.Option key={c.id} value={c.id}>
                          {c.nome}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item shouldUpdate noStyle>
                    {({ getFieldValue }) => {
                      return (
                        <Form.Item
                          label="Loteamento"
                          name="loteamentoID"
                          wrapperCol={{ span: 18 }}
                          labelCol={{ span: 6 }}
                        >
                          <Select optionFilterProp="children" showSearch allowClear disabled={getFieldValue('casaID')}>
                            {loteamentos.map((c) => (
                              <Select.Option key={c.id} value={c.id}>
                                {c.nome}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Card>
              </Col>
              <Col flex="1 1 33%">
                <Card title="Processo" size="small" style={{ minWidth: 280, minHeight: 400 }}>
                  <Form.Item name="desistiu" wrapperCol={{ offset: 8 }} valuePropName="checked">
                    <Checkbox>Desistiu</Checkbox>
                  </Form.Item>
                  <Form.Item name="dataAprovado" label="Avaliação">
                    <DatePicker format="DD/MM/YYYY" />
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue, setFieldsValue }) => (
                      <Form.Item name="aprovado" wrapperCol={{ offset: 8 }}>
                        <Radio.Group
                          disabled={!getFieldValue('dataAprovado')}
                          onChange={(e) => (e.target.value ? setFieldsValue({ relacionamento: false }) : null)}
                        >
                          <Radio value={true}>Aprovado</Radio>
                          <Radio value={false}>Reprovado</Radio>
                        </Radio.Group>
                      </Form.Item>
                    )}
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) =>
                      getFieldValue('aprovado') === false && (
                        <Form.Item name="relacionamento" wrapperCol={{ offset: 8 }} valuePropName="checked">
                          <Checkbox>Relacionamento</Checkbox>
                        </Form.Item>
                      )
                    }
                  </Form.Item>
                  <Form.Item name="dataConformidade" label="Conformidade">
                    <DatePicker format="DD/MM/YYYY" />
                  </Form.Item>
                  <Form.Item name="dataConforme" label="Conforme">
                    <DatePicker format="DD/MM/YYYY" />
                  </Form.Item>
                  <Form.Item name="dataAssinatura" label="Assinatura">
                    <DatePicker format="DD/MM/YYYY" />
                  </Form.Item>
                  <Form.Item name="competencia" label="Competência">
                    <DatePicker.MonthPicker format="MMM/YYYY" />
                  </Form.Item>
                </Card>
              </Col>
              <Col flex="1 1 33%">
                <Card title="Valores" size="small" style={{ minWidth: 300, minHeight: 400 }}>
                  <Row>
                    <Col>
                      <Form.Item shouldUpdate noStyle>
                        {({ getFieldValue }) => (
                          <Form.Item
                            wrapperCol={{ span: 16 }}
                            labelCol={{ span: 8 }}
                            label="Avaliação"
                            name="valorAvaliacao"
                            normalize={(value) => moneyMask.format(moneyMask.normalize(value))}
                          >
                            <Input disabled={getFieldValue('casaID')} />
                          </Form.Item>
                        )}
                      </Form.Item>
                      <Form.Item shouldUpdate noStyle>
                        {({ getFieldValue }) => (
                          <Form.Item
                            style={{ display: ' none' }}
                            wrapperCol={{ span: 16 }}
                            labelCol={{ span: 8 }}
                            label="Compra e Venda"
                            name="valorCompraVenda"
                            normalize={(value) => moneyMask.format(moneyMask.normalize(value))}
                          >
                            <Input disabled={getFieldValue('casaID')} />
                          </Form.Item>
                        )}
                      </Form.Item>
                      <Form.Item
                        wrapperCol={{ span: 16 }}
                        labelCol={{ span: 8 }}
                        label="Financiamento"
                        name="valorFinanciamento"
                        normalize={(value) => moneyMask.format(moneyMask.normalize(value))}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        wrapperCol={{ span: 16 }}
                        labelCol={{ span: 8 }}
                        label="Subsídio"
                        name="valorSubsidio"
                        normalize={(value) => moneyMask.format(moneyMask.normalize(value))}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        wrapperCol={{ span: 16 }}
                        labelCol={{ span: 8 }}
                        label="Entrada"
                        name="valorEntrada"
                        normalize={(value) => moneyMask.format(moneyMask.normalize(value))}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        wrapperCol={{ span: 16 }}
                        labelCol={{ span: 8 }}
                        label="FGTS"
                        name="fgts"
                        normalize={(value) => moneyMask.format(moneyMask.normalize(value))}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        wrapperCol={{ span: 16 }}
                        labelCol={{ span: 8 }}
                        label="Valor Líquido"
                        name="valorLiquido"
                        valuePropName="children"
                      >
                        <Typography.Text strong />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col flex="1 1 33%">
                <Card
                  title="Histórico"
                  size="small"
                  style={{ minWidth: 250, height: 430 }}
                  extra={[
                    <Button type="primary" key="1" onClick={() => sethistoricoform(true)} size="small">
                      Novo Histórico
                    </Button>,
                  ]}
                >
                  <div style={{ overflowX: 'scroll', maxHeight: 310 }}>
                    <List
                      size="small"
                      dataSource={cliente?.historico ? cliente?.historico : []}
                      renderItem={(item, index) => (
                        <List.Item key={index}>
                          <List.Item.Meta
                            title={item.notas}
                            description={`${moment(item.data).format('DD/MM/YYYY HH:mm')} - ${item.responsavel}`}
                          />
                        </List.Item>
                      )}
                    />
                  </div>
                </Card>
              </Col>
              <Col flex="1 1 33%">
                <Card title="Unidade Habitacional" size="small" style={{ minWidth: 280 }}>
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                      return (
                        <div>
                          <BucarCasa form={form} />
                          <Form.Item wrapperCol={{ offset: 8 }}>
                            <Button
                              size="small"
                              icon={<SearchOutlined />}
                              onClick={() => props.dispatch(BuscarCasaForm())}
                            >
                              Buscar Casa
                            </Button>
                            <Tooltip title="Dessasignar Casa">
                              <Button
                                size="small"
                                icon={<DeleteOutlined />}
                                onClick={() =>
                                  form.setFieldsValue({
                                    casaID: null,
                                    valorAvaliacao: moneyMask.format(cliente?.valorAvaliacao),
                                    valorCompraVenda: moneyMask.format(cliente?.valorCompraVenda),
                                    loteamentoID: cliente?.loteamentoID,
                                    endereco: '',
                                    empreendimento: '',
                                    art: '',
                                    matricula: '',
                                  })
                                }
                              />
                            </Tooltip>
                          </Form.Item>
                          <Form.Item label="Data Solcitação OS" name="dataSolicitacaoOS">
                            <DatePicker format="DD/MM/YYYY" />
                          </Form.Item>
                          <Form.Item hidden label="Endereço" name="endereco">
                            <Input disabled={getFieldValue('casaID')} />
                          </Form.Item>
                          <Form.Item label="Matrícula" name="matricula">
                            <Input disabled={getFieldValue('casaID')} />
                          </Form.Item>
                          {getFieldValue('casaID') && (
                            <Form.Item label="Empreendimento" name="empreendimento">
                              <Input disabled={getFieldValue('casaID')} />
                            </Form.Item>
                          )}
                          <Form.Item hidden label="ART" name="art">
                            <Input disabled={getFieldValue('casaID')} />
                          </Form.Item>
                          <Form.Item label="OS" name="os">
                            <Input />
                          </Form.Item>
                          <Form.Item label="Observações" name="observacaoCasa">
                            <Input.TextArea />
                          </Form.Item>
                        </div>
                      );
                    }}
                  </Form.Item>
                </Card>
              </Col>
            </Row>
          </div>
        </Form>
      )}
    </Spin>
  );
}

export default connect()(FichaCliente);
