import { AprovarPedido, GetPendentes } from '@actions/pedidosActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Button, Space, Table } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { CheckOutlined, StopOutlined, ShoppingCartOutlined, EyeOutlined } from '@ant-design/icons';
import PedidoItensModal from './PedidoItensModal';
import { SetPageHeader } from '@actions/userActions';
import CancelPedidoModalRedux from '../shared/CancelPedidoModalRedux';
import { ViewCancelForm } from '@actions/novaOrdemActions';
import { PedidoList } from '@models/Pedidos';
import { Link } from 'react-router-dom';

function PedidosPendentes() {
  const { pedidosPendentes, loadingPendentes, aprovandoPedido, cancelandoPedido } = useAppSelector(
    (state) => state.pedidos
  );
  const [viewItens, setViewItens] = useState<{ visible: boolean; pedidoID: number }>({
    visible: false,
    pedidoID: null,
  });
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(GetPendentes());
    dispatch(
      SetPageHeader({
        title: 'Pedidos Pendentes de Aprovação',
        subtitle: '',
        extras: [],
        menu: 'aprovarpedidos',
        backbutton: true,
      })
    );
  }, []);
  return (
    <div>
      <PedidoItensModal item={viewItens} setItem={setViewItens} />
      <CancelPedidoModalRedux />
      <Table
        size="small"
        dataSource={pedidosPendentes}
        loading={loadingPendentes || aprovandoPedido || cancelandoPedido}
        rowKey="id"
        scroll={{ y: 'calc(100vh - 220px)' }}
      >
        <Table.Column
          dataIndex="id"
          width={120}
          render={(v, row: PedidoList) => (
            <Space>
              <Button size="small" icon={<CheckOutlined />} onClick={() => dispatch(AprovarPedido(v))} />
              <Button
                size="small"
                icon={<StopOutlined />}
                onClick={() => dispatch(ViewCancelForm({ view: true, id: v, replace: false, cod: row.cod }))}
              />
              <Button
                size="small"
                icon={<ShoppingCartOutlined />}
                onClick={() => setViewItens({ visible: true, pedidoID: v })}
              />
              <Link to={`/Pedidos/${v}`}>
                <Button size="small" icon={<EyeOutlined />} />
              </Link>
            </Space>
          )}
        />
        <Table.Column title="Pedido Nº" dataIndex="cod" width={100} />
        <Table.Column title="Solicitante" dataIndex="comprador" />
        <Table.Column title="Orçamento" dataIndex="orcamento" />
        <Table.Column title="Data Criação" dataIndex="dataCriacao" render={(v) => moment(v).format('DD/MM/YYYY')} />
        <Table.Column
          title="Prazo"
          dataIndex="prazo"
          render={(v) => moment(v).isValid() && moment(v).format('DD/MM/YYYY')}
        />
      </Table>
    </div>
  );
}

export default PedidosPendentes;
