import { GetNovosCadastrosMes } from '@services/api/api.correspondencia';
import { Card, Spin } from 'antd';
import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
interface Props {
  mes: moment.Moment;
}
function DashboardNovosContratosMes({ mes }: Props) {
  const { data, isLoading } = GetNovosCadastrosMes(mes);
  const options: ApexOptions = {
    title: {
      text: 'Novos Cadastros por Mês',
    },
    subtitle: {
      text: 'Novos cadastros realizados mensalmente',
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: 'top', // top, center, bottom
        },
      },
    },
    dataLabels: {
      offsetY: -20,
      style: {
        colors: ['#304758'],
      },
    },
    xaxis: {
      categories: data.map((c) => `${c.mes}/${c.ano}`),
    },
  };
  const series: ApexAxisChartSeries = [
    {
      name: 'Contratos',
      data: data.map((c) => c.value),
    },
  ];
  return (
    <Card>
      <Spin spinning={isLoading}>
        <Chart options={options} series={series} type="bar" width="500" />
      </Spin>
    </Card>
  );
}

export default DashboardNovosContratosMes;
