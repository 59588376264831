import { Button, Form, Image, notification, Progress, Upload } from 'antd';
import { useEffect } from 'react';
import { useState } from 'react';
import { SetPageHeader } from '@actions/userActions';
import { Axios } from '@config/axios';
import { UploadOutlined } from '@ant-design/icons';
import defaultLogo from '../../../assets/img/main.png';
import { useAppDispatch } from '@config/reduxStore';
import { RcFile } from 'antd/lib/upload';

function LogoView() {
  const dispatch = useAppDispatch();
  const [saving, setSaving] = useState<boolean>(false);
  const [files, upFile] = useState<RcFile[]>([]);
  const [progress, setProgress] = useState<number>(0);
  const [logo, setLogo] = useState<any>(null);
  useEffect(() => {
    Axios.get('/api/v2/Usuarios/GetLogo').then((res) => setLogo(res.data));
    dispatch(
      SetPageHeader({
        title: 'Configuração',
        backbutton: true,
        extras: [],
        subtitle: 'Logomarca que aparecerá nos documentos exportados',
        menu: 'logo',
      })
    );
  }, []);

  return (
    <div>
      {saving && <Progress percent={progress} />}
      <Form>
        <Form.Item label="Imagem">
          <Upload
            name="arquivo"
            disabled={saving}
            style={{ display: 'inline' }}
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            multiple={true}
            beforeUpload={(_, fileList) => {
              upFile([...files, ...fileList]);
              Promise.resolve(false);
            }}
            accept=".jpg,.jpeg,.png"
            onRemove={(file) => {
              Promise.resolve(false);
              upFile(files.filter((f) => f !== file));
            }}
            fileList={files}
          >
            <Button icon={<UploadOutlined />} size="small">
              Selecionar Imagem
            </Button>
          </Upload>
          <Button
            style={{ display: 'inline' }}
            type="primary"
            size="small"
            loading={saving}
            disabled={files.length === 0}
            onClick={() => {
              const formdata = new FormData();
              files.map((f) => formdata.append('files', f, f.name));
              setSaving(true);
              Axios.post('/api/v2/Usuarios/PostLogo', formdata, {
                onUploadProgress: (event) => {
                  let progresso = Math.round((event.loaded * 100) / event.total);
                  setProgress(progresso);
                },
              })
                .then((res) => {
                  setLogo(res.data);
                  setSaving(false);
                  notification.success({ message: 'Logo atualizada com sucesso' });
                })
                .catch(() => {
                  setSaving(false);
                  notification.error({ message: 'Erro ao enviar imagem' });
                });
              upFile([]);
            }}
          >
            Enviar
          </Button>
        </Form.Item>
      </Form>
      {!logo && <Image width={100} src={defaultLogo} alt="logo" />}
      {logo && <Image width={100} src={logo} alt="logo" />}
    </div>
  );
}

export default LogoView;
