import { useEffect } from 'react';
import { Modal, Form, Input, Select, Button } from 'antd';
import { SalvarLoteamento, FormLoteamento } from '@actions/loteamentosActions';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Loteamento } from '@models/Loteamento';

function LoteamentoForm() {
  const dispatch = useAppDispatch();
  const loteamentos = useAppSelector((state) => state.loteamentos);
  const [form] = Form.useForm<Loteamento>();
  const submitForm = (values: Loteamento) => {
    dispatch(SalvarLoteamento(values));
  };
  useEffect(() => {
    form.resetFields();
    if (loteamentos.loteamento) {
      form.setFieldsValue(loteamentos.loteamentos.find((c) => c.id === loteamentos.loteamento));
    }
  }, [loteamentos.loteamento, loteamentos.loteamentos, form]);
  return (
    <div>
      <Modal
        open={loteamentos.formview}
        onCancel={() => dispatch(FormLoteamento(null, false))}
        title="Cadastro Loteamento"
        footer={[
          <Button onClick={() => dispatch(FormLoteamento(null, false))} key="2">
            Fechar
          </Button>,
          <Button key="3" type="primary" onClick={() => form.submit()} loading={loteamentos.savingloteamento}>
            Salvar
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form} onFinish={submitForm} name="loteamentoForm">
          <Form.Item style={{ display: 'none' }} name="id">
            <Input />
          </Form.Item>
          <Form.Item label="Nome" name="nome" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Estado" name="estado" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Select showSearch>
              <Select.Option value="Acre">Acre</Select.Option>
              <Select.Option value="Alagoas">Alagoas</Select.Option>
              <Select.Option value="Amazonas">Amazonas</Select.Option>
              <Select.Option value="Amapá">Amapá</Select.Option>
              <Select.Option value="Bahia">Bahia</Select.Option>
              <Select.Option value="Ceará">Ceará</Select.Option>
              <Select.Option value="Distrito Federal">Distrito Federal</Select.Option>
              <Select.Option value="Espírito Santo">Espírito Santo</Select.Option>
              <Select.Option value="Goiás">Goiás</Select.Option>
              <Select.Option value="Maranhão">Maranhão</Select.Option>
              <Select.Option value="Mato Grosso">Mato Grosso</Select.Option>
              <Select.Option value="Mato Grosso do Sul">Mato Grosso do Sul</Select.Option>
              <Select.Option value="Minas Gerais">Minas Gerais</Select.Option>
              <Select.Option value="Pará">Pará</Select.Option>
              <Select.Option value="Paraíba">Paraíba</Select.Option>
              <Select.Option value="Paraná">Paraná</Select.Option>
              <Select.Option value="Pernambuco">Pernambuco</Select.Option>
              <Select.Option value="Piauí">Piauí</Select.Option>
              <Select.Option value="Rio de Janeiro">Rio de Janeiro</Select.Option>
              <Select.Option value="Rio Grande do Norte">Rio Grande do Norte</Select.Option>
              <Select.Option value="Rondônia">Rondônia</Select.Option>
              <Select.Option value="Rio Grande do Sul">Rio Grande do Sul</Select.Option>
              <Select.Option value="Roraima">Roraima</Select.Option>
              <Select.Option value="Santa Catarina">Santa Catarina</Select.Option>
              <Select.Option value="Sergipe">Sergipe</Select.Option>
              <Select.Option value="São Paulo">São Paulo</Select.Option>
              <Select.Option value="Tocantins">Tocantins</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Cidade" name="cidade" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
export default LoteamentoForm;
