import { ReactNode } from 'react';
import { useAppSelector } from '@config/reduxStore';

export function Permission({ permission, children }: { permission: string; children: ReactNode | ReactNode[] }) {
  const permissions = useAppSelector((state) => state.user.user.permissions);
  if (permissions.find((c) => c === permission)) return <>{children}</>;
  else return <div></div>;
}
export function HasPermission(permission: string, userpermissions: string[]) {
  if (userpermissions.find((c) => c === permission)) return true;
  else return false;
}
export function Role({
  roles,
  children,
  component,
}: {
  roles: string[];
  children: ReactNode | ReactNode[];
  component?: ReactNode | ReactNode[];
}) {
  const userroles = useAppSelector((state) => state.user.user?.roles);
  let found = userroles.some((v) => roles.indexOf(v) !== -1);
  if (roles.find((c) => c === 'all')) found = true;
  if (found) return <>{component || children}</>;
  else return null;
}
export function HasRole(roles: string[], userroles: string[] = []) {
  if (userroles?.length === 0) return false;
  let found = userroles.some((v) => roles.includes(v));
  if (roles.find((c) => c === 'all')) found = true;
  return found;
}
