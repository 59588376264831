import { DatePicker, Form, Input, Modal, Select, TreeSelect, Typography } from 'antd';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { getOrcamentosMeta, salvarMeta } from '@actions/metasActions';
import { doubleMask1, moneyMask } from '@utils/masks';
import moment from 'moment';
import { naturalSorter } from '@utils/filterandsort';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { MetaOrcamento } from '@models/MetaOrcamento';

function NovaMetaModal({
  visible,
  setVisible,
  item,
}: {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  item: MetaOrcamento;
}) {
  const dispatch = useAppDispatch();
  const { orcamentos, loadingOrcamentos, loadingMetas } = useAppSelector((state) => state.metas);
  const [form] = Form.useForm();
  useEffect(() => {
    dispatch(getOrcamentosMeta());
  }, []);
  useEffect(() => {
    if (item) {
      form.setFieldsValue({ ...item, valor: moneyMask.format(item.valor), mes: moment(item.mes) });
    } else form.resetFields();
  }, [item]);
  return (
    <Modal
      title="Nova Meta"
      open={visible}
      onCancel={() => setVisible(false)}
      okText="Salvar"
      onOk={() => form.submit()}
      okButtonProps={{ loading: loadingMetas }}
    >
      <Form
        form={form}
        onFinish={(values) => dispatch(salvarMeta({ ...values, valor: moneyMask.normalize(values.valor) }))}
        labelCol={{ span: 4 }}
      >
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="mes" label="Mês" initialValue={moment()}>
          <DatePicker.MonthPicker format="MM/YYYY" />
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {({ setFieldValue }) => (
            <Form.Item name="orcamentoID" label="Obra" rules={[{ required: true, message: 'Campo obrigatório' }]}>
              <Select
                loading={loadingOrcamentos}
                optionFilterProp="children"
                showSearch
                placeholder="Selecionar obra"
                onChange={() => setFieldValue('grupoID', null)}
              >
                {orcamentos.map((c) => (
                  <Select.Option key={c.id} value={c.id} on>
                    {c.descricao}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            if (getFieldValue('orcamentoID')) {
              const grupos = orcamentos.find((c) => c.id === getFieldValue('orcamentoID'))?.grupos;
              const categorias = [...new Set(grupos.map((c) => c.categoriaID))]
                .map((c) => ({
                  value: c,
                  selectable: false,
                  title: grupos.find((d) => d.categoriaID === c)?.categoria,
                  children: grupos
                    .filter((d) => d.categoriaID === c)
                    .map((d) => ({
                      value: d.id,
                      descricao: d.descricao,
                      title: (
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div>{d.descricao}</div>
                          <div>{moneyMask.format(d.orcado)}</div>
                        </div>
                      ),
                    }))
                    .sort((a: { descricao: string }, b: { descricao: string }) =>
                      naturalSorter(a.descricao, b.descricao)
                    ),
                }))
                .sort((a: any, b: any) => naturalSorter(a.title, b.title));
              return (
                getFieldValue('orcamentoID') && (
                  <Form.Item name="grupoID" label="Grupo" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                    <TreeSelect
                      showSearch
                      placeholder="Selecionar grupo"
                      treeData={categorias}
                      treeNodeFilterProp="descricao"
                    />
                  </Form.Item>
                )
              );
            } else return null;
          }}
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) =>
            getFieldValue('orcamentoID') &&
            getFieldValue('grupoID') && (
              <Form.Item label="Orçado">
                <Typography.Text>
                  {moneyMask.format(
                    orcamentos
                      .find((c) => c.id === getFieldValue('orcamentoID'))
                      ?.grupos.find((c) => c.id === getFieldValue('grupoID')).orcado
                  )}
                </Typography.Text>
              </Form.Item>
            )
          }
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) =>
            getFieldValue('orcamentoID') &&
            getFieldValue('grupoID') && (
              <Form.Item label="Realizado">
                <Typography.Text>
                  {moneyMask.format(
                    orcamentos
                      .find((c) => c.id === getFieldValue('orcamentoID'))
                      ?.grupos.find((c) => c.id === getFieldValue('grupoID')).realizado
                  )}
                </Typography.Text>
              </Form.Item>
            )
          }
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) =>
            getFieldValue('orcamentoID') &&
            getFieldValue('grupoID') && (
              <Form.Item
                label="Meta"
                name="valor"
                initialValue={moneyMask.format(0)}
                normalize={(v) => moneyMask.format(moneyMask.normalize(v))}
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input />
              </Form.Item>
            )
          }
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) =>
            getFieldValue('orcamentoID') &&
            getFieldValue('grupoID') && (
              <Form.Item label="Redução" initialValue={doubleMask1.format(0)}>
                <Typography.Text>
                  {doubleMask1.format(
                    (100 *
                      (orcamentos
                        .find((c) => c.id === getFieldValue('orcamentoID'))
                        ?.grupos.find((c) => c.id === getFieldValue('grupoID')).orcado -
                        Number(moneyMask.normalize(getFieldValue('valor'))))) /
                      orcamentos
                        .find((c) => c.id === getFieldValue('orcamentoID'))
                        ?.grupos.find((c) => c.id === getFieldValue('grupoID')).orcado
                  )}
                </Typography.Text>
              </Form.Item>
            )
          }
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default NovaMetaModal;
