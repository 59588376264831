import { AppDispatch } from '@config/reduxStore';
import { apiEndpoints } from '@constants/apiEndpoints';
import { Axios } from '@config/axios';
export const GetProdutos = function () {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'GET_PRODUTOS',
      payload: Axios.get(apiEndpoints.PRODUTOS.GET_PRODUTOS),
    });
  };
};
export const SalvarProduto = function (values: any) {
  return function (dispatch: AppDispatch) {
    if (values.id) {
      dispatch({
        type: 'UPDATE_PRODUTO',
        payload: Axios.post(apiEndpoints.PRODUTOS.SALVAR_PRODUTO, { ...values }),
      });
    } else {
      dispatch({
        type: 'ADD_PRODUTO',
        payload: Axios.post(apiEndpoints.PRODUTOS.SALVAR_PRODUTO, { ...values }),
      });
    }
  };
};
export const DeleteProduto = function (id: number) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'DELETE_PRODUTO',
      payload: Axios.get(apiEndpoints.PRODUTOS.DELETE_PRODUTO, { params: { id } }),
    });
  };
};
export const FormProduto = function (id: number, open: boolean) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: 'FORM_PRODUTO',
      payload: { id, open },
    });
  };
};
