import { Button, Input, Table, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { GetAprovadores, SetPageHeader } from '@actions/userActions';
import { filterobject, getColumnSearchProps, naturalSorter, orderDate, sortAlpha } from '@utils/filterandsort';
import { Permission } from '@hooks//usePermissions';
import { EyeOutlined, CheckOutlined, StopOutlined } from '@ant-design/icons';
import moment from 'moment';
import { AprovarFolha, GetFolhasPendentes, ViewCancelForm } from '@actions/folhasActions';
import { moneyMask } from '@utils/masks';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { ColumnsType } from 'antd/lib/table';
import { FolhaList } from '@models/Folha';
import CancelFolhaModalRedux from './Shared/CancelFolhaModalRedux';
import { ordemType } from '@constants/ordemStatus';

function FolhasPendentes() {
  const dispatch = useAppDispatch();
  const [filter, setFilter] = useState('');
  const [filters, setFilters] = useState(null);
  const { folhasPendentes, loadingPendentes } = useAppSelector((state) => state.folhas);

  useEffect(() => {
    dispatch(GetFolhasPendentes());
    dispatch(GetAprovadores(ordemType.folha));
    dispatch(
      SetPageHeader({
        title: 'Engenharia',
        backbutton: true,
        extras: [],
        subtitle: 'Folhas pendentes de aprovação',
        menu: 'aprovarfolhas',
      })
    );
  }, [dispatch]);
  const columns: ColumnsType<FolhaList> = [
    {
      width: 90,
      fixed: 'left',
      render: (_, row) => (
        <div>
          <Link to={`/Folhas/${row.id}`}>
            <Button size="small" icon={<EyeOutlined />} />
          </Link>
          <Permission permission="ordem.aprove">
            <Tooltip title="Aprovar Folha">
              <Button icon={<CheckOutlined />} size="small" onClick={() => dispatch(AprovarFolha({ id: row.id }))} />
            </Tooltip>
          </Permission>
          <Tooltip title="Cancelar Ordem">
            <Button
              icon={<StopOutlined />}
              size="small"
              onClick={() => dispatch(ViewCancelForm({ view: true, id: row.id }))}
            />
          </Tooltip>
        </div>
      ),
    },
    {
      title: 'Nº Folha',
      dataIndex: 'cod',
      sorter: (a: FolhaList, b: FolhaList) => naturalSorter(a.cod, b.cod),
      ...getColumnSearchProps('cod', 'string', 'ID', filters),
    },
    {
      title: 'Descricao',
      dataIndex: 'descricao',
      sorter: (a, b) => sortAlpha(a.descricao, b.descricao),
      ...getColumnSearchProps('descricao', 'string', 'Descrição', filters),
    },
    {
      title: 'Tipo',
      dataIndex: 'tipo',
      sorter: (a, b) => a.tipo - b.tipo,
      filters: [
        { value: 0, text: 'Adiantamento' },
        { value: 1, text: 'Medição' },
      ],
      filteredValue: filters ? filters.filters.tipo : null,
      render: (text) => (text === 0 ? 'Adiantamento' : 'Medição'),
      onFilter: (value, row) => row.tipo === value,
    },
    {
      title: 'Data Criação',
      dataIndex: 'dataCriacao',
      sorter: (a, b) => orderDate(a.dataCriacao, b.dataCriacao),
      ...getColumnSearchProps('dataCriacao', 'daterange', 'Data de Criação', filters),
      render: (text) => (moment(text).isValid() ? moment(text).format('DD/MM/YYYY') : ''),
    },
    {
      title: 'Mês',
      dataIndex: 'mesCorrente',
      sorter: (a, b) => orderDate(a.mesCorrente, b.mesCorrente),
      ...getColumnSearchProps('fim', 'daterange', 'Fim', filters),
      render: (text) => (moment(text).isValid() ? moment(text).format('MM/YYYY') : ''),
    },
    {
      title: 'Orçamento',
      dataIndex: 'orcamento',
      sorter: (a, b) => sortAlpha(a.orcamento, b.orcamento),
      ...getColumnSearchProps('orcamento', 'string', 'Orçamento', filters),
    },
    {
      title: 'Valor Total',
      dataIndex: 'total',
      sorter: (a, b) => a.total - b.total,
      ...getColumnSearchProps('total', 'string', 'Valor Total', filters),
      render: (text) => moneyMask.format(text),
    },
  ];
  return (
    <div>
      <CancelFolhaModalRedux />
      <div style={{ textAlign: 'right' }}>
        <Input style={{ width: 200 }} onChange={(e) => setFilter(e.target.value)} placeholder="Buscar" allowClear />
      </div>
      <Table
        size="small"
        rowKey="id"
        bordered
        onChange={(paginantion, filters, sorter) => setFilters({ paginantion, filters, sorter })}
        loading={loadingPendentes}
        columns={columns}
        dataSource={filterobject(folhasPendentes, filter)}
        scroll={{ y: 'calc(100vh - 300px)', x: 1200 }}
        pagination={{
          position: ['bottomRight'],
          size: 'small',
          showSizeChanger: true,
          pageSizeOptions: [10, 25, 50, 100],
          showTotal: (total, range) => `Mostrando ${range[0]} a ${range[1]} de ${total} itens`,
        }}
      />
    </div>
  );
}
export default FolhasPendentes;
