import OrcamentoItemSelect from '@components/OrcamentoItemSelect';
import OrcamentoSelect from '@components/OrcamentoSelect';
import { doubleMask, moneyMask } from '@utils/masks';
import { Button, Col, Form, Input, Modal, Row, Typography } from 'antd';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import OrdemItemInfo from './OrdemForm.ItemInfo';
import { AddItemOrdem } from '@services/api/api.ordem';
import { useOrdemForm } from './useOrdemForm';
import { OrdemItem } from '@models/OrdemItem';

interface AddItemFormData {
  id?: number;
  orcamentoID: number;
  orcamentoItemID: number;
  quantidade: string;
  valorUnitario: string;
  desconto: string;
  ordemID: number;
  descricao: string;
}
interface OrdemAddItemModalProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  item?: OrdemItem;
}
function OrdemAddItemModal({ visible, setVisible, item = null }: OrdemAddItemModalProps) {
  const [addItemForm] = Form.useForm<AddItemFormData>();
  const [infoOpen, setInfoOpen] = useState(false);
  const addItem = AddItemOrdem();
  const { form } = useOrdemForm();

  useEffect(() => {
    if (addItem.isSuccess) {
      setVisible(false);
    }
  }, [addItem.isSuccess]);
  useEffect(() => {
    if (item) {
      addItemForm.setFieldsValue({
        orcamentoID: item.orcamentoID,
        orcamentoItemID: item.orcamentoItemID,
        desconto: moneyMask.format(item.desconto),
        descricao: item.descricao,
        valorUnitario: moneyMask.format(item.valorUnitario),
        quantidade: doubleMask.format(item.quantidade),
        id: item.id,
      });
    } else {
      addItemForm.resetFields();
    }
  }, [item]);
  return (
    <Modal
      open={visible}
      onCancel={() => setVisible(false)}
      title="Adicionar Item"
      width={infoOpen ? 1000 : undefined}
      onOk={() => addItemForm.submit()}
      cancelText="Fechar"
      okButtonProps={{ loading: addItem.isLoading && !addItem.isError }}
    >
      <Form
        onFinish={(values) => {
          addItem.mutate({
            ...values,
            ordemID: form.getFieldValue('id'),
            valorUnitario: moneyMask.normalize(values.valorUnitario.toString()),
            quantidade: doubleMask.normalize(values.quantidade.toString()),
            desconto: moneyMask.normalize(values.desconto.toString()),
          });
        }}
        form={addItemForm}
        name="addItem"
        layout="vertical"
        initialValues={{
          valorUnitario: moneyMask.format(0),
          desconto: moneyMask.format(0),
          quantidade: doubleMask.format(0),
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '6px' }}>
          <div style={{ maxWidth: 475 }}>
            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {({ setFieldValue }) => (
                <Form.Item
                  name="orcamentoID"
                  label="Orçamento"
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                >
                  <OrcamentoSelect
                    placeholder="Selecione um orçamento..."
                    onChange={() => {
                      setFieldValue('orcamentoItemID', null);
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <Form.Item
                  name="orcamentoItemID"
                  label="Item"
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                >
                  <OrcamentoItemSelect orcamentoID={getFieldValue('orcamentoID')} placeholder="Selecione um item..." />
                </Form.Item>
              )}
            </Form.Item>
            <Row gutter={[8, 8]}>
              <Col span={6}>
                <Form.Item
                  name="valorUnitario"
                  label="Valor Unitário"
                  normalize={(v) => moneyMask.format(moneyMask.normalize(v))}
                  rules={[
                    { required: true, message: 'Campo obrigatório' },
                    {
                      validator: async (_, valor) => {
                        if (moneyMask.normalize(valor) === 0) {
                          return Promise.reject(new Error('Valor zero'));
                        }
                        return null;
                      },
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="desconto"
                  label="Desconto"
                  normalize={(v) => moneyMask.format(moneyMask.normalize(v))}
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="quantidade"
                  label="Quantidade"
                  normalize={(v) => doubleMask.format(doubleMask.normalize(v))}
                  rules={[
                    { required: true, message: 'Campo obrigatório' },
                    {
                      validator: async (_, valor) => {
                        if (doubleMask.normalize(valor) === 0) {
                          return Promise.reject(new Error('Valor zero'));
                        }
                        return null;
                      },
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    const valorUnitario = moneyMask.normalize(getFieldValue('valorUnitario'));
                    const quantidade = doubleMask.normalize(getFieldValue('quantidade'));
                    const desconto = moneyMask.normalize(getFieldValue('desconto'));
                    const result = moneyMask.format((valorUnitario - desconto) * quantidade);
                    return (
                      <Form.Item label="Total" valuePropName="children">
                        <Typography.Text>{result}</Typography.Text>
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name="descricao" label="Descrição">
              <Input placeholder="Especificar melhor o item do orçamento..." />
            </Form.Item>
          </div>
          <div>
            <Form.Item noStyle shouldUpdate={(nv, pv) => nv.orcamentoItemID !== pv.orcamentoItemID}>
              {({ getFieldValue }) => {
                return (
                  <Button
                    disabled={!getFieldValue('orcamentoItemID')}
                    onClick={() => setInfoOpen(!infoOpen)}
                    size="small"
                    icon={infoOpen ? <ArrowLeftOutlined /> : <ArrowRightOutlined />}
                    style={{ height: 60, width: 16 }}
                  />
                );
              }}
            </Form.Item>
          </div>
          {infoOpen && (
            <div>
              <Form.Item noStyle shouldUpdate={(nv, pv) => nv.orcamentoItemID !== pv.orcamentoItemID}>
                {({ getFieldValue }) => {
                  if (getFieldValue('orcamentoItemID') !== null)
                    return <OrdemItemInfo id={getFieldValue('orcamentoItemID')} />;
                  else return null;
                }}
              </Form.Item>
            </div>
          )}
        </div>
      </Form>
    </Modal>
  );
}
export default OrdemAddItemModal;
