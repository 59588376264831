import { useEffect } from 'react';
import { Modal, Form, Input, Button, Row, Col, Radio, Select } from 'antd';
import { SalvarMaterial, FormMaterial } from '@actions/materiaisActions';
import { moneyMask } from '@utils/masks';
import { useAppDispatch, useAppSelector } from '@config/reduxStore';
import { Item } from '@models/Item';

function MaterialForm() {
  const dispatch = useAppDispatch();
  const materiais = useAppSelector((state) => state.materiais);
  const [form] = Form.useForm<Item>();
  const submitForm = (values: Item) => {
    dispatch(SalvarMaterial({ ...values, valorUnitario: moneyMask.normalize(values.valorUnitario as string) }));
  };
  useEffect(() => {
    if (materiais.material) {
      form.resetFields();
      form.setFieldsValue({
        ...materiais.materiais.find((c) => c.id === materiais.material),
        valorUnitario: moneyMask.format(materiais.materiais.find((c) => c.id === materiais.material).valorUnitario),
      });
    } else form.resetFields();
  }, [materiais.material, materiais.materiais, form]);
  return (
    <div>
      <Modal
        open={materiais.formview}
        onCancel={() => dispatch(FormMaterial(null, false))}
        title="Cadastro Material"
        footer={[
          <Button onClick={() => dispatch(FormMaterial(null, false))} key="2">
            Fechar
          </Button>,
          <Button key="3" type="primary" onClick={() => form.submit()} loading={materiais.savingmaterial}>
            Salvar
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form} onFinish={submitForm} name="materialForm" initialValues={{ tipo: 0 }}>
          <Form.Item style={{ display: 'none' }} name="id">
            <Input />
          </Form.Item>
          <Row gutter={8}>
            <Col span={18}>
              <Form.Item label="Descrição" name="descricao" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Unidade" name="unidade" rules={[{ required: true, message: 'Campo obrigatório' }]}>
                <Select>
                  <Select.Option value="MT">MT</Select.Option>
                  <Select.Option value="M²">M²</Select.Option>
                  <Select.Option value="M³">M³</Select.Option>
                  <Select.Option value="SC">SC</Select.Option>
                  <Select.Option value="PC">PC</Select.Option>
                  <Select.Option value="KG">KG</Select.Option>
                  <Select.Option value="UND">UND</Select.Option>
                  <Select.Option value="Casa">Casa</Select.Option>
                  <Select.Option value="VB">VB</Select.Option>
                  <Select.Option value="CAR">CAR</Select.Option>
                  <Select.Option value="L">L</Select.Option>
                  <Select.Option value="TON">TON</Select.Option>
                  <Select.Option value="H">H</Select.Option>
                  <Select.Option value="Dia">Dia</Select.Option>
                  <Select.Option value="Mês">Mês</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Tipo" name="tipo">
            <Radio.Group>
              <Radio value={0}>Material</Radio>
              <Radio value={1}>Serviço</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Valor Unitário"
            name="valorUnitario"
            rules={[{ required: true, message: 'Campo Obrigatório' }]}
            normalize={(v) => moneyMask.format(moneyMask.normalize(v))}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
export default MaterialForm;
