import LoadingText from '@components/LoadingText';
import { Col, Descriptions, Row } from 'antd';
import moment from 'moment';
import { useOrdemForm } from '../useOrdemForm';
import OrdemResumoFinalEmpresaFaturamento from './OrdemForm.ResumoFinal.EmpresaFaturamento';

function OrdemResumoFinalPagamento() {
  const { ordem, isLoading } = useOrdemForm();
  if (isLoading) return <LoadingText text="Carregando" />;
  return (
    <Row>
      <Col span={12}>
        <Descriptions size="small" bordered column={1} title="Pagamento">
          <Descriptions.Item label="Prazo de Pagamento">
            {moment(ordem.prazoPagamento).isValid() && moment(ordem.prazoPagamento).format('DD/MM/YYYY')}
          </Descriptions.Item>
          <Descriptions.Item label="Prazo de Entrega">
            {moment(ordem.prazoEntrega).isValid() && moment(ordem.prazoEntrega).format('DD/MM/YYYY')}
          </Descriptions.Item>
          <Descriptions.Item label="Forma de Pagamento">{ordem.formaPagamento?.toLocaleUpperCase()}</Descriptions.Item>
        </Descriptions>
      </Col>
      <Col span={12}>
        <OrdemResumoFinalEmpresaFaturamento />
      </Col>
    </Row>
  );
}

export default OrdemResumoFinalPagamento;
