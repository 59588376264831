import { ExtendedAction } from '@config/reduxStore';
import { ProcessoEmpreendimento } from '@models/ProcessoEmpreendimento';

interface ObraReducer {
  items: ProcessoEmpreendimento[];
  loading: boolean;
  obrasaving: boolean;
  feedback: string;
}
var initialState: ObraReducer = {
  items: [],
  loading: false,
  obrasaving: false,
  feedback: '',
};

const obraReducer = function (state = initialState, action: ExtendedAction) {
  switch (action.type) {
    case 'CHANGE_PROGRESSO_OBRA_CLIENTE_PENDING':
      state = {
        ...state,
        loading: true,
        feedback: '',
      };
      break;
    case 'CHANGE_PROGRESSO_OBRA_CLIENTE_REJECTED':
      state = {
        ...state,
        loading: false,
        feedback: action.payload.response.data.Message,
      };
      break;
    case 'CHANGE_PROGRESSO_OBRA_CLIENTE_FULFILLED':
      state = {
        ...state,
        loading: false,
        items: state.items.map((c) => {
          if (c.id === action.payload.data.processoid) {
            c.progresso = action.payload.data.progresso;
            return c;
          } else {
            return c;
          }
        }),
      };
      break;
    case 'CHANGE_ORDER_OBRA_PENDING':
      state = {
        ...state,
        loading: true,
        feedback: '',
      };
      break;
    case 'CHANGE_ORDER_OBRA_REJECTED':
      state = {
        ...state,
        loading: false,
        feedback: action.payload.response.data.Message,
      };
      break;
    case 'CHANGE_ORDER_OBRA_FULFILLED':
      state = {
        ...state,
        loading: false,
        items: action.payload.data,
      };
      break;
    case 'DELETE_OBRA_PENDING':
      state = {
        ...state,
        loading: true,
        feedback: '',
      };
      break;
    case 'DELETE_OBRA_REJECTED':
      state = {
        ...state,
        loading: false,
        feedback: action.payload.response.data.Message,
      };
      break;
    case 'DELETE_OBRA_FULFILLED':
      state = {
        ...state,
        loading: false,
        items: [...state.items.filter((c) => c.id !== action.payload.data)],
      };
      break;
    case 'ADD_OBRA_PENDING':
      state = {
        ...state,
        obrasaving: true,
        feedback: '',
      };
      break;
    case 'ADD_OBRA_REJECTED':
      state = {
        ...state,
        obrasaving: false,
        feedback: action.payload.response.data.Message,
      };
      break;
    case 'ADD_OBRA_FULFILLED':
      state = {
        ...state,
        obrasaving: false,
        items: [...state.items, action.payload.data],
      };
      break;
    case 'GET_OBRA_PENDING':
      state = {
        ...state,
        loading: true,
        feedback: '',
      };
      break;
    case 'GET_OBRA_REJECTED':
      state = {
        ...state,
        loading: false,
        feedback: action.payload.response.data.Message,
      };
      break;
    case 'GET_OBRA_FULFILLED':
      state = {
        ...state,
        loading: false,
        items: action.payload.data,
      };
      break;
    default:
      return state;
  }
  return state;
};

export default obraReducer;
