import { OrcamentoItem } from '@models/OrcamentoItem';
import { AdditemFolha } from '@services/api/api.folha';
import { GetOrcamentosRealizado } from '@services/api/api.orcamento';
import { doubleMask1, moneyMask, moneyNegMask } from '@utils/masks';
import { Button, Form, Input, Spin, Table } from 'antd';
import { useFolhaForm } from './useFolhaForm';
import { PlusOutlined } from '@ant-design/icons';

function FolhaFormOrcamentoAddItens() {
  const { isLoading, isFetching, folha } = useFolhaForm();
  const { data, isLoading: loadingOrcamento } = GetOrcamentosRealizado({
    ativo: true,
    orcamentoID: folha?.orcamentoID,
    tipo: 1,
  });
  const addItemFolha = AdditemFolha();
  return (
    <Spin spinning={isLoading || isFetching || loadingOrcamento || addItemFolha.isLoading}>
      <Form.List name="itens">
        {(fields) => (
          <div>
            {fields.map((field) => (
              <div key={field.name}>
                <Form.Item name={[field.name, 'id']} hidden>
                  <Input />
                </Form.Item>
                <Form.Item name={[field.name, 'avanco']} hidden>
                  <Input />
                </Form.Item>
                <Form.Item name={[field.name, 'medir']} hidden>
                  <Input />
                </Form.Item>
                <Form.Item name={[field.name, 'valorMedir']} hidden>
                  <Input />
                </Form.Item>
              </div>
            ))}
            <Table
              size="small"
              dataSource={data?.orcamentosItens.filter(
                (c) =>
                  c.valorTotal - c.realizadoValor > 0 && !c.bloqueado && !folha?.itens.map((d) => d.id).includes(c.id)
              )}
              pagination={false}
              rowKey="id"
              scroll={{ y: 300 }}
            >
              <Table.Column render={(_, __, index) => index + 1} width={40} align="center" />
              <Table.Column
                dataIndex="id"
                width={40}
                align="center"
                render={(_, row: OrcamentoItem) => (
                  <>
                    <Button
                      size="small"
                      icon={<PlusOutlined />}
                      onClick={() => {
                        addItemFolha.mutate({ medir: 100, folhaID: folha.id, orcamentoItemID: row.id, cod: folha.cod });
                      }}
                    />
                  </>
                )}
              />

              <Table.Column title="Categoria" dataIndex="categoria" />
              <Table.Column title="Grupo" dataIndex="grupo" />
              <Table.Column title="Item" dataIndex="item" />
              <Table.Column title="Unidade" dataIndex="unidade" width={80} align="center" />
              <Table.Column
                title="Orçado"
                width={100}
                dataIndex="valorTotal"
                align="right"
                render={(v) => moneyMask.format(v)}
              />
              <Table.Column
                title="Realizado"
                width={100}
                dataIndex="realizadoValor"
                align="right"
                render={(v) => moneyMask.format(v)}
              />
              <Table.Column
                title="Saldo"
                width={100}
                dataIndex="realizadoValor"
                align="right"
                render={(v, row: OrcamentoItem) => moneyNegMask.format(row.valorTotal - v)}
              />
              <Table.Column
                title="Avanço"
                width={100}
                align="right"
                render={(_, row: OrcamentoItem) => doubleMask1.format((100 * row.realizadoValor) / row.valorTotal)}
              />
            </Table>
          </div>
        )}
      </Form.List>
    </Spin>
  );
}

export default FolhaFormOrcamentoAddItens;
